var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      false
        ? _c(
            "a",
            {
              staticClass: "btn btn-success",
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.newUser()
                }
              }
            },
            [_vm._v("New User")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "content-tlist" }, [
        _c("table", { staticClass: "content-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.users, function(user) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(user.username))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(user.role && user.role.name))]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.editUser(user)
                        }
                      }
                    },
                    [_vm._v("View")]
                  ),
                  _vm._v(" "),
                  false
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.deleteUser(user)
                            }
                          }
                        },
                        [_vm._v("Delete")]
                      )
                    : _vm._e()
                ])
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _vm.currentUser
        ? _c("user-edit", {
            attrs: { user: _vm.currentUser, roles: _vm.roles }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("Username")]),
        _vm._v(" "),
        _c("td", [_vm._v("Role")]),
        _vm._v(" "),
        _c("td", [_vm._v("Actions")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
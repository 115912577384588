var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.open
        ? [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cp-box" },
              [
                _c(
                  "ul",
                  {
                    staticClass: "sub-tabs",
                    staticStyle: { "margin-bottom": "12px" }
                  },
                  [
                    _c(
                      "li",
                      {
                        class: { active: _vm.activeTab === "profile" },
                        on: {
                          click: function($event) {
                            _vm.activeTab = "profile"
                          }
                        }
                      },
                      [_c("a", [_vm._v("Registrations/Profiles")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        class: { active: _vm.activeTab === "account" },
                        on: {
                          click: function($event) {
                            _vm.activeTab = "account"
                          }
                        }
                      },
                      [_c("a", [_vm._v("Accounts")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        class: { active: _vm.activeTab === "transaction" },
                        on: {
                          click: function($event) {
                            _vm.activeTab = "transaction"
                          }
                        }
                      },
                      [_c("a", [_vm._v("Transactions")])]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.activeTab === "profile" ? _c("db-registrations") : _vm._e(),
                _vm._v(" "),
                _vm.activeTab === "account" ? _c("db-accounts") : _vm._e(),
                _vm._v(" "),
                _vm.activeTab === "transaction"
                  ? _c("db-transactions")
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "popup", class: { open: _vm.show } }, [
      _c("div", { staticClass: "pop-inner" }, [
        _c("div", { staticClass: "pop-box" }, [
          _c("a", {
            staticClass: "close",
            on: {
              click: function($event) {
                _vm.$parent.currentRole = null
              }
            }
          }),
          _vm._v(" "),
          _c("h2", [_vm._v("Edit Role Group")]),
          _vm._v(" "),
          _c("form", [
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("Role group name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentRole.name,
                    expression: "currentRole.name"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.currentRole.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.currentRole, "name", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row buttons" }, [
            _c(
              "a",
              {
                staticClass: "pop-btn",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.$parent.currentRole = null
                  }
                }
              },
              [_vm._v("Close")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "pop-btn",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.saveRole()
                  }
                }
              },
              [_vm._v("Save")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ControlCenter.vue?vue&type=template&id=05e30968&scoped=true&"
import script from "./ControlCenter.vue?vue&type=script&lang=js&"
export * from "./ControlCenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e30968",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6monbi/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05e30968')) {
      api.createRecord('05e30968', component.options)
    } else {
      api.reload('05e30968', component.options)
    }
    module.hot.accept("./ControlCenter.vue?vue&type=template&id=05e30968&scoped=true&", function () {
      api.rerender('05e30968', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/monbi/control-center/ControlCenter.vue"
export default component.exports
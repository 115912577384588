var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c("section", { staticClass: "content-tlist" }, [
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm._l(_vm.accounts, function(account) {
                return _c("tr", { key: account.id }, [
                  _c("td", [
                    _c("span", [_vm._v("Account Date")]),
                    _vm._v(
                      _vm._s(_vm._f("date")(account.createdAt, "MM/D/YYYY"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Account ID")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "/system-customers/registrations/" + account.regId
                        }
                      },
                      [_vm._v(_vm._s(account.accountNumber))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Currency")]),
                    _vm._v(_vm._s(account.currency))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Account Type")]),
                    _vm._v(_vm._s(account.regType))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Name")]),
                    _vm._v(
                      "\n          " + _vm._s(account.regName) + "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Account Status")]),
                    account
                      ? _c(
                          "em",
                          {
                            class:
                              _vm.statusColorClasses[
                                _vm.getStatus(account.accountStatus)
                              ]
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getStatus(account.accountStatus))
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              }),
              _vm._v(" "),
              !_vm.accounts.length
                ? _c("tr", [
                    _c(
                      "td",
                      { staticClass: "no-data", attrs: { colspan: "6" } },
                      [_vm._v("No data")]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "content-filters", staticStyle: { margin: "0 0 30px 0" } },
      [
        _c("div", { staticClass: "flex-between" }, [
          _c("h2", { staticClass: "inner-headers" }, [
            _vm._v("Customer Accounts")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Account")]),
      _vm._v(" "),
      _c("th", [_vm._v("Currency")]),
      _vm._v(" "),
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
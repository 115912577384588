var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { open: _vm.open },
      on: {
        "update:open": function($event) {
          _vm.open = $event
        }
      }
    },
    [
      _c("div", { staticClass: "popup-header" }, [
        _c("h3", [_vm._v("New File Transfer")])
      ]),
      _vm._v(" "),
      [
        _c(
          "div",
          { staticClass: "scrollable" },
          [
            [
              _c("div", { staticClass: "content-form" }, [
                _c(
                  "div",
                  { staticClass: "form-row form-fullw" },
                  [
                    _c("form-field-select-search", {
                      attrs: {
                        label: "Select Profile",
                        validator: _vm.$v.form.profile,
                        options: _vm.accountOptions,
                        required: ""
                      },
                      model: {
                        value: _vm.form.profile,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "profile", $$v)
                        },
                        expression: "form.profile"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-row form-fullw" },
                  [
                    _c("form-field-upload", {
                      attrs: { label: "File Attachment" },
                      model: {
                        value: _vm.form.file,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "file", $$v)
                        },
                        expression: "form.file"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-row form-fullw" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.description,
                        expression: "form.description"
                      }
                    ],
                    class: { error: _vm.$v.form.description.$invalid },
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "Description", rows: "5" },
                    domProps: { value: _vm.form.description },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "description", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ],
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                [
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      attrs: { disabled: _vm.$v.form.$invalid },
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.open = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ]
              ],
              2
            )
          ],
          2
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "content-tlist" }, [
    _c(
      "table",
      { staticClass: "content-table" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.data, function(review) {
          return _c("tr", { key: review.id }, [
            _c("td", [
              _c("span", [_vm._v("Review Date")]),
              _vm._v(
                _vm._s(_vm._f("date")(review.updatedAt, "MM/D/YYYY hh:mm A"))
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c("span", [_vm._v("Profile")]),
              _c(
                "a",
                { attrs: { href: "/system-customers/profiles/" + review.id } },
                [_vm._v(_vm._s(review.profileNumber))]
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c("span", [_vm._v("Name")]),
              _vm._v(_vm._s(review.name))
            ]),
            _vm._v(" "),
            _c("td", [
              _c("span", [_vm._v("Account Type")]),
              _vm._v(_vm._s(review.type))
            ]),
            _vm._v(" "),
            _c("td", [
              _c("span", [_vm._v("Status")]),
              _vm._v(" "),
              _c("em", { class: _vm.statusColorClasses[review.status] }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      review.status === "Complete"
                        ? "Activation"
                        : review.status
                    ) +
                    "\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Options")]),
                _vm._v(" "),
                _vm.$hasPerm("start-reiew") && review.complianceReview
                  ? [
                      review.complianceReview.done != 2
                        ? _c(
                            "button",
                            {
                              staticClass: "action-button save",
                              on: {
                                click: function($event) {
                                  return _vm.startReview(
                                    review.complianceReview.id
                                  )
                                }
                              }
                            },
                            [_vm._v("Start Review")]
                          )
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.$hasPerm("complete-review") && review.complianceReview
                  ? [
                      review.complianceReview.done == 2
                        ? _c(
                            "button",
                            {
                              staticClass: "action-button save",
                              on: {
                                click: function($event) {
                                  return _vm.completeReview(review)
                                }
                              }
                            },
                            [_vm._v("Complete Review")]
                          )
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        }),
        _vm._v(" "),
        !_vm.data.length
          ? _c("tr", [
              _c("td", { staticClass: "no-data", attrs: { colspan: "7" } }, [
                _vm._v("No data")
              ])
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Review Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Profile")]),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Profile Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "header" } }, [
    _c("ul", { staticClass: "quick-action" }, [
      _c(
        "li",
        [
          _c(
            "el-popover",
            {
              ref: "popover",
              attrs: { placement: "bottom", width: "500", trigger: "click" },
              scopedSlots: _vm._u([
                {
                  key: "reference",
                  fn: function() {
                    return [
                      _c(
                        "span",
                        [
                          _vm.unread
                            ? _c("el-badge", { attrs: { value: _vm.unread } }, [
                                _c("i", {
                                  staticClass: "el-icon-chat-line-square"
                                })
                              ])
                            : _c("i", {
                                staticClass: "el-icon-chat-line-square"
                              })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "div",
                { staticClass: "messages" },
                [
                  _vm._l(_vm.latestMessages, function(message) {
                    return _c("system-message", {
                      key: message.id,
                      attrs: { message: message }
                    })
                  }),
                  _vm._v(" "),
                  !_vm.latestMessages.length
                    ? _c("el-empty", {
                        attrs: {
                          "image-size": 50,
                          description: "There are no new messages."
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "see-all" }, [
                    _c(
                      "a",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { href: "/system-messages" }
                      },
                      [_vm._v("\n              View all\n            ")]
                    )
                  ])
                ],
                2
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                effect: "dark",
                content: "Notifications",
                placement: "bottom"
              }
            },
            [_c("i", { staticClass: "el-icon-bell" })]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          { attrs: { href: "/support" } },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: "Support",
                  placement: "bottom"
                }
              },
              [_c("i", { staticClass: "el-icon-help" })]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.openedSettings
        ? _c("settings-fee-schedule-settings", {
            attrs: { fee: _vm.fees[0] },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedSettings = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "flex header-flex" }, [
          _c("div", [
            _vm.$hasPerm("update-fee-Schedule")
              ? _c(
                  "button",
                  {
                    staticClass: "main",
                    on: {
                      click: function($event) {
                        _vm.modalType = "modal"
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _vm._v(" New Plan")
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticStyle: { "margin-right": "10px" } }, [
              _vm._v("\n          Plan\n          "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.plan,
                      expression: "plan"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.plan = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option"),
                  _vm._v(" "),
                  _vm._l(_vm.plans, function(item) {
                    return _c("option", { domProps: { value: item.id } }, [
                      _vm._v(_vm._s(item.text))
                    ])
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "second",
                on: {
                  click: function($event) {
                    _vm.openedSettings = true
                  }
                }
              },
              [
                _c("i", { staticClass: "el-icon-s-operation" }),
                _vm._v(" Settings")
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "content-tlist" }, [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm.feeSchedule
              ? [
                  _c("tr", [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Fee")]),
                        _vm._v(" "),
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.volumeFee.fee
                                    )
                                  ) +
                                  " %\n            "
                              )
                            ]
                          : [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.volumeFee.fee,
                                    expression: "feeSchedule.volumeFee.fee"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator: _vm.$v.feeSchedule.volumeFee.fee
                                },
                                domProps: {
                                  value: _vm.feeSchedule.volumeFee.fee
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.volumeFee,
                                      "fee",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v("%\n            ")
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td")
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Fee")]),
                        _vm._v(" "),
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.monthlyAccessFee.fee
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.monthlyAccessFee.fee,
                                    expression:
                                      "feeSchedule.monthlyAccessFee.fee"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.monthlyAccessFee.fee
                                },
                                domProps: {
                                  value: _vm.feeSchedule.monthlyAccessFee.fee
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.monthlyAccessFee,
                                      "fee",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td")
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _vm._m(4),
                    _vm._v(" "),
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.domesticWire.per_deposit
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.domesticWire.per_deposit,
                                    expression:
                                      "feeSchedule.domesticWire.per_deposit"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.domesticWire.per_deposit
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.domesticWire.per_deposit
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.domesticWire,
                                      "per_deposit",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.domesticWire
                                        .per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.domesticWire
                                        .per_withdrawal,
                                    expression:
                                      "feeSchedule.domesticWire.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.domesticWire
                                      .per_withdrawal
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.domesticWire.per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.domesticWire,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _vm._m(6),
                    _vm._v(" "),
                    _vm._m(7),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.internationalWire
                                        .per_deposit
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.internationalWire
                                        .per_deposit,
                                    expression:
                                      "feeSchedule.internationalWire.per_deposit"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.internationalWire
                                      .per_deposit
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.internationalWire
                                      .per_deposit
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.internationalWire,
                                      "per_deposit",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.internationalWire
                                        .per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.internationalWire
                                        .per_withdrawal,
                                    expression:
                                      "feeSchedule.internationalWire.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.internationalWire
                                      .per_withdrawal
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.internationalWire
                                      .per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.internationalWire,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _vm._m(8),
                    _vm._v(" "),
                    _vm._m(9),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.ach.per_deposit
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.ach.per_deposit,
                                    expression: "feeSchedule.ach.per_deposit"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator: _vm.$v.feeSchedule.ach.per_deposit
                                },
                                domProps: {
                                  value: _vm.feeSchedule.ach.per_deposit
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.ach,
                                      "per_deposit",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.ach.per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.ach.per_withdrawal,
                                    expression: "feeSchedule.ach.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.ach.per_withdrawal
                                },
                                domProps: {
                                  value: _vm.feeSchedule.ach.per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.ach,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _vm._m(10),
                    _vm._v(" "),
                    _vm._m(11),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.accountTransfer
                                        .per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.accountTransfer
                                        .per_withdrawal,
                                    expression:
                                      "feeSchedule.accountTransfer.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.accountTransfer
                                      .per_withdrawal
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.accountTransfer
                                      .per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.accountTransfer,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _vm._m(12),
                    _vm._v(" "),
                    _vm._m(13),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.checks.per_deposit
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.checks.per_deposit,
                                    expression: "feeSchedule.checks.per_deposit"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.checks.per_deposit
                                },
                                domProps: {
                                  value: _vm.feeSchedule.checks.per_deposit
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.checks,
                                      "per_deposit",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.checks.per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.checks.per_withdrawal,
                                    expression:
                                      "feeSchedule.checks.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.checks.per_withdrawal
                                },
                                domProps: {
                                  value: _vm.feeSchedule.checks.per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.checks,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _vm._m(14),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Fee")]),
                        _vm._v(" "),
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.exchange.fee
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.exchange.fee,
                                    expression: "feeSchedule.exchange.fee"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator: _vm.$v.feeSchedule.exchange.fee
                                },
                                domProps: {
                                  value: _vm.feeSchedule.exchange.fee
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.exchange,
                                      "fee",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td")
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _vm._m(15),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Fee")]),
                        _vm._v(" "),
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.assets.fee
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.assets.fee,
                                    expression: "feeSchedule.assets.fee"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator: _vm.$v.feeSchedule.assets.fee
                                },
                                domProps: { value: _vm.feeSchedule.assets.fee },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.assets,
                                      "fee",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.assets.per_deposit
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.assets.per_deposit,
                                    expression: "feeSchedule.assets.per_deposit"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.assets.per_deposit
                                },
                                domProps: {
                                  value: _vm.feeSchedule.assets.per_deposit
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.assets,
                                      "per_deposit",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.assets.per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.assets.per_withdrawal,
                                    expression:
                                      "feeSchedule.assets.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.assets.per_withdrawal
                                },
                                domProps: {
                                  value: _vm.feeSchedule.assets.per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.assets,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _vm._m(16),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Fee")]),
                        _vm._v(" "),
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.dormantAccounts.fee
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.dormantAccounts.fee,
                                    expression:
                                      "feeSchedule.dormantAccounts.fee"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.dormantAccounts.fee
                                },
                                domProps: {
                                  value: _vm.feeSchedule.dormantAccounts.fee
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.dormantAccounts,
                                      "fee",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td")
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _vm._m(17),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Fee")]),
                        _vm._v(" "),
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.debitCard.fee
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.debitCard.fee,
                                    expression: "feeSchedule.debitCard.fee"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator: _vm.$v.feeSchedule.debitCard.fee
                                },
                                domProps: {
                                  value: _vm.feeSchedule.debitCard.fee
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.debitCard,
                                      "fee",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.debitCard.per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.debitCard.per_withdrawal,
                                    expression:
                                      "feeSchedule.debitCard.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.debitCard.per_withdrawal
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.debitCard.per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.debitCard,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ])
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.feeSchedule
        ? _c("section", [
            _vm.$hasPerm("update-fee-Schedule")
              ? _c("div", { staticClass: "flex header-flex" }, [
                  _c("div", [
                    _vm.updateFlag
                      ? _c(
                          "button",
                          {
                            staticClass: "main",
                            on: {
                              click: function($event) {
                                return _vm.save()
                              }
                            }
                          },
                          [_vm._v("Save")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "main",
                            on: {
                              click: function($event) {
                                _vm.updateFlag = true
                              }
                            }
                          },
                          [_vm._v("Update")]
                        )
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.modalType
        ? _c("fee-plan-popup", {
            attrs: { "modal-type": _vm.modalType },
            on: {
              close: function($event) {
                _vm.modalType = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticStyle: { display: "none" } }, [
      _c("div", { staticClass: "flex settings-header" }, [
        _c("h2", { staticClass: "inner-headers" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Fee Name")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Fee")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Deposit")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Withdrawal")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Volume Fee\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Monthly Access Fee\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Domestic Wire\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            International Wire\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            ACH\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Account Transfer\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Checks\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Exchange\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Assets\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Dormant Accounts\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Debit Card\n          ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "content-filters" },
        [
          _c("basic-form", [
            _c("div", { staticClass: "cof-row" }, [
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Provider Type")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.providerType,
                      options: _vm.providerTypeOptions
                    },
                    model: {
                      value: _vm.form.providerType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "providerType", $$v)
                      },
                      expression: "form.providerType"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    attrs: {
                      validator: _vm.$v.form.provider_name,
                      label: "Provider Name"
                    },
                    model: {
                      value: _vm.form.provider_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "provider_name", $$v)
                      },
                      expression: "form.provider_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    attrs: { label: "Label" },
                    model: {
                      value: _vm.form.label,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "label", $$v)
                      },
                      expression: "form.label"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    attrs: { validator: _vm.$v.form.version, label: "Version" },
                    model: {
                      value: _vm.form.version,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "version", $$v)
                      },
                      expression: "form.version"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Environment")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.environment,
                      options: _vm.environmentOptions
                    },
                    model: {
                      value: _vm.form.environment,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "environment", $$v)
                      },
                      expression: "form.environment"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    attrs: { label: "Username" },
                    model: {
                      value: _vm.form.username,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    attrs: { label: "Password" },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    attrs: { label: "Key" },
                    model: {
                      value: _vm.form.code,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    attrs: { label: "Solution ID" },
                    model: {
                      value: _vm.form.solutionId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "solutionId", $$v)
                      },
                      expression: "form.solutionId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    attrs: { label: "Ledger ID" },
                    model: {
                      value: _vm.form.ledgerId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "ledgerId", $$v)
                      },
                      expression: "form.ledgerId"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cof-row" }, [
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    staticClass: "url",
                    attrs: { label: "URL", validator: _vm.$v.form.url },
                    model: {
                      value: _vm.form.url,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "url", $$v)
                      },
                      expression: "form.url"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    staticClass: "url",
                    attrs: {
                      label: "Client ID",
                      validator: _vm.$v.form.clientId
                    },
                    model: {
                      value: _vm.form.clientId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "clientId", $$v)
                      },
                      expression: "form.clientId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    staticClass: "url",
                    attrs: {
                      label: "Client Secret",
                      validator: _vm.$v.form.clientSecret
                    },
                    model: {
                      value: _vm.form.clientSecret,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "clientSecret", $$v)
                      },
                      expression: "form.clientSecret"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cof-row" }, [
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("KYC/AML")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.kyc,
                      options: _vm.yesNoOptions
                    },
                    model: {
                      value: _vm.form.kyc,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "kyc", $$v)
                      },
                      expression: "form.kyc"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("ACH")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.ach,
                      options: _vm.yesNoOptions
                    },
                    model: {
                      value: _vm.form.ach,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "ach", $$v)
                      },
                      expression: "form.ach"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Account Transfer")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.acct,
                      options: _vm.yesNoOptions
                    },
                    model: {
                      value: _vm.form.acct,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "acct", $$v)
                      },
                      expression: "form.acct"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Checks")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.checks,
                      options: _vm.yesNoOptions
                    },
                    model: {
                      value: _vm.form.checks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "checks", $$v)
                      },
                      expression: "form.checks"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Remote Deposit")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.remoteDeposit,
                      options: _vm.yesNoOptions
                    },
                    model: {
                      value: _vm.form.remoteDeposit,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remoteDeposit", $$v)
                      },
                      expression: "form.remoteDeposit"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Domestic Wires")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.domWires,
                      options: _vm.yesNoOptions
                    },
                    model: {
                      value: _vm.form.domWires,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "domWires", $$v)
                      },
                      expression: "form.domWires"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("International Wires")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.intlWires,
                      options: _vm.yesNoOptions
                    },
                    model: {
                      value: _vm.form.intlWires,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "intlWires", $$v)
                      },
                      expression: "form.intlWires"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Assets")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.assets,
                      options: _vm.yesNoOptions
                    },
                    model: {
                      value: _vm.form.assets,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "assets", $$v)
                      },
                      expression: "form.assets"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Multi-Currency")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.multiCurrency,
                      options: _vm.yesNoOptions
                    },
                    model: {
                      value: _vm.form.multiCurrency,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "multiCurrency", $$v)
                      },
                      expression: "form.multiCurrency"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Debit Cards")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.debitCards,
                      options: _vm.yesNoOptions
                    },
                    model: {
                      value: _vm.form.debitCards,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "debitCards", $$v)
                      },
                      expression: "form.debitCards"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Transaction Countries")]),
                  _vm._v(" "),
                  _c("multi-select-dropdown", {
                    attrs: { options: _vm.countries, isMulti: true },
                    model: {
                      value: _vm.form.transactionCountries,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "transactionCountries", $$v)
                      },
                      expression: "form.transactionCountries"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cof-row" }, [
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    staticClass: "url",
                    attrs: { label: "Notes", validator: _vm.$v.form.notes },
                    model: {
                      value: _vm.form.notes,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "notes", $$v)
                      },
                      expression: "form.notes"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Connection Type")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: { options: _vm.connectionTypeOptions },
                    model: {
                      value: _vm.form.connectionType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "connectionType", $$v)
                      },
                      expression: "form.connectionType"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cof-row" }, [
              _c("div", { staticClass: "cof-field mobinl" }, [
                _c("label", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "main",
                    on: {
                      click: function($event) {
                        return _vm.save()
                      }
                    }
                  },
                  [_vm._v("Add Provider")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cof-field mobinl" }, [
                _c("label", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        return _vm.resetFields()
                      }
                    }
                  },
                  [_vm._v("Reset Fields")]
                )
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("section", { staticClass: "content-tlist" }, [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm._l(_vm.providers, function(provider, i) {
              return _c("tr", { key: i }, [
                _c("td", { staticClass: "updateAt" }, [
                  _c("span", [_vm._v("Upd")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: provider.upd,
                        expression: "provider.upd"
                      }
                    ],
                    staticClass: "fee-checkbox",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(provider.upd)
                        ? _vm._i(provider.upd, null) > -1
                        : provider.upd
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = provider.upd,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(provider, "upd", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  provider,
                                  "upd",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(provider, "upd", $$c)
                          }
                        },
                        function($event) {
                          return _vm.updUpdate(i)
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("date")(provider.updatedAt, "YYYY-MM-D"))
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("date")(provider.updatedAt, "hh:mm:ss")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Provider Name")]),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.detail(provider.id)
                        }
                      }
                    },
                    [_vm._v(_vm._s(provider.provider_name))]
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Version")]),
                  _vm._v(_vm._s(provider.version))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Version")]),
                  _vm._v(_vm._s(provider.environment))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Date")]),
                  _vm._v(_vm._s(_vm._f("date")(provider.createdAt, "LL")))
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Status")]),
                    _vm._v(" "),
                    !_vm.isEdit(i)
                      ? [
                          _c(
                            "em",
                            {
                              class: {
                                c1: provider.status == "Active",
                                c2: provider.status == "Review",
                                c3: provider.status === "Testing"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.formatStatus(provider.status)) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      : _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: provider.status,
                                expression: "provider.status"
                              }
                            ],
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  provider,
                                  "status",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "Review" } }, [
                              _vm._v("Review")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "Testing" } }, [
                              _vm._v("Testing")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "Active" } }, [
                              _vm._v("Active")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "Disabled" } }, [
                              _vm._v("Disabled")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "Archive" } }, [
                              _vm._v("Archive")
                            ])
                          ]
                        )
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Notes")]),
                    _vm._v(" "),
                    !_vm.isEdit(i)
                      ? [_vm._v(_vm._s(provider.notes))]
                      : _c("mnb-input", {
                          model: {
                            value: provider.notes,
                            callback: function($$v) {
                              _vm.$set(provider, "notes", $$v)
                            },
                            expression: "provider.notes"
                          }
                        })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Options")]),
                    _vm._v(" "),
                    _vm.isEdit(i)
                      ? [
                          _c(
                            "button",
                            {
                              staticClass: "action-button save",
                              on: {
                                click: function($event) {
                                  return _vm.saveUpdate(i)
                                }
                              }
                            },
                            [_vm._v("Save")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "action-button cancel",
                              on: {
                                click: function($event) {
                                  return _vm.cancelEdit(i)
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          )
                        ]
                      : [
                          _c(
                            "button",
                            {
                              staticClass: "action-button update",
                              on: {
                                click: function($event) {
                                  return _vm.edit(i)
                                }
                              }
                            },
                            [_vm._v("Update")]
                          ),
                          _vm._v(" "),
                          provider.status == "Archive"
                            ? _c(
                                "button",
                                {
                                  staticClass: "action-button cancel",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteProvider(i)
                                    }
                                  }
                                },
                                [_vm._v("Remove")]
                              )
                            : _vm._e()
                        ]
                  ],
                  2
                )
              ])
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.open },
          on: {
            "update:open": function($event) {
              _vm.open = $event
            }
          }
        },
        [
          _c("h2", [_vm._v("Update Status")]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-field status-options" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.status,
                    expression: "status"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.status = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { value: "Review" } }, [
                  _vm._v("Review")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Testing" } }, [
                  _vm._v("Testing")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Active" } }, [
                  _vm._v("Active")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Disabled" } }, [
                  _vm._v("Disabled")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Archive" } }, [
                  _vm._v("Archive")
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row buttons" }, [
            _c(
              "a",
              {
                staticClass: "pop-btn",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.open = false
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "pop-btn",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.updateStatus()
                  }
                }
              },
              [_vm._v("Confirm")]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.openPopup },
          on: {
            "update:open": function($event) {
              _vm.openPopup = $event
            }
          }
        },
        [
          _vm.providerPopup
            ? _c("div", { staticClass: "popup-header" }, [
                _c("h3", [_vm._v("Provider Details")]),
                _vm._v(" "),
                _c("h4", [_vm._v(_vm._s(_vm.providerPopup.createdAt))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.providerPopup
            ? _c("div", { staticClass: "scrollable" }, [
                _c("div", { staticClass: "content-form" }, [
                  _c("h3", { staticClass: "subtitle" }, [
                    _vm._v("Provider Information")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-split" },
                    [
                      _c("form-field-select", {
                        attrs: {
                          label: "Provider Type",
                          options: _vm.providerTypeOptions,
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.providerType,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "providerType", $$v)
                          },
                          expression: "providerPopup.providerType"
                        }
                      }),
                      _vm._v(" "),
                      _c("form-field-select", {
                        attrs: {
                          label: "Status",
                          options: _vm.statusOptions,
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.status,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "status", $$v)
                          },
                          expression: "providerPopup.status"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-split" },
                    [
                      _c("form-field-text", {
                        attrs: {
                          label: "Provider Name",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.provider_name,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "provider_name", $$v)
                          },
                          expression: "providerPopup.provider_name"
                        }
                      }),
                      _vm._v(" "),
                      _c("form-field-text", {
                        attrs: {
                          label: "Label",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.label,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "label", $$v)
                          },
                          expression: "providerPopup.label"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-split" },
                    [
                      _c("form-field-text", {
                        attrs: {
                          label: "Version",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.version,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "version", $$v)
                          },
                          expression: "providerPopup.version"
                        }
                      }),
                      _vm._v(" "),
                      _c("form-field-select", {
                        attrs: {
                          label: "Environment",
                          disabled: !_vm.updateProvider,
                          options: _vm.environmentOptions
                        },
                        model: {
                          value: _vm.providerPopup.environment,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "environment", $$v)
                          },
                          expression: "providerPopup.environment"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-split" },
                    [
                      _c("form-field-text", {
                        attrs: {
                          label: "Username",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.username,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "username", $$v)
                          },
                          expression: "providerPopup.username"
                        }
                      }),
                      _vm._v(" "),
                      _c("form-field-text", {
                        attrs: {
                          label: "Password",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.password,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "password", $$v)
                          },
                          expression: "providerPopup.password"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-max" },
                    [
                      _c("form-field-text", {
                        attrs: {
                          label: "URL",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.url,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "url", $$v)
                          },
                          expression: "providerPopup.url"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-max" },
                    [
                      _c("form-field-text", {
                        attrs: {
                          label: "Key",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.code,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "code", $$v)
                          },
                          expression: "providerPopup.code"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-max" },
                    [
                      _c("form-field-text", {
                        attrs: {
                          label: "Solution ID",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.solutionId,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "solutionId", $$v)
                          },
                          expression: "providerPopup.solutionId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-max" },
                    [
                      _c("form-field-text", {
                        attrs: {
                          label: "Ledger ID",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.ledgerId,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "ledgerId", $$v)
                          },
                          expression: "providerPopup.ledgerId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-max" },
                    [
                      _c("form-field-text", {
                        attrs: {
                          label: "Client ID",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.clientId,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "clientId", $$v)
                          },
                          expression: "providerPopup.clientId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-max" },
                    [
                      _c("form-field-text", {
                        attrs: {
                          label: "Client Secret",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.clientSecret,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "clientSecret", $$v)
                          },
                          expression: "providerPopup.clientSecret"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "content-form" }, [
                  _c("h3", { staticClass: "subtitle" }, [
                    _vm._v("Provider Services")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "services-split" }, [
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("KYC/AML")]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.updateProvider },
                          model: {
                            value: _vm.providerPopup.kyc,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "kyc", $$v)
                            },
                            expression: "providerPopup.kyc"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("ACH")]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.updateProvider },
                          model: {
                            value: _vm.providerPopup.ach,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "ach", $$v)
                            },
                            expression: "providerPopup.ach"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "services-split" }, [
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("Account Transfer")]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.updateProvider },
                          model: {
                            value: _vm.providerPopup.acct,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "acct", $$v)
                            },
                            expression: "providerPopup.acct"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("Checks")]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.updateProvider },
                          model: {
                            value: _vm.providerPopup.checks,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "checks", $$v)
                            },
                            expression: "providerPopup.checks"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "services-split" }, [
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("Domestic Wires")]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.updateProvider },
                          model: {
                            value: _vm.providerPopup.domWires,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "domWires", $$v)
                            },
                            expression: "providerPopup.domWires"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("International Wires")]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.updateProvider },
                          model: {
                            value: _vm.providerPopup.intlWires,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "intlWires", $$v)
                            },
                            expression: "providerPopup.intlWires"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "services-split" }, [
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("Assets")]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.updateProvider },
                          model: {
                            value: _vm.providerPopup.assets,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "assets", $$v)
                            },
                            expression: "providerPopup.assets"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("Multi-Currency")]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.updateProvider },
                          model: {
                            value: _vm.providerPopup.multiCurrency,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "multiCurrency", $$v)
                            },
                            expression: "providerPopup.multiCurrency"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "services-split" }, [
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("Debit Cards")]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.updateProvider },
                          model: {
                            value: _vm.providerPopup.debitCards,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "debitCards", $$v)
                            },
                            expression: "providerPopup.debitCards"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("Remote Deposit")]),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: !_vm.updateProvider },
                          model: {
                            value: _vm.providerPopup.remoteDeposit,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "remoteDeposit", $$v)
                            },
                            expression: "providerPopup.remoteDeposit"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "div",
                    [
                      _c("p", [_vm._v("Transaction Countries")]),
                      _vm._v(" "),
                      _c("multi-select-dropdown", {
                        attrs: {
                          options: _vm.countries,
                          isMulti: true,
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.transactionCountries,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.providerPopup,
                              "transactionCountries",
                              $$v
                            )
                          },
                          expression: "providerPopup.transactionCountries"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "content-form" }, [
                  _c("h3", { staticClass: "subtitle" }, [
                    _vm._v("Provider Notes")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-max" },
                    [
                      _c("form-field-text", {
                        attrs: {
                          label: "Notes",
                          required: "",
                          disabled: !_vm.updateProvider
                        },
                        model: {
                          value: _vm.providerPopup.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "notes", $$v)
                          },
                          expression: "providerPopup.notes"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-max" },
                    [
                      _c("form-field-select", {
                        attrs: {
                          label: "Connection Type",
                          disabled: !_vm.updateProvider,
                          options: _vm.connectionTypeOptions
                        },
                        model: {
                          value: _vm.providerPopup.connectionType,
                          callback: function($$v) {
                            _vm.$set(_vm.providerPopup, "connectionType", $$v)
                          },
                          expression: "providerPopup.connectionType"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons" }, [
                  _vm.updateProvider
                    ? _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.saveProvider()
                            }
                          }
                        },
                        [_vm._v("Save")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              _vm.updateProvider = true
                            }
                          }
                        },
                        [_vm._v("Update")]
                      ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.openPopup = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Key Auto Update")]),
      _vm._v(" "),
      _c("th", [_vm._v("Provider Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Version")]),
      _vm._v(" "),
      _c("th", [_vm._v("Environment")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Notes")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cp-box" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("permissions", {
        attrs: {
          disabled: _vm.disabled,
          employee: _vm.employee,
          noButtons: _vm.noButtons,
          classes: "roleHeadline"
        },
        on: { editPerms: _vm.enableDisablePerms },
        model: {
          value: _vm.user.perms,
          callback: function($$v) {
            _vm.$set(_vm.user, "perms", $$v)
          },
          expression: "user.perms"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", { staticClass: "main-header" }, [
        _c("h2", { staticClass: "inner-headers" }, [_vm._v("Permissions")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
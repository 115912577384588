var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.featureStatus != null && !_vm.featureStatus
        ? [_vm._m(0)]
        : _vm.featureStatus != null && _vm.featureStatus
        ? [
            _c("section", [
              _c("div", { staticClass: "flex-between" }, [
                _c("h2", { staticClass: "inner-headers" }),
                _vm._v(" "),
                _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.openedFilters = true
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-operation" }),
                      _vm._v(" Filters\n          ")
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            !this.currentUser.parId
              ? [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      staticStyle: { "margin-top": "-60px" },
                      on: {
                        click: function($event) {
                          return _vm.openTradeModal()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(" New Trade")
                    ]
                  )
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("applied-filters", {
              attrs: { filters: _vm.appliedFilters },
              on: {
                clear: function($event) {
                  return _vm.clearFilters()
                },
                remove: function($event) {
                  return _vm.removeFilter($event)
                }
              }
            }),
            _vm._v(" "),
            _vm.$hasPerm("update-transaction")
              ? _c("section", [
                  _vm.currentUser.role && _vm.currentUser.role.group.id == 1
                    ? _c("div", { staticClass: "text-center" }, [
                        _vm.systemMaintenanceStatus
                          ? _c(
                              "button",
                              {
                                staticClass: "main forcebtt",
                                on: {
                                  click: function($event) {
                                    return _vm.updateTrasactions()
                                  }
                                }
                              },
                              [_vm._v("Update Transactions")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "accordion" }, [
              _c("div", { class: { "cof-box-active": _vm.activeInfo } }, [
                !_vm.loading
                  ? _c(
                      "section",
                      { staticClass: "content-tlist" },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "transactionsTable",
                            staticClass: "content-table",
                            attrs: { data: _vm.filteredData },
                            on: { "row-click": _vm.transactionsTableClick }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "expand" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        _vm._l([[props.row]], function(
                                          selectedTrade
                                        ) {
                                          return _c(
                                            "div",
                                            { staticClass: "popup-header" },
                                            [
                                              _c("h3", [_vm._v("Trade")]),
                                              _vm._v(" "),
                                              selectedTrade
                                                ? _c("h3", [
                                                    _vm._v(
                                                      _vm._s(
                                                        selectedTrade[0]
                                                          .tradeNumber
                                                      )
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              selectedTrade
                                                ? _c("h3", [
                                                    _vm._v(
                                                      "Status: " +
                                                        _vm._s(
                                                          _vm.letterUpper(
                                                            _vm.getStatus(
                                                              selectedTrade[0]
                                                                .quoteStatus
                                                            )
                                                          )
                                                        )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _vm._l([[props.row]], function(
                                          selectedTrade
                                        ) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "scrollable" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "information"
                                                  },
                                                  [
                                                    selectedTrade
                                                      ? [
                                                          _c("div", [
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Account"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": ****" +
                                                                  _vm._s(
                                                                    selectedTrade[0].accountId.substr(
                                                                      4,
                                                                      3
                                                                    )
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Created"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "date"
                                                                    )(
                                                                      selectedTrade[0]
                                                                        .createdAt,
                                                                      "MM/D/YYYY hh:mm A"
                                                                    )
                                                                  )
                                                              )
                                                            ])
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("div", [
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Profile"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    selectedTrade[0]
                                                                      .profileId
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Completed By"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    selectedTrade[0]
                                                                      .completedBy
                                                                  )
                                                              )
                                                            ])
                                                          ])
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "popup-header details"
                                                  },
                                                  [
                                                    _c("h3", [
                                                      _vm._v("Details")
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                selectedTrade
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "information"
                                                        },
                                                        [
                                                          _c("div", [
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v("Quote")
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    selectedTrade[0]
                                                                      .quoteNumber
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Trade Type"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    selectedTrade[0]
                                                                      .quoteType
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Base Amount"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "balance"
                                                                    )(
                                                                      selectedTrade[0]
                                                                        .baseAmount
                                                                    )
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Total Amount"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "balance"
                                                                    )(
                                                                      selectedTrade[0]
                                                                        .totalAmount
                                                                    )
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Unit Count"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    parseFloat(
                                                                      selectedTrade[0]
                                                                        .unitCount
                                                                    )
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _vm._v(" ")
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _vm._v(" ")
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "p",
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "Completed On"
                                                                  )
                                                                ]),
                                                                _vm._v(": "),
                                                                selectedTrade[0]
                                                                  .trade
                                                                  ? [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "date"
                                                                          )(
                                                                            selectedTrade[0]
                                                                              .trade
                                                                              .providerSettledAt,
                                                                            "MM/D/YYYY hh:mm A"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  : _vm._e()
                                                              ],
                                                              2
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("div", [
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v("Trade")
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    selectedTrade[0]
                                                                      .tradeNumber
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v("Asset")
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    selectedTrade[0]
                                                                      .assetName
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Broker Fee"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "balance"
                                                                    )(
                                                                      Math.abs(
                                                                        selectedTrade[0]
                                                                          .feeAmount
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Price Per Unit"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    parseFloat(
                                                                      selectedTrade[0]
                                                                        .pricePerUnit
                                                                    )
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Trade Fee"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "balance"
                                                                    )(
                                                                      Math.abs(
                                                                        selectedTrade[0]
                                                                          .tradeFee
                                                                      )
                                                                    )
                                                                  )
                                                              )
                                                            ])
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "popup-header notes"
                                              },
                                              [_c("h3", [_vm._v("Options")])]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "buttons" },
                                              [
                                                _c("div"),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "main",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.print(
                                                          selectedTrade[0].id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Print")]
                                                )
                                              ]
                                            )
                                          ]
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3815461579
                              )
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Date" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("date")(
                                                props.row.createdAt,
                                                "MM/D/YYYY"
                                              )
                                            )
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("date")(
                                                  props.row.createdAt,
                                                  "hh:mm A"
                                                )
                                              )
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1730629616
                              )
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Account" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        !_vm.isCustomer
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "/system-customers/registrations/" +
                                                    props.row.regId +
                                                    "?tab=Accounts"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(props.row.accountId)
                                                )
                                              ]
                                            )
                                          : [_vm._v(_vm._s(_vm.item.accountId))]
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1937966995
                              )
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Quote", prop: "quoteNumber" }
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Trade" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        props.row.trade
                                          ? [
                                              _vm._v(
                                                _vm._s(
                                                  props.row.trade.tradeNumber
                                                )
                                              )
                                            ]
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1325010347
                              )
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Type", prop: "quoteType" }
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Asset", prop: "assetName" }
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Amount" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("balance")(
                                                props.row.baseAmount
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2840994011
                              )
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Broker Fee" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("balance")(
                                                Math.abs(props.row.feeAmount)
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3585422663
                              )
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Total" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("balance")(
                                                props.row.totalAmount
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                103501836
                              )
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Per Unit" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              parseFloat(props.row.pricePerUnit)
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                306517877
                              )
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Unit Count" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              parseFloat(props.row.unitCount)
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1174651004
                              )
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Status" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(props) {
                                      return [
                                        _c(
                                          "em",
                                          {
                                            class:
                                              _vm.statusColorClasses[
                                                _vm.getStatus(
                                                  props.row.quoteStatus
                                                )
                                              ]
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.letterUpper(
                                                  _vm.getStatus(
                                                    props.row.quoteStatus
                                                  )
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3482266738
                              )
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "Options" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.loading
                  ? _c("section", { staticClass: "pagination-section" }, [
                      _c("div", [
                        _vm._v("\n            Per page\n            "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.perPage,
                                expression: "perPage"
                              }
                            ],
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.perPage = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function($event) {
                                  return _vm.setPages()
                                }
                              ]
                            }
                          },
                          [
                            _c("option", { attrs: { value: "25" } }, [
                              _vm._v("25")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "50" } }, [
                              _vm._v("50")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "75" } }, [
                              _vm._v("75")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "100" } }, [
                              _vm._v("100")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "150" } }, [
                              _vm._v("150")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "200" } }, [
                              _vm._v("200")
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "nav",
                        { attrs: { "aria-label": "Page navigation example" } },
                        [
                          _c("ul", { staticClass: "pagination" }, [
                            _c("li", { staticClass: "page-item" }, [
                              _vm.page != 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "page-link",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          _vm.page--
                                        }
                                      }
                                    },
                                    [_vm._v(" Previous ")]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "page-item" },
                              _vm._l(
                                _vm.pages.slice(_vm.page - 1, _vm.page + 5),
                                function(pageNumber) {
                                  return _c(
                                    "button",
                                    {
                                      key: pageNumber,
                                      staticClass: "page-link",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          _vm.page = pageNumber
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(pageNumber) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("li", { staticClass: "page-item" }, [
                              _vm.page < _vm.pages.length
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "page-link",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          _vm.page++
                                        }
                                      }
                                    },
                                    [_vm._v(" Next ")]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.addModal
        ? _c("manage-trade-desk", {
            on: {
              reload: function($event) {
                return _vm.reload()
              },
              close: function($event) {
                _vm.addModal = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("trade-desk-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "content-filters" }, [
      _c("h3", { staticClass: "monthly-head" }, [
        _vm._v(
          "This feature is not enabled. Please contact system administration in order to have it enabled."
        )
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "monthly-head" }, [
        _vm._v("Feature - Trade Desk")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isMobile
    ? _c("div", { staticClass: "mobile-banner" }, [
        _c("span", [_vm._v("The portal is best viewed using our mobile app.")]),
        _vm._v(" "),
        _vm.mobileLink
          ? _c("a", { attrs: { href: _vm.mobileLink } }, [_vm._v("Download")])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
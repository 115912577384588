var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("section", { staticClass: "content-tlist" }, [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm._l(_vm.providers, function(provider, i) {
              return provider.status != "Archived"
                ? _c("tr", { key: i }, [
                    _c("td", [
                      _c("span", [_vm._v("Provider Name")]),
                      _vm._v(_vm._s(provider.provider_name))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Version")]),
                      _vm._v(_vm._s(provider.version))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Version")]),
                      _vm._v(_vm._s(provider.environment))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Type")]),
                      _vm._v(_vm._s(provider.providerType))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Status")]),
                      _vm._v(" "),
                      _c(
                        "em",
                        {
                          class: {
                            c1: provider.status == "Active",
                            c2: provider.status == "Review",
                            c3: provider.status === "Testing"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.formatStatus(provider.status)) +
                              "\n          "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Services")]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.detail(provider.id)
                            }
                          }
                        },
                        [_vm._v("View")]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Notes")]),
                        _vm._v(" "),
                        [_vm._v(_vm._s(provider.notes))]
                      ],
                      2
                    )
                  ])
                : _vm._e()
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.openPopup },
          on: {
            "update:open": function($event) {
              _vm.openPopup = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Adapter Services")])
          ]),
          _vm._v(" "),
          _vm.providerPopup
            ? _c("div", { staticClass: "scrollable" }, [
                _c("div", { staticClass: "content-form" }, [
                  _c("div", { staticClass: "services-split" }, [
                    _c(
                      "div",
                      [
                        _c("p", [_c("b", [_vm._v("KYC/AML")])]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.providerPopup.kyc,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "kyc", $$v)
                            },
                            expression: "providerPopup.kyc"
                          }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("p", [_c("b", [_vm._v("Domestic Wires")])]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.providerPopup.domWires,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "domWires", $$v)
                            },
                            expression: "providerPopup.domWires"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("p", [_c("b", [_vm._v("Account Transfer")])]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.providerPopup.acct,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "acct", $$v)
                            },
                            expression: "providerPopup.acct"
                          }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("p", [_c("b", [_vm._v("International Wires")])]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.providerPopup.intlWires,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "intlWires", $$v)
                            },
                            expression: "providerPopup.intlWires"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("p", [_c("b", [_vm._v("ACH")])]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.providerPopup.ach,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "ach", $$v)
                            },
                            expression: "providerPopup.ach"
                          }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("p", [_c("b", [_vm._v("Multi-Currency")])]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.providerPopup.multiCurrency,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "multiCurrency", $$v)
                            },
                            expression: "providerPopup.multiCurrency"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("p", [_c("b", [_vm._v("Checks")])]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.providerPopup.checks,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "checks", $$v)
                            },
                            expression: "providerPopup.checks"
                          }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("p", [_c("b", [_vm._v("Assets")])]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.providerPopup.assets,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "assets", $$v)
                            },
                            expression: "providerPopup.assets"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("p", [_c("b", [_vm._v("Remote Deposit")])]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.providerPopup.remoteDeposit,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "remoteDeposit", $$v)
                            },
                            expression: "providerPopup.remoteDeposit"
                          }
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("p", [_c("b", [_vm._v("Debit Cards")])]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("mnb-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.providerPopup.debitCards,
                            callback: function($$v) {
                              _vm.$set(_vm.providerPopup, "debitCards", $$v)
                            },
                            expression: "providerPopup.debitCards"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.openPopup = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Version")]),
      _vm._v(" "),
      _c("th", [_vm._v("Environment")]),
      _vm._v(" "),
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Services")]),
      _vm._v(" "),
      _c("th", [_vm._v("Notes")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
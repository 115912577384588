var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$hasPerm("status-update")
      ? _c(
          "button",
          {
            staticClass: "btn main",
            attrs: {
              disabled:
                _vm.getStatus(_vm.registration.status) == "Active" ||
                _vm.getStatus(_vm.registration.status) == "Activation" ||
                _vm.getStatus(_vm.registration.status) == "Closed"
            },
            on: {
              click: function($event) {
                return _vm.openStatus()
              }
            }
          },
          [_vm._v("Active")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$hasPerm("status-update")
      ? _c(
          "button",
          {
            staticClass: "btn orange-btn",
            attrs: {
              disabled:
                _vm.getStatus(_vm.registration.status) == "Review" ||
                _vm.getStatus(_vm.registration.status) == "Activation" ||
                _vm.getStatus(_vm.registration.status) == "Closed"
            },
            on: {
              click: function($event) {
                return _vm.openStatusReview()
              }
            }
          },
          [_vm._v("Review")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$hasPerm("status-update")
      ? _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: {
              disabled:
                _vm.getStatus(_vm.registration.status) == "Restricted" ||
                _vm.getStatus(_vm.registration.status) == "Activation" ||
                _vm.getStatus(_vm.registration.status) == "Closed"
            },
            on: {
              click: function($event) {
                return _vm.openStatusRestricted()
              }
            }
          },
          [_vm._v("Restricted")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$hasPerm("status-update")
      ? _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: {
              disabled:
                _vm.getStatus(_vm.registration.status) == "Hold" ||
                _vm.getStatus(_vm.registration.status) == "Activation" ||
                _vm.getStatus(_vm.registration.status) == "Closed"
            },
            on: {
              click: function($event) {
                return _vm.openStatusHold()
              }
            }
          },
          [_vm._v("Hold")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$hasPerm("status-update")
      ? _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: {
              disabled:
                _vm.getStatus(_vm.registration.status) == "Dormant" ||
                _vm.getStatus(_vm.registration.status) == "Activation" ||
                _vm.getStatus(_vm.registration.status) == "Closed"
            },
            on: {
              click: function($event) {
                return _vm.openStatusDormant()
              }
            }
          },
          [_vm._v("Dormant")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$hasPerm("status-update")
      ? _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: {
              disabled:
                _vm.getStatus(_vm.registration.status) == "Closed" ||
                _vm.getStatus(_vm.registration.status) == "Active" ||
                _vm.getStatus(_vm.registration.status) == "Review" ||
                _vm.getStatus(_vm.registration.status) == "Restricted" ||
                _vm.getStatus(_vm.registration.status) == "Activation"
            },
            on: {
              click: function($event) {
                return _vm.openStatusClosed()
              }
            }
          },
          [_vm._v("Closed")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticStyle: { margin: "20px 0" } }, [
      _c("div", { staticClass: "cof-box" }, [
        _c("div", { staticClass: "cof-form" }, [
          _c("a", { staticClass: "cof-close" }),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _c("div", { staticClass: "cof-field" }, [
              _c("label", [_vm._v("Date Search")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-multi flex" },
                [
                  _c("date-time-picker", {
                    attrs: {
                      "value-type": "String",
                      "value-format": "yyyy-LL-dd",
                      format: "yyyy-LL-dd",
                      "time-picker": false,
                      "auto-close": true,
                      "min-date": "2020-01-01",
                      "max-date": _vm.today
                    },
                    model: {
                      value: _vm.form.fromDate,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "fromDate", $$v)
                      },
                      expression: "form.fromDate"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("To")]),
                  _vm._v(" "),
                  _c("date-time-picker", {
                    attrs: {
                      "value-type": "String",
                      "value-format": "yyyy-LL-dd",
                      format: "yyyy-LL-dd",
                      "time-picker": false,
                      "auto-close": true,
                      "min-date": "2020-01-01",
                      "max-date": _vm.today
                    },
                    model: {
                      value: _vm.form.toDate,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "toDate", $$v)
                      },
                      expression: "form.toDate"
                    }
                  }),
                  _vm._v("  \n              "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [_vm._v("Search")]
                  ),
                  _vm._v("  \n              "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          return _vm.resetItem()
                        }
                      }
                    },
                    [_vm._v("Clear")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "content-tlist", staticStyle: { margin: "20px 0" } },
      [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.histories, function(item, i) {
              return _c("tr", { key: i }, [
                _c("td", [
                  _vm._v(
                    _vm._s(_vm._f("date")(item.createdAt, "MM/D/YYYY hh:mm A"))
                  )
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(item.addedBy))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(item.beforeStatus))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(item.updateStatus))])
              ])
            })
          ],
          2
        )
      ]
    ),
    _vm._v(" "),
    _c("section", { staticClass: "popup", class: { open: _vm.open } }, [
      _c("div", { staticClass: "pop-inner", style: { maxWidth: "400px" } }, [
        _c("div", { staticClass: "pop-box" }, [
          _c("h2", [_vm._v("Enter Compliance PIN to Continue")]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row" }, [
            _c("div", { staticClass: "pf-field" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pin,
                    expression: "pin"
                  }
                ],
                staticClass: "pin-code",
                attrs: {
                  oninput:
                    "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                  type: "number",
                  maxlength: "4"
                },
                domProps: { value: _vm.pin },
                on: {
                  keyup: _vm.pinInput,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.pin = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row" }, [
            _c(
              "div",
              { staticClass: "pf-field" },
              [_vm.pinFailed ? [_vm._v("Invalid PIN")] : _vm._e()],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row buttons" }, [
            _c(
              "a",
              {
                staticClass: "pop-btn",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.cancel()
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "pop-btn",
                on: {
                  click: function($event) {
                    return _vm.checkPin()
                  }
                }
              },
              [_vm._v("Confirm")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("th", [_vm._v("By")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status Update")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
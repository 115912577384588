var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _vm.registration
      ? _c("section", { staticClass: "content-tabs" }, [
          _c("div", { staticClass: "content-tabs-container" }, [
            _c("div", { staticClass: "cs-tri" }, [
              _c("div", [
                _vm._v("Profile: "),
                _c("strong", [_vm._v(_vm._s(_vm.registration.profileNumber))])
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("Name: "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.registration.type === "Personal"
                        ? _vm.registration.data.firstName +
                            " " +
                            _vm.registration.data.lastName
                        : _vm.registration.data.legal_name
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("Status: "),
                _c("strong", [_vm._v(_vm._s(_vm.status))])
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("Updated: "),
                _c("strong", [
                  _vm._v(_vm._s(_vm._f("date")(_vm.registration.updatedAt)))
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "ctabs first-row" },
              _vm._l(_vm.tabs[1], function(tab) {
                return _c(
                  "li",
                  { key: tab, class: { active: _vm.activeTab === tab } },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            _vm.activeTab = tab
                          }
                        }
                      },
                      [_vm._v(_vm._s(tab))]
                    )
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "ctabs second-row" },
              _vm._l(_vm.tabs[2], function(tab) {
                return _c(
                  "li",
                  { key: tab, class: { active: _vm.activeTab === tab } },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            _vm.activeTab = tab
                          }
                        }
                      },
                      [_vm._v(_vm._s(tab))]
                    )
                  ]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "client-properties active" },
      [
        _vm.activeTab === "Boarding"
          ? _c("registration-data", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "General"
          ? _c("general-info", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Profile"
          ? _c("profile-info", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Documents"
          ? _c("profile-documents", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Notes"
          ? _c("notes", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Emails"
          ? _c("emails", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Access"
          ? _c("access", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Accounts"
          ? _c("accounts", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Compliance"
          ? _c("compliance", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Services"
          ? _c("services", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Settings"
          ? _c("settings", { attrs: { id: _vm.id } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Card Services"
          ? _c("card-services", { attrs: { id: _vm.id } })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$hasPerm("partners-approve") &&
    _vm.partner &&
    _vm.partner.status == "New"
      ? _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                return _vm.deny(_vm.partner.id)
              }
            }
          },
          [_vm._v("Deny")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.partner && _vm.partner.status == "Denied"
      ? _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                return _vm.archive(_vm.partner.id)
              }
            }
          },
          [_vm._v("Archive")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("ul", { staticClass: "cp-rows active" }, [
      _c("li", [
        _vm.partner
          ? _c("ul", { staticClass: "cp-fields" }, [
              _c("li", [
                _c("span", [_vm._v("Partner Since")]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm._f("date")(_vm.partner.createdAt, "LL")))
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [_vm._v("Partner Type")]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm.partner.type))
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [_vm._v("Partner Number")]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.partner.partnerNumber) +
                      "\n          "
                  )
                ])
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
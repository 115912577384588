var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.open
        ? [
            _c("applied-filters", {
              attrs: { filters: _vm.appliedFilters },
              on: {
                clear: function($event) {
                  return _vm.clearFilters()
                },
                remove: function($event) {
                  return _vm.removeFilter($event)
                }
              }
            }),
            _vm._v(" "),
            _c("section", { staticClass: "content-filters" }, [
              _c("div", { staticClass: "cof-box" }, [
                _c("div", { staticClass: "cof-form" }, [
                  _c("div", { staticClass: "cof-row" }, [
                    _c(
                      "div",
                      { staticClass: "cof-field" },
                      [
                        _c("label", [_vm._v("Select Account")]),
                        _vm._v(" "),
                        _c("mnb-select", {
                          attrs: { options: _vm.accountOptions },
                          model: {
                            value: _vm.mainAccount,
                            callback: function($$v) {
                              _vm.mainAccount = $$v
                            },
                            expression: "mainAccount"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.perms && _vm.mainAccount
              ? _c("div", { staticClass: "accordion" }, [
                  _c("section", { staticClass: "content-tlist" }, [
                    _c(
                      "table",
                      { staticClass: "content-table" },
                      [
                        _c("tr", [
                          _vm.account.serviceProviders.provider_name ==
                            "Prime Trust Test" ||
                          _vm.account.serviceProviders.provider_name ==
                            "Prime Trust"
                            ? _c("th", [
                                _vm._v(
                                  "\n                Prime Linked\n              "
                                )
                              ])
                            : _vm.account.serviceProviders.provider_name ==
                                "Solid Test" ||
                              _vm.account.serviceProviders.provider_name ==
                                "Solid"
                            ? _c("th", [
                                _vm._v(
                                  "\n                Solid Linked\n              "
                                )
                              ])
                            : _c("th", [
                                _vm._v(
                                  "\n                Linked\n              "
                                )
                              ]),
                          _vm._v(" "),
                          _vm.account.serviceProviders.provider_name ==
                            "Prime Trust Test" ||
                          _vm.account.serviceProviders.provider_name ==
                            "Prime Trust"
                            ? _c("th", [
                                _vm._v(
                                  "\n                Prime Fees\n              "
                                )
                              ])
                            : _vm.account.serviceProviders.provider_name ==
                                "Solid Test" ||
                              _vm.account.serviceProviders.provider_name ==
                                "Solid"
                            ? _c("th", [
                                _vm._v(
                                  "\n                Solid Fees\n              "
                                )
                              ])
                            : _c("th", [
                                _vm._v("\n                Fees\n              ")
                              ]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Name")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Account")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Type")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Currency")]),
                          _vm._v(" "),
                          _vm.account.serviceProviders.provider_name ==
                            "Prime Trust Test" ||
                          _vm.account.serviceProviders.provider_name ==
                            "Prime Trust"
                            ? _c("th", [_vm._v("Reference Code")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.account.serviceProviders.provider_name ==
                            "Solid Test" ||
                          _vm.account.serviceProviders.provider_name == "Solid"
                            ? _c("th", [_vm._v("Routing Number")])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("th", [_vm._v("Status")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Available")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Balance")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Account Date")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Options")])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _vm.account.serviceProviders.provider_name ==
                            "Prime Trust Test" ||
                          _vm.account.serviceProviders.provider_name ==
                            "Prime Trust"
                            ? _c("td", [
                                _c("span", [_vm._v("Linked")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.account.linked,
                                      expression: "account.linked"
                                    }
                                  ],
                                  staticClass: "fee-checkbox",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.account.linked)
                                      ? _vm._i(_vm.account.linked, null) > -1
                                      : _vm.account.linked
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.account.linked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.account,
                                                "linked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.account,
                                                "linked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.account, "linked", $$c)
                                        }
                                      },
                                      function($event) {
                                        return _vm.checkLinked(_vm.account.id)
                                      }
                                    ]
                                  }
                                })
                              ])
                            : _vm.account.serviceProviders.provider_name ==
                                "Solid Test" ||
                              _vm.account.serviceProviders.provider_name ==
                                "Solid"
                            ? _c("td", [
                                _c("span", [_vm._v("Linked")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.account.solidLinked,
                                      expression: "account.solidLinked"
                                    }
                                  ],
                                  staticClass: "fee-checkbox",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.account.solidLinked
                                    )
                                      ? _vm._i(_vm.account.solidLinked, null) >
                                        -1
                                      : _vm.account.solidLinked
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.account.solidLinked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.account,
                                                "solidLinked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.account,
                                                "solidLinked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.account,
                                            "solidLinked",
                                            $$c
                                          )
                                        }
                                      },
                                      function($event) {
                                        return _vm.checkSolidLinked(
                                          _vm.account.id
                                        )
                                      }
                                    ]
                                  }
                                })
                              ])
                            : _c("td", [
                                _c("span", [_vm._v("Linked")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.account.linked,
                                      expression: "account.linked"
                                    }
                                  ],
                                  staticClass: "fee-checkbox",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.account.linked)
                                      ? _vm._i(_vm.account.linked, null) > -1
                                      : _vm.account.linked
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.account.linked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.account,
                                                "linked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.account,
                                                "linked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.account, "linked", $$c)
                                        }
                                      },
                                      function($event) {
                                        return _vm.checkLinked(_vm.account.id)
                                      }
                                    ]
                                  }
                                })
                              ]),
                          _vm._v(" "),
                          _vm.account.serviceProviders.provider_name ==
                            "Prime Trust Test" ||
                          _vm.account.serviceProviders.provider_name ==
                            "Prime Trust"
                            ? _c("td", [
                                _c("span", [_vm._v("Fees")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.account.fees,
                                      expression: "account.fees"
                                    }
                                  ],
                                  staticClass: "fee-checkbox",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.account.fees)
                                      ? _vm._i(_vm.account.fees, null) > -1
                                      : _vm.account.fees
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.account.fees,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.account,
                                                "fees",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.account,
                                                "fees",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.account, "fees", $$c)
                                        }
                                      },
                                      function($event) {
                                        return _vm.checkFees(_vm.account.id)
                                      }
                                    ]
                                  }
                                })
                              ])
                            : _vm.account.serviceProviders.provider_name ==
                                "Solid Test" ||
                              _vm.account.serviceProviders.provider_name ==
                                "Solid"
                            ? _c("td", [
                                _c("span", [_vm._v("Fees")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.account.solidFees,
                                      expression: "account.solidFees"
                                    }
                                  ],
                                  staticClass: "fee-checkbox",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.account.solidFees
                                    )
                                      ? _vm._i(_vm.account.solidFees, null) > -1
                                      : _vm.account.solidFees
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.account.solidFees,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.account,
                                                "solidFees",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.account,
                                                "solidFees",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.account,
                                            "solidFees",
                                            $$c
                                          )
                                        }
                                      },
                                      function($event) {
                                        return _vm.checkSolidFees(
                                          _vm.account.id
                                        )
                                      }
                                    ]
                                  }
                                })
                              ])
                            : _c("td", [
                                _c("span", [_vm._v("Fees")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.account.fees,
                                      expression: "account.fees"
                                    }
                                  ],
                                  staticClass: "fee-checkbox",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.account.fees)
                                      ? _vm._i(_vm.account.fees, null) > -1
                                      : _vm.account.fees
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.account.fees,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.account,
                                                "fees",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.account,
                                                "fees",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.account, "fees", $$c)
                                        }
                                      },
                                      function($event) {
                                        return _vm.checkFees(_vm.account.id)
                                      }
                                    ]
                                  }
                                })
                              ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v("Name")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.account.accountName) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v("Account ID")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.account.accountNumber) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v("Account Type")]),
                            _vm._v(
                              _vm._s(_vm.typeText[_vm.account.accountType])
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v("Currency")]),
                            _vm._v(_vm._s(_vm.account.currency))
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("span", [_vm._v("Reference Code")]),
                              _vm._v(" "),
                              _vm.account.serviceProviders.provider_name ==
                                "Prime Trust Test" ||
                              _vm.account.serviceProviders.provider_name ==
                                "Prime Trust"
                                ? [
                                    _vm._v(
                                      _vm._s(_vm.account.QPaymentReference)
                                    )
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.account.serviceProviders.provider_name ==
                                "Solid Test" ||
                              _vm.account.serviceProviders.provider_name ==
                                "Solid"
                                ? [_vm._v(_vm._s(_vm.account.routingNumber))]
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v("Account Status")]),
                            _c(
                              "em",
                              {
                                class:
                                  _vm.statusColorClasses[
                                    _vm.getStatus(_vm.account.accountStatus)
                                  ]
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.getStatus(_vm.account.accountStatus)
                                    )
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v("Available")]),
                            _vm._v(
                              _vm._s(
                                _vm._f("balance")(
                                  _vm.account.systemAvailableBalance
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v("Balance")]),
                            _vm._v(
                              _vm._s(
                                _vm._f("balance")(
                                  _vm.account.systemTotalBalance
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v("Account Date")]),
                            _vm._v(
                              _vm._s(
                                _vm._f("date")(
                                  _vm.account.createdAt,
                                  "MM/D/YYYY"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("span", [_vm._v("Options")]),
                              _vm._v(" "),
                              _vm.systemMaintenanceStatus
                                ? [
                                    _vm.account.accountStatus == "Active"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "action-button",
                                            on: {
                                              click: function($event) {
                                                return _vm.generateQReference(
                                                  _vm.account
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Q Reference")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.account.accountStatus == "Active"
                                      ? _c(
                                          "button",
                                          { staticClass: "action-button save" },
                                          [_vm._v("Account Name")]
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        !_vm.featureFundsStatus ? [_vm._m(0)] : _vm._e(),
                        _vm._v(" "),
                        _vm.account.serviceProviders.multiCurrency == 1
                          ? [
                              _vm.getCurrencyName("AUD") != null
                                ? [
                                    !_vm.featureFundsStatus
                                      ? [
                                          _vm.getCurrencyStatus(
                                            _vm.account.fundsCurrencies,
                                            "AUD"
                                          ) != null
                                            ? [
                                                _c("tr", [
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _vm._m(1),
                                                  _vm._v(" "),
                                                  _vm._m(2),
                                                  _vm._v(" "),
                                                  _vm._m(3),
                                                  _vm._v(" "),
                                                  _vm._m(4),
                                                  _vm._v(" "),
                                                  _vm._m(5),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Account Status")
                                                      ]),
                                                      _vm._v(" "),
                                                      !_vm.featureFundsStatus
                                                        ? [
                                                            _vm._v(
                                                              "\n                          Disabled\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "AUD"
                                                          ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getCurrencyStatus(
                                                                    _vm.account
                                                                      .fundsCurrencies,
                                                                    "AUD"
                                                                  )
                                                                    ? "Enabled"
                                                                    : "Disabled"
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Available")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "AUD"
                                                      ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "AUD"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsAvailableBalance(
                                                                      _vm.account,
                                                                      "AUD"
                                                                    )
                                                                      ? _vm.getSystemFundsAvailableBalance(
                                                                          _vm.account,
                                                                          "AUD"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "AUD"
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "AUD"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsAvailableBalance(
                                                                      _vm.account,
                                                                      "AUD"
                                                                    )
                                                                      ? _vm.getSystemFundsAvailableBalance(
                                                                          _vm.account,
                                                                          "AUD"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Balance")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "AUD"
                                                      ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "AUD"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsTotalBalance(
                                                                      _vm.account,
                                                                      "AUD"
                                                                    )
                                                                      ? _vm.getSystemFundsTotalBalance(
                                                                          _vm.account,
                                                                          "AUD"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "AUD"
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "AUD"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsTotalBalance(
                                                                      _vm.account,
                                                                      "AUD"
                                                                    )
                                                                      ? _vm.getSystemFundsTotalBalance(
                                                                          _vm.account,
                                                                          "AUD"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._m(6),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Options")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.featureFundsStatus
                                                        ? [
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "AUD"
                                                            ) == null
                                                              ? [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-success",
                                                                      attrs: {
                                                                        disabled: !_vm.getCurrencyName(
                                                                          "AUD"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.activateCurrency(
                                                                            _vm
                                                                              .account
                                                                              .id,
                                                                            "AUD"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Activate"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              : [
                                                                  _vm.getCurrencyStatus(
                                                                    _vm.account
                                                                      .fundsCurrencies,
                                                                    "AUD"
                                                                  )
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-secondary",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.disableCurrency(
                                                                                _vm
                                                                                  .account
                                                                                  .fundsCurrencies,
                                                                                "AUD"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Disable"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-success",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.enableCurrency(
                                                                                _vm
                                                                                  .account
                                                                                  .fundsCurrencies,
                                                                                "AUD"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Enable"
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ])
                                              ]
                                            : _vm._e()
                                        ]
                                      : [
                                          _c("tr", [
                                            _c("td"),
                                            _vm._v(" "),
                                            _c("td"),
                                            _vm._v(" "),
                                            _vm._m(7),
                                            _vm._v(" "),
                                            _vm._m(8),
                                            _vm._v(" "),
                                            _vm._m(9),
                                            _vm._v(" "),
                                            _vm._m(10),
                                            _vm._v(" "),
                                            _vm._m(11),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [
                                                  _vm._v("Account Status")
                                                ]),
                                                _vm._v(" "),
                                                !_vm.featureFundsStatus
                                                  ? [
                                                      _vm._v(
                                                        "\n                        Disabled\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "AUD"
                                                    ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "AUD"
                                                            )
                                                              ? "Enabled"
                                                              : "Disabled"
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [
                                                  _vm._v("Available")
                                                ]),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "AUD"
                                                ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "AUD"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsAvailableBalance(
                                                                _vm.account,
                                                                "AUD"
                                                              )
                                                                ? _vm.getSystemFundsAvailableBalance(
                                                                    _vm.account,
                                                                    "AUD"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "AUD"
                                                    )
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "AUD"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsAvailableBalance(
                                                                _vm.account,
                                                                "AUD"
                                                              )
                                                                ? _vm.getSystemFundsAvailableBalance(
                                                                    _vm.account,
                                                                    "AUD"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Balance")]),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "AUD"
                                                ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "AUD"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsTotalBalance(
                                                                _vm.account,
                                                                "AUD"
                                                              )
                                                                ? _vm.getSystemFundsTotalBalance(
                                                                    _vm.account,
                                                                    "AUD"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "AUD"
                                                    )
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "AUD"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsTotalBalance(
                                                                _vm.account,
                                                                "AUD"
                                                              )
                                                                ? _vm.getSystemFundsTotalBalance(
                                                                    _vm.account,
                                                                    "AUD"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _vm._m(12),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Options")]),
                                                _vm._v(" "),
                                                _vm.featureFundsStatus
                                                  ? [
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "AUD"
                                                      ) == null
                                                        ? [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-success",
                                                                attrs: {
                                                                  disabled: !_vm.getCurrencyName(
                                                                    "AUD"
                                                                  )
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.activateCurrency(
                                                                      _vm
                                                                        .account
                                                                        .id,
                                                                      "AUD"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Activate"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : [
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "AUD"
                                                            )
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-secondary",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.disableCurrency(
                                                                          _vm
                                                                            .account
                                                                            .fundsCurrencies,
                                                                          "AUD"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Disable"
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-success",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.enableCurrency(
                                                                          _vm
                                                                            .account
                                                                            .fundsCurrencies,
                                                                          "AUD"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Enable"
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.getCurrencyName("CAD") != null
                                ? [
                                    !_vm.featureFundsStatus
                                      ? [
                                          _vm.getCurrencyStatus(
                                            _vm.account.fundsCurrencies,
                                            "CAD"
                                          ) != null
                                            ? [
                                                _c("tr", [
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _vm._m(13),
                                                  _vm._v(" "),
                                                  _vm._m(14),
                                                  _vm._v(" "),
                                                  _vm._m(15),
                                                  _vm._v(" "),
                                                  _vm._m(16),
                                                  _vm._v(" "),
                                                  _vm._m(17),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Account Status")
                                                      ]),
                                                      _vm._v(" "),
                                                      !_vm.featureFundsStatus
                                                        ? [
                                                            _vm._v(
                                                              "\n                          Disabled\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "CAD"
                                                          ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getCurrencyStatus(
                                                                    _vm.account
                                                                      .fundsCurrencies,
                                                                    "CAD"
                                                                  )
                                                                    ? "Enabled"
                                                                    : "Disabled"
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Available")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "CAD"
                                                      ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "CAD"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsAvailableBalance(
                                                                      _vm.account,
                                                                      "CAD"
                                                                    )
                                                                      ? _vm.getSystemFundsAvailableBalance(
                                                                          _vm.account,
                                                                          "CAD"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "CAD"
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "CAD"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsAvailableBalance(
                                                                      _vm.account,
                                                                      "CAD"
                                                                    )
                                                                      ? _vm.getSystemFundsAvailableBalance(
                                                                          _vm.account,
                                                                          "CAD"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Balance")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "CAD"
                                                      ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "CAD"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsTotalBalance(
                                                                      _vm.account,
                                                                      "CAD"
                                                                    )
                                                                      ? _vm.getSystemFundsTotalBalance(
                                                                          _vm.account,
                                                                          "CAD"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "CAD"
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "CAD"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsTotalBalance(
                                                                      _vm.account,
                                                                      "CAD"
                                                                    )
                                                                      ? _vm.getSystemFundsTotalBalance(
                                                                          _vm.account,
                                                                          "CAD"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._m(18),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Options")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.featureFundsStatus
                                                        ? [
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "CAD"
                                                            ) == null
                                                              ? [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-success",
                                                                      attrs: {
                                                                        disabled: !_vm.getCurrencyName(
                                                                          "CAD"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.activateCurrency(
                                                                            _vm
                                                                              .account
                                                                              .id,
                                                                            "AUD"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Activate"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              : [
                                                                  _vm.getCurrencyStatus(
                                                                    _vm.account
                                                                      .fundsCurrencies,
                                                                    "CAD"
                                                                  )
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-secondary",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.disableCurrency(
                                                                                _vm
                                                                                  .account
                                                                                  .fundsCurrencies,
                                                                                "CAD"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Disable"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-success",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.enableCurrency(
                                                                                _vm
                                                                                  .account
                                                                                  .fundsCurrencies,
                                                                                "CAD"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Enable"
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ])
                                              ]
                                            : _vm._e()
                                        ]
                                      : [
                                          _c("tr", [
                                            _c("td"),
                                            _vm._v(" "),
                                            _c("td"),
                                            _vm._v(" "),
                                            _vm._m(19),
                                            _vm._v(" "),
                                            _vm._m(20),
                                            _vm._v(" "),
                                            _vm._m(21),
                                            _vm._v(" "),
                                            _vm._m(22),
                                            _vm._v(" "),
                                            _vm._m(23),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [
                                                  _vm._v("Account Status")
                                                ]),
                                                _vm._v(" "),
                                                !_vm.featureFundsStatus
                                                  ? [
                                                      _vm._v(
                                                        "\n                        Disabled\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "CAD"
                                                    ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "CAD"
                                                            )
                                                              ? "Enabled"
                                                              : "Disabled"
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [
                                                  _vm._v("Available")
                                                ]),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "CAD"
                                                ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "CAD"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsAvailableBalance(
                                                                _vm.account,
                                                                "CAD"
                                                              )
                                                                ? _vm.getSystemFundsAvailableBalance(
                                                                    _vm.account,
                                                                    "CAD"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "CAD"
                                                    )
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "CAD"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsAvailableBalance(
                                                                _vm.account,
                                                                "CAD"
                                                              )
                                                                ? _vm.getSystemFundsAvailableBalance(
                                                                    _vm.account,
                                                                    "CAD"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Balance")]),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "CAD"
                                                ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "CAD"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsTotalBalance(
                                                                _vm.account,
                                                                "CAD"
                                                              )
                                                                ? _vm.getSystemFundsTotalBalance(
                                                                    _vm.account,
                                                                    "CAD"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "CAD"
                                                    )
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "CAD"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsTotalBalance(
                                                                _vm.account,
                                                                "CAD"
                                                              )
                                                                ? _vm.getSystemFundsTotalBalance(
                                                                    _vm.account,
                                                                    "CAD"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _vm._m(24),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Options")]),
                                                _vm._v(" "),
                                                _vm.featureFundsStatus
                                                  ? [
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "CAD"
                                                      ) == null
                                                        ? [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-success",
                                                                attrs: {
                                                                  disabled: !_vm.getCurrencyName(
                                                                    "CAD"
                                                                  )
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.activateCurrency(
                                                                      _vm
                                                                        .account
                                                                        .id,
                                                                      "AUD"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Activate"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : [
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "CAD"
                                                            )
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-secondary",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.disableCurrency(
                                                                          _vm
                                                                            .account
                                                                            .fundsCurrencies,
                                                                          "CAD"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Disable"
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-success",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.enableCurrency(
                                                                          _vm
                                                                            .account
                                                                            .fundsCurrencies,
                                                                          "CAD"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Enable"
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.getCurrencyName("EUR") != null
                                ? [
                                    !_vm.featureFundsStatus
                                      ? [
                                          _vm.getCurrencyStatus(
                                            _vm.account.fundsCurrencies,
                                            "EUR"
                                          ) != null
                                            ? [
                                                _c("tr", [
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _vm._m(25),
                                                  _vm._v(" "),
                                                  _vm._m(26),
                                                  _vm._v(" "),
                                                  _vm._m(27),
                                                  _vm._v(" "),
                                                  _vm._m(28),
                                                  _vm._v(" "),
                                                  _vm._m(29),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Account Status")
                                                      ]),
                                                      _vm._v(" "),
                                                      !_vm.featureFundsStatus
                                                        ? [
                                                            _vm._v(
                                                              "\n                          Disabled\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "EUR"
                                                          ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getCurrencyStatus(
                                                                    _vm.account
                                                                      .fundsCurrencies,
                                                                    "EUR"
                                                                  )
                                                                    ? "Enabled"
                                                                    : "Disabled"
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Available")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "EUR"
                                                      ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "EUR"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsAvailableBalance(
                                                                      _vm.account,
                                                                      "EUR"
                                                                    )
                                                                      ? _vm.getSystemFundsAvailableBalance(
                                                                          _vm.account,
                                                                          "EUR"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "EUR"
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "EUR"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsAvailableBalance(
                                                                      _vm.account,
                                                                      "EUR"
                                                                    )
                                                                      ? _vm.getSystemFundsAvailableBalance(
                                                                          _vm.account,
                                                                          "EUR"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Balance")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "EUR"
                                                      ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "EUR"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsTotalBalance(
                                                                      _vm.account,
                                                                      "EUR"
                                                                    )
                                                                      ? _vm.getSystemFundsTotalBalance(
                                                                          _vm.account,
                                                                          "EUR"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "EUR"
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "EUR"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsTotalBalance(
                                                                      _vm.account,
                                                                      "EUR"
                                                                    )
                                                                      ? _vm.getSystemFundsTotalBalance(
                                                                          _vm.account,
                                                                          "EUR"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._m(30),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Options")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.featureFundsStatus
                                                        ? [
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "EUR"
                                                            ) == null
                                                              ? [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-success",
                                                                      attrs: {
                                                                        disabled: !_vm.getCurrencyName(
                                                                          "EUR"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.activateCurrency(
                                                                            _vm
                                                                              .account
                                                                              .id,
                                                                            "EUR"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Activate"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              : [
                                                                  _vm.getCurrencyStatus(
                                                                    _vm.account
                                                                      .fundsCurrencies,
                                                                    "EUR"
                                                                  )
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-secondary",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.disableCurrency(
                                                                                _vm
                                                                                  .account
                                                                                  .fundsCurrencies,
                                                                                "EUR"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Disable"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-success",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.enableCurrency(
                                                                                _vm
                                                                                  .account
                                                                                  .fundsCurrencies,
                                                                                "EUR"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Enable"
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ])
                                              ]
                                            : _vm._e()
                                        ]
                                      : [
                                          _c("tr", [
                                            _c("td"),
                                            _vm._v(" "),
                                            _c("td"),
                                            _vm._v(" "),
                                            _vm._m(31),
                                            _vm._v(" "),
                                            _vm._m(32),
                                            _vm._v(" "),
                                            _vm._m(33),
                                            _vm._v(" "),
                                            _vm._m(34),
                                            _vm._v(" "),
                                            _vm._m(35),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [
                                                  _vm._v("Account Status")
                                                ]),
                                                _vm._v(" "),
                                                !_vm.featureFundsStatus
                                                  ? [
                                                      _vm._v(
                                                        "\n                        Disabled\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "EUR"
                                                    ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "EUR"
                                                            )
                                                              ? "Enabled"
                                                              : "Disabled"
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [
                                                  _vm._v("Available")
                                                ]),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "EUR"
                                                ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "EUR"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsAvailableBalance(
                                                                _vm.account,
                                                                "EUR"
                                                              )
                                                                ? _vm.getSystemFundsAvailableBalance(
                                                                    _vm.account,
                                                                    "EUR"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "EUR"
                                                    )
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "EUR"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsAvailableBalance(
                                                                _vm.account,
                                                                "EUR"
                                                              )
                                                                ? _vm.getSystemFundsAvailableBalance(
                                                                    _vm.account,
                                                                    "EUR"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Balance")]),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "EUR"
                                                ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "EUR"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsTotalBalance(
                                                                _vm.account,
                                                                "EUR"
                                                              )
                                                                ? _vm.getSystemFundsTotalBalance(
                                                                    _vm.account,
                                                                    "EUR"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "EUR"
                                                    )
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "EUR"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsTotalBalance(
                                                                _vm.account,
                                                                "EUR"
                                                              )
                                                                ? _vm.getSystemFundsTotalBalance(
                                                                    _vm.account,
                                                                    "EUR"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _vm._m(36),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Options")]),
                                                _vm._v(" "),
                                                _vm.featureFundsStatus
                                                  ? [
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "EUR"
                                                      ) == null
                                                        ? [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-success",
                                                                attrs: {
                                                                  disabled: !_vm.getCurrencyName(
                                                                    "EUR"
                                                                  )
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.activateCurrency(
                                                                      _vm
                                                                        .account
                                                                        .id,
                                                                      "EUR"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Activate"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : [
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "EUR"
                                                            )
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-secondary",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.disableCurrency(
                                                                          _vm
                                                                            .account
                                                                            .fundsCurrencies,
                                                                          "EUR"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Disable"
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-success",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.enableCurrency(
                                                                          _vm
                                                                            .account
                                                                            .fundsCurrencies,
                                                                          "EUR"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Enable"
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.getCurrencyName("GBP") != null
                                ? [
                                    !_vm.featureFundsStatus
                                      ? [
                                          _vm.getCurrencyStatus(
                                            _vm.account.fundsCurrencies,
                                            "GBP"
                                          ) != null
                                            ? [
                                                _c("tr", [
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _vm._m(37),
                                                  _vm._v(" "),
                                                  _vm._m(38),
                                                  _vm._v(" "),
                                                  _vm._m(39),
                                                  _vm._v(" "),
                                                  _vm._m(40),
                                                  _vm._v(" "),
                                                  _vm._m(41),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Account Status")
                                                      ]),
                                                      _vm._v(" "),
                                                      !_vm.featureFundsStatus
                                                        ? [
                                                            _vm._v(
                                                              "\n                          Disabled\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "GBP"
                                                          ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getCurrencyStatus(
                                                                    _vm.account
                                                                      .fundsCurrencies,
                                                                    "GBP"
                                                                  )
                                                                    ? "Enabled"
                                                                    : "Disabled"
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Available")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "GBP"
                                                      ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "GBP"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsAvailableBalance(
                                                                      _vm.account,
                                                                      "GBP"
                                                                    )
                                                                      ? _vm.getSystemFundsAvailableBalance(
                                                                          _vm.account,
                                                                          "GBP"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "GBP"
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "GBP"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsAvailableBalance(
                                                                      _vm.account,
                                                                      "GBP"
                                                                    )
                                                                      ? _vm.getSystemFundsAvailableBalance(
                                                                          _vm.account,
                                                                          "GBP"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Balance")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "GBP"
                                                      ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "GBP"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsTotalBalance(
                                                                      _vm.account,
                                                                      "GBP"
                                                                    )
                                                                      ? _vm.getSystemFundsTotalBalance(
                                                                          _vm.account,
                                                                          "GBP"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "GBP"
                                                      )
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "GBP"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsTotalBalance(
                                                                      _vm.account,
                                                                      "GBP"
                                                                    )
                                                                      ? _vm.getSystemFundsTotalBalance(
                                                                          _vm.account,
                                                                          "GBP"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._m(42),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Options")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.featureFundsStatus
                                                        ? [
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "GBP"
                                                            ) == null
                                                              ? [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-success",
                                                                      attrs: {
                                                                        disabled: !_vm.getCurrencyName(
                                                                          "GBP"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.activateCurrency(
                                                                            _vm
                                                                              .account
                                                                              .id,
                                                                            "GBP"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Activate"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              : [
                                                                  _vm.getCurrencyStatus(
                                                                    _vm.account
                                                                      .fundsCurrencies,
                                                                    "GBP"
                                                                  )
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-secondary",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.disableCurrency(
                                                                                _vm
                                                                                  .account
                                                                                  .fundsCurrencies,
                                                                                "GBP"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Disable"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-success",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.enableCurrency(
                                                                                _vm
                                                                                  .account
                                                                                  .fundsCurrencies,
                                                                                "GBP"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Enable"
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ])
                                              ]
                                            : _vm._e()
                                        ]
                                      : [
                                          _c("tr", [
                                            _c("td"),
                                            _vm._v(" "),
                                            _c("td"),
                                            _vm._v(" "),
                                            _vm._m(43),
                                            _vm._v(" "),
                                            _vm._m(44),
                                            _vm._v(" "),
                                            _vm._m(45),
                                            _vm._v(" "),
                                            _vm._m(46),
                                            _vm._v(" "),
                                            _vm._m(47),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [
                                                  _vm._v("Account Status")
                                                ]),
                                                _vm._v(" "),
                                                !_vm.featureFundsStatus
                                                  ? [
                                                      _vm._v(
                                                        "\n                        Disabled\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "GBP"
                                                    ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "GBP"
                                                            )
                                                              ? "Enabled"
                                                              : "Disabled"
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [
                                                  _vm._v("Available")
                                                ]),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "GBP"
                                                ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "GBP"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsAvailableBalance(
                                                                _vm.account,
                                                                "GBP"
                                                              )
                                                                ? _vm.getSystemFundsAvailableBalance(
                                                                    _vm.account,
                                                                    "GBP"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "GBP"
                                                    )
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "GBP"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsAvailableBalance(
                                                                _vm.account,
                                                                "GBP"
                                                              )
                                                                ? _vm.getSystemFundsAvailableBalance(
                                                                    _vm.account,
                                                                    "GBP"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Balance")]),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "GBP"
                                                ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "GBP"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsTotalBalance(
                                                                _vm.account,
                                                                "GBP"
                                                              )
                                                                ? _vm.getSystemFundsTotalBalance(
                                                                    _vm.account,
                                                                    "GBP"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "GBP"
                                                )
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "GBP"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsTotalBalance(
                                                                _vm.account,
                                                                "GBP"
                                                              )
                                                                ? _vm.getSystemFundsTotalBalance(
                                                                    _vm.account,
                                                                    "GBP"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _vm._m(48),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Options")]),
                                                _vm._v(" "),
                                                _vm.featureFundsStatus
                                                  ? [
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "GBP"
                                                      ) == null
                                                        ? [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-success",
                                                                attrs: {
                                                                  disabled: !_vm.getCurrencyName(
                                                                    "GBP"
                                                                  )
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.activateCurrency(
                                                                      _vm
                                                                        .account
                                                                        .id,
                                                                      "GBP"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Activate"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : [
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "GBP"
                                                            )
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-secondary",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.disableCurrency(
                                                                          _vm
                                                                            .account
                                                                            .fundsCurrencies,
                                                                          "GBP"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Disable"
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-success",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.enableCurrency(
                                                                          _vm
                                                                            .account
                                                                            .fundsCurrencies,
                                                                          "GBP"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Enable"
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.getCurrencyName("JPY") != null
                                ? [
                                    !_vm.featureFundsStatus
                                      ? [
                                          _vm.getCurrencyStatus(
                                            _vm.account.fundsCurrencies,
                                            "JPY"
                                          ) != null
                                            ? [
                                                _c("tr", [
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _vm._m(49),
                                                  _vm._v(" "),
                                                  _vm._m(50),
                                                  _vm._v(" "),
                                                  _vm._m(51),
                                                  _vm._v(" "),
                                                  _vm._m(52),
                                                  _vm._v(" "),
                                                  _vm._m(53),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Account Status")
                                                      ]),
                                                      _vm._v(" "),
                                                      !_vm.featureFundsStatus
                                                        ? [
                                                            _vm._v(
                                                              "\n                          Disabled\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "JPY"
                                                          ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getCurrencyStatus(
                                                                    _vm.account
                                                                      .fundsCurrencies,
                                                                    "JPY"
                                                                  )
                                                                    ? "Enabled"
                                                                    : "Disabled"
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Available")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "JPY"
                                                      ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "JPY"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsAvailableBalance(
                                                                      _vm.account,
                                                                      "JPY"
                                                                    )
                                                                      ? _vm.getSystemFundsAvailableBalance(
                                                                          _vm.account,
                                                                          "JPY"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "JPY"
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "JPY"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsAvailableBalance(
                                                                      _vm.account,
                                                                      "JPY"
                                                                    )
                                                                      ? _vm.getSystemFundsAvailableBalance(
                                                                          _vm.account,
                                                                          "JPY"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Balance")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "JPY"
                                                      ) != null
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "JPY"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsTotalBalance(
                                                                      _vm.account,
                                                                      "JPY"
                                                                    )
                                                                      ? _vm.getSystemFundsTotalBalance(
                                                                          _vm.account,
                                                                          "JPY"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm.getCurrencyStatus(
                                                            _vm.account
                                                              .fundsCurrencies,
                                                            "JPY"
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.getFundsCurrencySymbol(
                                                                    _vm.account,
                                                                    "JPY"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getSystemFundsTotalBalance(
                                                                      _vm.account,
                                                                      "JPY"
                                                                    )
                                                                      ? _vm.getSystemFundsTotalBalance(
                                                                          _vm.account,
                                                                          "JPY"
                                                                        )
                                                                      : ""
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._m(54),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Options")
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.featureFundsStatus
                                                        ? [
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "JPY"
                                                            ) == null
                                                              ? [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-success",
                                                                      attrs: {
                                                                        disabled: !_vm.getCurrencyName(
                                                                          "JPY"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.activateCurrency(
                                                                            _vm
                                                                              .account
                                                                              .id,
                                                                            "JPY"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Activate"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              : [
                                                                  _vm.getCurrencyStatus(
                                                                    _vm.account
                                                                      .fundsCurrencies,
                                                                    "JPY"
                                                                  )
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-secondary",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.disableCurrency(
                                                                                _vm
                                                                                  .account
                                                                                  .fundsCurrencies,
                                                                                "JPY"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Disable"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-success",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.enableCurrency(
                                                                                _vm
                                                                                  .account
                                                                                  .fundsCurrencies,
                                                                                "JPY"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Enable"
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ])
                                              ]
                                            : _vm._e()
                                        ]
                                      : [
                                          _c("tr", [
                                            _c("td"),
                                            _vm._v(" "),
                                            _c("td"),
                                            _vm._v(" "),
                                            _vm._m(55),
                                            _vm._v(" "),
                                            _vm._m(56),
                                            _vm._v(" "),
                                            _vm._m(57),
                                            _vm._v(" "),
                                            _vm._m(58),
                                            _vm._v(" "),
                                            _vm._m(59),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [
                                                  _vm._v("Account Status")
                                                ]),
                                                _vm._v(" "),
                                                !_vm.featureFundsStatus
                                                  ? [
                                                      _vm._v(
                                                        "\n                        Disabled\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "JPY"
                                                    ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "JPY"
                                                            )
                                                              ? "Enabled"
                                                              : "Disabled"
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [
                                                  _vm._v("Available")
                                                ]),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "JPY"
                                                ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "JPY"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsAvailableBalance(
                                                                _vm.account,
                                                                "JPY"
                                                              )
                                                                ? _vm.getSystemFundsAvailableBalance(
                                                                    _vm.account,
                                                                    "JPY"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "JPY"
                                                    )
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "JPY"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsAvailableBalance(
                                                                _vm.account,
                                                                "JPY"
                                                              )
                                                                ? _vm.getSystemFundsAvailableBalance(
                                                                    _vm.account,
                                                                    "JPY"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Balance")]),
                                                _vm._v(" "),
                                                _vm.getCurrencyStatus(
                                                  _vm.account.fundsCurrencies,
                                                  "JPY"
                                                ) != null
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "JPY"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsTotalBalance(
                                                                _vm.account,
                                                                "JPY"
                                                              )
                                                                ? _vm.getSystemFundsTotalBalance(
                                                                    _vm.account,
                                                                    "JPY"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm.getCurrencyStatus(
                                                      _vm.account
                                                        .fundsCurrencies,
                                                      "JPY"
                                                    )
                                                  ? [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.getFundsCurrencySymbol(
                                                              _vm.account,
                                                              "JPY"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "balance_without_symbol"
                                                            )(
                                                              _vm.getSystemFundsTotalBalance(
                                                                _vm.account,
                                                                "JPY"
                                                              )
                                                                ? _vm.getSystemFundsTotalBalance(
                                                                    _vm.account,
                                                                    "JPY"
                                                                  )
                                                                : ""
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _vm._m(60),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Options")]),
                                                _vm._v(" "),
                                                _vm.featureFundsStatus
                                                  ? [
                                                      _vm.getCurrencyStatus(
                                                        _vm.account
                                                          .fundsCurrencies,
                                                        "JPY"
                                                      ) == null
                                                        ? [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-success",
                                                                attrs: {
                                                                  disabled: !_vm.getCurrencyName(
                                                                    "JPY"
                                                                  )
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.activateCurrency(
                                                                      _vm
                                                                        .account
                                                                        .id,
                                                                      "JPY"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Activate"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : [
                                                            _vm.getCurrencyStatus(
                                                              _vm.account
                                                                .fundsCurrencies,
                                                              "JPY"
                                                            )
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-secondary",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.disableCurrency(
                                                                          _vm
                                                                            .account
                                                                            .fundsCurrencies,
                                                                          "JPY"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Disable"
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-success",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.enableCurrency(
                                                                          _vm
                                                                            .account
                                                                            .fundsCurrencies,
                                                                          "JPY"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Enable"
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                  ]
                                : _vm._e()
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.account.serviceProviders.provider_name == "Solid" ||
                        _vm.account.serviceProviders.provider_name ==
                          "Solid Test"
                          ? void 0
                          : _vm.account.serviceProviders.assets
                          ? [
                              _vm.featureStatus
                                ? _c("tr", [
                                    _c("td"),
                                    _vm._v(" "),
                                    _c("td"),
                                    _vm._v(" "),
                                    _vm._m(61)
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.featureStatus
                                ? _c("tr", [
                                    _c("td"),
                                    _vm._v(" "),
                                    _vm._m(62),
                                    _vm._v(" "),
                                    _c("td", { attrs: { colspan: "9" } }, [
                                      _vm._v(
                                        "\n                  This feature is not enabled. Please contact system administration in order to have it enabled. Feature - Digital Assets\n                "
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.featureStatus
                                ? [
                                    _vm._m(63),
                                    _vm._v(" "),
                                    _vm._l(_vm.assetsList, function(item) {
                                      return _c("tr", [
                                        _c("td"),
                                        _vm._v(" "),
                                        _c("td"),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "1" } }, [
                                          _c("span", [_vm._v("Currency")]),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item) +
                                              "\n                  "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "1" } }, [
                                          _c("span", [_vm._v("Name")]),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.getCurrencyName(item)
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "1" } }, [
                                          _c("span", [_vm._v("Tag Required")]),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.getTagRequired(item)) +
                                              "\n                  "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "1" } }, [
                                          _c("span", [_vm._v("Tag")]),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.getTag(_vm.account, item)
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "2" } }, [
                                          _c("span", [
                                            _vm._v("Wallet Address")
                                          ]),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.getIncomingAssetWalletAddress(
                                                  _vm.account,
                                                  item
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c("span", [
                                            _vm._v("Available Balance")
                                          ]),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.getAssetsSystemAssetAvailableBalance(
                                                  _vm.account,
                                                  item
                                                )
                                                  ? parseFloat(
                                                      _vm.getAssetsSystemAssetAvailableBalance(
                                                        _vm.account,
                                                        item
                                                      )
                                                    )
                                                  : ""
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "2" } }, [
                                          _c("span", [_vm._v("Balance")]),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.getAssetsSystemAssetTotalBalance(
                                                  _vm.account,
                                                  item
                                                )
                                                  ? parseFloat(
                                                      _vm.getAssetsSystemAssetTotalBalance(
                                                        _vm.account,
                                                        item
                                                      )
                                                    )
                                                  : ""
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "1" } }, [
                                          _c("span", [
                                            _vm._v("Wallet Address")
                                          ]),
                                          _vm._v(" "),
                                          _vm.systemMaintenanceStatus &&
                                          _vm.getIncomingAssetWalletAddress(
                                            _vm.account,
                                            item
                                          )
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "action-button save",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.getWallet(
                                                        item,
                                                        _vm.account.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Update")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.getIncomingAssetWalletAddress(
                                            _vm.account,
                                            item
                                          )
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "action-button save",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.wallet(
                                                        item,
                                                        _vm.account.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Activate")]
                                              )
                                            : _vm._e()
                                        ])
                                      ])
                                    })
                                  ]
                                : [
                                    _vm._m(64),
                                    _vm._v(" "),
                                    _vm._l(_vm.assetsList, function(item) {
                                      return _vm.getIncomingAssetWalletAddress(
                                        _vm.account,
                                        item
                                      )
                                        ? _c("tr", [
                                            _c("td"),
                                            _vm._v(" "),
                                            _c("td"),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { attrs: { colspan: "1" } },
                                              [
                                                _c("span", [
                                                  _vm._v("Currency")
                                                ]),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { attrs: { colspan: "1" } },
                                              [
                                                _c("span", [_vm._v("Name")]),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.getCurrencyName(item)
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { attrs: { colspan: "1" } },
                                              [
                                                _c("span", [
                                                  _vm._v("Tag Required")
                                                ]),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.getTagRequired(item)
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { attrs: { colspan: "1" } },
                                              [
                                                _c("span", [_vm._v("Tag")]),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.getTag(
                                                        _vm.account,
                                                        item
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { attrs: { colspan: "2" } },
                                              [
                                                _c("span", [
                                                  _vm._v("Wallet Address")
                                                ]),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.getIncomingAssetWalletAddress(
                                                        _vm.account,
                                                        item
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [
                                                _vm._v("Available Balance")
                                              ]),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.getAssetsSystemAssetAvailableBalance(
                                                      _vm.account,
                                                      item
                                                    )
                                                      ? parseFloat(
                                                          _vm.getAssetsSystemAssetAvailableBalance(
                                                            _vm.account,
                                                            item
                                                          )
                                                        )
                                                      : ""
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { attrs: { colspan: "2" } },
                                              [
                                                _c("span", [_vm._v("Balance")]),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.getAssetsSystemAssetTotalBalance(
                                                        _vm.account,
                                                        item
                                                      )
                                                        ? parseFloat(
                                                            _vm.getAssetsSystemAssetTotalBalance(
                                                              _vm.account,
                                                              item
                                                            )
                                                          )
                                                        : ""
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(65, true)
                                          ])
                                        : _vm._e()
                                    })
                                  ]
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._m(66)
                      ],
                      2
                    )
                  ])
                ])
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "popup", class: { open: !_vm.open } }, [
        _c("div", { staticClass: "pop-inner", style: { maxWidth: "400px" } }, [
          _c("div", { staticClass: "pop-box" }, [
            _c("h2", [_vm._v("Enter Finance PIN to Continue")]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pin,
                      expression: "pin"
                    }
                  ],
                  staticClass: "pin-code",
                  attrs: {
                    oninput:
                      "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                    type: "number",
                    maxlength: "4"
                  },
                  domProps: { value: _vm.pin },
                  on: {
                    keyup: _vm.pinInput,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.pin = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c(
                "div",
                { staticClass: "pf-field" },
                [_vm.pinFailed ? [_vm._v("Invalid PIN")] : _vm._e()],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row buttons" }, [
              _c(
                "a",
                {
                  staticClass: "pop-btn",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "pop-btn",
                  on: {
                    click: function($event) {
                      return _vm.checkPin()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.errorOpen, width: 400 },
          on: {
            "update:open": function($event) {
              _vm.errorOpen = $event
            }
          }
        },
        [
          _c("div", { staticClass: "scrollable" }, [
            _c("p", [_vm._v(_vm._s(_vm.message))])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("corp-account-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "12" } }, [
        _vm._v(
          "\n                  This feature is not enabled. Please contact system administration in order to have it enabled. Feature - Multi-Currency\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account ID")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Type")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Currency")]), _vm._v("AUD")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Reference Code")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Date")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account ID")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Type")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Currency")]), _vm._v("AUD")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Reference Code")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Date")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account ID")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Type")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Currency")]), _vm._v("CAD")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Reference Code")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Date")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account ID")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Type")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Currency")]), _vm._v("CAD")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Reference Code")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Date")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account ID")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Type")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Currency")]), _vm._v("EUR")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Reference Code")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Date")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account ID")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Type")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Currency")]), _vm._v("EUR")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Reference Code")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Date")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account ID")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Type")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Currency")]),
      _vm._v("\n                        GBP\n                      ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Reference Code")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Date")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account ID")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Type")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Currency")]),
      _vm._v("\n                      GBP\n                    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Reference Code")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Date")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account ID")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Type")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Currency")]), _vm._v("JPY")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Reference Code")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Date")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account ID")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Type")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Currency")]), _vm._v("JPY")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Reference Code")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account Date")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "10" } }, [
      _c("b", { staticClass: "assets-label" }, [_vm._v("ASSETS")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("b", { staticClass: "assets-label" }, [_vm._v("ASSETS")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td"),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [
        _vm._v("\n                    Currency\n                  ")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [
        _vm._v("\n                    Name\n                  ")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [
        _vm._v("\n                    Tag Required\n                  ")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [
        _vm._v("\n                    Tag\n                  ")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "2" } }, [
        _vm._v("\n                    Wallet Address\n                  ")
      ]),
      _vm._v(" "),
      _c("td", [_vm._v("\n                    Available\n                  ")]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "2" } }, [
        _vm._v("\n                    Balance\n                  ")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [
        _vm._v("\n                    Options\n                  ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td"),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [
        _vm._v("\n                    Currency\n                  ")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [
        _vm._v("\n                    Name\n                  ")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [
        _vm._v("\n                    Tag Required\n                  ")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [
        _vm._v("\n                    Tag\n                  ")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "2" } }, [
        _vm._v("\n                    Wallet Address\n                  ")
      ]),
      _vm._v(" "),
      _c("td", [_vm._v("\n                    Available\n                  ")]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "2" } }, [
        _vm._v("\n                    Balance\n                  ")
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [
        _vm._v("\n                    Options\n                  ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "1" } }, [
      _c("span", [_vm._v("Wallet Address")]),
      _vm._v("\n                    Disabled\n                  ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "12" } })])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup", class: { open: _vm.expired } }, [
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pop-inner" }, [
      _c("div", { staticClass: "pop-box session-timeout" }, [
        _c("a", { staticClass: "close", attrs: { href: "" } }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "You have been logged out due to inactivity. Please click below to login again."
          )
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "button", attrs: { href: "" } }, [
          _vm._v("Login")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
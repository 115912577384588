var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.open
        ? [
            _vm._m(0),
            _vm._v(" "),
            _vm.featureStatus != null && !_vm.featureStatus
              ? [
                  _c("h3", { staticClass: "monthly-head" }, [
                    _vm._v(
                      "This feature is not enabled. Please contact system administration in order to have it enabled."
                    )
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "monthly-head" }, [
                    _vm._v("Feature - Account Reconciliation")
                  ])
                ]
              : [
                  _vm.perms
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            on: {
                              click: function($event) {
                                return _vm.openModal()
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "el-icon-money" }),
                            _vm._v(" New Reconciliation")
                          ]
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.perms
                    ? _c("section", { staticClass: "content-tlist" }, [
                        _c(
                          "table",
                          { staticClass: "content-table" },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _vm.currencyType == "Funds"
                              ? [
                                  _vm.accounts.length > 0 &&
                                  (_vm.accounts[0].serviceProviders
                                    .provider_name == "Solid" ||
                                    _vm.accounts[0].serviceProviders
                                      .provider_name == "Solid Test")
                                    ? [
                                        _vm._l(_vm.accounts, function(item, i) {
                                          return item.accountStatus !=
                                            "Pending" &&
                                            _vm.statusCurrencyActivated(item) &&
                                            item.accountStatus != "New" &&
                                            item.accountStatus != "Closed"
                                            ? _c(
                                                "tr",
                                                {
                                                  class:
                                                    _vm.getSystemFundsAvailableBalance(
                                                      item
                                                    ) !=
                                                    _vm.getProviderFundsAvailableBalance(
                                                      item
                                                    )
                                                      ? "highlight-yellow"
                                                      : ""
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Account")
                                                      ]),
                                                      _vm._v(" "),
                                                      !(item.regCorp == 1)
                                                        ? _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  "/system-customers/registrations/" +
                                                                  item.regId
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.accountNumber
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  item.accountNumber
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Currency")
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(_vm.currencyName)
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Available")
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                        _vm._s(
                                                          _vm._f(
                                                            "balance_without_symbol"
                                                          )(
                                                            _vm.getSystemFundsAvailableBalance(
                                                              item
                                                            )
                                                          )
                                                        )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Total")
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                        _vm._s(
                                                          _vm._f(
                                                            "balance_without_symbol"
                                                          )(
                                                            _vm.getSystemFundsTotalBalance(
                                                              item
                                                            )
                                                          )
                                                        )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Account Status")
                                                    ]),
                                                    _c(
                                                      "em",
                                                      {
                                                        class:
                                                          _vm
                                                            .statusColorClasses[
                                                            _vm.getStatus(
                                                              item.accountStatus
                                                            )
                                                          ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.getStatus(
                                                                item.accountStatus
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Provider Available"
                                                      )
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                        _vm._s(
                                                          _vm._f(
                                                            "balance_without_symbol"
                                                          )(
                                                            _vm.getProviderFundsAvailableBalance(
                                                              item
                                                            )
                                                          )
                                                        )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Provider Balance"
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getProviderFundsTotalBalance(
                                                        item
                                                      )
                                                        ? [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.currencySymbol
                                                              ) +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getProviderFundsTotalBalance(
                                                                      item
                                                                    )
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Provider Account Status"
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      item.solidAccount.length >
                                                      0
                                                        ? [
                                                            _c(
                                                              "em",
                                                              {
                                                                class:
                                                                  _vm
                                                                    .statusColorClasses[
                                                                    _vm.getStatus(
                                                                      item
                                                                        .solidAccount[0]
                                                                        .accountStatus
                                                                    )
                                                                  ]
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm.getStatus(
                                                                        item
                                                                          .solidAccount[0]
                                                                          .accountStatus
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._m(2, true)
                                                ]
                                              )
                                            : _vm._e()
                                        }),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _vm._m(3),
                                          _vm._v(" "),
                                          _c("td"),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.currencySymbol) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "balance_without_symbol"
                                                    )(_vm.totalFundsAvailable)
                                                  )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.currencySymbol) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "balance_without_symbol"
                                                    )(_vm.totalFundsBalance)
                                                  )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("td"),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.currencySymbol) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "balance_without_symbol"
                                                    )(
                                                      _vm.totalFundsProviderAvailable
                                                    )
                                                  )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.currencySymbol) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "balance_without_symbol"
                                                    )(
                                                      _vm.totalFundsProviderBalance
                                                    )
                                                  )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("td"),
                                          _vm._v(" "),
                                          _c("td")
                                        ])
                                      ]
                                    : [
                                        _vm._l(_vm.accounts, function(item, i) {
                                          return item.accountStatus !=
                                            "Pending" &&
                                            _vm.statusCurrencyActivated(item) &&
                                            item.accountStatus != "New" &&
                                            item.accountStatus != "Closed"
                                            ? _c(
                                                "tr",
                                                {
                                                  class:
                                                    _vm.getSystemFundsAvailableBalance(
                                                      item
                                                    ) !=
                                                      _vm.getProviderFundsAvailableBalance(
                                                        item
                                                      ) ||
                                                    _vm.getSystemFundsTotalBalance(
                                                      item
                                                    ) !=
                                                      _vm.getProviderFundsTotalBalance(
                                                        item
                                                      )
                                                      ? "highlight-yellow"
                                                      : ""
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Account")
                                                      ]),
                                                      _vm._v(" "),
                                                      !(item.regCorp == 1)
                                                        ? _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  "/system-customers/registrations/" +
                                                                  item.regId
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.accountNumber
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  item.accountNumber
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Currency")
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(_vm.currencyName)
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Available")
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                        _vm._s(
                                                          _vm._f(
                                                            "balance_without_symbol"
                                                          )(
                                                            _vm.getSystemFundsAvailableBalance(
                                                              item
                                                            )
                                                          )
                                                        )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Total")
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                        _vm._s(
                                                          _vm._f(
                                                            "balance_without_symbol"
                                                          )(
                                                            _vm.getSystemFundsTotalBalance(
                                                              item
                                                            )
                                                          )
                                                        )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Account Status")
                                                    ]),
                                                    _c(
                                                      "em",
                                                      {
                                                        class:
                                                          _vm
                                                            .statusColorClasses[
                                                            _vm.getStatus(
                                                              item.accountStatus
                                                            )
                                                          ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.getStatus(
                                                                item.accountStatus
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Provider Available"
                                                      )
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currencySymbol
                                                      ) +
                                                        _vm._s(
                                                          _vm._f(
                                                            "balance_without_symbol"
                                                          )(
                                                            _vm.getProviderFundsAvailableBalance(
                                                              item
                                                            )
                                                          )
                                                        )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Provider Balance"
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.getProviderFundsTotalBalance(
                                                        item
                                                      )
                                                        ? [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.currencySymbol
                                                              ) +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance_without_symbol"
                                                                  )(
                                                                    _vm.getProviderFundsTotalBalance(
                                                                      item
                                                                    )
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Provider Account Status"
                                                      )
                                                    ]),
                                                    _c(
                                                      "em",
                                                      {
                                                        class:
                                                          _vm
                                                            .statusColorClasses[
                                                            _vm.getStatus(
                                                              item.result
                                                            )
                                                          ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.getStatus(
                                                                item.result
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _vm._m(4, true)
                                                ]
                                              )
                                            : _vm._e()
                                        }),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _vm._m(5),
                                          _vm._v(" "),
                                          _c("td"),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.currencySymbol) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "balance_without_symbol"
                                                    )(_vm.totalFundsAvailable)
                                                  )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.currencySymbol) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "balance_without_symbol"
                                                    )(_vm.totalFundsBalance)
                                                  )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("td"),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.currencySymbol) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "balance_without_symbol"
                                                    )(
                                                      _vm.totalFundsProviderAvailable
                                                    )
                                                  )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(_vm.currencySymbol) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "balance_without_symbol"
                                                    )(
                                                      _vm.totalFundsProviderBalance
                                                    )
                                                  )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("td"),
                                          _vm._v(" "),
                                          _c("td")
                                        ])
                                      ]
                                ]
                              : _vm.currencyType == "Assets"
                              ? [
                                  _vm._l(_vm.accounts, function(item, i) {
                                    return item.accountStatus != "Pending" &&
                                      _vm.statusAssetsActivated(item) &&
                                      item.accountStatus != "Closed"
                                      ? _c(
                                          "tr",
                                          {
                                            class:
                                              _vm.getSystemAssetsAvailableBalance(
                                                item
                                              ) !=
                                                _vm.getProviderAssetsAvailableBalance(
                                                  item
                                                ) ||
                                              _vm.getSystemAssetsTotalBalance(
                                                item
                                              ) !=
                                                _vm.getProviderAssetsTotalBalance(
                                                  item
                                                )
                                                ? "highlight-yellow"
                                                : ""
                                          },
                                          [
                                            _c(
                                              "td",
                                              [
                                                _c("span", [_vm._v("Account")]),
                                                _vm._v(" "),
                                                !(item.regCorp == 1)
                                                  ? _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            "/system-customers/registrations/" +
                                                            item.regId
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.accountNumber
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            item.accountNumber
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [_vm._v("Currency")]),
                                              _vm._v(_vm._s(_vm.currencyName))
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [_vm._v("Available")]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getSystemAssetsAvailableBalance(
                                                    item
                                                  )
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [_vm._v("Total")]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getSystemAssetsTotalBalance(
                                                    item
                                                  )
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [
                                                _vm._v("Account Status")
                                              ]),
                                              _c(
                                                "em",
                                                {
                                                  class:
                                                    _vm.statusColorClasses[
                                                      _vm.getStatus(
                                                        item.accountStatus
                                                      )
                                                    ]
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.getStatus(
                                                          item.accountStatus
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [
                                                _vm._v("Provider Available")
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getProviderAssetsAvailableBalance(
                                                    item
                                                  )
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [
                                                _vm._v("Provider Balance")
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getProviderAssetsTotalBalance(
                                                    item
                                                  )
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [
                                                _vm._v(
                                                  "Provider Account Status"
                                                )
                                              ]),
                                              _c(
                                                "em",
                                                {
                                                  class:
                                                    _vm.statusColorClasses[
                                                      _vm.getStatus(item.result)
                                                    ]
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.getStatus(
                                                          item.result
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _vm._m(6, true)
                                          ]
                                        )
                                      : _vm._e()
                                  }),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _vm._m(7),
                                    _vm._v(" "),
                                    _c("td"),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.totalAssetsAvailable))
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.totalAssetsBalance))
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("td"),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalAssetsProviderAvailable
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.totalAssetsProviderBalance)
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("td"),
                                    _vm._v(" "),
                                    _c("td")
                                  ])
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "popup", class: { open: !_vm.open } }, [
        _c("div", { staticClass: "pop-inner", style: { maxWidth: "400px" } }, [
          _c("div", { staticClass: "pop-box" }, [
            _c("h2", [_vm._v("Enter Finance PIN to Continue")]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pin,
                      expression: "pin"
                    }
                  ],
                  staticClass: "pin-code",
                  attrs: {
                    oninput:
                      "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                    type: "number",
                    maxlength: "4"
                  },
                  domProps: { value: _vm.pin },
                  on: {
                    keyup: _vm.pinInput,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.pin = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c(
                "div",
                { staticClass: "pf-field" },
                [_vm.pinFailed ? [_vm._v("Invalid PIN")] : _vm._e()],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row buttons" }, [
              _c(
                "a",
                {
                  staticClass: "pop-btn",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "pop-btn",
                  on: {
                    click: function($event) {
                      return _vm.checkPin()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.modalType
        ? _c("reconcile-form", {
            attrs: { "modal-type": _vm.modalType },
            on: {
              close: function($event) {
                _vm.modalType = null
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Account")]),
      _vm._v(" "),
      _c("th", [_vm._v("Currency")]),
      _vm._v(" "),
      _c("th", [_vm._v("Available")]),
      _vm._v(" "),
      _c("th", [_vm._v("Balance")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Provider Available")]),
      _vm._v(" "),
      _c("th", [_vm._v("Provider Balance")]),
      _vm._v(" "),
      _c("th", [_vm._v("Provider Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Options")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("b", [_vm._v("Totals")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Options")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("b", [_vm._v("Totals")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Options")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("b", [_vm._v("Totals")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.resetUserPassword
    ? _c(
        "section",
        { staticClass: "form-box reg-form login-form" },
        [
          _vm.system
            ? [
                _c("h1", { staticClass: "logo" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.base + _vm.system.logo,
                      alt: _vm.system.logo + " Logo"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("h2", [_c("span", [_vm._v(_vm._s(_vm.system.name))])]),
                _vm._v(" "),
                _c("h3", [_vm._v(_vm._s(_vm.system.login_text))])
              ]
            : _vm._e(),
          _vm._v(" "),
          [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.login($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "content-form" }, [
                  _vm.error
                    ? _c("div", { staticClass: "error-msg" }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.error) + "\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-fullw" },
                    [
                      _c("form-field-text", {
                        staticClass: "filled",
                        attrs: { label: "User name", required: "" },
                        model: {
                          value: _vm.form.username,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row form-fullw" },
                    [
                      _c("form-field-text", {
                        staticClass: "filled",
                        attrs: {
                          type: "password",
                          label: "Password",
                          required: ""
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row form-fullw" }, [
                    _c("div", { staticClass: "txtc" }, [
                      _c("p", [
                        _vm._v("Forgot password? "),
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.resetUserPassword = true
                              }
                            }
                          },
                          [_vm._v("Click here")]
                        )
                      ])
                    ])
                  ])
                ])
              ]
            )
          ],
          _vm._v(" "),
          _c(
            "popup",
            {
              attrs: { open: _vm.resetModal, width: 470 },
              on: {
                "update:open": function($event) {
                  _vm.resetModal = $event
                }
              }
            },
            [
              _vm.loggingUser
                ? [
                    _c("h2", [
                      _vm._v(
                        _vm._s(
                          _vm.resetPassword
                            ? "Reset Password"
                            : "Account Activation"
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info" }, [
                      _c(
                        "div",
                        [
                          _vm._v("ID:\n          "),
                          _vm.loggingUser.employee
                            ? [
                                _vm._v(
                                  _vm._s(_vm.loggingUser.employee.employeeId)
                                )
                              ]
                            : _vm.loggingUser.registration
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm.loggingUser.registration.mainAccount
                                      .profileNumber
                                  )
                                )
                              ]
                            : [_vm._v("N/A")]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("Username: " + _vm._s(_vm.loggingUser.username))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("form", [
                      _c("div", { staticClass: "content-form" }, [
                        _c(
                          "div",
                          { staticClass: "form-row form-fullw" },
                          [
                            _c("form-field-text", {
                              attrs: {
                                type: "password",
                                validator: _vm.$v.resetForm.password,
                                label: "Set Password",
                                required: ""
                              },
                              model: {
                                value: _vm.resetForm.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.resetForm, "password", $$v)
                                },
                                expression: "resetForm.password"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-row form-fullw" },
                          [
                            _c("form-field-text", {
                              attrs: {
                                type: "password",
                                validator: _vm.$v.resetForm.confirmPassword,
                                label: "Confirm Password",
                                required: ""
                              },
                              model: {
                                value: _vm.resetForm.confirmPassword,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.resetForm,
                                    "confirmPassword",
                                    $$v
                                  )
                                },
                                expression: "resetForm.confirmPassword"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.resetPassword
                          ? _c("div", { staticClass: "agree" }, [
                              _c("span", [
                                _vm._v(
                                  "By activating my account, I agree to the:"
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.resetForm.data1,
                                        expression: "resetForm.data1"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.resetForm.data1
                                      )
                                        ? _vm._i(_vm.resetForm.data1, null) > -1
                                        : _vm.resetForm.data1
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.resetForm.data1,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.resetForm,
                                                "data1",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.resetForm,
                                                "data1",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.resetForm, "data1", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.termsModal = true
                                        }
                                      }
                                    },
                                    [_vm._v("Terms & Conditions")]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.resetForm.data2,
                                        expression: "resetForm.data2"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.resetForm.data2
                                      )
                                        ? _vm._i(_vm.resetForm.data2, null) > -1
                                        : _vm.resetForm.data2
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.resetForm.data2,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.resetForm,
                                                "data2",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.resetForm,
                                                "data2",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.resetForm, "data2", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.privacyModal = true
                                        }
                                      }
                                    },
                                    [_vm._v("Privacy Policy")]
                                  )
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row form-center" }, [
                          _c("div", [
                            _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.reset()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.resetPassword ? "Reset" : "Activate"
                                  )
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.resetPassword
                          ? _c(
                              "a",
                              {
                                staticClass: "cancel",
                                on: {
                                  click: function($event) {
                                    _vm.resetModal = false
                                  }
                                }
                              },
                              [_vm._v("Cancel")]
                            )
                          : _vm._e()
                      ])
                    ])
                  ]
                : [
                    _c("div", { staticClass: "expired" }, [
                      _c("b", [
                        _vm._v(
                          "This " +
                            _vm._s(
                              _vm.resetPassword ? "Link" : "Activation Code"
                            ) +
                            " is Expired!"
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [
                          _vm._v(
                            "Please request a new " +
                              _vm._s(
                                _vm.resetPassword
                                  ? "reset link"
                                  : "activation code"
                              ) +
                              " and try again."
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("You may also contact Support at:")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.system.support_email)),
                        _c("br"),
                        _vm._v(_vm._s(_vm.system.support_phone))
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          on: {
                            click: function($event) {
                              _vm.resetModal = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ])
                  ]
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "popup",
            {
              attrs: { open: _vm.termsModal },
              on: {
                "update:open": function($event) {
                  _vm.termsModal = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "privacy-popup scrollable" },
                [
                  _vm._v("\n      Terms and Conditions\n      "),
                  _c("terms-popup")
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "popup",
            {
              attrs: { open: _vm.privacyModal },
              on: {
                "update:open": function($event) {
                  _vm.privacyModal = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "privacy-popup scrollable" },
                [_vm._v("\n      Privacy Policy\n      "), _c("privacy-popup")],
                1
              )
            ]
          )
        ],
        2
      )
    : _c("reset-password")
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-center" }, [
      _c("div", [_c("button", [_vm._v("Login")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.open
        ? [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content-filters" },
              [
                _c("basic-form", [
                  _c("div", { staticClass: "cof-row" }, [
                    _c("div", { staticClass: "split" }, [
                      _c(
                        "div",
                        { staticClass: "split" },
                        [
                          _c("mnb-input", {
                            attrs: { label: "Active Theme", disabled: "" },
                            model: {
                              value: _vm.activatedThemeName,
                              callback: function($$v) {
                                _vm.activatedThemeName = $$v
                              },
                              expression: "activatedThemeName"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "split" }, [
                      _c(
                        "div",
                        { staticClass: "split" },
                        [
                          _c("mnb-select", {
                            attrs: {
                              options: _vm.themeOptions,
                              label: "Theme"
                            },
                            model: {
                              value: _vm.themeOption,
                              callback: function($$v) {
                                _vm.themeOption = $$v
                              },
                              expression: "themeOption"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-center" }, [
                            _vm.themeOption != "new" &&
                            _vm.themeOption != null &&
                            _vm.activatedThemeId != _vm.themeOption
                              ? _c("div", { staticClass: "cof-field mobinl" }, [
                                  _c("label", [_vm._v(" ")]),
                                  _vm._v(" "),
                                  _vm.$hasPerm("themes")
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "main",
                                          on: {
                                            click: function($event) {
                                              return _vm.activate()
                                            }
                                          }
                                        },
                                        [_vm._v("Activate")]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mode == "Overview"
        ? _c("section", [
            _c("div", { staticClass: "flex-between" }, [
              _c("h2", { staticClass: "inner-headers" }),
              _vm._v(" "),
              _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        _vm.openedFilters = true
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-s-operation" }),
                    _vm._v(" Filters")
                  ]
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.mode == "Overview"
        ? _c(
            "button",
            {
              staticClass: "btn btn-success",
              staticStyle: { "margin-top": "-55px" },
              on: {
                click: function($event) {
                  _vm.mode = "New"
                }
              }
            },
            [
              _c("i", { staticClass: "el-icon-plus" }),
              _vm._v(" New Registration")
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mode == "Overview"
        ? _c("applied-filters", {
            attrs: { filters: _vm.appliedFilters },
            on: {
              clear: function($event) {
                return _vm.clearFilters()
              },
              remove: function($event) {
                return _vm.removeFilter($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.mode == "Overview"
        ? _c("div", { staticClass: "accordion" }, [
            _c("div", { class: { "cof-box-active": _vm.activeInfo } }, [
              _c("section", { staticClass: "content-tlist" }, [
                _c(
                  "table",
                  { staticClass: "content-table" },
                  [
                    _c("tr", [
                      _c("th", [
                        _vm._v("Date "),
                        _c("img", {
                          attrs: { src: "/assets/img/updown.png", width: "13" },
                          on: {
                            click: function($event) {
                              return _vm.sort("createdAt")
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Updated")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Number")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Type")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Status")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Options")])
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.filteredData, function(registration) {
                      return _c("tr", { key: registration.id }, [
                        _c("td", [
                          _c("span", [_vm._v("Profile Date")]),
                          _vm._v(
                            _vm._s(
                              _vm._f("date")(
                                registration.createdAt,
                                "MM/D/YYYY"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Updated")]),
                            registration.updatedAt
                              ? [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("date")(
                                        registration.updatedAt,
                                        "MM/D/YYYY"
                                      )
                                    )
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Profile")]),
                            _vm._v(" "),
                            !_vm.partner
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/system-customers/" +
                                        _vm.ppage +
                                        "/" +
                                        registration.id
                                    }
                                  },
                                  [_vm._v(_vm._s(registration.profileNumber))]
                                )
                              : [_vm._v(_vm._s(registration.profileNumber))]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Name")]),
                            _vm._v(" "),
                            registration.type === "Personal"
                              ? [
                                  _vm._v(
                                    _vm._s(registration.data.firstName) +
                                      " " +
                                      _vm._s(registration.data.lastName)
                                  )
                                ]
                              : [_vm._v(_vm._s(registration.data.legal_name))]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Profile Type")]),
                          _vm._v(_vm._s(registration.type))
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Status")]),
                          _c(
                            "em",
                            {
                              class: _vm.statusColorClasses[registration.status]
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    registration.status === "Complete"
                                      ? "Activation"
                                      : registration.status
                                  )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Options")]),
                            _vm._v(" "),
                            registration.status === "Denied"
                              ? [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "action-button cancel",
                                      on: {
                                        click: function($event) {
                                          return _vm.archive(registration.id)
                                        }
                                      }
                                    },
                                    [_vm._v("Archive")]
                                  )
                                ]
                              : registration.status !== "Archived" &&
                                _vm.oneMonthBefore(registration)
                              ? [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "action-button cancel",
                                      on: {
                                        click: function($event) {
                                          return _vm.archive(registration.id)
                                        }
                                      }
                                    },
                                    [_vm._v("Archive")]
                                  )
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$hasPerm("reject")
                              ? [
                                  registration.status === "New" ||
                                  registration.status === "In Process"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "action-button remove",
                                          on: {
                                            click: function($event) {
                                              return _vm.reject(registration.id)
                                            }
                                          }
                                        },
                                        [_vm._v("Reject")]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    }),
                    _vm._v(" "),
                    !_vm.registrations.length
                      ? _c("tr", [
                          _c(
                            "td",
                            { staticClass: "no-data", attrs: { colspan: "7" } },
                            [_vm._v("No data")]
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              !_vm.loading
                ? _c("section", { staticClass: "pagination-section" }, [
                    _c("div", [
                      _vm._v("\n          Per page\n          "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.perPage,
                              expression: "perPage"
                            }
                          ],
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.perPage = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.setPages()
                              }
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { value: "25" } }, [
                            _vm._v("25")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "50" } }, [
                            _vm._v("50")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "75" } }, [
                            _vm._v("75")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "100" } }, [
                            _vm._v("100")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "150" } }, [
                            _vm._v("150")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "200" } }, [
                            _vm._v("200")
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "nav",
                      { attrs: { "aria-label": "Page navigation example" } },
                      [
                        _c("ul", { staticClass: "pagination" }, [
                          _c("li", { staticClass: "page-item" }, [
                            _vm.page != 1
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "page-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.page--
                                      }
                                    }
                                  },
                                  [_vm._v(" Previous ")]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "page-item" },
                            _vm._l(
                              _vm.pages.slice(_vm.page - 1, _vm.page + 5),
                              function(pageNumber) {
                                return _c(
                                  "button",
                                  {
                                    key: pageNumber,
                                    staticClass: "page-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.page = pageNumber
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(pageNumber) + " ")]
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c("li", { staticClass: "page-item" }, [
                            _vm.page < _vm.pages.length
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "page-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.page++
                                      }
                                    }
                                  },
                                  [_vm._v(" Next ")]
                                )
                              : _vm._e()
                          ])
                        ])
                      ]
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm.mode == "New"
        ? _c("RegisterForm2", { attrs: { internal: true } })
        : _vm._e(),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("profile-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
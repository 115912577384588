var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-form" }, [
    _c(
      "div",
      { staticClass: "form-row form-split" },
      [
        _c("form-field-text", {
          attrs: {
            label: "Name of Bank Account",
            validator: _vm.v.account_name,
            required: ""
          },
          model: {
            value: _vm.form.account_name,
            callback: function($$v) {
              _vm.$set(_vm.form, "account_name", $$v)
            },
            expression: "form.account_name"
          }
        }),
        _vm._v(" "),
        _c("form-field-select", {
          attrs: {
            label: "Account Type",
            validator: _vm.v.account_type,
            options: _vm.accountOptions,
            required: ""
          },
          model: {
            value: _vm.form.account_type,
            callback: function($$v) {
              _vm.$set(_vm.form, "account_type", $$v)
            },
            expression: "form.account_type"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "form-row",
        class: _vm.hideBankAccountType ? "form-split" : "form-three"
      },
      [
        _c("form-field-text", {
          attrs: {
            label: "Routing Number",
            validator: _vm.v.routing_number,
            required: ""
          },
          model: {
            value: _vm.form.routing_number,
            callback: function($$v) {
              _vm.$set(_vm.form, "routing_number", $$v)
            },
            expression: "form.routing_number"
          }
        }),
        _vm._v(" "),
        _c("form-field-text", {
          attrs: {
            label: "Bank Account Number",
            validator: _vm.v.account_number,
            required: ""
          },
          model: {
            value: _vm.form.account_number,
            callback: function($$v) {
              _vm.$set(_vm.form, "account_number", $$v)
            },
            expression: "form.account_number"
          }
        }),
        _vm._v(" "),
        !_vm.hideBankAccountType
          ? _c("form-field-select", {
              attrs: {
                label: "Bank Account Type",
                validator: _vm.v.bank_account_type,
                options: _vm.bankTypeOptions,
                required: ""
              },
              model: {
                value: _vm.form.bank_account_type,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bank_account_type", $$v)
                },
                expression: "form.bank_account_type"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
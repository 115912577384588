var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "cp-box" }, [
      _c("div", { staticClass: "cof-field mobinl" }, [
        _c("label", [_vm._v(" ")]),
        _vm._v(" "),
        _vm.$hasPerm("partners-update") && _vm.canEdit
          ? _c(
              "button",
              {
                staticClass: "main",
                on: {
                  click: function($event) {
                    return _vm.addNewDocument()
                  }
                }
              },
              [_vm._v("Add New")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("section", { staticClass: "content-filters" }, [
        _vm.addDocument
          ? _c(
              "div",
              {
                staticClass: "cof-row",
                staticStyle: { "margin-bottom": "70px" }
              },
              [
                _c("mnb-input", {
                  staticStyle: { width: "60%" },
                  attrs: {
                    label: "Description",
                    validator: _vm.$v.descriptionOther
                  },
                  model: {
                    value: _vm.descriptionOther,
                    callback: function($$v) {
                      _vm.descriptionOther = $$v
                    },
                    expression: "descriptionOther"
                  }
                }),
                _vm._v(" "),
                _c("form-field-upload", {
                  staticStyle: { width: "255px" },
                  attrs: {
                    value: _vm.addDocumentForm.file,
                    validator: _vm.$v.addDocumentForm.file,
                    label: "Document",
                    classes: { "add-document": true },
                    btnClasses: "add-document-btn"
                  },
                  model: {
                    value: _vm.addDocumentForm.file,
                    callback: function($$v) {
                      _vm.$set(_vm.addDocumentForm, "file", $$v)
                    },
                    expression: "addDocumentForm.file"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "cof-field mobinl",
                    staticStyle: { "max-width": "100px" }
                  },
                  [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "main",
                        staticStyle: {
                          "min-width": "100px",
                          "margin-left": "20px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.saveDocument()
                          }
                        }
                      },
                      [_vm._v("Submit")]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(0)
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
        [
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _c("tr", [
                _c("th", [_vm._v("Description")]),
                _vm._v(" "),
                _c("th", [_vm._v("Filename")]),
                _vm._v(" "),
                _vm.canEdit ? _c("th", [_vm._v("Options")]) : _vm._e()
              ]),
              _vm._v(" "),
              _vm._l(_vm.reorderedDocuments, function(document, i) {
                return _c(
                  "tr",
                  { key: document.id },
                  [
                    document.description == null && document.file == null
                      ? void 0
                      : [
                          _c(
                            "td",
                            [
                              _c("span", [_vm._v("Description")]),
                              _vm._v(" "),
                              _vm.isEdit(i) &&
                              document.deletable &&
                              !_vm.newCopy
                                ? _c("mnb-input", {
                                    attrs: {
                                      validator:
                                        _vm.$v.editingForms[i].description,
                                      classes: {
                                        error:
                                          _vm.$v.editingForms[i].description
                                            .$invalid
                                      }
                                    },
                                    model: {
                                      value: _vm.editingForms[i].description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editingForms[i],
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "editingForms[i].description"
                                    }
                                  })
                                : [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(document.description) +
                                        "\n              "
                                    )
                                  ]
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("span", [_vm._v("Filename")]),
                              _vm._v(" "),
                              _vm.isEdit(i)
                                ? _c("form-field-upload", {
                                    staticStyle: { width: "173px" },
                                    attrs: {
                                      label: "",
                                      validator: _vm.$v.editingForms[i].file,
                                      classes: {
                                        error:
                                          _vm.$v.editingForms[i].file.$invalid,
                                        "add-document": true
                                      },
                                      btnClasses: "add-document-btn-upload"
                                    },
                                    model: {
                                      value: _vm.editingForms[i].file,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editingForms[i],
                                          "file",
                                          $$v
                                        )
                                      },
                                      expression: "editingForms[i].file"
                                    }
                                  })
                                : [
                                    document.file
                                      ? [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "/download/PartnerDocument/" +
                                                  document.id,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(document.file) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ]
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.canEdit
                            ? _c(
                                "td",
                                [
                                  _c("span", [_vm._v("Options")]),
                                  _vm._v(" "),
                                  _vm.isEdit(i)
                                    ? [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "document-handle save",
                                            on: {
                                              click: function($event) {
                                                return _vm.saveUpdateDocument(i)
                                              }
                                            }
                                          },
                                          [_vm._v("Save")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "document-handle cancel",
                                            on: {
                                              click: function($event) {
                                                return _vm.cancelEdit(i)
                                              }
                                            }
                                          },
                                          [_vm._v("Cancel")]
                                        )
                                      ]
                                    : [
                                        _vm.$hasPerm("update-profile")
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "document-handle update",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editDocument(i)
                                                  }
                                                }
                                              },
                                              [_vm._v("Update")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.$hasPerm("remove-documents")
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "document-handle remove",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteDocument(
                                                      document.id,
                                                      document.deletable
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Remove")]
                                            )
                                          : _vm._e()
                                      ]
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                  ],
                  2
                )
              }),
              _vm._v(" "),
              !_vm.documents.length
                ? _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "empty-table-text",
                        attrs: { colspan: "5" }
                      },
                      [_vm._v("There are no Documents")]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cof-field mobinl",
        staticStyle: { "margin-left": "20px" }
      },
      [
        _c("label", [_vm._v(" ")]),
        _vm._v(" "),
        _c("button", { staticClass: "second" }, [_vm._v("Reset Fields")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
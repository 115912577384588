var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$hasPerm("new-scan")
        ? _c(
            "button",
            {
              staticClass: "btn btn-success",
              on: {
                click: function($event) {
                  _vm.open = true
                }
              }
            },
            [_vm._v("New Scan")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "content-tlist", staticStyle: { margin: "20px 0" } },
        [
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.scans, function(scan) {
                return _c("tr", { key: scan.id }, [
                  _c("td", [
                    _c("span", [_vm._v("Scan")]),
                    _vm._v(_vm._s(scan.scanId))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Date/Time")]),
                    _vm._v(_vm._s(_vm._f("date")(scan.createdAt, "MM/D/YYYY")))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Profile")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/system-customers/registrations/" + scan.regId
                        }
                      },
                      [_vm._v(_vm._s(scan.profile))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Name")]),
                    _vm._v("\n          " + _vm._s(scan.name) + "\n        ")
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Scan Type")]),
                    _vm._v(
                      "\n          " + _vm._s(scan.scanType) + "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Scan Result")]),
                    _vm._v(" "),
                    _c(
                      "strong",
                      { class: _vm.statusColorClasses[scan.scanResult] },
                      [_vm._v(_vm._s(scan.scanResult))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("File")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: {
                          target: "_blank",
                          href: "files/scanReports/" + scan.id + "/" + scan.file
                        }
                      },
                      [_vm._v(_vm._s(scan.file))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Options")]),
                    _vm._v(" "),
                    scan.scanResult === "Pending"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: {
                              click: function($event) {
                                return _vm.update(scan.id)
                              }
                            }
                          },
                          [_vm._v("Update")]
                        )
                      : _vm._e()
                  ])
                ])
              })
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.open },
          on: {
            "update:open": function($event) {
              _vm.open = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("New Scan")])
          ]),
          _vm._v(" "),
          [
            _c(
              "div",
              { staticClass: "scrollable" },
              [
                [
                  _c("div", { staticClass: "content-form" }, [
                    _c(
                      "div",
                      { staticClass: "form-row form-split" },
                      [
                        _c("form-field-select", {
                          attrs: {
                            label: "Scan Type",
                            validator: _vm.$v.submitForm.scanType,
                            options: _vm.providerOptions,
                            required: ""
                          },
                          model: {
                            value: _vm.submitForm.scanType,
                            callback: function($$v) {
                              _vm.$set(_vm.submitForm, "scanType", $$v)
                            },
                            expression: "submitForm.scanType"
                          }
                        }),
                        _vm._v(" "),
                        _c("form-field-select", {
                          attrs: {
                            label: "Select Profile",
                            validator: _vm.$v.submitForm.profile,
                            options: _vm.accountOptions,
                            required: "",
                            disabled: ""
                          },
                          model: {
                            value: _vm.submitForm.profile,
                            callback: function($$v) {
                              _vm.$set(_vm.submitForm, "profile", $$v)
                            },
                            expression: "submitForm.profile"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-row form-fullw justify-content-center"
                      },
                      [
                        _c("p", { staticClass: "text-center" }, [
                          _vm._v("Notes for Compliance Scans")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row form-fullw" }, [
                      _c("p", [
                        _vm._v(
                          "1 - KYC Reports are enabled for Business Profiles only"
                        )
                      ])
                    ])
                  ])
                ],
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    [
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          attrs: { disabled: _vm.$v.submitForm.$invalid },
                          on: {
                            click: function($event) {
                              return _vm.submit()
                            }
                          }
                        },
                        [_vm._v("Submit")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.open = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ]
                  ],
                  2
                )
              ],
              2
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Scan")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("th", [_vm._v("Profile")]),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Scan Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Scan Result")]),
      _vm._v(" "),
      _c("th", [_vm._v("File")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
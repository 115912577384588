var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "space-around" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open"
            }
          ],
          staticClass: "content-tlist",
          staticStyle: { width: "70%", border: "none", padding: "30px" }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          !_vm.nextFlag
            ? [
                _c("div", { staticClass: "scrollable" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "content-form content-form-modern text-center"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-row form-split depositType" },
                        [
                          _c("form-field-select", {
                            attrs: {
                              label: "Deposit Type",
                              validator: _vm.$v.form.depositType,
                              options: _vm.depositTypeOptions,
                              required: ""
                            },
                            model: {
                              value: _vm.form.depositType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "depositType", $$v)
                              },
                              expression: "form.depositType"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "main",
                        on: {
                          click: function($event) {
                            return _vm.moveNextPopup()
                          }
                        }
                      },
                      [_vm._v("Next")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "second",
                        on: {
                          click: function($event) {
                            _vm.open = false
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  ])
                ])
              ]
            : [
                _vm.form.depositType == "Funds"
                  ? _c("div", { staticClass: "scrollable" }, [
                      _c(
                        "div",
                        { staticClass: "content-form content-form-modern" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-row form-split" },
                            [
                              _c("form-field-select-search", {
                                staticClass: "notranslate",
                                attrs: {
                                  label: "Account",
                                  validator: _vm.$v.form.account,
                                  options: _vm.accountOptions,
                                  "option-group": !_vm.isCustomer,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.account,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "account", $$v)
                                  },
                                  expression: "form.account"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.error || _vm.infos
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "content-form bank-info content-form-modern"
                            },
                            [
                              _c(
                                "h3",
                                { staticClass: "subtitle text-center" },
                                [
                                  _vm._v("Deposit Details"),
                                  _vm.selectedAccount
                                    ? [
                                        _vm._v(
                                          " - " +
                                            _vm._s(_vm.selectedAccount.label) +
                                            " Account"
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.error
                                ? _c(
                                    "div",
                                    { staticClass: "form-row form-fullw" },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.error) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.infos
                                ? [
                                    _vm.infos["provider"] == "Solid"
                                      ? [
                                          _vm.form.deposit_type ==
                                          "domestic_wire"
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(1),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "accountName"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(2),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "accountNumber"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(3),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "routingNumber"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(4),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.infos[
                                                                "address"
                                                              ]["street-1"]
                                                            ) +
                                                              ", " +
                                                              _vm._s(
                                                                _vm.infos[
                                                                  "address"
                                                                ]["street-2"]
                                                              )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.infos[
                                                                "address"
                                                              ]["city"]
                                                            ) +
                                                              ", " +
                                                              _vm._s(
                                                                _vm.infos[
                                                                  "address"
                                                                ]["region"]
                                                              ) +
                                                              ", " +
                                                              _vm._s(
                                                                _vm.infos[
                                                                  "address"
                                                                ]["postal-code"]
                                                              )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.infos[
                                                                "address"
                                                              ]["country"]
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(5),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "bankName"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(6),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "bankAddress"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._m(7)
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.form.deposit_type ==
                                          "international_wires"
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(8),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "accountName"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(9),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "accountNumber"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(10),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "routingNumber"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(11),
                                                    _vm._v(" "),
                                                    _c("label", [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.infos[
                                                              "accountType"
                                                            ]
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(12),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.infos[
                                                                "address"
                                                              ]["street-1"]
                                                            ) +
                                                              ", " +
                                                              _vm._s(
                                                                _vm.infos[
                                                                  "address"
                                                                ]["street-2"]
                                                              )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.infos[
                                                                "address"
                                                              ]["city"]
                                                            ) +
                                                              ", " +
                                                              _vm._s(
                                                                _vm.infos[
                                                                  "address"
                                                                ]["region"]
                                                              ) +
                                                              ", " +
                                                              _vm._s(
                                                                _vm.infos[
                                                                  "address"
                                                                ]["postal-code"]
                                                              )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.infos[
                                                                "address"
                                                              ]["country"]
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(13),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "bankName"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._m(14),
                                                _vm._v(" "),
                                                _vm._m(15),
                                                _vm._v(" "),
                                                _vm._m(16),
                                                _vm._v(" "),
                                                _vm._m(17),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(18),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    FBO Solid at Evolve, FFC " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "accountName"
                                                              ]
                                                            ) +
                                                            "," +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "accountNumber"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._m(19)
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.form.deposit_type == "ach"
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(20),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "accountNumber"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(21),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "routingNumber"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(22),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "bankName"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(23),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "accountType"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(24),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "accountName"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._m(25)
                                              ]
                                            : _vm._e()
                                        ]
                                      : _vm.infos["provider"] == "Think Green"
                                      ? [
                                          [
                                            _c(
                                              "h3",
                                              {
                                                staticClass: "subtitle",
                                                staticStyle: {
                                                  "text-decoration": "underline"
                                                }
                                              },
                                              [_vm._v("Domestic Wire")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [_vm._v("Bank Information")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(26),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["bankName"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(27),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "bankAddress"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(28),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "bank-phone"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(29),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "routingNumber"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("h3", {
                                              staticClass: "subtitle"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [
                                                _vm._v(
                                                  "Beneficiary Information"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(30),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["creditTo"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(31),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["address"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(32),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "accountNumber"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(33),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["reference"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          _vm._v(" "),
                                          [
                                            _c("h3", {
                                              staticClass: "subtitle"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "h3",
                                              {
                                                staticClass: "subtitle",
                                                staticStyle: {
                                                  "text-decoration": "underline"
                                                }
                                              },
                                              [_vm._v("International Wire")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [_vm._v("Bank Information")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(34),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["bankName"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(35),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "bankAddress"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(36),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "bank-phone"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(37),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["swiftCode"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("h3", {
                                              staticClass: "subtitle"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [
                                                _vm._v(
                                                  "Beneficiary Information"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(38),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["creditTo"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(39),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["address"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(40),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "accountNumber"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(41),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["reference"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("h3", {
                                              staticClass: "subtitle"
                                            }),
                                            _vm._v(" "),
                                            _vm._m(42)
                                          ]
                                        ]
                                      : _vm.infos["provider"] == "Argense"
                                      ? [
                                          [
                                            _c(
                                              "h3",
                                              {
                                                staticClass: "subtitle",
                                                staticStyle: {
                                                  "text-decoration": "underline"
                                                }
                                              },
                                              [_vm._v("Domestic Wire")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [_vm._v("Bank Information")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(43),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["bankName"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(44),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "bankAddress"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(45),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "bank-phone"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(46),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "routingNumber"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("h3", {
                                              staticClass: "subtitle"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [
                                                _vm._v(
                                                  "Beneficiary Information"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(47),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["creditTo"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(48),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["address"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(49),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "accountNumber"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(50),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["reference"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          _vm._v(" "),
                                          [
                                            _c("h3", {
                                              staticClass: "subtitle"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "h3",
                                              {
                                                staticClass: "subtitle",
                                                staticStyle: {
                                                  "text-decoration": "underline"
                                                }
                                              },
                                              [_vm._v("International Wire")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [_vm._v("Bank Information")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(51),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["bankName"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(52),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "bankAddress"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(53),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "bank-phone"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(54),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["swiftCode"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("h3", {
                                              staticClass: "subtitle"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [
                                                _vm._v(
                                                  "Beneficiary Information"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(55),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["creditTo"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(56),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["address"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(57),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos[
                                                            "accountNumber"
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _vm._m(58),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "notranslate"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.infos["reference"]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("h3", {
                                              staticClass: "subtitle"
                                            }),
                                            _vm._v(" "),
                                            _vm._m(59)
                                          ]
                                        ]
                                      : [
                                          _vm.form.deposit_type ==
                                          "domestic_wire"
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(60),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "depository-bank-name"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(61),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "bank-address"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(62),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "bank-phone"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(63),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "routing-number"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(64),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "credit-to"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(65),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "beneficiary-address"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(66),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "account-number"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(67),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "reference"
                                                              ]
                                                            ) +
                                                            ", " +
                                                            _vm._s(
                                                              _vm
                                                                .selectedAccount
                                                                .reg_name
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._m(68)
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.form.deposit_type ==
                                          "international_wires"
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(69),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "depository-bank-name"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(70),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "routing-number"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(71),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "swift-code"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(72),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "credit-to"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(73),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "beneficiary-address"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(74),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "account-number"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(75),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "reference"
                                                              ]
                                                            ) +
                                                            ", " +
                                                            _vm._s(
                                                              _vm
                                                                .selectedAccount
                                                                .reg_name
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(76),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "bank-address"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _vm._m(77),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "notranslate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.infos[
                                                                "bank-phone"
                                                              ]
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._m(78)
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.form.deposit_type == "ach"
                                            ? [
                                                _vm._v(
                                                  "\n                ACH deposit details are unavailable at this time.\n              "
                                                )
                                              ]
                                            : _vm._e()
                                        ]
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        !_vm.infos
                          ? _c(
                              "button",
                              {
                                staticClass: "main",
                                on: {
                                  click: function($event) {
                                    return _vm.paymentDetails()
                                  }
                                }
                              },
                              [_vm._v("Submit")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.infos
                          ? _c(
                              "a",
                              {
                                staticClass: "btn main",
                                attrs: {
                                  href:
                                    "/files/paymentDetails/" +
                                    _vm.form.account +
                                    "/" +
                                    _vm.form.account +
                                    "-payment_details.pdf",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("PDF")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.infos &&
                        _vm.form.deposit_type == "international_wires"
                          ? _c(
                              "a",
                              {
                                staticClass: "btn main",
                                attrs: {
                                  href:
                                    "/files/paymentDetails/" +
                                    _vm.form.account +
                                    "/" +
                                    _vm.form.account +
                                    "-payment_details_international.pdf",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("PDF")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.infos &&
                        _vm.form.deposit_type == "ach" &&
                        _vm.infos["provider"] == "Solid"
                          ? _c(
                              "a",
                              {
                                staticClass: "btn main",
                                attrs: {
                                  href:
                                    "/files/paymentDetails/" +
                                    _vm.form.account +
                                    "/" +
                                    _vm.form.account +
                                    "-payment_details_ach.pdf",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("PDF")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                _vm.open = false
                              }
                            }
                          },
                          [_vm._v("Close")]
                        )
                      ])
                    ])
                  : _c("div", { staticClass: "scrollable" }, [
                      _c(
                        "div",
                        { staticClass: "content-form content-form-modern" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-row form-split" },
                            [
                              _c("form-field-select-search", {
                                attrs: {
                                  label: "Account",
                                  validator: _vm.$v.form.account,
                                  options: _vm.accountOptions,
                                  "option-group": !_vm.isCustomer,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.account,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "account", $$v)
                                  },
                                  expression: "form.account"
                                }
                              }),
                              _vm._v(" "),
                              _c("form-field-select", {
                                attrs: {
                                  label: "Assets Type",
                                  validator: _vm.$v.form.assetsCurrency,
                                  options: _vm.assetsCurrencyOptions,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.assetsCurrency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "assetsCurrency", $$v)
                                  },
                                  expression: "form.assetsCurrency"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row payment-reference" }, [
                        _c("label"),
                        _vm._v(" "),
                        !_vm.infos
                          ? _c(
                              "button",
                              {
                                staticClass: "main",
                                on: {
                                  click: function($event) {
                                    return _vm.assetPaymentDetails()
                                  }
                                }
                              },
                              [_vm._v("Submit")]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content-form content-form-modern" },
                        [
                          _vm.infos
                            ? _c(
                                "h3",
                                { staticClass: "subtitle" },
                                [
                                  _vm._v("Asset Deposit Details"),
                                  _vm.selectedAccount
                                    ? [
                                        _vm._v(
                                          " - " +
                                            _vm._s(_vm.selectedAccount.label) +
                                            " Account"
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.infos
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-row form-split depositType"
                                  },
                                  [
                                    _c("label", [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.infos.currency) +
                                            " Wallet Address "
                                        )
                                      ])
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-row form-split depositType"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "wallet-address" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.infos.address) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _vm._m(79),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-row form-split depositType"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "wallet-address" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.infos.tag) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-row form-split" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "notranslate" },
                                      [
                                        _c("b", [_vm._v("Note:")]),
                                        _vm._v(
                                          " Send only " +
                                            _vm._s(_vm.infos.currency) +
                                            " to this wallet address. Any other asset type sent may be lost and unrecoverable.\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _vm.infos
                          ? _c(
                              "a",
                              {
                                staticClass: "btn main",
                                attrs: {
                                  href:
                                    "/files/paymentDetails/" +
                                    _vm.form.account +
                                    "/" +
                                    _vm.form.account +
                                    "-asset_payment_details.pdf",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("View")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                _vm.open = false
                              }
                            }
                          },
                          [_vm._v("Close")]
                        )
                      ])
                    ])
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popup-header" }, [
      _c("h3", [_vm._v("Deposit Payment Details")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Routing Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("br"),
      _vm._v(" "),
      _c("p", { staticClass: "notranslate" }, [
        _c("b", [_vm._v("Note: ")]),
        _vm._v(
          "Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                  "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Routing Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Type: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("label", [_c("b", [_vm._v("Bank Address: ")])]),
      _vm._v(" "),
      _c("label", { staticClass: "notranslate" }, [
        _vm._v(
          "\n                    6070 Poplar Ave, Suite 200, Memphis, TN 38119\n                  "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("p", [_vm._v("Correspondent/Intermediary Bank SWIFT: FRNAUS44")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("p", [
        _vm._v(
          "Correspondent/Intermediary Bank Name: First National Banker's Bank"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("p", [
        _vm._v(
          "Correspondent/Intermediary Bank Address: 7813 Office Park Blvd Baton Rouge, LA 70809"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Special instructions: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("br"),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("Note: ")]),
        _vm._v(
          "Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                  "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Routing Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Type: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("br"),
      _vm._v(" "),
      _c("p", { staticClass: "notranslate" }, [
        _c("b", [_vm._v("Note: ")]),
        _vm._v(
          "Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                  "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Phone: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Routing Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Credit To: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Reference: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Phone: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("SWIFT Code: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Credit To: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Reference: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("p", { staticClass: "notranslate" }, [
        _c("b", [_vm._v("Note: ")]),
        _vm._v("Your account number "),
        _c("b", [_vm._v("MUST")]),
        _vm._v(
          " be included in the reference field on all deposit transactions. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                  "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Phone: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Routing Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Credit To: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Reference: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Phone: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("SWIFT Code: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Credit To: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Reference: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("p", { staticClass: "notranslate" }, [
        _c("b", [_vm._v("Note: ")]),
        _vm._v("Your account number "),
        _c("b", [_vm._v("MUST")]),
        _vm._v(
          " be included in the reference field on all deposit transactions. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                  "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Phone: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Routing Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Credit To: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Reference Code: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("br"),
      _vm._v(" "),
      _c("p", { staticClass: "notranslate" }, [
        _c("b", [_vm._v("Note: ")]),
        _vm._v("Your Q-Code and account name "),
        _c("b", [_vm._v("MUST")]),
        _vm._v(
          " be included on all deposit transactions. ACH deposits are not accepted. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                  "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Name: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Routing Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank SWIFT Code: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Credit To: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Account Number: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Reference Code: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Address: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Bank Phone: ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("br"),
      _vm._v(" "),
      _c("p", { staticClass: "notranslate" }, [
        _c("b", [_vm._v("Note: ")]),
        _vm._v("Your Q-Code and account name "),
        _c("b", [_vm._v("MUST")]),
        _vm._v(
          " be included on all deposit transactions. ACH deposits are not accepted. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                  "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split depositType" }, [
      _c("label", [_c("b", [_vm._v(" Destination Tag ")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
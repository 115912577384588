var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "cp-box" },
      [
        _vm.canEdit
          ? [
              _c("div", { staticClass: "mail-buttons-container" }, [
                _vm.registration.status == "New"
                  ? _c("div", { staticClass: "cof-field mobinl" }, [
                      _c("label", [_vm._v(" ")]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.sendMail(
                                _vm.id,
                                "registrations",
                                "RegistrationEmail"
                              )
                            }
                          }
                        },
                        [_vm._v("Registration")]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.registration.status == "Complete" ||
                _vm.registration.status == "Activation"
                  ? _c("div", { staticClass: "cof-field mobinl" }, [
                      _c("label", [_vm._v(" ")]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.sendMail(
                                _vm.registration.user.id,
                                "users",
                                "CustomerWelcomeEmail"
                              )
                            }
                          }
                        },
                        [_vm._v("Activation")]
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("hr")
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("section", { staticClass: "content-filters" }, [
          _c("div", {}, [
            _c("div", { staticClass: "cof-row" }, [
              _c("div", { staticClass: "cof-field" }, [
                _c("label", [_vm._v("Date Search")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cof-multi flex" },
                  [
                    _c("date-time-picker", {
                      attrs: {
                        "value-type": "String",
                        "value-format": "yyyy-LL-dd",
                        format: "yyyy-LL-dd",
                        "time-picker": false,
                        "auto-close": true,
                        "max-date": _vm.today
                      },
                      model: {
                        value: _vm.searchForm.fromDate,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "fromDate", $$v)
                        },
                        expression: "searchForm.fromDate"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("To")]),
                    _vm._v(" "),
                    _c("date-time-picker", {
                      attrs: {
                        "value-type": "String",
                        "value-format": "yyyy-LL-dd",
                        format: "yyyy-LL-dd",
                        "time-picker": false,
                        "auto-close": true,
                        "max-date": _vm.today
                      },
                      model: {
                        value: _vm.searchForm.toDate,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "toDate", $$v)
                        },
                        expression: "searchForm.toDate"
                      }
                    }),
                    _vm._v("\n               \n              "),
                    _c(
                      "button",
                      {
                        staticClass: "main",
                        on: {
                          click: function($event) {
                            return _vm.search()
                          }
                        }
                      },
                      [_vm._v("Search")]
                    ),
                    _vm._v("\n               \n              "),
                    _c(
                      "button",
                      {
                        staticClass: "second",
                        on: {
                          click: function($event) {
                            return _vm.resetItem()
                          }
                        }
                      },
                      [_vm._v("Reset Filters")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
          [
            _c(
              "table",
              { staticClass: "content-table" },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm._l(_vm.filterEmails, function(email) {
                  return _c("tr", { key: email.id }, [
                    _c("td", [
                      _c("span", [_vm._v("Date/Time")]),
                      _vm._v(
                        _vm._s(
                          _vm._f("date")(email.createdAt, "MM/D/YYYY hh:mm A")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Type")]),
                      _vm._v(_vm._s(email.email_type))
                    ])
                  ])
                }),
                _vm._v(" "),
                !_vm.emails.length
                  ? _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "empty-table-text",
                          attrs: { colspan: "2" }
                        },
                        [_vm._v("There are no emails")]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("th", [_vm._v("Type")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.partner
    ? _c(
        "div",
        { staticClass: "cp-box" },
        [
          _c("ul", { staticClass: "sub-tabs" }, [
            _c(
              "li",
              {
                class: { active: _vm.activeTab === "compliance" },
                on: {
                  click: function($event) {
                    _vm.activeTab = "compliance"
                  }
                }
              },
              [_c("a", [_vm._v("Partner Review")])]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { active: _vm.activeTab === "account_status" },
                on: {
                  click: function($event) {
                    _vm.activeTab = "account_status"
                  }
                }
              },
              [_c("a", [_vm._v("Partner Status")])]
            ),
            _vm._v(" "),
            _vm.featureStatus
              ? _c(
                  "li",
                  {
                    class: { active: _vm.activeTab === "scans" },
                    on: {
                      click: function($event) {
                        _vm.activeTab = "scans"
                      }
                    }
                  },
                  [_c("a", [_vm._v("Scans")])]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.activeTab === "compliance"
            ? _c("compliance-settings", {
                attrs: { registration: _vm.partner }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === "scans"
            ? _c("compliance-scans", { attrs: { registration: _vm.partner } })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === "account_status"
            ? _c("compliance-account-status", {
                attrs: { registration: _vm.partner }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
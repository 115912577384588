var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("button", { staticClass: "btn main" }, [_vm._v("New Card")]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "content-tlist", staticStyle: { margin: "20px 0" } },
        [
          _c("table", { staticClass: "content-table" }, [
            _c("tr", [
              _c("th", [_vm._v("Date/Time")]),
              _vm._v(" "),
              _c("th", [_vm._v("Provider")]),
              _vm._v(" "),
              _c("th", [_vm._v("Card Type")]),
              _vm._v(" "),
              _c("th", [_vm._v("Card Number")]),
              _vm._v(" "),
              _c("th", [_vm._v("Options")])
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
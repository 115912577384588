var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("h2", [_vm._v("Account Creation")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "status" },
          [
            _vm._v("\n        Status\n        "),
            _c("status-icon", {
              attrs: { color: _vm.mainAccount ? "green" : null }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.featureMasterAccounts == null
        ? void 0
        : [
            _c(
              "table",
              {
                staticClass: "content-table",
                staticStyle: { "min-width": "unset" }
              },
              [
                _c("tr", [
                  _c("th", [_vm._v("Account Provider")]),
                  _vm._v(" "),
                  _vm.registration.type == "Business" &&
                  _vm.featureMasterAccounts
                    ? _c("th")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.registration.type == "Master Business" &&
                  _vm.featureMasterAccounts
                    ? _c("th")
                    : _vm._e(),
                  _vm._v(" "),
                  _c("th", [_vm._v("Label")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Type")]),
                  _vm._v(" "),
                  _vm.showOptions
                    ? _c("th", { staticStyle: { "text-align": "right" } })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Account")]),
                      _vm._v(" "),
                      _c("mnb-select", {
                        attrs: {
                          options: _vm.providerOptions,
                          disabled: !!_vm.serviceProviders
                        },
                        model: {
                          value: _vm.providerForm.provider,
                          callback: function($$v) {
                            _vm.$set(_vm.providerForm, "provider", $$v)
                          },
                          expression: "providerForm.provider"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.registration.type == "Business" &&
                  _vm.featureMasterAccounts
                    ? _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.setAsMaster($event)
                              }
                            }
                          },
                          [_vm._v("Master Account")]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.registration.type == "Master Business" &&
                  _vm.featureMasterAccounts
                    ? _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.setAsMaster($event)
                              }
                            }
                          },
                          [_vm._v("Remove Master Account")]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Account Label")]),
                      _vm._v(" "),
                      _vm.provider
                        ? [_vm._v(_vm._s(_vm.provider.label))]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Account Type")]),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.registration.type) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.showOptions ? _c("td") : _vm._e()
                ])
              ]
            ),
            _vm._v(" "),
            _vm.providerForm.provider
              ? _c(
                  "section",
                  {
                    staticClass: "content-tlist",
                    staticStyle: { margin: "30px 0" }
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "table",
                      {
                        staticClass: "content-table",
                        staticStyle: { "min-width": "unset" }
                      },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("tr", { staticClass: "content-filters" }, [
                          _c(
                            "td",
                            [
                              _c("span", [_vm._v("Services")]),
                              _vm._v(" "),
                              _vm.currentAccount
                                ? [
                                    _vm._v(
                                      _vm._s(_vm.currentAccount.accountNumber)
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("span", [_vm._v("Multi-currency")]),
                              _vm._v(" "),
                              !_vm.profileSettings
                                ? [
                                    _vm.featureFundsStatus &&
                                    _vm.provider.multiCurrency == 1
                                      ? _c("mnb-switch", {
                                          attrs: {
                                            validator:
                                              _vm.$v.profileSettingsform
                                                .multiCurrency
                                          },
                                          model: {
                                            value:
                                              _vm.profileSettingsform
                                                .multiCurrency,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.profileSettingsform,
                                                "multiCurrency",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "profileSettingsform.multiCurrency"
                                          }
                                        })
                                      : _c("mnb-switch", {
                                          attrs: { value: false, disabled: "" }
                                        })
                                  ]
                                : _c("mnb-switch", {
                                    attrs: {
                                      value:
                                        _vm.profileSettingsform.multiCurrency ==
                                        1,
                                      disabled: ""
                                    }
                                  })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("td", { attrs: { colspan: "6" } })
                        ]),
                        _vm._v(" "),
                        _vm._m(2)
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "table",
                      {
                        staticClass: "content-table",
                        staticStyle: { "min-width": "unset" }
                      },
                      [
                        this.registration.accounts.length <= 1
                          ? [
                              _vm._m(3),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", { staticStyle: { width: "15%" } }, [
                                  _vm._v("Verifications")
                                ]),
                                _vm._v(" "),
                                _c("th", { staticStyle: { width: "12%" } }, [
                                  _vm._v("Administrative")
                                ]),
                                _vm._v(" "),
                                _c("th", { staticStyle: { width: "12%" } }, [
                                  _vm._v("RDC")
                                ]),
                                _vm._v(" "),
                                !_vm.isPersonal
                                  ? _c(
                                      "th",
                                      { staticStyle: { width: "12%" } },
                                      [_vm._v("Double Verification")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("th", { attrs: { colspan: "4" } })
                              ]),
                              _vm._v(" "),
                              _c("tr", { staticClass: "content-filters" }, [
                                _vm._m(4),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("span", [_vm._v("Administrative")]),
                                    _vm._v(" "),
                                    !_vm.profileSettings
                                      ? _c("mnb-switch", {
                                          attrs: {
                                            validator:
                                              _vm.$v.profileSettingsform
                                                .administrative
                                          },
                                          model: {
                                            value:
                                              _vm.profileSettingsform
                                                .administrative,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.profileSettingsform,
                                                "administrative",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "profileSettingsform.administrative"
                                          }
                                        })
                                      : _c("mnb-switch", {
                                          attrs: {
                                            value:
                                              _vm.profileSettingsform
                                                .administrative == 1,
                                            disabled: ""
                                          }
                                        })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    !_vm.profileSettings
                                      ? _c("mnb-switch", {
                                          attrs: {
                                            validator:
                                              _vm.$v.profileSettingsform
                                                .remoteDepositCapture
                                          },
                                          model: {
                                            value:
                                              _vm.profileSettingsform
                                                .remoteDepositCapture,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.profileSettingsform,
                                                "remoteDepositCapture",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "profileSettingsform.remoteDepositCapture"
                                          }
                                        })
                                      : _c("mnb-switch", {
                                          attrs: {
                                            value:
                                              _vm.profileSettingsform
                                                .remoteDepositCapture == 1,
                                            disabled: ""
                                          }
                                        })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                !_vm.isPersonal
                                  ? _c(
                                      "td",
                                      [
                                        _c("span", [
                                          _vm._v("Double Verification")
                                        ]),
                                        _vm._v(" "),
                                        !_vm.profileSettings
                                          ? _c("mnb-switch", {
                                              attrs: {
                                                validator:
                                                  _vm.$v.profileSettingsform
                                                    .double_verification,
                                                disabled:
                                                  !_vm.isPersonal ||
                                                  !_vm.profileSettingsform
                                                    .account
                                              },
                                              model: {
                                                value:
                                                  _vm.profileSettingsform
                                                    .double_verification,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.profileSettingsform,
                                                    "double_verification",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "profileSettingsform.double_verification"
                                              }
                                            })
                                          : _c("mnb-switch", {
                                              attrs: {
                                                value:
                                                  _vm.profileSettingsform
                                                    .double_verification == 1,
                                                disabled: ""
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", { attrs: { colspan: "4" } })
                              ]),
                              _vm._v(" "),
                              _c("tr", { staticClass: "space" }, [
                                _c("th", { staticStyle: { width: "15%" } }, [
                                  _vm._v("Permissions")
                                ]),
                                _vm._v(" "),
                                _c("th", { staticStyle: { width: "12%" } }, [
                                  _vm._v("Create Sub-Users")
                                ]),
                                _vm._v(" "),
                                _vm.isMasterBusiness
                                  ? _c(
                                      "th",
                                      { staticStyle: { width: "12%" } },
                                      [_vm._v("Create Sub-Profiles")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("th", { attrs: { colspan: "5" } })
                              ]),
                              _vm._v(" "),
                              _c("tr", { staticClass: "content-filters" }, [
                                _vm._m(5),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("span", [_vm._v("Create Sub-Users")]),
                                    _vm._v(" "),
                                    !_vm.profileSettings
                                      ? _c("mnb-switch", {
                                          attrs: {
                                            validator:
                                              _vm.$v.profileSettingsform
                                                .create_subusers
                                          },
                                          model: {
                                            value:
                                              _vm.profileSettingsform
                                                .create_subusers,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.profileSettingsform,
                                                "create_subusers",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "profileSettingsform.create_subusers"
                                          }
                                        })
                                      : _c("mnb-switch", {
                                          attrs: {
                                            value:
                                              _vm.profileSettingsform
                                                .create_subusers == 1,
                                            disabled: ""
                                          }
                                        })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.isMasterBusiness
                                  ? _c(
                                      "td",
                                      [
                                        _c("span", [
                                          _vm._v("Create Sub-Profiles")
                                        ]),
                                        _vm._v(" "),
                                        !_vm.profileSettings
                                          ? _c("mnb-switch", {
                                              attrs: {
                                                value:
                                                  _vm.profileSettingsform
                                                    .create_subprofiles,
                                                validator:
                                                  _vm.$v.profileSettingsform
                                                    .create_subprofiles,
                                                disabled: ""
                                              }
                                            })
                                          : _c("mnb-switch", {
                                              attrs: {
                                                value:
                                                  _vm.profileSettingsform
                                                    .create_subprofiles == 1,
                                                disabled: ""
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", { attrs: { colspan: "5" } })
                              ]),
                              _vm._v(" "),
                              _vm._m(6),
                              _vm._v(" "),
                              _c("tr", { staticClass: "content-filters" }, [
                                _c("td"),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("span", [
                                      _vm._v("Send Welcome/Activation Email")
                                    ]),
                                    _vm._v(" "),
                                    !_vm.profileSettings
                                      ? _c("mnb-switch", {
                                          attrs: {
                                            validator:
                                              _vm.$v.profileSettingsform
                                                .welcome_email
                                          },
                                          model: {
                                            value:
                                              _vm.profileSettingsform
                                                .welcome_email,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.profileSettingsform,
                                                "welcome_email",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "profileSettingsform.welcome_email"
                                          }
                                        })
                                      : _c("mnb-switch", {
                                          attrs: {
                                            value:
                                              _vm.profileSettingsform
                                                .welcome_email == 1,
                                            disabled: ""
                                          }
                                        })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("span", [_vm._v("Profile Review Days")]),
                                    _vm._v(" "),
                                    !_vm.profileSettings
                                      ? _c("mnb-select", {
                                          staticClass: "select",
                                          attrs: {
                                            validator:
                                              _vm.$v.profileSettingsform
                                                .review_days,
                                            options: _vm.reviewOptions
                                          },
                                          model: {
                                            value:
                                              _vm.profileSettingsform
                                                .review_days,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.profileSettingsform,
                                                "review_days",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "profileSettingsform.review_days"
                                          }
                                        })
                                      : [
                                          _vm._v(
                                            _vm._s(
                                              _vm.profileSettingsform
                                                .review_days
                                            )
                                          )
                                        ]
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("span", [_vm._v("Profile Language")]),
                                    _vm._v(" "),
                                    !_vm.profileSettings
                                      ? _c("mnb-select", {
                                          staticClass: "select",
                                          attrs: {
                                            validator:
                                              _vm.$v.profileSettingsform
                                                .language,
                                            options: _vm.languageOptions
                                          },
                                          model: {
                                            value:
                                              _vm.profileSettingsform.language,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.profileSettingsform,
                                                "language",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "profileSettingsform.language"
                                          }
                                        })
                                      : [
                                          _vm._v(
                                            _vm._s(
                                              _vm.profileSettingsform.language
                                            )
                                          )
                                        ]
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("td", { attrs: { colspan: "4" } })
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "table",
              {
                staticClass: "content-table",
                staticStyle: { "min-width": "unset" }
              },
              [
                _c("tr", [
                  _vm._m(7),
                  _vm._v(" "),
                  _vm.registration.status !== "Complete"
                    ? _c(
                        "td",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c("span", [_vm._v("Options")]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Options")]),
                          _vm._v(" "),
                          this.registration.accounts.length < 1 && !_vm.form
                            ? _c(
                                "button",
                                {
                                  staticClass: "main",
                                  attrs: {
                                    disabled:
                                      !_vm.isAvailable ||
                                      _vm.$v.providerForm.$invalid
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.run()
                                    }
                                  }
                                },
                                [_vm._v("Confirm")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          this.registration.accounts[
                            this.registration.accounts.length - 1
                          ] && !_vm.form
                            ? [
                                this.registration.accounts[
                                  this.registration.accounts.length - 1
                                ].accountStatus == "New"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "main",
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmNewAccount()
                                          }
                                        }
                                      },
                                      [_vm._v("Confirm")]
                                    )
                                  : _vm._e()
                              ]
                            : _vm.mainAccount &&
                              _vm.registration.status != "Active"
                            ? _c(
                                "button",
                                {
                                  staticClass: "second",
                                  on: {
                                    click: function($event) {
                                      return _vm.remove()
                                    }
                                  }
                                },
                                [_vm._v("Reset")]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "footer space" }, [
              _c(
                "div",
                { staticClass: "datetime", staticStyle: { color: "inherit" } },
                [
                  _c("span", [_vm._v("Date/Time")]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  !_vm.currentAccount
                    ? [_vm._v("N/A")]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm._f("date")(_vm.currentAccount.createdAt, "LL")
                          ) +
                            " " +
                            _vm._s(
                              _vm._f("date")(_vm.currentAccount.createdAt, "LT")
                            )
                        )
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _c("br")
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h2", [_vm._v("Account Settings")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Account")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "15%" } }, [
        _vm._v("Multi"),
        _c("br"),
        _vm._v("Currency")
      ]),
      _vm._v(" "),
      _c("th", { attrs: { colspan: "6" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "content-filters" }, [
      _c("th", { attrs: { colspan: "8" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "space" }, [
      _c("td", { attrs: { colspan: "11" } }, [
        _c("h2", { staticClass: "profile-settings" }, [
          _vm._v("Profile Settings")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Verifications")]), _vm._v(" Enable")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Permissions")]), _vm._v(" Enable")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "space" }, [
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Options")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [
        _vm._v("Welcome "),
        _c("br"),
        _vm._v("Email")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [
        _vm._v("Review  "),
        _c("br"),
        _vm._v("Days")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("Profile Language")]),
      _vm._v(" "),
      _c("th", { attrs: { colspan: "4" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "10" } }, [
      _c("span", [_vm._v("Account")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
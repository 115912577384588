import { render, staticRenderFns } from "./AccountLinkPanel.vue?vue&type=template&id=57899e23&scoped=true&"
import script from "./AccountLinkPanel.vue?vue&type=script&lang=js&"
export * from "./AccountLinkPanel.vue?vue&type=script&lang=js&"
import style0 from "./AccountLinkPanel.vue?vue&type=style&index=0&id=57899e23&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57899e23",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6monbi/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57899e23')) {
      api.createRecord('57899e23', component.options)
    } else {
      api.reload('57899e23', component.options)
    }
    module.hot.accept("./AccountLinkPanel.vue?vue&type=template&id=57899e23&scoped=true&", function () {
      api.rerender('57899e23', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/monbi/transactions/AccountLinkPanel.vue"
export default component.exports
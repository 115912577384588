var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
    [
      _c("h2", [_vm._v("Profile Settings")]),
      _vm._v(" "),
      _vm.featureFundsStatus != null
        ? [
            _c(
              "table",
              { staticClass: "content-table" },
              [
                _vm.hideVerificationPermissionsReview
                  ? [
                      _c("tr", { staticClass: "space" }, [
                        _c("th", { attrs: { width: "200" } }, [
                          _vm._v("Verifications")
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Administrative")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Remote Deposit Capture")]),
                        _vm._v(" "),
                        !_vm.isPersonal
                          ? _c("th", [_vm._v("Double Verification")])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "content-filters" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Administrative")]),
                            _vm._v(" "),
                            _c("mnb-switch", {
                              attrs: {
                                validator: _vm.$v.form.administrative,
                                disabled: !_vm.updateSettings
                              },
                              model: {
                                value: _vm.form.administrative,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "administrative", $$v)
                                },
                                expression: "form.administrative"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Remote Deposit Capture")]),
                            _vm._v(" "),
                            _c("mnb-switch", {
                              attrs: {
                                validator: _vm.$v.form.remoteDepositCapture,
                                disabled: !_vm.updateSettings
                              },
                              model: {
                                value: _vm.form.remoteDepositCapture,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "remoteDepositCapture",
                                    $$v
                                  )
                                },
                                expression: "form.remoteDepositCapture"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.isPersonal
                          ? _c(
                              "td",
                              [
                                _c("span", [_vm._v("Double Verification")]),
                                _vm._v(" "),
                                _c("mnb-switch", {
                                  attrs: {
                                    validator: _vm.$v.form.double_verification,
                                    disabled: ""
                                  },
                                  model: {
                                    value: _vm.form.double_verification,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "double_verification",
                                        $$v
                                      )
                                    },
                                    expression: "form.double_verification"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", { attrs: { colspan: "7" } })
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "space" }, [
                        _c("th", { attrs: { width: "200" } }, [
                          _vm._v("Permissions")
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Create Sub-Users")]),
                        _vm._v(" "),
                        _vm.isMasterBusiness
                          ? _c("th", [_vm._v("Create Sub-Profiles")])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "content-filters" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Create Sub-Users")]),
                            _vm._v(" "),
                            _c("mnb-switch", {
                              attrs: {
                                validator: _vm.$v.form.create_subusers,
                                disabled: !_vm.updateSettings
                              },
                              model: {
                                value: _vm.form.create_subusers,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "create_subusers", $$v)
                                },
                                expression: "form.create_subusers"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.isMasterBusiness
                          ? _c(
                              "td",
                              [
                                _c("span", [_vm._v("Create Sub-Profiles")]),
                                _vm._v(" "),
                                _c("mnb-switch", {
                                  attrs: {
                                    validator: _vm.$v.form.create_subprofiles,
                                    disabled: ""
                                  },
                                  model: {
                                    value: _vm.form.create_subprofiles,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "create_subprofiles",
                                        $$v
                                      )
                                    },
                                    expression: "form.create_subprofiles"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", { attrs: { colspan: "7" } })
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", { staticClass: "space" }, [
                  _c("th", { attrs: { width: "200" } }, [_vm._v("Options")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Send Confirmation Emails")]),
                  _vm._v(" "),
                  _vm.hideVerificationPermissionsReview
                    ? _c("th", [_vm._v("Profile Review Days")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("th", [_vm._v("Profile Language")])
                ]),
                _vm._v(" "),
                _c("tr", { staticClass: "content-filters" }, [
                  _c("td"),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Send Confirmation Emails")]),
                      _vm._v(" "),
                      _c("mnb-switch", {
                        attrs: {
                          validator: _vm.$v.form.welcome_email,
                          disabled: !_vm.updateSettings
                        },
                        model: {
                          value: _vm.form.welcome_email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "welcome_email", $$v)
                          },
                          expression: "form.welcome_email"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hideVerificationPermissionsReview
                    ? _c(
                        "td",
                        [
                          _c("span", [_vm._v("Profile Review Days")]),
                          _vm._v(" "),
                          _vm.updateSettings
                            ? _c("mnb-select", {
                                staticClass: "select",
                                attrs: {
                                  validator: _vm.$v.form.review_days,
                                  options: _vm.reviewOptions
                                },
                                model: {
                                  value: _vm.form.review_days,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "review_days", $$v)
                                  },
                                  expression: "form.review_days"
                                }
                              })
                            : [_vm._v(_vm._s(_vm.form.review_days))]
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Profile Language")]),
                      _vm._v(" "),
                      _vm.updateSettings
                        ? _c("mnb-select", {
                            staticClass: "select",
                            attrs: {
                              validator: _vm.$v.form.language,
                              options: _vm.languageOptions
                            },
                            model: {
                              value: _vm.form.language,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "language", $$v)
                              },
                              expression: "form.language"
                            }
                          })
                        : [_vm._v(_vm._s(_vm.form.language))]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "5" } })
                ])
              ],
              2
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.noDateUpdate
        ? _c("div", { staticClass: "footer space" }, [
            _c(
              "span",
              [
                _c("b", [_vm._v(" ")]),
                _c("br"),
                _vm._v(" "),
                _vm.updateSettings
                  ? [_vm._v(" ")]
                  : [_vm._v(" "), _c("br"), _vm._v(" ")]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.$hasPerm("update-profile")
                  ? [
                      _vm.updateSettings
                        ? _c(
                            "button",
                            {
                              staticClass: "main",
                              on: {
                                click: function($event) {
                                  return _vm.save()
                                }
                              }
                            },
                            [_vm._v("Save")]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "second",
                              on: {
                                click: function($event) {
                                  return _vm.update()
                                }
                              }
                            },
                            [_vm._v("Update")]
                          )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Verifications")]), _vm._v(" Enable")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Permissions")]), _vm._v(" Enable")])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      [
        _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              on: {
                click: function($event) {
                  _vm.activeInfo = "statements"
                }
              }
            },
            [_vm._v(" Statements")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              on: {
                click: function($event) {
                  _vm.activeInfo = "accounts"
                }
              }
            },
            [_vm._v(" Accounts")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              on: {
                click: function($event) {
                  return _vm.getLedgerBalance()
                }
              }
            },
            [_vm._v("Ledger Balance")]
          )
        ]),
        _vm._v(" "),
        _c(
          "popup",
          {
            attrs: { open: _vm.openLedger },
            on: {
              "update:open": function($event) {
                _vm.openLedger = $event
              }
            }
          },
          [
            _c("div", { staticClass: "popup-header" }, [
              _c("h3", [_vm._v("Ledger Balance")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "scrollable" }, [
              _c("div", { staticClass: "content-form" }, [
                _c(
                  "h3",
                  {
                    staticStyle: { "font-size": "20px", "font-weight": "bold" }
                  },
                  [_vm._v("Argense")]
                ),
                _vm._v(" "),
                _c(
                  "h3",
                  {
                    staticClass: "text-center",
                    staticStyle: { "font-size": "24px", "font-weight": "bold" }
                  },
                  [
                    _vm._v(
                      "\n          $ " +
                        _vm._s(this.ledgerBalance) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttons text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        _vm.openLedger = false
                      }
                    }
                  },
                  [_vm._v("Close")]
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _vm.activeInfo == "accounts" ? _c("corporate-accounts") : _vm._e(),
        _vm._v(" "),
        _vm.activeInfo == "transactions" ? _c("corp-transactions") : _vm._e(),
        _vm._v(" "),
        _vm.activeInfo == "statements" ? _c("corporate-statements") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
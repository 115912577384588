var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "div",
        { staticClass: "users-profile" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("section", { staticClass: "client-properties active" }, [
            _c(
              "div",
              { staticClass: "cp-box" },
              [
                _c(
                  "ul",
                  {
                    staticClass: "ctabs",
                    staticStyle: { "margin-bottom": "50px" }
                  },
                  [
                    _c(
                      "li",
                      {
                        class: { active: _vm.active === 1 },
                        on: {
                          click: function($event) {
                            _vm.active = 1
                          }
                        }
                      },
                      [_c("a", [_vm._v("My Info")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        class: { active: _vm.active === 2 },
                        on: {
                          click: function($event) {
                            _vm.active = 2
                          }
                        }
                      },
                      [_c("a", [_vm._v("My Accounts")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        class: { active: _vm.active === 3 },
                        on: {
                          click: function($event) {
                            _vm.active = 3
                          }
                        }
                      },
                      [_c("a", [_vm._v("My Settings")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        class: { active: _vm.active === 4 },
                        on: {
                          click: function($event) {
                            _vm.active = 4
                          }
                        }
                      },
                      [_c("a", [_vm._v("My Access")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        class: { active: _vm.active === 5 },
                        on: {
                          click: function($event) {
                            _vm.active = 5
                          }
                        }
                      },
                      [_c("a", [_vm._v("Change Password")])]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.active === 1
                  ? _c("div", [
                      _c("ul", { staticClass: "sub-tabs" }, [
                        _c(
                          "li",
                          {
                            class: { active: _vm.subActive === "profile" },
                            on: {
                              click: function($event) {
                                _vm.subActive = "profile"
                              }
                            }
                          },
                          [_c("a", [_vm._v("Profile")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            class: { active: _vm.subActive === "Contacts" },
                            on: {
                              click: function($event) {
                                _vm.subActive = "Contacts"
                              }
                            }
                          },
                          [_c("a", [_vm._v("Contacts")])]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.subActive === "profile"
                        ? _c(
                            "div",
                            [
                              _vm.registration.type === "Business"
                                ? _c("registration-data-info", {
                                    ref: "contacts",
                                    attrs: {
                                      registration: _vm.registration,
                                      hideContacts: "",
                                      showEdit: false
                                    }
                                  })
                                : _c("registration-data-info", {
                                    ref: "contacts",
                                    attrs: {
                                      registration: _vm.registration,
                                      hideContacts: "",
                                      showEdit: false
                                    }
                                  })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subActive === "Contacts"
                        ? _c(
                            "div",
                            [
                              _c("contacts", {
                                attrs: {
                                  registration: _vm.registration,
                                  showEditAdd: false
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.active === 2
                  ? _c("div", [
                      _c("ul", { staticClass: "sub-tabs" }, [
                        _c(
                          "li",
                          {
                            class: { active: _vm.accoutsActive === "accounts" },
                            on: {
                              click: function($event) {
                                _vm.accoutsActive = "accounts"
                              }
                            }
                          },
                          [_c("a", [_vm._v("Accounts")])]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.accoutsActive === "accounts"
                        ? _c("div", [
                            _c(
                              "div",
                              [
                                _c(
                                  "section",
                                  {
                                    staticClass: "content-filters",
                                    staticStyle: { margin: "0 0 30px 0" }
                                  },
                                  [
                                    _c("div", { staticClass: "cof-form" }, [
                                      _c("div", { staticClass: "cof-row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "cof-field" },
                                          [
                                            _c("label", [
                                              _vm._v("Select Account")
                                            ]),
                                            _vm._v(" "),
                                            _c("mnb-select", {
                                              attrs: {
                                                options: _vm.accountOptions
                                              },
                                              model: {
                                                value: _vm.mainAccount,
                                                callback: function($$v) {
                                                  _vm.mainAccount = $$v
                                                },
                                                expression: "mainAccount"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.mainAccount
                                  ? [
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "content-table-split",
                                            staticStyle: {
                                              display: "flex",
                                              "column-gap": "50px"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticStyle: { width: "30%" } },
                                              [
                                                _c(
                                                  "h2",
                                                  { staticClass: "balance" },
                                                  [_vm._v("Details")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "section",
                                                  {
                                                    staticClass:
                                                      "content-tlist",
                                                    staticStyle: {
                                                      margin: "30px 0"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "content-table"
                                                      },
                                                      [
                                                        _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "content-filters"
                                                          },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "title"
                                                              },
                                                              [_vm._v("Number")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.account
                                                                    .accountNumber
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "title"
                                                            },
                                                            [_vm._v("Label")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.account
                                                                  .label
                                                              )
                                                            )
                                                          ])
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "content-filters"
                                                          },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "title"
                                                              },
                                                              [_vm._v("Status")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _c(
                                                                "em",
                                                                {
                                                                  class:
                                                                    _vm
                                                                      .statusColorClasses[
                                                                      _vm.getStatus(
                                                                        _vm
                                                                          .account
                                                                          .accountStatus
                                                                      )
                                                                    ]
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.getStatus(
                                                                          _vm
                                                                            .account
                                                                            .accountStatus
                                                                        )
                                                                      ) +
                                                                      "\n                            "
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "content-filters"
                                                          },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "title"
                                                              },
                                                              [_vm._v("Key")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.account
                                                                      .accountKey
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "content-filters"
                                                          },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "title"
                                                              },
                                                              [_vm._v("Plan")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.account
                                                                      .accountPlan
                                                                      ? _vm
                                                                          .account
                                                                          .feePlan
                                                                          .name
                                                                      : ""
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "title"
                                                            },
                                                            [_vm._v("Settings")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "link",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.modalFeeScheduleType =
                                                                      "Fee"
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("View")]
                                                            )
                                                          ])
                                                        ]),
                                                        _vm._v(" "),
                                                        _vm._m(1),
                                                        _vm._v(" "),
                                                        _vm._m(2),
                                                        _vm._v(" "),
                                                        _vm._m(3)
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "footer space"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "second",
                                                                  staticStyle: {
                                                                    visibility:
                                                                      "hidden"
                                                                  }
                                                                },
                                                                [_vm._v(" ")]
                                                              )
                                                            ]
                                                          ],
                                                          2
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticStyle: { width: "30%" } },
                                              [
                                                _c(
                                                  "h2",
                                                  { staticClass: "balance" },
                                                  [_vm._v("Balances")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "section",
                                                  {
                                                    staticClass:
                                                      "content-tlist",
                                                    staticStyle: {
                                                      margin: "30px 0"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "content-table"
                                                      },
                                                      [
                                                        _vm._m(4),
                                                        _vm._v(" "),
                                                        _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "title"
                                                            },
                                                            [_vm._v("Balance")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "balance"
                                                                )(
                                                                  _vm.account
                                                                    .systemTotalBalance
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Available"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "balance"
                                                                )(
                                                                  _vm.account
                                                                    .systemAvailableBalance
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]),
                                                        _vm._v(" "),
                                                        _vm._m(5),
                                                        _vm._v(" "),
                                                        _vm._m(6),
                                                        _vm._v(" "),
                                                        _vm._m(7),
                                                        _vm._v(" "),
                                                        _vm._m(8),
                                                        _vm._v(" "),
                                                        _vm._m(9),
                                                        _vm._v(" "),
                                                        _vm._m(10)
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "footer space"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "second",
                                                                  staticStyle: {
                                                                    visibility:
                                                                      "hidden"
                                                                  }
                                                                },
                                                                [_vm._v(" ")]
                                                              )
                                                            ]
                                                          ],
                                                          2
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.account.profileSettings &&
                                            _vm.account.profileSettings
                                              .debitCards
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "30%"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass: "balance"
                                                      },
                                                      [_vm._v("Cards")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "section",
                                                      {
                                                        staticClass:
                                                          "content-tlist",
                                                        staticStyle: {
                                                          margin: "30px 0"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "table",
                                                          {
                                                            staticClass:
                                                              "content-table"
                                                          },
                                                          [
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "title"
                                                                },
                                                                [_vm._v("Type")]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _vm.account
                                                                    .cards
                                                                    .length >
                                                                    0 &&
                                                                  _vm.account
                                                                    .cards[
                                                                    _vm.account
                                                                      .cards
                                                                      .length -
                                                                      1
                                                                  ]
                                                                    .cardStatus &&
                                                                  _vm.account
                                                                    .cards[
                                                                    _vm.account
                                                                      .cards
                                                                      .length -
                                                                      1
                                                                  ].cardType
                                                                    ? [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              _vm.account.cards[
                                                                                _vm
                                                                                  .account
                                                                                  .cards
                                                                                  .length -
                                                                                  1
                                                                              ].cardType
                                                                                .charAt(
                                                                                  0
                                                                                )
                                                                                .toUpperCase()
                                                                            ) +
                                                                            _vm._s(
                                                                              _vm.account.cards[
                                                                                _vm
                                                                                  .account
                                                                                  .cards
                                                                                  .length -
                                                                                  1
                                                                              ].cardType.slice(
                                                                                1
                                                                              )
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "title"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Label"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _vm.account
                                                                    .cards
                                                                    .length >
                                                                    0 &&
                                                                  _vm.account
                                                                    .cards[
                                                                    _vm.account
                                                                      .cards
                                                                      .length -
                                                                      1
                                                                  ].cardStatus
                                                                    ? [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .account
                                                                                .cards[
                                                                                _vm
                                                                                  .account
                                                                                  .cards
                                                                                  .length -
                                                                                  1
                                                                              ]
                                                                                .label
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "title"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Status"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("td", [
                                                                _vm.account
                                                                  .cards
                                                                  .length > 0 &&
                                                                _vm.account
                                                                  .cards[
                                                                  _vm.account
                                                                    .cards
                                                                    .length - 1
                                                                ].cardStatus
                                                                  ? _c(
                                                                      "em",
                                                                      {
                                                                        class:
                                                                          _vm
                                                                            .statusColorClasses[
                                                                            _vm
                                                                              .account
                                                                              .cards[
                                                                              _vm
                                                                                .account
                                                                                .cards
                                                                                .length -
                                                                                1
                                                                            ]
                                                                              .cardStatus
                                                                          ]
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .account
                                                                                .cards[
                                                                                _vm
                                                                                  .account
                                                                                  .cards
                                                                                  .length -
                                                                                  1
                                                                              ]
                                                                                .cardStatus
                                                                            )
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ])
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "title"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Number"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _vm.account
                                                                    .cards
                                                                    .length >
                                                                    0 &&
                                                                  _vm.account
                                                                    .cards[
                                                                    _vm.account
                                                                      .cards
                                                                      .length -
                                                                      1
                                                                  ].cardStatus
                                                                    ? [
                                                                        _vm._v(
                                                                          "\n                              " +
                                                                            _vm._s(
                                                                              _vm.account.cards[
                                                                                _vm
                                                                                  .account
                                                                                  .cards
                                                                                  .length -
                                                                                  1
                                                                              ].cardLast4.substr(
                                                                                0,
                                                                                6
                                                                              )
                                                                            ) +
                                                                            "******" +
                                                                            _vm._s(
                                                                              _vm.account.cards[
                                                                                _vm
                                                                                  .account
                                                                                  .cards
                                                                                  .length -
                                                                                  1
                                                                              ].cardLast4.substr(
                                                                                -4
                                                                              )
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "title"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Expiry"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _vm.account
                                                                    .cards
                                                                    .length >
                                                                    0 &&
                                                                  _vm.account
                                                                    .cards[
                                                                    _vm.account
                                                                      .cards
                                                                      .length -
                                                                      1
                                                                  ].cardStatus
                                                                    ? [
                                                                        _vm._v(
                                                                          "\n                              " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .account
                                                                                .cards[
                                                                                _vm
                                                                                  .account
                                                                                  .cards
                                                                                  .length -
                                                                                  1
                                                                              ]
                                                                                .expiryMonth
                                                                            ) +
                                                                            " / " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .account
                                                                                .cards[
                                                                                _vm
                                                                                  .account
                                                                                  .cards
                                                                                  .length -
                                                                                  1
                                                                              ]
                                                                                .expiryYear
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "title"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Settings"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _vm.account
                                                                    .cards
                                                                    .length >
                                                                    0 &&
                                                                  _vm.account
                                                                    .cards[
                                                                    _vm.account
                                                                      .cards
                                                                      .length -
                                                                      1
                                                                  ].cardStatus
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticStyle: {
                                                                            cursor:
                                                                              "pointer"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.openCard = true
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Settings"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    "\n                               \n                            "
                                                                  ),
                                                                  _vm.account
                                                                    .cards
                                                                    .length >
                                                                    0 &&
                                                                  _vm.account
                                                                    .cards[
                                                                    _vm.account
                                                                      .cards
                                                                      .length -
                                                                      1
                                                                  ].cardStatus
                                                                    ? [
                                                                        _vm
                                                                          .account
                                                                          .cards[
                                                                          _vm
                                                                            .account
                                                                            .cards
                                                                            .length -
                                                                            1
                                                                        ]
                                                                          .cardStatus ==
                                                                          "pendingActivation" &&
                                                                        _vm
                                                                          .account
                                                                          .cards[
                                                                          _vm
                                                                            .account
                                                                            .cards
                                                                            .length -
                                                                            1
                                                                        ]
                                                                          .cardType !=
                                                                          "virtual"
                                                                          ? _c(
                                                                              "a",
                                                                              {
                                                                                staticStyle: {
                                                                                  cursor:
                                                                                    "pointer"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.openDelivery = true
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Delivery"
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _vm._m(11),
                                                            _vm._v(" "),
                                                            _vm._m(12),
                                                            _vm._v(" "),
                                                            _c(
                                                              "tr",
                                                              {
                                                                staticClass:
                                                                  "content-filters"
                                                              },
                                                              [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    attrs: {
                                                                      colspan:
                                                                        "2"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "main",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.openViewCard = true
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "View Card"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "footer space"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              [
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "second",
                                                                      staticStyle: {
                                                                        visibility:
                                                                          "hidden"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ],
                                                              2
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.active === 3
                  ? _c("div", [
                      _c("ul", { staticClass: "sub-tabs" }, [
                        _c(
                          "li",
                          {
                            class: {
                              active: _vm.settingsActive === "settings"
                            },
                            on: {
                              click: function($event) {
                                _vm.settingsActive = "settings"
                              }
                            }
                          },
                          [_c("a", [_vm._v("Settings")])]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.settingsActive === "settings"
                        ? _c(
                            "div",
                            [
                              _c("settings-setup", {
                                attrs: {
                                  registration: _vm.registration,
                                  noDateUpdate: false,
                                  hideVerificationPermissionsReview: false
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.settingsActive === "fee"
                        ? _c(
                            "div",
                            [
                              _c("fees-schedule", {
                                attrs: {
                                  registration: _vm.registration,
                                  noUpdate: false
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.active === 4
                  ? _c("div", [
                      _c("ul", { staticClass: "sub-tabs" }, [
                        _c(
                          "li",
                          {
                            class: { active: _vm.accessActive === "users" },
                            on: {
                              click: function($event) {
                                _vm.accessActive = "users"
                              }
                            }
                          },
                          [_c("a", [_vm._v("Users")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            class: { active: _vm.accessActive === "userLog" },
                            on: {
                              click: function($event) {
                                _vm.accessActive = "userLog"
                              }
                            }
                          },
                          [_c("a", [_vm._v("User Log")])]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.accessActive === "users"
                        ? _c("div", [
                            _c(
                              "div",
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-block",
                                    attrs: { disabled: !_vm.selected },
                                    on: {
                                      click: function($event) {
                                        return _vm.block()
                                      }
                                    }
                                  },
                                  [_vm._v("Block")]
                                ),
                                _vm._v(" "),
                                _vm.registration.profileSettings.create_subusers
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: {
                                          click: function($event) {
                                            return _vm.openAddUser()
                                          }
                                        }
                                      },
                                      [_vm._v("Add Sub-User")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: {
                                      disabled:
                                        !_vm.selected ||
                                        _vm.selected.id ===
                                          _vm.registration.user.id
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.remove()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Remove Sub-User\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "section",
                                  {
                                    staticClass: "content-tlist",
                                    staticStyle: { margin: "30px 0" }
                                  },
                                  [
                                    _c(
                                      "table",
                                      { staticClass: "content-table" },
                                      [
                                        _vm._m(13),
                                        _vm._v(" "),
                                        _vm._l(_vm.users, function(user, i) {
                                          return _c("tr", { key: i }, [
                                            _c("td", [
                                              _c("span"),
                                              _vm._v(" "),
                                              _c("input", {
                                                attrs: {
                                                  type: "radio",
                                                  name: "access-user"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.onCbxClicked(
                                                      user
                                                    )
                                                  }
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [_vm._v("Username")]),
                                              _vm._v(_vm._s(user.username))
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [
                                                _vm._v("First Name")
                                              ]),
                                              _vm._v(_vm._s(user.firstName))
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [_vm._v("Last Name")]),
                                              _vm._v(_vm._s(user.lastName))
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [
                                                _vm._v("Activation Date")
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("date")(
                                                    user.activationDate,
                                                    "MM/D/YYYY hh:mm A"
                                                  )
                                                )
                                              )
                                            ])
                                          ])
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "popup",
                                  {
                                    attrs: { open: _vm.addModal },
                                    on: {
                                      "update:open": function($event) {
                                        _vm.addModal = $event
                                      }
                                    }
                                  },
                                  [
                                    _vm.addModal
                                      ? [
                                          _c(
                                            "h4",
                                            { staticClass: "popup-title" },
                                            [_vm._v("Add Sub-User")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "scrollable" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "content-form" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row form-split"
                                                    },
                                                    [
                                                      _c("form-field-text", {
                                                        attrs: {
                                                          maxlength: 25,
                                                          validator:
                                                            _vm.$v.form
                                                              .firstName,
                                                          label: "First Name",
                                                          required: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.firstName,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "firstName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.firstName"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("form-field-text", {
                                                        attrs: {
                                                          maxlength: 25,
                                                          validator:
                                                            _vm.$v.form
                                                              .lastName,
                                                          label: "Last Name",
                                                          required: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.lastName,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "lastName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.lastName"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row form-split"
                                                    },
                                                    [
                                                      _c("form-field-text", {
                                                        attrs: {
                                                          validator:
                                                            _vm.$v.form.email,
                                                          label:
                                                            "Email Address",
                                                          required: ""
                                                        },
                                                        model: {
                                                          value: _vm.form.email,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "email",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.email"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("form-field-text", {
                                                        attrs: {
                                                          maxlength: 15,
                                                          validator:
                                                            _vm.$v.form.phone,
                                                          label: "Phone Number",
                                                          required: ""
                                                        },
                                                        model: {
                                                          value: _vm.form.phone,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "phone",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.phone"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "buttons" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "main",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.add()
                                                    }
                                                  }
                                                },
                                                [_vm._v("Add")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "second",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.addModal = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("Cancel")]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.accessActive === "userLog"
                        ? _c("div", [
                            _vm.lastLogin
                              ? _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "cp-fields justify-content-none"
                                  },
                                  [
                                    _c("li", [
                                      _c("span", [_vm._v("Last Login")]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "val" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("date")(
                                                _vm.lastLogin.recorded
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("span", [_vm._v("Data Transfer")]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "val" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("fileSize")(
                                                _vm.lastLogin.transferred
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("span", [_vm._v("IP Address")]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "val" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.lastLogin.ipAddress) +
                                            "\n              "
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.active === 5 ? _c("change-password") : _vm._e(),
                _vm._v(" "),
                _c(
                  "popup",
                  {
                    attrs: { open: _vm.openCard },
                    on: {
                      "update:open": function($event) {
                        _vm.openCard = $event
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "popup-header" }, [
                      _c("h3", [_vm._v("Card Settings")])
                    ]),
                    _vm._v(" "),
                    _vm.mainAccount && _vm.account.cards.length > 0
                      ? _c("div", { staticClass: "scrollable" }, [
                          _c("div", { staticClass: "content-form" }, [
                            _c("div", { staticClass: "form-row form-three" }, [
                              _c("div", [
                                _c("p", [_c("b", [_vm._v("ATM Access")])]),
                                _vm._v(" "),
                                _vm.account.cards[_vm.account.cards.length - 1]
                                  .atmAccess
                                  ? _c("p", [_vm._v("Yes")])
                                  : _c("p", [_vm._v("No")])
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", [_c("b", [_vm._v("Limit Amount")])]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  [
                                    _vm.account.cards[
                                      _vm.account.cards.length - 1
                                    ].limitAmount == 0 ||
                                    _vm.account.cards[
                                      _vm.account.cards.length - 1
                                    ].limitAmount == null
                                      ? [
                                          _vm._v(
                                            "\n                    None\n                  "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.account.cards[
                                                  _vm.account.cards.length - 1
                                                ].limitAmount
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", [_c("b", [_vm._v("Limit Interval")])]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  [
                                    _vm.account.cards[
                                      _vm.account.cards.length - 1
                                    ].limitInterval
                                      ? [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.account.cards[
                                                  _vm.account.cards.length - 1
                                                ].limitInterval
                                                  .charAt(0)
                                                  .toUpperCase()
                                              ) +
                                              _vm._s(
                                                _vm.account.cards[
                                                  _vm.account.cards.length - 1
                                                ].limitInterval.slice(1)
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      : [_vm._v("None")]
                                  ],
                                  2
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content-form" }, [
                            _c("p", [_c("b", [_vm._v("Allowed Categories")])]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.account.cards[
                                    _vm.account.cards.length - 1
                                  ].allowedCategories
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content-form" }, [
                            _c("p", [_c("b", [_vm._v("Blocked Categories")])]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.account.cards[
                                    _vm.account.cards.length - 1
                                  ].blockedCategories
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content-form" }, [
                            _c("p", [_c("b", [_vm._v("Allowed Merchants")])]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.account.cards[
                                    _vm.account.cards.length - 1
                                  ].allowedMerchants
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content-form" }, [
                            _c("p", [_c("b", [_vm._v("Blocked Merchants")])]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.account.cards[
                                    _vm.account.cards.length - 1
                                  ].blockedMerchants
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "buttons" }, [
                            _c(
                              "button",
                              {
                                staticClass: "second",
                                on: {
                                  click: function($event) {
                                    _vm.openCard = false
                                  }
                                }
                              },
                              [_vm._v("Close")]
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "popup",
                  {
                    attrs: { open: _vm.openDelivery },
                    on: {
                      "update:open": function($event) {
                        _vm.openDelivery = $event
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "popup-header" }, [
                      _c("h3", [_vm._v("Card Delivery")])
                    ]),
                    _vm._v(" "),
                    _vm.mainAccount && _vm.account.cards.length > 0
                      ? _c("div", { staticClass: "scrollable" }, [
                          _c("div", { staticClass: "content-form" }, [
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", [
                                _c("p", [_c("b", [_vm._v("Delivery Status")])]),
                                _vm._v(" "),
                                _vm.account.cards[_vm.account.cards.length - 1]
                                  .shipping &&
                                _vm.account.cards[_vm.account.cards.length - 1]
                                  .shipping.deliveryStatus
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.account.cards[
                                            _vm.account.cards.length - 1
                                          ].shipping.deliveryStatus
                                            .charAt(0)
                                            .toUpperCase()
                                        ) +
                                          _vm._s(
                                            _vm.account.cards[
                                              _vm.account.cards.length - 1
                                            ].shipping.deliveryStatus.slice(1)
                                          )
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", [_c("b", [_vm._v("ETA")])]),
                                _vm._v(" "),
                                _vm.account.cards[_vm.account.cards.length - 1]
                                  .shipping
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.account.cards[
                                              _vm.account.cards.length - 1
                                            ].shipping.eta
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", [
                                  _c("b", [_vm._v("Delivery Partner")])
                                ]),
                                _vm._v(" "),
                                _vm.account.cards[_vm.account.cards.length - 1]
                                  .shipping
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.account.cards[
                                              _vm.account.cards.length - 1
                                            ].shipping.deliveryPartner
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", [_c("b", [_vm._v("Tracking Number")])]),
                                _vm._v(" "),
                                _vm.account.cards[_vm.account.cards.length - 1]
                                  .shipping
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.account.cards[
                                              _vm.account.cards.length - 1
                                            ].shipping.trackingNumber
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.account.cards[_vm.account.cards.length - 1]
                            .shipping &&
                          _vm.account.cards[_vm.account.cards.length - 1]
                            .shipping.shippingAddress
                            ? _c("div", { staticClass: "content-form" }, [
                                _c("p", [
                                  _c("b", [_vm._v("Shipping Address")])
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "address-line" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.account.cards[
                                        _vm.account.cards.length - 1
                                      ].shipping.shippingAddress.line1
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "address-line" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.account.cards[
                                        _vm.account.cards.length - 1
                                      ].shipping.shippingAddress.line2
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "address-line" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.account.cards[
                                        _vm.account.cards.length - 1
                                      ].shipping.shippingAddress.city
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "address-line" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.account.cards[
                                        _vm.account.cards.length - 1
                                      ].shipping.shippingAddress.state
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "address-line" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.account.cards[
                                        _vm.account.cards.length - 1
                                      ].shipping.shippingAddress.country
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "address-line" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.account.cards[
                                        _vm.account.cards.length - 1
                                      ].shipping.shippingAddress.postalCode
                                    )
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "buttons" }, [
                            _c(
                              "button",
                              {
                                staticClass: "second",
                                on: {
                                  click: function($event) {
                                    _vm.openDelivery = false
                                  }
                                }
                              },
                              [_vm._v("Close")]
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "popup",
                  {
                    attrs: { open: _vm.openFee },
                    on: {
                      "update:open": function($event) {
                        _vm.openFee = $event
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "popup-header" }, [
                      _c("h3", [_vm._v("Fee Schedule")])
                    ]),
                    _vm._v(" "),
                    _vm.feeSchedule
                      ? _c("div", {}, [
                          _c("table", { staticClass: "content-table" }, [
                            _c("tr", [
                              _c("th", { staticStyle: { width: "15%" } }, [
                                _vm._v("Fee Name")
                              ]),
                              _vm._v(" "),
                              _c("th", { staticStyle: { width: "10%" } }, [
                                _vm._v("Fee")
                              ]),
                              _vm._v(" "),
                              _c("th", { staticStyle: { width: "10%" } }, [
                                _vm._v("Deposit")
                              ]),
                              _vm._v(" "),
                              _c("th", { staticStyle: { width: "10%" } }, [
                                _vm._v("Withdrawal")
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v(
                                  "\n                Volume Fee\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", [_vm._v("Fee")]),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.volumeFee.fee
                                      )
                                    ) +
                                    " %\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c("td")
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v(
                                  "\n                Monthly Access Fee\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", [_vm._v("Fee")]),
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.monthlyAccessFee.fee
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c("td")
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v(
                                  "\n                Domestic Wire\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [_c("span", [_vm._v("Fee")])]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.domesticWire.per_deposit
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.domesticWire
                                          .per_withdrawal
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v(
                                  "\n                International Wire\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [_c("span", [_vm._v("Fee")])]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.internationalWire
                                          .per_deposit
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.internationalWire
                                          .per_withdrawal
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v("\n                ACH\n              ")
                              ]),
                              _vm._v(" "),
                              _c("td", [_c("span", [_vm._v("Fee")])]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.ach.per_deposit
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.ach.per_withdrawal
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v(
                                  "\n                Account Transfer\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [_c("span", [_vm._v("Fee")])]),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.accountTransfer
                                          .per_withdrawal
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v(
                                  "\n                Checks\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [_c("span", [_vm._v("Fee")])]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.checks.per_deposit
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.checks.per_withdrawal
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v(
                                  "\n                Exchange\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", [_vm._v("Fee")]),
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.exchange.fee
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c("td")
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v(
                                  "\n                Assets\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", [_vm._v("Fee")]),
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.assets.fee
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.assets.per_deposit
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.assets.per_withdrawal
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v(
                                  "\n                Dormant Accounts\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", [_vm._v("Fee")]),
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.dormantAccounts.fee
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c("td")
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Fee Name")]),
                                _vm._v(
                                  "\n                Debit Card\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", [_vm._v("Fee")]),
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.debitCard.fee
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                  $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.debitCard.per_withdrawal
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "buttons" }, [
                            _c(
                              "button",
                              {
                                staticClass: "second",
                                on: {
                                  click: function($event) {
                                    _vm.openFee = false
                                  }
                                }
                              },
                              [_vm._v("Close")]
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "popup",
            {
              staticClass: "view-card",
              attrs: { open: _vm.openViewCard },
              on: {
                "update:open": function($event) {
                  _vm.openViewCard = $event
                }
              }
            },
            [
              _c("div", { staticClass: "scrollable" }, [
                _vm.account && _vm.account.cards.length > 0
                  ? _c("div", { staticClass: "card-data-container" }, [
                      _c("div", { staticClass: "card-number" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.account.cards[
                                _vm.account.cards.length - 1
                              ].cardLast4.substr(0, 4)
                            ) +
                            "   " +
                            _vm._s(
                              _vm.account.cards[
                                _vm.account.cards.length - 1
                              ].cardLast4.substr(4, 4)
                            ) +
                            "   " +
                            _vm._s(
                              _vm.account.cards[
                                _vm.account.cards.length - 1
                              ].cardLast4.substr(8, 4)
                            ) +
                            "   " +
                            _vm._s(
                              _vm.account.cards[
                                _vm.account.cards.length - 1
                              ].cardLast4.substr(12, 4)
                            ) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-exp-m" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.account.cards[_vm.account.cards.length - 1]
                                .expiryMonth
                            ) +
                            "/" +
                            _vm._s(
                              _vm.account.cards[
                                _vm.account.cards.length - 1
                              ].expiryYear.substr(-2)
                            ) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-cvv" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.account.cards[_vm.account.cards.length - 1]
                                .cvv
                            ) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "cardholder" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(this.registration.data.firstName) +
                            " " +
                            _vm._s(this.registration.data.lastName) +
                            "\n        "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "buttons text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.openViewCard = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _vm.modalActivationType
            ? _c("accounts-card-activation-modal", {
                attrs: {
                  account: _vm.account,
                  registration: _vm.registration,
                  "modal-type": _vm.modalActivationType
                },
                on: {
                  close: function($event) {
                    _vm.modalActivationType = null
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modalPinData && _vm.modalPinData.provider == "Solid"
            ? _c("accounts-card-pin-modal", {
                attrs: {
                  account: _vm.account,
                  registration: _vm.registration,
                  pinData: _vm.modalPinData
                },
                on: {
                  close: function($event) {
                    _vm.modalPinData = null
                  },
                  refresh: _vm.loadPinModal
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modalCardData && _vm.modalCardData.provider == "Solid"
            ? _c("accounts-card-data-modal", {
                attrs: {
                  account: _vm.account,
                  registration: _vm.registration,
                  pinData: _vm.modalCardData
                },
                on: {
                  close: function($event) {
                    _vm.modalCardData = null
                  },
                  refresh: _vm.loadCardModal
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modalFeeScheduleType
            ? _c("accounts-fee-schedule-modal", {
                attrs: {
                  account: _vm.account,
                  registration: _vm.registration,
                  "modal-type": _vm.modalFeeScheduleType,
                  provider: _vm.provider
                },
                on: {
                  close: function($event) {
                    _vm.modalFeeScheduleType = null
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [
        _c("h2", { staticClass: "inner-headers" }, [_vm._v("My Profile")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "2" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "content-filters" }, [
      _c(
        "td",
        {
          staticClass: "title",
          staticStyle: { color: "black" },
          attrs: { colspan: "2" }
        },
        [_vm._v("Options")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "content-filters" }, [
      _c("td", { attrs: { colspan: "2" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "content-filters" }, [
      _c("td", { staticClass: "title" }, [_vm._v("Currency")]),
      _vm._v(" "),
      _c("td", [_vm._v("USD")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "2" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "2" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "2" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "content-filters" }, [
      _c("td", { attrs: { colspan: "2" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "content-filters" }, [
      _c(
        "td",
        {
          staticClass: "title",
          staticStyle: { color: "black" },
          attrs: { colspan: "2" }
        },
        [_vm._v("Options")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "content-filters" }, [
      _c("td", { attrs: { colspan: "2" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "content-filters" }, [
      _c("td", { attrs: { colspan: "2" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "content-filters" }, [
      _c(
        "td",
        {
          staticClass: "title",
          staticStyle: { color: "black" },
          attrs: { colspan: "2" }
        },
        [_vm._v("Options")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th"),
      _vm._v(" "),
      _c("th", [_vm._v("Username")]),
      _vm._v(" "),
      _c("th", [_vm._v("First Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Last Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Activation Date")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
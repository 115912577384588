var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("h2", [_vm._v("Boarding Compliance")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "status" },
          [
            _vm._v("\n      Status\n      "),
            _c("status-icon", {
              attrs: {
                color: _vm.registration.boardingApproval ? "green" : null
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.registration.type == "Personal"
        ? _c(
            "table",
            {
              staticClass: "content-table",
              staticStyle: { "min-width": "unset" }
            },
            [
              _c("tr", [
                _c("td", { attrs: { colspan: "3" } }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("label", [_vm._v("Scan Type:")]),
                      _vm._v("  \n          "),
                      _c("mnb-select", {
                        attrs: { options: _vm.providerDisabledOptions },
                        model: {
                          value: _vm.form.scanType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "scanType", $$v)
                          },
                          expression: "form.scanType"
                        }
                      }),
                      _vm._v("  \n          "),
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          attrs: { disabled: !_vm.form.scanType },
                          on: {
                            click: function($event) {
                              return _vm.scan()
                            }
                          }
                        },
                        [_vm._v("Scan")]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.scans, function(scan) {
                return _c("tr", { key: scan.id }, [
                  _c("td", [
                    _c("span", [_vm._v("Scan")]),
                    _vm._v(_vm._s(scan.scanId))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Date/Time")]),
                    _vm._v(_vm._s(_vm._f("date")(scan.createdAt, "MM/D/YYYY")))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Scan Type")]),
                    _vm._v("\n        " + _vm._s(scan.scanType) + "\n      ")
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Scan Result")]),
                    _vm._v(" "),
                    _c(
                      "strong",
                      { class: _vm.statusColorClasses[scan.scanResult] },
                      [_vm._v(_vm._s(scan.scanResult))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("File")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: {
                          target: "_blank",
                          href: "files/scanReports/" + scan.id + "/" + scan.file
                        }
                      },
                      [_vm._v(_vm._s(scan.file))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Options")]),
                    _vm._v(" "),
                    scan.scanResult === "Pending"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: {
                              click: function($event) {
                                return _vm.update(scan.id)
                              }
                            }
                          },
                          [_vm._v("Update")]
                        )
                      : _vm._e()
                  ])
                ])
              }),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "date-time", attrs: { colspan: "3" } },
                  [
                    _c("p", { staticClass: "date" }, [_vm._v("Date / Time:")]),
                    _vm._v(" "),
                    !_vm.registration.boardingApproval
                      ? [_vm._v("N/A")]
                      : [
                          _vm._v(
                            _vm._s(
                              _vm._f("date")(_vm.registration.createdAt, "LL")
                            )
                          ),
                          _c("br"),
                          _vm._v(
                            _vm._s(
                              _vm._f("date")(_vm.registration.createdAt, "LT")
                            )
                          )
                        ]
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.registration.status !== "Complete"
                  ? _c(
                      "td",
                      {
                        staticStyle: { "text-align": "right" },
                        attrs: { colspan: "3" }
                      },
                      [
                        _c("span", [_vm._v("Options")]),
                        _vm._v(" "),
                        _vm.$hasPerm("boarding-approval")
                          ? [
                              !_vm.registration.boardingApproval
                                ? [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.approve()
                                          }
                                        }
                                      },
                                      [_vm._v("Skip")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        class: { main: true },
                                        attrs: { disabled: "" }
                                      },
                                      [_vm._v("Approve")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        class: { second: true },
                                        attrs: { disabled: "" }
                                      },
                                      [_vm._v("Denied")]
                                    )
                                  ]
                                : [
                                    _c(
                                      "button",
                                      {
                                        class: { second: true },
                                        on: {
                                          click: function($event) {
                                            return _vm.reset()
                                          }
                                        }
                                      },
                                      [_vm._v("Reset")]
                                    )
                                  ]
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ],
            2
          )
        : _c(
            "table",
            {
              staticClass: "content-table",
              staticStyle: { "min-width": "unset" }
            },
            [
              _c("tr", [
                _c("td", { attrs: { colspan: "3" } }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("label", [_vm._v("Scan Type:")]),
                      _vm._v("  \n          "),
                      _c("mnb-select", {
                        attrs: { options: _vm.providerOptions },
                        model: {
                          value: _vm.form.scanType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "scanType", $$v)
                          },
                          expression: "form.scanType"
                        }
                      }),
                      _vm._v("  \n          "),
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          attrs: { disabled: !_vm.form.scanType },
                          on: {
                            click: function($event) {
                              return _vm.scan()
                            }
                          }
                        },
                        [_vm._v("Scan")]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm._l(_vm.scans, function(scan) {
                return _c("tr", { key: scan.id }, [
                  _c("td", [
                    _c("span", [_vm._v("Scan")]),
                    _vm._v(_vm._s(scan.scanId))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Date/Time")]),
                    _vm._v(_vm._s(_vm._f("date")(scan.createdAt, "MM/D/YYYY")))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Scan Type")]),
                    _vm._v("\n        " + _vm._s(scan.scanType) + "\n      ")
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Scan Result")]),
                    _vm._v(" "),
                    _c(
                      "strong",
                      { class: _vm.statusColorClasses[scan.scanResult] },
                      [_vm._v(_vm._s(scan.scanResult))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("File")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: {
                          target: "_blank",
                          href: "files/scanReports/" + scan.id + "/" + scan.file
                        }
                      },
                      [_vm._v(_vm._s(scan.file))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Options")]),
                    _vm._v(" "),
                    scan.scanResult === "Pending"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: {
                              click: function($event) {
                                return _vm.update(scan.id)
                              }
                            }
                          },
                          [_vm._v("Update")]
                        )
                      : _vm._e()
                  ])
                ])
              }),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "date-time", attrs: { colspan: "3" } },
                  [
                    _c("p", { staticClass: "date" }, [_vm._v("Date / Time:")]),
                    _vm._v(" "),
                    !_vm.registration.boardingApproval
                      ? [_vm._v("N/A")]
                      : [
                          _vm._v(
                            _vm._s(
                              _vm._f("date")(_vm.registration.createdAt, "LL")
                            )
                          ),
                          _c("br"),
                          _vm._v(
                            _vm._s(
                              _vm._f("date")(_vm.registration.createdAt, "LT")
                            )
                          )
                        ]
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.registration.status !== "Complete"
                  ? _c(
                      "td",
                      {
                        staticStyle: { "text-align": "right" },
                        attrs: { colspan: "3" }
                      },
                      [
                        _c("span", [_vm._v("Options")]),
                        _vm._v(" "),
                        _vm.$hasPerm("boarding-approval")
                          ? [
                              _vm.scans[0]
                                ? [
                                    _vm.registration.status == "In Process" &&
                                    _vm.registration.boardingApproval
                                      ? [
                                          _c(
                                            "button",
                                            {
                                              class: { second: true },
                                              attrs: {
                                                disabled:
                                                  _vm.scans[0].scanResult !=
                                                  "Complete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.reset()
                                                }
                                              }
                                            },
                                            [_vm._v("Reset")]
                                          )
                                        ]
                                      : _vm.registration.status == "Denied"
                                      ? [
                                          _c(
                                            "button",
                                            {
                                              class: { second: true },
                                              on: {
                                                click: function($event) {
                                                  return _vm.reset()
                                                }
                                              }
                                            },
                                            [_vm._v("Reset")]
                                          )
                                        ]
                                      : [
                                          _c(
                                            "button",
                                            {
                                              class: { main: true },
                                              attrs: {
                                                disabled:
                                                  _vm.scans[0].scanResult !=
                                                  "Complete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.approve()
                                                }
                                              }
                                            },
                                            [_vm._v("Approve")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              class: { second: true },
                                              attrs: {
                                                disabled:
                                                  _vm.scans[0].scanResult !=
                                                  "Complete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.denied()
                                                }
                                              }
                                            },
                                            [_vm._v("Denied")]
                                          )
                                        ]
                                  ]
                                : [
                                    _c(
                                      "button",
                                      {
                                        class: { main: true },
                                        attrs: { disabled: "" }
                                      },
                                      [_vm._v("Approve")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        class: { second: true },
                                        attrs: { disabled: "" }
                                      },
                                      [_vm._v("Denied")]
                                    )
                                  ]
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ],
            2
          )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Scan")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("th", [_vm._v("Scan Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Scan Result")]),
      _vm._v(" "),
      _c("th", [_vm._v("File")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Scan")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("th", [_vm._v("Scan Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Scan Result")]),
      _vm._v(" "),
      _c("th", [_vm._v("File")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
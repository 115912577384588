var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c("div", { staticClass: "users-profile" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("section", { staticClass: "client-properties active" }, [
          _c(
            "div",
            { staticClass: "cp-box" },
            [
              _c(
                "ul",
                {
                  staticClass: "ctabs",
                  staticStyle: { "margin-bottom": "50px" }
                },
                [
                  _c(
                    "li",
                    {
                      class: { active: _vm.active === 1 },
                      on: {
                        click: function($event) {
                          _vm.active = 1
                        }
                      }
                    },
                    [_c("a", [_vm._v("My Info")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: { active: _vm.active === 3 },
                      on: {
                        click: function($event) {
                          _vm.active = 3
                        }
                      }
                    },
                    [_c("a", [_vm._v("My Settings")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: { active: _vm.active === 4 },
                      on: {
                        click: function($event) {
                          _vm.active = 4
                        }
                      }
                    },
                    [_c("a", [_vm._v("My Access")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: { active: _vm.active === 5 },
                      on: {
                        click: function($event) {
                          _vm.active = 5
                        }
                      }
                    },
                    [_c("a", [_vm._v("Change Password")])]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.active === 1
                ? _c("div", [
                    _c("ul", { staticClass: "sub-tabs" }, [
                      _c(
                        "li",
                        {
                          class: { active: _vm.subActive === "profile" },
                          on: {
                            click: function($event) {
                              _vm.subActive = "profile"
                            }
                          }
                        },
                        [_c("a", [_vm._v("Profile")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          class: { active: _vm.subActive === "Contacts" },
                          on: {
                            click: function($event) {
                              _vm.subActive = "Contacts"
                            }
                          }
                        },
                        [_c("a", [_vm._v("Contacts")])]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.subActive === "profile"
                      ? _c(
                          "div",
                          [
                            _c("registration-data-info", {
                              ref: "contacts",
                              attrs: {
                                registration: _vm.registration,
                                hideBasics: "",
                                hideContacts: "",
                                showEdit: false
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.subActive === "Contacts"
                      ? _c(
                          "div",
                          [
                            _c("contacts", {
                              attrs: {
                                registration: _vm.registration,
                                showEditAdd: false
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.active === 3
                ? _c("div", [
                    _c("ul", { staticClass: "sub-tabs" }, [
                      _c(
                        "li",
                        {
                          class: { active: _vm.settingsActive === "settings" },
                          on: {
                            click: function($event) {
                              _vm.settingsActive = "settings"
                            }
                          }
                        },
                        [_c("a", [_vm._v("Settings")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          class: { active: _vm.settingsActive === "fee" },
                          on: {
                            click: function($event) {
                              _vm.settingsActive = "fee"
                            }
                          }
                        },
                        [_c("a", [_vm._v("Schedule")])]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.settingsActive === "settings"
                      ? _c(
                          "div",
                          [
                            _c("settings-setup", {
                              attrs: {
                                registration: _vm.registration,
                                hideVerificationPermissionsReview: "",
                                noDateUpdate: false
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settingsActive === "fee"
                      ? _c(
                          "div",
                          [
                            _c("fees-schedule", {
                              attrs: {
                                registration: _vm.registration,
                                noUpdate: false
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.active === 4
                ? _c("div", [
                    _c("ul", { staticClass: "sub-tabs" }, [
                      _c(
                        "li",
                        {
                          class: { active: _vm.accessActive === "users" },
                          on: {
                            click: function($event) {
                              _vm.accessActive = "users"
                            }
                          }
                        },
                        [_c("a", [_vm._v("Users")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          class: { active: _vm.accessActive === "userLog" },
                          on: {
                            click: function($event) {
                              _vm.accessActive = "userLog"
                            }
                          }
                        },
                        [_c("a", [_vm._v("User Log")])]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.accessActive === "users"
                      ? _c("div", [
                          _c(
                            "div",
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-block",
                                  attrs: { disabled: !_vm.selected },
                                  on: {
                                    click: function($event) {
                                      return _vm.block()
                                    }
                                  }
                                },
                                [_vm._v("Block")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function($event) {
                                      return _vm.openAddUser()
                                    }
                                  }
                                },
                                [_vm._v("Add Sub-User")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    disabled:
                                      !_vm.selected ||
                                      _vm.selected.id ===
                                        _vm.registration.user.id
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.remove()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Remove Sub-User\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "section",
                                {
                                  staticClass: "content-tlist",
                                  staticStyle: { margin: "30px 0" }
                                },
                                [
                                  _c(
                                    "table",
                                    { staticClass: "content-table" },
                                    [
                                      _vm._m(1),
                                      _vm._v(" "),
                                      _vm._l(_vm.users, function(user, i) {
                                        return _c("tr", { key: i }, [
                                          _c("td", [
                                            _c("span"),
                                            _vm._v(" "),
                                            _c("input", {
                                              attrs: {
                                                type: "radio",
                                                name: "access-user"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.onCbxClicked(user)
                                                }
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("span", [_vm._v("Username")]),
                                            _vm._v(_vm._s(user.username))
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("span", [_vm._v("First Name")]),
                                            _vm._v(_vm._s(user.firstName))
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("span", [_vm._v("Last Name")]),
                                            _vm._v(_vm._s(user.lastName))
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v("Activation Date")
                                            ]),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("date")(
                                                  user.activationDate,
                                                  "MM/D/YYYY hh:mm A"
                                                )
                                              )
                                            )
                                          ])
                                        ])
                                      })
                                    ],
                                    2
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "popup",
                                {
                                  attrs: { open: _vm.addModal },
                                  on: {
                                    "update:open": function($event) {
                                      _vm.addModal = $event
                                    }
                                  }
                                },
                                [
                                  _vm.addModal
                                    ? [
                                        _c(
                                          "h4",
                                          { staticClass: "popup-title" },
                                          [_vm._v("Add Sub-User")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "scrollable" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "content-form" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _c("form-field-text", {
                                                      attrs: {
                                                        maxlength: 25,
                                                        validator:
                                                          _vm.$v.form.firstName,
                                                        label: "First Name",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.firstName,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "firstName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.firstName"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("form-field-text", {
                                                      attrs: {
                                                        maxlength: 25,
                                                        validator:
                                                          _vm.$v.form.lastName,
                                                        label: "Last Name",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.lastName,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "lastName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.lastName"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row form-split"
                                                  },
                                                  [
                                                    _c("form-field-text", {
                                                      attrs: {
                                                        validator:
                                                          _vm.$v.form.email,
                                                        label: "Email Address",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value: _vm.form.email,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "email",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "form.email"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("form-field-text", {
                                                      attrs: {
                                                        maxlength: 15,
                                                        validator:
                                                          _vm.$v.form.phone,
                                                        label: "Phone Number",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value: _vm.form.phone,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "phone",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "form.phone"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "buttons" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "main",
                                              on: {
                                                click: function($event) {
                                                  return _vm.add()
                                                }
                                              }
                                            },
                                            [_vm._v("Add")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "second",
                                              on: {
                                                click: function($event) {
                                                  _vm.addModal = false
                                                }
                                              }
                                            },
                                            [_vm._v("Cancel")]
                                          )
                                        ])
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.accessActive === "userLog"
                      ? _c("div", [
                          _vm.lastLogin
                            ? _c(
                                "ul",
                                {
                                  staticClass: "cp-fields justify-content-none"
                                },
                                [
                                  _c("li", [
                                    _c("span", [_vm._v("Last Login")]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "val" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._f("date")(
                                              _vm.lastLogin.recorded
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c("span", [_vm._v("Data Transfer")]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "val" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._f("fileSize")(
                                              _vm.lastLogin.transferred
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c("span", [_vm._v("IP Address")]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "val" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.lastLogin.ipAddress) +
                                          "\n              "
                                      )
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.active === 5 ? _c("change-password") : _vm._e()
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [
        _c("h2", { staticClass: "inner-headers" }, [_vm._v("My Profile")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th"),
      _vm._v(" "),
      _c("th", [_vm._v("Username")]),
      _vm._v(" "),
      _c("th", [_vm._v("First Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Last Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Activation Date")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
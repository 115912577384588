var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("h2", [_vm._v("Account Plan")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "status" },
          [
            _vm._v("\n      Status\n      "),
            _c("status-icon", {
              attrs: { color: _vm.accountPlan ? "green" : null }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "content-table", staticStyle: { "min-width": "unset" } },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("tr", { staticClass: "content-filters" }, [
            _c(
              "td",
              { attrs: { colspan: "2" } },
              [
                _c("span", [_vm._v("Plan")]),
                _vm._v(" "),
                !_vm.accountPlan
                  ? _c("mnb-select", {
                      staticClass: "select",
                      attrs: {
                        options: _vm.plans,
                        validator: _vm.$v.form.accountPlan
                      },
                      model: {
                        value: _vm.form.accountPlan,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "accountPlan", $$v)
                        },
                        expression: "form.accountPlan"
                      }
                    })
                  : _c("mnb-select", {
                      staticClass: "select",
                      attrs: { options: _vm.plans, disabled: true },
                      model: {
                        value: _vm.accountPlan,
                        callback: function($$v) {
                          _vm.accountPlan = $$v
                        },
                        expression: "accountPlan"
                      }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _c("td", { attrs: { colspan: "5" } })
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm.feeSchedule
            ? [
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Fee")]),
                      _vm._v(" "),
                      _vm.accountPlan
                        ? [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("formattedTwoDecimal")(
                                    _vm.feeSchedule.volumeFee.fee
                                  )
                                ) +
                                " %\n          "
                            )
                          ]
                        : [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.feeSchedule.volumeFee.fee,
                                  expression: "feeSchedule.volumeFee.fee"
                                }
                              ],
                              staticClass: "fee-input",
                              attrs: {
                                type: "number",
                                validator: _vm.$v.feeSchedule.volumeFee.fee
                              },
                              domProps: {
                                value: _vm.feeSchedule.volumeFee.fee
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.feeSchedule.volumeFee,
                                    "fee",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" %\n          ")
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Fee")]),
                      _vm._v(" "),
                      _vm.accountPlan
                        ? [
                            _vm._v(
                              "\n            $ " +
                                _vm._s(
                                  _vm._f("formattedTwoDecimal")(
                                    _vm.feeSchedule.monthlyAccessFee.fee
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        : [
                            _vm._v("\n            $ "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.feeSchedule.monthlyAccessFee.fee,
                                  expression: "feeSchedule.monthlyAccessFee.fee"
                                }
                              ],
                              staticClass: "fee-input",
                              attrs: {
                                type: "number",
                                validator:
                                  _vm.$v.feeSchedule.monthlyAccessFee.fee
                              },
                              domProps: {
                                value: _vm.feeSchedule.monthlyAccessFee.fee
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.feeSchedule.monthlyAccessFee,
                                    "fee",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Fee")]),
                      _vm._v(" "),
                      _vm.accountPlan
                        ? [
                            _vm._v(
                              "\n            $ " +
                                _vm._s(
                                  _vm._f("formattedTwoDecimal")(
                                    _vm.feeSchedule.dormantAccounts.fee
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        : [
                            _vm._v("\n            $ "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.feeSchedule.dormantAccounts.fee,
                                  expression: "feeSchedule.dormantAccounts.fee"
                                }
                              ],
                              staticClass: "fee-input",
                              attrs: {
                                type: "number",
                                validator:
                                  _vm.$v.feeSchedule.dormantAccounts.fee
                              },
                              domProps: {
                                value: _vm.feeSchedule.dormantAccounts.fee
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.feeSchedule.dormantAccounts,
                                    "fee",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _vm.provider.ach == 1
                  ? _c("tr", [
                      _c(
                        "td",
                        [
                          !_vm.accountPlan
                            ? [
                                _vm.provider.ach == 1
                                  ? _c("mnb-switch", {
                                      attrs: {
                                        validator:
                                          _vm.$v.profileSettingsform.ach,
                                        disabled:
                                          _vm.customEnv.noach ||
                                          !_vm.profileSettingsform.account
                                      },
                                      model: {
                                        value: _vm.profileSettingsform.ach,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.profileSettingsform,
                                            "ach",
                                            $$v
                                          )
                                        },
                                        expression: "profileSettingsform.ach"
                                      }
                                    })
                                  : _c("mnb-switch", {
                                      attrs: { value: false, disabled: "" }
                                    })
                              ]
                            : _c("mnb-switch", {
                                attrs: {
                                  value: _vm.profileSettingsform.ach == 1,
                                  disabled: ""
                                }
                              })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._m(5),
                      _vm._v(" "),
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.ach.per_deposit
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.feeSchedule.ach.per_deposit,
                                      expression: "feeSchedule.ach.per_deposit"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator:
                                      _vm.$v.feeSchedule.ach.per_deposit
                                  },
                                  domProps: {
                                    value: _vm.feeSchedule.ach.per_deposit
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.ach,
                                        "per_deposit",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.ach.per_withdrawal
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.feeSchedule.ach.per_withdrawal,
                                      expression:
                                        "feeSchedule.ach.per_withdrawal"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator:
                                      _vm.$v.feeSchedule.ach.per_withdrawal
                                  },
                                  domProps: {
                                    value: _vm.feeSchedule.ach.per_withdrawal
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.ach,
                                        "per_withdrawal",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("ACH")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? _c(
                                "div",
                                { staticClass: "money-input" },
                                [
                                  _c("span", [_vm._v("$")]),
                                  _vm._v(" "),
                                  !_vm.accountPlan
                                    ? _c("mnb-input", {
                                        staticClass: "input",
                                        attrs: {
                                          label: "Limit",
                                          "no-style": "",
                                          disabled:
                                            _vm.customEnv.noach ||
                                            _vm.provider.ach != 1 ||
                                            !_vm.profileSettingsform.ach
                                        },
                                        model: {
                                          value:
                                            _vm.profileSettingsform.ach_limit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.profileSettingsform,
                                              "ach_limit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profileSettingsform.ach_limit"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : [
                                _vm._v(
                                  "$ " +
                                    _vm._s(_vm.profileSettingsform.ach_limit)
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.provider.acct == 1
                  ? _c("tr", [
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Account to Account")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? [
                                _vm.provider.acct == 1
                                  ? _c("mnb-switch", {
                                      attrs: {
                                        validator:
                                          _vm.$v.profileSettingsform.acc_to_acc
                                      },
                                      model: {
                                        value:
                                          _vm.profileSettingsform.acc_to_acc,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.profileSettingsform,
                                            "acc_to_acc",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "profileSettingsform.acc_to_acc"
                                      }
                                    })
                                  : _c("mnb-switch", {
                                      attrs: { value: false, disabled: "" }
                                    })
                              ]
                            : _c("mnb-switch", {
                                attrs: {
                                  value:
                                    _vm.profileSettingsform.acc_to_acc == 1,
                                  disabled: ""
                                }
                              })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._m(7),
                      _vm._v(" "),
                      _vm._m(8),
                      _vm._v(" "),
                      _c("td"),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.accountTransfer
                                          .per_withdrawal
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.feeSchedule.accountTransfer
                                          .per_withdrawal,
                                      expression:
                                        "feeSchedule.accountTransfer.per_withdrawal"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator:
                                      _vm.$v.feeSchedule.accountTransfer
                                        .per_withdrawal
                                  },
                                  domProps: {
                                    value:
                                      _vm.feeSchedule.accountTransfer
                                        .per_withdrawal
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.accountTransfer,
                                        "per_withdrawal",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Account to Account")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? _c(
                                "div",
                                { staticClass: "money-input" },
                                [
                                  _c("span", [_vm._v("$")]),
                                  _vm._v(" "),
                                  !_vm.accountPlan
                                    ? _c("mnb-input", {
                                        staticClass: "input",
                                        attrs: {
                                          label: "Limit",
                                          "no-style": "",
                                          disabled:
                                            _vm.provider.acct != 1 ||
                                            !_vm.profileSettingsform.acc_to_acc
                                        },
                                        model: {
                                          value:
                                            _vm.profileSettingsform
                                              .acc_to_acc_limit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.profileSettingsform,
                                              "acc_to_acc_limit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profileSettingsform.acc_to_acc_limit"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : [
                                _vm._v(
                                  "$ " +
                                    _vm._s(
                                      _vm.profileSettingsform.acc_to_acc_limit
                                    )
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.provider.checks == 1
                  ? _c("tr", [
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Checks")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? [
                                _vm.provider.checks == 1
                                  ? _c("mnb-switch", {
                                      attrs: {
                                        validator:
                                          _vm.$v.profileSettingsform.checks
                                      },
                                      model: {
                                        value: _vm.profileSettingsform.checks,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.profileSettingsform,
                                            "checks",
                                            $$v
                                          )
                                        },
                                        expression: "profileSettingsform.checks"
                                      }
                                    })
                                  : _c("mnb-switch", {
                                      attrs: { value: false, disabled: "" }
                                    })
                              ]
                            : _c("mnb-switch", {
                                attrs: {
                                  value: _vm.profileSettingsform.checks == 1,
                                  disabled: ""
                                }
                              })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._m(9),
                      _vm._v(" "),
                      _vm._m(10),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.checks.per_deposit
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.feeSchedule.checks.per_deposit,
                                      expression:
                                        "feeSchedule.checks.per_deposit"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator:
                                      _vm.$v.feeSchedule.checks.per_deposit
                                  },
                                  domProps: {
                                    value: _vm.feeSchedule.checks.per_deposit
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.checks,
                                        "per_deposit",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.checks.per_withdrawal
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.feeSchedule.checks.per_withdrawal,
                                      expression:
                                        "feeSchedule.checks.per_withdrawal"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator:
                                      _vm.$v.feeSchedule.checks.per_withdrawal
                                  },
                                  domProps: {
                                    value: _vm.feeSchedule.checks.per_withdrawal
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.checks,
                                        "per_withdrawal",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Checks")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? _c(
                                "div",
                                { staticClass: "money-input" },
                                [
                                  _c("span", [_vm._v("$")]),
                                  _vm._v(" "),
                                  !_vm.accountPlan
                                    ? _c("mnb-input", {
                                        staticClass: "input",
                                        attrs: {
                                          label: "Limit",
                                          "no-style": "",
                                          disabled:
                                            _vm.provider.checks != 1 ||
                                            !_vm.profileSettingsform.checks
                                        },
                                        model: {
                                          value:
                                            _vm.profileSettingsform
                                              .checks_limit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.profileSettingsform,
                                              "checks_limit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profileSettingsform.checks_limit"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : [
                                _vm._v(
                                  "$ " +
                                    _vm._s(_vm.profileSettingsform.checks_limit)
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.provider.domWires == 1
                  ? _c("tr", [
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Wire Transfer")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? [
                                _vm.provider.domWires == 1
                                  ? _c("mnb-switch", {
                                      attrs: {
                                        validator:
                                          _vm.$v.profileSettingsform.domWires
                                      },
                                      model: {
                                        value: _vm.profileSettingsform.domWires,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.profileSettingsform,
                                            "domWires",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "profileSettingsform.domWires"
                                      }
                                    })
                                  : _c("mnb-switch", {
                                      attrs: { value: false, disabled: "" }
                                    })
                              ]
                            : _c("mnb-switch", {
                                attrs: {
                                  value: _vm.profileSettingsform.domWires == 1,
                                  disabled: ""
                                }
                              })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._m(11),
                      _vm._v(" "),
                      _vm._m(12),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.domesticWire.per_deposit
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.feeSchedule.domesticWire
                                          .per_deposit,
                                      expression:
                                        "feeSchedule.domesticWire.per_deposit"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator:
                                      _vm.$v.feeSchedule.domesticWire
                                        .per_deposit
                                  },
                                  domProps: {
                                    value:
                                      _vm.feeSchedule.domesticWire.per_deposit
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.domesticWire,
                                        "per_deposit",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.domesticWire
                                          .per_withdrawal
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.feeSchedule.domesticWire
                                          .per_withdrawal,
                                      expression:
                                        "feeSchedule.domesticWire.per_withdrawal"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator:
                                      _vm.$v.feeSchedule.domesticWire
                                        .per_withdrawal
                                  },
                                  domProps: {
                                    value:
                                      _vm.feeSchedule.domesticWire
                                        .per_withdrawal
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.domesticWire,
                                        "per_withdrawal",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Wire Transfer")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? _c(
                                "div",
                                { staticClass: "money-input" },
                                [
                                  _c("span", [_vm._v("$")]),
                                  _vm._v(" "),
                                  !_vm.accountPlan
                                    ? _c("mnb-input", {
                                        staticClass: "input",
                                        attrs: {
                                          label: "Limit",
                                          "no-style": "",
                                          disabled:
                                            _vm.provider.domWires != 1 ||
                                            !_vm.profileSettingsform.domWires
                                        },
                                        model: {
                                          value:
                                            _vm.profileSettingsform
                                              .domWires_limit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.profileSettingsform,
                                              "domWires_limit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profileSettingsform.domWires_limit"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : [
                                _vm._v(
                                  "$ " +
                                    _vm._s(
                                      _vm.profileSettingsform.domWires_limit
                                    )
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.provider.intlWires == 1
                  ? _c("tr", [
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Wire Transfer")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? [
                                _vm.provider.intlWires == 1
                                  ? _c("mnb-switch", {
                                      attrs: {
                                        validator:
                                          _vm.$v.profileSettingsform.intlWires
                                      },
                                      model: {
                                        value:
                                          _vm.profileSettingsform.intlWires,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.profileSettingsform,
                                            "intlWires",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "profileSettingsform.intlWires"
                                      }
                                    })
                                  : _c("mnb-switch", {
                                      attrs: { value: false, disabled: "" }
                                    })
                              ]
                            : _c("mnb-switch", {
                                attrs: {
                                  value: _vm.profileSettingsform.intlWires == 1,
                                  disabled: ""
                                }
                              })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._m(13),
                      _vm._v(" "),
                      _vm._m(14),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.internationalWire
                                          .per_deposit
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.feeSchedule.internationalWire
                                          .per_deposit,
                                      expression:
                                        "feeSchedule.internationalWire.per_deposit"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator:
                                      _vm.$v.feeSchedule.internationalWire
                                        .per_deposit
                                  },
                                  domProps: {
                                    value:
                                      _vm.feeSchedule.internationalWire
                                        .per_deposit
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.internationalWire,
                                        "per_deposit",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.internationalWire
                                          .per_withdrawal
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.feeSchedule.internationalWire
                                          .per_withdrawal,
                                      expression:
                                        "feeSchedule.internationalWire.per_withdrawal"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator:
                                      _vm.$v.feeSchedule.internationalWire
                                        .per_withdrawal
                                  },
                                  domProps: {
                                    value:
                                      _vm.feeSchedule.internationalWire
                                        .per_withdrawal
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.internationalWire,
                                        "per_withdrawal",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Wire Transfer")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? _c(
                                "div",
                                { staticClass: "money-input" },
                                [
                                  _c("span", [_vm._v("$")]),
                                  _vm._v(" "),
                                  !_vm.accountPlan
                                    ? _c("mnb-input", {
                                        staticClass: "input",
                                        attrs: {
                                          label: "Limit",
                                          "no-style": "",
                                          disabled:
                                            _vm.provider.intlWires != 1 ||
                                            !_vm.profileSettingsform.intlWires
                                        },
                                        model: {
                                          value:
                                            _vm.profileSettingsform
                                              .intlWires_limit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.profileSettingsform,
                                              "intlWires_limit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profileSettingsform.intlWires_limit"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : [
                                _vm._v(
                                  "$ " +
                                    _vm._s(
                                      _vm.profileSettingsform.intlWires_limit
                                    )
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.provider.debitCards == 1
                  ? _c("tr", [
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Debit Cards")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? [
                                _vm.provider.debitCards == 1
                                  ? _c("mnb-switch", {
                                      attrs: {
                                        validator:
                                          _vm.$v.profileSettingsform.debitCards
                                      },
                                      model: {
                                        value:
                                          _vm.profileSettingsform.debitCards,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.profileSettingsform,
                                            "debitCards",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "profileSettingsform.debitCards"
                                      }
                                    })
                                  : _c("mnb-switch", {
                                      attrs: { value: false, disabled: "" }
                                    })
                              ]
                            : _c("mnb-switch", {
                                attrs: {
                                  value:
                                    _vm.profileSettingsform.debitCards == 1,
                                  disabled: ""
                                }
                              })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._m(15),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Fee")]),
                          _vm._v(" "),
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.debitCard.fee
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.feeSchedule.debitCard.fee,
                                      expression: "feeSchedule.debitCard.fee"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator: _vm.$v.feeSchedule.debitCard.fee
                                  },
                                  domProps: {
                                    value: _vm.feeSchedule.debitCard.fee
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.debitCard,
                                        "fee",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("td"),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.accountPlan
                            ? [
                                _vm._v(
                                  "\n            $ " +
                                    _vm._s(
                                      _vm._f("formattedTwoDecimal")(
                                        _vm.feeSchedule.debitCard.per_withdrawal
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : [
                                _vm._v("\n            $ "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.feeSchedule.debitCard
                                          .per_withdrawal,
                                      expression:
                                        "feeSchedule.debitCard.per_withdrawal"
                                    }
                                  ],
                                  staticClass: "fee-input",
                                  attrs: {
                                    type: "number",
                                    validator:
                                      _vm.$v.feeSchedule.debitCard
                                        .per_withdrawal
                                  },
                                  domProps: {
                                    value:
                                      _vm.feeSchedule.debitCard.per_withdrawal
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.feeSchedule.debitCard,
                                        "per_withdrawal",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Debit Cards")]),
                          _vm._v(" "),
                          !_vm.accountPlan
                            ? _c(
                                "div",
                                { staticClass: "money-input" },
                                [
                                  _c("span", [_vm._v("$")]),
                                  _vm._v(" "),
                                  !_vm.accountPlan
                                    ? _c("mnb-input", {
                                        staticClass: "input",
                                        attrs: {
                                          label: "Limit",
                                          "no-style": "",
                                          disabled:
                                            _vm.provider.debitCards != 1 ||
                                            !_vm.profileSettingsform.debitCards
                                        },
                                        model: {
                                          value:
                                            _vm.profileSettingsform
                                              .debit_cards_limit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.profileSettingsform,
                                              "debit_cards_limit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "profileSettingsform.debit_cards_limit"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : [
                                _vm._v(
                                  "$ " +
                                    _vm._s(
                                      _vm.profileSettingsform.debit_cards_limit
                                    )
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Trade Desk")]),
                      _vm._v(" "),
                      !_vm.accountPlan
                        ? [
                            _c("mnb-switch", {
                              attrs: {
                                validator: _vm.$v.profileSettingsform.trade_desk
                              },
                              model: {
                                value: _vm.profileSettingsform.trade_desk,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.profileSettingsform,
                                    "trade_desk",
                                    $$v
                                  )
                                },
                                expression: "profileSettingsform.trade_desk"
                              }
                            })
                          ]
                        : _c("mnb-switch", {
                            attrs: {
                              value: _vm.profileSettingsform.trade_desk == 1,
                              disabled: ""
                            }
                          })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._m(16),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Fee")]),
                      _vm._v(" "),
                      _vm.accountPlan
                        ? [
                            _vm._v(
                              "\n            $ " +
                                _vm._s(
                                  _vm._f("formattedTwoDecimal")(
                                    _vm.feeSchedule.exchange.fee
                                  )
                                ) +
                                " \n          "
                            )
                          ]
                        : [
                            _vm._v("\n            $ "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.feeSchedule.exchange.fee,
                                  expression: "feeSchedule.exchange.fee"
                                }
                              ],
                              staticClass: "fee-input",
                              attrs: {
                                type: "number",
                                validator: _vm.$v.feeSchedule.exchange.fee
                              },
                              domProps: { value: _vm.feeSchedule.exchange.fee },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.feeSchedule.exchange,
                                    "fee",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Trade Desk")]),
                      _vm._v(" "),
                      !_vm.accountPlan
                        ? _c(
                            "div",
                            { staticClass: "money-input" },
                            [
                              _c("span"),
                              _vm._v(" "),
                              !_vm.accountPlan
                                ? _c("mnb-input", {
                                    staticClass: "input",
                                    attrs: {
                                      label: "Limit",
                                      "no-style": "",
                                      disabled: !_vm.profileSettingsform
                                        .trade_desk
                                    },
                                    model: {
                                      value:
                                        _vm.profileSettingsform
                                          .trade_desk_limit,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.profileSettingsform,
                                          "trade_desk_limit",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "profileSettingsform.trade_desk_limit"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : [
                            _vm._v(
                              _vm._s(_vm.profileSettingsform.trade_desk_limit)
                            )
                          ]
                    ],
                    2
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer space" }, [
        _c(
          "span",
          [
            _c("b", [_vm._v("Date / Time:")]),
            _c("br"),
            _vm._v(" "),
            !_vm.accountPlan
              ? [_vm._v("N/A")]
              : [
                  _vm._v(
                    _vm._s(_vm._f("date")(_vm.registration.updatedAt, "LL"))
                  ),
                  _c("br"),
                  _vm._v(
                    _vm._s(_vm._f("date")(_vm.registration.updatedAt, "LT"))
                  )
                ]
          ],
          2
        ),
        _vm._v(" "),
        _vm.registration.status !== "Complete"
          ? _c("div", [
              !_vm.accountPlan
                ? _c(
                    "button",
                    {
                      staticClass: "main",
                      attrs: {
                        disabled:
                          !_vm.form.accountPlan || _vm.$v.feeSchedule.$invalid
                      },
                      on: {
                        click: function($event) {
                          return _vm.create()
                        }
                      }
                    },
                    [_vm._v("Confirm")]
                  )
                : _vm.registration.status != "Active"
                ? _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          return _vm.remove()
                        }
                      }
                    },
                    [_vm._v("Remove")]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Account Plan")]),
      _vm._v(" "),
      _c("th"),
      _vm._v(" "),
      _c("th"),
      _vm._v(" "),
      _c("th")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th"),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Fee Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Fee")]),
      _vm._v(" "),
      _c("th", [_vm._v("Deposit")]),
      _vm._v(" "),
      _c("th", [_vm._v("Withdrawal")]),
      _vm._v(" "),
      _c("th", [_vm._v("Limits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n          Volume Fee\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n          Monthly Access Fee\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n          Dormant Accounts\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n          ACH\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n          Account Transfers\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n          Checks\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n          Domestic Wire\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n          International Wire\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n          Debit Card\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n          Trade Desk\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "complex-field" }, [
    _c(
      "input",
      _vm._b(
        {
          ref: "inputField",
          class: _vm.inputClass,
          attrs: { type: "text", readonly: "", disabled: _vm.disabled },
          domProps: { value: _vm.value }
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
    _vm._v(" "),
    !_vm.disabled
      ? _c(
          "a",
          {
            staticClass: "copy-icon",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.doCopy($event)
              }
            }
          },
          [
            _c(
              "svg",
              { attrs: { height: "16", viewBox: "0 0 48 48", width: "16" } },
              [
                _c("path", { attrs: { d: "M0 0h48v48h-48z", fill: "none" } }),
                _c("path", {
                  attrs: {
                    d:
                      "M32 2h-24c-2.21 0-4 1.79-4 4v28h4v-28h24v-4zm6 8h-22c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4v-28c0-2.21-1.79-4-4-4zm0 32h-22v-28h22v28z"
                  }
                })
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
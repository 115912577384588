import { render, staticRenderFns } from "./CloseAllTemplates.vue?vue&type=template&id=6918b41c&"
import script from "./CloseAllTemplates.vue?vue&type=script&lang=js&"
export * from "./CloseAllTemplates.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6monbi/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6918b41c')) {
      api.createRecord('6918b41c', component.options)
    } else {
      api.reload('6918b41c', component.options)
    }
    module.hot.accept("./CloseAllTemplates.vue?vue&type=template&id=6918b41c&", function () {
      api.rerender('6918b41c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/monbi/CloseAllTemplates.vue"
export default component.exports
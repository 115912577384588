var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      { staticClass: "role-group-list" },
      _vm._l(_vm.permissions, function(roleGroup, roleGId) {
        return _c("li", [
          _c("h2", { class: _vm.classes }, [_vm._v(_vm._s(roleGroup.title))]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(roleGroup.items, function(roleTitle, roleId) {
              return _c(
                "li",
                { key: "role" + roleTitle + "x" + _vm.inherited },
                [
                  !_vm.isInherited(roleId)
                    ? _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.actualValue,
                              expression: "actualValue"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox", disabled: _vm.disabled },
                          domProps: {
                            value: roleId,
                            checked: Array.isArray(_vm.actualValue)
                              ? _vm._i(_vm.actualValue, roleId) > -1
                              : _vm.actualValue
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.actualValue,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = roleId,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.actualValue = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.actualValue = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.actualValue = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "form-check-label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(roleTitle) +
                              "\n            "
                          )
                        ])
                      ])
                    : _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          staticClass: "form-check-input",
                          attrs: {
                            type: "checkbox",
                            checked: "",
                            disabled: ""
                          },
                          domProps: { value: true }
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "form-check-label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(roleTitle) +
                              "\n            "
                          )
                        ])
                      ])
                ]
              )
            }),
            0
          )
        ])
      }),
      0
    ),
    _vm._v(" "),
    !_vm.noButtons
      ? _c(
          "div",
          { staticClass: "edit-buttons" },
          [
            _vm.disabled
              ? _c(
                  "button",
                  { staticClass: " second", on: { click: _vm.editPerms } },
                  [_vm._v("Update")]
                )
              : [
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      staticStyle: { "margin-right": "10px" },
                      on: {
                        click: function($event) {
                          return _vm.updatePermissions()
                        }
                      }
                    },
                    [_vm._v("Save")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: " second", on: { click: _vm.editPerms } },
                    [_vm._v("Cancel")]
                  )
                ]
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
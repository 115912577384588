var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cp-box" },
    [
      _c(
        "ul",
        { staticClass: "sub-tabs", staticStyle: { "margin-bottom": "12px" } },
        [
          _c(
            "li",
            {
              class: { active: _vm.activeTab === "services" },
              on: {
                click: function($event) {
                  _vm.activeTab = "services"
                }
              }
            },
            [_c("a", [_vm._v("Services")])]
          )
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.activeTab === "services"
        ? _c("services-setup", { attrs: { registration: _vm.registration } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
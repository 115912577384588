var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", [
        _c("div", { staticClass: "flex-between" }, [
          _c("h2", { staticClass: "inner-headers" }, [
            _vm._v("Compliance > Scans")
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
            _c(
              "button",
              {
                staticClass: "second",
                on: {
                  click: function($event) {
                    _vm.openedFilters = true
                  }
                }
              },
              [
                _c("i", { staticClass: "el-icon-s-operation" }),
                _vm._v(" Filters")
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.$hasPerm("new-scan")
        ? _c(
            "button",
            {
              staticClass: "btn btn-success",
              on: {
                click: function($event) {
                  _vm.open = "Open"
                }
              }
            },
            [_vm._v("New Scan")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("applied-filters", {
        attrs: { filters: _vm.appliedFilters },
        on: {
          clear: function($event) {
            return _vm.clearFilters()
          },
          remove: function($event) {
            return _vm.removeFilter($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "accordion" }, [
        _c(
          "div",
          { class: { "cof-box": true, "cof-box-active": _vm.activeInfo } },
          [
            _c(
              "h4",
              {
                staticClass: "accordion-headline",
                on: {
                  click: function($event) {
                    _vm.activeInfo = !_vm.activeInfo
                  }
                }
              },
              [_vm._v("Scans")]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "total-number",
                on: {
                  click: function($event) {
                    _vm.activeInfo = !_vm.activeInfo
                  }
                }
              },
              [_vm._v("(" + _vm._s(_vm.totalNumber) + ") Click to expand")]
            ),
            _vm._v(" "),
            _c("section", { staticClass: "content-tlist" }, [
              _c(
                "table",
                { staticClass: "content-table" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(_vm.scans, function(scan) {
                    return _c("tr", { key: scan.id }, [
                      _c("td", [
                        _c("span", [_vm._v("Scan")]),
                        _vm._v(_vm._s(scan.scanId))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Date/Time")]),
                        _vm._v(
                          _vm._s(_vm._f("date")(scan.createdAt, "MM/D/YYYY"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Profile")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/system-customers/registrations/" + scan.regId
                            }
                          },
                          [_vm._v(_vm._s(scan.profile))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Name")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(scan.name) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Scan Type")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(scan.scanType) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Scan Result")]),
                        _vm._v(" "),
                        _c(
                          "strong",
                          { class: _vm.statusColorClasses[scan.scanResult] },
                          [_vm._v(_vm._s(scan.scanResult))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("File")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            attrs: {
                              target: "_blank",
                              href:
                                "files/scanReports/" + scan.id + "/" + scan.file
                            }
                          },
                          [_vm._v(_vm._s(scan.file))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Options")]),
                        _vm._v(" "),
                        scan.scanResult === "Pending"
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                on: {
                                  click: function($event) {
                                    return _vm.update(scan.id)
                                  }
                                }
                              },
                              [_vm._v("Update")]
                            )
                          : _vm._e()
                      ])
                    ])
                  })
                ],
                2
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.open
        ? _c("scans-modal", {
            attrs: { "modal-type": _vm.open },
            on: {
              close: function($event) {
                _vm.open = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("scan-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Scan")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("th", [_vm._v("Profile")]),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Scan Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Scan Result")]),
      _vm._v(" "),
      _c("th", [_vm._v("File")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
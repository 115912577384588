var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "space-around" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open"
            }
          ],
          staticClass: "content-tlist",
          staticStyle: { width: "70%", border: "none", padding: "30px" }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          !_vm.nextFlag
            ? [
                _c("div", { staticClass: "scrollable" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "content-form content-form-modern text-center"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-row form-split depositType" },
                        [
                          _c("form-field-select", {
                            attrs: {
                              label: "Select Provider",
                              validator: _vm.$v.form.provider,
                              options: _vm.providerOptions,
                              required: ""
                            },
                            model: {
                              value: _vm.form.provider,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "provider", $$v)
                              },
                              expression: "form.provider"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "main",
                        on: {
                          click: function($event) {
                            return _vm.moveNextPopup()
                          }
                        }
                      },
                      [_vm._v("Next")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "second",
                        on: {
                          click: function($event) {
                            _vm.open = false
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  ])
                ])
              ]
            : [
                _c("div", { staticClass: "scrollable" }, [
                  _c(
                    "div",
                    { staticClass: "content-form content-form-modern" },
                    [
                      _c(
                        "div",
                        { staticClass: "form-row form-split" },
                        [
                          _c("form-field-select-search", {
                            attrs: {
                              label: "Account",
                              validator: _vm.$v.form.account,
                              options: _vm.accountOptions,
                              "option-group": !_vm.isCustomer,
                              required: ""
                            },
                            model: {
                              value: _vm.form.account,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "account", $$v)
                              },
                              expression: "form.account"
                            }
                          }),
                          _vm._v(" "),
                          _c("form-field-select", {
                            attrs: {
                              disabled: !_vm.selectedAccount,
                              label: "Deposit Method",
                              validator: _vm.$v.form.depositMethod,
                              options: _vm.depositOptions,
                              required: ""
                            },
                            model: {
                              value: _vm.form.depositMethod,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "depositMethod", $$v)
                              },
                              expression: "form.depositMethod"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-split" },
                        [
                          _c("form-field-select", {
                            attrs: {
                              disabled: "",
                              label: "Currency",
                              validator: _vm.$v.form.currency,
                              options: _vm.currencyOptions,
                              required: ""
                            },
                            model: {
                              value: _vm.form.currency,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "currency", $$v)
                              },
                              expression: "form.currency"
                            }
                          }),
                          _vm._v(" "),
                          _c("form-field-text", {
                            attrs: {
                              label: "Deposit Amount",
                              validator: _vm.$v.form.amount,
                              required: ""
                            },
                            model: {
                              value: _vm.form.amount,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "amount", $$v)
                              },
                              expression: "form.amount"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw" },
                        [
                          _c("form-field-text", {
                            attrs: {
                              label: "Sender Name",
                              validator: _vm.$v.form.senderName,
                              maxlength: 75,
                              required: ""
                            },
                            model: {
                              value: _vm.form.senderName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "senderName", $$v)
                              },
                              expression: "form.senderName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw" },
                        [
                          _c("form-field-textarea", {
                            attrs: {
                              label: "Details",
                              validator: _vm.$v.form.details,
                              rows: 3,
                              maxlength: 1000
                            },
                            model: {
                              value: _vm.form.details,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "details", $$v)
                              },
                              expression: "form.details"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw" },
                        [
                          _c("form-field-text", {
                            attrs: {
                              label: "Reference",
                              validator: _vm.$v.form.reference,
                              maxlength: 75,
                              required: ""
                            },
                            model: {
                              value: _vm.form.reference,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "reference", $$v)
                              },
                              expression: "form.reference"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("h3", { staticClass: "subtitle" }, [
                        _vm._v("EXTRA INFORMATION")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-split" },
                        [
                          _c("form-field-text", {
                            attrs: {
                              label: "Provider Transaction Number",
                              validator: _vm.$v.form.providerTransactionNumber,
                              maxlength: 50,
                              required: ""
                            },
                            model: {
                              value: _vm.form.providerTransactionNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "providerTransactionNumber",
                                  $$v
                                )
                              },
                              expression: "form.providerTransactionNumber"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "cof-field" }, [
                            _vm._v(
                              "\n                Provider Created At\n                "
                            ),
                            _c(
                              "div",
                              { staticClass: "cof-multi flex" },
                              [
                                _c("date-time-picker", {
                                  attrs: {
                                    "value-type": "String",
                                    "value-format": "yyyy-LL-dd",
                                    format: "yyyy-LL-dd",
                                    "time-picker": false,
                                    "auto-close": true,
                                    "max-date": _vm.today
                                  },
                                  model: {
                                    value: _vm.form.providerCreatedAt,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "providerCreatedAt",
                                        $$v
                                      )
                                    },
                                    expression: "form.providerCreatedAt"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "main",
                        on: {
                          click: function($event) {
                            return _vm.add()
                          }
                        }
                      },
                      [_vm._v("Submit")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "second",
                        on: {
                          click: function($event) {
                            _vm.open = false
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _vm.submitError
                      ? _c("label", { staticClass: "error-message" }, [
                          _vm._v("Please check error(s) and try again.")
                        ])
                      : _vm._e()
                  ])
                ])
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popup-header" }, [
      _c("h3", [_vm._v("New Deposit")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-filters" },
      [
        _c("basic-form", [
          _c(
            "div",
            { staticClass: "cof-row" },
            [
              _c("mnb-select", {
                attrs: {
                  options: _vm.accountNumberLengthOptions,
                  label: "Session Timeout (Minutes)",
                  disabled: !_vm.updateInformations
                },
                model: {
                  value: _vm.form.sessionTimeout,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sessionTimeout", $$v)
                  },
                  expression: "form.sessionTimeout"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _vm.updateInformations
              ? _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ])
              : _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          _vm.updateInformations = true
                        }
                      }
                    },
                    [_vm._v("Update")]
                  )
                ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [
        _c("h2", { staticClass: "inner-headers" }, [
          _vm._v("Manage > Session Management")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
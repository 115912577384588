var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.employee
    ? _c("div", { staticClass: "cp-box" }, [
        _c("ul", { staticClass: "cp-rows active" }, [
          _c("li", [
            _c("h4", [_vm._v("General Information")]),
            _vm._v(" "),
            _c("ul", { staticClass: "cp-fields justify-content-none" }, [
              _c("li", [
                _c("span", [_vm._v("Employee ID")]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.employee.employeeId) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [_vm._v("Role")]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.employee.role.name) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [_vm._v("First Name")]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.employee.firstName) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [_vm._v("Last Name")]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.employee.lastName) +
                      "\n          "
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h4", [_vm._v("Contact Information")]),
            _vm._v(" "),
            _c("ul", { staticClass: "cp-fields justify-content-none" }, [
              _c("li", [
                _c("span", [_vm._v("Email")]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.employee.email) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [_vm._v("Phone")]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.employee.phone) +
                      "\n          "
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
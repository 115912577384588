var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "content-form content-form-modern" },
        [
          _c("h3", { staticClass: "subtitle" }, [
            _vm._v("BANK & BENEFICIARY INFORMATION")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-row form-four routing-number-validate" },
            [
              _c(
                "div",
                [
                  _c("form-field-text", {
                    attrs: {
                      label: "Bank SWIFT Code",
                      validator: _vm.v.swift,
                      required: ""
                    },
                    model: {
                      value: _vm.form.swift,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "swift", $$v)
                      },
                      expression: "form.swift"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success show-btn",
                      attrs: { disabled: !_vm.showSwiftBtn },
                      on: {
                        click: function($event) {
                          return _vm.validateSwift()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-search" }),
                      _vm._v(" Validate")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("form-field-select", {
                attrs: {
                  label: "Account Type",
                  validator: _vm.v.account_type,
                  options: _vm.accountOptions,
                  required: ""
                },
                model: {
                  value: _vm.form.account_type,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "account_type", $$v)
                  },
                  expression: "form.account_type"
                }
              }),
              _vm._v(" "),
              _c("form-field-text", {
                attrs: {
                  label: "Account Name",
                  validator: _vm.v.account_name,
                  required: ""
                },
                model: {
                  value: _vm.form.account_name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "account_name", $$v)
                  },
                  expression: "form.account_name"
                }
              }),
              _vm._v(" "),
              _c("form-field-text", {
                attrs: {
                  label: "Account Number",
                  validator: _vm.v.account_number,
                  required: ""
                },
                model: {
                  value: _vm.form.account_number,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "account_number", $$v)
                  },
                  expression: "form.account_number"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "bank-name" },
            [
              _vm.bankSwiftInfo
                ? [_vm._v(_vm._s(_vm.bankSwiftInfo["bank"]) + ",")]
                : _vm._e(),
              _vm.bankSwiftAddress
                ? [
                    _vm._v(
                      _vm._s(_vm.bankSwiftAddress["address"]) +
                        ",  " +
                        _vm._s(_vm.bankSwiftAddress["line2"])
                    )
                  ]
                : _vm._e(),
              _vm._v(", "),
              _vm.bankSwiftAddress
                ? [
                    _vm._v(
                      _vm._s(_vm.bankSwiftAddress["city"]) +
                        ", " +
                        _vm._s(_vm.bankSwiftAddress["state"]) +
                        ", " +
                        _vm._s(_vm.bankSwiftAddress["zip"]) +
                        ", " +
                        _vm._s(_vm.bankSwiftAddress["country"])
                    )
                  ]
                : _vm._e(),
              _vm._v(" ")
            ],
            2
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "use_intermediary_bank_div" }, [
            _c("h3", { staticClass: "subtitle" }, [
              _vm._v("INTERMEDIARY BANK INFORMATION")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "center-checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.use_intermediary_bank,
                    expression: "form.use_intermediary_bank"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  id: "use_intermediary_bank",
                  disabled: ""
                },
                domProps: {
                  checked: Array.isArray(_vm.form.use_intermediary_bank)
                    ? _vm._i(_vm.form.use_intermediary_bank, null) > -1
                    : _vm.form.use_intermediary_bank
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.form.use_intermediary_bank,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.form,
                            "use_intermediary_bank",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.form,
                            "use_intermediary_bank",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.form, "use_intermediary_bank", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(0)
            ])
          ]),
          _vm._v(" "),
          _vm.form.use_intermediary_bank
            ? [
                _c(
                  "div",
                  { staticClass: "form-row form-split" },
                  [
                    _c("form-field-select", {
                      attrs: {
                        label: "Intermediary Bank Identifier",
                        validator: _vm.v.intermediaryBankIdentifier,
                        options: _vm.intermediaryBankIdentifierOptions,
                        required: ""
                      },
                      model: {
                        value: _vm.form.intermediaryBankIdentifier,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "intermediaryBankIdentifier", $$v)
                        },
                        expression: "form.intermediaryBankIdentifier"
                      }
                    }),
                    _vm._v(" "),
                    _vm.form.intermediaryBankIdentifier == "routing"
                      ? _c(
                          "div",
                          { staticClass: "routing-number-validate" },
                          [
                            _c("form-field-text", {
                              attrs: {
                                label: "Routing Number",
                                validator:
                                  _vm.v.intermediary_bank_routing_number,
                                required: ""
                              },
                              model: {
                                value:
                                  _vm.form.intermediary_bank_routing_number,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "intermediary_bank_routing_number",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.intermediary_bank_routing_number"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success show-btn",
                                attrs: { disabled: !_vm.showBtn },
                                on: {
                                  click: function($event) {
                                    return _vm.validateRoutingNumber()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "el-icon-search" }),
                                _vm._v(" Validate")
                              ]
                            )
                          ],
                          1
                        )
                      : _vm.form.intermediaryBankIdentifier == "swift"
                      ? _c(
                          "div",
                          { staticClass: "routing-number-validate" },
                          [
                            _c("form-field-text", {
                              attrs: {
                                label: "SWIFT Code",
                                validator: _vm.v.intermediary_bank_swift_code,
                                required: ""
                              },
                              model: {
                                value: _vm.form.intermediary_bank_swift_code,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "intermediary_bank_swift_code",
                                    $$v
                                  )
                                },
                                expression: "form.intermediary_bank_swift_code"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success show-btn",
                                attrs: { disabled: !_vm.showIntSwiftBtn },
                                on: {
                                  click: function($event) {
                                    return _vm.validateIntSwift()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "el-icon-search" }),
                                _vm._v(" Validate")
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.form.intermediaryBankIdentifier == "routing"
                  ? [
                      _c(
                        "p",
                        { staticClass: "bank-name" },
                        [
                          _vm.bankInfo
                            ? [
                                _vm._v(
                                  _vm._s(_vm.bankInfo.name) +
                                    ", " +
                                    _vm._s(_vm.bankInfo.phone) +
                                    ", " +
                                    _vm._s(_vm.bankInfo.street) +
                                    ", " +
                                    _vm._s(_vm.bankInfo.city) +
                                    ", " +
                                    _vm._s(_vm.bankInfo.state) +
                                    ", " +
                                    _vm._s(_vm.bankInfo.zip)
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      !_vm.bankInfo
                        ? _c("p", { staticClass: "error-message" }, [
                            _vm._v("No Address Provided")
                          ])
                        : _vm._e()
                    ]
                  : _vm.form.intermediaryBankIdentifier == "swift"
                  ? [
                      _c(
                        "p",
                        { staticClass: "bank-name" },
                        [
                          _vm.bankIntSwiftInfo
                            ? [
                                _vm._v(
                                  _vm._s(_vm.bankIntSwiftInfo["bank"]) + ","
                                )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.bankIntSwiftAddress
                            ? [
                                _vm._v(
                                  _vm._s(_vm.bankIntSwiftAddress["address"]) +
                                    ",  " +
                                    _vm._s(_vm.bankIntSwiftAddress["line2"]) +
                                    ", " +
                                    _vm._s(_vm.bankIntSwiftAddress["city"]) +
                                    ", " +
                                    _vm._s(_vm.bankIntSwiftAddress["state"]) +
                                    ", " +
                                    _vm._s(_vm.bankIntSwiftAddress["zip"]) +
                                    ", " +
                                    _vm._s(_vm.bankIntSwiftAddress["country"])
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("h3", { staticClass: "subtitle" }, [
            _vm._v("BENEFICIARY ADDRESS")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row form-split" }),
          _vm._v(" "),
          _c("address-dom-form", {
            attrs: { v: _vm.v },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.showConfirm },
          on: {
            "update:open": function($event) {
              _vm.showConfirm = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Intermediary Bank Confirmation")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scrollable" }, [
            _c(
              "div",
              { staticClass: "content-form bank-info" },
              [
                _vm.bankError
                  ? _c("p", [
                      _vm._v(
                        "Error searching Banks. " + _vm._s(_vm.bankErrorMsg)
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.notFound
                  ? _c("p", [
                      _vm._v(
                        "Intermediary Bank not found. Please check information and try again."
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.bankInfo
                  ? [
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Routing Number: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankInfo.routingNumber) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Name: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankInfo.name) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.bankInfo.street
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Address: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankInfo.street) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankInfo.city
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("City: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankInfo.city) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankInfo.state
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [
                              _c("b", [_vm._v("State/Province: ")])
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankInfo.state) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankInfo.zip
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Postal/Zip: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankInfo.zip) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankInfo.country
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Country: ")])]),
                            _vm._v(" "),
                            _c("label")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankInfo.phone
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Phone: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankInfo.phone) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.notFound || _vm.bankError
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showConfirm = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ]
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.confirmIntermediary()
                            }
                          }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showConfirm = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ]
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.showSwiftConfirm },
          on: {
            "update:open": function($event) {
              _vm.showSwiftConfirm = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Bank Swift Confirmation")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scrollable" }, [
            _c(
              "div",
              { staticClass: "content-form bank-info" },
              [
                _vm.bankError
                  ? _c("p", [
                      _vm._v(
                        "Error searching Banks. " + _vm._s(_vm.bankErrorMsg)
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.notFound
                  ? _c("p", [
                      _vm._v(
                        "Intermediary Bank not found. Please check information and try again."
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.bankSwiftInfo
                  ? [
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Swift Code: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.form.swift) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Name: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankSwiftAddress["name"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.bankSwiftAddress["address"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Address: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankSwiftAddress["address"]) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankSwiftAddress["city"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("City: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankSwiftAddress["city"]) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankSwiftAddress["state"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [
                              _c("b", [_vm._v("State/Province: ")])
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankSwiftAddress["state"]) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankSwiftAddress["zip"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Postal/Zip: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankSwiftAddress["zip"]) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankSwiftAddress["country"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Country: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankSwiftAddress["country"]) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankSwiftAddress["phone"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Phone: ")])]),
                            _vm._v(" "),
                            _c("label")
                          ])
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.notFound || _vm.bankError
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showSwiftConfirm = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ]
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.confirmSwiftIntermediary()
                            }
                          }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showSwiftConfirm = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ]
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.showIntSwiftConfirm },
          on: {
            "update:open": function($event) {
              _vm.showIntSwiftConfirm = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Bank Swift Confirmation")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scrollable" }, [
            _c(
              "div",
              { staticClass: "content-form bank-info" },
              [
                _vm.bankError
                  ? _c("p", [
                      _vm._v(
                        "Error searching Banks. " + _vm._s(_vm.bankErrorMsg)
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.notFound
                  ? _c("p", [
                      _vm._v(
                        "Intermediary Bank not found. Please check information and try again."
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.bankIntSwiftInfo
                  ? [
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Swift Code: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.form.intermediary_bank_swift_code) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Name: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankIntSwiftAddress["name"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.bankIntSwiftAddress["address"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Address: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankIntSwiftAddress["address"]) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankIntSwiftAddress["city"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("City: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankIntSwiftAddress["city"]) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankIntSwiftAddress["state"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [
                              _c("b", [_vm._v("State/Province: ")])
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankIntSwiftAddress["state"]) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankIntSwiftAddress["zip"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Postal/Zip: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankIntSwiftAddress["zip"]) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankIntSwiftAddress["country"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Country: ")])]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.bankIntSwiftAddress["country"]) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bankIntSwiftAddress["phone"]
                        ? _c("div", { staticClass: "form-row form-split" }, [
                            _c("label", [_c("b", [_vm._v("Phone: ")])]),
                            _vm._v(" "),
                            _c("label")
                          ])
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.notFound || _vm.bankError
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showIntSwiftConfirm = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ]
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.confirmIntSwiftIntermediary()
                            }
                          }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showIntSwiftConfirm = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ]
              ],
              2
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "use_intermediary_bank" } }, [
      _vm._v("Use Intermediary Bank "),
      _c("span", { staticClass: "error-message" }, [_vm._v("*Required")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
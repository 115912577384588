var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.registration
    ? _c(
        "div",
        { staticClass: "cp-box" },
        [
          _c("ul", { staticClass: "sub-tabs" }, [
            _c(
              "li",
              {
                class: { active: _vm.activeTab === "users" },
                on: {
                  click: function($event) {
                    _vm.activeTab = "users"
                  }
                }
              },
              [_c("a", [_vm._v("Users")])]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { active: _vm.activeTab === "log" },
                on: {
                  click: function($event) {
                    _vm.activeTab = "log"
                  }
                }
              },
              [_c("a", [_vm._v("User Log")])]
            )
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.activeTab === "users"
            ? _c("access-users", { attrs: { registration: _vm.registration } })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === "log"
            ? _c("access-user-log", {
                attrs: { registration: _vm.registration }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", [
    _c("div", { staticClass: "content-form" }, [
      _c(
        "div",
        { staticClass: "form-row form-fullw" },
        [
          _c("form-field-text", {
            attrs: {
              type: "password",
              validator: _vm.$v.form.current,
              label: "Current Password",
              required: ""
            },
            model: {
              value: _vm.form.current,
              callback: function($$v) {
                _vm.$set(_vm.form, "current", $$v)
              },
              expression: "form.current"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-fullw" },
        [
          _c("form-field-text", {
            attrs: {
              type: "password",
              validator: _vm.$v.form.password,
              label: "New Password",
              required: ""
            },
            model: {
              value: _vm.form.password,
              callback: function($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-fullw" },
        [
          _c("form-field-text", {
            attrs: {
              type: "password",
              validator: _vm.$v.form.confirmPassword,
              label: "Confirm New Password",
              required: ""
            },
            model: {
              value: _vm.form.confirmPassword,
              callback: function($$v) {
                _vm.$set(_vm.form, "confirmPassword", $$v)
              },
              expression: "form.confirmPassword"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-row form-center" }, [
        _c("div", [
          _c(
            "button",
            {
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("Confirm")]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "cancel",
          on: {
            click: function($event) {
              return _vm.reset()
            }
          }
        },
        [_vm._v("Cancel")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "form-box reg-form" },
    [
      !_vm.internal
        ? _c("h1", { staticClass: "logo" }, [
            _c("img", {
              attrs: {
                src: _vm.base + _vm.system.logo,
                alt: _vm.system.logo + " Logo"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.internal
        ? _c("h2", [_c("span", [_vm._v(_vm._s(_vm.system.name))])])
        : _vm._e(),
      _vm._v(" "),
      !_vm.internal
        ? _c("h3", { staticStyle: { "margin-bottom": "10px" } }, [
            _vm._v("PARTNER REGISTRATION")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.system.registration_form
        ? [
            !_vm.internal
              ? _c("h4", { staticClass: "app-page-text" }, [
                  _vm._v(_vm._s(_vm.system.app_page_text))
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.submitted
              ? [
                  _c("div", { staticClass: "content-form" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-row form-fullw" },
                      [
                        _c("form-field-select", {
                          attrs: {
                            label: "Partner Type",
                            required: true,
                            options: _vm.partnerOptions
                          },
                          model: {
                            value: _vm.partnerType,
                            callback: function($$v) {
                              _vm.partnerType = $$v
                            },
                            expression: "partnerType"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.partnerType && _vm.form
                    ? [
                        _c(
                          "div",
                          { staticClass: "content-form" },
                          [
                            _c("partner-form", {
                              attrs: { v: _vm.$v.form },
                              on: {
                                "add-contact": function($event) {
                                  return _vm.addContact()
                                },
                                "remove-contact": function($event) {
                                  return _vm.removeContact()
                                },
                                "add-document": function($event) {
                                  return _vm.addDocument()
                                },
                                "remove-document": function($event) {
                                  return _vm.removeDocument()
                                }
                              },
                              model: {
                                value: _vm.form,
                                callback: function($$v) {
                                  _vm.form = $$v
                                },
                                expression: "form"
                              }
                            }),
                            _vm._v(" "),
                            _vm._m(1),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-row form-fullw" }, [
                              _c("div", { staticClass: "autoh" }, [
                                _c("ul", { staticClass: "cmarks" }, [
                                  _c("li", [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "check",
                                        class: { error: _vm.errors.terms }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.terms,
                                              expression: "terms"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(_vm.terms)
                                              ? _vm._i(_vm.terms, null) > -1
                                              : _vm.terms
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.terms,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.terms = $$a.concat([
                                                      $$v
                                                    ]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.terms = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.terms = $$c
                                              }
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span"),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                _vm.termsModal = true
                                              }
                                            }
                                          },
                                          [_vm._v("Terms & Conditions")]
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "check",
                                        class: {
                                          error: _vm.errors.privacy_policy
                                        }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.privacy_policy,
                                              expression: "privacy_policy"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.privacy_policy
                                            )
                                              ? _vm._i(
                                                  _vm.privacy_policy,
                                                  null
                                                ) > -1
                                              : _vm.privacy_policy
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.privacy_policy,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.privacy_policy = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.privacy_policy = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.privacy_policy = $$c
                                              }
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span"),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                _vm.privacyModal = true
                                              }
                                            }
                                          },
                                          [_vm._v("Privacy Policy")]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-row form-center" }, [
                              _c("div", {}, [
                                !_vm.fileError
                                  ? _c(
                                      "button",
                                      {
                                        attrs: { disabled: _vm.saving },
                                        on: {
                                          click: function($event) {
                                            return _vm.submit()
                                          }
                                        }
                                      },
                                      [_vm._v("Submit Partner")]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.retry()
                                          }
                                        }
                                      },
                                      [_vm._v("Retry Partner")]
                                    )
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ]
              : _c("div", { staticClass: "submitted-note" }, [
                  _vm.success
                    ? _c("div", { staticClass: "success" }, [
                        _vm._v(
                          "\n        Partner Successfully Submitted!\n        "
                        ),
                        _vm.internal
                          ? _c("div", { staticClass: "register-shade" })
                          : _vm._e()
                      ])
                    : _c("div", { staticClass: "denied" }, [
                        _c("span", [
                          _vm._v(
                            "We are unable to complete your request at this time. Please contact support at "
                          ),
                          _c("b", [_vm._v(_vm._s(_vm.system.support_email))]),
                          _vm._v(" or by phone at "),
                          _c("b", [_vm._v(_vm._s(_vm.system.support_phone))]),
                          _vm._v(" to complete your request.")
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v("Thank you, \n        "),
                        _c("br"),
                        _c("br"),
                        _c("b", [_vm._v(_vm._s(_vm.system.dba_name))])
                      ])
                ])
          ]
        : _c("h4", { staticClass: "app-page-text" }, [
            _vm._v(_vm._s(_vm.system.disabled_form_text))
          ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.termsModal },
          on: {
            "update:open": function($event) {
              _vm.termsModal = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "privacy-popup scrollable",
              on: {
                "&scroll": function($event) {
                  return _vm.handleScroll($event)
                }
              }
            },
            [_c("terms-popup")],
            1
          ),
          _vm._v(" "),
          _vm.submitClicked
            ? _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c(
                    "button",
                    {
                      class: _vm.reachTerms ? "main" : "second",
                      on: {
                        click: function($event) {
                          return _vm.acceptTerms()
                        }
                      }
                    },
                    [_vm._v("Accept")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.termsModal = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _vm.showTermsMessage
                    ? [
                        _vm._v(
                          "You must complete to the bottom of the Terms in order to accept."
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.privacyModal },
          on: {
            "update:open": function($event) {
              _vm.privacyModal = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "privacy-popup scrollable" },
            [_c("privacy-popup")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.fileErrorPopup },
          on: {
            "update:open": function($event) {
              _vm.fileErrorPopup = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "scrollable",
              on: {
                "&scroll": function($event) {
                  return _vm.handleScroll($event)
                }
              }
            },
            [
              _c("div", {}, [
                _c("h2", [_c("strong", [_vm._v("Document upload error")])]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          There was an issue with your registration documents. Please select a new file to upload and click below to retry your registration.\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          Please contact support if you continue to experience issues.\n        "
                  )
                ])
              ])
            ]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [_vm._v("Partner Type "), _c("span", [_vm._v("*")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-fullw" }, [
      _c("div", { staticClass: "txtc" }, [
        _c("p", [_vm._v("Do you agree to our:")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cp-box" }, [
    _c("ul", { staticClass: "cp-rows active" }, [
      _c("li", [
        _c(
          "button",
          {
            staticClass: "btn btn-success",
            on: {
              click: function($event) {
                _vm.showCreate = true
              }
            }
          },
          [_vm._v("Add New")]
        )
      ]),
      _vm._v(" "),
      _c(
        "li",
        { staticClass: "boxed-plist" },
        [
          _c("h4", [_vm._v("Profile Notes")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.notes, function(note) {
            return _c(
              "ul",
              { key: note.id, staticClass: "cp-fields profile-lists" },
              [
                _c("li", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("date")(note.createdAt)) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("\n          " + _vm._s(note.addedBy) + "\n        ")
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "note-area" }, [
                  _vm._v("\n          " + _vm._s(note.notes) + "\n        ")
                ])
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.showCreate
        ? _c("li", [
            _c("h4", [_vm._v("Add New Note")]),
            _vm._v(" "),
            _c("ul", { staticClass: "cp-fields" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.text,
                    expression: "text"
                  }
                ],
                staticClass: "textarea-profile-notes",
                attrs: { rows: "5" },
                domProps: { value: _vm.text },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.text = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "cp-fields" }, [
              _c("li", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    on: {
                      click: function($event) {
                        return _vm.saveEmployeeNotes()
                      }
                    }
                  },
                  [_vm._v("Save")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "cp-fields profile-lists" }, [
      _c("li", [_c("span", [_vm._v("Date / Time")])]),
      _vm._v(" "),
      _c("li", [_c("span", [_vm._v("Added By")])]),
      _vm._v(" "),
      _c("li", [_c("span", [_vm._v("Note")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
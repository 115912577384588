var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticStyle: { margin: "30px" } }, [
      _c("div", { staticClass: "content-form" }, [
        _c(
          "div",
          { staticClass: "form-row form-split" },
          [
            _c("form-field-select", {
              attrs: {
                label: "Select Account Type",
                options: {
                  Personal: "Personal Accounts",
                  Business: "Business Accounts"
                }
              },
              model: {
                value: _vm.type,
                callback: function($$v) {
                  _vm.type = $$v
                },
                expression: "type"
              }
            }),
            _vm._v(" "),
            _c("form-field-select", {
              attrs: { label: "Available Account plans", options: _vm.plans },
              model: {
                value: _vm.plan,
                callback: function($$v) {
                  _vm.plan = $$v
                },
                expression: "plan"
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content-tlist" }, [
      _c(
        "table",
        { staticClass: "content-table" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.feeSchedule
            ? [
                _c("tr", [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Fee")]),
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.volumeFee.fee
                          )
                        ) +
                        " %\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Fee")]),
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.monthlyAccessFee.fee
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(3),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.domesticWire.per_deposit
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.domesticWire.per_withdrawal
                          )
                        ) +
                        "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(5),
                  _vm._v(" "),
                  _vm._m(6),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.internationalWire.per_deposit
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.internationalWire.per_withdrawal
                          )
                        ) +
                        "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(7),
                  _vm._v(" "),
                  _vm._m(8),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.ach.per_deposit
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.ach.per_withdrawal
                          )
                        ) +
                        "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(9),
                  _vm._v(" "),
                  _vm._m(10),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.accountTransfer.per_withdrawal
                          )
                        ) +
                        "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(11),
                  _vm._v(" "),
                  _vm._m(12),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.checks.per_deposit
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.checks.per_withdrawal
                          )
                        ) +
                        " \n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(13),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Fee")]),
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.exchange.fee
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(14),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Fee")]),
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.assets.fee
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.assets.per_deposit
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.assets.per_withdrawal
                          )
                        ) +
                        "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(15),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Fee")]),
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.dormantAccounts.fee
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _vm._m(16),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Fee")]),
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.debitCard.fee
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            $ " +
                        _vm._s(
                          _vm._f("formattedTwoDecimal")(
                            _vm.feeSchedule.debitCard.per_withdrawal
                          )
                        ) +
                        "\n          "
                    )
                  ])
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Fee Name")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Fee")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Deposit")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Withdrawal")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Volume Fee\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Monthly Access Fee\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Domestic Wire\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            International Wire\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            ACH\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Account Transfer\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Checks\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Fee")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Exchange\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Assets\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Dormant Accounts\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Fee Name")]),
      _vm._v("\n            Debit Card\n          ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
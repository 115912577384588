var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "form-row form-split" },
      [
        _c("form-field-text", {
          attrs: {
            maxlength: 25,
            validator: _vm.v.firstName,
            label: "First Name",
            required: ""
          },
          model: {
            value: _vm.form.firstName,
            callback: function($$v) {
              _vm.$set(_vm.form, "firstName", $$v)
            },
            expression: "form.firstName"
          }
        }),
        _vm._v(" "),
        _c("form-field-text", {
          attrs: {
            maxlength: 25,
            validator: _vm.v.lastName,
            label: "Last Name",
            required: ""
          },
          model: {
            value: _vm.form.lastName,
            callback: function($$v) {
              _vm.$set(_vm.form, "lastName", $$v)
            },
            expression: "form.lastName"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-row form-split" },
      [
        _c("form-field-text", {
          attrs: {
            maxlength: 20,
            validator: _vm.v.phone,
            label: "Phone Number",
            required: ""
          },
          model: {
            value: _vm.form.phone,
            callback: function($$v) {
              _vm.$set(_vm.form, "phone", $$v)
            },
            expression: "form.phone"
          }
        }),
        _vm._v(" "),
        _c("form-field-text", {
          attrs: {
            maxlength: 20,
            validator: _vm.v.mobile,
            label: "Mobile Number",
            required: ""
          },
          model: {
            value: _vm.form.mobile,
            callback: function($$v) {
              _vm.$set(_vm.form, "mobile", $$v)
            },
            expression: "form.mobile"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "form-row form-fullw",
        staticStyle: { position: "relative", "margin-bottom": "10px" }
      },
      [
        _c("form-field-text", {
          attrs: {
            validator: _vm.v.email,
            label: "Email Address",
            required: ""
          },
          model: {
            value: _vm.form.email,
            callback: function($$v) {
              _vm.$set(_vm.form, "email", $$v)
            },
            expression: "form.email"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$hasPerm("access")
        ? [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: {
                  disabled: !_vm.selected || !_vm.selected.activationCode
                },
                on: {
                  click: function($event) {
                    return _vm.resetActivationCode()
                  }
                }
              },
              [_vm._v("Reset Activation Code")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: {
                  disabled: !_vm.selected || _vm.selected.activationCode
                },
                on: {
                  click: function($event) {
                    return _vm.resetPassword()
                  }
                }
              },
              [_vm._v("Reset Password")]
            ),
            _vm._v(" "),
            _vm.selected
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-block",
                    attrs: { disabled: !_vm.selected },
                    on: {
                      click: function($event) {
                        return _vm.toggleBlock()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.selected.active === "Blocked" ? "Unblock" : "Block"
                      )
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.registration.status != "Closed"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { disabled: _vm.registration.status == "Dormant" },
                    on: {
                      click: function($event) {
                        return _vm.openAddUser()
                      }
                    }
                  },
                  [_vm._v("Add Sub-User")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  disabled:
                    !_vm.selected ||
                    _vm.selected.id === _vm.registration.user.id
                },
                on: {
                  click: function($event) {
                    return _vm.remove()
                  }
                }
              },
              [_vm._v("\n      Remove Sub-User\n    ")]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
        [
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.users, function(user) {
                return _c("tr", { key: user.id }, [
                  _c("td", [
                    _c("span"),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { type: "radio", name: "access-user" },
                      on: {
                        change: function($event) {
                          return _vm.onCbxClicked($event, user)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Username")]),
                    _vm._v(_vm._s(user.username))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("First Name")]),
                    _vm._v(_vm._s(user.firstName))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Last Name")]),
                    _vm._v(_vm._s(user.lastName))
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Activation Code")]),
                      _vm._v(" "),
                      user.activationCode === "Expired"
                        ? _c("em", { staticClass: "c4" }, [
                            _vm._v(_vm._s(user.activationCode))
                          ])
                        : [_vm._v(_vm._s(user.activationCode || "N/A"))]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Activation Code Expires")]),
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(
                          user.activationCodeExpiryDate,
                          "MM/D/YYYY hh:mm A"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Activation Date")]),
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(user.activationDate, "MM/D/YYYY hh:mm A")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Reset Pass Request")]),
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(
                          user.resetPasswordRequestedDateTime,
                          "MM/D/YYYY hh:mm A"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Reset Pass Complete")]),
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(
                          user.resetPasswordCompletedDateTime,
                          "MM/D/YYYY hh:mm A"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Blocked")]),
                    _vm._v(_vm._s(user.active === "Blocked" ? "Yes" : "No"))
                  ])
                ])
              })
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.addModal },
          on: {
            "update:open": function($event) {
              _vm.addModal = $event
            }
          }
        },
        [
          _vm.addModal
            ? [
                _c("h4", { staticClass: "popup-title" }, [
                  _vm._v("Add Sub-User")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "scrollable" }, [
                  _c("div", { staticClass: "content-form" }, [
                    _c(
                      "div",
                      { staticClass: "form-row form-split" },
                      [
                        _c("form-field-text", {
                          attrs: {
                            maxlength: 25,
                            validator: _vm.$v.form.firstName,
                            label: "First Name",
                            required: ""
                          },
                          model: {
                            value: _vm.form.firstName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "firstName", $$v)
                            },
                            expression: "form.firstName"
                          }
                        }),
                        _vm._v(" "),
                        _c("form-field-text", {
                          attrs: {
                            maxlength: 25,
                            validator: _vm.$v.form.lastName,
                            label: "Last Name",
                            required: ""
                          },
                          model: {
                            value: _vm.form.lastName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "lastName", $$v)
                            },
                            expression: "form.lastName"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-row form-split" },
                      [
                        _c("form-field-text", {
                          attrs: {
                            validator: _vm.$v.form.email,
                            label: "Email Address",
                            required: ""
                          },
                          model: {
                            value: _vm.form.email,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "email", $$v)
                            },
                            expression: "form.email"
                          }
                        }),
                        _vm._v(" "),
                        _c("form-field-text", {
                          attrs: {
                            maxlength: 15,
                            validator: _vm.$v.form.phone,
                            label: "Phone Number",
                            required: ""
                          },
                          model: {
                            value: _vm.form.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "phone", $$v)
                            },
                            expression: "form.phone"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.add()
                        }
                      }
                    },
                    [_vm._v("Add")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.addModal = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th"),
      _vm._v(" "),
      _c("th", [_vm._v("Username")]),
      _vm._v(" "),
      _c("th", [_vm._v("First Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Last Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Activation Code")]),
      _vm._v(" "),
      _c("th", [_vm._v("Activation Code Expires")]),
      _vm._v(" "),
      _c("th", [_vm._v("Activation Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Reset Pass Request")]),
      _vm._v(" "),
      _c("th", [_vm._v("Reset Pass Complete")]),
      _vm._v(" "),
      _c("th", [_vm._v("Blocked")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cp-box" }, [
    _c("ul", { staticClass: "cp-rows active" }, [
      _c("li", [
        _c("ul", { staticClass: "cp-fields" }, [
          _c("li", [
            _c("span", [_vm._v("Client Since")]),
            _vm._v(" "),
            _c("div", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm._f("date")(_vm.registration.createdAt, "LL")))
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("span", [_vm._v("Profile Type")]),
            _vm._v(" "),
            _c("div", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.registration.type))
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("span", [_vm._v("Profile Number")]),
            _vm._v(" "),
            _c("div", { staticClass: "val" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.registration.profileNumber) +
                  "\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.registration.partner
            ? _c("li", [
                _c("span", [_vm._v("Partner")]),
                _vm._v(" "),
                _c("div", { staticClass: "val" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.registration.partner.partnerNumber) +
                      " - " +
                      _vm._s(_vm.registration.partner.business.legal_name) +
                      "\n          "
                  )
                ])
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filled" }, [
    _c("label", [
      _vm._v(_vm._s(_vm.label) + " "),
      _vm.required ? _c("em", [_vm._v("*")]) : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { ref: "fieldBox", staticClass: "vgv-field" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./CorporateStatements.vue?vue&type=template&id=722b5b33&scoped=true&"
import script from "./CorporateStatements.vue?vue&type=script&lang=js&"
export * from "./CorporateStatements.vue?vue&type=script&lang=js&"
import style0 from "./CorporateStatements.vue?vue&type=style&index=0&id=722b5b33&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "722b5b33",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6monbi/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('722b5b33')) {
      api.createRecord('722b5b33', component.options)
    } else {
      api.reload('722b5b33', component.options)
    }
    module.hot.accept("./CorporateStatements.vue?vue&type=template&id=722b5b33&scoped=true&", function () {
      api.rerender('722b5b33', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/monbi/accounting/CorporateStatements.vue"
export default component.exports
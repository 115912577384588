var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "static-page privacy-popup" }, [
    _c("h2", [_c("strong", [_vm._v(_vm._s(_vm.legal.privacyPolicyHeader))])]),
    _vm._v(" "),
    _c("div", { domProps: { innerHTML: _vm._s(_vm.legal.privacyPolicyText) } }),
    _vm._v(" "),
    _c("h3", [_vm._v("Last updated")]),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm.legal.privacyPolicyDate))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
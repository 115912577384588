var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        [
          !_vm.processFlag
            ? _c("section", [
                _c(
                  "div",
                  {
                    staticClass: "flex-between",
                    staticStyle: { position: "relative" }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { padding: "24px 30px 10px 10px" } },
                      [
                        _vm.$hasPerm("funds_processing")
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                on: {
                                  click: function($event) {
                                    return _vm.open("deposit")
                                  }
                                }
                              },
                              [_vm._v("+ New Deposit")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom-end",
                              width: "300",
                              "popper-class": "paddingless-popper",
                              trigger: "click"
                            }
                          },
                          [
                            _c(
                              "table",
                              { staticClass: "content-table smaller" },
                              [
                                _c("tr", [
                                  _c("th", [_vm._v("Funds")]),
                                  _vm._v(" "),
                                  _c("th"),
                                  _vm._v(" "),
                                  _c("th")
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [_vm._v("Deposit")]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(_vm.totalFundsDepositNumber))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      "$" +
                                        _vm._s(
                                          _vm._f("balance_without_symbol")(
                                            _vm.totalDepositAmount
                                          )
                                        )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [_vm._v("Withdrawals")]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      _vm._s(_vm.totalFundsWithdrawalNumber)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      "$" +
                                        _vm._s(
                                          _vm._f("balance_without_symbol")(
                                            _vm.totalWithdrawalAmount
                                          )
                                        )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Assets")]),
                                  _vm._v(" "),
                                  _c("th"),
                                  _vm._v(" "),
                                  _c("th")
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [_vm._v("Deposit")]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(_vm.totalAssetsDepositNumber))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(_vm._s(_vm.totalAssetsDepositAmount))
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [_vm._v("Withdrawals")]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      _vm._s(_vm.totalAssetsWithdrawalNumber)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(_vm.totalAssetsWithdrawalAmount)
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn second",
                                attrs: { slot: "reference" },
                                slot: "reference"
                              },
                              [
                                _c("i", { staticClass: "el-icon-tickets" }),
                                _vm._v(" Summary")
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { padding: "24px 30px 10px 10px" } },
                      [
                        _vm.totalNumber > 0
                          ? [
                              _vm.filters
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn second",
                                      on: {
                                        click: function($event) {
                                          _vm.downloadModal = true
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-download"
                                      }),
                                      _vm._v("Download")
                                    ]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn second",
                                      on: {
                                        click: function($event) {
                                          _vm.downloadModal = true
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-download"
                                      }),
                                      _vm._v("Download")
                                    ]
                                  )
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                _vm.openedFilters = true
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "el-icon-s-operation" }),
                            _vm._v(" Filters")
                          ]
                        )
                      ],
                      2
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("applied-filters", {
            attrs: { filters: _vm.appliedFilters },
            on: {
              clear: function($event) {
                return _vm.clearFilters()
              },
              remove: function($event) {
                return _vm.removeFilter($event)
              }
            }
          }),
          _vm._v(" "),
          !_vm.modalType && !_vm.processFlag
            ? _c("div", { staticClass: "accordion" }, [
                _c(
                  "div",
                  {
                    class: {
                      "trans-sub-cof-box": true,
                      "cof-box-active": _vm.activeInfo
                    }
                  },
                  [
                    !_vm.loading
                      ? _c(
                          "section",
                          { staticClass: "content-tlist" },
                          [
                            _c(
                              "el-table",
                              {
                                ref: "transactionsTable",
                                staticClass: "content-table",
                                attrs: { data: _vm.filteredData },
                                on: { "row-click": _vm.transactionsTableClick }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { type: "expand" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm._l([[props.row]], function(
                                              selectedTransaction
                                            ) {
                                              return _c(
                                                "div",
                                                { staticClass: "popup-header" },
                                                [
                                                  _c("h3", [
                                                    _vm._v("Transaction")
                                                  ]),
                                                  _vm._v(" "),
                                                  selectedTransaction
                                                    ? _c("h3", [
                                                        _vm._v(
                                                          _vm._s(
                                                            selectedTransaction[0]
                                                              .transactionId
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  selectedTransaction
                                                    ? _c("h3", [
                                                        _vm._v(
                                                          "Status: " +
                                                            _vm._s(
                                                              selectedTransaction[0]
                                                                .status
                                                            )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            }),
                                            _vm._v(" "),
                                            _vm._l([[props.row]], function(
                                              selectedTransaction
                                            ) {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "scrollable" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "information"
                                                      },
                                                      [
                                                        selectedTransaction
                                                          ? [
                                                              _c("div", [
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Account"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": ****" +
                                                                      _vm._s(
                                                                        selectedTransaction[0].accountId.substr(
                                                                          -3
                                                                        )
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Created"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .createdAt
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Entry Type"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .entryType
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Currency Type"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .currencyType
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "p",
                                                                  [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Transaction Amount"
                                                                      )
                                                                    ]),
                                                                    _vm._v(
                                                                      ":\n                          "
                                                                    ),
                                                                    selectedTransaction[0]
                                                                      .currencyType ==
                                                                    "Assets"
                                                                      ? [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              selectedTransaction[0]
                                                                                .assetAmount
                                                                                ? parseFloat(
                                                                                    selectedTransaction[0]
                                                                                      .assetAmount
                                                                                  )
                                                                                : ""
                                                                            )
                                                                          )
                                                                        ]
                                                                      : [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "balance"
                                                                              )(
                                                                                selectedTransaction[0]
                                                                                  .amount
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                  ],
                                                                  2
                                                                )
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("div", [
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Profile"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .profileNumber
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Completed By"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .completedBy
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Transaction Type"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .transactionTypeText[
                                                                          selectedTransaction[0]
                                                                            .transactionType
                                                                        ]
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Currency Name"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ":" +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .currencyName
                                                                      ) +
                                                                      " - " +
                                                                      _vm._s(
                                                                        _vm.getCurrencyName(
                                                                          selectedTransaction[0]
                                                                            .currencyName
                                                                        )
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "p",
                                                                  [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Transaction Fee"
                                                                      )
                                                                    ]),
                                                                    _vm._v(
                                                                      ": $ "
                                                                    ),
                                                                    selectedTransaction[0]
                                                                      .transactionFee
                                                                      ? [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "formattedTwoDecimal"
                                                                              )(
                                                                                selectedTransaction[0]
                                                                                  .transactionFee
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      : [
                                                                          _vm._v(
                                                                            "0.00"
                                                                          )
                                                                        ]
                                                                  ],
                                                                  2
                                                                )
                                                              ])
                                                            ]
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "popup-header details"
                                                      },
                                                      [
                                                        _c("h3", [
                                                          _vm._v("Details")
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    selectedTransaction
                                                      ? [
                                                          selectedTransaction[0]
                                                            .entryType ==
                                                            "Deposit" &&
                                                          selectedTransaction[0]
                                                            .transactionType ==
                                                            "Asset"
                                                            ? [
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getCurrencyName(
                                                                          selectedTransaction[0]
                                                                            .currencyName
                                                                        )
                                                                      ) +
                                                                        " Deposit Wallet Address"
                                                                    )
                                                                  ]),
                                                                  _vm._v(":")
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      selectedTransaction[0]
                                                                        .contributionIncomingWalletAddress
                                                                    )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(" ")
                                                                ])
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          selectedTransaction[0]
                                                            .entryType !=
                                                            "Deposit" &&
                                                          selectedTransaction[0]
                                                            .transactionType ==
                                                            "Asset"
                                                            ? [
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Pay to"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .label
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Pay to Wallet Address"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .outgoingWalletAddress
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Destination Tag"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .tag
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(" ")
                                                                ])
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "information"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  selectedTransaction[0]
                                                                    .entryType ==
                                                                  "Deposit"
                                                                    ? [
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                        "checks"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Payer"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .remoteDepositPayor
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .remoteDepositPayor
                                                                                              .nameOfSender
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Address"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              selectedTransaction[0]
                                                                                .remoteDepositPayor
                                                                                ? [
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .remoteDepositPayor
                                                                                              .address
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .remoteDepositPayor
                                                                                              .city
                                                                                          ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .remoteDepositPayor
                                                                                                .state
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .remoteDepositPayor
                                                                                                .country
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .remoteDepositPayor
                                                                                                .postal
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Check Front"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "image-link",
                                                                                      attrs: {
                                                                                        href:
                                                                                          "/files/transactions/" +
                                                                                          selectedTransaction[0]
                                                                                            .id +
                                                                                          "/" +
                                                                                          selectedTransaction[0]
                                                                                            .checkImageFront,
                                                                                        target:
                                                                                          "_blank"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          selectedTransaction[0]
                                                                                            .checkImageFront
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Check Bank"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "image-link",
                                                                                      attrs: {
                                                                                        href:
                                                                                          "/files/transactions/" +
                                                                                          selectedTransaction[0]
                                                                                            .id +
                                                                                          "/" +
                                                                                          selectedTransaction[0]
                                                                                            .checkImageBack,
                                                                                        target:
                                                                                          "_blank"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          selectedTransaction[0]
                                                                                            .checkImageBack
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "Asset"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .transactionReferenceCode
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Note"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": Send only " +
                                                                                      _vm._s(
                                                                                        _vm.getCurrencyName(
                                                                                          selectedTransaction[0]
                                                                                            .currencyName
                                                                                        )
                                                                                      ) +
                                                                                      " to this wallet address. Any other asset type sent may be lost and unrecoverable."
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "acc_to_acc"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "DebitCard"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "MERCHANT"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantName
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantCity
                                                                                    ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .merchantState
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .merchantCountry
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .postalCode
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantCategory
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : [
                                                                              selectedTransaction[0]
                                                                                .provider ==
                                                                                "Solid" ||
                                                                              selectedTransaction[0]
                                                                                .provider ==
                                                                                "Solid Test"
                                                                                ? [
                                                                                    selectedTransaction[0]
                                                                                      .transactionType ==
                                                                                    "ach"
                                                                                      ? [
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Source"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        selectedTransaction[0]
                                                                                                          .fundsSourceName
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Settled"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm._f(
                                                                                                          "date"
                                                                                                        )(
                                                                                                          selectedTransaction[0]
                                                                                                            .settledAt
                                                                                                        )
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Settled Details"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      "name: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .name
                                                                                                        ) +
                                                                                                        ", routingNumber: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .routingNumber
                                                                                                        ) +
                                                                                                        ", bankName: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .bankName
                                                                                                        )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " "
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " "
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Special Instructions"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        selectedTransaction[0]
                                                                                                          .specialInstructions
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "br"
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Memo"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": " +
                                                                                                  _vm._s(
                                                                                                    selectedTransaction[0]
                                                                                                      .memo
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Reference"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": " +
                                                                                                  _vm._s(
                                                                                                    selectedTransaction[0]
                                                                                                      .transactionReferenceCode
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      : selectedTransaction[0]
                                                                                          .transactionType ==
                                                                                          "domesticWire" ||
                                                                                        selectedTransaction[0]
                                                                                          .transactionType ==
                                                                                          "internationalWire"
                                                                                      ? [
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Source"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        selectedTransaction[0]
                                                                                                          .fundsSourceName
                                                                                                      ) +
                                                                                                        " name: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .name
                                                                                                        )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Settled"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm._f(
                                                                                                          "date"
                                                                                                        )(
                                                                                                          selectedTransaction[0]
                                                                                                            .settledAt
                                                                                                        )
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Settled Details"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      "routingNumber: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .routingNumber
                                                                                                        ) +
                                                                                                        ", Address Line1: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .line1
                                                                                                        ) +
                                                                                                        ", Address Line2: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .line2
                                                                                                        ) +
                                                                                                        ", city: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .city
                                                                                                        ) +
                                                                                                        ", state: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .state
                                                                                                        ) +
                                                                                                        ", country: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .country
                                                                                                        ) +
                                                                                                        ", postal: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .postalCode
                                                                                                        ) +
                                                                                                        ", bankName: " +
                                                                                                        _vm._s(
                                                                                                          selectedTransaction[0]
                                                                                                            .settlementDetails
                                                                                                            .bankName
                                                                                                        )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " "
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " "
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Special Instructions"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        selectedTransaction[0]
                                                                                                          .specialInstructions
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "br"
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Memo"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": " +
                                                                                                  _vm._s(
                                                                                                    selectedTransaction[0]
                                                                                                      .memo
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Reference"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": " +
                                                                                                  _vm._s(
                                                                                                    selectedTransaction[0]
                                                                                                      .transactionReferenceCode
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      : [
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Source"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        selectedTransaction[0]
                                                                                                          .fundsSourceName
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Settled"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm._f(
                                                                                                          "date"
                                                                                                        )(
                                                                                                          selectedTransaction[0]
                                                                                                            .settledAt
                                                                                                        )
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Settled Details"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        selectedTransaction[0]
                                                                                                          .settlementDetails
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " "
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " "
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Special Instructions"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": "
                                                                                              ),
                                                                                              selectedTransaction[0]
                                                                                                ? [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        selectedTransaction[0]
                                                                                                          .specialInstructions
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                : _vm._e()
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "br"
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Memo"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": " +
                                                                                                  _vm._s(
                                                                                                    selectedTransaction[0]
                                                                                                      .memo
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "p",
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Reference"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                ": " +
                                                                                                  _vm._s(
                                                                                                    selectedTransaction[0]
                                                                                                      .transactionReferenceCode
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                  ]
                                                                                : [
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Source"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          ": "
                                                                                        ),
                                                                                        selectedTransaction[0]
                                                                                          ? [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  selectedTransaction[0]
                                                                                                    .fundsSourceName
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          : _vm._e()
                                                                                      ],
                                                                                      2
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Settled"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          ": "
                                                                                        ),
                                                                                        selectedTransaction[0]
                                                                                          .settledAt
                                                                                          ? [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm._f(
                                                                                                    "date"
                                                                                                  )(
                                                                                                    selectedTransaction[0]
                                                                                                      .settledAt
                                                                                                  )
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          : _vm._e()
                                                                                      ],
                                                                                      2
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Settled Details"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          ": "
                                                                                        ),
                                                                                        selectedTransaction[0]
                                                                                          ? [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  selectedTransaction[0]
                                                                                                    .settlementDetails
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          : _vm._e()
                                                                                      ],
                                                                                      2
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Special Instructions"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          ": "
                                                                                        ),
                                                                                        selectedTransaction[0]
                                                                                          ? [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  selectedTransaction[0]
                                                                                                    .specialInstructions
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          : _vm._e()
                                                                                      ],
                                                                                      2
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Memo"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          ": " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .memo
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Reference"
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          ": " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .transactionReferenceCode
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                            ]
                                                                      ]
                                                                    : [
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "international_wires" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "internationalWire"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Bank Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .bankName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Swift"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .swift
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "INTERMEDIARY BANK"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Bank Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryBankName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Routing"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryBankRoutingNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "SWIFT"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryBankSwiftCode
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryBankReference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Address"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .intermediaryAddress
                                                                                        .address
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .intermediaryAddress
                                                                                        .city
                                                                                    ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryAddress
                                                                                          .state
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryAddress
                                                                                          .country
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryAddress
                                                                                          .postal
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "BENEFICIARY"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Type"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountType
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Address"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              selectedTransaction[0]
                                                                                .address
                                                                                ? [
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .address
                                                                                              .address
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .city
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .state
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .country
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .postal
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "checks"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Payee"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .payee
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Address"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              selectedTransaction[0]
                                                                                .address
                                                                                ? [
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .address
                                                                                              .address
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .address
                                                                                              .city
                                                                                          ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .state
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .country
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .postal
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "ach"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Bank Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .bankName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Routing"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .routingNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "BENEFICIARY"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Type"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountType
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                              "domestic_wire" ||
                                                                            selectedTransaction[0]
                                                                              .transactionType ==
                                                                              "domesticWire"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Bank Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .bankName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Routing Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .routingNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "BENEFICIARY"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Type"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountType
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Address"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              selectedTransaction[0]
                                                                                .address
                                                                                ? [
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .address
                                                                                              .address
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .address
                                                                                              .city
                                                                                          ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .state
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .country
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .postal
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "Asset"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "acc_to_acc"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .toAccountName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .toAccountId
                                                                                          ? _vm.getAccountNumber(
                                                                                              selectedTransaction[0]
                                                                                                .toAccountId
                                                                                            )
                                                                                          : ""
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "Trade"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "System"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "DebitCard"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "MERCHANT"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantName
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantCity
                                                                                    ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .merchantState
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .merchantCountry
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .postalCode
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantCategory
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": ****" +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .toAccountId
                                                                                          ? selectedTransaction[0].toAccountId.substr(
                                                                                              4,
                                                                                              3
                                                                                            )
                                                                                          : ""
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                      ]
                                                                ],
                                                                2
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                [
                                                                  selectedTransaction[0]
                                                                    .entryType ==
                                                                  "Deposit"
                                                                    ? [
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "domestic_wire" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "international_wires" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "ach" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "Asset" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "acc_to_acc" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "Trade" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "domesticWire" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "internationalWire"
                                                                          ? void 0
                                                                          : [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              )
                                                                            ]
                                                                      ]
                                                                    : [
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "Asset" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "Trade"
                                                                          ? void 0
                                                                          : [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              )
                                                                            ]
                                                                      ]
                                                                ],
                                                                2
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "popup-header notes"
                                                      },
                                                      [
                                                        _c("h3", [
                                                          _vm._v("Notes")
                                                        ]),
                                                        _vm._v(" "),
                                                        !_vm.isCustomer &&
                                                        !_vm.addNote
                                                          ? _c("img", {
                                                              staticClass:
                                                                "main plus-img",
                                                              attrs: {
                                                                src:
                                                                  "/assets/img/green30.png"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.addNote = true
                                                                }
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.addNote
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "action-button save add-note-button",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveNote(
                                                                      selectedTransaction[0]
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("Save")]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.addNote
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content-form"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-row form-max"
                                                              },
                                                              [
                                                                _c(
                                                                  "form-field-text",
                                                                  {
                                                                    model: {
                                                                      value:
                                                                        _vm.note,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.note = $$v
                                                                      },
                                                                      expression:
                                                                        "note"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "content-table"
                                                      },
                                                      [
                                                        _c("tr", [
                                                          _c(
                                                            "th",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "0"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Date/Time"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("th", [
                                                            _vm._v("Added By")
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("th", [
                                                            _vm._v("Note")
                                                          ])
                                                        ]),
                                                        _vm._v(" "),
                                                        selectedTransaction
                                                          ? _vm._l(
                                                              selectedTransaction[0]
                                                                .notes,
                                                              function(
                                                                item,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  "tr",
                                                                  {
                                                                    key: item.id
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticStyle: {
                                                                          "padding-left":
                                                                            "0"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Date/Time"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "date"
                                                                            )(
                                                                              item.noteTime
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("td", [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Added By"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.addedBy
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("td", [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Note"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.note
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "popup-header notes"
                                                  },
                                                  [
                                                    _c("h3", [
                                                      _vm._v("Options")
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "buttons" },
                                                  [
                                                    selectedTransaction
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "optionBtns"
                                                          },
                                                          [
                                                            _vm.$hasPerm(
                                                              "approve-transaction"
                                                            )
                                                              ? [
                                                                  !_vm.isCustomer &&
                                                                  selectedTransaction[0]
                                                                    .status ===
                                                                    "Review"
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "approve",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.approvePopupTransaction(
                                                                                selectedTransaction[0]
                                                                                  .id
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Approve"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            !_vm.isCustomer
                                                              ? [
                                                                  _vm.perms
                                                                    ? [
                                                                        selectedTransaction[0]
                                                                          .status ===
                                                                          "Pending" ||
                                                                        selectedTransaction[0]
                                                                          .status ===
                                                                          "Review"
                                                                          ? _c(
                                                                              "button",
                                                                              {
                                                                                staticClass:
                                                                                  "cancel cancel-action",
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.cancelPopupTransaction(
                                                                                      selectedTransaction[0]
                                                                                        .id
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Cancel"
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    : _vm._e()
                                                                ]
                                                              : [
                                                                  selectedTransaction[0]
                                                                    .status ===
                                                                    "Pending" ||
                                                                  selectedTransaction[0]
                                                                    .status ===
                                                                    "Review"
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "cancel cancel-action",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.cancelPopupTransaction(
                                                                                selectedTransaction[0]
                                                                                  .id
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Cancel"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "main",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.print(
                                                              selectedTransaction[0]
                                                                .id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Print")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3147670178
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Date", "min-width": "7" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("date")(
                                                    props.row.createdAt,
                                                    "MM/D/YYYY"
                                                  )
                                                )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      props.row.createdAt,
                                                      "hh:mm A"
                                                    )
                                                  )
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3405582704
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Account",
                                    "min-width": "10"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            !_vm.isCustomer
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/system-customers/registrations/" +
                                                        props.row.regId +
                                                        "?tab=Accounts"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.row.accountId
                                                      )
                                                    )
                                                  ]
                                                )
                                              : [
                                                  _vm._v(
                                                    _vm._s(props.row.accountId)
                                                  )
                                                ]
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2298757196
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Number", "min-width": "7" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  props.row.transactionId
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2928896148
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Entry", "min-width": "6" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  props.row.entryType ==
                                                    "Deposit"
                                                    ? "DEP"
                                                    : "W/D"
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2824671145
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Type", "min-width": "8" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.transactionTypeText[
                                                    props.row.transactionType
                                                  ]
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3882519206
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Currency",
                                    "min-width": "6",
                                    prop: "currencyName"
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Amount", "min-width": "10" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            props.row.currencyType == "Assets"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      parseFloat(
                                                        props.row.assetAmount
                                                      )
                                                    )
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row.currencySymbol
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "balance_without_symbol"
                                                        )(props.row.amount)
                                                      )
                                                  )
                                                ]
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1435241097
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Balance", "min-width": "8" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            props.row.currencyType == "Assets"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      parseFloat(
                                                        props.row
                                                          .availableAssetsBalance
                                                      )
                                                    )
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row.currencySymbol
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "balance_without_symbol"
                                                        )(
                                                          props.row
                                                            .availableFundsBalance
                                                        )
                                                      )
                                                  )
                                                ]
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2898522032
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Status", "min-width": "7" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _c(
                                              "em",
                                              {
                                                class:
                                                  _vm.statusColorClasses[
                                                    props.row.status
                                                  ]
                                              },
                                              [_vm._v(_vm._s(props.row.status))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1839509103
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Reference",
                                    "min-width": "17",
                                    prop: "reference"
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Options",
                                    "min-width": "14"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm.$hasPerm("funds_processing")
                                              ? [
                                                  props.row.provider ==
                                                  "Think Green"
                                                    ? [
                                                        props.row.status ===
                                                        "Pending"
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "action-button cancel",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.cancelTransaction(
                                                                      props.row
                                                                        .id
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("Cancel")]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        props.row.status ===
                                                        "Review"
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "action-button cancel",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.cancelTransaction(
                                                                      props.row
                                                                        .id
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("Cancel")]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  props.row.entryType ==
                                                    "Withdrawal" &&
                                                  props.row.status == "Pending"
                                                    ? [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "action-button save",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.process(
                                                                  props.row
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [_vm._v("Process")]
                                                        )
                                                      ]
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  props.row.transactionType !=
                                                    "System" &&
                                                  props.row.transactionType !=
                                                    "acc_to_acc" &&
                                                  props.row.status == "Complete"
                                                    ? [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "action-button save",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.process(
                                                                  props.row
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [_vm._v("Reversal")]
                                                        )
                                                      ]
                                                    : _vm._e()
                                                ]
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2050702520
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.loading
                      ? _c("section", { staticClass: "pagination-section" }, [
                          _c("div", [
                            _vm._v("\n          Per page\n          "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.perPage,
                                    expression: "perPage"
                                  }
                                ],
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.perPage = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function($event) {
                                      return _vm.setPages()
                                    }
                                  ]
                                }
                              },
                              [
                                _c("option", { attrs: { value: "25" } }, [
                                  _vm._v("25")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "50" } }, [
                                  _vm._v("50")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "75" } }, [
                                  _vm._v("75")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "100" } }, [
                                  _vm._v("100")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "150" } }, [
                                  _vm._v("150")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "200" } }, [
                                  _vm._v("200")
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "nav",
                            {
                              attrs: { "aria-label": "Page navigation example" }
                            },
                            [
                              _c("ul", { staticClass: "pagination" }, [
                                _c("li", { staticClass: "page-item" }, [
                                  _vm.page != 1
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "page-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              _vm.page--
                                            }
                                          }
                                        },
                                        [_vm._v(" Previous ")]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { staticClass: "page-item" },
                                  _vm._l(
                                    _vm.pages.slice(_vm.page - 1, _vm.page + 5),
                                    function(pageNumber) {
                                      return _c(
                                        "button",
                                        {
                                          key: pageNumber,
                                          staticClass: "page-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              _vm.page = pageNumber
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(pageNumber) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("li", { staticClass: "page-item" }, [
                                  _vm.page < _vm.pages.length
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "page-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              _vm.page++
                                            }
                                          }
                                        },
                                        [_vm._v(" Next ")]
                                      )
                                    : _vm._e()
                                ])
                              ])
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.modalType && _vm.processFlag
            ? _c("div", { staticClass: "accordion" }, [
                _c("div", { staticClass: "trans-sub-cof-box cof-box-active" }, [
                  _c("section", { staticClass: "content-tlist" }, [
                    _c("div", { staticStyle: { padding: "30px" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-around"
                          }
                        },
                        [
                          _c("div", { staticStyle: { width: "70%" } }, [
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#51aeff",
                                  "font-weight": "bold"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.processFlag = false
                                  }
                                }
                              },
                              [_vm._v("<< Back")]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { width: "25%" } }, [
                            _c("h2", { staticClass: "detail-header" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.transactionTypeText[
                                    _vm.processItem.transactionType
                                  ]
                                ) +
                                  " | " +
                                  _vm._s(_vm.processItem.transactionId) +
                                  " | " +
                                  _vm._s(_vm.processItem.status)
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "h2",
                              {
                                staticClass: "detail-header",
                                staticStyle: { "margin-bottom": "15px" }
                              },
                              [_vm._v(_vm._s(_vm.processItem.accountId))]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-around"
                          }
                        },
                        [
                          _c("div", { staticStyle: { width: "70%" } }, [
                            _c("h2", { staticClass: "balance" }, [
                              _vm._v("Bank")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mnb-form" }, [
                              _c(
                                "div",
                                { staticClass: "mnb-row" },
                                [
                                  _c("form-field-copy-mnb", {
                                    attrs: {
                                      disabled: !_vm.processItem.bankName,
                                      label: "Name"
                                    },
                                    model: {
                                      value: _vm.processItem.bankName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.processItem,
                                          "bankName",
                                          $$v
                                        )
                                      },
                                      expression: "processItem.bankName"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("form-field-copy-mnb", {
                                    attrs: {
                                      disabled: !_vm.processItem.routingNumber,
                                      label: "Routing Number"
                                    },
                                    model: {
                                      value: _vm.processItem.routingNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.processItem,
                                          "routingNumber",
                                          $$v
                                        )
                                      },
                                      expression: "processItem.routingNumber"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("form-field-copy-mnb", {
                                    attrs: {
                                      disabled: !_vm.processItem.swift,
                                      label: "Swift Code"
                                    },
                                    model: {
                                      value: _vm.processItem.swift,
                                      callback: function($$v) {
                                        _vm.$set(_vm.processItem, "swift", $$v)
                                      },
                                      expression: "processItem.swift"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mnb-row" },
                                [
                                  _vm.processItem.bankAddress
                                    ? _c("form-field-copy-mnb", {
                                        attrs: {
                                          disabled: !_vm.processItem.bankAddress
                                            .address,
                                          label: "Address"
                                        },
                                        model: {
                                          value:
                                            _vm.processItem.bankAddress.address,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.processItem.bankAddress,
                                              "address",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "processItem.bankAddress.address"
                                        }
                                      })
                                    : _c("form-field-copy-mnb", {
                                        attrs: {
                                          disabled: !_vm.processItem
                                            .bankAddress,
                                          label: "Address"
                                        }
                                      }),
                                  _vm._v(" "),
                                  _c("form-field-copy-mnb", {
                                    attrs: {
                                      disabled: true,
                                      label: "Address 2"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mnb-row" },
                                [
                                  _vm.processItem.bankAddress
                                    ? _c("form-field-copy-mnb", {
                                        attrs: {
                                          disabled: !_vm.processItem.bankAddress
                                            .city,
                                          label: "City"
                                        },
                                        model: {
                                          value:
                                            _vm.processItem.bankAddress.city,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.processItem.bankAddress,
                                              "city",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "processItem.bankAddress.city"
                                        }
                                      })
                                    : _c("form-field-copy-mnb", {
                                        attrs: {
                                          disabled: !_vm.processItem
                                            .bankAddress,
                                          label: "City"
                                        }
                                      }),
                                  _vm._v(" "),
                                  _vm.processItem.bankAddress
                                    ? _c("form-field-copy-mnb", {
                                        attrs: {
                                          disabled: !_vm.processItem.bankAddress
                                            .state,
                                          label: "Province/State"
                                        },
                                        model: {
                                          value:
                                            _vm.processItem.bankAddress.state,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.processItem.bankAddress,
                                              "state",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "processItem.bankAddress.state"
                                        }
                                      })
                                    : _c("form-field-copy-mnb", {
                                        attrs: {
                                          disabled: !_vm.processItem
                                            .bankAddress,
                                          label: "Province/State"
                                        }
                                      }),
                                  _vm._v(" "),
                                  _vm.processItem.bankAddress
                                    ? _c("form-field-copy-mnb", {
                                        attrs: {
                                          disabled: !_vm.processItem.bankAddress
                                            .country,
                                          label: "Country"
                                        },
                                        model: {
                                          value:
                                            _vm.processItem.bankAddress.country,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.processItem.bankAddress,
                                              "country",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "processItem.bankAddress.country"
                                        }
                                      })
                                    : _c("form-field-copy-mnb", {
                                        attrs: {
                                          disabled: !_vm.processItem
                                            .bankAddress,
                                          label: "Country"
                                        }
                                      }),
                                  _vm._v(" "),
                                  _vm.processItem.bankAddress
                                    ? _c("form-field-copy-mnb", {
                                        attrs: {
                                          disabled: !_vm.processItem.bankAddress
                                            .postal,
                                          label: "Postal/Zip Code"
                                        },
                                        model: {
                                          value:
                                            _vm.processItem.bankAddress.postal,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.processItem.bankAddress,
                                              "postal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "processItem.bankAddress.postal"
                                        }
                                      })
                                    : _c("form-field-copy-mnb", {
                                        attrs: {
                                          disabled: !_vm.processItem
                                            .bankAddress,
                                          label: "Postal/Zip Code"
                                        }
                                      })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("h2", { staticClass: "balance" }, [
                              _vm._v("Intermediary Bank")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mnb-form" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mnb-row" },
                                  [
                                    _c("form-field-copy-mnb", {
                                      attrs: {
                                        disabled: !_vm.processItem
                                          .intermediaryBankName,
                                        label: "Name"
                                      },
                                      model: {
                                        value:
                                          _vm.processItem.intermediaryBankName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.processItem,
                                            "intermediaryBankName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "processItem.intermediaryBankName"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("form-field-copy-mnb", {
                                      attrs: {
                                        disabled: !_vm.processItem
                                          .intermediaryBankRoutingNumber,
                                        label: "Routing Number"
                                      },
                                      model: {
                                        value:
                                          _vm.processItem
                                            .intermediaryBankRoutingNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.processItem,
                                            "intermediaryBankRoutingNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "processItem.intermediaryBankRoutingNumber"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("form-field-copy-mnb", {
                                      attrs: {
                                        disabled: !_vm.processItem
                                          .intermediaryBankSwiftCode,
                                        label: "Swift Code"
                                      },
                                      model: {
                                        value:
                                          _vm.processItem
                                            .intermediaryBankSwiftCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.processItem,
                                            "intermediaryBankSwiftCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "processItem.intermediaryBankSwiftCode"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.processItem.intermediaryAddress
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "mnb-row" },
                                        [
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress.address,
                                              label: "Address"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem
                                                  .intermediaryAddress.address,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem
                                                    .intermediaryAddress,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.intermediaryAddress.address"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress.address2,
                                              label: "Address 2"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "mnb-row" },
                                        [
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress.city,
                                              label: "City"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem
                                                  .intermediaryAddress.city,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem
                                                    .intermediaryAddress,
                                                  "city",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.intermediaryAddress.city"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress.state,
                                              label: "Province/State"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem
                                                  .intermediaryAddress.state,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem
                                                    .intermediaryAddress,
                                                  "state",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.intermediaryAddress.state"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress.country,
                                              label: "Country"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem
                                                  .intermediaryAddress.country,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem
                                                    .intermediaryAddress,
                                                  "country",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.intermediaryAddress.country"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress.postal,
                                              label: "Postal/Zip Code"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem
                                                  .intermediaryAddress.postal,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem
                                                    .intermediaryAddress,
                                                  "postal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.intermediaryAddress.postal"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        { staticClass: "mnb-row" },
                                        [
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress,
                                              label: "Address"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress,
                                              label: "Address 2"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "mnb-row" },
                                        [
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress,
                                              label: "City"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress,
                                              label: "Province/State"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress,
                                              label: "Country"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .intermediaryAddress,
                                              label: "Postal/Zip Code"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("h2", { staticClass: "balance" }, [
                              _vm._v("Beneficiary")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mnb-form" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mnb-row" },
                                  [
                                    _c("form-field-copy-mnb", {
                                      attrs: {
                                        disabled: !_vm.processItem.accountName,
                                        label: "Account Name"
                                      },
                                      model: {
                                        value: _vm.processItem.accountName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.processItem,
                                            "accountName",
                                            $$v
                                          )
                                        },
                                        expression: "processItem.accountName"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("form-field-copy-mnb", {
                                      attrs: {
                                        disabled: !_vm.processItem
                                          .accountNumber,
                                        label: "Account Number"
                                      },
                                      model: {
                                        value: _vm.processItem.accountNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.processItem,
                                            "accountNumber",
                                            $$v
                                          )
                                        },
                                        expression: "processItem.accountNumber"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("form-field-copy-mnb", {
                                      attrs: {
                                        disabled: !_vm.processItem.accountType,
                                        label: "Account Type"
                                      },
                                      model: {
                                        value: _vm.processItem.accountType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.processItem,
                                            "accountType",
                                            $$v
                                          )
                                        },
                                        expression: "processItem.accountType"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.processItem.address
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "mnb-row" },
                                        [
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem.address
                                                .address,
                                              label: "Address"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem.address.address,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem.address,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.address.address"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem.address
                                                .address2,
                                              label: "Address 2"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem.address
                                                  .address2,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem.address,
                                                  "address2",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.address.address2"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "mnb-row" },
                                        [
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem.address
                                                .city,
                                              label: "City"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem.address.city,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem.address,
                                                  "city",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.address.city"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem.address
                                                .state,
                                              label: "Province/State"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem.address.state,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem.address,
                                                  "state",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.address.state"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem.address
                                                .country,
                                              label: "Country"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem.address.country,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem.address,
                                                  "country",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.address.country"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem.address
                                                .postal,
                                              label: "Postal/Zip Code"
                                            },
                                            model: {
                                              value:
                                                _vm.processItem.address.postal,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.processItem.address,
                                                  "postal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "processItem.address.postal"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        { staticClass: "mnb-row" },
                                        [
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .address,
                                              label: "Address"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .address,
                                              label: "Address 2"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "mnb-row" },
                                        [
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .address,
                                              label: "City"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .address,
                                              label: "Province/State"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .address,
                                              label: "Country"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-copy-mnb", {
                                            attrs: {
                                              disabled: !_vm.processItem
                                                .address,
                                              label: "Postal/Zip Code"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("h2", { staticClass: "balance" }, [
                              _vm._v("Additional Information")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mnb-form" }, [
                              _c(
                                "div",
                                { staticClass: "mnb-row" },
                                [
                                  _c("form-field-copy-mnb", {
                                    attrs: { label: "Reference" },
                                    model: {
                                      value: _vm.processItem.accountName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.processItem,
                                          "accountName",
                                          $$v
                                        )
                                      },
                                      expression: "processItem.accountName"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("form-field-copy-mnb", {
                                    attrs: { label: "Memo" },
                                    model: {
                                      value: _vm.processItem.accountNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.processItem,
                                          "accountNumber",
                                          $$v
                                        )
                                      },
                                      expression: "processItem.accountNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { width: "25%" } },
                            [
                              _c("h2", { staticClass: "balance" }, [
                                _vm._v("Options")
                              ]),
                              _vm._v(" "),
                              _vm.$hasPerm("funds_processing")
                                ? [
                                    _vm.processItem.status == "Pending"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-success",
                                            on: {
                                              click: function($event) {
                                                ;(_vm.settleModal = true),
                                                  (_vm.form = {})
                                              }
                                            }
                                          },
                                          [_vm._v("Settle")]
                                        )
                                      : _vm._e(),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.processItem.status == "Complete"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-success",
                                            on: {
                                              click: function($event) {
                                                ;(_vm.reversalModal = true),
                                                  (_vm.form = {})
                                              }
                                            }
                                          },
                                          [_vm._v("Reversal")]
                                        )
                                      : _vm._e(),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn",
                                        on: {
                                          click: function($event) {
                                            return _vm.cancelProcessTransaction(
                                              _vm.processItem.id
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Cancel")]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "popup",
            {
              attrs: { open: _vm.addConfirmModal, width: 400 },
              on: {
                "update:open": function($event) {
                  _vm.addConfirmModal = $event
                }
              }
            },
            [
              _c("div", { staticClass: "popup-header" }, [
                _c("h3", [_vm._v("Confirmation")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "scrollable" }, [
                _c("div", { staticClass: "content-form" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _vm.confirmSuccess
                      ? _c("p", { staticClass: "transaction-status" }, [
                          _vm._v("Transaction Cancelled")
                        ])
                      : _c("p", { staticClass: "transaction-status" }, [
                          _vm._v("Error: Unable to Cancel Transaction")
                        ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        _vm.addConfirmModal = false
                      }
                    }
                  },
                  [_vm._v("Close")]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "popup",
            {
              attrs: { open: _vm.addApproveModal, width: 400 },
              on: {
                "update:open": function($event) {
                  _vm.addApproveModal = $event
                }
              }
            },
            [
              _c("div", { staticClass: "popup-header" }, [
                _c("h3", [_vm._v("Confirmation")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "scrollable" }, [
                _c("div", { staticClass: "content-form" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _vm.approveSuccess
                      ? _c("p", { staticClass: "transaction-status" }, [
                          _vm._v("Transaction Approved")
                        ])
                      : _c("p", { staticClass: "transaction-status" }, [
                          _vm._v("Error: Unable to Approve Transaction")
                        ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        _vm.addApproveModal = false
                      }
                    }
                  },
                  [_vm._v("Close")]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.openedFilters
            ? _c("transactions-filters", {
                attrs: { filters: _vm.filters },
                on: {
                  apply: function($event) {
                    return _vm.applyFilters($event)
                  },
                  close: function($event) {
                    _vm.openedFilters = false
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType
            ? _c("add-deposit-panel", {
                attrs: { "modal-type": _vm.modalType },
                on: {
                  close: function($event) {
                    _vm.modalType = null
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.processItem
            ? _c(
                "popup",
                {
                  attrs: { open: _vm.settleModal },
                  on: {
                    "update:open": function($event) {
                      _vm.settleModal = $event
                    }
                  }
                },
                [
                  _c("div", { staticClass: "popup-header" }, [
                    _c("h3", [
                      _vm._v(
                        "Settle Transaction " +
                          _vm._s(_vm.processItem.transactionId)
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "scrollable" }, [
                    _c("div", { staticClass: "mnb-form" }, [
                      _c("div", { staticClass: "form-row form-fullw" }, [
                        _c("div", { staticClass: "cof-field mnb-field" }, [
                          _c("label", [_vm._v("Settled Date")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "cof-multi flex" },
                            [
                              _c("date-time-picker", {
                                attrs: {
                                  "value-type": "String",
                                  "value-format": "yyyy-LL-dd",
                                  format: "yyyy-LL-dd",
                                  "time-picker": false,
                                  "auto-close": true,
                                  "max-date": _vm.today
                                },
                                model: {
                                  value: _vm.form.settledDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "settledDate", $$v)
                                  },
                                  expression: "form.settledDate"
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.form.settledDate.$dirty &&
                              _vm.$v.form.settledDate.$invalid
                                ? _c("div", { staticClass: "error-message" }, [
                                    _c("span", [
                                      _vm._v("Please enter settled date.")
                                    ])
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw mnb-field" },
                        [
                          _c("form-field-textarea", {
                            attrs: {
                              label: "Settlement Details",
                              rows: 3,
                              maxlength: 750,
                              inputClass: "mnb-input"
                            },
                            model: {
                              value: _vm.form.details,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "details", $$v)
                              },
                              expression: "form.details"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw mnb-field" },
                        [
                          _c("form-field-textarea", {
                            attrs: {
                              label: "Special Instructions",
                              rows: 3,
                              maxlength: 750,
                              inputClass: "mnb-input"
                            },
                            model: {
                              value: _vm.form.instructions,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "instructions", $$v)
                              },
                              expression: "form.instructions"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.settleTransaction()
                            }
                          }
                        },
                        [_vm._v("Settle")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.settleModal = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _vm.submitError
                        ? _c("label", { staticClass: "error-message" }, [
                            _vm._v("Please check error(s) and try again.")
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.processItem
            ? _c(
                "popup",
                {
                  attrs: { open: _vm.reversalModal },
                  on: {
                    "update:open": function($event) {
                      _vm.reversalModal = $event
                    }
                  }
                },
                [
                  _c("div", { staticClass: "popup-header" }, [
                    _c("h3", [
                      _vm._v(
                        "Reverse Transaction " +
                          _vm._s(_vm.processItem.transactionId)
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "scrollable" }, [
                    _c("div", { staticClass: "mnb-form" }, [
                      _c("div", { staticClass: "form-row form-fullw" }, [
                        _c("div", { staticClass: "cof-field mnb-field" }, [
                          _c("label", [_vm._v("Reversal Date")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "cof-multi flex" },
                            [
                              _c("date-time-picker", {
                                attrs: {
                                  "value-type": "String",
                                  "value-format": "yyyy-LL-dd",
                                  format: "yyyy-LL-dd",
                                  "time-picker": false,
                                  "auto-close": true,
                                  "max-date": _vm.today
                                },
                                model: {
                                  value: _vm.form.settledDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "settledDate", $$v)
                                  },
                                  expression: "form.settledDate"
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.form.settledDate.$dirty &&
                              _vm.$v.form.settledDate.$invalid
                                ? _c("div", { staticClass: "error-message" }, [
                                    _c("span", [
                                      _vm._v("Please enter settled date.")
                                    ])
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw mnb-field" },
                        [
                          _c("form-field-textarea", {
                            attrs: {
                              label: "Reversal Details",
                              validator: _vm.$v.form.details,
                              rows: 3,
                              maxlength: 750,
                              inputClass: "mnb-input"
                            },
                            model: {
                              value: _vm.form.details,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "details", $$v)
                              },
                              expression: "form.details"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.reversalTransaction()
                            }
                          }
                        },
                        [_vm._v("Reversal")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.reversalModal = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _vm.submitError
                        ? _c("label", { staticClass: "error-message" }, [
                            _vm._v("Please check error(s) and try again.")
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "popup",
            {
              attrs: { open: _vm.downloadModal },
              on: {
                "update:open": function($event) {
                  _vm.downloadModal = $event
                }
              }
            },
            [
              _c("div", { staticClass: "popup-header" }, [
                _c("h3", [_vm._v("Download")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "scrollable" }, [
                _c("h1", { staticClass: "text-center" }, [
                  _vm._v("Choose the Download Format")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "content-form text-center" }, [
                  _c(
                    "div",
                    { staticClass: "form-row form-split depositType" },
                    [
                      _c("form-field-select", {
                        attrs: {
                          value: "Excel",
                          label: "File Type",
                          options: [{ id: "Excel", text: "Excel" }],
                          required: ""
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.downloadExcel()
                        }
                      }
                    },
                    [_vm._v("Download")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.downloadModal = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./Reporting.vue?vue&type=template&id=05f911f8&"
import script from "./Reporting.vue?vue&type=script&lang=js&"
export * from "./Reporting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6monbi/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05f911f8')) {
      api.createRecord('05f911f8', component.options)
    } else {
      api.reload('05f911f8', component.options)
    }
    module.hot.accept("./Reporting.vue?vue&type=template&id=05f911f8&", function () {
      api.rerender('05f911f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/monbi/transactions/Reporting.vue"
export default component.exports
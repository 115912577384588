var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.partner
    ? _c(
        "div",
        { staticClass: "cp-box" },
        [
          _c("ul", { staticClass: "sub-tabs" }, [
            _c(
              "li",
              {
                class: { active: _vm.activeTab === "tracking" },
                on: {
                  click: function($event) {
                    _vm.activeTab = "tracking"
                  }
                }
              },
              [_c("a", [_vm._v("Tracking")])]
            )
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.activeTab === "tracking"
            ? _c("tools-tracking", { attrs: { partner: _vm.partner } })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
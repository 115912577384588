var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "content-form content-form-modern" },
        [
          _c("h3", { staticClass: "subtitle" }, [
            _vm._v("BANK & BENEFICIARY INFORMATION")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-row form-four routing-number-validate" },
            [
              _c(
                "div",
                [
                  _c("form-field-text", {
                    attrs: {
                      label: "Bank SWIFT Code",
                      validator: _vm.v.swift,
                      required: "",
                      disabled: _vm.disabledField
                    },
                    model: {
                      value: _vm.form.swift,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "swift", $$v)
                      },
                      expression: "form.swift"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success show-btn",
                      attrs: { disabled: !_vm.showSwiftBtn },
                      on: {
                        click: function($event) {
                          return _vm.validateSwift()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-search" }),
                      _vm._v(" Validate")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("form-field-select", {
                attrs: {
                  label: "Account Type",
                  validator: _vm.v.accountType,
                  options: _vm.accountOptions,
                  required: "",
                  disabled: _vm.disabledField
                },
                model: {
                  value: _vm.form.accountType,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "accountType", $$v)
                  },
                  expression: "form.accountType"
                }
              }),
              _vm._v(" "),
              _c("form-field-text", {
                attrs: {
                  label: "Account Name",
                  validator: _vm.v.accountName,
                  required: "",
                  disabled: _vm.disabledField
                },
                model: {
                  value: _vm.form.accountName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "accountName", $$v)
                  },
                  expression: "form.accountName"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("form-field-text", {
                    attrs: {
                      label: "Account Number / IBAN",
                      validator: _vm.v.accountNumber,
                      required: "",
                      disabled: _vm.disabledField
                    },
                    model: {
                      value: _vm.form.accountNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "accountNumber", $$v)
                      },
                      expression: "form.accountNumber"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "account-number-alert" }, [
                    _vm._v(
                      "Numbers and letters are accepted for Account Number / IBAN"
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "bank-name" },
            [
              _vm.bankSwiftInfo
                ? [_vm._v(_vm._s(_vm.bankSwiftInfo["bankName"]) + ",")]
                : _vm._e(),
              _vm._v(" "),
              _vm.bankSwiftAddress
                ? [
                    _vm._v(
                      _vm._s(_vm.bankSwiftAddress["line1"]) +
                        ",  " +
                        _vm._s(_vm.bankSwiftAddress["line2"])
                    )
                  ]
                : _vm._e(),
              _vm._v(", "),
              _vm.bankSwiftAddress
                ? [
                    _vm._v(
                      _vm._s(_vm.bankSwiftAddress["city"]) +
                        ", " +
                        _vm._s(_vm.bankSwiftAddress["state"]) +
                        ", " +
                        _vm._s(_vm.bankSwiftAddress["postalCode"]) +
                        ", " +
                        _vm._s(_vm.bankSwiftAddress["country"])
                    )
                  ]
                : _vm._e(),
              _vm._v(" ")
            ],
            2
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "use_intermediary_bank_div" }, [
            _c("h3", { staticClass: "subtitle" }, [
              _vm._v("INTERMEDIARY BANK INFORMATION")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "center-checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.useIntermediaryBank,
                    expression: "form.useIntermediaryBank"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  id: "use_intermediary_bank",
                  disabled: "",
                  disabled: _vm.disabledField
                },
                domProps: {
                  checked: Array.isArray(_vm.form.useIntermediaryBank)
                    ? _vm._i(_vm.form.useIntermediaryBank, null) > -1
                    : _vm.form.useIntermediaryBank
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.form.useIntermediaryBank,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.form,
                            "useIntermediaryBank",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.form,
                            "useIntermediaryBank",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.form, "useIntermediaryBank", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(0)
            ])
          ]),
          _vm._v(" "),
          _vm.form.useIntermediaryBank
            ? [
                _c(
                  "div",
                  { staticClass: "form-row form-split" },
                  [
                    _c("form-field-select", {
                      attrs: {
                        label: "Intermediary Bank Identifier",
                        validator: _vm.v.intermediaryBankIdentifier,
                        options: _vm.intermediaryBankIdentifierOptions,
                        required: "",
                        disabled: _vm.disabledField
                      },
                      model: {
                        value: _vm.form.intermediaryBankIdentifier,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "intermediaryBankIdentifier", $$v)
                        },
                        expression: "form.intermediaryBankIdentifier"
                      }
                    }),
                    _vm._v(" "),
                    _vm.form.intermediaryBankIdentifier == "routing"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "form-row form-split routing-number-validate"
                          },
                          [
                            _c("form-field-text", {
                              attrs: {
                                label: "Intermediary Bank Routing Number",
                                validator: _vm.v.intermediaryBankRoutingNumber,
                                required: "",
                                disabled: _vm.disabledField
                              },
                              model: {
                                value: _vm.form.intermediaryBankRoutingNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "intermediaryBankRoutingNumber",
                                    $$v
                                  )
                                },
                                expression: "form.intermediaryBankRoutingNumber"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success show-btn",
                                attrs: { disabled: !_vm.showBtn },
                                on: {
                                  click: function($event) {
                                    return _vm.validateRoutingNumber()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "el-icon-search" }),
                                _vm._v(" Validate")
                              ]
                            )
                          ],
                          1
                        )
                      : _vm.form.intermediaryBankIdentifier == "swift"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "form-row form-split routing-number-validate"
                          },
                          [
                            _c("form-field-text", {
                              attrs: {
                                label: "Intermediary Bank SWIFT Code",
                                validator: _vm.v.intermediaryBankSwiftCode,
                                required: "",
                                disabled: _vm.disabledField
                              },
                              model: {
                                value: _vm.form.intermediaryBankSwiftCode,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "intermediaryBankSwiftCode",
                                    $$v
                                  )
                                },
                                expression: "form.intermediaryBankSwiftCode"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success show-btn",
                                attrs: { disabled: !_vm.showIntSwiftBtn },
                                on: {
                                  click: function($event) {
                                    return _vm.validateIntSwift()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "el-icon-search" }),
                                _vm._v(" Validate")
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.form.intermediaryBankIdentifier == "routing"
                  ? [
                      _c(
                        "p",
                        { staticClass: "bank-name" },
                        [
                          _vm.bankInfo
                            ? [
                                _vm._v(
                                  _vm._s(_vm.bankInfo.name) +
                                    ", " +
                                    _vm._s(_vm.bankInfo.phone) +
                                    ", " +
                                    _vm._s(_vm.bankInfo.street) +
                                    ", " +
                                    _vm._s(_vm.bankInfo.city) +
                                    ", " +
                                    _vm._s(_vm.bankInfo.state) +
                                    ", " +
                                    _vm._s(_vm.bankInfo.zip)
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      !_vm.bankInfo
                        ? _c("p", { staticClass: "error-message" }, [
                            _vm._v("No Address Provided")
                          ])
                        : _vm._e()
                    ]
                  : _vm.form.intermediaryBankIdentifier == "swift"
                  ? [
                      _c(
                        "p",
                        { staticClass: "bank-name" },
                        [
                          _vm.bankIntSwiftInfo
                            ? [
                                _vm._v(
                                  _vm._s(_vm.bankIntSwiftInfo["bankName"]) + ","
                                )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.bankIntSwiftAddress
                            ? [
                                _vm._v(
                                  _vm._s(_vm.bankIntSwiftAddress["line1"]) +
                                    ", " +
                                    _vm._s(_vm.bankIntSwiftAddress["line2"]) +
                                    ", " +
                                    _vm._s(_vm.bankIntSwiftAddress["city"]) +
                                    ", " +
                                    _vm._s(_vm.bankIntSwiftAddress["state"]) +
                                    ", " +
                                    _vm._s(
                                      _vm.bankIntSwiftAddress["postalCode"]
                                    ) +
                                    ", " +
                                    _vm._s(_vm.bankIntSwiftAddress["country"])
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("h3", { staticClass: "subtitle" }, [
            _vm._v("BENEFICIARY ADDRESS")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row form-split" }),
          _vm._v(" "),
          _c("address-dom-form", {
            attrs: {
              v: _vm.v.address,
              disabledField: _vm.disabledField,
              transactionCountries: _vm.transactionCountries
            },
            model: {
              value: _vm.form.address,
              callback: function($$v) {
                _vm.$set(_vm.form, "address", $$v)
              },
              expression: "form.address"
            }
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.showConfirm },
          on: {
            "update:open": function($event) {
              _vm.showConfirm = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Intermediary Bank Confirmation")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scrollable" }, [
            _c(
              "div",
              { staticClass: "content-form bank-info" },
              [
                _vm.bankError
                  ? _c("p", [
                      _vm._v(
                        "Error searching Banks. " + _vm._s(_vm.bankErrorMsg)
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.notFound
                  ? _c("p", [
                      _vm._v(
                        "Intermediary Bank not found. Please check information and try again."
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.bankInfo
                  ? [
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Routing Number: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankInfo.routingNumber) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Name: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankInfo.name) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Address: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankInfo.street) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Address 2: ")])]),
                        _vm._v(" "),
                        _vm.bankAddress ? _c("label") : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("City: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankInfo.city) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("State/Province: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankInfo.state) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Postal/Zip: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankInfo.zip) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Country: ")])]),
                        _vm._v(" "),
                        _c("label")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Phone: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankInfo.phone) +
                              "\n            "
                          )
                        ])
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.notFound || _vm.bankError
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showConfirm = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ]
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.confirmIntermediary()
                            }
                          }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showConfirm = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ]
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.showSwiftConfirm },
          on: {
            "update:open": function($event) {
              _vm.showSwiftConfirm = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Bank Swift Confirmation")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scrollable" }, [
            _c(
              "div",
              { staticClass: "content-form bank-info" },
              [
                _vm.bankError
                  ? _c("p", [
                      _vm._v(
                        "Error searching Banks. " + _vm._s(_vm.bankErrorMsg)
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.notFound
                  ? _c("p", [
                      _vm._v(
                        "Intermediary Bank not found. Please check information and try again."
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.bankSwiftInfo
                  ? [
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [
                          _c("b", [_vm._v("Bank Routing Number: ")])
                        ]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankSwiftInfo["routingNumber"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank Name: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankSwiftInfo["bankName"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank Address: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankSwiftAddress["line1"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank Address 2: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankSwiftAddress["line2"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank City: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankSwiftAddress["city"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [
                          _c("b", [_vm._v("Bank State/Province: ")])
                        ]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankSwiftAddress["state"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank Country: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankSwiftAddress["country"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank Phone: ")])]),
                        _vm._v(" "),
                        _c("label")
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.notFound || _vm.bankError
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showSwiftConfirm = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ]
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.confirmSwiftIntermediary()
                            }
                          }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showSwiftConfirm = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ]
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.showIntSwiftConfirm },
          on: {
            "update:open": function($event) {
              _vm.showIntSwiftConfirm = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Bank Swift Confirmation")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scrollable" }, [
            _c(
              "div",
              { staticClass: "content-form bank-info" },
              [
                _vm.bankError
                  ? _c("p", [
                      _vm._v(
                        "Error searching Banks. " + _vm._s(_vm.bankErrorMsg)
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.notFound
                  ? _c("p", [
                      _vm._v(
                        "Intermediary Bank not found. Please check information and try again."
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.bankIntSwiftInfo
                  ? [
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [
                          _c("b", [_vm._v("Bank Routing Number: ")])
                        ]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankIntSwiftInfo["routingNumber"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank Name: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankIntSwiftInfo["bankName"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank Address: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankIntSwiftAddress["line1"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank Address 2: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankIntSwiftAddress["line2"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank City: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankIntSwiftAddress["city"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [
                          _c("b", [_vm._v("Bank State/Province: ")])
                        ]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankIntSwiftAddress["state"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank Country: ")])]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.bankIntSwiftAddress["country"]) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-split" }, [
                        _c("label", [_c("b", [_vm._v("Bank Phone: ")])]),
                        _vm._v(" "),
                        _c("label")
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.notFound || _vm.bankError
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showIntSwiftConfirm = false
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ]
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.confirmIntSwiftIntermediary()
                            }
                          }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              _vm.showIntSwiftConfirm = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ]
              ],
              2
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "use_intermediary_bank" } }, [
      _vm._v("Use Intermediary Bank "),
      _c("span", { staticClass: "error-message" }, [_vm._v("*Required")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
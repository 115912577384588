var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.open
        ? [
            _c("section", [
              _c("div", { staticClass: "flex-between" }, [
                _c("div", { staticStyle: { "margin-top": "15px" } }, [
                  _vm.featureAdvanceDepositNoticeStatus &&
                  _vm.$hasPerm("corporate-deposit")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          on: {
                            click: function($event) {
                              return _vm.openTransModal("deposit")
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _vm._v(" Deposit")
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.featureRemoteDeposit
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          on: {
                            click: function($event) {
                              return _vm.openRemoteDeposit()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _vm._v(" Remote Deposit")
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.openedFilters = true
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-operation" }),
                      _vm._v(" Filters")
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("applied-filters", {
              attrs: { filters: _vm.appliedFilters },
              on: {
                clear: function($event) {
                  return _vm.clearFilters()
                },
                remove: function($event) {
                  return _vm.removeFilter($event)
                }
              }
            }),
            _vm._v(" "),
            _c("section"),
            _vm._v(" "),
            _vm.modalType
              ? _c("add-deposit-withdrawal-panel", {
                  attrs: { "modal-type": _vm.modalType },
                  on: {
                    close: function($event) {
                      _vm.modalType = null
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.modalPaymentType
              ? _c("payment-details-panel", {
                  attrs: { "modal-payment-type": _vm.modalPaymentType },
                  on: {
                    close: function($event) {
                      _vm.modalPaymentType = null
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.modalBankType
              ? _c("bank-lookup-panel", {
                  attrs: { "modal-bank-type": _vm.modalBankType },
                  on: {
                    close: function($event) {
                      _vm.modalBankType = null
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.modalDepositType
              ? _c("add-remote-deposit-modal", {
                  attrs: { "modal-type": _vm.modalDepositType },
                  on: {
                    close: function($event) {
                      _vm.modalDepositType = null
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.$hasPerm("update-transaction")
              ? _c("section", [
                  _vm.currentUser.role.group.id == 1
                    ? _c("div", { staticClass: "text-center" }, [
                        _vm.systemMaintenanceStatus
                          ? _c(
                              "button",
                              {
                                staticClass: "main forcebtt",
                                on: {
                                  click: function($event) {
                                    return _vm.updateTrasactions()
                                  }
                                }
                              },
                              [_vm._v("Update Transactions")]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.modalType && !_vm.modalPaymentType && !_vm.modalBankType
              ? _c("div", { staticClass: "accordion" }, [
                  _c("div", { class: { "cof-box-active": _vm.activeInfo } }, [
                    !_vm.loading
                      ? _c(
                          "section",
                          { staticClass: "content-tlist" },
                          [
                            _c(
                              "el-table",
                              {
                                ref: "transactionsTable",
                                staticClass: "content-table",
                                attrs: { data: _vm.filteredData },
                                on: { "row-click": _vm.transactionsTableClick }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { type: "expand" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm._l([[props.row]], function(
                                              selectedTransaction
                                            ) {
                                              return _c(
                                                "div",
                                                { staticClass: "popup-header" },
                                                [
                                                  _c("h3", [
                                                    _vm._v("Transaction")
                                                  ]),
                                                  _vm._v(" "),
                                                  selectedTransaction
                                                    ? _c("h3", [
                                                        _vm._v(
                                                          _vm._s(
                                                            selectedTransaction[0]
                                                              .transactionId
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  selectedTransaction
                                                    ? _c("h3", [
                                                        _vm._v(
                                                          "Status: " +
                                                            _vm._s(
                                                              selectedTransaction[0]
                                                                .status
                                                            )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            }),
                                            _vm._v(" "),
                                            _vm._l([[props.row]], function(
                                              selectedTransaction
                                            ) {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "scrollable" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "information"
                                                      },
                                                      [
                                                        selectedTransaction
                                                          ? [
                                                              _c("div", [
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Account"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .accountId
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Created"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .createdAt
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Entry Type"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .entryType
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Currency Type"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .currencyType
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "p",
                                                                  [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Transaction Amount"
                                                                      )
                                                                    ]),
                                                                    _vm._v(
                                                                      ":\n                            "
                                                                    ),
                                                                    selectedTransaction[0]
                                                                      .currencyType ==
                                                                    "Assets"
                                                                      ? [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              selectedTransaction[0]
                                                                                .assetAmount
                                                                                ? parseFloat(
                                                                                    selectedTransaction[0]
                                                                                      .assetAmount
                                                                                  )
                                                                                : ""
                                                                            )
                                                                          )
                                                                        ]
                                                                      : [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "balance"
                                                                              )(
                                                                                selectedTransaction[0]
                                                                                  .amount
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                  ],
                                                                  2
                                                                )
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("div", [
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Profile"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .profileId
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Completed By"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .completedBy
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Transaction Type"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .transactionTypeText[
                                                                          selectedTransaction[0]
                                                                            .transactionType
                                                                        ]
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Currency Name"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ":" +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .currencyName
                                                                      ) +
                                                                      " - " +
                                                                      _vm._s(
                                                                        _vm.getCurrencyName(
                                                                          selectedTransaction[0]
                                                                            .currencyName
                                                                        )
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Transaction Fee"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": $" +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "formattedTwoDecimal"
                                                                        )(
                                                                          selectedTransaction[0]
                                                                            .transactionFee
                                                                        )
                                                                      )
                                                                  )
                                                                ])
                                                              ])
                                                            ]
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "popup-header details"
                                                      },
                                                      [
                                                        _c("h3", [
                                                          _vm._v("Details")
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    selectedTransaction
                                                      ? [
                                                          selectedTransaction[0]
                                                            .entryType ==
                                                            "Deposit" &&
                                                          selectedTransaction[0]
                                                            .transactionType ==
                                                            "Asset"
                                                            ? [
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getCurrencyName(
                                                                          selectedTransaction[0]
                                                                            .currencyName
                                                                        )
                                                                      ) +
                                                                        " Deposit Wallet Address"
                                                                    )
                                                                  ]),
                                                                  _vm._v(":")
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      selectedTransaction[0]
                                                                        .contributionIncomingWalletAddress
                                                                    )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(" ")
                                                                ])
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          selectedTransaction[0]
                                                            .entryType !=
                                                            "Deposit" &&
                                                          selectedTransaction[0]
                                                            .transactionType ==
                                                            "Asset"
                                                            ? [
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Pay to"
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        selectedTransaction[0]
                                                                          .label
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Pay to Wallet Address"
                                                                    )
                                                                  ]),
                                                                  _vm._v(": ")
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      selectedTransaction[0]
                                                                        .outgoingWalletAddress
                                                                    )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("p", [
                                                                  _vm._v(" ")
                                                                ])
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "information"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  selectedTransaction[0]
                                                                    .entryType ==
                                                                  "Deposit"
                                                                    ? [
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                        "checks"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Payer"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .remoteDepositPayor
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .remoteDepositPayor
                                                                                              .nameOfSender
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Address"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              selectedTransaction[0]
                                                                                .remoteDepositPayor
                                                                                ? [
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .remoteDepositPayor
                                                                                              .address
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .remoteDepositPayor
                                                                                              .city
                                                                                          ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .remoteDepositPayor
                                                                                                .state
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .remoteDepositPayor
                                                                                                .country
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .remoteDepositPayor
                                                                                                .postal
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Check Front"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "image-link",
                                                                                      attrs: {
                                                                                        href:
                                                                                          "/files/transactions/" +
                                                                                          selectedTransaction[0]
                                                                                            .id +
                                                                                          "/" +
                                                                                          selectedTransaction[0]
                                                                                            .checkImageFront,
                                                                                        target:
                                                                                          "_blank"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          selectedTransaction[0]
                                                                                            .checkImageFront
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Check Bank"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "image-link",
                                                                                      attrs: {
                                                                                        href:
                                                                                          "/files/transactions/" +
                                                                                          selectedTransaction[0]
                                                                                            .id +
                                                                                          "/" +
                                                                                          selectedTransaction[0]
                                                                                            .checkImageBack,
                                                                                        target:
                                                                                          "_blank"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          selectedTransaction[0]
                                                                                            .checkImageBack
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "Asset"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .transactionReferenceCode
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Note"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": Send only " +
                                                                                      _vm._s(
                                                                                        _vm.getCurrencyName(
                                                                                          selectedTransaction[0]
                                                                                            .currencyName
                                                                                        )
                                                                                      ) +
                                                                                      " to this wallet address. Any other asset type sent may be lost and unrecoverable."
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "acc_to_acc"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": Account Transfer"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "DebitCard"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "MERCHANT"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantName
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantCity
                                                                                    ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .merchantState
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .merchantCountry
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .postalCode
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantCategory
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "System"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .transactionReferenceCode
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                      ]
                                                                    : [
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                        "international_wires"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Bank Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .bankName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Swift"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .swift
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "INTERMEDIARY BANK"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Bank Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryBankName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Routing"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryBankRoutingNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "SWIFT"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryBankSwiftCode
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryBankReference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Address"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .intermediaryAddress
                                                                                        .address
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .intermediaryAddress
                                                                                        .city
                                                                                    ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryAddress
                                                                                          .state
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryAddress
                                                                                          .country
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .intermediaryAddress
                                                                                          .postal
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "BENEFICIARY"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Type"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountType
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Address"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              selectedTransaction[0]
                                                                                .address
                                                                                ? [
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .address
                                                                                              .address
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .city
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .state
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .country
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .postal
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Further Credit Account Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .futherCreditAccName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Further Credit Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .futherCreditAccNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "checks"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Payee"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .payee
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Address"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              selectedTransaction[0]
                                                                                .address
                                                                                ? [
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .address
                                                                                              .address
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .address
                                                                                              .city
                                                                                          ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .state
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .country
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .postal
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "ach"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Bank Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .bankName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Routing"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .routingNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "BENEFICIARY"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Type"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountType
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "domestic_wire"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Bank Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .bankName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Routing Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .routingNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "BENEFICIARY"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Type"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountType
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Address"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ":"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              selectedTransaction[0]
                                                                                .address
                                                                                ? [
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .address
                                                                                              .address
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .address
                                                                                              .city
                                                                                          ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .state
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .country
                                                                                            ) +
                                                                                            ", " +
                                                                                            _vm._s(
                                                                                              selectedTransaction[0]
                                                                                                .address
                                                                                                .postal
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Further Credit Account Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .futherCreditAccName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Further Credit Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .futherCreditAccNumber
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "Asset"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "acc_to_acc"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Name"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .accountName
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": ****" +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .toAccountId
                                                                                          ? selectedTransaction[0].toAccountId.substr(
                                                                                              4,
                                                                                              3
                                                                                            )
                                                                                          : ""
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "Trade"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "System"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : selectedTransaction[0]
                                                                              .transactionType ==
                                                                            "DebitCard"
                                                                          ? [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "MERCHANT"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantName
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantCity
                                                                                    ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .merchantState
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .merchantCountry
                                                                                      ) +
                                                                                      ", " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .merchant
                                                                                          .merchant
                                                                                          .postalCode
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      selectedTransaction[0]
                                                                                        .merchant
                                                                                        .merchant
                                                                                        .merchantCategory
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          : [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Account Number"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": ****" +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .toAccountId
                                                                                          ? selectedTransaction[0].toAccountId.substr(
                                                                                              4,
                                                                                              3
                                                                                            )
                                                                                          : ""
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Memo"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .memo
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reference"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": " +
                                                                                      _vm._s(
                                                                                        selectedTransaction[0]
                                                                                          .reference
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                      ]
                                                                ],
                                                                2
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                [
                                                                  selectedTransaction[0]
                                                                    .entryType ==
                                                                  "Deposit"
                                                                    ? [
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "domestic_wire" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "international_wires" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "ach" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "Asset" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "acc_to_acc" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "Trade" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "domesticWire" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "internationalWire"
                                                                          ? void 0
                                                                          : [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              )
                                                                            ]
                                                                      ]
                                                                    : [
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "Asset" ||
                                                                        selectedTransaction[0]
                                                                          .transactionType ==
                                                                          "Trade"
                                                                          ? void 0
                                                                          : [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Source"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .fundsSourceName
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    .settledAt
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "date"
                                                                                            )(
                                                                                              selectedTransaction[0]
                                                                                                .settledAt
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Settled Details"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .settlementDetails
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "b",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Special Instructions"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    ": "
                                                                                  ),
                                                                                  selectedTransaction[0]
                                                                                    ? [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            selectedTransaction[0]
                                                                                              .specialInstructions
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    : _vm._e()
                                                                                ],
                                                                                2
                                                                              )
                                                                            ]
                                                                      ]
                                                                ],
                                                                2
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "popup-header notes"
                                                      },
                                                      [
                                                        _c("h3", [
                                                          _vm._v("Notes")
                                                        ]),
                                                        _vm._v(" "),
                                                        !_vm.isCustomer &&
                                                        !_vm.addNote
                                                          ? _c("img", {
                                                              staticClass:
                                                                "main plus-img",
                                                              attrs: {
                                                                src:
                                                                  "/assets/img/green30.png"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.addNote = true
                                                                }
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.addNote
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "action-button save add-note-button",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveNote()
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("Save")]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.addNote
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content-form"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-row form-max"
                                                              },
                                                              [
                                                                _c(
                                                                  "form-field-text",
                                                                  {
                                                                    model: {
                                                                      value:
                                                                        _vm.note,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.note = $$v
                                                                      },
                                                                      expression:
                                                                        "note"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "content-table"
                                                      },
                                                      [
                                                        _c("tr", [
                                                          _c(
                                                            "th",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "0"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Date/Time"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("th", [
                                                            _vm._v("Added By")
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("th", [
                                                            _vm._v("Note")
                                                          ])
                                                        ]),
                                                        _vm._v(" "),
                                                        selectedTransaction
                                                          ? _vm._l(
                                                              selectedTransaction[0]
                                                                .notes,
                                                              function(
                                                                item,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  "tr",
                                                                  {
                                                                    key: item.id
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticStyle: {
                                                                          "padding-left":
                                                                            "0"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Date/Time"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "date"
                                                                            )(
                                                                              item.noteTime
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("td", [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Added By"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.addedBy
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("td", [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Note"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.note
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "popup-header notes"
                                                  },
                                                  [
                                                    _c("h3", [
                                                      _vm._v("Options")
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "buttons" },
                                                  [
                                                    selectedTransaction
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "optionBtns"
                                                          },
                                                          [
                                                            _vm.$hasPerm(
                                                              "approve-transaction"
                                                            )
                                                              ? [
                                                                  !_vm.isCustomer &&
                                                                  selectedTransaction[0]
                                                                    .status ===
                                                                    "Review"
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "approve",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.approvePopupTransaction(
                                                                                selectedTransaction[0]
                                                                                  .id
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Approve"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            selectedTransaction[0]
                                                              .status ===
                                                              "Pending" ||
                                                            selectedTransaction[0]
                                                              .status ===
                                                              "Review"
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "cancel",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.cancelPopupTransaction(
                                                                          selectedTransaction[0]
                                                                            .id
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Cancel"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "main",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.print(
                                                              selectedTransaction[0]
                                                                .id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Print")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1251398223
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Date", "min-width": "7" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("date")(
                                                    props.row.createdAt,
                                                    "MM/D/YYYY"
                                                  )
                                                )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      props.row.createdAt,
                                                      "hh:mm A"
                                                    )
                                                  )
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1730629616
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Account",
                                    "min-width": "10"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(props.row.accountId) +
                                                "\n              "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    384652913
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Number", "min-width": "7" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  props.row.transactionId
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3156587924
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Entry", "min-width": "6" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  props.row.entryType ==
                                                    "Deposit"
                                                    ? "DEP"
                                                    : "W/D"
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1514989481
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Type", "min-width": "8" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.transactionTypeText[
                                                    props.row.transactionType
                                                  ]
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2086757542
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Amount", "min-width": "12" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            props.row.currencyType == "Assets"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      parseFloat(
                                                        props.row.assetAmount
                                                      )
                                                    )
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row.currencySymbol
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "balance_without_symbol"
                                                        )(props.row.amount)
                                                      )
                                                  )
                                                ]
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1435241097
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Balance",
                                    "min-width": "12"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            props.row.currencyType == "Assets"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      parseFloat(
                                                        props.row
                                                          .availableAssetsBalance
                                                      )
                                                    )
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.row.currencySymbol
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "balance_without_symbol"
                                                        )(
                                                          props.row
                                                            .availableFundsBalance
                                                        )
                                                      )
                                                  )
                                                ]
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2898522032
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "Status", "min-width": "7" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _c(
                                              "em",
                                              {
                                                class:
                                                  _vm.statusColorClasses[
                                                    props.row.status
                                                  ]
                                              },
                                              [_vm._v(_vm._s(props.row.status))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1839509103
                                  )
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Reference",
                                    "min-width": "17",
                                    prop: "reference"
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Options",
                                    "min-width": "14"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return [
                                            _vm.$hasPerm("approve-transaction")
                                              ? [
                                                  !_vm.isCustomer &&
                                                  props.row.status === "Review"
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "action-button save",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.approveTransaction(
                                                                props.row.id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Approve")]
                                                      )
                                                    : _vm._e()
                                                ]
                                              : _vm._e(),
                                            _vm._v(" "),
                                            props.row.transactionType == "Asset"
                                              ? [
                                                  props.row.status ===
                                                    "Pending" ||
                                                  props.row.status === "Review"
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "action-button cancel",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.cancelAssetTransaction(
                                                                props.row.id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Cancel")]
                                                      )
                                                    : _vm._e()
                                                ]
                                              : [
                                                  props.row.status ===
                                                    "Pending" ||
                                                  props.row.status === "Review"
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "action-button cancel",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.cancelTransaction(
                                                                props.row.id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Cancel")]
                                                      )
                                                    : _vm._e()
                                                ],
                                            _vm._v(" "),
                                            props.row.status === "Pending" &&
                                            props.row
                                              .disbursementAuthorizationsStatus ===
                                              "pending"
                                              ? [
                                                  !_vm.isCustomer
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "action-button cancel",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.authRetry(
                                                                props.row.id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Auth")]
                                                      )
                                                    : _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "action-button cancel",
                                                          attrs: {
                                                            disabled: ""
                                                          }
                                                        },
                                                        [_vm._v("Auth")]
                                                      )
                                                ]
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1545027517
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.loading
                      ? _c("section", { staticClass: "pagination-section" }, [
                          _c("div", [
                            _vm._v("\n        Per page\n        "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.perPage,
                                    expression: "perPage"
                                  }
                                ],
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.perPage = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function($event) {
                                      return _vm.setPages()
                                    }
                                  ]
                                }
                              },
                              [
                                _c("option", { attrs: { value: "25" } }, [
                                  _vm._v("25")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "50" } }, [
                                  _vm._v("50")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "75" } }, [
                                  _vm._v("75")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "100" } }, [
                                  _vm._v("100")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "150" } }, [
                                  _vm._v("150")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "200" } }, [
                                  _vm._v("200")
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "nav",
                            {
                              attrs: { "aria-label": "Page navigation example" }
                            },
                            [
                              _c("ul", { staticClass: "pagination" }, [
                                _c("li", { staticClass: "page-item" }, [
                                  _vm.page != 1
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "page-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              _vm.page--
                                            }
                                          }
                                        },
                                        [_vm._v(" Previous ")]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { staticClass: "page-item" },
                                  _vm._l(
                                    _vm.pages.slice(_vm.page - 1, _vm.page + 5),
                                    function(pageNumber) {
                                      return _c(
                                        "button",
                                        {
                                          key: pageNumber,
                                          staticClass: "page-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              _vm.page = pageNumber
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(pageNumber) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("li", { staticClass: "page-item" }, [
                                  _vm.page < _vm.pages.length
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "page-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              _vm.page++
                                            }
                                          }
                                        },
                                        [_vm._v(" Next ")]
                                      )
                                    : _vm._e()
                                ])
                              ])
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "popup",
              {
                attrs: { open: _vm.addConfirmModal, width: 400 },
                on: {
                  "update:open": function($event) {
                    _vm.addConfirmModal = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "popup-header" }, [
                  _c("h3", [_vm._v("Confirmation")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "scrollable" }, [
                  _c("div", { staticClass: "content-form" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _vm.confirmSuccess
                        ? _c("p", { staticClass: "transaction-status" }, [
                            _vm._v("Transaction Cancelled")
                          ])
                        : _c("p", { staticClass: "transaction-status" }, [
                            _vm._v("Error: Unable to Cancel Transaction")
                          ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.addConfirmModal = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "popup",
              {
                attrs: { open: _vm.addApproveModal, width: 400 },
                on: {
                  "update:open": function($event) {
                    _vm.addApproveModal = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "popup-header" }, [
                  _c("h3", [_vm._v("Confirmation")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "scrollable" }, [
                  _c("div", { staticClass: "content-form" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _vm.approveSuccess
                        ? _c("p", { staticClass: "transaction-status" }, [
                            _vm._v("Transaction Approved")
                          ])
                        : _c("p", { staticClass: "transaction-status" }, [
                            _vm._v("Error: Unable to Approve Transaction")
                          ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.addApproveModal = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ])
              ]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "popup", class: { open: !_vm.open } }, [
        _c("div", { staticClass: "pop-inner", style: { maxWidth: "400px" } }, [
          _c("div", { staticClass: "pop-box" }, [
            _c("h2", [_vm._v("Enter Corporate Entry PIN to Continue")]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pin,
                      expression: "pin"
                    }
                  ],
                  staticClass: "pin-code",
                  attrs: {
                    oninput:
                      "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                    type: "number",
                    maxlength: "4"
                  },
                  domProps: { value: _vm.pin },
                  on: {
                    keyup: _vm.pinInput,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.pin = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c(
                "div",
                { staticClass: "pf-field" },
                [_vm.pinFailed ? [_vm._v("Invalid PIN")] : _vm._e()],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row buttons" }, [
              _c(
                "a",
                {
                  staticClass: "pop-btn",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "pop-btn",
                  on: {
                    click: function($event) {
                      return _vm.checkPin()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.errorOpen, width: 400 },
          on: {
            "update:open": function($event) {
              _vm.errorOpen = $event
            }
          }
        },
        [
          _c("div", { staticClass: "scrollable" }, [
            _c("p", [_vm._v(_vm._s(_vm.message))])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("corp-transaction-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.open
        ? [
            _vm.perms
              ? [
                  _c("section", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "24px 30px 10px 10px",
                          display: "flex"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "content-filters" },
                          [
                            _c("basic-form", [
                              _c("div", { staticClass: "cof-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "cof-field" },
                                  [
                                    _c("mnb-select", {
                                      attrs: {
                                        options: _vm.providerOptions,
                                        label: "",
                                        disabled:
                                          !!_vm.form.fromDate ||
                                          !!_vm.form.toDate
                                      },
                                      model: {
                                        value: _vm.form.provider,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "provider", $$v)
                                        },
                                        expression: "form.provider"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.form.provider && _vm.activeInfoReversals
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                on: {
                                  click: function($event) {
                                    ;(_vm.activeInfo = !_vm.activeInfo),
                                      (_vm.activeInfoReversals = false)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-document-add"
                                }),
                                _vm._v(" Generate Fees")
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.form.provider && _vm.activeInfo
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                on: {
                                  click: function($event) {
                                    ;(_vm.activeInfoReversals = !_vm.activeInfoReversals),
                                      (_vm.activeInfo = false)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "el-icon-receiving" }),
                                _vm._v(" Owed Fees")
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.form.provider
                    ? [
                        _c("div", { staticClass: "accordion" }, [
                          _vm.activeInfo
                            ? _c(
                                "div",
                                { class: { "cof-box-active": _vm.activeInfo } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "content-filters" },
                                    [
                                      _vm.form.provider
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "cof-row dates" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "cof-field" },
                                                  [
                                                    _c("label", [
                                                      _vm._v("Start Date")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "cof-multi flex"
                                                      },
                                                      [
                                                        _c("date-time-picker", {
                                                          attrs: {
                                                            "value-type":
                                                              "String",
                                                            "value-format":
                                                              "yyyy-LL-dd",
                                                            format:
                                                              "yyyy-LL-dd",
                                                            "time-picker": false,
                                                            "auto-close": true,
                                                            "max-date":
                                                              _vm.date2,
                                                            disabled: !_vm.form
                                                              .provider
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.fromDate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "fromDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.fromDate"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "cof-field" },
                                                  [
                                                    _c("label", [
                                                      _vm._v("End Date")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "cof-multi flex"
                                                      },
                                                      [
                                                        _c("date-time-picker", {
                                                          attrs: {
                                                            "value-type":
                                                              "String",
                                                            "value-format":
                                                              "yyyy-LL-dd",
                                                            format:
                                                              "yyyy-LL-dd",
                                                            "time-picker": false,
                                                            "auto-close": true,
                                                            "max-date":
                                                              _vm.date2,
                                                            disabled: !_vm.form
                                                              .provider
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.toDate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "toDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.toDate"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.toDateError
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error-message"
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Invalid End Date."
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cof-row buttons"
                                              },
                                              [
                                                _vm.$hasPerm("generate-fees")
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "cof-field"
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(" ")
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "second",
                                                            attrs: {
                                                              disabled:
                                                                !_vm.form
                                                                  .provider ||
                                                                _vm.toDateError ||
                                                                _vm.form
                                                                  .fromDate ==
                                                                  null ||
                                                                _vm.form
                                                                  .toDate ==
                                                                  null
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.generate()
                                                              }
                                                            }
                                                          },
                                                          [_vm._v("Generate")]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "cof-field" },
                                                  [
                                                    _c("label", [_vm._v(" ")]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass: "second",
                                                        attrs: {
                                                          disabled: !_vm.form
                                                            .provider
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.reset()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Reset")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "cof-row run" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "cof-field" },
                                                  [
                                                    _c("label", [_vm._v(" ")]),
                                                    _vm._v(" "),
                                                    _vm.isRun
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "action-button save",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.run()
                                                              }
                                                            }
                                                          },
                                                          [_vm._v("Run")]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isResult
                                        ? _c("div", { staticClass: "result" }, [
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [_vm._v("Result:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "table",
                                              { staticClass: "result-table" },
                                              [
                                                _c("tr", [
                                                  _c("td", [
                                                    _vm._v("Collected:")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        width: "25%"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.total
                                                            .totalCollected
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("balance")(
                                                          _vm.total
                                                            .totalCollectedFee
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]),
                                                _vm._v(" "),
                                                _c("tr", [
                                                  _c("td", [
                                                    _vm._v("Not Collected:")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        width: "25%"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.total
                                                            .totalNotCollected
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("balance")(
                                                          _vm.total
                                                            .totalNotCollectedFee
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ])
                                              ]
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "section",
                                    { staticClass: "content-tlist" },
                                    [
                                      _c(
                                        "table",
                                        { staticClass: "content-table" },
                                        [
                                          _vm._m(0),
                                          _vm._v(" "),
                                          _vm._l(_vm.results, function(
                                            item,
                                            i
                                          ) {
                                            return item.accountStatus !=
                                              "Pending"
                                              ? _c(
                                                  "tr",
                                                  {
                                                    key: i,
                                                    class:
                                                      item.totalFee >=
                                                      item.systemAvailableBalance
                                                        ? "highlight-yellow"
                                                        : ""
                                                  },
                                                  [
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v("Account")
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              "/system-customers/registrations/" +
                                                              item.regId
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.accountNumber
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v("Account")
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(item.regName)
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v("Account")
                                                      ]),
                                                      _c(
                                                        "em",
                                                        {
                                                          class:
                                                            _vm
                                                              .statusAccountColorClasses[
                                                              item.accountStatus
                                                            ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.accountStatus
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Transaction Volume"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("balance")(
                                                            item.volume
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v("Discount Fee")
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "formattedTwoDecimal"
                                                          )(item.discount)
                                                        ) + "%"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Discount Charged"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("balance")(
                                                            item.discountCharged
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Transaction Fees"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("balance")(
                                                            item.transactionFee
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v("Monthly Fee")
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("balance")(
                                                            item.monthlyFee
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c("span", [
                                                          _vm._v("Dormant Fee")
                                                        ]),
                                                        item.accountStatus ==
                                                        "Dormant"
                                                          ? [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "balance"
                                                                  )(
                                                                    item.dormantAccountsFee
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v("Owed Fees")
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("balance")(
                                                            item.owedFee
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v("Total Fees")
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("balance")(
                                                            item.totalFee
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Available Balance"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("balance")(
                                                            item.systemAvailableBalance
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v("Result")
                                                      ]),
                                                      _c(
                                                        "em",
                                                        {
                                                          class:
                                                            _vm
                                                              .statusColorClasses[
                                                              item.resultMonthly
                                                            ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.resultMonthly
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("span", [
                                                        _vm._v("Options")
                                                      ]),
                                                      _vm._v(" "),
                                                      item.resultMonthly ==
                                                      "Failed"
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "action-button save",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.runOne(
                                                                    item.id,
                                                                    i
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("Run")]
                                                          )
                                                        : _vm._e()
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          }),
                                          _vm._v(" "),
                                          _vm.total
                                            ? _c("tr", [
                                                _c("td", [_vm._v("Totals")]),
                                                _vm._v(" "),
                                                _vm._m(1),
                                                _vm._v(" "),
                                                _vm._m(2),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v("Totals")
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("balance")(
                                                        _vm.total.totalVolume
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _vm._m(3),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v("Totals")
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("balance")(
                                                        _vm.total
                                                          .totalDiscountCharged
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v("Totals")
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("balance")(
                                                        _vm.total
                                                          .totalTransactionFee
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v("Totals")
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("balance")(
                                                        _vm.total
                                                          .totalMonthlyFee
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v("Totals")
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("balance")(
                                                        _vm.total
                                                          .totalDormantFee
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v("Totals")
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("balance")(
                                                        _vm.total.totalOwedFee
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v("Totals")
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("balance")(
                                                        _vm.total.totalTotalFee
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _vm._m(4),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  [
                                                    _c("span", [
                                                      _vm._v("Totals")
                                                    ]),
                                                    _vm.total.totalRealTotalFee
                                                      ? [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f("balance")(
                                                                _vm.total
                                                                  .totalRealTotalFee
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _vm._m(5)
                                              ])
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.activeInfoReversals
                            ? _c(
                                "div",
                                {
                                  class: {
                                    "cof-box-active": _vm.activeInfoReversals
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    {
                                      staticClass: "content-filters",
                                      staticStyle: { padding: "30px" }
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "tsm-handle",
                                            attrs: { href: "" }
                                          },
                                          [_vm._v("Filters")]
                                        ),
                                        _vm._v(" "),
                                        _c("h4", [_vm._v("Filter")]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "cof-form" }, [
                                          _c("a", { staticClass: "cof-close" }),
                                          _vm._v(" "),
                                          _c("h6", [
                                            _vm._v("Registrations Search")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "cof-row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "cof-field" },
                                                [
                                                  _c("label", [
                                                    _vm._v("Account ID")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.searchForm
                                                            .accountId,
                                                        expression:
                                                          "searchForm.accountId"
                                                      }
                                                    ],
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        _vm.searchForm.accountId
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.searchForm,
                                                          "accountId",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "cof-field" },
                                                [
                                                  _c("label", [_vm._v("Name")]),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.searchForm.name,
                                                        expression:
                                                          "searchForm.name"
                                                      }
                                                    ],
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value: _vm.searchForm.name
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.searchForm,
                                                          "name",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "cof-field" },
                                                [
                                                  _c("label", [
                                                    _vm._v("Status")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.searchForm
                                                              .status,
                                                          expression:
                                                            "searchForm.status"
                                                        }
                                                      ],
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          var $$selectedVal = Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function(o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function(o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                          _vm.$set(
                                                            _vm.searchForm,
                                                            "status",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("option", {
                                                        domProps: {
                                                          value: null
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "New"
                                                          }
                                                        },
                                                        [_vm._v("NEW")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "Pending"
                                                          }
                                                        },
                                                        [_vm._v("PENDING")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "Open"
                                                          }
                                                        },
                                                        [_vm._v("OPEN")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "Restricted"
                                                          }
                                                        },
                                                        [_vm._v("RESTRICTED")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "Hold"
                                                          }
                                                        },
                                                        [_vm._v("HOLD")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "Dormant"
                                                          }
                                                        },
                                                        [_vm._v("DORMANT")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "Closed"
                                                          }
                                                        },
                                                        [_vm._v("CLOSED")]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cof-field mobinl"
                                                },
                                                [
                                                  _c("label", [_vm._v(" ")]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "main",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.search()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Search")]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cof-field mobinl"
                                                },
                                                [
                                                  _c("label", [_vm._v(" ")]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "second",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.resetItem()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Reset Filters")]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "section",
                                    { staticClass: "content-tlist" },
                                    [
                                      _c(
                                        "table",
                                        { staticClass: "content-table" },
                                        [
                                          _c("tr", [
                                            _c("th", [_vm._v("Account")]),
                                            _vm._v(" "),
                                            _c("th", [_vm._v("Name")]),
                                            _vm._v(" "),
                                            _c("th", [_vm._v("Status")]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v("Owed Fee "),
                                              _c("img", {
                                                attrs: {
                                                  src: "/assets/img/updown.png",
                                                  width: "13"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.sortTemplateName(
                                                      "owedFee"
                                                    )
                                                  }
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v("Available Balance "),
                                              _c("img", {
                                                attrs: {
                                                  src: "/assets/img/updown.png",
                                                  width: "13"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.sortTemplateName(
                                                      "availableBalance"
                                                    )
                                                  }
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("th", [_vm._v("Result")]),
                                            _vm._v(" "),
                                            _c("th", [_vm._v("Options")])
                                          ]),
                                          _vm._v(" "),
                                          _vm._l(_vm.owedResults, function(
                                            item,
                                            i
                                          ) {
                                            return item.accountStatus !=
                                              "Pending" && item.owedFee > 0
                                              ? _c("tr", { key: i }, [
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Account")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            "/system-customers/registrations/" +
                                                            item.regId
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.accountNumber
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Account")
                                                    ]),
                                                    _vm._v(_vm._s(item.regName))
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Account")
                                                    ]),
                                                    _c(
                                                      "em",
                                                      {
                                                        class:
                                                          _vm
                                                            .statusAccountColorClasses[
                                                            item.accountStatus
                                                          ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.accountStatus
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Owed Fees")
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("balance")(
                                                          item.owedFee
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Available Balance"
                                                      )
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("balance")(
                                                          item.systemAvailableBalance
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("span", [
                                                      _vm._v("Result")
                                                    ]),
                                                    _c(
                                                      "em",
                                                      {
                                                        class:
                                                          _vm
                                                            .statusColorClasses[
                                                            item.resultMonthly
                                                          ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.resultMonthly
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("span", [
                                                        _vm._v("Options")
                                                      ]),
                                                      _vm._v(" "),
                                                      item.systemAvailableBalance !=
                                                      0
                                                        ? [
                                                            _vm.$hasPerm(
                                                              "generate-fees"
                                                            ) &&
                                                            parseFloat(
                                                              item.owedFee
                                                            ) <=
                                                              parseFloat(
                                                                item.systemAvailableBalance
                                                              )
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "action-button save",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.fullPayment(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Full Payment"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.$hasPerm(
                                                              "generate-fees"
                                                            ) &&
                                                            parseFloat(
                                                              item.owedFee
                                                            ) >
                                                              parseFloat(
                                                                item.systemAvailableBalance
                                                              )
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "action-button save",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.partialPayment(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Partial Payment"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ])
                                              : _vm._e()
                                          }),
                                          _vm._v(" "),
                                          _vm.total
                                            ? _c("tr", [
                                                _c("td", [_vm._v("Totals")]),
                                                _vm._v(" "),
                                                _vm._m(6),
                                                _vm._v(" "),
                                                _vm._m(7),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c("span", [
                                                    _vm._v("Totals")
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("balance")(
                                                        _vm.owedTotal
                                                          .totalOwedFee
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _vm._m(8),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  [
                                                    _c("span", [
                                                      _vm._v("Totals")
                                                    ]),
                                                    _vm.owedTotal
                                                      .totalRealTotalFee
                                                      ? [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f("balance")(
                                                                _vm.owedTotal
                                                                  .totalRealTotalFee
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _vm._m(9)
                                              ])
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ]
                    : _vm._e()
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "popup", class: { open: !_vm.open } }, [
        _c("div", { staticClass: "pop-inner", style: { maxWidth: "400px" } }, [
          _c("div", { staticClass: "pop-box" }, [
            _c("h2", [_vm._v("Enter Finance PIN to Continue")]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pin,
                      expression: "pin"
                    }
                  ],
                  staticClass: "pin-code",
                  attrs: {
                    oninput:
                      "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                    type: "number",
                    maxlength: "4"
                  },
                  domProps: { value: _vm.pin },
                  on: {
                    keyup: _vm.pinInput,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.pin = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c(
                "div",
                { staticClass: "pf-field" },
                [_vm.pinFailed ? [_vm._v("Invalid PIN")] : _vm._e()],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row buttons" }, [
              _c(
                "a",
                {
                  staticClass: "pop-btn",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "pop-btn",
                  on: {
                    click: function($event) {
                      return _vm.checkPin()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ])
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Account")]),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Volume")]),
      _vm._v(" "),
      _c("th", [_vm._v("Volume Fee")]),
      _vm._v(" "),
      _c("th", [_vm._v("Volume Charge")]),
      _vm._v(" "),
      _c("th", [_vm._v("Transaction Fees")]),
      _vm._v(" "),
      _c("th", [_vm._v("Monthly Fee")]),
      _vm._v(" "),
      _c("th", [_vm._v("Dormant Fee")]),
      _vm._v(" "),
      _c("th", [_vm._v("Owed Fee")]),
      _vm._v(" "),
      _c("th", [_vm._v("Total Fees")]),
      _vm._v(" "),
      _c("th", [_vm._v("Available Balance")]),
      _vm._v(" "),
      _c("th", [_vm._v("Result")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Totals")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Totals")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Totals")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Totals")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Totals")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Totals")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Totals")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Totals")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Totals")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
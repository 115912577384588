var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.actualValue,
          expression: "actualValue"
        }
      ],
      on: {
        input: function($event) {
          return _vm.$emit("input", $event.target.value)
        },
        change: function($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function(o) {
              return o.selected
            })
            .map(function(o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.actualValue = $event.target.multiple
            ? $$selectedVal
            : $$selectedVal[0]
        }
      }
    },
    _vm._l(_vm.options, function(opt) {
      return _c(
        "option",
        { domProps: { value: opt.value, selected: opt.value == _vm.value } },
        [_vm._v(_vm._s(opt.label))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
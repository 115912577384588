var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "section",
          [
            _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function($event) {
                      _vm.activeInfo = "fees"
                    }
                  }
                },
                [_vm._v(" Fees Detail")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: {
                    click: function($event) {
                      _vm.activeInfo = "transaction"
                    }
                  }
                },
                [_vm._v(" Transaction Detail")]
              )
            ]),
            _vm._v(" "),
            _vm.activeInfo == "fees" ? _c("fees-report") : _vm._e(),
            _vm._v(" "),
            _vm.activeInfo == "transaction"
              ? _c("transaction-report")
              : _vm._e()
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "support-page" }, [
    _c("div", { staticClass: "support-split" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("aside", { staticClass: "s-side" }, [
        _c("h3", [_vm._v("Support Contact Informaton")]),
        _vm._v(" "),
        _c("ul", [
          !_vm.customEnv.nosupptickets
            ? _c("li", { staticClass: "ticket" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Support Ticket")]),
                _vm._v(" "),
                _vm._m(1)
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.customEnv.nosupptickets
            ? _c("li", { staticClass: "chat" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Live Chat")]),
                _vm._v(" "),
                _vm._m(2)
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("li", { staticClass: "email" }, [
            _c("div", { staticClass: "title" }, [_vm._v("Email")]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _vm._v("Contact us at "),
              _c(
                "a",
                { attrs: { href: "mailto:" + _vm.system.support_email } },
                [_vm._v(_vm._s(_vm.system.support_email))]
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "phone" }, [
            _c("div", { staticClass: "title" }, [_vm._v("Phone")]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _vm._v("Contact us at "),
              _c("a", { attrs: { href: "tel:" + _vm.system.support_phone } }, [
                _vm._v(_vm._s(_vm.system.support_phone))
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "s-main" }, [
      _c("h2", { staticClass: "inner-headers" }, [_vm._v("Support")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("a", { attrs: { href: "" } }, [_vm._v("Click Here")]),
      _vm._v(" to open a new support ticket")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("a", { attrs: { href: "" } }, [_vm._v("Click Here")]),
      _vm._v(" to chat with one of our representatives")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "content-tlist" }, [
      _c(
        "table",
        { staticClass: "content-table" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.fees, function(item, i) {
            return _c(
              "tr",
              { key: i },
              [
                i == 0
                  ? [
                      _c("td", [
                        _c("span", [_vm._v("Fee Name")]),
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Fee")]),
                          _vm._v(" "),
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formattedTwoDecimal")(item.fee)
                                ) +
                                " "
                            )
                          ],
                          _vm._v("\n            %\n          ")
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("td"),
                      _vm._v(" "),
                      _c("td"),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Description")]),
                          _vm._v(" "),
                          [_vm._v(" " + _vm._s(item.description))]
                        ],
                        2
                      )
                    ]
                  : [
                      _c("td", [
                        _c("span", [_vm._v("Fee Name")]),
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Fee")]),
                          _vm._v(" "),
                          item.fee != null
                            ? [
                                _vm._v("\n              $"),
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formattedTwoDecimal")(item.fee)
                                      ) +
                                      " "
                                  )
                                ]
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Per Deposit")]),
                          _vm._v(" "),
                          item.per_deposit != null
                            ? [
                                _vm._v("\n              $"),
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formattedTwoDecimal")(
                                          item.per_deposit
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Per Withdrawal")]),
                          _vm._v(" "),
                          item.per_withdrawal != null
                            ? [
                                _vm._v("\n              $"),
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formattedTwoDecimal")(
                                          item.per_withdrawal
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Description")]),
                          _vm._v(" "),
                          [_vm._v(" " + _vm._s(item.description))]
                        ],
                        2
                      )
                    ]
              ],
              2
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Fee Name")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Fee")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Deposit")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Withdrawal")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "13%" } }, [_vm._v("Description")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.featureStatus == null
        ? void 0
        : [
            !_vm.featureStatus
              ? [
                  _c("h3", { staticClass: "monthly-head" }, [
                    _vm._v(
                      "This feature is not enabled. Please contact system administration in order to have it enabled."
                    )
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "monthly-head" }, [
                    _vm._v("Feature - Dispute Manager")
                  ])
                ]
              : [
                  _c("button", { staticClass: "btn main" }, [
                    _vm._v("New Dispute")
                  ]),
                  _vm._v(" "),
                  _vm._m(0)
                ]
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "content-tlist", staticStyle: { margin: "20px 0" } },
      [
        _c("table", { staticClass: "content-table" }, [
          _c("tr", [
            _c("th", [_vm._v("Date/Time")]),
            _vm._v(" "),
            _c("th", [_vm._v("By")]),
            _vm._v(" "),
            _c("th", [_vm._v("Account")]),
            _vm._v(" "),
            _c("th", [_vm._v("Dispute Number")]),
            _vm._v(" "),
            _c("th", [_vm._v("Reason")]),
            _vm._v(" "),
            _c("th", [_vm._v("Status")]),
            _vm._v(" "),
            _c("th", [_vm._v("Amount")]),
            _vm._v(" "),
            _c("th", [_vm._v("Options")])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
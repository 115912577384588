var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("h2", [_vm._v("Terms & Conditions")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "status" },
          [
            _vm._v("\n      Status\n      "),
            _c("status-icon", { attrs: { color: _vm.terms ? "green" : null } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "content-table", staticStyle: { "min-width": "unset" } },
        [
          _c("tr", [
            _c("th", [_vm._v("Date/Time")]),
            _vm._v(" "),
            _c("th", [_vm._v("IP Address")]),
            _vm._v(" "),
            _c("th", [_vm._v("Terms")]),
            _vm._v(" "),
            _c("th", [_vm._v("Privacy")]),
            _vm._v(" "),
            _c("th", [_vm._v("File")]),
            _vm._v(" "),
            _vm.registration.status !== "Complete" && !_vm.mainAccount
              ? _c("th", [_vm._v("Options")])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("tr", [
            _c(
              "td",
              [
                _c("span", [_vm._v("Date/Time")]),
                _vm._v(" "),
                !_vm.terms
                  ? [_vm._v("N/A")]
                  : [
                      _vm._v(
                        _vm._s(_vm._f("date")(_vm.terms.createdAt, "LL")) +
                          " " +
                          _vm._s(_vm._f("date")(_vm.terms.createdAt, "LT"))
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("IP Address")]),
                _vm._v(" "),
                !_vm.terms ? [_vm._v("N/A")] : [_vm._v(_vm._s(_vm.terms.ip))]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Terms")]),
                _vm._v(" "),
                !_vm.terms ? [_vm._v("N/A")] : [_vm._v(_vm._s(_vm.terms.terms))]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Privacy")]),
                _vm._v(" "),
                !_vm.terms
                  ? [_vm._v("N/A")]
                  : [_vm._v(_vm._s(_vm.terms.privacy))]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("File")]),
                _vm._v(" "),
                !_vm.terms
                  ? [_vm._v("N/A")]
                  : [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/download/RegDocument/" + _vm.terms.file.id,
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.terms.file.file) +
                              "\n          "
                          )
                        ]
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _vm.registration.status !== "Complete" && !_vm.mainAccount
              ? _c("td", [
                  _c("span", [_vm._v("Options")]),
                  _vm._v(" "),
                  !_vm.terms
                    ? _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.create()
                            }
                          }
                        },
                        [_vm._v("Create")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              return _vm.remove()
                            }
                          }
                        },
                        [_vm._v("Remove")]
                      )
                ])
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
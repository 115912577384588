var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.featureStatus != null && !_vm.featureStatus
        ? [
            _c("h3", { staticClass: "monthly-head" }, [
              _vm._v(
                "This feature is not enabled. Please contact system administration in order to have it enabled."
              )
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "monthly-head" }, [
              _vm._v("Feature - Control Center")
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
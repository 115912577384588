var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", [
        _c("div", { staticClass: "flex-between" }, [
          _c("h2", { staticClass: "inner-headers" }),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
            _c(
              "button",
              {
                staticClass: "second",
                on: {
                  click: function($event) {
                    _vm.openedFilters = true
                  }
                }
              },
              [
                _c("i", { staticClass: "el-icon-s-operation" }),
                _vm._v(" Filters")
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      [
        _c(
          "button",
          {
            staticClass: "btn btn-success",
            staticStyle: { "margin-top": "-60px" },
            on: {
              click: function($event) {
                return _vm.openModal("withdraw")
              }
            }
          },
          [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" New Template")]
        )
      ],
      _vm._v(" "),
      _c("applied-filters", {
        attrs: { filters: _vm.appliedFilters },
        on: {
          clear: function($event) {
            return _vm.clearFilters()
          },
          remove: function($event) {
            return _vm.removeFilter($event)
          }
        }
      }),
      _vm._v(" "),
      !_vm.modalType && !_vm.open
        ? _c("div", { staticClass: "accordion" }, [
            _c("div", { class: { "cof-box-active": _vm.activeInfo } }, [
              !_vm.loading
                ? _c("section", { staticClass: "content-tlist" }, [
                    _c(
                      "table",
                      { staticClass: "content-table" },
                      [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticStyle: {
                                width: "11%",
                                "text-align": "center"
                              }
                            },
                            [
                              _vm._v("Created "),
                              _c("img", {
                                attrs: {
                                  src: "/assets/img/updown.png",
                                  width: "13"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.sortTemplateName("createdAt")
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                width: "11%",
                                "text-align": "center"
                              }
                            },
                            [_vm._v("Updated")]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                width: "5%",
                                "text-align": "center"
                              }
                            },
                            [_vm._v("By")]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                width: "6%",
                                "text-align": "center"
                              }
                            },
                            [
                              _vm._v("Account "),
                              _c("img", {
                                attrs: {
                                  src: "/assets/img/updown.png",
                                  width: "13"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.sortTemplateName("account")
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                width: "13%",
                                "text-align": "center"
                              }
                            },
                            [
                              _vm._v("Template "),
                              _c("img", {
                                attrs: {
                                  src: "/assets/img/updown.png",
                                  width: "13"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.sortTemplateName("templateName")
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                width: "13%",
                                "text-align": "center"
                              }
                            },
                            [_vm._v("Beneficiary")]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                width: "7%",
                                "text-align": "center"
                              }
                            },
                            [
                              _vm._v("Method "),
                              _c("img", {
                                attrs: {
                                  src: "/assets/img/updown.png",
                                  width: "13"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.sortTemplateName("method")
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                width: "25%",
                                "text-align": "center"
                              }
                            },
                            [_vm._v("Options")]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.filteredData, function(item, i) {
                          return _c(
                            "tr",
                            { key: i },
                            [
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("span", [_vm._v("Date Created")]),
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("date")(
                                        item.createdAt,
                                        "MM/D/YYYY"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("span", [_vm._v("Last Updated")]),
                                  _vm._v(" "),
                                  item.updatedAt
                                    ? [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("date")(
                                              item.updatedAt,
                                              "MM/D/YYYY"
                                            )
                                          )
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("span", [_vm._v("By")]),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.savedBy) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              [
                                _vm.isCustomer
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _c("span", [_vm._v("Account")]),
                                        _vm._v(_vm._s(item.account))
                                      ]
                                    )
                                  : _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _c("span", [_vm._v("Account")]),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "/system-customers/profiles/" +
                                                item.regId
                                            }
                                          },
                                          [_vm._v(_vm._s(item.account))]
                                        )
                                      ]
                                    )
                              ],
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("span", [_vm._v("Template Name")]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      on: {
                                        click: function($event) {
                                          return _vm.openTemplateModal(item)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item.templateName))]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("span", [_vm._v("Template Name")]),
                                  _vm._v(" "),
                                  item.method == "Asset"
                                    ? [_vm._v(_vm._s(item.payeeToName))]
                                    : item.method == "checks"
                                    ? [_vm._v(_vm._s(item.payee))]
                                    : [_vm._v(_vm._s(item.accountName))]
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("span", [_vm._v("Method")]),
                                  _vm._v(
                                    _vm._s(_vm.transactionTypeText[item.method])
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("span", [_vm._v("Options")]),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "action-button save",
                                      on: {
                                        click: function($event) {
                                          return _vm.openTemplateModal(item)
                                        }
                                      }
                                    },
                                    [_vm._v("Update")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "action-button save",
                                      attrs: {
                                        disabled:
                                          item.provider.status == "Disabled" ||
                                          !_vm.system.transaction_withdrawals ||
                                          (item.accountStatus != "Open" &&
                                            item.accountStatus != "Active")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.newTrans(item)
                                        }
                                      }
                                    },
                                    [_vm._v("New Trans")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "action-button remove",
                                      on: {
                                        click: function($event) {
                                          return _vm.removeTemplate(item)
                                        }
                                      }
                                    },
                                    [_vm._v("Remove")]
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        }),
                        _vm._v(" "),
                        !_vm.templates.length
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "no-data",
                                  attrs: { colspan: "11" }
                                },
                                [_vm._v("No data")]
                              )
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.loading
                ? _c("section", { staticClass: "pagination-section" }, [
                    _c("div", [
                      _vm._v("\n          Per page\n          "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.perPage,
                              expression: "perPage"
                            }
                          ],
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.perPage = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.setPages()
                              }
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { value: "25" } }, [
                            _vm._v("25")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "50" } }, [
                            _vm._v("50")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "75" } }, [
                            _vm._v("75")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "100" } }, [
                            _vm._v("100")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "150" } }, [
                            _vm._v("150")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "200" } }, [
                            _vm._v("200")
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "nav",
                      { attrs: { "aria-label": "Page navigation example" } },
                      [
                        _c("ul", { staticClass: "pagination" }, [
                          _c("li", { staticClass: "page-item" }, [
                            _vm.page != 1
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "page-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.page--
                                      }
                                    }
                                  },
                                  [_vm._v(" Previous ")]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "page-item" },
                            _vm._l(
                              _vm.pages.slice(_vm.page - 1, _vm.page + 5),
                              function(pageNumber) {
                                return _c(
                                  "button",
                                  {
                                    key: pageNumber,
                                    staticClass: "page-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.page = pageNumber
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(pageNumber) + " ")]
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c("li", { staticClass: "page-item" }, [
                            _vm.page < _vm.pages.length
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "page-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.page++
                                      }
                                    }
                                  },
                                  [_vm._v(" Next ")]
                                )
                              : _vm._e()
                          ])
                        ])
                      ]
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.template
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-around"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.open,
                      expression: "open"
                    }
                  ],
                  staticClass: "content-tlist",
                  staticStyle: { width: "70%", border: "none", padding: "30px" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "popup-header" },
                    [
                      _vm.addConfirmModal
                        ? [
                            _vm.template
                              ? _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.template.method != "Asset"
                                        ? "Funds Withdrawal"
                                        : "Assets Withdrawal"
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.transaction
                              ? _c("span", [
                                  _vm._v(
                                    "Transaction: " +
                                      _vm._s(_vm.transaction.transactionId)
                                  )
                                ])
                              : _vm._e()
                          ]
                        : _vm.newTransFlag
                        ? [
                            _c("h3", [_vm._v("New Transaction")]),
                            _vm._v(" "),
                            _c("h3", [_vm._v(_vm._s(_vm.template.type))]),
                            _vm._v(" "),
                            _c("h3", { staticStyle: { width: "100px" } })
                          ]
                        : [
                            _c("h3", [_vm._v("Update Template")]),
                            _vm._v(" "),
                            _c("h3", [
                              _vm._v(_vm._s(_vm.template.templateName))
                            ]),
                            _vm._v(" "),
                            _c("h3", { staticStyle: { width: "100px" } })
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.addConfirmModal
                    ? _c(
                        "div",
                        { staticClass: "scrollable" },
                        [
                          _vm.template.method != "Asset"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "content-form content-form-modern"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-row form-split" },
                                    [
                                      _c("form-field-text", {
                                        attrs: {
                                          label: "Account",
                                          disabled: "true",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.templateAccount,
                                          callback: function($$v) {
                                            _vm.templateAccount = $$v
                                          },
                                          expression: "templateAccount"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("form-field-text", {
                                        attrs: {
                                          label: "Currency",
                                          disabled: "true",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.template.currency,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template,
                                              "currency",
                                              $$v
                                            )
                                          },
                                          expression: "template.currency"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-row form-split" },
                                    [
                                      _c("form-field-text", {
                                        attrs: {
                                          label: "Payment Method",
                                          disabled: "true",
                                          options: _vm.depositOptions,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.templateMethod,
                                          callback: function($$v) {
                                            _vm.templateMethod = $$v
                                          },
                                          expression: "templateMethod"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.newTransFlag
                                        ? _c("form-field-text", {
                                            attrs: {
                                              validator: _vm.$v.template.amount,
                                              label: "Amount",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.template.amount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.template,
                                                  "amount",
                                                  $$v
                                                )
                                              },
                                              expression: "template.amount"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "content-form content-form-modern"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-row form-split" },
                                    [
                                      _c("form-field-text", {
                                        attrs: {
                                          label: "Account",
                                          disabled: "true",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.templateAccount,
                                          callback: function($$v) {
                                            _vm.templateAccount = $$v
                                          },
                                          expression: "templateAccount"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("form-field-text", {
                                        attrs: {
                                          label: "Currency",
                                          disabled: "true",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.template.currency,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template,
                                              "currency",
                                              $$v
                                            )
                                          },
                                          expression: "template.currency"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-row form-split" },
                                    [
                                      _c("form-field-select", {
                                        attrs: {
                                          label: "Sending to",
                                          disabled: "true",
                                          options: _vm.sendingToOptions,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.template.sendingTo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template,
                                              "sendingTo",
                                              $$v
                                            )
                                          },
                                          expression: "template.sendingTo"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.newTransFlag
                                        ? _c("form-field-text", {
                                            attrs: {
                                              validator:
                                                _vm.$v.template.assetsAmount,
                                              label: "Units Withdrawal Amount",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.template.assetsAmount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.template,
                                                  "assetsAmount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "template.assetsAmount"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                          _vm._v(" "),
                          _c("div", { staticClass: "invalid-balance" }, [
                            _vm.invalidBalance
                              ? _c("div", { staticClass: "error-message" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Exceeds available balance. Please lower amount and try again."
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "invalid-balance" }, [
                            _vm.invalidToProvider
                              ? _c("div", { staticClass: "error-message" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Receiver cannot accept Account to Account Transfers. Please check information and try again."
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.template.method != "Asset"
                            ? _c("div", { staticClass: "sum" }, [
                                _c("div", [
                                  _vm._v("Fee: "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm._f("money")(_vm.fundsFee))
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.newTransFlag
                                  ? _c("div", [
                                      _vm._v("Total withdrawal "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm._f("money")(_vm.total))
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ])
                            : _c("div", { staticClass: "sum" }, [
                                _c("div", [
                                  _vm._v("Fee: "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm._f("money")(_vm.assetsFee))
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.newTransFlag
                                  ? _c("div", [
                                      _vm._v("Total withdrawal: "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("unit_asset_balance")(
                                              _vm.totalAssets
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ]),
                          _vm._v(" "),
                          _vm.template.method == "Asset"
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "content-form content-form-modern"
                                  },
                                  [
                                    _vm.template.sendingTo == "AccountTransfer"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("form-field-select", {
                                              attrs: {
                                                label: "Select Linked Account",
                                                validator:
                                                  _vm.$v.template.toAccountId,
                                                options:
                                                  _vm.linkedAccountOptions,
                                                required: "",
                                                disabled: !_vm.updateTemplateFlag
                                              },
                                              model: {
                                                value: _vm.template.toAccountId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.template,
                                                    "toAccountId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "template.toAccountId"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.errorWallet
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "error-message"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "This account does not have an activated wallet for the asset type chosen. Please check information and try again."
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-row form-split"
                                            },
                                            [
                                              _c("form-field-text", {
                                                attrs: {
                                                  label: "Pay to Name",
                                                  disabled: !_vm.updateTemplateFlag,
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.template.payeeToName,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.template,
                                                      "payeeToName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "template.payeeToName"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-row form-max"
                                            },
                                            [
                                              _c("form-field-text", {
                                                attrs: {
                                                  label:
                                                    "Pay to Wallet Address",
                                                  disabled: !_vm.updateTemplateFlag,
                                                  validator:
                                                    _vm.$v.template
                                                      .payeeToWalletAddress,
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.template
                                                      .payeeToWalletAddress,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.template,
                                                      "payeeToWalletAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "template.payeeToWalletAddress"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-row form-max"
                                            },
                                            [
                                              _c("form-field-text", {
                                                attrs: {
                                                  label: "Destination Tag",
                                                  disabled: !_vm.updateTemplateFlag,
                                                  validator:
                                                    _vm.$v.template.tag,
                                                  required: ""
                                                },
                                                model: {
                                                  value: _vm.template.tag,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.template,
                                                      "tag",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "template.tag"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showInternationalWireForm
                            ? _c("international-wire-form", {
                                attrs: {
                                  v: _vm.$v.template,
                                  disabledField: !_vm.updateTemplateFlag,
                                  transactionCountries: _vm.transactionCountries
                                },
                                model: {
                                  value: _vm.template,
                                  callback: function($$v) {
                                    _vm.template = $$v
                                  },
                                  expression: "template"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showACHForm
                            ? _c("ach-form", {
                                attrs: {
                                  v: _vm.$v.template,
                                  disabledField: !_vm.updateTemplateFlag
                                },
                                model: {
                                  value: _vm.template,
                                  callback: function($$v) {
                                    _vm.template = $$v
                                  },
                                  expression: "template"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showPayee
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "content-form content-form-modern"
                                },
                                [
                                  _c("h3", { staticClass: "subtitle" }, [
                                    _vm._v("PAYEE INFORMATION")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-row form-split" },
                                    [
                                      _c("form-field-text", {
                                        attrs: {
                                          label: "Payee",
                                          required: "",
                                          disabled: !_vm.updateTemplateFlag,
                                          validator: _vm.$v.template.payee
                                        },
                                        model: {
                                          value: _vm.template.payee,
                                          callback: function($$v) {
                                            _vm.$set(_vm.template, "payee", $$v)
                                          },
                                          expression: "template.payee"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showAddressForm
                            ? [
                                _c("h3", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.template.method === "domestic_wire"
                                        ? "BENEFICIARY ADDRESS"
                                        : "PAYEE ADDRESS"
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.template.method !== "domestic_wire"
                                  ? _c("address-form", {
                                      attrs: {
                                        v: _vm.$v.template.address,
                                        disabledField: !_vm.updateTemplateFlag,
                                        transactionCountries:
                                          _vm.transactionCountries
                                      },
                                      model: {
                                        value: _vm.template.address,
                                        callback: function($$v) {
                                          _vm.$set(_vm.template, "address", $$v)
                                        },
                                        expression: "template.address"
                                      }
                                    })
                                  : _c("address-dom-form", {
                                      attrs: {
                                        v: _vm.$v.template.address,
                                        disabledField: !_vm.updateTemplateFlag,
                                        transactionCountries:
                                          _vm.transactionCountries
                                      },
                                      model: {
                                        value: _vm.template.address,
                                        callback: function($$v) {
                                          _vm.$set(_vm.template, "address", $$v)
                                        },
                                        expression: "template.address"
                                      }
                                    })
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "content-form content-form-modern" },
                            [
                              _vm.showA2AAccountNumber
                                ? _c("h3", { staticClass: "subtitle" }, [
                                    _vm._v("ACCOUNT INFORMATION")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showA2AAccountNumber
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-row form-split acc-to-acc"
                                    },
                                    [
                                      _c("form-field-select", {
                                        attrs: {
                                          label: "Select Linked Account",
                                          validator:
                                            _vm.$v.template.toAccountId,
                                          options: _vm.linkedAccountOptions,
                                          required: "",
                                          disabled: "true"
                                        },
                                        model: {
                                          value: _vm.template.toAccountId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template,
                                              "toAccountId",
                                              $$v
                                            )
                                          },
                                          expression: "template.toAccountId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._m(0),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-row form-split template-name"
                                },
                                [
                                  _vm.newTransFlag
                                    ? _c("form-field-text", {
                                        attrs: {
                                          label: "Memo",
                                          maxlength: 50,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.template.memo,
                                          callback: function($$v) {
                                            _vm.$set(_vm.template, "memo", $$v)
                                          },
                                          expression: "template.memo"
                                        }
                                      })
                                    : _c("form-field-text", {
                                        attrs: {
                                          label: "Memo",
                                          maxlength: 50,
                                          required: "",
                                          disabled: !_vm.updateTemplateFlag
                                        },
                                        model: {
                                          value: _vm.template.memo,
                                          callback: function($$v) {
                                            _vm.$set(_vm.template, "memo", $$v)
                                          },
                                          expression: "template.memo"
                                        }
                                      })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-row form-max" },
                                [
                                  _vm.newTransFlag
                                    ? _c("form-field-text", {
                                        attrs: {
                                          label: "Reference",
                                          maxlength: 60,
                                          required: "",
                                          validator: _vm.$v.template.reference
                                        },
                                        model: {
                                          value: _vm.template.reference,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template,
                                              "reference",
                                              $$v
                                            )
                                          },
                                          expression: "template.reference"
                                        }
                                      })
                                    : _c("form-field-text", {
                                        attrs: {
                                          label: "Reference",
                                          maxlength: 60,
                                          required: "",
                                          disabled: !_vm.updateTemplateFlag,
                                          validator: _vm.$v.template.reference
                                        },
                                        model: {
                                          value: _vm.template.reference,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.template,
                                              "reference",
                                              $$v
                                            )
                                          },
                                          expression: "template.reference"
                                        }
                                      })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {},
                            [
                              _vm.newTransFlag
                                ? [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "main",
                                        on: {
                                          click: function($event) {
                                            return _vm.withdraw()
                                          }
                                        }
                                      },
                                      [_vm._v("Submit")]
                                    )
                                  ]
                                : [
                                    !_vm.updateTemplateFlag
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "main",
                                            on: {
                                              click: function($event) {
                                                _vm.updateTemplateFlag = true
                                              }
                                            }
                                          },
                                          [_vm._v("Update")]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass: "main",
                                            on: {
                                              click: function($event) {
                                                return _vm.updateTemplate()
                                              }
                                            }
                                          },
                                          [_vm._v("Save")]
                                        )
                                  ],
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "second",
                                  on: {
                                    click: function($event) {
                                      _vm.open = false
                                    }
                                  }
                                },
                                [_vm._v("Close")]
                              )
                            ],
                            2
                          )
                        ],
                        2
                      )
                    : _c("div", { staticClass: "scrollable" }, [
                        _vm.template.method != "Asset"
                          ? _c(
                              "div",
                              {
                                staticClass: "content-form content-form-modern"
                              },
                              [
                                _c("div", { staticClass: "form-row" }, [
                                  _vm.transaction
                                    ? _c(
                                        "p",
                                        { staticClass: "transaction-status" },
                                        [
                                          _vm._v(
                                            "Status: " +
                                              _vm._s(_vm.transaction.status)
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "transaction-information" },
                                  [
                                    _vm.transaction
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "transaction-status margin-10"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.withdrawOptionsText) +
                                                " Withdrawal Information"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    this.template.method == "domestic_wire" ||
                                    this.template.method ==
                                      "international_wires"
                                      ? _c("p", { staticClass: "margin-10" }, [
                                          _vm._v(
                                            "Please see below for the details submitted for your withdrawal."
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    this.template.method ==
                                    "international_wires"
                                      ? [
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Bank Name")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.bankName
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Account Name")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.accountName
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Account Number")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .accountNumber
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Account Type")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.accountType
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Bank Swift Code")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(this.template.swift)
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "Intermediary Bank Name"
                                                  )
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .intermediaryBankName
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "Intermediary Bank Reference"
                                                  )
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .intermediaryBankReference
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "Intermediary Bank Routing Number"
                                                  )
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .intermediaryBankRoutingNumber
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "Intermediary Bank SWIFT Code"
                                                  )
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .intermediaryBankSwiftCode
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "INTERMEDIARY BANK ADDRESS"
                                                  )
                                                ])
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Country")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .intermediaryAddress
                                                        .country
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Province/State")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .intermediaryAddress
                                                        .state
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Address")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .intermediaryAddress
                                                        .address
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("City")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .intermediaryAddress
                                                        .city
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Postal")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .intermediaryAddress
                                                        .postal
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("BENEFICIARY ADDRESS")
                                                ])
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("IBAN")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(this.template.iban)
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Country")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .country
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Province/State")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .state
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Address")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .address
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("City")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address.city
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Postal")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .postal
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "ADDITIONAL INFORMATION"
                                                  )
                                                ])
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "Further Credit Account Name"
                                                  )
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .futherCreditAccName
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "Further Credit Account Number"
                                                  )
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .futherCreditAccNumber
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Reference")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.reference
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Memo")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(this.template.memo)
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      : this.template.method == "checks"
                                      ? [
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Amount")]),
                                                _vm._v(
                                                  ": $" +
                                                    _vm._s(
                                                      _vm._f(
                                                        "formattedTwoDecimal"
                                                      )(this.template.amount)
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Payee")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(this.template.payee)
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("PAYEE ADDRESS")
                                                ])
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Country")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .country
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Province/State")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .state
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Address")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .address
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("City")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address.city
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Postal")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .postal
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Reference")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      _vm.transaction.reference
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Memo")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(_vm.transaction.memo)
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      : this.template.method == "ach"
                                      ? [
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Amount")]),
                                                _vm._v(
                                                  ": $" +
                                                    _vm._s(
                                                      _vm._f(
                                                        "formattedTwoDecimal"
                                                      )(this.template.amount)
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Name of Bank Account")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.bankName
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Account Type")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.accountType
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Routing Number")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .routingNumber
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Bank Account Number")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .accountNumber
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Bank Account Type")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .bankAccountType
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Reference")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      _vm.transaction.reference
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Memo")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(_vm.transaction.memo)
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      : this.template.method == "domestic_wire"
                                      ? [
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Account Name")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      _vm.transaction
                                                        .accountName
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Routing Number")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      _vm.transaction
                                                        .routingNumber
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Account Number")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      _vm.transaction
                                                        .accountNumber
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Account Type")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      _vm.transaction
                                                        .accountType
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("BENEFICIARY ADDRESS")
                                                ])
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Country")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .country
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Province/State")
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .state
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Address")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .address
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("City")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address.city
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Postal")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template.address
                                                        .postal
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "ADDITIONAL INFORMATION"
                                                  )
                                                ])
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "Further Credit Account Name"
                                                  )
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .futherCreditAccName
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v(
                                                    "Further Credit Account Number"
                                                  )
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      this.template
                                                        .futherCreditAccNumber
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Reference")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      _vm.transaction.reference
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Memo")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(_vm.transaction.memo)
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      : [
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Amount")]),
                                                _vm._v(
                                                  ": $" +
                                                    _vm._s(
                                                      _vm._f(
                                                        "formattedTwoDecimal"
                                                      )(this.template.amount)
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [
                                                  _vm._v("Account Number")
                                                ]),
                                                _vm._v(
                                                  ":  ****" +
                                                    _vm._s(
                                                      this.template.toAccountId.substr(
                                                        4,
                                                        3
                                                      )
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Reference")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(
                                                      _vm.transaction.reference
                                                    )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.transaction
                                            ? _c("p", [
                                                _c("b", [_vm._v("Memo")]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(_vm.transaction.memo)
                                                )
                                              ])
                                            : _vm._e()
                                        ],
                                    _vm._v(" "),
                                    _vm._m(1)
                                  ],
                                  2
                                )
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "content-form content-form-modern"
                              },
                              [
                                _c("div", { staticClass: "form-row" }, [
                                  _vm.transaction
                                    ? _c(
                                        "p",
                                        { staticClass: "transaction-status" },
                                        [
                                          _vm._v(
                                            "Status: " +
                                              _vm._s(_vm.transaction.status)
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "transaction-information" },
                                  [
                                    _vm.transaction
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "transaction-status margin-10"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.withdrawOptionsText) +
                                                " Withdrawal Information"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Amount")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm._f("assets_balance")(
                                                  _vm.transaction
                                                    .totalAssetAmount
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Account Number")]),
                                          _vm._v(
                                            ": ****" +
                                              _vm._s(
                                                _vm.transaction.toAccountId
                                                  ? _vm.transaction.toAccountId.substr(
                                                      4,
                                                      3
                                                    )
                                                  : ""
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.transaction
                                                .outgoingWalletAddress
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Reference")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(_vm.transaction.reference)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Memo")]),
                                          _vm._v(
                                            ": " + _vm._s(_vm.transaction.memo)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "margin-10" }, [
                                      _c("b", [_vm._v("Note")]),
                                      _vm._v(
                                        ": Send only " +
                                          _vm._s(
                                            _vm.getCurrencyName(
                                              _vm.template.currencyId
                                            )
                                          ) +
                                          " to this wallet address. Any other asset type sent may be lost and unrecoverable."
                                      )
                                    ])
                                  ]
                                )
                              ]
                            ),
                        _vm._v(" "),
                        _c("div", { staticClass: "buttons" }, [
                          _c(
                            "button",
                            {
                              staticClass: "second",
                              on: {
                                click: function($event) {
                                  _vm.open = false
                                }
                              }
                            },
                            [_vm._v("Close")]
                          )
                        ])
                      ])
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.modalType
        ? _c("add-template-panel", {
            attrs: { "modal-type": _vm.modalType },
            on: {
              close: function($event) {
                _vm.modalType = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("templates-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("h3", { staticClass: "subtitle" }, [_vm._v("EXTRA INFORMATION")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "margin-10" }, [
      _c("b", [_vm._v("Note")]),
      _vm._v(
        ": If you have any doubts or questions please verify your instructions with your account representative. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
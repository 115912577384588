var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content-filters" },
          [
            _c("basic-form", [
              _c(
                "div",
                { staticClass: "cof-row" },
                [
                  _c("mnb-input", {
                    attrs: { label: "Email" },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _vm._v(" "),
                  _c("mnb-input", {
                    attrs: { label: "Phone" },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  }),
                  _vm._v(" "),
                  _c("mnb-input", {
                    attrs: { label: "Legal Name" },
                    model: {
                      value: _vm.legal_name,
                      callback: function($$v) {
                        _vm.legal_name = $$v
                      },
                      expression: "legal_name"
                    }
                  }),
                  _vm._v(" "),
                  _c("mnb-input", {
                    attrs: { label: "DBA Name" },
                    model: {
                      value: _vm.dba_name,
                      callback: function($$v) {
                        _vm.dba_name = $$v
                      },
                      expression: "dba_name"
                    }
                  }),
                  _vm._v(" "),
                  _c("mnb-input", {
                    attrs: { label: "SSN" },
                    model: {
                      value: _vm.sin,
                      callback: function($$v) {
                        _vm.sin = $$v
                      },
                      expression: "sin"
                    }
                  }),
                  _vm._v(" "),
                  _c("mnb-input", {
                    attrs: { label: "Tax ID" },
                    model: {
                      value: _vm.tax_id,
                      callback: function($$v) {
                        _vm.tax_id = $$v
                      },
                      expression: "tax_id"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center" }, [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "action-button save",
                        on: {
                          click: function($event) {
                            return _vm.doCheck()
                          }
                        }
                      },
                      [_vm._v("Check")]
                    )
                  ])
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("section", { staticClass: "content-tlist" }, [
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm._l(_vm.registrations, function(item, i) {
                return _c(
                  "tr",
                  { key: i },
                  [
                    [
                      _c("td", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/system-customers/registrations/" + item.id
                            }
                          },
                          [_vm._v(_vm._s(item.profileNumber || item.regId))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/system-customers/registrations/" + item.id
                            }
                          },
                          [_vm._v(_vm._s(item.regId))]
                        )
                      ])
                    ]
                  ],
                  2
                )
              })
            ],
            2
          )
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Profile ID")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Registration ID")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { open: _vm.open },
      on: {
        "update:open": function($event) {
          _vm.open = $event
        }
      }
    },
    [
      _c("h2", [_vm._v("Create New Employee")]),
      _vm._v(" "),
      _c("div", { staticClass: "pf-row split" }, [
        _c(
          "div",
          { staticClass: "pf-field" },
          [
            _c("form-field-select", {
              attrs: {
                validator: _vm.$v.item.role,
                label: "Role",
                options: _vm.roleOptions,
                required: ""
              },
              model: {
                value: _vm.item.role,
                callback: function($$v) {
                  _vm.$set(_vm.item, "role", $$v)
                },
                expression: "item.role"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pf-row split" }, [
        _c(
          "div",
          { staticClass: "pf-field" },
          [
            _c("form-field-text", {
              attrs: {
                label: "First Name",
                validator: _vm.$v.item.firstName,
                maxlength: 25,
                required: ""
              },
              model: {
                value: _vm.item.firstName,
                callback: function($$v) {
                  _vm.$set(_vm.item, "firstName", $$v)
                },
                expression: "item.firstName"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pf-field" },
          [
            _c("form-field-text", {
              attrs: {
                label: "Last Name",
                validator: _vm.$v.item.lastName,
                maxlength: 25,
                required: ""
              },
              model: {
                value: _vm.item.lastName,
                callback: function($$v) {
                  _vm.$set(_vm.item, "lastName", $$v)
                },
                expression: "item.lastName"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pf-row split" }, [
        _c(
          "div",
          { staticClass: "pf-field" },
          [
            _c("form-field-text", {
              attrs: {
                validator: _vm.$v.item.email,
                label: "Email",
                required: "",
                maxlength: 50
              },
              model: {
                value: _vm.item.email,
                callback: function($$v) {
                  _vm.$set(_vm.item, "email", $$v)
                },
                expression: "item.email"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pf-field" },
          [
            _c("form-field-text", {
              attrs: {
                validator: _vm.$v.item.phone,
                label: "Phone",
                maxlength: 20,
                required: ""
              },
              model: {
                value: _vm.item.phone,
                callback: function($$v) {
                  _vm.$set(_vm.item, "phone", $$v)
                },
                expression: "item.phone"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.duplicateEmail
        ? _c("div", { staticClass: "pf-row split error" }, [
            _vm._v("\n      Email address already exists!\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "label",
        { staticStyle: { "margin-bottom": "10px", display: "block" } },
        [_vm._v("Permissions")]
      ),
      _vm._v(" "),
      _c("permissions", {
        attrs: { role: _vm.item.role, noButtons: true },
        model: {
          value: _vm.item.perms,
          callback: function($$v) {
            _vm.$set(_vm.item, "perms", $$v)
          },
          expression: "item.perms"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "pf-row buttons" }, [
        _c(
          "a",
          {
            staticClass: "pop-btn",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.open = false
              }
            }
          },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "pop-btn",
            on: {
              click: function($event) {
                return _vm.showConfirm()
              }
            }
          },
          [_vm._v("Create")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _vm.perms
          ? [
              _c(
                "div",
                { staticClass: "content-filters" },
                [
                  _c("basic-form", [
                    _c("div", { staticClass: "cof-row dates" }, [
                      _c("div", { staticClass: "cof-field" }, [
                        _c("label", [_vm._v("Start Date")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cof-multi flex" },
                          [
                            _c("date-time-picker", {
                              attrs: {
                                "value-type": "String",
                                "value-format": "yyyy-LL-dd",
                                format: "yyyy-LL-dd",
                                "time-picker": false,
                                "auto-close": true,
                                "min-date": "2021-09-01"
                              },
                              model: {
                                value: _vm.form.fromDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "fromDate", $$v)
                                },
                                expression: "form.fromDate"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "cof-field" }, [
                        _c("label", [_vm._v("End Date")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cof-multi flex" },
                          [
                            _c("date-time-picker", {
                              attrs: {
                                "value-type": "String",
                                "value-format": "yyyy-LL-dd",
                                format: "yyyy-LL-dd",
                                "time-picker": false,
                                "auto-close": true,
                                "min-date": "2021-09-01",
                                "max-date": _vm.date2
                              },
                              model: {
                                value: _vm.form.toDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "toDate", $$v)
                                },
                                expression: "form.toDate"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.toDateError
                          ? _c("div", { staticClass: "error-message" }, [
                              _c("span", [_vm._v("Invalid End Date.")])
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cof-row buttons" }, [
                      _c("div", { staticClass: "cof-field" }, [
                        _c("label", [_vm._v(" ")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            attrs: {
                              disabled:
                                _vm.toDateError ||
                                _vm.form.fromDate == null ||
                                _vm.form.toDate == null
                            },
                            on: {
                              click: function($event) {
                                return _vm.refreshTransactions()
                              }
                            }
                          },
                          [_vm._v("Generate")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "cof-field" }, [
                        _c("label", [_vm._v(" ")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                return _vm.reset()
                              }
                            }
                          },
                          [_vm._v("Reset")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cof-row run" }, [
                      _c("div", { staticClass: "cof-field" }, [
                        _c("label", [_vm._v(" ")]),
                        _vm._v(" "),
                        _vm.isRun
                          ? _c(
                              "a",
                              {
                                staticClass: "btn main",
                                attrs: {
                                  href:
                                    "/export_fees_report/" +
                                    _vm.form.fromDate +
                                    "/" +
                                    _vm.form.toDate,
                                  target: "_blank"
                                }
                              },
                              [_vm._v("Export")]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("section", { staticClass: "content-tlist" }, [
                _c(
                  "table",
                  { staticClass: "content-table" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._l(_vm.filteredData, function(item, i) {
                      return _c("tr", { key: i }, [
                        _c("td", [
                          _c("span", [_vm._v("Date")]),
                          _vm._v(
                            _vm._s(
                              _vm._f("date")(
                                item.createdAt,
                                "MM/D/YYYY hh:mm A"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Account")]),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/system-customers/registrations/" +
                                  item.regId +
                                  "?tab=Accounts"
                              }
                            },
                            [_vm._v(_vm._s(item.accountId))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Account")]),
                          _vm._v(_vm._s(item.transactionId))
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Entry")]),
                          _vm._v(_vm._s(item.entryType))
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Amount")]),
                            _vm._v(" "),
                            item.currencyType == "Assets"
                              ? [_vm._v(_vm._s(parseFloat(item.assetAmount)))]
                              : [
                                  _vm._v(
                                    _vm._s(item.currencySymbol) +
                                      " " +
                                      _vm._s(
                                        _vm._f("balance_without_symbol")(
                                          item.amount
                                        )
                                      )
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Reference")]),
                          _vm._v(_vm._s(item.reference))
                        ])
                      ])
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              !_vm.loading
                ? _c("section", { staticClass: "pagination-section" }, [
                    _c("div", [
                      _vm._v("\n          Per page\n          "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.perPage,
                              expression: "perPage"
                            }
                          ],
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.perPage = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.setPages()
                              }
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { value: "25" } }, [
                            _vm._v("25")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "50" } }, [
                            _vm._v("50")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "75" } }, [
                            _vm._v("75")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "100" } }, [
                            _vm._v("100")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "150" } }, [
                            _vm._v("150")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "200" } }, [
                            _vm._v("200")
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "nav",
                      { attrs: { "aria-label": "Page navigation example" } },
                      [
                        _c("ul", { staticClass: "pagination" }, [
                          _c("li", { staticClass: "page-item" }, [
                            _vm.page != 1
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "page-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.page--
                                      }
                                    }
                                  },
                                  [_vm._v(" Previous ")]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "page-item" },
                            _vm._l(
                              _vm.pages.slice(_vm.page - 1, _vm.page + 5),
                              function(pageNumber) {
                                return _c(
                                  "button",
                                  {
                                    key: pageNumber,
                                    staticClass: "page-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.page = pageNumber
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(pageNumber) + " ")]
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c("li", { staticClass: "page-item" }, [
                            _vm.page < _vm.pages.length
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "page-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.page++
                                      }
                                    }
                                  },
                                  [_vm._v(" Next ")]
                                )
                              : _vm._e()
                          ])
                        ])
                      ]
                    )
                  ])
                : _vm._e()
            ]
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Account")]),
      _vm._v(" "),
      _c("th", [_vm._v("Transaction")]),
      _vm._v(" "),
      _c("th", [_vm._v("Entry")]),
      _vm._v(" "),
      _c("th", [_vm._v("Amount")]),
      _vm._v(" "),
      _c("th", [_vm._v("Reference")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      void 0,
      _vm._v(" "),
      [
        [
          _c(
            "div",
            { staticClass: "content-tlist", staticStyle: { padding: "30px" } },
            [
              _c(
                "basic-form",
                [
                  _c("label", [_vm._v("Maintenance Message")]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.systemStatus.maintenanceMessage,
                        expression: "systemStatus.maintenanceMessage"
                      }
                    ],
                    staticClass: "fullw",
                    attrs: { rows: "10", disabled: !_vm.updateInformations },
                    domProps: { value: _vm.systemStatus.maintenanceMessage },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.systemStatus,
                          "maintenanceMessage",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Section 1 ")]),
                  _vm._v(" "),
                  _c("mnb-input", {
                    attrs: {
                      label: "Category",
                      maxlength: 150,
                      disabled: !_vm.updateInformations
                    },
                    model: {
                      value: _vm.systemStatus.section1Name,
                      callback: function($$v) {
                        _vm.$set(_vm.systemStatus, "section1Name", $$v)
                      },
                      expression: "systemStatus.section1Name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content-tlist" }, [
                _c(
                  "table",
                  { staticClass: "content-table" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm._l(_vm.systemStatus.section1Items, function(item, i) {
                      return _c("tr", [
                        _c(
                          "td",
                          [
                            _vm.updateInformations
                              ? [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.name,
                                        expression: "item.name"
                                      }
                                    ],
                                    staticClass: "split",
                                    attrs: { type: "text" },
                                    domProps: { value: item.name },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          item,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              : [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.name) +
                                      "\n                "
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _vm.updateInformations
                              ? [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.message,
                                        expression: "item.message"
                                      }
                                    ],
                                    staticClass: "split",
                                    attrs: { type: "text" },
                                    domProps: { value: item.message },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          item,
                                          "message",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              : [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.message) +
                                      "\n                "
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _vm.updateInformations
                              ? [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.color,
                                          expression: "item.color"
                                        }
                                      ],
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            item,
                                            "color",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "green" } },
                                        [_vm._v("Green")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "yellow" } },
                                        [_vm._v("Yellow")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "red" } },
                                        [_vm._v("Red")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "black" } },
                                        [_vm._v("Black")]
                                      )
                                    ]
                                  )
                                ]
                              : [
                                  _c("div", {
                                    staticClass: "color",
                                    class: item.color
                                  })
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _vm.updateInformations
                              ? [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.downtime,
                                          expression: "item.downtime"
                                        }
                                      ],
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            item,
                                            "downtime",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "Operational" } },
                                        [_vm._v("Operational")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Maintenance" } },
                                        [_vm._v("Maintenance")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Down" } },
                                        [_vm._v("Down")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Offline" } },
                                        [_vm._v("Offline")]
                                      )
                                    ]
                                  )
                                ]
                              : [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.downtime) +
                                      "\n                "
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm.updateInformations
                            ? _c(
                                "button",
                                {
                                  staticClass: "button-handle cancel",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeStatusItem1(i)
                                    }
                                  }
                                },
                                [_vm._v("Remove")]
                              )
                            : _vm._e()
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.itemList1, function(item, i) {
                      return _c("tr", { key: i }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.name,
                                expression: "item.name"
                              }
                            ],
                            staticClass: "split",
                            attrs: { type: "text" },
                            domProps: { value: item.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "name", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.message,
                                expression: "item.message"
                              }
                            ],
                            staticClass: "split",
                            attrs: { type: "text" },
                            domProps: { value: item.message },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "message", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.color,
                                  expression: "item.color"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    item,
                                    "color",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "green" } }, [
                                _vm._v("Green")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "yellow" } }, [
                                _vm._v("Yellow")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "red" } }, [
                                _vm._v("Red")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "black" } }, [
                                _vm._v("Black")
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.downtime,
                                  expression: "item.downtime"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    item,
                                    "downtime",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "Operational" } },
                                [_vm._v("Operational")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "Maintenance" } },
                                [_vm._v("Maintenance")]
                              ),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "Down" } }, [
                                _vm._v("Down")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "Offline" } }, [
                                _vm._v("Offline")
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "button-handle cancel",
                              on: {
                                click: function($event) {
                                  return _vm.removeItem1(i)
                                }
                              }
                            },
                            [_vm._v("Remove")]
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("basic-form", [
                _c(
                  "button",
                  {
                    staticClass: "main",
                    staticStyle: { "margin-left": "30px" },
                    attrs: { disabled: !_vm.updateInformations },
                    on: {
                      click: function($event) {
                        return _vm.addItem1()
                      }
                    }
                  },
                  [_vm._v("+ Add Item")]
                )
              ]),
              _vm._v(" "),
              _c(
                "basic-form",
                [
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Section 2 ")]),
                  _vm._v(" "),
                  _c("mnb-input", {
                    attrs: {
                      label: "Category",
                      maxlength: 150,
                      disabled: !_vm.updateInformations
                    },
                    model: {
                      value: _vm.systemStatus.section2Name,
                      callback: function($$v) {
                        _vm.$set(_vm.systemStatus, "section2Name", $$v)
                      },
                      expression: "systemStatus.section2Name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content-tlist" }, [
                _c(
                  "table",
                  { staticClass: "content-table" },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _vm._l(_vm.systemStatus.section2Items, function(item, i) {
                      return _c("tr", [
                        _c(
                          "td",
                          [
                            _vm.updateInformations
                              ? [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.name,
                                        expression: "item.name"
                                      }
                                    ],
                                    staticClass: "split",
                                    attrs: { type: "text" },
                                    domProps: { value: item.name },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          item,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              : [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.name) +
                                      "\n                "
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _vm.updateInformations
                              ? [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.message,
                                        expression: "item.message"
                                      }
                                    ],
                                    staticClass: "split",
                                    attrs: { type: "text" },
                                    domProps: { value: item.message },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          item,
                                          "message",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              : [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.message) +
                                      "\n                "
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _vm.updateInformations
                              ? [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.color,
                                          expression: "item.color"
                                        }
                                      ],
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            item,
                                            "color",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "green" } },
                                        [_vm._v("Green")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "yellow" } },
                                        [_vm._v("Yellow")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "red" } },
                                        [_vm._v("Red")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "black" } },
                                        [_vm._v("Black")]
                                      )
                                    ]
                                  )
                                ]
                              : [
                                  _c("div", {
                                    staticClass: "color",
                                    class: item.color
                                  })
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _vm.updateInformations
                              ? [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.downtime,
                                          expression: "item.downtime"
                                        }
                                      ],
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            item,
                                            "downtime",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "Operational" } },
                                        [_vm._v("Operational")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Maintenance" } },
                                        [_vm._v("Maintenance")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Down" } },
                                        [_vm._v("Down")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Offline" } },
                                        [_vm._v("Offline")]
                                      )
                                    ]
                                  )
                                ]
                              : [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.downtime) +
                                      "\n                "
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm.updateInformations
                            ? _c(
                                "button",
                                {
                                  staticClass: "button-handle cancel",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeStatusItem2(i)
                                    }
                                  }
                                },
                                [_vm._v("Remove")]
                              )
                            : _vm._e()
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.itemList2, function(item, i) {
                      return _c("tr", { key: i }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.name,
                                expression: "item.name"
                              }
                            ],
                            staticClass: "split",
                            attrs: { type: "text" },
                            domProps: { value: item.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "name", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.message,
                                expression: "item.message"
                              }
                            ],
                            staticClass: "split",
                            attrs: { type: "text" },
                            domProps: { value: item.message },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "message", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.color,
                                  expression: "item.color"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    item,
                                    "color",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "green" } }, [
                                _vm._v("Green")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "yellow" } }, [
                                _vm._v("Yellow")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "red" } }, [
                                _vm._v("Red")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "black" } }, [
                                _vm._v("Black")
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.downtime,
                                  expression: "item.downtime"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    item,
                                    "downtime",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "Operational" } },
                                [_vm._v("Operational")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "Maintenance" } },
                                [_vm._v("Maintenance")]
                              ),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "Down" } }, [
                                _vm._v("Down")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "Offline" } }, [
                                _vm._v("Offline")
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "button-handle cancel",
                              on: {
                                click: function($event) {
                                  return _vm.removeItem2(i)
                                }
                              }
                            },
                            [_vm._v("Remove")]
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("basic-form", [
                _c(
                  "button",
                  {
                    staticClass: "main",
                    staticStyle: { "margin-left": "30px" },
                    attrs: { disabled: !_vm.updateInformations },
                    on: {
                      click: function($event) {
                        return _vm.addItem2()
                      }
                    }
                  },
                  [_vm._v("+ Add Item")]
                )
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "basic-form",
                { staticStyle: { "text-align": "center" } },
                [
                  _vm.updateInformations
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "main",
                            on: {
                              click: function($event) {
                                return _vm.save()
                              }
                            }
                          },
                          [_vm._v("Submit")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                return _vm.cancel()
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ]
                    : [
                        _c(
                          "button",
                          {
                            staticClass: "main",
                            on: {
                              click: function($event) {
                                _vm.updateInformations = true
                              }
                            }
                          },
                          [_vm._v("Update")]
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ]
      ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Item Message")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Color")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Downtime")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Item Message")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Color")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Downtime")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-form" }, [
      _c(
        "div",
        { staticClass: "form-row form-corporate" },
        [
          _c("form-field-select", {
            attrs: {
              validator: _vm.v.type,
              label: "Contact Type",
              options: _vm.contactTypeOptions,
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.type,
              callback: function($$v) {
                _vm.$set(_vm.form, "type", $$v)
              },
              expression: "form.type"
            }
          }),
          _vm._v(" "),
          _c("form-field-select", {
            attrs: {
              validator: _vm.v.sex,
              label: "Sex",
              options: { Male: "Male", Female: "Female" },
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.sex,
              callback: function($$v) {
                _vm.$set(_vm.form, "sex", $$v)
              },
              expression: "form.sex"
            }
          }),
          _vm._v(" "),
          _c("form-field-date", {
            attrs: {
              dirty: _vm.v.$dirty,
              label: "Date of Birth",
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.dob,
              callback: function($$v) {
                _vm.$set(_vm.form, "dob", $$v)
              },
              expression: "form.dob"
            }
          }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              maxlength: 25,
              validator: _vm.v.firstName,
              label: "First Name",
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.firstName,
              callback: function($$v) {
                _vm.$set(_vm.form, "firstName", $$v)
              },
              expression: "form.firstName"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-corporate" },
        [
          _c("form-field-text", {
            attrs: {
              maxlength: 25,
              validator: _vm.v.lastName,
              label: "Last Name",
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.lastName,
              callback: function($$v) {
                _vm.$set(_vm.form, "lastName", $$v)
              },
              expression: "form.lastName"
            }
          }),
          _vm._v(" "),
          _c("form-field-select", {
            attrs: {
              validator: _vm.v.country,
              label: "Country",
              options: _vm.countryOptions,
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.country,
              callback: function($$v) {
                _vm.$set(_vm.form, "country", $$v)
              },
              expression: "form.country"
            }
          }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              validator: _vm.v.city,
              label: "City",
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.city,
              callback: function($$v) {
                _vm.$set(_vm.form, "city", $$v)
              },
              expression: "form.city"
            }
          }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              label: "Address",
              validator: _vm.v.address,
              maxlength: 50,
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.address,
              callback: function($$v) {
                _vm.$set(_vm.form, "address", $$v)
              },
              expression: "form.address"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-corporate" },
        [
          _c("form-field-text", {
            attrs: {
              label: "Address 2",
              maxlength: 50,
              disabled: _vm.disabled
            },
            model: {
              value: _vm.form.address2,
              callback: function($$v) {
                _vm.$set(_vm.form, "address2", $$v)
              },
              expression: "form.address2"
            }
          }),
          _vm._v(" "),
          _vm.stateOptions
            ? _c("form-field-select", {
                attrs: {
                  validator: _vm.v.state,
                  label: "State/Province",
                  options: _vm.stateOptions,
                  disabled: _vm.disabled,
                  required: ""
                },
                model: {
                  value: _vm.form.state,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "state", $$v)
                  },
                  expression: "form.state"
                }
              })
            : _c("form-field-text", {
                attrs: {
                  validator: _vm.v.state,
                  label: "State/Province",
                  disabled: _vm.disabled,
                  required: ""
                },
                model: {
                  value: _vm.form.state,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "state", $$v)
                  },
                  expression: "form.state"
                }
              }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              label: "ZIP / Postal Code",
              validator: _vm.v.zip,
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.zip,
              callback: function($$v) {
                _vm.$set(_vm.form, "zip", $$v)
              },
              expression: "form.zip"
            }
          }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              maxlength: 15,
              validator: _vm.v.phone,
              label: "Phone Number",
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.phone,
              callback: function($$v) {
                _vm.$set(_vm.form, "phone", $$v)
              },
              expression: "form.phone"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-corporate" },
        [
          _c("form-field-text", {
            attrs: {
              maxlength: 15,
              validator: _vm.v.mobile,
              label: "Mobile Number",
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.mobile,
              callback: function($$v) {
                _vm.$set(_vm.form, "mobile", $$v)
              },
              expression: "form.mobile"
            }
          }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              validator: _vm.v.email,
              label: "Email Address",
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.email,
              callback: function($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email"
            }
          }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              maxlength: 20,
              validator: _vm.v.passport,
              label: "Passport Number",
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.passport,
              callback: function($$v) {
                _vm.$set(_vm.form, "passport", $$v)
              },
              expression: "form.passport"
            }
          }),
          _vm._v(" "),
          _c("form-field-select", {
            attrs: {
              validator: _vm.v.id_type,
              label: "Government Issued ID Type",
              options: {
                "Drivers License": "Drivers License",
                "Citizenship Card": "Citizenship Card",
                "Government ID": "Government ID"
              },
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.id_type,
              callback: function($$v) {
                _vm.$set(_vm.form, "id_type", $$v)
              },
              expression: "form.id_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-row form-corporate",
          staticStyle: { "justify-content": "flex-start" }
        },
        [
          _c("form-field-text", {
            attrs: {
              maxlength: 20,
              validator: _vm.v.id_number,
              label: "Government Issued ID Number",
              disabled: _vm.disabled,
              required: ""
            },
            model: {
              value: _vm.form.id_number,
              callback: function($$v) {
                _vm.$set(_vm.form, "id_number", $$v)
              },
              expression: "form.id_number"
            }
          }),
          _vm._v(" "),
          _vm.disabled
            ? _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      _vm.open = true
                    }
                  }
                },
                [
                  _vm.showSin
                    ? _c("form-field-text", {
                        attrs: {
                          maxlength: 20,
                          validator: _vm.v.sin,
                          label: "SSN/Fiscal Number",
                          required: "",
                          disabled: _vm.disabled
                        },
                        model: {
                          value: _vm.form.sin,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sin", $$v)
                          },
                          expression: "form.sin"
                        }
                      })
                    : _c("form-field-text", {
                        attrs: {
                          maxlength: 20,
                          validator: _vm.v.sin,
                          label: "SSN/Fiscal Number",
                          required: "",
                          disabled: _vm.disabled
                        },
                        model: {
                          value: _vm.hiddenSin,
                          callback: function($$v) {
                            _vm.hiddenSin = $$v
                          },
                          expression: "hiddenSin"
                        }
                      })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disabled
            ? _c(
                "div",
                [
                  !_vm.showEditSin
                    ? _c(
                        "div",
                        {
                          on: {
                            mousedown: function($event) {
                              return _vm.editSin()
                            }
                          }
                        },
                        [
                          _c("form-field-text", {
                            attrs: {
                              maxlength: 20,
                              validator: _vm.v.sin,
                              label: "SSN/Fiscal Number",
                              required: "",
                              disabled: _vm.disabled
                            },
                            model: {
                              value: _vm.hiddenSin,
                              callback: function($$v) {
                                _vm.hiddenSin = $$v
                              },
                              expression: "hiddenSin"
                            }
                          })
                        ],
                        1
                      )
                    : _c("form-field-text", {
                        attrs: {
                          maxlength: 20,
                          validator: _vm.v.sin,
                          label: "SSN/Fiscal Number",
                          required: "",
                          disabled: _vm.disabled
                        },
                        model: {
                          value: _vm.form.sin,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sin", $$v)
                          },
                          expression: "form.sin"
                        }
                      })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.type === "Beneficial Owner"
            ? _c(
                "div",
                { staticStyle: { "margin-left": "7px" } },
                [
                  _c("form-field-text", {
                    attrs: {
                      validator: _vm.v.ownership_percentage,
                      label: "Ownership Percentage",
                      required: "",
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.form.ownership_percentage,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "ownership_percentage", $$v)
                      },
                      expression: "form.ownership_percentage"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "popup", class: { open: _vm.open } }, [
      _c("div", { staticClass: "pop-inner", style: { maxWidth: "400px" } }, [
        _c("div", { staticClass: "pop-box" }, [
          _c("h2", [_vm._v("Enter Management PIN to Continue")]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row" }, [
            _c("div", { staticClass: "pf-field" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pin,
                    expression: "pin"
                  }
                ],
                staticClass: "pin-code",
                attrs: {
                  oninput:
                    "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                  type: "number",
                  maxlength: "4"
                },
                domProps: { value: _vm.pin },
                on: {
                  keyup: _vm.pinInput,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.pin = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row" }, [
            _c(
              "div",
              { staticClass: "pf-field" },
              [_vm.pinFailed ? [_vm._v("Invalid PIN")] : _vm._e()],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row buttons" }, [
            _c(
              "a",
              {
                staticClass: "pop-btn",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.cancelPin()
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "pop-btn",
                on: {
                  click: function($event) {
                    return _vm.checkPin()
                  }
                }
              },
              [_vm._v("Confirm")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c("section", { staticClass: "content-tlist" }, [
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm._l(_vm.registrations, function(registration) {
                return _c("tr", { key: registration.id }, [
                  _c("td", [
                    _c("span", [_vm._v("Registered Date")]),
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(registration.createdAt, "MM/D/YYYY")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Updated Date")]),
                      registration.updatedAt
                        ? [
                            _vm._v(
                              _vm._s(
                                _vm._f("date")(
                                  registration.updatedAt,
                                  "MM/D/YYYY"
                                )
                              )
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Approved Date")]),
                      registration.approvalDate
                        ? [
                            _vm._v(
                              _vm._s(
                                _vm._f("date")(
                                  registration.approvalDate,
                                  "MM/D/YYYY"
                                )
                              )
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Profile")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "/system-customers/registrations/" + registration.id
                        }
                      },
                      [_vm._v(_vm._s(registration.profileNumber))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Profile Type")]),
                    _vm._v(_vm._s(registration.type))
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Name")]),
                      _vm._v(" "),
                      registration.type === "Personal"
                        ? [
                            _vm._v(
                              _vm._s(registration.data.firstName) +
                                " " +
                                _vm._s(registration.data.lastName)
                            )
                          ]
                        : [_vm._v(_vm._s(registration.data.legal_name))]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Status")]),
                    _c(
                      "em",
                      { class: _vm.statusColorClasses[registration.status] },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              registration.status === "Complete"
                                ? "Activation"
                                : registration.status
                            )
                        )
                      ]
                    )
                  ])
                ])
              }),
              _vm._v(" "),
              !_vm.registrations.length
                ? _c("tr", [
                    _c(
                      "td",
                      { staticClass: "no-data", attrs: { colspan: "7" } },
                      [_vm._v("No data")]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "content-filters", staticStyle: { margin: "0 0 30px 0" } },
      [
        _c("div", { staticClass: "flex-between" }, [
          _c("h2", { staticClass: "inner-headers" }, [
            _vm._v("Customer Profiles")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Registered")]),
      _vm._v(" "),
      _c("th", [_vm._v("Updated")]),
      _vm._v(" "),
      _c("th", [_vm._v("Approved")]),
      _vm._v(" "),
      _c("th", [_vm._v("Profile")]),
      _vm._v(" "),
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("section", { staticClass: "content-filters" }, [
      _c("div", { staticClass: "cof-box" }, [
        _c("a", { staticClass: "tsm-handle", attrs: { href: "" } }, [
          _vm._v("Filters")
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("Issue Report Search")]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-form" }, [
          _c("a", { staticClass: "cof-close" }),
          _vm._v(" "),
          _c("h6", [_vm._v("Issue Search")]),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _c(
              "div",
              { staticClass: "cof-field sysint-page" },
              [
                _c("mnb-select", {
                  attrs: { options: _vm.statusOptions, label: "Status" },
                  model: {
                    value: _vm.form.status,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _c("div", { staticClass: "cof-field mobinl" }, [
              _c("label", [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "main",
                  on: {
                    click: function($event) {
                      return _vm.search()
                    }
                  }
                },
                [_vm._v("Search")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cof-field mobinl" }, [
              _c("label", [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "second",
                  on: {
                    click: function($event) {
                      return _vm.resetItem()
                    }
                  }
                },
                [_vm._v("Reset Filters")]
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content-tlist" }, [
      _c(
        "table",
        { staticClass: "content-table" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm._l(_vm.filteredData, function(item, i) {
            return _c("tr", { key: i }, [
              _c("td", [
                _c("span", [_vm._v("Date")]),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("date")(item.createdAt, "YYYY-MM-D"))
                ),
                _c("br"),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("date")(item.createdAt, "hh:mm A")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("span", [_vm._v("Updated")]),
                  _vm._v(" "),
                  item.updatedAt
                    ? [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("date")(item.updatedAt, "YYYY-MM-D"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("date")(item.updatedAt, "hh:mm A")) +
                            "\n          "
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("td", [
                _c("span", [_vm._v("Name")]),
                _vm._v(_vm._s(item.name))
              ]),
              _vm._v(" "),
              _c("td", [
                _c("span", [_vm._v("Email")]),
                _vm._v(_vm._s(item.emailAddress))
              ]),
              _vm._v(" "),
              _c("td", [
                _c("span", [_vm._v("Account")]),
                _vm._v(_vm._s(item.accountNumber))
              ]),
              _vm._v(" "),
              _c("td", [
                _c("span", [_vm._v("File")]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "link",
                    attrs: {
                      target: "_blank",
                      href: "files/issues/" + item.id + "/" + item.file
                    }
                  },
                  [_vm._v(_vm._s(item.file))]
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _c("span", [_vm._v("Description")]),
                _vm._v(_vm._s(item.issueDescription))
              ]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("span", [_vm._v("Status")]),
                  _vm._v(" "),
                  _vm.isEdit != item.id
                    ? [
                        _c(
                          "strong",
                          { class: _vm.statusColorClasses[item.status] },
                          [_vm._v(_vm._s(item.status))]
                        )
                      ]
                    : _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.status,
                              expression: "item.status"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                item,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "Pending" } }, [
                            _vm._v("Pending")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "In Process" } }, [
                            _vm._v("In Process")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "Completed" } }, [
                            _vm._v("Completed")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "Closed" } }, [
                            _vm._v("Closed")
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "Archived", disabled: "" } },
                            [_vm._v("Archived")]
                          )
                        ]
                      )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("span", [_vm._v("Options")]),
                  _vm._v(" "),
                  _vm.isEdit == item.id
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "action-button save",
                            on: {
                              click: function($event) {
                                return _vm.saveUpdate(item)
                              }
                            }
                          },
                          [_vm._v("Save")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "action-button cancel",
                            on: {
                              click: function($event) {
                                return _vm.cancelEdit(item)
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ]
                    : [
                        _c(
                          "button",
                          {
                            staticClass: "action-button update",
                            on: {
                              click: function($event) {
                                return _vm.update(item)
                              }
                            }
                          },
                          [_vm._v("Status")]
                        ),
                        _vm._v(" "),
                        item.status == "Closed"
                          ? _c(
                              "button",
                              {
                                staticClass: "action-button cancel",
                                on: {
                                  click: function($event) {
                                    return _vm.archive(item)
                                  }
                                }
                              },
                              [_vm._v("Archive")]
                            )
                          : _vm._e()
                      ]
                ],
                2
              )
            ])
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    !_vm.loading
      ? _c("section", { staticClass: "pagination-section" }, [
          _c("div", [
            _vm._v("\n      Per page\n      "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.perPage,
                    expression: "perPage"
                  }
                ],
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.perPage = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function($event) {
                      return _vm.setPages()
                    }
                  ]
                }
              },
              [
                _c("option", { attrs: { value: "25" } }, [_vm._v("25")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "50" } }, [_vm._v("50")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "75" } }, [_vm._v("75")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "100" } }, [_vm._v("100")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "150" } }, [_vm._v("150")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "200" } }, [_vm._v("200")])
              ]
            )
          ]),
          _vm._v(" "),
          _c("nav", { attrs: { "aria-label": "Page navigation example" } }, [
            _c("ul", { staticClass: "pagination" }, [
              _c("li", { staticClass: "page-item" }, [
                _vm.page != 1
                  ? _c(
                      "button",
                      {
                        staticClass: "page-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.page--
                          }
                        }
                      },
                      [_vm._v(" Previous ")]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "page-item" },
                _vm._l(_vm.pages.slice(_vm.page - 1, _vm.page + 5), function(
                  pageNumber
                ) {
                  return _c(
                    "button",
                    {
                      key: pageNumber,
                      staticClass: "page-link",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.page = pageNumber
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(pageNumber) + " ")]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("li", { staticClass: "page-item" }, [
                _vm.page < _vm.pages.length
                  ? _c(
                      "button",
                      {
                        staticClass: "page-link",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.page++
                          }
                        }
                      },
                      [_vm._v(" Next ")]
                    )
                  : _vm._e()
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Date")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Updated")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Email")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Account")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "8%" } }, [_vm._v("File")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Description")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "7%" } }, [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "13%" } }, [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
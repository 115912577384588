var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cp-box" },
    [
      _c(
        "ul",
        { staticClass: "sub-tabs", staticStyle: { "margin-bottom": "12px" } },
        [
          _c(
            "li",
            {
              class: { active: _vm.activeTab === "general" },
              on: {
                click: function($event) {
                  _vm.activeTab = "general"
                }
              }
            },
            [_c("a", [_vm._v("General")])]
          ),
          _vm._v(" "),
          _vm.partner &&
          (_vm.partner.status == "New" || _vm.partner.status == "Denied")
            ? _c(
                "li",
                {
                  class: { active: _vm.activeTab === "boarding" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "boarding"
                    }
                  }
                },
                [_c("a", [_vm._v("Boarding")])]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.$hasPerm("update-profile") ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.activeTab === "general"
        ? _c("general", { attrs: { id: _vm.id } })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab === "boarding"
        ? _c("setup-checklist", {
            attrs: { partner: _vm.partner, noUpdate: true }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "cp-box" }, [
      _c("div", { staticClass: "cof-field mobinl" }, [
        _c("label", [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "main",
            on: {
              click: function($event) {
                return _vm.addNewDocument()
              }
            }
          },
          [_vm._v("Add New")]
        )
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
        [
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _vm._m(1),
              _vm._v(" "),
              _vm._l(_vm.documents, function(document, i) {
                return _c("tr", { key: document.id }, [
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Description")]),
                      _vm._v(" "),
                      _vm.isEdit(i)
                        ? _c("mnb-input", {
                            model: {
                              value: _vm.editingForms[i].description,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editingForms[i],
                                  "description",
                                  $$v
                                )
                              },
                              expression: "editingForms[i].description"
                            }
                          })
                        : [
                            _vm._v(
                              "\n              " +
                                _vm._s(document.description) +
                                "\n            "
                            )
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Type")]),
                      _vm._v(" "),
                      _vm.isEdit(i)
                        ? _c("mnb-input", {
                            model: {
                              value: _vm.editingForms[i].type,
                              callback: function($$v) {
                                _vm.$set(_vm.editingForms[i], "type", $$v)
                              },
                              expression: "editingForms[i].type"
                            }
                          })
                        : [
                            _vm._v(
                              "\n              " +
                                _vm._s(document.type) +
                                "\n            "
                            )
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Number")]),
                      _vm._v(" "),
                      _vm.isEdit(i)
                        ? _c("mnb-input", {
                            model: {
                              value: _vm.editingForms[i].number,
                              callback: function($$v) {
                                _vm.$set(_vm.editingForms[i], "number", $$v)
                              },
                              expression: "editingForms[i].number"
                            }
                          })
                        : [
                            _vm._v(
                              "\n              " +
                                _vm._s(document.number) +
                                "\n            "
                            )
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Filename")]),
                      _vm._v(" "),
                      _vm.isEdit(i)
                        ? _c("form-field-upload", {
                            staticStyle: { width: "173px" },
                            attrs: {
                              label: "",
                              classes: "add-document",
                              btnClasses: "add-document-btn-upload"
                            },
                            model: {
                              value: _vm.editingForms[i].file,
                              callback: function($$v) {
                                _vm.$set(_vm.editingForms[i], "file", $$v)
                              },
                              expression: "editingForms[i].file"
                            }
                          })
                        : [
                            document.file
                              ? [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "/download/EmployeeDocument/" +
                                          document.id,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(document.file) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Options")]),
                      _vm._v(" "),
                      _vm.isEdit(i)
                        ? [
                            _c(
                              "button",
                              {
                                staticClass: "document-handle save",
                                on: {
                                  click: function($event) {
                                    return _vm.saveUpdateDocument(i)
                                  }
                                }
                              },
                              [_vm._v("Save")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "document-handle cancel",
                                on: {
                                  click: function($event) {
                                    return _vm.cancelEdit(i)
                                  }
                                }
                              },
                              [_vm._v("Cancel")]
                            )
                          ]
                        : [
                            _c(
                              "button",
                              {
                                staticClass: "document-handle update",
                                on: {
                                  click: function($event) {
                                    return _vm.editDocument(i)
                                  }
                                }
                              },
                              [_vm._v("Update")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "document-handle remove",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteDocument(document.id)
                                  }
                                }
                              },
                              [_vm._v("Remove")]
                            )
                          ]
                    ],
                    2
                  )
                ])
              })
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "content-filters" }, [
        _vm.addDocument
          ? _c(
              "div",
              {
                staticClass: "cof-row",
                staticStyle: { "margin-bottom": "70px" }
              },
              [
                _c("mnb-input", {
                  attrs: {
                    label: "Description",
                    validator: _vm.$v.addDocumentForm.description
                  },
                  model: {
                    value: _vm.addDocumentForm.description,
                    callback: function($$v) {
                      _vm.$set(_vm.addDocumentForm, "description", $$v)
                    },
                    expression: "addDocumentForm.description"
                  }
                }),
                _vm._v(" "),
                _c("mnb-input", {
                  attrs: { label: "Type" },
                  model: {
                    value: _vm.addDocumentForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.addDocumentForm, "type", $$v)
                    },
                    expression: "addDocumentForm.type"
                  }
                }),
                _vm._v(" "),
                _c("mnb-input", {
                  attrs: { label: "Number" },
                  model: {
                    value: _vm.addDocumentForm.number,
                    callback: function($$v) {
                      _vm.$set(_vm.addDocumentForm, "number", $$v)
                    },
                    expression: "addDocumentForm.number"
                  }
                }),
                _vm._v(" "),
                _c("form-field-upload", {
                  staticStyle: { width: "255px" },
                  attrs: {
                    label: "Document",
                    classes: "add-document",
                    btnClasses: "add-document-btn"
                  },
                  model: {
                    value: _vm.addDocumentForm.file,
                    callback: function($$v) {
                      _vm.$set(_vm.addDocumentForm, "file", $$v)
                    },
                    expression: "addDocumentForm.file"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "cof-field mobinl",
                    staticStyle: { "max-width": "100px" }
                  },
                  [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "main",
                        staticStyle: {
                          "min-width": "100px",
                          "margin-left": "20px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.saveDocument()
                          }
                        }
                      },
                      [_vm._v("Update")]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(2)
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", { staticClass: "main-header" }, [
        _c("h2", { staticClass: "inner-headers" }, [
          _vm._v("Employee Documents")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Description")]),
      _vm._v(" "),
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Number")]),
      _vm._v(" "),
      _c("th", [_vm._v("Filename")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "cof-field mobinl",
        staticStyle: { "margin-left": "20px" }
      },
      [
        _c("label", [_vm._v(" ")]),
        _vm._v(" "),
        _c("button", { staticClass: "second" }, [_vm._v("Reset Fields")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
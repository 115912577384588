var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticStyle: { margin: "30px 0" } }, [
    _c("h2", [_vm._v("Services")]),
    _vm._v(" "),
    _c("table", { staticClass: "content-table" }, [
      _c("tr", [
        _c("th", [_vm._v("Account")]),
        _vm._v(" "),
        _c("th", [_vm._v("Compliance")]),
        _vm._v(" "),
        _c("th", [_vm._v("ACH/EFT")]),
        _vm._v(" "),
        _c("th", [_vm._v("Acct/Acct")]),
        _vm._v(" "),
        _c("th", [_vm._v("Checks")]),
        _vm._v(" "),
        _c("th", [_vm._v("Dom Wires")]),
        _vm._v(" "),
        _c("th", [_vm._v("Intl Wires")]),
        _vm._v(" "),
        _c("th", [_vm._v("Assets")]),
        _vm._v(" "),
        _c("th", [_vm._v("Multi-Currency")]),
        _vm._v(" "),
        _c("th", [_vm._v("Debit Cards")]),
        _vm._v(" "),
        _vm.$hasPerm("update-profile") ? _c("th") : _vm._e()
      ]),
      _vm._v(" "),
      _c("tr", [
        _c(
          "td",
          [
            _c("span", [_vm._v("Account")]),
            _vm._v(" "),
            _c("mnb-select", {
              attrs: {
                options: _vm.providerOptions,
                disabled: !_vm.updateServices
              },
              model: {
                value: _vm.form.provider,
                callback: function($$v) {
                  _vm.$set(_vm.form, "provider", $$v)
                },
                expression: "form.provider"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("span", [_vm._v("Compliance")]),
            _vm._v(" "),
            _vm.provider
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.provider.kyc == 1 ? "Enabled" : "Disabled") +
                      "\n        "
                  )
                ]
              : [_vm._v("N/A")]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("span", [_vm._v("ACH")]),
            _vm._v(" "),
            _vm.provider
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.provider.ach == 1 ? "Enabled" : "Disabled") +
                      "\n        "
                  )
                ]
              : [_vm._v("N/A")]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("span", [_vm._v("ACH")]),
            _vm._v(" "),
            _vm.provider
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.provider.acct == 1 ? "Enabled" : "Disabled") +
                      "\n        "
                  )
                ]
              : [_vm._v("N/A")]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("span", [_vm._v("Checks")]),
            _vm._v(" "),
            _vm.provider
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.provider.checks == 1 ? "Enabled" : "Disabled"
                      ) +
                      "\n        "
                  )
                ]
              : [_vm._v("N/A")]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("span", [_vm._v("Wire Transfer")]),
            _vm._v(" "),
            _vm.provider
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.provider.domWires == 1 ? "Enabled" : "Disabled"
                      ) +
                      "\n        "
                  )
                ]
              : [_vm._v("N/A")]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("span", [_vm._v("Wire Transfer")]),
            _vm._v(" "),
            _vm.provider
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.provider.intlWires == 1 ? "Enabled" : "Disabled"
                      ) +
                      "\n        "
                  )
                ]
              : [_vm._v("N/A")]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("span", [_vm._v("Assets")]),
            _vm._v(" "),
            _vm.provider
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.provider.assets == 1 ? "Enabled" : "Disabled"
                      ) +
                      "\n        "
                  )
                ]
              : [_vm._v("N/A")]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("span", [_vm._v("Multi-Currency")]),
            _vm._v(" "),
            _vm.provider
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.provider.multiCurrency == 1 ? "Enabled" : "Disabled"
                      ) +
                      "\n        "
                  )
                ]
              : [_vm._v("N/A")]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "td",
          [
            _c("span", [_vm._v("Debit Cards")]),
            _vm._v(" "),
            _vm.provider
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.provider.debitCards == 1 ? "Enabled" : "Disabled"
                      ) +
                      "\n        "
                  )
                ]
              : [_vm._v("N/A")]
          ],
          2
        ),
        _vm._v(" "),
        _vm.$hasPerm("update-profile")
          ? _c("td", [_c("span", [_vm._v("Options")])])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
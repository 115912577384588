var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-form" },
    [
      _c(
        "div",
        { staticClass: "form-row form-split" },
        [
          _c("form-field-text", {
            attrs: {
              label: "Name on Account",
              validator: _vm.v.account_name,
              required: ""
            },
            model: {
              value: _vm.form.account_name,
              callback: function($$v) {
                _vm.$set(_vm.form, "account_name", $$v)
              },
              expression: "form.account_name"
            }
          }),
          _vm._v(" "),
          _c("form-field-select", {
            attrs: {
              label: "Account Type",
              validator: _vm.v.account_type,
              options: _vm.accountOptions,
              required: ""
            },
            model: {
              value: _vm.form.account_type,
              callback: function($$v) {
                _vm.$set(_vm.form, "account_type", $$v)
              },
              expression: "form.account_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-three" },
        [
          _c("form-field-text", {
            attrs: {
              label: "Bank Name",
              validator: _vm.v.bank_name,
              required: ""
            },
            model: {
              value: _vm.form.bank_name,
              callback: function($$v) {
                _vm.$set(_vm.form, "bank_name", $$v)
              },
              expression: "form.bank_name"
            }
          }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              label: "Bank Account Number",
              validator: _vm.v.account_number,
              required: ""
            },
            model: {
              value: _vm.form.account_number,
              callback: function($$v) {
                _vm.$set(_vm.form, "account_number", $$v)
              },
              expression: "form.account_number"
            }
          }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              label: "SWIFT Code",
              validator: _vm.v.swift,
              required: ""
            },
            model: {
              value: _vm.form.swift,
              callback: function($$v) {
                _vm.$set(_vm.form, "swift", $$v)
              },
              expression: "form.swift"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center-checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.use_intermediary_bank,
              expression: "form.use_intermediary_bank"
            }
          ],
          attrs: {
            type: "checkbox",
            id: "use_intermediary_bank",
            disabled: ""
          },
          domProps: {
            checked: Array.isArray(_vm.form.use_intermediary_bank)
              ? _vm._i(_vm.form.use_intermediary_bank, null) > -1
              : _vm.form.use_intermediary_bank
          },
          on: {
            change: function($event) {
              var $$a = _vm.form.use_intermediary_bank,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.form,
                      "use_intermediary_bank",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.form,
                      "use_intermediary_bank",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.form, "use_intermediary_bank", $$c)
              }
            }
          }
        }),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _vm.form.use_intermediary_bank
        ? [
            _c(
              "div",
              { staticClass: "form-row form-split" },
              [
                _c("form-field-text", {
                  attrs: {
                    label: "Intermediary Bank Name",
                    validator: _vm.v.intermediary_bank_name,
                    required: ""
                  },
                  model: {
                    value: _vm.form.intermediary_bank_name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "intermediary_bank_name", $$v)
                    },
                    expression: "form.intermediary_bank_name"
                  }
                }),
                _vm._v(" "),
                _c("form-field-text", {
                  attrs: {
                    label: "Intermediary Bank Reference",
                    validator: _vm.v.intermediary_bank_reference,
                    required: ""
                  },
                  model: {
                    value: _vm.form.intermediary_bank_reference,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "intermediary_bank_reference", $$v)
                    },
                    expression: "form.intermediary_bank_reference"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("h3", { staticClass: "subtitle" }, [
              _vm._v("INTERMEDIARY BANK ADDRESS")
            ]),
            _vm._v(" "),
            _c("address-form", {
              attrs: { v: _vm.v.intermediary_address },
              model: {
                value: _vm.form.intermediary_address,
                callback: function($$v) {
                  _vm.$set(_vm.form, "intermediary_address", $$v)
                },
                expression: "form.intermediary_address"
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("h3", { staticClass: "subtitle" }, [_vm._v("BENEFICIARY ADDRESS")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-split" },
        [
          _c("form-field-text", {
            attrs: { label: "IBAN", validator: _vm.v.iban, required: "" },
            model: {
              value: _vm.form.iban,
              callback: function($$v) {
                _vm.$set(_vm.form, "iban", $$v)
              },
              expression: "form.iban"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("address-form", {
        attrs: { v: _vm.v },
        model: {
          value: _vm.form,
          callback: function($$v) {
            _vm.form = $$v
          },
          expression: "form"
        }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "use_intermediary_bank" } }, [
      _vm._v("Use Intermediary Bank "),
      _c("span", { staticClass: "error-message" }, [_vm._v("*Required")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
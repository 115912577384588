var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.registration.type == "Personal"
        ? _c(
            "ul",
            { staticClass: "cp-rows active" },
            [
              _vm.showEdit
                ? _c(
                    "li",
                    { staticStyle: { "flex-direction": "row" } },
                    [
                      _vm.$hasPerm("edit-profile")
                        ? [
                            !_vm.editStatus
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success",
                                    on: {
                                      click: function($event) {
                                        return _vm.edit()
                                      }
                                    }
                                  },
                                  [_vm._v("Edit")]
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editStatus
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              on: {
                                click: function($event) {
                                  return _vm.save()
                                }
                              }
                            },
                            [_vm._v("Save")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editStatus
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              on: {
                                click: function($event) {
                                  _vm.editStatus = false
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.hideBasics
                ? _c("li", [
                    _c("ul", { staticClass: "cp-fields" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Registration Date")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.registration.createdAt,
                                  "dddd, MMMM D YYYY"
                                )
                              ) +
                              "\n              "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.registration.createdAt,
                                  "hh:mm:ss A"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Registration ID")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.registration.regId) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.registration.partner
                        ? _c("li", [
                            _c("span", [_vm._v("Partner")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "val" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.registration.partner.partnerNumber
                                  ) +
                                  " - " +
                                  _vm._s(
                                    _vm.registration.partner.business.legal_name
                                  ) +
                                  "\n            "
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.hideInfo
                ? [
                    _c("ul", { staticClass: "cp-fields" }, [
                      _c("li", [
                        _c("span", { staticClass: "form-label" }, [
                          _vm._v("First Name "),
                          _vm.editStatus && _vm.$v.form.firstName.$invalid
                            ? _c("span", { staticClass: "error-message" }, [
                                _vm._v("  *")
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _vm.editStatus && !_vm.registration.user
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.firstName,
                                  expression: "form.firstName"
                                }
                              ],
                              class: { error: _vm.$v.form.firstName.$invalid },
                              attrs: { type: "text" },
                              domProps: { value: _vm.form.firstName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "firstName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _c("div", { staticClass: "val" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.registration.data.firstName) +
                                  "\n              "
                              )
                            ]),
                        _vm._v(" "),
                        _vm.editStatus && _vm.$v.form.firstName.$invalid
                          ? _c("div", [
                              _c("span", { staticClass: "error-message" }, [
                                _vm._v("This field is required.")
                              ])
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", { staticClass: "form-label" }, [
                          _vm._v("Last Name "),
                          _vm.editStatus && _vm.$v.form.lastName.$invalid
                            ? _c("span", { staticClass: "error-message" }, [
                                _vm._v("  *")
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _vm.editStatus && !_vm.registration.user
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.lastName,
                                  expression: "form.lastName"
                                }
                              ],
                              class: { error: _vm.$v.form.lastName.$invalid },
                              attrs: { type: "text" },
                              domProps: { value: _vm.form.lastName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "lastName",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _c("div", { staticClass: "val" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.registration.data.lastName) +
                                  "\n              "
                              )
                            ]),
                        _vm._v(" "),
                        _vm.editStatus && _vm.$v.form.lastName.$invalid
                          ? _c("div", [
                              _c("span", { staticClass: "error-message" }, [
                                _vm._v("This field is required.")
                              ])
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Sex")]),
                        _vm._v(" "),
                        _vm.editStatus && !_vm.registration.user
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.sex,
                                    expression: "form.sex"
                                  }
                                ],
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.form,
                                      "sex",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "Male" } }, [
                                  _vm._v("Male")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "Female" } }, [
                                  _vm._v("Female")
                                ])
                              ]
                            )
                          : _c("div", { staticClass: "val" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.registration.data.sex) +
                                  "\n              "
                              )
                            ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("SSN/Fiscal Number "),
                            _vm.editStatus && _vm.$v.form.sin.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.editStatus && !_vm.registration.user
                            ? [
                                !_vm.editSin
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.sin,
                                          expression: "form.sin"
                                        }
                                      ],
                                      class: {
                                        error: _vm.$v.form.sin.$invalid
                                      },
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.form.sin },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.form,
                                            "sin",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editSin
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.editSin,
                                          expression: "editSin"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.editSin },
                                      on: {
                                        mousedown: function($event) {
                                          return _vm.closeEditSin()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.editSin = $event.target.value
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : _vm.isCustomer
                            ? _c(
                                "div",
                                { staticClass: "val" },
                                [
                                  _vm.showSin
                                    ? [
                                        _vm._v(
                                          _vm._s(_vm.registration.data.sin)
                                        )
                                      ]
                                    : [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("sinNum")(
                                              _vm.registration.data.sin
                                            )
                                          )
                                        )
                                      ]
                                ],
                                2
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "val",
                                  on: {
                                    click: function($event) {
                                      _vm.open = true
                                    }
                                  }
                                },
                                [
                                  _vm.showSin
                                    ? [
                                        _vm._v(
                                          _vm._s(_vm.registration.data.sin)
                                        )
                                      ]
                                    : [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("sinNum")(
                                              _vm.registration.data.sin
                                            )
                                          )
                                        )
                                      ]
                                ],
                                2
                              ),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.sin.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "dob" },
                        [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("Date of Birth "),
                            _vm.editStatus && _vm.$v.form.dob.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.editStatus && !_vm.registration.user
                            ? _c("VueDatePicker", {
                                attrs: {
                                  format: "YYYY-MM-DD",
                                  "min-date": _vm.date1,
                                  "max-date": _vm.date2
                                },
                                model: {
                                  value: _vm.form.dob,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "dob", $$v)
                                  },
                                  expression: "form.dob"
                                }
                              })
                            : _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("date")(
                                        _vm.registration.data.dob,
                                        "LL"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.dob.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", { staticClass: "form-label" }, [
                          _vm._v("Email "),
                          _vm.editStatus && _vm.$v.form.email.$invalid
                            ? _c("span", { staticClass: "error-message" }, [
                                _vm._v("  *")
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _vm.editStatus && !_vm.registration.user
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.email,
                                  expression: "form.email"
                                }
                              ],
                              class: { error: _vm.$v.form.email.$invalid },
                              attrs: { type: "text" },
                              domProps: { value: _vm.form.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _c("div", { staticClass: "val" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.registration.data.email) +
                                  "\n              "
                              )
                            ]),
                        _vm._v(" "),
                        _vm.editStatus && _vm.$v.form.email.$invalid
                          ? _c("div", [
                              _c("span", { staticClass: "error-message" }, [
                                _vm._v("This field is required.")
                              ])
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        staticClass: "cp-fields",
                        staticStyle: { "margin-top": "20px" }
                      },
                      [
                        _c("li", [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("Phone "),
                            _vm.editStatus && _vm.$v.form.phone.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.phone) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.phone,
                                    expression: "form.phone"
                                  }
                                ],
                                class: { error: _vm.$v.form.phone.$invalid },
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.phone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "phone",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.phone.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Mobile")]),
                          _vm._v(" "),
                          !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.mobile) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.mobile,
                                    expression: "form.mobile"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.mobile },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "mobile",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li"),
                        _vm._v(" "),
                        _c("li"),
                        _vm._v(" "),
                        _c("li"),
                        _vm._v(" "),
                        _c("li")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        staticClass: "cp-fields",
                        staticStyle: { "margin-top": "20px" }
                      },
                      [
                        _c("li", [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("Address "),
                            _vm.editStatus && _vm.$v.form.address.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.address) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.address,
                                    expression: "form.address"
                                  }
                                ],
                                class: { error: _vm.$v.form.address.$invalid },
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.address },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "address",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.address.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Address 2")]),
                          _vm._v(" "),
                          !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.address2) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.address2,
                                    expression: "form.address2"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.address2 },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "address2",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("City "),
                            _vm.editStatus && _vm.$v.form.city.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.city) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.city,
                                    expression: "form.city"
                                  }
                                ],
                                class: { error: _vm.$v.form.city.$invalid },
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.city },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "city",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.city.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "li",
                          [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("State/Province "),
                              _vm.editStatus && _vm.$v.form.state.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            !_vm.editStatus
                              ? _c("div", { staticClass: "val" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("state")(
                                        _vm.registration.data.state
                                      )
                                    )
                                  )
                                ])
                              : [
                                  _vm.stateOptions(_vm.form.country)
                                    ? _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.state,
                                              expression: "form.state"
                                            }
                                          ],
                                          staticClass: "fullw",
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.form,
                                                "state",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        _vm._l(
                                          _vm.stateOptions(_vm.form.country),
                                          function(state, key) {
                                            return _c(
                                              "option",
                                              {
                                                key: key,
                                                domProps: { value: key }
                                              },
                                              [_vm._v(_vm._s(state))]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.state,
                                            expression: "form.state"
                                          }
                                        ],
                                        class: {
                                          error: _vm.$v.form.state.$invalid
                                        },
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.form.state },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.form,
                                              "state",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                  _vm._v(" "),
                                  _vm.editStatus && _vm.$v.form.state.$invalid
                                    ? _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "error-message" },
                                          [_vm._v("This field is required.")]
                                        )
                                      ])
                                    : _vm._e()
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("ZIP/Postal Code "),
                            _vm.editStatus && _vm.$v.form.zip.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.zip) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.zip,
                                    expression: "form.zip"
                                  }
                                ],
                                class: { error: _vm.$v.form.zip.$invalid },
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.zip },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "zip",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.zip.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("Country "),
                            _vm.editStatus && _vm.$v.form.country.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.editStatus && !_vm.registration.user
                            ? _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.country,
                                      expression: "form.country"
                                    }
                                  ],
                                  staticClass: "fullw",
                                  class: {
                                    error: _vm.$v.form.country.$invalid
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.form,
                                        "country",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.contactCountryOptions, function(
                                  country,
                                  key
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: key,
                                      domProps: { value: country.id }
                                    },
                                    [_vm._v(_vm._s(country.text))]
                                  )
                                }),
                                0
                              )
                            : _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("country")(
                                      _vm.registration.data.country
                                    )
                                  )
                                )
                              ]),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.country.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.registration.type == "Business" ||
      _vm.registration.type == "Master Business"
        ? _c(
            "ul",
            { staticClass: "cp-rows active" },
            [
              _vm.showEdit
                ? _c(
                    "li",
                    { staticStyle: { "flex-direction": "row" } },
                    [
                      _vm.$hasPerm("edit-profile")
                        ? [
                            !_vm.editStatus
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success",
                                    on: {
                                      click: function($event) {
                                        return _vm.edit()
                                      }
                                    }
                                  },
                                  [_vm._v("Edit")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.editStatus &&
                            _vm.registration.status !== "Active" &&
                            _vm.registration.status !== "Restricted" &&
                            _vm.registration.status !== "Complete"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.openAddContact()
                                      }
                                    }
                                  },
                                  [_vm._v("Add")]
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editStatus
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              on: {
                                click: function($event) {
                                  return _vm.save()
                                }
                              }
                            },
                            [_vm._v("Save")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editStatus
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              on: {
                                click: function($event) {
                                  _vm.editStatus = false
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.hideBasics
                ? [
                    _c(
                      "ul",
                      {
                        staticClass: "cp-fields",
                        staticStyle: { "margin-top": "20px" }
                      },
                      [
                        _c("li", [
                          _c("span", [_vm._v("Profile Type")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.registration.type) +
                                "\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Business Type")]),
                          _vm._v(" "),
                          _vm.registration.data && !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.registration.data.business_type
                                    ) +
                                    "\n            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.business_type,
                                      expression: "form.business_type"
                                    }
                                  ],
                                  staticClass: "fullw",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.form,
                                        "business_type",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.businessTypes, function(
                                  businessType,
                                  key
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: key,
                                      domProps: { value: businessType }
                                    },
                                    [_vm._v(_vm._s(businessType))]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Registration Date")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.registration.createdAt,
                                    "dddd, MMMM D YYYY"
                                  )
                                ) +
                                "\n              "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.registration.createdAt,
                                    "hh:mm:ss A"
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Registration ID")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.registration.regId) +
                                "\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.registration.partner
                          ? _c("li", [
                              _c("span", [_vm._v("Partner")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.registration.partner.partnerNumber
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm.registration.partner.business
                                        .legal_name
                                    ) +
                                    "\n            "
                                )
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.hideInfo
                ? [
                    _vm.hideBasics
                      ? _c(
                          "ul",
                          {
                            staticClass: "cp-fields",
                            staticStyle: { "margin-top": "20px" }
                          },
                          [
                            _c("li", [
                              _c("span", { staticClass: "form-label" }, [
                                _vm._v("Type")
                              ]),
                              _vm._v(" "),
                              _vm.editStatus
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.business_type,
                                          expression: "form.business_type"
                                        }
                                      ],
                                      staticClass: "fullw",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.form,
                                            "business_type",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.businessTypes, function(
                                      businessType,
                                      key
                                    ) {
                                      return _c(
                                        "option",
                                        {
                                          key: key,
                                          domProps: { value: businessType }
                                        },
                                        [_vm._v(_vm._s(businessType))]
                                      )
                                    }),
                                    0
                                  )
                                : _c("div", { staticClass: "val" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.registration.data.business_type
                                        ) +
                                        "\n              "
                                    )
                                  ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("span", { staticClass: "form-label" }, [
                                _vm._v("Website "),
                                _vm.editStatus && _vm.$v.form.website.$invalid
                                  ? _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("  *")]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              !_vm.editStatus
                                ? _c("div", { staticClass: "val" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.registration.data.website) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editStatus
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.website,
                                        expression: "form.website"
                                      }
                                    ],
                                    class: {
                                      error: _vm.$v.form.website.$invalid
                                    },
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.form.website },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "website",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editStatus && _vm.$v.form.website.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("li"),
                            _vm._v(" "),
                            _c("li"),
                            _vm._v(" "),
                            _c("li"),
                            _vm._v(" "),
                            _c("li")
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        staticClass: "cp-fields",
                        staticStyle: { "margin-top": "20px" }
                      },
                      [
                        _c("li", [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("Legal Name "),
                            _vm.editStatus && _vm.$v.form.legal_name.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.editStatus && !_vm.registration.user
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.legal_name,
                                    expression: "form.legal_name"
                                  }
                                ],
                                class: {
                                  error: _vm.$v.form.legal_name.$invalid
                                },
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.legal_name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "legal_name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.legal_name) +
                                    "\n              "
                                )
                              ]),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.legal_name.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("DBA "),
                            _vm.editStatus && _vm.$v.form.dba_name.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.dba_name) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.dba_name,
                                    expression: "form.dba_name"
                                  }
                                ],
                                class: { error: _vm.$v.form.dba_name.$invalid },
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.dba_name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "dba_name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.dba_name.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "li",
                          [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Date "),
                              _vm.editStatus &&
                              _vm.$v.form.incorporation_date.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.editStatus && !_vm.registration.user
                              ? _c("vue-englishdatepicker", {
                                  class: {
                                    error:
                                      _vm.$v.form.incorporation_date.$invalid
                                  },
                                  model: {
                                    value: _vm.form.incorporation_date,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "incorporation_date",
                                        $$v
                                      )
                                    },
                                    expression: "form.incorporation_date"
                                  }
                                })
                              : _c("div", { staticClass: "val" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("date")(
                                          _vm.registration.data
                                            .incorporation_date,
                                          "LL"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]),
                            _vm._v(" "),
                            _vm.editStatus &&
                            _vm.$v.form.incorporation_date.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("Tax ID "),
                            _vm.editStatus && _vm.$v.form.tax_id.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.editStatus && !_vm.registration.user
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.tax_id,
                                    expression: "form.tax_id"
                                  }
                                ],
                                class: { error: _vm.$v.form.tax_id.$invalid },
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.tax_id },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "tax_id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.tax_id) +
                                    "\n              "
                                )
                              ]),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.tax_id.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("Phone "),
                            _vm.editStatus && _vm.$v.form.phone.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.phone) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.phone,
                                    expression: "form.phone"
                                  }
                                ],
                                class: { error: _vm.$v.form.phone.$invalid },
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.phone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "phone",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.phone.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("Email "),
                            _vm.editStatus && _vm.$v.form.email.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.editStatus && !_vm.registration.user
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.email,
                                    expression: "form.email"
                                  }
                                ],
                                class: { error: _vm.$v.form.email.$invalid },
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.registration.data.email) +
                                    "\n              "
                                )
                              ]),
                          _vm._v(" "),
                          _vm.editStatus && _vm.$v.form.email.$invalid
                            ? _c("div", [
                                _c("span", { staticClass: "error-message" }, [
                                  _vm._v("This field is required.")
                                ])
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.hideAddress
                      ? [
                          _c(
                            "ul",
                            {
                              staticClass: "cp-fields",
                              staticStyle: { "margin-top": "20px" }
                            },
                            [
                              _c("li", [
                                _c("span", { staticClass: "form-label" }, [
                                  _vm._v("Address "),
                                  _vm.editStatus && _vm.$v.form.address.$invalid
                                    ? _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("  *")]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                !_vm.editStatus
                                  ? _c("div", { staticClass: "val" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.registration.data.address
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.address,
                                          expression: "form.address"
                                        }
                                      ],
                                      class: {
                                        error: _vm.$v.form.address.$invalid
                                      },
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.form.address },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.form,
                                            "address",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus && _vm.$v.form.address.$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("span", [_vm._v("Address 2")]),
                                _vm._v(" "),
                                !_vm.editStatus
                                  ? _c("div", { staticClass: "val" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.registration.data.address2
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.address2,
                                          expression: "form.address2"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.form.address2 },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.form,
                                            "address2",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("span", { staticClass: "form-label" }, [
                                  _vm._v("City "),
                                  _vm.editStatus && _vm.$v.form.city.$invalid
                                    ? _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("  *")]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                !_vm.editStatus
                                  ? _c("div", { staticClass: "val" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.registration.data.city) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.city,
                                          expression: "form.city"
                                        }
                                      ],
                                      class: {
                                        error: _vm.$v.form.city.$invalid
                                      },
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.form.city },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.form,
                                            "city",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus && _vm.$v.form.city.$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c(
                                "li",
                                [
                                  _c("span", { staticClass: "form-label" }, [
                                    _vm._v("State/Province "),
                                    _vm.editStatus && _vm.$v.form.state.$invalid
                                      ? _c(
                                          "span",
                                          { staticClass: "error-message" },
                                          [_vm._v("  *")]
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  !_vm.editStatus
                                    ? _c("div", { staticClass: "val" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("state")(
                                              _vm.registration.data.state
                                            )
                                          )
                                        )
                                      ])
                                    : [
                                        _vm.stateOptions(
                                          _vm.form.formation_country
                                        )
                                          ? _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.form.state,
                                                    expression: "form.state"
                                                  }
                                                ],
                                                staticClass: "fullw",
                                                class: {
                                                  error:
                                                    _vm.$v.form.state.$invalid
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.form,
                                                      "state",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              _vm._l(
                                                _vm.stateOptions(
                                                  _vm.form.formation_country
                                                ),
                                                function(state, key) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: key,
                                                      domProps: { value: key }
                                                    },
                                                    [_vm._v(_vm._s(state))]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.form.state,
                                                  expression: "form.state"
                                                }
                                              ],
                                              class: {
                                                error:
                                                  _vm.$v.form.state.$invalid
                                              },
                                              attrs: { type: "text" },
                                              domProps: {
                                                value: _vm.form.state
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.form,
                                                    "state",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                        _vm._v(" "),
                                        _vm.editStatus &&
                                        _vm.$v.form.state.$invalid
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "error-message"
                                                },
                                                [
                                                  _vm._v(
                                                    "This field is required."
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("li", [
                                _c("span", { staticClass: "form-label" }, [
                                  _vm._v("Zip/Postal Code "),
                                  _vm.editStatus && _vm.$v.form.zip.$invalid
                                    ? _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("  *")]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                !_vm.editStatus
                                  ? _c("div", { staticClass: "val" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.registration.data.zip) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.zip,
                                          expression: "form.zip"
                                        }
                                      ],
                                      class: {
                                        error: _vm.$v.form.zip.$invalid
                                      },
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.form.zip },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.form,
                                            "zip",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus && _vm.$v.form.zip.$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("span", { staticClass: "form-label" }, [
                                  _vm._v("Country "),
                                  _vm.editStatus &&
                                  _vm.$v.form.formation_country.$invalid
                                    ? _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("  *")]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _vm.editStatus && !_vm.registration.user
                                  ? _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.formation_country,
                                            expression: "form.formation_country"
                                          }
                                        ],
                                        staticClass: "fullw",
                                        class: {
                                          error:
                                            _vm.$v.form.formation_country
                                              .$invalid
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.form,
                                              "formation_country",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      _vm._l(_vm.countryOptions, function(
                                        country,
                                        key
                                      ) {
                                        return _c(
                                          "option",
                                          {
                                            key: key,
                                            domProps: { value: country.id }
                                          },
                                          [_vm._v(_vm._s(country.text))]
                                        )
                                      }),
                                      0
                                    )
                                  : _c("div", { staticClass: "val" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("country")(
                                            _vm.registration.data
                                              .formation_country
                                          )
                                        )
                                      )
                                    ]),
                                _vm._v(" "),
                                _vm.editStatus &&
                                _vm.$v.form.formation_country.$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          )
                        ]
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.hideContacts
                ? _vm._l(_vm.registration.data.contacts, function(
                    contact,
                    index
                  ) {
                    return _c("div", { key: index }, [
                      index > 0 ? _c("hr") : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "cp-fields",
                          staticStyle: { "margin-top": "20px" }
                        },
                        [
                          _c("li", [
                            _c("span", [_vm._v("Type")]),
                            _vm._v(" "),
                            _vm.editStatus && !_vm.registration.user
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.contacts[index].type,
                                        expression: "form.contacts[index].type"
                                      }
                                    ],
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.form.contacts[index],
                                          "type",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "Beneficial Owner" } },
                                      [_vm._v("Beneficial Owner")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      { attrs: { value: "Authorized Person" } },
                                      [_vm._v("Authorized Person")]
                                    )
                                  ]
                                )
                              : _c("div", { staticClass: "val" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(contact.type) +
                                      "\n              "
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          (!_vm.editStatus &&
                            contact.type === "Beneficial Owner") ||
                          (_vm.editStatus &&
                            _vm.form.contacts[index].type ===
                              "Beneficial Owner")
                            ? _c("li", [
                                _c("span", { staticClass: "form-label" }, [
                                  _vm._v("Ownership"),
                                  _vm.editStatus &&
                                  _vm.$v.form.contacts.$each[index]
                                    .ownership_percentage.$invalid
                                    ? _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("  *")]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "val" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        !_vm.editStatus
                                          ? contact.ownership_percentage
                                          : ""
                                      ) +
                                      "\n                "
                                  ),
                                  _vm.editStatus
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.form.contacts[index]
                                                .ownership_percentage,
                                            expression:
                                              "form.contacts[index].ownership_percentage"
                                          }
                                        ],
                                        class: {
                                          error:
                                            _vm.$v.form.contacts.$each[index]
                                              .ownership_percentage.$invalid
                                        },
                                        attrs: { type: "text" },
                                        domProps: {
                                          value:
                                            _vm.form.contacts[index]
                                              .ownership_percentage
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.form.contacts[index],
                                              "ownership_percentage",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.editStatus &&
                                  _vm.$v.form.contacts.$each[index]
                                    .ownership_percentage.$invalid
                                    ? _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "error-message" },
                                          [_vm._v("This field is required.")]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "cp-fields",
                          staticStyle: { "margin-top": "20px" }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("First Name "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].firstName
                                .$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.editStatus && !_vm.registration.user
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.contacts[index].firstName,
                                      expression:
                                        "form.contacts[index].firstName"
                                    }
                                  ],
                                  class: {
                                    error:
                                      _vm.$v.form.contacts.$each[index]
                                        .firstName.$invalid
                                  },
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.form.contacts[index].firstName
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form.contacts[index],
                                        "firstName",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _c("div", { staticClass: "val" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(contact.firstName) +
                                      "\n              "
                                  )
                                ]),
                            _vm._v(" "),
                            _vm.editStatus &&
                            _vm.$v.form.contacts.$each[index].firstName.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Last Name "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].lastName
                                .$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.editStatus && !_vm.registration.user
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.contacts[index].lastName,
                                      expression:
                                        "form.contacts[index].lastName"
                                    }
                                  ],
                                  class: {
                                    error:
                                      _vm.$v.form.contacts.$each[index].lastName
                                        .$invalid
                                  },
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.form.contacts[index].lastName
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form.contacts[index],
                                        "lastName",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _c("div", { staticClass: "val" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(contact.lastName) +
                                      "\n              "
                                  )
                                ]),
                            _vm._v(" "),
                            _vm.editStatus &&
                            _vm.$v.form.contacts.$each[index].lastName.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", [_vm._v("Sex")]),
                            _vm._v(" "),
                            _vm.editStatus && !_vm.registration.user
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.contacts[index].sex,
                                        expression: "form.contacts[index].sex"
                                      }
                                    ],
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.form.contacts[index],
                                          "sex",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "Male" } }, [
                                      _vm._v("Male")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      { attrs: { value: "Female" } },
                                      [_vm._v("Female")]
                                    )
                                  ]
                                )
                              : _c("div", { staticClass: "val" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(contact.sex) +
                                      "\n              "
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("SSN/Fiscal Number "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].sin.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "val" },
                              [
                                _vm.editStatus && !_vm.registration.user
                                  ? [
                                      !_vm.contactsEditSin[index]
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.form.contacts[index].sin,
                                                expression:
                                                  "form.contacts[index].sin"
                                              }
                                            ],
                                            class: {
                                              error:
                                                _vm.$v.form.contacts.$each[
                                                  index
                                                ].sin.$invalid
                                            },
                                            attrs: { type: "text" },
                                            domProps: {
                                              value:
                                                _vm.form.contacts[index].sin
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.form.contacts[index],
                                                  "sin",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.contactsEditSin[index]
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.contactsEditSin[index],
                                                expression:
                                                  "contactsEditSin[index]"
                                              }
                                            ],
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: _vm.contactsEditSin[index]
                                            },
                                            on: {
                                              mousedown: function($event) {
                                                return _vm.closeContactEditSin(
                                                  index
                                                )
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.contactsEditSin,
                                                  index,
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  : _vm.isCustomer
                                  ? _c(
                                      "div",
                                      [
                                        _vm.showSin
                                          ? [_vm._v(_vm._s(contact.sin))]
                                          : [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("sinNum")(contact.sin)
                                                )
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  : _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            _vm.open = true
                                          }
                                        }
                                      },
                                      [
                                        _vm.showSin
                                          ? [_vm._v(_vm._s(contact.sin))]
                                          : [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("sinNum")(contact.sin)
                                                )
                                              )
                                            ]
                                      ],
                                      2
                                    ),
                                _vm._v(" "),
                                _vm.editStatus &&
                                _vm.$v.form.contacts.$each[index].sin.$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "dob" },
                            [
                              _c("span", { staticClass: "form-label" }, [
                                _vm._v("Date of Birth "),
                                _vm.editStatus &&
                                _vm.$v.form.contacts.$each[index].dob.$invalid
                                  ? _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("  *")]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _vm.editStatus && !_vm.registration.user
                                ? _c("VueDatePicker", {
                                    attrs: {
                                      format: "YYYY-MM-DD",
                                      "min-date": _vm.date1,
                                      "max-date": _vm.date2
                                    },
                                    model: {
                                      value: _vm.form.contacts[index].dob,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form.contacts[index],
                                          "dob",
                                          $$v
                                        )
                                      },
                                      expression: "form.contacts[index].dob"
                                    }
                                  })
                                : _c("div", { staticClass: "val" }, [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("date")(contact.dob, "LL")
                                        )
                                      )
                                    ])
                                  ]),
                              _vm._v(" "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].dob.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Email "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].email.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "val" },
                              [
                                _vm.editStatus && !_vm.registration.user
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.contacts[index].email,
                                          expression:
                                            "form.contacts[index].email"
                                        }
                                      ],
                                      class: {
                                        error:
                                          _vm.$v.form.contacts.$each[index]
                                            .email.$invalid
                                      },
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.form.contacts[index].email
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.form.contacts[index],
                                            "email",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(contact.email) +
                                          "\n                "
                                      )
                                    ],
                                _vm._v(" "),
                                _vm.editStatus &&
                                _vm.$v.form.contacts.$each[index].email.$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "cp-fields",
                          staticStyle: { "margin-top": "20px" }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Phone "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].phone.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "val" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(!_vm.editStatus ? contact.phone : "") +
                                  "\n                "
                              ),
                              _vm.editStatus
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.contacts[index].phone,
                                        expression: "form.contacts[index].phone"
                                      }
                                    ],
                                    class: {
                                      error:
                                        _vm.$v.form.contacts.$each[index].phone
                                          .$invalid
                                    },
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.form.contacts[index].phone
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form.contacts[index],
                                          "phone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].phone.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", [_vm._v("Mobile")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "val" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    !_vm.editStatus ? contact.mobile : ""
                                  ) +
                                  "\n                "
                              ),
                              _vm.editStatus
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.contacts[index].mobile,
                                        expression:
                                          "form.contacts[index].mobile"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.form.contacts[index].mobile
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form.contacts[index],
                                          "mobile",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "cp-fields",
                          staticStyle: { "margin-top": "20px" }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Address "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].address.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "val" },
                              [
                                !_vm.editStatus
                                  ? [_vm._v(_vm._s(contact.address || "N/A"))]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.form.contacts[index].address,
                                          expression:
                                            "form.contacts[index].address"
                                        }
                                      ],
                                      class: {
                                        error:
                                          _vm.$v.form.contacts.$each[index]
                                            .address.$invalid
                                      },
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.form.contacts[index].address
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.form.contacts[index],
                                            "address",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus &&
                                _vm.$v.form.contacts.$each[index].address
                                  .$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", [_vm._v("Address 2")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "val" },
                              [
                                !_vm.editStatus
                                  ? [_vm._v(_vm._s(contact.address2 || "N/A"))]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.form.contacts[index].address2,
                                          expression:
                                            "form.contacts[index].address2"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.form.contacts[index].address2
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.form.contacts[index],
                                            "address2",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("City "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].city.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "val" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(!_vm.editStatus ? contact.city : "") +
                                  "\n                "
                              ),
                              _vm.editStatus
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.contacts[index].city,
                                        expression: "form.contacts[index].city"
                                      }
                                    ],
                                    class: {
                                      error:
                                        _vm.$v.form.contacts.$each[index].city
                                          .$invalid
                                    },
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.form.contacts[index].city
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form.contacts[index],
                                          "city",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].city.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("State/Provice "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].state.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "val" },
                              [
                                !_vm.editStatus
                                  ? [
                                      _vm.stateOptions(contact.country)
                                        ? [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("state")(contact.state)
                                              )
                                            )
                                          ]
                                        : [_vm._v(_vm._s(contact.state))]
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editStatus
                                  ? [
                                      _vm.stateOptions(
                                        _vm.form.contacts[index].country
                                      )
                                        ? _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.form.contacts[index]
                                                      .state,
                                                  expression:
                                                    "form.contacts[index].state"
                                                }
                                              ],
                                              staticClass: "fullw",
                                              class: {
                                                error:
                                                  _vm.$v.form.contacts.$each[
                                                    index
                                                  ].state.$invalid
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.form.contacts[index],
                                                    "state",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(
                                              _vm.stateOptions(
                                                _vm.form.contacts[index].country
                                              ),
                                              function(state, key) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: key,
                                                    domProps: { value: key }
                                                  },
                                                  [_vm._v(_vm._s(state))]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.form.contacts[index]
                                                    .state,
                                                expression:
                                                  "form.contacts[index].state"
                                              }
                                            ],
                                            class: {
                                              error:
                                                _vm.$v.form.contacts.$each[
                                                  index
                                                ].state.$invalid
                                            },
                                            attrs: { type: "text" },
                                            domProps: {
                                              value:
                                                _vm.form.contacts[index].state
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.form.contacts[index],
                                                  "state",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                      _vm._v(" "),
                                      _vm.editStatus &&
                                      _vm.$v.form.contacts.$each[index].state
                                        .$invalid
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "error-message" },
                                              [
                                                _vm._v(
                                                  "This field is required."
                                                )
                                              ]
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Zip/Postal Code "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].zip.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "val" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(!_vm.editStatus ? contact.zip : "") +
                                  "\n                "
                              ),
                              _vm.editStatus
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.contacts[index].zip,
                                        expression: "form.contacts[index].zip"
                                      }
                                    ],
                                    class: {
                                      error:
                                        _vm.$v.form.contacts.$each[index].zip
                                          .$invalid
                                    },
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.form.contacts[index].zip
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form.contacts[index],
                                          "zip",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].zip.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Country "),
                              _vm.editStatus &&
                              _vm.$v.form.contacts.$each[index].country.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.editStatus && !_vm.registration.user
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.contacts[index].country,
                                        expression:
                                          "form.contacts[index].country"
                                      }
                                    ],
                                    staticClass: "fullw",
                                    class: {
                                      error:
                                        _vm.$v.form.contacts.$each[index]
                                          .country.$invalid
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.form.contacts[index],
                                          "country",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  _vm._l(_vm.contactCountryOptions, function(
                                    country,
                                    key
                                  ) {
                                    return _c(
                                      "option",
                                      {
                                        key: key,
                                        domProps: { value: country.id }
                                      },
                                      [_vm._v(_vm._s(country.text))]
                                    )
                                  }),
                                  0
                                )
                              : _c(
                                  "div",
                                  { staticClass: "val" },
                                  [
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("country")(contact.country)
                                        )
                                      )
                                    ]
                                  ],
                                  2
                                ),
                            _vm._v(" "),
                            _vm.editStatus &&
                            _vm.$v.form.contacts.$each[index].country.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.showRemoveContactButton && index && _vm.isAdmin
                            ? _c("li", [
                                _c("span", [_vm._v("Options")]),
                                _vm._v(" "),
                                _vm.editStatus &&
                                (_vm.registration.status == "New" ||
                                  _vm.registration.status == "Pending")
                                  ? _c("div", { staticClass: "val" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function($event) {
                                              return _vm.askRemoveContact(
                                                index,
                                                contact
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Remove contact")]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.hideInfo
                ? [
                    _c(
                      "ul",
                      {
                        staticClass: "cp-fields",
                        staticStyle: { "margin-top": "20px" }
                      },
                      [
                        _c("li", { staticStyle: { width: "40%" } }, [
                          _c("span", [_vm._v("Industry")]),
                          _vm._v(" "),
                          _vm.registration.data.questions && !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.registration.data.questions
                                        .business_industry
                                    ) +
                                    "\n            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.form.questions.business_industry,
                                      expression:
                                        "form.questions.business_industry"
                                    }
                                  ],
                                  staticClass: "fullw",
                                  class: {
                                    error:
                                      _vm.$v.form.questions.business_industry
                                        .$invalid
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.form.questions,
                                        "business_industry",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.businessIndustries, function(
                                  businessIndustry,
                                  key
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: key,
                                      domProps: { value: businessIndustry }
                                    },
                                    [_vm._v(_vm._s(businessIndustry))]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", { staticStyle: { width: "40%" } }, [
                          _c("span", { staticClass: "form-label" }, [
                            _vm._v("Business Description"),
                            _vm.editStatus && _vm.$v.form.description.$invalid
                              ? _c("span", { staticClass: "error-message" }, [
                                  _vm._v("  *")
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          !_vm.editStatus
                            ? _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.registration.data.description) +
                                    "\n            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editStatus
                            ? _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.description,
                                    expression: "form.description"
                                  }
                                ],
                                ref: "description",
                                staticClass: "fullw",
                                class: {
                                  error: _vm.$v.form.description.$invalid
                                },
                                domProps: { value: _vm.form.description },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "description",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("li", [_vm._v(" ")]),
                        _vm._v(" "),
                        _c("li", [_vm._v(" ")]),
                        _vm._v(" "),
                        _c("li", [_vm._v(" ")])
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "popup", class: { open: _vm.open } }, [
        _c("div", { staticClass: "pop-inner", style: { maxWidth: "400px" } }, [
          _c("div", { staticClass: "pop-box" }, [
            _c("h2", [_vm._v("Enter Management PIN to Continue")]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pin,
                      expression: "pin"
                    }
                  ],
                  staticClass: "pin-code",
                  attrs: {
                    oninput:
                      "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                    type: "number",
                    maxlength: "4"
                  },
                  domProps: { value: _vm.pin },
                  on: {
                    keyup: _vm.pinInput,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.pin = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c(
                "div",
                { staticClass: "pf-field" },
                [_vm.pinFailed ? [_vm._v("Invalid PIN")] : _vm._e()],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row buttons" }, [
              _c(
                "a",
                {
                  staticClass: "pop-btn",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancelPin()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "pop-btn",
                  on: {
                    click: function($event) {
                      return _vm.checkPin()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.addModal, width: 1250 },
          on: {
            "update:open": function($event) {
              _vm.addModal = $event
            }
          }
        },
        [
          _vm.addModal
            ? [
                _c("h4", [_vm._v("Add Contact")]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "div",
                    { staticClass: "content-form" },
                    [
                      _vm._l(_vm.beneficialOwners, function(
                        beneficialOwner,
                        i
                      ) {
                        return [
                          beneficialOwner.type === "Beneficial Owner"
                            ? _c(
                                "div",
                                {
                                  key: beneficialOwner.email,
                                  staticClass: "beneficial-owner-content"
                                },
                                [
                                  _c("p", [_vm._v("Beneficial Owner - ")]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(beneficialOwner.firstName) +
                                        " " +
                                        _vm._s(beneficialOwner.lastName)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "beneficial-owner-percent" },
                                    [_vm._v("Ownership Percent")]
                                  ),
                                  _vm._v(" "),
                                  _vm.addContactForm.type !== "Beneficial Owner"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "beneficial-owner-percent-content"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              beneficialOwner.ownership_percentage
                                            )
                                          )
                                        ]
                                      )
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.editContactForm.contacts[i]
                                                .ownership_percentage,
                                            expression:
                                              "editContactForm.contacts[i].ownership_percentage"
                                          }
                                        ],
                                        staticClass: "addContactsInput",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value:
                                            _vm.editContactForm.contacts[i]
                                              .ownership_percentage
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.editContactForm.contacts[i],
                                              "ownership_percentage",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                ]
                              )
                            : _vm._e()
                        ]
                      }),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "cp-fields",
                          staticStyle: {
                            "margin-top": "10px",
                            "justify-content": "flex-start"
                          }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact Type "),
                              _vm.$v.addContactForm.type.$dirty &&
                              _vm.$v.addContactForm.type.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.type,
                                    expression: "addContactForm.type"
                                  }
                                ],
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "type",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _vm.registration.status !== "Active" &&
                                _vm.registration.status !== "Complete"
                                  ? _c(
                                      "option",
                                      { attrs: { value: "Beneficial Owner" } },
                                      [_vm._v("Beneficial Owner")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "Authorized Person" } },
                                  [_vm._v("Authorized Person")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.$v.addContactForm.type.$dirty &&
                            _vm.$v.addContactForm.type.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.addContactForm.type === "Beneficial Owner"
                            ? _c("li", [
                                _c("span", { staticClass: "form-label" }, [
                                  _vm._v("Owner Percentage "),
                                  _vm.$v.addContactForm.ownership_percentage
                                    .$dirty &&
                                  _vm.$v.addContactForm.ownership_percentage
                                    .$invalid
                                    ? _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("  *")]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.addContactForm
                                            .ownership_percentage,
                                        expression:
                                          "addContactForm.ownership_percentage"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.addContactForm.ownership_percentage
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addContactForm,
                                          "ownership_percentage",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.$v.addContactForm.ownership_percentage
                                    .$dirty &&
                                  _vm.$v.addContactForm.ownership_percentage
                                    .$invalid
                                    ? _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "error-message" },
                                          [_vm._v("This field is required.")]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "cp-fields",
                          staticStyle: { "margin-top": "10px" }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("First Name "),
                              _vm.$v.addContactForm.firstName.$dirty &&
                              _vm.$v.addContactForm.firstName.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.addContactForm.firstName,
                                  expression: "addContactForm.firstName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.addContactForm.firstName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.addContactForm,
                                    "firstName",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.$v.addContactForm.firstName.$dirty &&
                            _vm.$v.addContactForm.firstName.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Last Name "),
                              _vm.$v.addContactForm.lastName.$dirty &&
                              _vm.$v.addContactForm.lastName.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.addContactForm.lastName,
                                  expression: "addContactForm.lastName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.addContactForm.lastName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.addContactForm,
                                    "lastName",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.$v.addContactForm.lastName.$dirty &&
                            _vm.$v.addContactForm.lastName.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Sex "),
                              _vm.$v.addContactForm.sex.$dirty &&
                              _vm.$v.addContactForm.sex.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.sex,
                                    expression: "addContactForm.sex"
                                  }
                                ],
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "sex",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "Male" } }, [
                                  _vm._v("Male")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "Female" } }, [
                                  _vm._v("Female")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.$v.addContactForm.sex.$dirty &&
                            _vm.$v.addContactForm.sex.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("SSN/Fiscal Number "),
                              _vm.$v.addContactForm.sin.$dirty &&
                              _vm.$v.addContactForm.sin.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addContactForm.sin,
                                        expression: "addContactForm.sin"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.addContactForm.sin },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addContactForm,
                                          "sin",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ],
                                _vm._v(" "),
                                _vm.$v.addContactForm.sin.$dirty &&
                                _vm.$v.addContactForm.sin.$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "dob" },
                            [
                              _c("span", { staticClass: "form-label" }, [
                                _vm._v("Date of Birth "),
                                _vm.$v.addContactForm.dob.$dirty &&
                                _vm.$v.addContactForm.dob.$invalid
                                  ? _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("  *")]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("VueDatePicker", {
                                attrs: {
                                  format: "YYYY-MM-DD",
                                  "min-date": _vm.date1,
                                  "max-date": _vm.date2
                                },
                                model: {
                                  value: _vm.addContactForm.dob,
                                  callback: function($$v) {
                                    _vm.$set(_vm.addContactForm, "dob", $$v)
                                  },
                                  expression: "addContactForm.dob"
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.addContactForm.dob.$dirty &&
                              _vm.$v.addContactForm.dob.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Phone "),
                              _vm.$v.addContactForm.phone.$dirty &&
                              _vm.$v.addContactForm.phone.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.phone,
                                    expression: "addContactForm.phone"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.addContactForm.phone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "phone",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.addContactForm.phone.$dirty &&
                              _vm.$v.addContactForm.phone.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Mobile")
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.mobile,
                                    expression: "addContactForm.mobile"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.addContactForm.mobile },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "mobile",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Email "),
                              _vm.$v.addContactForm.email.$dirty &&
                              _vm.$v.addContactForm.email.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.email,
                                    expression: "addContactForm.email"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.addContactForm.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.addContactForm.email.$dirty &&
                              _vm.$v.addContactForm.email.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "cp-fields",
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-bottom": "30px"
                          }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact Country "),
                              _vm.$v.addContactForm.country.$dirty &&
                              _vm.$v.addContactForm.country.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.country,
                                    expression: "addContactForm.country"
                                  }
                                ],
                                staticClass: "fullw",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "country",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.contactCountryOptions, function(
                                country,
                                key
                              ) {
                                return _c(
                                  "option",
                                  { key: key, domProps: { value: country.id } },
                                  [_vm._v(_vm._s(country.text))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm.$v.addContactForm.country.$dirty &&
                            _vm.$v.addContactForm.country.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact Address "),
                              _vm.$v.addContactForm.address.$dirty &&
                              _vm.$v.addContactForm.address.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.addContactForm.address,
                                  expression: "addContactForm.address"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.addContactForm.address },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.addContactForm,
                                    "address",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.$v.addContactForm.address.$dirty &&
                            _vm.$v.addContactForm.address.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact Address 2")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.addContactForm.address2,
                                  expression: "addContactForm.address2"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.addContactForm.address2 },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.addContactForm,
                                    "address2",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact City "),
                              _vm.$v.addContactForm.city.$dirty &&
                              _vm.$v.addContactForm.city.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addContactForm.city,
                                        expression: "addContactForm.city"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.addContactForm.city
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addContactForm,
                                          "city",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ],
                                _vm._v(" "),
                                _vm.$v.addContactForm.city.$dirty &&
                                _vm.$v.addContactForm.city.$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact State/Province "),
                              _vm.$v.addContactForm.state.$dirty &&
                              _vm.$v.addContactForm.state.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm.stateOptions(_vm.addContactForm.country)
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.addContactForm.state,
                                          expression: "addContactForm.state"
                                        }
                                      ],
                                      staticClass: "fullw",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.addContactForm,
                                            "state",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(
                                      _vm.stateOptions(
                                        _vm.addContactForm.country
                                      ),
                                      function(state, key) {
                                        return _c(
                                          "option",
                                          {
                                            key: key,
                                            domProps: { value: key }
                                          },
                                          [_vm._v(_vm._s(state))]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addContactForm.state,
                                        expression: "addContactForm.state"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.addContactForm.state
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addContactForm,
                                          "state",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                              _vm._v(" "),
                              _vm.$v.addContactForm.state.$dirty &&
                              _vm.$v.addContactForm.state.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact Zip/Postal Code "),
                              _vm.$v.addContactForm.zip.$dirty &&
                              _vm.$v.addContactForm.zip.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.zip,
                                    expression: "addContactForm.zip"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.addContactForm.zip },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "zip",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _vm.$v.addContactForm.zip.$dirty &&
                            _vm.$v.addContactForm.zip.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ]
                      ),
                      _vm._v("\n            Documents\n            "),
                      _c(
                        "ul",
                        {
                          staticClass: "document",
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-bottom": "30px"
                          }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Government Issued ID Type")
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.addContactForm.id_types[0].id_type,
                                    expression:
                                      "addContactForm.id_types[0].id_type"
                                  }
                                ],
                                staticClass: "fullw",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.addContactForm.id_types[0],
                                      "id_type",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.idTypes, function(country, key) {
                                return _c(
                                  "option",
                                  { key: key, domProps: { value: key } },
                                  [_vm._v(_vm._s(country))]
                                )
                              }),
                              0
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("form-field-text", {
                                attrs: {
                                  maxlength: 20,
                                  label: "Government Issued ID Number",
                                  required: ""
                                },
                                model: {
                                  value:
                                    _vm.addContactForm.id_types[0].id_number,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addContactForm.id_types[0],
                                      "id_number",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "addContactForm.id_types[0].id_number"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "div",
                              {
                                staticClass: "form-row form-fullw",
                                staticStyle: {
                                  position: "relative",
                                  width: "400px"
                                }
                              },
                              [
                                _c("form-field-upload", {
                                  attrs: {
                                    label:
                                      "Contact " +
                                      (_vm.addContactForm.id_types[0].id_type ||
                                        "Government Issued ID Type")
                                  },
                                  model: {
                                    value:
                                      _vm.addContactForm.id_types[0].document,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addContactForm.id_types[0],
                                        "document",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "addContactForm.id_types[0].document"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "div",
                              {
                                staticClass: "form-row form-fullw",
                                staticStyle: {
                                  position: "relative",
                                  width: "345px"
                                }
                              },
                              [
                                _c("form-field-upload", {
                                  attrs: {
                                    label: "Contact Address Confirmation"
                                  },
                                  model: {
                                    value: _vm.addContactForm.addressConfirm,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addContactForm,
                                        "addressConfirm",
                                        $$v
                                      )
                                    },
                                    expression: "addContactForm.addressConfirm"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.addContact()
                        }
                      }
                    },
                    [_vm._v("Add")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.addModal = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [_vm._v("Profile Type")]),
      _vm._v(" "),
      _c("div", { staticClass: "val" }, [
        _vm._v("\n              Personal\n            ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
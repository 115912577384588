var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", [
        _c("div", { staticClass: "flex-between" }, [
          _c("h2", { staticClass: "inner-headers" }, [
            _vm._v("Transaction > Statements")
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
            _c(
              "button",
              {
                staticClass: "second",
                on: {
                  click: function($event) {
                    _vm.openedFilters = true
                  }
                }
              },
              [
                _c("i", { staticClass: "el-icon-s-operation" }),
                _vm._v(" Filters")
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("applied-filters", {
        attrs: { filters: _vm.appliedFilters },
        on: {
          clear: function($event) {
            return _vm.clearFilters()
          },
          remove: function($event) {
            return _vm.removeFilter($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "accordion" }, [
        _c(
          "div",
          { class: { "cof-box": true, "cof-box-active": _vm.activeInfo } },
          [
            _c(
              "h4",
              {
                staticClass: "accordion-headline",
                on: {
                  click: function($event) {
                    _vm.activeInfo = !_vm.activeInfo
                  }
                }
              },
              [_vm._v("Statements")]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "total-number",
                on: {
                  click: function($event) {
                    _vm.activeInfo = !_vm.activeInfo
                  }
                }
              },
              [_vm._v("(" + _vm._s(_vm.totalNumber) + ") Click to expand")]
            ),
            _vm._v(" "),
            _c("section", { staticClass: "content-tlist" }, [
              _c(
                "table",
                { staticClass: "content-table" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(_vm.sortStatements, function(statement) {
                    return _c("tr", { key: statement.id }, [
                      _c("td", [
                        _c("span", [_vm._v("Statement Date")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("date")(statement.createdAt, "MM/D/YYYY")
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Start Date")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("date")(statement.start, "MM/D/YYYY")
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("End Date")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm._f("date")(statement.end, "MM/D/YYYY")) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Account")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              statement.registration.accounts[0]
                                ? statement.registration.accounts[0]
                                    .accountNumber
                                : null
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Account Type")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(statement.registration.type) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Profile")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(statement.registration.profileNumber) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Profile Name")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(statement.registration.name) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Number")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(statement.number) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("span", [_vm._v("Filename")]),
                          _vm._v(" "),
                          statement.file
                            ? [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/download/Statement/" + statement.id,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(statement.file) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._m(1, true)
                    ])
                  })
                ],
                2
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("statements-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Statement Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Start Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("End Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Account")]),
      _vm._v(" "),
      _c("th", [_vm._v("Account Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Profile")]),
      _vm._v(" "),
      _c("th", [_vm._v("Profile Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Number")]),
      _vm._v(" "),
      _c("th", [_vm._v("Filename")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Options")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$hasPerm("edit-profile")
        ? [
            !_vm.editStatus && _vm.showEditAdd
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    on: {
                      click: function($event) {
                        return _vm.edit()
                      }
                    }
                  },
                  [_vm._v("Edit")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editStatus && _vm.showEditAdd
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    on: {
                      click: function($event) {
                        return _vm.save()
                      }
                    }
                  },
                  [_vm._v("Save")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editStatus && _vm.showEditAdd
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: {
                      click: function($event) {
                        _vm.editStatus = false
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.registration.type === "Business" && _vm.showEditAdd
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.openAddContact()
                      }
                    }
                  },
                  [_vm._v("Add")]
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.registration.type === "Business"
        ? _c("registration-data-info", {
            ref: "contacts",
            attrs: {
              registration: _vm.registration,
              "hide-basics": "",
              "hide-info": "",
              "show-remove-contact-button": ""
            }
          })
        : _c("registration-data-info", {
            ref: "contacts",
            attrs: {
              registration: _vm.registration,
              "hide-address": "",
              "hide-contacts": "",
              "hide-basics": ""
            }
          }),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.addModal, width: 1250 },
          on: {
            "update:open": function($event) {
              _vm.addModal = $event
            }
          }
        },
        [
          _vm.addModal
            ? [
                _c("h4", [_vm._v("Add Contact")]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "div",
                    { staticClass: "content-form" },
                    [
                      _vm._l(_vm.beneficialOwners, function(
                        beneficialOwner,
                        i
                      ) {
                        return [
                          beneficialOwner.type === "Beneficial Owner"
                            ? _c(
                                "div",
                                {
                                  key: beneficialOwner.email,
                                  staticClass: "beneficial-owner-content"
                                },
                                [
                                  _c("p", [_vm._v("Beneficial Owner - ")]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(beneficialOwner.firstName) +
                                        " " +
                                        _vm._s(beneficialOwner.lastName)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "beneficial-owner-percent" },
                                    [_vm._v("Ownership Percent")]
                                  ),
                                  _vm._v(" "),
                                  _vm.addContactForm.type !== "Beneficial Owner"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "beneficial-owner-percent-content"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              beneficialOwner.ownership_percentage
                                            )
                                          )
                                        ]
                                      )
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.editContactForm.contacts[i]
                                                .ownership_percentage,
                                            expression:
                                              "editContactForm.contacts[i].ownership_percentage"
                                          }
                                        ],
                                        staticClass: "addContactsInput",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value:
                                            _vm.editContactForm.contacts[i]
                                              .ownership_percentage
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.editContactForm.contacts[i],
                                              "ownership_percentage",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                ]
                              )
                            : _vm._e()
                        ]
                      }),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "cp-fields",
                          staticStyle: {
                            "margin-top": "10px",
                            "justify-content": "flex-start"
                          }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact Type "),
                              _vm.$v.addContactForm.type.$dirty &&
                              _vm.$v.addContactForm.type.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.type,
                                    expression: "addContactForm.type"
                                  }
                                ],
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "type",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _vm.registration.status !== "Active" &&
                                _vm.registration.status !== "Complete"
                                  ? _c(
                                      "option",
                                      { attrs: { value: "Beneficial Owner" } },
                                      [_vm._v("Beneficial Owner")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "Authorized Person" } },
                                  [_vm._v("Authorized Person")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.$v.addContactForm.type.$dirty &&
                            _vm.$v.addContactForm.type.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.addContactForm.type === "Beneficial Owner"
                            ? _c("li", [
                                _c("span", { staticClass: "form-label" }, [
                                  _vm._v("Owner Percentage "),
                                  _vm.$v.addContactForm.ownership_percentage
                                    .$dirty &&
                                  _vm.$v.addContactForm.ownership_percentage
                                    .$invalid
                                    ? _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("  *")]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.addContactForm
                                            .ownership_percentage,
                                        expression:
                                          "addContactForm.ownership_percentage"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.addContactForm.ownership_percentage
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addContactForm,
                                          "ownership_percentage",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.$v.addContactForm.ownership_percentage
                                    .$dirty &&
                                  _vm.$v.addContactForm.ownership_percentage
                                    .$invalid
                                    ? _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "error-message" },
                                          [_vm._v("This field is required.")]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "cp-fields",
                          staticStyle: { "margin-top": "10px" }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("First Name "),
                              _vm.$v.addContactForm.firstName.$dirty &&
                              _vm.$v.addContactForm.firstName.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.addContactForm.firstName,
                                  expression: "addContactForm.firstName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.addContactForm.firstName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.addContactForm,
                                    "firstName",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.$v.addContactForm.firstName.$dirty &&
                            _vm.$v.addContactForm.firstName.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Last Name "),
                              _vm.$v.addContactForm.lastName.$dirty &&
                              _vm.$v.addContactForm.lastName.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.addContactForm.lastName,
                                  expression: "addContactForm.lastName"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.addContactForm.lastName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.addContactForm,
                                    "lastName",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.$v.addContactForm.lastName.$dirty &&
                            _vm.$v.addContactForm.lastName.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Sex "),
                              _vm.$v.addContactForm.sex.$dirty &&
                              _vm.$v.addContactForm.sex.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.sex,
                                    expression: "addContactForm.sex"
                                  }
                                ],
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "sex",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "Male" } }, [
                                  _vm._v("Male")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "Female" } }, [
                                  _vm._v("Female")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.$v.addContactForm.sex.$dirty &&
                            _vm.$v.addContactForm.sex.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("SSN/Fiscal Number "),
                              _vm.$v.addContactForm.sin.$dirty &&
                              _vm.$v.addContactForm.sin.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addContactForm.sin,
                                        expression: "addContactForm.sin"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.addContactForm.sin },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addContactForm,
                                          "sin",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ],
                                _vm._v(" "),
                                _vm.$v.addContactForm.sin.$dirty &&
                                _vm.$v.addContactForm.sin.$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("span", { staticClass: "form-label" }, [
                                _vm._v("Date of Birth "),
                                _vm.$v.addContactForm.dob.$dirty &&
                                _vm.$v.addContactForm.dob.$invalid
                                  ? _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("  *")]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("vue-englishdatepicker", {
                                model: {
                                  value: _vm.addContactForm.dob,
                                  callback: function($$v) {
                                    _vm.$set(_vm.addContactForm, "dob", $$v)
                                  },
                                  expression: "addContactForm.dob"
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.addContactForm.dob.$dirty &&
                              _vm.$v.addContactForm.dob.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Phone "),
                              _vm.$v.addContactForm.phone.$dirty &&
                              _vm.$v.addContactForm.phone.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.phone,
                                    expression: "addContactForm.phone"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.addContactForm.phone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "phone",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.addContactForm.phone.$dirty &&
                              _vm.$v.addContactForm.phone.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Mobile")
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.mobile,
                                    expression: "addContactForm.mobile"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.addContactForm.mobile },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "mobile",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Email "),
                              _vm.$v.addContactForm.email.$dirty &&
                              _vm.$v.addContactForm.email.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.email,
                                    expression: "addContactForm.email"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.addContactForm.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.addContactForm.email.$dirty &&
                              _vm.$v.addContactForm.email.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "cp-fields",
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-bottom": "30px"
                          }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact Country "),
                              _vm.$v.addContactForm.country.$dirty &&
                              _vm.$v.addContactForm.country.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.country,
                                    expression: "addContactForm.country"
                                  }
                                ],
                                staticClass: "fullw",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "country",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.contactCountryOptions, function(
                                country,
                                key
                              ) {
                                return _c(
                                  "option",
                                  { key: key, domProps: { value: key } },
                                  [_vm._v(_vm._s(country))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm.$v.addContactForm.country.$dirty &&
                            _vm.$v.addContactForm.country.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact Address "),
                              _vm.$v.addContactForm.address.$dirty &&
                              _vm.$v.addContactForm.address.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.addContactForm.address,
                                  expression: "addContactForm.address"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.addContactForm.address },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.addContactForm,
                                    "address",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.$v.addContactForm.address.$dirty &&
                            _vm.$v.addContactForm.address.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact Address 2")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.addContactForm.address2,
                                  expression: "addContactForm.address2"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.addContactForm.address2 },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.addContactForm,
                                    "address2",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact City "),
                              _vm.$v.addContactForm.city.$dirty &&
                              _vm.$v.addContactForm.city.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addContactForm.city,
                                        expression: "addContactForm.city"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.addContactForm.city
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addContactForm,
                                          "city",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ],
                                _vm._v(" "),
                                _vm.$v.addContactForm.city.$dirty &&
                                _vm.$v.addContactForm.city.$invalid
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "error-message" },
                                        [_vm._v("This field is required.")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact State/Province "),
                              _vm.$v.addContactForm.state.$dirty &&
                              _vm.$v.addContactForm.state.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm.stateOptions(_vm.addContactForm.country)
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.addContactForm.state,
                                          expression: "addContactForm.state"
                                        }
                                      ],
                                      staticClass: "fullw",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.addContactForm,
                                            "state",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(
                                      _vm.stateOptions(
                                        _vm.addContactForm.country
                                      ),
                                      function(state, key) {
                                        return _c(
                                          "option",
                                          {
                                            key: key,
                                            domProps: { value: key }
                                          },
                                          [_vm._v(_vm._s(state))]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.addContactForm.state,
                                        expression: "addContactForm.state"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.addContactForm.state
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.addContactForm,
                                          "state",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                              _vm._v(" "),
                              _vm.$v.addContactForm.state.$dirty &&
                              _vm.$v.addContactForm.state.$invalid
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-message" },
                                      [_vm._v("This field is required.")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Contact Zip/Postal Code "),
                              _vm.$v.addContactForm.zip.$dirty &&
                              _vm.$v.addContactForm.zip.$invalid
                                ? _c("span", { staticClass: "error-message" }, [
                                    _vm._v("  *")
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.addContactForm.zip,
                                    expression: "addContactForm.zip"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.addContactForm.zip },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.addContactForm,
                                      "zip",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _vm.$v.addContactForm.zip.$dirty &&
                            _vm.$v.addContactForm.zip.$invalid
                              ? _c("div", [
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v("This field is required.")
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ]
                      ),
                      _vm._v("\n          Documents\n          "),
                      _c(
                        "ul",
                        {
                          staticClass: "document",
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-bottom": "30px"
                          }
                        },
                        [
                          _c("li", [
                            _c("span", { staticClass: "form-label" }, [
                              _vm._v("Government Issued ID Type")
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.addContactForm.id_types[0].id_type,
                                    expression:
                                      "addContactForm.id_types[0].id_type"
                                  }
                                ],
                                staticClass: "fullw",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.addContactForm.id_types[0],
                                      "id_type",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.idTypes, function(country, key) {
                                return _c(
                                  "option",
                                  { key: key, domProps: { value: key } },
                                  [_vm._v(_vm._s(country))]
                                )
                              }),
                              0
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "li",
                            [
                              _c("form-field-text", {
                                attrs: {
                                  maxlength: 20,
                                  label: "Government Issued ID Number",
                                  required: ""
                                },
                                model: {
                                  value:
                                    _vm.addContactForm.id_types[0].id_number,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addContactForm.id_types[0],
                                      "id_number",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "addContactForm.id_types[0].id_number"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "div",
                              {
                                staticClass: "form-row form-fullw",
                                staticStyle: {
                                  position: "relative",
                                  width: "500px"
                                }
                              },
                              [
                                _c("form-field-upload", {
                                  attrs: {
                                    label:
                                      "Contact " +
                                      (_vm.addContactForm.id_types[0].id_type ||
                                        "Government Issued ID Type")
                                  },
                                  model: {
                                    value:
                                      _vm.addContactForm.id_types[0].document,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addContactForm.id_types[0],
                                        "document",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "addContactForm.id_types[0].document"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "document",
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-bottom": "30px"
                          }
                        },
                        [
                          _c("li", [
                            _c(
                              "div",
                              {
                                staticClass: "form-row form-fullw",
                                staticStyle: {
                                  position: "relative",
                                  width: "500px"
                                }
                              },
                              [
                                _c("form-field-upload", {
                                  attrs: {
                                    label: "Contact Address Confirmation"
                                  },
                                  model: {
                                    value: _vm.addContactForm.addressConfirm,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.addContactForm,
                                        "addressConfirm",
                                        $$v
                                      )
                                    },
                                    expression: "addContactForm.addressConfirm"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.addContact()
                        }
                      }
                    },
                    [_vm._v("Add")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.addModal = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "status-page" }, [
    _c("div", { staticClass: "ss-head" }, [
      _c("div", [
        _c("h1", [_vm._v("System Status")]),
        _vm._v(" "),
        _c("div", { staticClass: "next-schedule" }, [
          _c("label", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v("Scheduled maintenance or downtime:")
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("p", { staticStyle: { "font-size": "14px" } }, [
            _vm._v(_vm._s(_vm.scheduled || "no downtime scheduled"))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "status-list" }, [
      _c(
        "ul",
        _vm._l(_vm.items, function(item, index) {
          return _c("li", { class: { opened: _vm.opened[index] } }, [
            _c(
              "div",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggleOpened(index)
                  }
                }
              },
              [
                _c("a", { staticClass: "toggle", attrs: { href: "" } }, [
                  _vm._v("\n            " + _vm._s(item.title) + "\n          ")
                ])
              ]
            ),
            _vm._v(" "),
            _vm.opened[index]
              ? _c(
                  "ul",
                  _vm._l(item.items, function(subitem, si) {
                    return _c("li", [
                      _c("div", [_vm._v(_vm._s(subitem.name))]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(subitem.message))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "color", class: subitem.color }),
                      _vm._v(" "),
                      subitem.downtime
                        ? _c("div", { staticClass: "err" }, [
                            _vm._v(_vm._s(subitem.downtime))
                          ])
                        : _c("div", [_vm._v("Operational")])
                    ])
                  }),
                  0
                )
              : _vm._e()
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
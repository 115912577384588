var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.form
    ? _c(
        "div",
        { attrs: { id: "form-personal" } },
        [
          _vm.step === undefined || _vm.step === 1
            ? [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("register.personal_acc_heading")) + " "),
                  _c("span", [_vm._v("*")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-row form-split" },
                  [
                    _c("form-field-select", {
                      attrs: {
                        validator: _vm.v.sex,
                        label: _vm.$t("register.contact_gender"),
                        required: "",
                        options: {
                          Male: _vm.$t("register.gender_m"),
                          Female: _vm.$t("register.gender_f")
                        }
                      },
                      model: {
                        value: _vm.form.sex,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "sex", $$v)
                        },
                        expression: "form.sex"
                      }
                    }),
                    _vm._v(" "),
                    _c("form-field-date", {
                      attrs: {
                        dirty: _vm.v.$dirty,
                        label: _vm.$t("register.contact_birth"),
                        required: ""
                      },
                      model: {
                        value: _vm.form.dob,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "dob", $$v)
                        },
                        expression: "form.dob"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-row form-split" },
                  [
                    _c("form-field-text", {
                      attrs: {
                        validator: _vm.v.firstName,
                        label: _vm.$t("register.contact_FN"),
                        maxlength: 25,
                        required: ""
                      },
                      model: {
                        value: _vm.form.firstName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "firstName", $$v)
                        },
                        expression: "form.firstName"
                      }
                    }),
                    _vm._v(" "),
                    _c("form-field-text", {
                      attrs: {
                        validator: _vm.v.lastName,
                        label: _vm.$t("register.contact_LN"),
                        maxlength: 25,
                        required: ""
                      },
                      model: {
                        value: _vm.form.lastName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "lastName", $$v)
                        },
                        expression: "form.lastName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-row form-split" },
                  [
                    _c("form-field-text", {
                      attrs: {
                        validator: _vm.v.phone,
                        label: _vm.$t("register.contact_phone"),
                        maxlength: 20,
                        required: ""
                      },
                      model: {
                        value: _vm.form.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "phone", $$v)
                        },
                        expression: "form.phone"
                      }
                    }),
                    _vm._v(" "),
                    _c("form-field-text", {
                      attrs: {
                        validator: _vm.v.mobile,
                        label: _vm.$t("register.contact_mobile"),
                        maxlength: 20,
                        required: ""
                      },
                      model: {
                        value: _vm.form.mobile,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "mobile", $$v)
                        },
                        expression: "form.mobile"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "form-row form-split",
                    staticStyle: {
                      position: "relative",
                      "margin-bottom": "10px"
                    }
                  },
                  [
                    _c("form-field-text", {
                      attrs: {
                        validator: _vm.v.email,
                        label: _vm.$t("register.contact_email"),
                        required: ""
                      },
                      model: {
                        value: _vm.form.email,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email"
                      }
                    }),
                    _vm._v(" "),
                    _c("form-field-text", {
                      attrs: {
                        validator: _vm.v.sin,
                        label: _vm.$t("register.contact_ssn"),
                        maxlength: 15,
                        required: ""
                      },
                      model: {
                        value: _vm.form.sin,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "sin", $$v)
                        },
                        expression: "form.sin"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "what-this",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.ssnModal = true
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("register.whats_this")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.form.id_types, function(type, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "form-row form-split" },
                    [
                      _c("form-field-select", {
                        attrs: {
                          validator: _vm.v.id_types.$each[index].id_type,
                          label: _vm.$t("register.gov_issued_id_type"),
                          options: _vm.issueIdTypes(
                            _vm.form.id_types[index].id_type
                          ),
                          required: ""
                        },
                        model: {
                          value: _vm.form.id_types[index].id_type,
                          callback: function($$v) {
                            _vm.$set(_vm.form.id_types[index], "id_type", $$v)
                          },
                          expression: "form.id_types[index].id_type"
                        }
                      }),
                      _vm._v(" "),
                      _c("form-field-text", {
                        attrs: {
                          maxlength: 20,
                          validator: _vm.v.id_types.$each[index].id_number,
                          label: _vm.$t("register.gov_issued_id_num"),
                          required: ""
                        },
                        model: {
                          value: _vm.form.id_types[index].id_number,
                          callback: function($$v) {
                            _vm.$set(_vm.form.id_types[index], "id_number", $$v)
                          },
                          expression: "form.id_types[index].id_number"
                        }
                      })
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-row form-split" }, [
                  _c("div", [
                    _vm.form.id_types.length < _vm.idTypes.length
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addIssueType()
                              }
                            }
                          },
                          [_vm._v("+ " + _vm._s(_vm.$t("register.add_ID")))]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.form.id_types.length > 1
                    ? _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.removeIssueTypeId()
                            }
                          }
                        },
                        [_vm._v("- " + _vm._s(_vm.$t("register.remove_ID")))]
                      )
                    : _vm._e()
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.step === undefined || _vm.step === 2
            ? [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("register.personal_address_conf_header")) +
                      " "
                  ),
                  _c("span", [_vm._v("*")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-row form-split" },
                  [
                    _c(
                      "div",
                      { staticStyle: { position: "relative" } },
                      [
                        _c("form-field-select", {
                          attrs: {
                            validator: _vm.v.country,
                            label: _vm.$t("register.contact_country"),
                            required: "",
                            options: _vm.countryOptions
                          },
                          model: {
                            value: _vm.form.country,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "country", $$v)
                            },
                            expression: "form.country"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "what-this",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.countryModal = true
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("register.prohibited_c")))]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("form-field-text", {
                      attrs: {
                        validator: _vm.v.city,
                        label: _vm.$t("register.contact_city"),
                        required: "",
                        maxlength: 25
                      },
                      model: {
                        value: _vm.form.city,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "city", $$v)
                        },
                        expression: "form.city"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-row form-split" },
                  [
                    _c("form-field-text", {
                      attrs: {
                        validator: _vm.v.address,
                        maxlength: 50,
                        label: _vm.$t("register.contact_address"),
                        required: ""
                      },
                      model: {
                        value: _vm.form.address,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "address", $$v)
                        },
                        expression: "form.address"
                      }
                    }),
                    _vm._v(" "),
                    _c("form-field-text", {
                      attrs: {
                        maxlength: 50,
                        validator: _vm.v.address2,
                        label: _vm.$t("register.contact_address_2")
                      },
                      model: {
                        value: _vm.form.address2,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "address2", $$v)
                        },
                        expression: "form.address2"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-row form-split" },
                  [
                    _vm.stateOptions
                      ? _c("form-field-select", {
                          attrs: {
                            validator: _vm.v.state,
                            label: _vm.$t("register.contact_state"),
                            options: _vm.stateOptions,
                            required: ""
                          },
                          model: {
                            value: _vm.form.state,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "state", $$v)
                            },
                            expression: "form.state"
                          }
                        })
                      : _c("form-field-text", {
                          attrs: {
                            validator: _vm.v.state,
                            label: _vm.$t("register.contact_state"),
                            required: "",
                            maxlength: 50
                          },
                          model: {
                            value: _vm.form.state,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "state", $$v)
                            },
                            expression: "form.state"
                          }
                        }),
                    _vm._v(" "),
                    _c("form-field-text", {
                      attrs: {
                        validator: _vm.v.zip,
                        maxlength: 15,
                        label: _vm.$t("register.contact_zip"),
                        required: ""
                      },
                      model: {
                        value: _vm.form.zip,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "zip", $$v)
                        },
                        expression: "form.zip"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.step === undefined || _vm.step === 3
            ? [
                _c("h4", [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("register.personal_doc_submission")) +
                      "\n      "
                  ),
                  _c("span", [_vm._v("*")])
                ]),
                _vm._v(" "),
                _vm.form.submitLater
                  ? _c("p", [
                      _vm._v(_vm._s(_vm.$t("register.final_acc_activation")))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.form.id_types, function(type, idx) {
                  return _c(
                    "div",
                    {
                      key: "subdocs_" + idx,
                      staticClass: "form-row form-fullw",
                      staticStyle: { position: "relative" }
                    },
                    [
                      _c("form-field-upload", {
                        attrs: {
                          validator: _vm.v.id_types.$each[idx].document,
                          label:
                            (type.id_type ||
                              _vm.$t("register.contact_increment_ID_text")) +
                            " (10MB max)",
                          disabled: _vm.form.submit_later,
                          required: ""
                        },
                        model: {
                          value: _vm.form.id_types[idx].document,
                          callback: function($$v) {
                            _vm.$set(_vm.form.id_types[idx], "document", $$v)
                          },
                          expression: "form.id_types[idx].document"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "what-this",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.issuedModal = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("register.whats_this")))]
                      )
                    ],
                    1
                  )
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "popup",
            {
              attrs: { open: _vm.issuedModal },
              on: {
                "update:open": function($event) {
                  _vm.issuedModal = $event
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "scrollable",
                  on: {
                    "&scroll": function($event) {
                      return _vm.handleScroll($event)
                    }
                  }
                },
                [
                  _c("div", {}, [
                    _c("h2", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("register.bus_gov_issues_ID_popup_header")
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("register.bus_gov_issues_ID_popup_line1")
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("register.bus_gov_issues_ID_popup_line2")
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("register.bus_gov_issues_ID_popup_line3")
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "popup",
            {
              attrs: { open: _vm.ssnModal },
              on: {
                "update:open": function($event) {
                  _vm.ssnModal = $event
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "scrollable",
                  on: {
                    "&scroll": function($event) {
                      return _vm.handleScroll($event)
                    }
                  }
                },
                [
                  _c("div", {}, [
                    _c("h2", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("register.modal_ssn")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("register.modal_ssn_line1")) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n           " +
                          _vm._s(_vm.$t("register.modal_ssn_line2")) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p")
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "popup",
            {
              attrs: { open: _vm.countryModal },
              on: {
                "update:open": function($event) {
                  _vm.countryModal = $event
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "scrollable",
                  on: {
                    "&scroll": function($event) {
                      return _vm.handleScroll($event)
                    }
                  }
                },
                [
                  _c("div", {}, [
                    _c("h2", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("register.prohibited_c")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticStyle: { "column-count": "3" } },
                      _vm._l(_vm.prohibitedCountries, function(item) {
                        return _c("li", [
                          _vm._v(
                            "\n              " + _vm._s(item) + "\n            "
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ]
              )
            ]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
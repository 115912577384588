var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { open: _vm.open },
      on: {
        "update:open": function($event) {
          _vm.open = $event
        }
      }
    },
    [
      _c("div", { staticClass: "popup-header" }, [
        _c("h3", [_vm._v("New Scan")])
      ]),
      _vm._v(" "),
      [
        _c(
          "div",
          { staticClass: "scrollable" },
          [
            [
              _c("div", { staticClass: "content-form" }, [
                _c(
                  "div",
                  { staticClass: "form-row form-split" },
                  [
                    _c("form-field-select", {
                      attrs: {
                        label: "Scan Type",
                        validator: _vm.$v.submitForm.scanType,
                        options: _vm.providerOptions,
                        required: ""
                      },
                      model: {
                        value: _vm.submitForm.scanType,
                        callback: function($$v) {
                          _vm.$set(_vm.submitForm, "scanType", $$v)
                        },
                        expression: "submitForm.scanType"
                      }
                    }),
                    _vm._v(" "),
                    _c("form-field-select-search", {
                      attrs: {
                        label: "Select Profile",
                        validator: _vm.$v.submitForm.profile,
                        options: _vm.accountOptions,
                        required: ""
                      },
                      model: {
                        value: _vm.submitForm.profile,
                        callback: function($$v) {
                          _vm.$set(_vm.submitForm, "profile", $$v)
                        },
                        expression: "submitForm.profile"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-row form-fullw justify-content-center" },
                  [
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v("Notes for Compliance Scans")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "form-row form-fullw" }, [
                  _c("p", [
                    _vm._v(
                      "1 - KYC Reports are enabled for Business Profiles only"
                    )
                  ])
                ])
              ])
            ],
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                [
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      attrs: { disabled: _vm.$v.submitForm.$invalid },
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.open = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ]
              ],
              2
            )
          ],
          2
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
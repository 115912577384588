var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-form content-form-modern" },
    [
      _c(
        "div",
        { staticClass: "form-row form-split" },
        [
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c("form-field-select", {
                attrs: {
                  disabled: _vm.form.disabled,
                  label: "Country",
                  validator: _vm.v.country,
                  options: _vm.countryOptions,
                  required: ""
                },
                model: {
                  value: _vm.form.country,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "country", $$v)
                  },
                  expression: "form.country"
                }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "what-this",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.countryModal = true
                    }
                  }
                },
                [_vm._v("Prohibited Countries")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.stateOptions
            ? _c("form-field-select", {
                attrs: {
                  disabled: _vm.form.disabled,
                  label: "Province/State",
                  validator: _vm.v.state,
                  options: _vm.stateOptions,
                  required: ""
                },
                model: {
                  value: _vm.form.state,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "state", $$v)
                  },
                  expression: "form.state"
                }
              })
            : _c("form-field-text", {
                attrs: {
                  disabled: _vm.form.disabled,
                  validator: _vm.v.state,
                  label: "Province/State",
                  required: ""
                },
                model: {
                  value: _vm.form.state,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "state", $$v)
                  },
                  expression: "form.state"
                }
              })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-split" },
        [
          _c("form-field-text", {
            attrs: {
              disabled: _vm.form.disabled,
              label: "Address 1",
              validator: _vm.v.address,
              required: ""
            },
            model: {
              value: _vm.form.address,
              callback: function($$v) {
                _vm.$set(_vm.form, "address", $$v)
              },
              expression: "form.address"
            }
          }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              disabled: _vm.form.disabled,
              label: "Address 2",
              validator: _vm.v.address2,
              required: ""
            },
            model: {
              value: _vm.form.address2,
              callback: function($$v) {
                _vm.$set(_vm.form, "address2", $$v)
              },
              expression: "form.address2"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row form-split" },
        [
          _c("form-field-text", {
            attrs: {
              disabled: _vm.form.disabled,
              label: "City",
              validator: _vm.v.city,
              required: ""
            },
            model: {
              value: _vm.form.city,
              callback: function($$v) {
                _vm.$set(_vm.form, "city", $$v)
              },
              expression: "form.city"
            }
          }),
          _vm._v(" "),
          _c("form-field-text", {
            attrs: {
              disabled: _vm.form.disabled,
              label: "Postal",
              validator: _vm.v.postal,
              required: ""
            },
            model: {
              value: _vm.form.postal,
              callback: function($$v) {
                _vm.$set(_vm.form, "postal", $$v)
              },
              expression: "form.postal"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.countryModal },
          on: {
            "update:open": function($event) {
              _vm.countryModal = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "scrollable",
              on: {
                "&scroll": function($event) {
                  return _vm.handleScroll($event)
                }
              }
            },
            [
              _c("div", {}, [
                _c("h2", [_c("strong", [_vm._v("PROHIBITED COUNTRIES")])]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticStyle: { "column-count": "3" } },
                  _vm._l(_vm.prohibitedCountries, function(item, index) {
                    return _c("li", { key: index }, [
                      _vm._v("\n            " + _vm._s(item) + "\n          ")
                    ])
                  }),
                  0
                )
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checklist-steps" }, [
    _c("div", { staticClass: "step-form" }, [
      _c("div", { staticClass: "step-new-head" }, [
        _c("h3", { staticStyle: { color: "#000" } }, [
          _vm._v("Partner Boarding")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "step-nav" }, [
          _vm.step
            ? _c(
                "a",
                {
                  staticClass: "prev",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.prevStep($event)
                    }
                  }
                },
                [_vm._v("< < Back")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "cancel",
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancel($event)
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _vm.step < 4
            ? _c(
                "a",
                {
                  staticClass: "next",
                  class: { disabled: !_vm.canNext },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.nextStep($event)
                    }
                  }
                },
                [_vm._v("Next > >")]
              )
            : _vm.step == 4
            ? _c(
                "a",
                {
                  staticClass: "fin",
                  class: { disabled: !_vm.canNext },
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.finish($event)
                    }
                  }
                },
                [_vm._v("Finish")]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step-new-split" }, [
        _c("ul", { staticClass: "step-tabs st7" }, [
          _c(
            "li",
            {
              class: { past: _vm.isDone[1], active: _vm.step == 1 },
              on: {
                click: function($event) {
                  _vm.isDone[1] && (_vm.step = 1)
                }
              }
            },
            [_vm._v("\n          Documents\n        ")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { past: _vm.isDone[2], active: _vm.step == 2 },
              on: {
                click: function($event) {
                  _vm.isDone[2] && (_vm.step = 2)
                }
              }
            },
            [_vm._v("\n          Settings\n        ")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { past: _vm.isDone[3], active: _vm.step == 3 },
              on: {
                click: function($event) {
                  _vm.isDone[3] && (_vm.step = 3)
                }
              }
            },
            [_vm._v("\n          Schedule\n        ")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { past: _vm.isDone[4], active: _vm.step == 4 },
              on: {
                click: function($event) {
                  _vm.isDone[4] && (_vm.step = 4)
                }
              }
            },
            [_vm._v("\n          Finalize\n        ")]
          )
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "tab-content" }, [
          _vm.step == 1
            ? _c(
                "li",
                { staticClass: "active" },
                [_c("documents", { attrs: { partner: _vm.partner } })],
                1
              )
            : _vm.step == 2
            ? _c(
                "li",
                { staticClass: "active" },
                [_c("profile-settings", { attrs: { partner: _vm.partner } })],
                1
              )
            : _vm.step == 3
            ? _c(
                "li",
                { staticClass: "active" },
                [_c("profile-schedule", { attrs: { partner: _vm.partner } })],
                1
              )
            : _vm.step == 4
            ? _c(
                "li",
                { staticClass: "active" },
                [_c("finalize", { attrs: { partner: _vm.partner } })],
                1
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step-nav" }, [
        _vm.step
          ? _c(
              "a",
              {
                staticClass: "prev",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.prevStep($event)
                  }
                }
              },
              [_vm._v("< < Back")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "cancel",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.cancel($event)
              }
            }
          },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _vm.step < 4
          ? _c(
              "a",
              {
                staticClass: "next",
                class: { disabled: !_vm.canNext },
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.nextStep($event)
                  }
                }
              },
              [_vm._v("Next > >")]
            )
          : _vm.step == 5
          ? _c(
              "a",
              {
                staticClass: "fin",
                class: { disabled: !_vm.canNext },
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.finish($event)
                  }
                }
              },
              [_vm._v("Finish")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("section", { staticClass: "content-tlist" }, [
      _c(
        "table",
        { staticClass: "content-table" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm._l(_vm.features, function(feature, i) {
            return _c(
              "tr",
              { key: i, class: feature.secondary ? "secondary" : "" },
              [
                _c("td", [
                  _c("div", { staticClass: "switch" }, [
                    _c("i", {
                      class: feature.status == "Enabled" ? "green" : "grey"
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(feature.feature))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(feature.featureDetails))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    feature.feature == "Trade Desk"
                      ? [
                          feature.statusEnabled
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: { disabled: !_vm.featureStatus },
                                  on: {
                                    click: function($event) {
                                      return _vm.disable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Disable")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn main",
                                  attrs: { disabled: !_vm.featureStatus },
                                  on: {
                                    click: function($event) {
                                      return _vm.enable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Enable")]
                              )
                        ]
                      : feature.feature == "Exchange Desk"
                      ? [
                          feature.statusEnabled
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: {
                                    disabled: !_vm.featureStatusMultiCurrency
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.disable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Disable")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn main",
                                  attrs: {
                                    disabled: !_vm.featureStatusMultiCurrency
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Enable")]
                              )
                        ]
                      : feature.feature == "Dispute Manager"
                      ? [
                          feature.statusEnabled
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: {
                                    disabled: !_vm.featureStatusDebitCard
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.disable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Disable")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn main",
                                  attrs: {
                                    disabled: !_vm.featureStatusDebitCard
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Enable")]
                              )
                        ]
                      : feature.feature == "Physical Cards"
                      ? [
                          feature.statusEnabled
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: {
                                    disabled: !_vm.featureStatusDebitCard
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.disable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Disable")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn main",
                                  attrs: {
                                    disabled: !_vm.featureStatusDebitCard
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Enable")]
                              )
                        ]
                      : feature.feature == "Virtual Cards"
                      ? [
                          feature.statusEnabled
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: {
                                    disabled: !_vm.featureStatusDebitCard
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.disable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Disable")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn main",
                                  attrs: {
                                    disabled: !_vm.featureStatusDebitCard
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.enable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Enable")]
                              )
                        ]
                      : feature.feature == "Multi-Currency"
                      ? [
                          feature.statusEnabled
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  on: {
                                    click: function($event) {
                                      return _vm.disableMultiCurrency(
                                        feature.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Disable")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn main",
                                  on: {
                                    click: function($event) {
                                      return _vm.enable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Enable")]
                              )
                        ]
                      : feature.feature == "Digital Assets"
                      ? [
                          feature.statusEnabled
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  on: {
                                    click: function($event) {
                                      return _vm.disableDigitalAssets(
                                        feature.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Disable")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn main",
                                  on: {
                                    click: function($event) {
                                      return _vm.enable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Enable")]
                              )
                        ]
                      : [
                          feature.statusEnabled
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  on: {
                                    click: function($event) {
                                      return _vm.disable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Disable")]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "btn main",
                                  on: {
                                    click: function($event) {
                                      return _vm.enable(feature.id)
                                    }
                                  }
                                },
                                [_vm._v("Enable")]
                              )
                        ],
                    _vm._v(" "),
                    _c("button", { staticClass: "btn main" }, [
                      _vm._v("Manage")
                    ])
                  ],
                  2
                )
              ]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Feature")]),
      _vm._v(" "),
      _c("th", [_vm._v("Feature Details")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("h2", [_vm._v("Finalize")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "status" },
          [
            _vm._v("\n        Status\n        "),
            _c("status-icon", {
              attrs: {
                color:
                  _vm.complianceScan && _vm.complianceScan.confirm === "Pass"
                    ? "green"
                    : null
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "content-table", staticStyle: { "min-width": "unset" } },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("tr", [
            _c(
              "td",
              [
                _c("span", [_vm._v("Provider")]),
                _vm._v(" "),
                _vm.serviceProviders
                  ? [_vm._v(_vm._s(_vm.serviceProverName))]
                  : [_vm._v("N/A")]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("IDV")]),
                _vm._v(" "),
                !_vm.complianceScan
                  ? [
                      _vm.serviceProverName == "Solid" ||
                      _vm.serviceProverName == "Solid Test"
                        ? _c("mnb-select", {
                            staticClass: "select",
                            attrs: { options: _vm.yesNoOptions },
                            model: {
                              value: _vm.form.idv,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "idv", $$v)
                              },
                              expression: "form.idv"
                            }
                          })
                        : _c("mnb-select", {
                            staticClass: "select",
                            attrs: {
                              options: _vm.yesNoOptions,
                              disabled: true
                            },
                            model: {
                              value: (_vm.form.idv = 0),
                              callback: function($$v) {
                                _vm.$set(_vm.form, "idv = 0", $$v)
                              },
                              expression: "form.idv = 0"
                            }
                          })
                    ]
                  : [
                      _c("mnb-select", {
                        staticClass: "select",
                        attrs: { options: _vm.yesNoOptions, disabled: true },
                        model: {
                          value: _vm.complianceScan.idv,
                          callback: function($$v) {
                            _vm.$set(_vm.complianceScan, "idv", $$v)
                          },
                          expression: "complianceScan.idv"
                        }
                      })
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Date/Time")]),
                _vm._v(" "),
                !_vm.complianceScan
                  ? [_vm._v("N/A")]
                  : [
                      _vm._v(
                        _vm._s(
                          _vm._f("date")(_vm.complianceScan.createdAt, "LL")
                        ) +
                          " " +
                          _vm._s(
                            _vm._f("date")(_vm.complianceScan.createdAt, "LT")
                          )
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Account")]),
                _vm._v(" "),
                _vm.serviceProverName == "Solid" ||
                _vm.serviceProverName == "Solid Test"
                  ? [
                      _vm.solidAccount
                        ? [_vm._v(_vm._s(_vm.solidAccount.accountNumber))]
                        : _vm._e()
                    ]
                  : [
                      !_vm.complianceScan
                        ? [_vm._v("N/A")]
                        : [_vm._v(_vm._s(_vm.complianceScan.accountNumber))]
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c("td", [
              _c("span", [_vm._v("Status")]),
              _vm._v(
                "\n          " + _vm._s(_vm.registration.status) + "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Result")]),
                _vm._v(" "),
                _vm.serviceProverName == "Solid" ||
                _vm.serviceProverName == "Solid Test"
                  ? [
                      _vm.solidAccount
                        ? [
                            _vm._v(
                              _vm._s(
                                _vm.statusColorText[
                                  _vm.solidAccount.accountStatus
                                ]
                              )
                            )
                          ]
                        : _vm._e()
                    ]
                  : [
                      !_vm.complianceScan
                        ? void 0
                        : [_vm._v(_vm._s(_vm.complianceScan.result))]
                    ]
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "n-acc-check-opt" }, [
        _c(
          "div",
          { staticClass: "n-acc-actions" },
          [
            _vm.isMannual
              ? [
                  _vm.complianceScan &&
                  _vm.complianceScan.result == "Opened" &&
                  _vm.complianceScan.cipStatus == "APP" &&
                  _vm.complianceScan.amlStatus == "APP" &&
                  _vm.complianceScan.kycStatus == "APP" &&
                  _vm.complianceScan.confirm != "Pass"
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "main",
                            attrs: {
                              disabled:
                                !_vm.isAvailable ||
                                _vm.complianceScan.confirm == "Pass"
                            },
                            on: {
                              click: function($event) {
                                return _vm.confirmManual()
                              }
                            }
                          },
                          [_vm._v("\n              Confirm\n            ")]
                        )
                      ]
                    : _vm.complianceScan && _vm.complianceScan.confirm != "Pass"
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "main",
                            attrs: { disabled: !_vm.isAvailable },
                            on: {
                              click: function($event) {
                                return _vm.approveManual()
                              }
                            }
                          },
                          [_vm._v("Approve")]
                        )
                      ]
                    : [
                        _vm.registration.accounts[
                          _vm.registration.accounts.length - 1
                        ].profileSettings
                          ? [
                              !_vm.complianceScan
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "main",
                                      attrs: { disabled: !_vm.isAvailable },
                                      on: {
                                        click: function($event) {
                                          return _vm.runManual()
                                        }
                                      }
                                    },
                                    [_vm._v("Run")]
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                ]
              : [
                  _vm.argenseAccount && _vm.complianceScan
                    ? [
                        _vm.complianceScan.result == "Opened" ||
                        _vm.complianceScan.result == "Approved" ||
                        _vm.complianceScan.result == "Activation"
                          ? [
                              _c(
                                "button",
                                {
                                  staticClass: "main",
                                  attrs: {
                                    disabled:
                                      !_vm.isAvailable ||
                                      _vm.complianceScan.confirm == "Pass"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.confirm()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Confirm\n              "
                                  )
                                ]
                              )
                            ]
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.complianceScan &&
                  _vm.complianceScan.result == "Opened" &&
                  _vm.complianceScan.cipStatus == "APP" &&
                  _vm.complianceScan.amlStatus == "APP" &&
                  _vm.complianceScan.kycStatus == "APP" &&
                  _vm.complianceScan.confirm != "Pass"
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "main",
                            attrs: {
                              disabled:
                                !_vm.isAvailable ||
                                _vm.complianceScan.confirm == "Pass"
                            },
                            on: {
                              click: function($event) {
                                return _vm.confirm()
                              }
                            }
                          },
                          [_vm._v("\n              Confirm\n            ")]
                        )
                      ]
                    : _vm.complianceScan && _vm.complianceScan.confirm != "Pass"
                    ? [
                        _vm.systemMaintenanceStatus
                          ? _c(
                              "button",
                              {
                                staticClass: "main forcebtt",
                                attrs: { disabled: !_vm.isAvailable },
                                on: {
                                  click: function($event) {
                                    return _vm.update()
                                  }
                                }
                              },
                              [_vm._v("\n            Update\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.serviceProverName == "Solid" ||
                        _vm.serviceProverName == "Solid Test"
                          ? [
                              _vm.solidAccount
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "main",
                                      on: {
                                        click: function($event) {
                                          return _vm.confirm()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Confirm\n              "
                                      )
                                    ]
                                  )
                                : _vm.registration.type != "Business"
                                ? [
                                    _vm.complianceScan &&
                                    _vm.complianceScan.kycStatus == "approved"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "main",
                                            on: {
                                              click: function($event) {
                                                return _vm.setAccount()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Set Account\n                "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass: "main",
                                            on: {
                                              click: function($event) {
                                                _vm.documentOpen = true
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Document\n                "
                                            )
                                          ]
                                        )
                                  ]
                                : [
                                    _vm.complianceScan &&
                                    _vm.complianceScan.kycStatus ==
                                      "approved" &&
                                    _vm.complianceScan.kybStatus == "approved"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "main",
                                            on: {
                                              click: function($event) {
                                                return _vm.setAccount()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Set Account\n                "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass: "main",
                                            on: {
                                              click: function($event) {
                                                _vm.documentOpen = true
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Document\n                "
                                            )
                                          ]
                                        )
                                  ]
                            ]
                          : [_vm._v("\n               \n              ")],
                        _vm._v(" "),
                        _vm.systemMaintenanceStatus
                          ? [
                              !_vm.noforce
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "main forcebtt",
                                      on: {
                                        click: function($event) {
                                          return _vm.force()
                                        }
                                      }
                                    },
                                    [_vm._v("Force")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.registration.type == "Business"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "main forcebtt",
                                      on: {
                                        click: function($event) {
                                          return _vm.addContacts()
                                        }
                                      }
                                    },
                                    [_vm._v("Add Contacts")]
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                    : [
                        _vm.registration.accounts[
                          _vm.registration.accounts.length - 1
                        ].profileSettings
                          ? [
                              !_vm.complianceScan
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "main",
                                      attrs: { disabled: !_vm.isAvailable },
                                      on: {
                                        click: function($event) {
                                          return _vm.run()
                                        }
                                      }
                                    },
                                    [_vm._v("Run")]
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "content-table" },
        [
          _vm._m(3),
          _vm._v(" "),
          _vm.isMannual
            ? [
                _vm.registration.type != "Business"
                  ? [
                      _vm.complianceScan
                        ? _c("tr", [
                            _c("td", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.registration.data.firstName) +
                                  " " +
                                  _vm._s(_vm.registration.data.lastName) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "em",
                                {
                                  class:
                                    _vm.statusColorClasses[
                                      _vm.complianceScan.amlStatus
                                    ]
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.statusColorText[
                                        _vm.complianceScan.amlStatus
                                      ]
                                    )
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "em",
                                {
                                  class:
                                    _vm.statusColorClasses[
                                      _vm.complianceScan.cipStatus
                                    ]
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.statusColorText[
                                        _vm.complianceScan.cipStatus
                                      ]
                                    )
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "em",
                                {
                                  class:
                                    _vm.statusColorClasses[
                                      _vm.complianceScan.kycStatus
                                    ]
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.statusColorText[
                                        _vm.complianceScan.kycStatus
                                      ]
                                    )
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td")
                          ])
                        : _vm._e()
                    ]
                  : _vm.complianceScan
                  ? [
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.registration.data.legal_name) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "em",
                            {
                              class:
                                _vm.statusColorClasses[
                                  _vm.complianceScan.amlStatus
                                ]
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.statusColorText[
                                    _vm.complianceScan.amlStatus
                                  ]
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "em",
                            {
                              class:
                                _vm.statusColorClasses[
                                  _vm.complianceScan.cipStatus
                                ]
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.statusColorText[
                                    _vm.complianceScan.cipStatus
                                  ]
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "em",
                            {
                              class:
                                _vm.statusColorClasses[
                                  _vm.complianceScan.kycStatus
                                ]
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.statusColorText[
                                    _vm.complianceScan.kycStatus
                                  ]
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td")
                      ])
                    ]
                  : _vm._e()
              ]
            : _vm.serviceProverName == "Solid" ||
              _vm.serviceProverName == "Solid Test"
            ? [
                _vm.registration.type != "Business"
                  ? [
                      _vm.complianceScan
                        ? _c("tr", [
                            _c("td", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.registration.data.firstName) +
                                  " " +
                                  _vm._s(_vm.registration.data.lastName) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td"),
                            _vm._v(" "),
                            _c("td"),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "em",
                                {
                                  class:
                                    _vm.statusColorClasses[
                                      _vm.complianceScan.kycStatus
                                    ]
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.statusColorText[
                                        _vm.complianceScan.kycStatus
                                      ]
                                    )
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn main",
                                  on: {
                                    click: function($event) {
                                      return _vm.viewSolidMessage()
                                    }
                                  }
                                },
                                [_vm._v("View")]
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  : _vm.complianceScan
                  ? [
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.registration.data.legal_name) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "em",
                            {
                              class:
                                _vm.statusColorClasses[
                                  _vm.complianceScan.kybStatus
                                ]
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.statusColorText[
                                    _vm.complianceScan.kybStatus
                                  ]
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "btn main",
                              on: {
                                click: function($event) {
                                  return _vm.viewSolidBusinessMessage()
                                }
                              }
                            },
                            [_vm._v("View")]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.registration.data.contacts, function(contact) {
                        return _c("tr", [
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(contact.firstName) +
                                " " +
                                _vm._s(contact.lastName) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td"),
                          _vm._v(" "),
                          _c("td"),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "em",
                              {
                                class:
                                  _vm.statusColorClasses[
                                    _vm.complianceScan.kycStatus
                                  ]
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.statusColorText[
                                      _vm.complianceScan.kycStatus
                                    ]
                                  )
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "btn main",
                                on: {
                                  click: function($event) {
                                    return _vm.viewSolidContactMessage(
                                      contact.firstName + " " + contact.lastName
                                    )
                                  }
                                }
                              },
                              [_vm._v("View")]
                            )
                          ])
                        ])
                      })
                    ]
                  : _vm._e()
              ]
            : _vm.serviceProverName == "Argense Sandbox" ||
              _vm.serviceProverName == "Argense"
            ? [
                _vm.registration.type != "Business"
                  ? [
                      _vm.complianceScan
                        ? _c("tr", [
                            _c("td", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.registration.data.firstName) +
                                  " " +
                                  _vm._s(_vm.registration.data.lastName) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.complianceScan.document_status) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.complianceScan.compliance_status) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td")
                          ])
                        : _vm._e()
                    ]
                  : _vm.complianceScan
                  ? [
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.registration.data.legal_name) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.complianceScan.document_status) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.complianceScan.compliance_status) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.registration.data.contacts, function(
                        contact,
                        index
                      ) {
                        return _c("tr", { key: index }, [
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(contact.firstName) +
                                " " +
                                _vm._s(contact.lastName) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.complianceScan.contacts_status[index]
                                    .document_status
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.complianceScan.contacts_status[index]
                                    .compliance_status
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td")
                        ])
                      })
                    ]
                  : _vm._e()
              ]
            : _vm.complianceScan && _vm.complianceScan.response
            ? _vm._l(_vm.complianceScan.response.included, function(include) {
                return include.type == "contacts"
                  ? _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n            " +
                            _vm._s(include.attributes.name) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          include.attributes["aml-cleared"]
                            ? [
                                _c("em", { staticClass: "c1" }, [
                                  _vm._v("Passed")
                                ])
                              ]
                            : [
                                _c("em", { staticClass: "c2" }, [
                                  _vm._v("Pending")
                                ])
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          include.attributes["cip-cleared"]
                            ? [
                                _c("em", { staticClass: "c1" }, [
                                  _vm._v("Passed")
                                ])
                              ]
                            : [
                                _c("em", { staticClass: "c2" }, [
                                  _vm._v("Pending")
                                ])
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          include.attributes["identity-confirmed"]
                            ? [
                                _c("em", { staticClass: "c1" }, [
                                  _vm._v("Passed")
                                ])
                              ]
                            : [
                                _c("em", { staticClass: "c2" }, [
                                  _vm._v("Pending")
                                ])
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm.complianceScan &&
                          ((_vm.complianceScan.amlMessage &&
                            _vm.complianceScan.amlMessage[include.id]) ||
                            (_vm.complianceScan.cipMessage &&
                              _vm.complianceScan.cipMessage[include.id]) ||
                            (_vm.complianceScan.cipMessage &&
                              _vm.complianceScan.kycMessage[include.id]))
                            ? [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn main",
                                    on: {
                                      click: function($event) {
                                        return _vm.viewMessage(
                                          include.id,
                                          include.attributes.name
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("View")]
                                )
                              ]
                            : [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-secondary",
                                    on: {
                                      click: function($event) {
                                        return _vm.viewMessage(
                                          include.id,
                                          include.attributes.name
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("View")]
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.errorAccount, width: 400 },
          on: {
            "update:open": function($event) {
              _vm.errorAccount = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Error")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "scrollable" },
            [
              _vm._v(
                "\n        Error - " + _vm._s(_vm.errorDate) + "\n        "
              ),
              _c("p", [_vm._v(_vm._s(_vm.errorMessage))]),
              _vm._v(" "),
              _vm._l(_vm.messages, function(message) {
                return _c("p", { staticStyle: { "text-align": "center" } }, [
                  _c("b", [_vm._v(_vm._s(message) + " is missing.")])
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.providerMessages, function(providerMessage) {
                return _c("p", { staticStyle: { "text-align": "center" } }, [
                  _c("b", [_vm._v(_vm._s(providerMessage))])
                ])
              })
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "popup", class: { open: _vm.open } }, [
        _c("div", { staticClass: "pop-inner", style: { maxWidth: "400px" } }, [
          _c("div", { staticClass: "pop-box" }, [
            _c("h2", [_vm._v("Enter Admin PIN to Continue")]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pin,
                      expression: "pin"
                    }
                  ],
                  staticClass: "pin-code",
                  attrs: {
                    oninput:
                      "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                    type: "number",
                    maxlength: "4"
                  },
                  domProps: { value: _vm.pin },
                  on: {
                    keyup: _vm.pinInput,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.pin = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c(
                "div",
                { staticClass: "pf-field" },
                [_vm.pinFailed ? [_vm._v("Invalid PIN")] : _vm._e()],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row buttons" }, [
              _c(
                "a",
                {
                  staticClass: "pop-btn",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancelPin()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "pop-btn",
                  on: {
                    click: function($event) {
                      return _vm.checkPin()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.messageOpen, width: 900 },
          on: {
            "update:open": function($event) {
              _vm.messageOpen = $event
            }
          }
        },
        [
          _c("h2", [_vm._v("Compliance Messages")]),
          _vm._v(" "),
          _vm.serviceProverName == "Solid" ||
          _vm.serviceProverName == "Solid Test"
            ? _c(
                "div",
                { staticClass: "pf-row split comliance-header" },
                [
                  _vm.registration.type == "Business"
                    ? [
                        _vm.business
                          ? [
                              _vm.complianceScan
                                ? _c("p", { staticClass: "subtitle" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.statusColorText[
                                          _vm.complianceScan.kybStatus
                                        ]
                                      )
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.complianceScan
                                ? _c("p", { staticClass: "subtitle" }, [
                                    _vm._v(
                                      _vm._s(_vm.registration.data.legal_name)
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.complianceScan
                                ? _c("p", { staticClass: "subtitle" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.solidAccount
                                          ? _vm.solidAccount.accountNumber
                                          : ""
                                      )
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.complianceScan
                                ? _c("p", { staticClass: "subtitle" }, [
                                    _vm._v(_vm._s(_vm.complianceScan.createdAt))
                                  ])
                                : _vm._e()
                            ]
                          : [
                              _vm.complianceScan
                                ? _c("p", { staticClass: "subtitle" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.statusColorText[
                                          _vm.complianceScan.kycStatus
                                        ]
                                      )
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.complianceScan
                                ? _c("p", { staticClass: "subtitle" }, [
                                    _vm._v(_vm._s(_vm.contactName))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.complianceScan
                                ? _c("p", { staticClass: "subtitle" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.solidAccount
                                          ? _vm.solidAccount.accountNumber
                                          : ""
                                      )
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.complianceScan
                                ? _c("p", { staticClass: "subtitle" }, [
                                    _vm._v(_vm._s(_vm.complianceScan.createdAt))
                                  ])
                                : _vm._e()
                            ]
                      ]
                    : [
                        _vm.complianceScan
                          ? _c("p", { staticClass: "subtitle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.statusColorText[
                                    _vm.complianceScan.kycStatus
                                  ]
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.complianceScan
                          ? _c("p", { staticClass: "subtitle" }, [
                              _vm._v(
                                _vm._s(_vm.registration.data.firstName) +
                                  " " +
                                  _vm._s(_vm.registration.data.lastName)
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.complianceScan
                          ? _c("p", { staticClass: "subtitle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.solidAccount
                                    ? _vm.solidAccount.accountNumber
                                    : ""
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.complianceScan
                          ? _c("p", { staticClass: "subtitle" }, [
                              _vm._v(_vm._s(_vm.complianceScan.createdAt))
                            ])
                          : _vm._e()
                      ]
                ],
                2
              )
            : _c("div", { staticClass: "pf-row split comliance-header" }, [
                _vm.complianceScan
                  ? _c("p", { staticClass: "subtitle" }, [
                      _vm._v(_vm._s(_vm.complianceScan.result))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.complianceScan
                  ? _c("p", { staticClass: "subtitle" }, [
                      _vm._v(_vm._s(_vm.contactName))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.complianceScan
                  ? _c("p", { staticClass: "subtitle" }, [
                      _vm._v(_vm._s(_vm.complianceScan.accountNumber))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.complianceScan
                  ? _c("p", { staticClass: "subtitle" }, [
                      _vm._v(_vm._s(_vm.complianceScan.createdAt))
                    ])
                  : _vm._e()
              ]),
          _vm._v(" "),
          _vm.serviceProverName == "Solid" ||
          _vm.serviceProverName == "Solid Test"
            ? _c("table", { staticClass: "content-table" }, [
                _c("tr", [
                  _c("th", { staticStyle: { width: "25%" } }, [_vm._v("Date")]),
                  _vm._v(" "),
                  _c("th", { staticStyle: { width: "25%" } }, [
                    _vm._v("Review Code")
                  ]),
                  _vm._v(" "),
                  _c("th", { staticStyle: { width: "50%" } }, [
                    _vm._v("Review Message")
                  ])
                ]),
                _vm._v(" "),
                _vm.complianceScan && _vm.complianceScan.reviewCode
                  ? _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.complianceScan.createdAt))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.complianceScan.reviewCode))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm.complianceScan.reviewMessage))
                      ])
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _c("tr", [
                _c("th", { staticStyle: { width: "25%" } }, [_vm._v("Date")]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "25%" } }, [
                  _vm._v("AML Message")
                ]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "50%" } }, [
                  _vm._v("AML Message Details")
                ])
              ]),
              _vm._v(" "),
              _vm.complianceScan &&
              _vm.contactId &&
              _vm.complianceScan.amlMessage
                ? _vm._l(_vm.complianceScan.amlMessage[_vm.contactId], function(
                    item
                  ) {
                    return item.date
                      ? _c("tr", [
                          _c("td", [_vm._v(_vm._s(item.date))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.message))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.detail))])
                        ])
                      : _vm._e()
                  })
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _c("tr", [
                _c("th", { staticStyle: { width: "25%" } }, [_vm._v("Date")]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "25%" } }, [
                  _vm._v("CIP Message")
                ]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "50%" } }, [
                  _vm._v("CIP Message Details")
                ])
              ]),
              _vm._v(" "),
              _vm.complianceScan &&
              _vm.contactId &&
              _vm.complianceScan.cipMessage
                ? _vm._l(_vm.complianceScan.cipMessage[_vm.contactId], function(
                    item
                  ) {
                    return item.date
                      ? _c("tr", [
                          _c("td", [_vm._v(_vm._s(item.date))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.message))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.detail))])
                        ])
                      : _vm._e()
                  })
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _c("tr", [
                _c("th", { staticStyle: { width: "25%" } }, [_vm._v("Date")]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "25%" } }, [
                  _vm._v("KYC Message")
                ]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "50%" } }, [
                  _vm._v("KYC Message Details")
                ])
              ]),
              _vm._v(" "),
              _vm.complianceScan &&
              _vm.contactId &&
              _vm.complianceScan.kycMessage
                ? _vm._l(_vm.complianceScan.kycMessage[_vm.contactId], function(
                    item
                  ) {
                    return item.date
                      ? _c("tr", [
                          _c("td", [_vm._v(_vm._s(item.date))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.message))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.detail))])
                        ])
                      : _vm._e()
                  })
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _c("tr", [
                _c("th", { staticStyle: { width: "25%" } }, [_vm._v("Date")]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "25%" } }, [
                  _vm._v("KYC Action Items Message")
                ]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "50%" } }, [
                  _vm._v("KYC Action Items Message Details")
                ])
              ]),
              _vm._v(" "),
              _vm.complianceScan &&
              _vm.contactId &&
              _vm.complianceScan.kycActionItems
                ? _vm._l(
                    _vm.complianceScan.kycActionItems[_vm.contactId],
                    function(item) {
                      return item.date
                        ? _c("tr", [
                            _c("td", [_vm._v(_vm._s(item.date))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.message))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.detail))])
                          ])
                        : _vm._e()
                    }
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.business
            ? _c(
                "table",
                { staticClass: "content-table" },
                [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "25%" } }, [
                      _vm._v("Date")
                    ]),
                    _vm._v(" "),
                    _c("th", { staticStyle: { width: "25%" } }, [
                      _vm._v("KYB Message")
                    ]),
                    _vm._v(" "),
                    _c("th", { staticStyle: { width: "50%" } }, [
                      _vm._v("KYB Message Details")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.complianceScan && _vm.complianceScan.kycResults
                    ? [
                        _c("tr", [
                          _c("td", [
                            _vm._v(_vm._s(_vm.complianceScan.createdAt))
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s())]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(_vm.complianceScan.kycResults))
                          ])
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row buttons" }, [
            _c(
              "a",
              {
                staticClass: "pop-btn",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.messageOpen = false
                  }
                }
              },
              [_vm._v("Close")]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.documentOpen, width: 900 },
          on: {
            "update:open": function($event) {
              _vm.documentOpen = $event
            }
          }
        },
        [
          _c("h2", [_vm._v("Document Submission")]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row split comliance-header" }, [
            _c("p", { staticClass: "subtitle" }, [
              _vm._v(_vm._s(_vm.serviceProverName))
            ]),
            _vm._v(" "),
            _vm.registration.type == "Business"
              ? _c("p", { staticClass: "subtitle" }, [
                  _vm._v(_vm._s(_vm.registration.data.legal_name))
                ])
              : _c("p", { staticClass: "subtitle" }, [
                  _vm._v(
                    _vm._s(_vm.registration.data.firstName) +
                      " " +
                      _vm._s(_vm.registration.data.lastName)
                  )
                ]),
            _vm._v(" "),
            _c("p", { staticClass: "subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.registration.accounts[
                    this.registration.accounts.length - 1
                  ].accountNumber
                )
              )
            ])
          ]),
          _vm._v("\n      Documents List\n      "),
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _c("tr", [
                _c("th", [_vm._v("Select ")]),
                _vm._v(" "),
                _c("th", [_vm._v("Description")]),
                _vm._v(" "),
                _c("th", [_vm._v("Type")]),
                _vm._v(" "),
                _c("th", [_vm._v("Number")]),
                _vm._v(" "),
                _c("th", [_vm._v("Filename")])
              ]),
              _vm._v(" "),
              _vm._l(_vm.reorderedDocuments, function(document) {
                return _c("tr", { key: document.id }, [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedDocument,
                          expression: "selectedDocument"
                        }
                      ],
                      staticClass: "fee-checkbox",
                      attrs: { type: "radio", name: "document-select" },
                      domProps: {
                        value: document.id,
                        checked: _vm._q(_vm.selectedDocument, document.id)
                      },
                      on: {
                        change: function($event) {
                          _vm.selectedDocument = document.id
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(document.description))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(document.type))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(document.number))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(document.file))])
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row buttons" }, [
            _c(
              "button",
              {
                staticClass: "main",
                attrs: { disabled: !_vm.selectedDocument },
                on: {
                  click: function($event) {
                    return _vm.submitDocument()
                  }
                }
              },
              [_vm._v("Submit")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "second",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.documentOpen = false
                  }
                }
              },
              [_vm._v("Close")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "13%" } }, [_vm._v("Provider")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("IDV")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "13%" } }, [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Account")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "13%" } }, [_vm._v("Result")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [_c("h2", [_vm._v("Options")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h2", [_vm._v("Compliance")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "15%" } }, [
        _vm._v("\n          Name\n        ")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "13%" } }, [
        _vm._v("\n          Documents\n        ")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "13%" } }, [
        _vm._v("\n          Compliance\n        ")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [
        _vm._v("\n          Messages\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.registration
    ? _c(
        "div",
        { staticClass: "cp-box" },
        [
          _c("ul", { staticClass: "sub-tabs" }, [
            _vm.registration.type == "Business"
              ? _c(
                  "li",
                  {
                    class: { active: _vm.activeTab === "profile" },
                    on: {
                      click: function($event) {
                        _vm.activeTab = "profile"
                      }
                    }
                  },
                  [_c("a", [_vm._v("Business")])]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { active: _vm.activeTab === "contacts" },
                on: {
                  click: function($event) {
                    _vm.activeTab = "contacts"
                  }
                }
              },
              [_c("a", [_vm._v("Contacts")])]
            )
          ]),
          _vm._v(" "),
          _vm.$hasPerm("edit-profile") ? _c("hr") : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === "profile"
            ? _c("registration-data-info", {
                attrs: {
                  registration: _vm.registration,
                  "main-profile-only-name": "",
                  "hide-basics": "",
                  "hide-contacts": "",
                  "show-edit": ""
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === "contacts"
            ? _c("contacts", {
                attrs: { registration: _vm.registration, showEditAdd: true }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading ? _c("div", [_vm._v("Loading...")]) : _vm._e(),
    _vm._v(" "),
    !_vm.loading
      ? _c("section", [
          _vm.closed
            ? _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
                _vm._v("\n        All profiles listed below are closed\n      ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.closeAll($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n          Close all shown profiles\n          " +
                    _vm._s(
                      _vm.progress
                        ? "(" +
                            _vm.progress +
                            "/" +
                            _vm.registrations.length +
                            ")"
                        : ""
                    ) +
                    "\n        "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "content-tlist" }, [
            _c(
              "table",
              { staticClass: "content-table" },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm._l(_vm.registrations, function(registration) {
                  return _c("tr", { key: registration.id }, [
                    _c("td", [
                      _c("span", [_vm._v("Registration Date")]),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("date")(registration.createdAt, "MM/D/YYYY")
                          )
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("date")(registration.createdAt, "hh:mm A")
                          ) +
                          "\n              "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Profile")]),
                      _vm._v(" "),
                      _c("a", [
                        _vm._v(
                          _vm._s(
                            registration.profileNumber || registration.regId
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Name")]),
                        _vm._v(" "),
                        registration.data
                          ? [
                              registration.type === "Personal"
                                ? [
                                    _vm._v(
                                      _vm._s(registration.data.firstName) +
                                        " " +
                                        _vm._s(registration.data.lastName)
                                    )
                                  ]
                                : [_vm._v(_vm._s(registration.data.legal_name))]
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Profile Type")]),
                      _vm._v(_vm._s(registration.type))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Status")]),
                      _c("em", [_vm._v(_vm._s(registration.status))])
                    ])
                  ])
                })
              ],
              2
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Profile")]),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Profile Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
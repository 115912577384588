var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "space-around" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open"
            }
          ],
          staticClass: "content-tlist",
          staticStyle: { width: "70%", border: "none", padding: "30px" }
        },
        [
          _vm.form.depositType == "Assets"
            ? _c("div", { staticClass: "popup-header" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.isDeposit ? "Assets Deposit" : "Assets Withdrawal"
                    )
                  )
                ]),
                _vm._v(" "),
                _vm.transaction
                  ? _c("span", [
                      _vm._v(
                        "Transaction: " + _vm._s(_vm.transaction.transactionId)
                      )
                    ])
                  : _vm._e()
              ])
            : _vm.form.depositType == "Funds"
            ? _c("div", { staticClass: "popup-header" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.isDeposit ? "Deposit" : "Withdrawal"))
                ]),
                _vm._v(" "),
                _vm.transaction
                  ? _c("span", [
                      _vm._v(
                        "Transaction: " + _vm._s(_vm.transaction.transactionId)
                      )
                    ])
                  : _vm._e()
              ])
            : _c("div", { staticClass: "popup-header" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.isDeposit ? "Deposit" : "Withdrawal"))
                ]),
                _vm._v(" "),
                _vm.transaction
                  ? _c("span", [
                      _vm._v(
                        "Transaction: " + _vm._s(_vm.transaction.transactionId)
                      )
                    ])
                  : _vm._e()
              ]),
          _vm._v(" "),
          !_vm.form.depositType
            ? [
                _c("div", { staticClass: "scrollable" }, [
                  _c(
                    "div",
                    { staticClass: "content-form content-form-modern" },
                    [
                      _c(
                        "div",
                        { staticClass: "form-row form-split depositType" },
                        [
                          _c("form-field-select", {
                            attrs: {
                              label:
                                (_vm.isDeposit ? "Deposit" : "Withdrawal") +
                                " Type",
                              validator: _vm.$v.form.depositType,
                              options: _vm.depositTypeOptions,
                              required: ""
                            },
                            model: {
                              value: _vm.form.depositType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "depositType", $$v)
                              },
                              expression: "form.depositType"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.form.depositType
            ? [
                _vm.addModal
                  ? [
                      _vm.form.depositType == "Funds"
                        ? _c(
                            "div",
                            { staticClass: "scrollable" },
                            [
                              _vm.isDeposit && _vm.system.transaction_deposits
                                ? [
                                    _c("div", { staticClass: "content-form" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-row form-split" },
                                        [
                                          _c("form-field-select-search", {
                                            attrs: {
                                              label: "Account",
                                              validator: _vm.$v.form.account,
                                              options: _vm.accountOptions,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.account,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "account",
                                                  $$v
                                                )
                                              },
                                              expression: "form.account"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-select", {
                                            attrs: {
                                              disabled: !_vm.selectedAccount,
                                              label:
                                                "How will you " +
                                                (_vm.isDeposit
                                                  ? "deposit"
                                                  : "withdraw"),
                                              validator:
                                                _vm.$v.form.deposit_type,
                                              options: _vm.depositOptions,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.deposit_type,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "deposit_type",
                                                  $$v
                                                )
                                              },
                                              expression: "form.deposit_type"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "form-row form-split" },
                                        [
                                          _c("form-field-select", {
                                            attrs: {
                                              label: "Currency",
                                              validator: _vm.$v.form.currency,
                                              options: _vm.currencyOptions,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.currency,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "currency",
                                                  $$v
                                                )
                                              },
                                              expression: "form.currency"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-text", {
                                            attrs: {
                                              label:
                                                (_vm.isDeposit
                                                  ? "Deposit"
                                                  : "Withdrawal") + " Amount",
                                              validator: _vm.$v.form.amount,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.amount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "amount",
                                                  $$v
                                                )
                                              },
                                              expression: "form.amount"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "sum" }, [
                                      _c("div", [
                                        _vm._v("Fee: "),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm._f("money")(_vm.fee))
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "Total " +
                                            _vm._s(
                                              _vm.isDeposit
                                                ? "deposit"
                                                : "withdrawal"
                                            ) +
                                            ": "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm._f("money")(_vm.total))
                                          )
                                        ])
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.showInternationalWireForm
                                      ? _c("international-wire-form", {
                                          attrs: { v: _vm.$v.form },
                                          model: {
                                            value: _vm.form,
                                            callback: function($$v) {
                                              _vm.form = $$v
                                            },
                                            expression: "form"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showACHForm
                                      ? _c("ach-form", {
                                          attrs: { v: _vm.$v.form },
                                          model: {
                                            value: _vm.form,
                                            callback: function($$v) {
                                              _vm.form = $$v
                                            },
                                            expression: "form"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showPayee
                                      ? _c(
                                          "div",
                                          { staticClass: "content-form" },
                                          [
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [_vm._v("PAYEE INFORMATION")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _c("form-field-text", {
                                                  attrs: {
                                                    validator:
                                                      _vm.$v.form.payee,
                                                    label: "Payee",
                                                    required: ""
                                                  },
                                                  model: {
                                                    value: _vm.form.payee,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "payee",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.payee"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showAddressForm
                                      ? [
                                          _c(
                                            "h3",
                                            { staticClass: "subtitle" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.form.deposit_type ===
                                                    "domestic_wire"
                                                    ? "BENEFICIARY ADDRESS"
                                                    : "PAYEE ADDRESS"
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("address-dom-form", {
                                            attrs: { v: _vm.$v.form },
                                            model: {
                                              value: _vm.form,
                                              callback: function($$v) {
                                                _vm.form = $$v
                                              },
                                              expression: "form"
                                            }
                                          })
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "content-form" }, [
                                      _vm.form.use_further_credit_account
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "form-row form-split"
                                            },
                                            [
                                              _c("form-field-text", {
                                                attrs: {
                                                  label:
                                                    "Further Credit Account Name",
                                                  validator:
                                                    _vm.$v.form
                                                      .futher_credit_acc_name,
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .futher_credit_acc_name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "futher_credit_acc_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.futher_credit_acc_name"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("form-field-text", {
                                                attrs: {
                                                  label:
                                                    "Further Credit Account Number",
                                                  validator:
                                                    _vm.$v.form
                                                      .futher_credit_acc_number,
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .futher_credit_acc_number,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "futher_credit_acc_number",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.futher_credit_acc_number"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showA2AAccountNumber
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-row form-split acc-to-acc"
                                            },
                                            [
                                              _c("form-field-text", {
                                                attrs: {
                                                  label: "Account Number",
                                                  validator:
                                                    _vm.$v.form
                                                      .a2a_account_number,
                                                  required: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.a2a_account_number,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "a2a_account_number",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.a2a_account_number"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.invalidAccountNumber
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "error-message"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Invalid Account Number."
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.invalidFundsCurrency
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "error-message"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.form.currency
                                                          ) + " not available."
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("h3", { staticClass: "subtitle" }, [
                                        _vm._v("EXTRA INFORMATION")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "form-row form-split" },
                                        [
                                          _c("form-field-text", {
                                            attrs: {
                                              label: "Memo",
                                              maxlength: 150,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.memo,
                                              callback: function($$v) {
                                                _vm.$set(_vm.form, "memo", $$v)
                                              },
                                              expression: "form.memo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                : !_vm.isDeposit &&
                                  _vm.system.transaction_withdrawals
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "content-form content-form-modern"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("form-field-select-search", {
                                              attrs: {
                                                label: "Account",
                                                validator: _vm.$v.form.account,
                                                options: _vm.accountOptions,
                                                required: ""
                                              },
                                              model: {
                                                value: _vm.form.account,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "account",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.account"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("form-field-select", {
                                              attrs: {
                                                disabled: !_vm.selectedAccount,
                                                label: "Withdrawal Method",
                                                validator:
                                                  _vm.$v.form.deposit_type,
                                                options: _vm.depositOptions,
                                                required: ""
                                              },
                                              model: {
                                                value: _vm.form.deposit_type,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "deposit_type",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.deposit_type"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _c("form-field-select", {
                                                  attrs: {
                                                    label: "Currency",
                                                    validator:
                                                      _vm.$v.form.currency,
                                                    options:
                                                      _vm.currencyOptions,
                                                    required: ""
                                                  },
                                                  model: {
                                                    value: _vm.form.currency,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "currency",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.currency"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("form-field-text", {
                                                  attrs: {
                                                    label:
                                                      (_vm.isDeposit
                                                        ? "Deposit"
                                                        : "Withdrawal") +
                                                      " Amount",
                                                    validator:
                                                      _vm.$v.form.amount,
                                                    required: ""
                                                  },
                                                  model: {
                                                    value: _vm.form.amount,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "amount",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.amount"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "invalid-balance" },
                                      [
                                        _vm.invalidBalance
                                          ? _c(
                                              "div",
                                              { staticClass: "error-message" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Exceeds available balance. Please lower amount and try again."
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.showInternationalWireForm
                                      ? _c("international-wire-form", {
                                          attrs: { v: _vm.$v.form },
                                          model: {
                                            value: _vm.form,
                                            callback: function($$v) {
                                              _vm.form = $$v
                                            },
                                            expression: "form"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showACHForm
                                      ? _c("ach-form", {
                                          attrs: { v: _vm.$v.form },
                                          model: {
                                            value: _vm.form,
                                            callback: function($$v) {
                                              _vm.form = $$v
                                            },
                                            expression: "form"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showPayee
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-form content-form-modern"
                                          },
                                          [
                                            _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [_vm._v("PAYEE INFORMATION")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _c("form-field-text", {
                                                  attrs: {
                                                    validator:
                                                      _vm.$v.form.payee,
                                                    label: "Payee",
                                                    required: ""
                                                  },
                                                  model: {
                                                    value: _vm.form.payee,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "payee",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.payee"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showAddressForm
                                      ? [
                                          _c(
                                            "h3",
                                            { staticClass: "subtitle" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.form.deposit_type ===
                                                    "domestic_wire"
                                                    ? "BENEFICIARY ADDRESS"
                                                    : "PAYEE ADDRESS"
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("address-dom-form", {
                                            attrs: { v: _vm.$v.form },
                                            model: {
                                              value: _vm.form,
                                              callback: function($$v) {
                                                _vm.form = $$v
                                              },
                                              expression: "form"
                                            }
                                          })
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "content-form content-form-modern"
                                      },
                                      [
                                        _vm.form.use_further_credit_account
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _c("form-field-text", {
                                                  attrs: {
                                                    label:
                                                      "Further Credit Account Name",
                                                    validator:
                                                      _vm.$v.form
                                                        .futher_credit_acc_name,
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .futher_credit_acc_name,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "futher_credit_acc_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.futher_credit_acc_name"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("form-field-text", {
                                                  attrs: {
                                                    label:
                                                      "Further Credit Account Number",
                                                    validator:
                                                      _vm.$v.form
                                                        .futher_credit_acc_number,
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .futher_credit_acc_number,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "futher_credit_acc_number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.futher_credit_acc_number"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.showA2AAccountNumber
                                          ? _c(
                                              "h3",
                                              { staticClass: "subtitle" },
                                              [_vm._v("ACCOUNT INFORMATION")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.showA2AAccountNumber
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split acc-to-acc"
                                              },
                                              [
                                                _c("form-field-select-search", {
                                                  attrs: {
                                                    label: "Select Account",
                                                    validator:
                                                      _vm.$v.form
                                                        .a2a_account_number,
                                                    options:
                                                      _vm.toAccountOptions,
                                                    "option-group": !_vm.isCustomer,
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .a2a_account_number,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "a2a_account_number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.a2a_account_number"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _vm.invalidAccountNumber
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "error-message"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Invalid Account Number."
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.invalidFundsCurrency
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "error-message"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.form.currency
                                                            ) +
                                                              " not available."
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("h3", { staticClass: "subtitle" }, [
                                          _vm._v("EXTRA INFORMATION")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-fullw"
                                          },
                                          [
                                            _c("form-field-text", {
                                              attrs: {
                                                label: "Memo",
                                                maxlength: 150,
                                                required: ""
                                              },
                                              model: {
                                                value: _vm.form.memo,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "memo",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.memo"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "form-row form-max" },
                                          [
                                            _c("form-field-text", {
                                              attrs: {
                                                label: "Reference",
                                                maxlength: 60,
                                                required: "",
                                                validator: _vm.$v.form.reference
                                              },
                                              model: {
                                                value: _vm.form.reference,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "reference",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.reference"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                : [
                                    _vm.isDeposit
                                      ? _c(
                                          "div",
                                          { staticClass: "disabledText" },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.system
                                                    .disabled_deposits_text
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "disabledText" },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.system
                                                    .disabled_withdrawals_text
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                  ],
                              _vm._v(" "),
                              _c("div", { staticClass: "withdraw-sum" }, [
                                _c("div", [
                                  _vm._v(
                                    "Total " +
                                      _vm._s(
                                        _vm.isDeposit ? "deposit" : "withdrawal"
                                      ) +
                                      ": "
                                  ),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm._f("money")(_vm.total)))
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _vm.isDeposit &&
                                  !_vm.system.transaction_deposits
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "second",
                                          on: {
                                            click: function($event) {
                                              _vm.open = false
                                            }
                                          }
                                        },
                                        [_vm._v("Close")]
                                      )
                                    : !_vm.isDeposit &&
                                      !_vm.system.transaction_withdrawals
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "second",
                                          on: {
                                            click: function($event) {
                                              _vm.open = false
                                            }
                                          }
                                        },
                                        [_vm._v("Close")]
                                      )
                                    : [
                                        _vm.isDeposit
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "main",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.add()
                                                  }
                                                }
                                              },
                                              [_vm._v("Submit")]
                                            )
                                          : _c(
                                              "button",
                                              {
                                                staticClass: "main",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.withdraw()
                                                  }
                                                }
                                              },
                                              [_vm._v("Submit")]
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "second",
                                            on: {
                                              click: function($event) {
                                                _vm.open = false
                                              }
                                            }
                                          },
                                          [_vm._v("Cancel")]
                                        )
                                      ],
                                  _vm._v(" "),
                                  _vm.submitError
                                    ? _c(
                                        "label",
                                        { staticClass: "error-message" },
                                        [
                                          _vm._v(
                                            "Please check error(s) and try again."
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            2
                          )
                        : _c(
                            "div",
                            { staticClass: "scrollable" },
                            [
                              _vm.isDeposit && _vm.system.transaction_deposits
                                ? [
                                    _c("div", { staticClass: "content-form" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-row form-split" },
                                        [
                                          _c("form-field-select-search", {
                                            attrs: {
                                              label: "Account",
                                              validator: _vm.$v.form.account,
                                              options: _vm.accountOptions,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.account,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "account",
                                                  $$v
                                                )
                                              },
                                              expression: "form.account"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-select", {
                                            attrs: {
                                              label: "Assets Type",
                                              validator:
                                                _vm.$v.form.assetsCurrency,
                                              options:
                                                _vm.assetsCurrencyOptions,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.assetsCurrency,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "assetsCurrency",
                                                  $$v
                                                )
                                              },
                                              expression: "form.assetsCurrency"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "form-row form-split" },
                                        [
                                          _c("div"),
                                          _vm._v(" "),
                                          _c("form-field-text", {
                                            attrs: {
                                              label:
                                                "Units " +
                                                (_vm.isDeposit
                                                  ? "Deposit"
                                                  : "Withdrawal") +
                                                " Amount",
                                              validator:
                                                _vm.$v.form.assetsAmount,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.assetsAmount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "assetsAmount",
                                                  $$v
                                                )
                                              },
                                              expression: "form.assetsAmount"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "invalid-balance" },
                                      [
                                        _vm.invalidDepositFee
                                          ? _c(
                                              "div",
                                              { staticClass: "error-message" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "The Amount must be greater than the Fee."
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "sum" }, [
                                      _c("div", [
                                        _vm._v("Fee: "),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("money")(_vm.assetsFee)
                                            )
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "Total " +
                                            _vm._s(
                                              _vm.isDeposit
                                                ? "deposit"
                                                : "withdrawal"
                                            ) +
                                            ": "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("assets_balance")(
                                                _vm.totalAssets
                                              )
                                            )
                                          )
                                        ])
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "content-form" }, [
                                      _c("h3", { staticClass: "subtitle" }, [
                                        _vm._v("EXTRA INFORMATION")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "form-row form-split" },
                                        [
                                          _c("form-field-text", {
                                            attrs: {
                                              label: "Memo",
                                              maxlength: 150,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.memo,
                                              callback: function($$v) {
                                                _vm.$set(_vm.form, "memo", $$v)
                                              },
                                              expression: "form.memo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                : !_vm.isDeposit &&
                                  _vm.system.transaction_withdrawals
                                ? [
                                    _c("div", { staticClass: "content-form" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-row form-split" },
                                        [
                                          _c("form-field-select-search", {
                                            attrs: {
                                              label: "Account",
                                              validator: _vm.$v.form.account,
                                              options: _vm.accountOptions,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.account,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "account",
                                                  $$v
                                                )
                                              },
                                              expression: "form.account"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-select", {
                                            attrs: {
                                              label: "Assets Type",
                                              validator:
                                                _vm.$v.form.assetsCurrency,
                                              options:
                                                _vm.assetsCurrencyOptions,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.assetsCurrency,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "assetsCurrency",
                                                  $$v
                                                )
                                              },
                                              expression: "form.assetsCurrency"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "form-row form-split" },
                                        [
                                          _c("form-field-select", {
                                            attrs: {
                                              label: "Sending to",
                                              validator: _vm.$v.form.sendingTo,
                                              options: _vm.sendingToOptions,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.sendingTo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "sendingTo",
                                                  $$v
                                                )
                                              },
                                              expression: "form.sendingTo"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("form-field-text", {
                                            attrs: {
                                              label:
                                                "Units " +
                                                (_vm.isDeposit
                                                  ? "Deposit"
                                                  : "Withdrawal") +
                                                " Amount",
                                              validator:
                                                _vm.$v.form.assetsAmount,
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.form.assetsAmount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "assetsAmount",
                                                  $$v
                                                )
                                              },
                                              expression: "form.assetsAmount"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "invalid-balance" },
                                      [
                                        _vm.invalidAssetBalance
                                          ? _c(
                                              "div",
                                              { staticClass: "error-message" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Exceeds available balance. Please lower amount and try again."
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "invalid-balance" },
                                      [
                                        _vm.invalidToProvider
                                          ? _c(
                                              "div",
                                              { staticClass: "error-message" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Receiver cannot accept Account to Account Transfers. Please check information and try again."
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "sum" }, [
                                      _c("div", [
                                        _vm._v("Fee: "),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("money")(_vm.assetsFee)
                                            )
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "Total " +
                                            _vm._s(
                                              _vm.isDeposit
                                                ? "deposit"
                                                : "withdrawal"
                                            ) +
                                            ": "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("assets_balance")(
                                                _vm.totalAssets
                                              )
                                            )
                                          )
                                        ])
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _vm.form.sendingTo == "WalletAddress"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-form paddingless"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _c("form-field-text", {
                                                  attrs: {
                                                    validator:
                                                      _vm.$v.form.payeeToName,
                                                    label: "Pay to Name"
                                                  },
                                                  model: {
                                                    value: _vm.form.payeeToName,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "payeeToName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.payeeToName"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-row form-max"
                                              },
                                              [
                                                _c("form-field-text", {
                                                  attrs: {
                                                    validator:
                                                      _vm.$v.form
                                                        .payeeToWalletAddress,
                                                    label:
                                                      "Pay to Wallet Address",
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .payeeToWalletAddress,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "payeeToWalletAddress",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.payeeToWalletAddress"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-row form-max"
                                              },
                                              [
                                                _c("form-field-text", {
                                                  attrs: {
                                                    validator: _vm.$v.form.tag,
                                                    label: "Destination Tag",
                                                    required: ""
                                                  },
                                                  model: {
                                                    value: _vm.form.tag,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "tag",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.tag"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showInternationalWireForm
                                      ? _c("international-wire-form", {
                                          attrs: { v: _vm.$v.form },
                                          model: {
                                            value: _vm.form,
                                            callback: function($$v) {
                                              _vm.form = $$v
                                            },
                                            expression: "form"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showACHForm
                                      ? _c("ach-form", {
                                          attrs: { v: _vm.$v.form },
                                          model: {
                                            value: _vm.form,
                                            callback: function($$v) {
                                              _vm.form = $$v
                                            },
                                            expression: "form"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showPayee
                                      ? _c(
                                          "div",
                                          { staticClass: "content-form" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _c("form-field-text", {
                                                  attrs: {
                                                    validator:
                                                      _vm.$v.form.payee,
                                                    label: "Payee",
                                                    required: ""
                                                  },
                                                  model: {
                                                    value: _vm.form.payee,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "payee",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.payee"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showAddressForm
                                      ? [
                                          _c(
                                            "h3",
                                            { staticClass: "subtitle" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.form.deposit_type ===
                                                    "domestic_wire"
                                                    ? "BENEFICIARY ADDRESS"
                                                    : "PAYEE ADDRESS"
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("address-dom-form", {
                                            attrs: { v: _vm.$v.form },
                                            model: {
                                              value: _vm.form,
                                              callback: function($$v) {
                                                _vm.form = $$v
                                              },
                                              expression: "form"
                                            }
                                          })
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-form paddingless"
                                      },
                                      [
                                        _vm.form.use_further_credit_account
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split"
                                              },
                                              [
                                                _c("form-field-text", {
                                                  attrs: {
                                                    label:
                                                      "Further Credit Account Name",
                                                    validator:
                                                      _vm.$v.form
                                                        .futher_credit_acc_name,
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .futher_credit_acc_name,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "futher_credit_acc_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.futher_credit_acc_name"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("form-field-text", {
                                                  attrs: {
                                                    label:
                                                      "Further Credit Account Number",
                                                    validator:
                                                      _vm.$v.form
                                                        .futher_credit_acc_number,
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .futher_credit_acc_number,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "futher_credit_acc_number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.futher_credit_acc_number"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.form.sendingTo == "AccountTransfer"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-row form-split acc-to-acc"
                                              },
                                              [
                                                _c("form-field-select", {
                                                  attrs: {
                                                    label: "Select Account",
                                                    validator:
                                                      _vm.$v.form
                                                        .a2a_account_number,
                                                    options:
                                                      _vm.toAccountOptions,
                                                    "option-group": !_vm.isCustomer,
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .a2a_account_number,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "a2a_account_number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.a2a_account_number"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _vm.invalidAccountNumber
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "error-message"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Invalid Account Number."
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.invalidFundsCurrency
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "error-message"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.form.currency
                                                            ) +
                                                              " not available."
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.errorWallet
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "error-message"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "This account does not have an activated wallet for the asset type chosen. Please check information and try again."
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._m(0),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-row form-split template-name"
                                          },
                                          [
                                            _c("form-field-text", {
                                              attrs: {
                                                label: "Memo",
                                                maxlength: 150,
                                                required: ""
                                              },
                                              model: {
                                                value: _vm.form.memo,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "memo",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.memo"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                : [
                                    _vm.isDeposit
                                      ? _c(
                                          "div",
                                          { staticClass: "disabledText" },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.system
                                                    .disabled_deposits_text
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "disabledText" },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.system
                                                    .disabled_withdrawals_text
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                  ],
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _vm.isDeposit &&
                                  !_vm.system.transaction_deposits
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "second",
                                          on: {
                                            click: function($event) {
                                              _vm.open = false
                                            }
                                          }
                                        },
                                        [_vm._v("Close")]
                                      )
                                    : !_vm.isDeposit &&
                                      !_vm.system.transaction_withdrawals
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "second",
                                          on: {
                                            click: function($event) {
                                              _vm.open = false
                                            }
                                          }
                                        },
                                        [_vm._v("Close")]
                                      )
                                    : [
                                        _vm.isDeposit
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "main",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.depositAssets()
                                                  }
                                                }
                                              },
                                              [_vm._v("Submit")]
                                            )
                                          : _c(
                                              "button",
                                              {
                                                staticClass: "main",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.withdrawAssets()
                                                  }
                                                }
                                              },
                                              [_vm._v("Submit")]
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "second",
                                            on: {
                                              click: function($event) {
                                                _vm.open = false
                                              }
                                            }
                                          },
                                          [_vm._v("Cancel")]
                                        )
                                      ],
                                  _vm._v(" "),
                                  _vm.submitError
                                    ? _c(
                                        "label",
                                        { staticClass: "error-message" },
                                        [
                                          _vm._v(
                                            "Please check error(s) and try again."
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            2
                          )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.addConfirmModal
                  ? [
                      _vm.isDeposit
                        ? _c("div", { staticClass: "scrollable" }, [
                            _c("div", { staticClass: "content-form" }, [
                              _c("div", { staticClass: "form-row" }, [
                                _vm.transaction
                                  ? _c(
                                      "p",
                                      { staticClass: "transaction-status" },
                                      [
                                        _vm._v(
                                          "Status: " +
                                            _vm._s(_vm.transaction.status)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              this.form.deposit_type != "ach"
                                ? _c("div", { staticClass: "form-row" }, [
                                    _vm.transaction
                                      ? _c(
                                          "p",
                                          { staticClass: "transaction-status" },
                                          [
                                            _vm._v(
                                              "Reference: " +
                                                _vm._s(
                                                  _vm.transaction
                                                    .qPaymentReference
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "information" },
                                [
                                  _vm.transaction
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "transaction-status margin-10"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.depositOptionsText) +
                                              " Information"
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  this.form.deposit_type == "domestic_wire" ||
                                  this.form.deposit_type ==
                                    "international_wires"
                                    ? _c("p", { staticClass: "margin-10" }, [
                                        _vm._v(
                                          "Please see below for the details you will need to complete your payment process."
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  this.form.deposit_type == "checks"
                                    ? [_c("p", [_vm._v("No Address Provided")])]
                                    : this.form.deposit_type == "ach"
                                    ? [
                                        _c("p", [
                                          _vm._v(
                                            "ACH Deposit Added Successfully"
                                          )
                                        ])
                                      ]
                                    : [
                                        _vm.transaction &&
                                        _vm.transaction.instructions
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Depository Bank Name")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction
                                                      .instructions[
                                                      "depository-bank-name"
                                                    ]
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction &&
                                        _vm.transaction.instructions
                                          ? _c("p", [
                                              _c("b", [_vm._v("Bank Address")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction
                                                      .instructions[
                                                      "bank-address"
                                                    ]
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction &&
                                        _vm.transaction.instructions
                                          ? _c("p", [
                                              _c("b", [_vm._v("Bank Phone")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction
                                                      .instructions[
                                                      "bank-phone"
                                                    ]
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction &&
                                        _vm.transaction.instructions
                                          ? _c("p", [
                                              _c("b", [_vm._v("Credit To")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction
                                                      .instructions["credit-to"]
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction &&
                                        _vm.transaction.instructions
                                          ? _c("p", [
                                              _c("b", [_vm._v("Address")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction
                                                      .instructions[
                                                      "beneficiary-address"
                                                    ]
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction &&
                                        _vm.transaction.instructions
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Routing Number")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction
                                                      .instructions[
                                                      "routing-number"
                                                    ]
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction &&
                                        _vm.transaction.instructions
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Account Number")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction
                                                      .instructions[
                                                      "account-number"
                                                    ]
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction &&
                                        _vm.transaction.instructions
                                          ? _c("p", [
                                              _c("b", [_vm._v("SWIFT Code")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction
                                                      .instructions[
                                                      "swift-code"
                                                    ]
                                                  )
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                  _vm._v(" "),
                                  this.form.deposit_type == "domestic_wire"
                                    ? _c("p", { staticClass: "margin-10" }, [
                                        _c("b", [_vm._v("Note")]),
                                        _vm._v(
                                          ": Your domestic wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly."
                                        )
                                      ])
                                    : this.form.deposit_type ==
                                      "international_wires"
                                    ? _c("p", { staticClass: "margin-10" }, [
                                        _c("b", [_vm._v("Note")]),
                                        _vm._v(
                                          ": Your international wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly."
                                        )
                                      ])
                                    : _c("p", { staticClass: "margin-10" }, [
                                        _c("b", [_vm._v("Note")]),
                                        _vm._v(
                                          ": Please follow any specific funding instructions to ensure the funds are received quickly.  If you have any doubts or questions please verify your instructions with your account representative."
                                        )
                                      ])
                                ],
                                2
                              )
                            ])
                          ])
                        : _c("div", { staticClass: "scrollable" }, [
                            _c("div", { staticClass: "content-form" }, [
                              _c("div", { staticClass: "form-row" }, [
                                _vm.transaction
                                  ? _c(
                                      "p",
                                      { staticClass: "transaction-status" },
                                      [
                                        _vm._v(
                                          "Status: " +
                                            _vm._s(_vm.transaction.status)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "information" },
                                [
                                  _vm.transaction
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "transaction-status margin-10"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.withdrawOptionsText) +
                                              " Withdrawal Information"
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  this.form.deposit_type == "domestic_wire" ||
                                  this.form.deposit_type ==
                                    "international_wires"
                                    ? _c("p", { staticClass: "margin-10" }, [
                                        _vm._v(
                                          "Please see below for the details submitted for your withdrawal."
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  this.form.deposit_type ==
                                  "international_wires"
                                    ? [
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Bank Name")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(this.form.bank_name)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Bank Account Name")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(this.form.account_name)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Bank Account Number")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form.account_number
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Account Type")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(this.form.account_type)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Swift")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.swift)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Intermediary Bank Name")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .intermediary_bank_name
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  "Intermediary Bank Reference"
                                                )
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .intermediary_bank_reference
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  "Intermediary Bank Routing Number"
                                                )
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .intermediary_bank_routing_number
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  "Intermediary Bank SWIFT Code"
                                                )
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .intermediary_bank_swift_code
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  "Intermediary Bank Address"
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Country")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .intermediary_address
                                                      .country
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Province/State")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .intermediary_address
                                                      .state
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Address")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .intermediary_address
                                                      .address
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("City")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .intermediary_address.city
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Postal")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .intermediary_address
                                                      .postal
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("IBAN")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.iban)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Beneficiary Address")
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Country")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.country)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Province/State")
                                              ]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.state)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Address")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.address)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("City")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.city)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Postal")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.postal)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  "Further Credit Account Name"
                                                )
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .futher_credit_acc_name
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  "Further Credit Account Number"
                                                )
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .futher_credit_acc_number
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Reference")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(this.form.reference)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Memo")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.memo)
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    : this.form.deposit_type == "checks"
                                    ? [
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Amount")]),
                                              _vm._v(
                                                ": $" +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formattedTwoDecimal"
                                                    )(this.form.amount)
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Payee")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.payee)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Payee Address")])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Country")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.country)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Province/State")
                                              ]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.state)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Address")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.address)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("City")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.city)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Postal")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.postal)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Reference")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction.reference
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Memo")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(_vm.transaction.memo)
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    : this.form.deposit_type == "ach"
                                    ? [
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Amount")]),
                                              _vm._v(
                                                ": $" +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formattedTwoDecimal"
                                                    )(this.form.amount)
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Name of Bank Account")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(this.form.bank_name)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Account Type")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(this.form.account_type)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Routing Number")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form.routing_number
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Bank Account Number")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form.account_number
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Bank Account Type")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form.bank_account_type
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Reference")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction.reference
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Memo")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(_vm.transaction.memo)
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    : this.form.deposit_type == "domestic_wire"
                                    ? [
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Name of Bank Account")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction.bankName
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Routing Number")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction
                                                      .routingNumber
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Bank Account Number")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction
                                                      .accountNumber
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Account Type")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction.accountType
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Beneficiary Address")
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Country")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.country)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Province/State")
                                              ]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.state)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Address")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.address)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("City")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.city)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Postal")]),
                                              _vm._v(
                                                ": " + _vm._s(this.form.postal)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  "Further Credit Account Name"
                                                )
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .futher_credit_acc_name
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  "Further Credit Account Number"
                                                )
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form
                                                      .futher_credit_acc_number
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Reference")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction.reference
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Memo")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(_vm.transaction.memo)
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    : [
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Amount")]),
                                              _vm._v(
                                                ": $" +
                                                  _vm._s(
                                                    _vm._f(
                                                      "formattedTwoDecimal"
                                                    )(this.form.amount)
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Account Number")
                                              ]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    this.form.a2a_account_number
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Reference")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    _vm.transaction.reference
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.transaction
                                          ? _c("p", [
                                              _c("b", [_vm._v("Memo")]),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(_vm.transaction.memo)
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                  _vm._v(" "),
                                  _vm._m(1)
                                ],
                                2
                              )
                            ])
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                _vm.open = false
                              }
                            }
                          },
                          [_vm._v("Close")]
                        )
                      ])
                    ]
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("h3", { staticClass: "subtitle" }, [_vm._v("EXTRA INFORMATION")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "margin-10" }, [
      _c("b", [_vm._v("Note")]),
      _vm._v(
        ": If you have any doubts or questions please verify your instructions with your account representative. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.registration
    ? _c(
        "div",
        { staticClass: "cp-box" },
        [
          _vm.featureStatus == null
            ? void 0
            : [
                !_vm.featureStatus
                  ? [
                      _c("h3", { staticClass: "monthly-head" }, [
                        _vm._v(
                          "This feature is not enabled. Please contact system administration in order to have it enabled."
                        )
                      ]),
                      _vm._v(" "),
                      _c("h3", { staticClass: "monthly-head" }, [
                        _vm._v("Feature - Card Services")
                      ])
                    ]
                  : [
                      _c("ul", { staticClass: "sub-tabs" }, [
                        _c(
                          "li",
                          {
                            class: { active: _vm.activeTab === "DebitCard" },
                            on: {
                              click: function($event) {
                                _vm.activeTab = "DebitCard"
                              }
                            }
                          },
                          [_c("a", [_vm._v("Debit Card")])]
                        )
                      ]),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _vm.activeTab === "DebitCard"
                        ? _c("debit-card", {
                            attrs: { registration: _vm.registration }
                          })
                        : _vm._e()
                    ]
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
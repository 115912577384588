var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", [
        _c("div", { staticClass: "flex-between" }, [
          _c("h2", { staticClass: "inner-headers" }),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
            _c(
              "button",
              {
                staticClass: "second",
                on: {
                  click: function($event) {
                    _vm.openedFilters = true
                  }
                }
              },
              [
                _c("i", { staticClass: "el-icon-s-operation" }),
                _vm._v(" Filters")
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("applied-filters", {
        attrs: { filters: _vm.appliedFilters },
        on: {
          clear: function($event) {
            return _vm.clearFilters()
          },
          remove: function($event) {
            return _vm.removeFilter($event)
          }
        }
      }),
      _vm._v(" "),
      _c("section", { staticClass: "content-tlist" }, [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.logs, function(item, i) {
              return _c(
                "tr",
                { key: i },
                [
                  [
                    _c("td", [
                      _c("span", [_vm._v("Date")]),
                      _vm._v(
                        _vm._s(
                          _vm._f("date")(item.createdAt, "dddd, MMMM D YYYY")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Version")]),
                      _vm._v(
                        "\n            " + _vm._s(item.version) + "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: { width: "500px", "padding-right": "10px" }
                      },
                      [
                        _c("span", [_vm._v("Description")]),
                        _vm._v(" "),
                        _c("mnb-textarea", {
                          attrs: {
                            classes: { full: true },
                            disabled: true,
                            full: ""
                          },
                          on: { input: _vm.mixin_autoResize_resize },
                          model: {
                            value: item.description,
                            callback: function($$v) {
                              _vm.$set(item, "description", $$v)
                            },
                            expression: "item.description"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("change-log-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Date")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Version")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "40%" } }, [_vm._v("Description")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("h2", [_vm._v("Finalize")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "status" },
          [
            _vm._v("\n      Status\n      "),
            _c("status-icon", {
              attrs: { color: _vm.partnerStatus === "Pass" ? "green" : null }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "content-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("tr", [
          _c("td", [
            _c("span", [_vm._v("Partner Type")]),
            _vm._v("\n        " + _vm._s(_vm.partner.type) + "\n      ")
          ]),
          _vm._v(" "),
          _c("td", [
            _c("span", [_vm._v("Partner Number")]),
            _vm._v(
              "\n        " + _vm._s(_vm.partner.partnerNumber) + "\n      "
            )
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _vm._m(4)
        ])
      ]),
      _vm._v(" "),
      !_vm.approved && _vm.partner.status != "Denied"
        ? [
            _vm._m(5),
            _vm._v(" "),
            _c("div", { staticClass: "n-acc-check-opt" }, [
              _c("div", { staticClass: "n-acc-actions" }, [
                _vm.$hasPerm("partners-approve")
                  ? _c(
                      "button",
                      {
                        staticClass: "main",
                        on: {
                          click: function($event) {
                            return _vm.confirm()
                          }
                        }
                      },
                      [_vm._v("\n        Approve\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$hasPerm("partners-approve")
                  ? _c(
                      "button",
                      {
                        staticClass: "main",
                        on: {
                          click: function($event) {
                            return _vm.deny(_vm.partner.id)
                          }
                        }
                      },
                      [_vm._v("\n        Deny\n      ")]
                    )
                  : _vm._e()
              ])
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "13%" } }, [_vm._v("Partner Type")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Partner Number")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "13%" } }),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "10%" } }),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "13%" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Date/Time")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Status")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Result")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [_c("h2", [_vm._v("Options")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
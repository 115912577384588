var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticStyle: { "margin-left": "30px" } }, [
        _c("div", { staticClass: "flex-between" }, [
          _c("div", [_vm._v(" ")]),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
            _c(
              "button",
              {
                staticClass: "second",
                on: {
                  click: function($event) {
                    _vm.openedFilters = true
                  }
                }
              },
              [
                _c("i", { staticClass: "el-icon-s-operation" }),
                _vm._v(" Filters")
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("applied-filters", {
        attrs: { filters: _vm.appliedFilters },
        on: {
          clear: function($event) {
            return _vm.clearFilters()
          },
          remove: function($event) {
            return _vm.removeFilter($event)
          }
        }
      }),
      _vm._v(" "),
      _c("section", { staticClass: "content-tlist" }, [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.statements, function(statement) {
              return _c("tr", { key: statement.id }, [
                _c("td", [
                  _c("span", [_vm._v("Statement")]),
                  _vm._v(
                    "\n          " + _vm._s(statement.number) + "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Statement Date")]),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("date")(statement.createdAt, "LL")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Filename")]),
                    _vm._v(" "),
                    statement.file
                      ? [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/download/PartnerStatement/" + statement.id,
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(statement.file) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Options")]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/download/PartnerStatement/" + statement.id,
                        target: "_blank"
                      }
                    },
                    [_c("button", { staticClass: "main" }, [_vm._v("View")])]
                  )
                ])
              ])
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("partner-stats-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Statement")]),
      _vm._v(" "),
      _c("th", [_vm._v("Statement Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Filename")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="content-form content-form-modern">
    <div class="form-row form-split">
      <div style="position: relative;">
        <form-field-select :disabled="form.disabled" v-model="form.country" label="Country" :validator="v.country" :options="countryOptions" required />
        <a class="what-this" @click.prevent="countryModal = true">Prohibited Countries</a>
      </div>
      <form-field-select :disabled="form.disabled" v-if="stateOptions" v-model="form.state" label="Province/State" :validator="v.state" :options="stateOptions" required />
      <form-field-text :disabled="form.disabled" v-else v-model="form.state" :validator="v.state" label="Province/State" required />
    </div>
    <div class="form-row form-split">
      <form-field-text :disabled="form.disabled" v-model="form.address" label="Address 1" :validator="v.address" required />
      <form-field-text :disabled="form.disabled" v-model="form.address2" label="Address 2" :validator="v.address2" required />
    </div>
    <div class="form-row form-split">
      <form-field-text :disabled="form.disabled" v-model="form.city" label="City" :validator="v.city" required />
      <form-field-text :disabled="form.disabled" v-model="form.postal" label="Postal" :validator="v.postal" required />
    </div>
    <popup :open.sync="countryModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>PROHIBITED COUNTRIES</strong></h2>
          <br/>
          <ul style="column-count: 3;">
            <li v-for="(item, index) in prohibitedCountries" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    usOnly: {
      type: Boolean,
      required: false
    },

    transactionCountries: {
      type: Array,
      required: false
    },
  },

  data () {
    return {
      form: this.value,
      countryModal: false,
    }
  },

  computed: {
    countryOptions () {
      if(this.usOnly){
        return [
          {
            id: "US",
            text: "United States"
          }
        ]
      }else{
        if(this.transactionCountries) {
          return window.fullCountries.filter(a => {
            return this.transactionCountries.includes(a.id)
          })
        }else{
          return window.countriesOptions
        }
      }
    },

    prohibitedCountries () {
      if(this.transactionCountries) { 
        return window.fullCountries.filter(a => {
            return !this.transactionCountries.includes(a.id)
          }).map(a =>(a.text))
      }else{
        return window.prohibitedCountries
      }
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.country)) return null

      return window.states[this.form.country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    }
  },

  watch: {
    'form.country' (value) {
      if(this.form.country == 'US' || this.form.country == 'CA') {
        this.form.state = null
      }
    },

    value: {
      handler () {
        this.form = this.value
      },
      deep: true,
      immediate: true
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .what-this {
    position: absolute;
    right: 10px;
    top: 54px;
    cursor: pointer;
    font-size: 13px;
  }
</style>


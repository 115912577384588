var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("h2", [_vm._v("Documents")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "status" },
          [
            _vm._v("\n      Status\n      "),
            _c("status-icon", {
              attrs: { color: _vm.documentsChecklist ? "green" : null }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "content-table", staticStyle: { "min-width": "unset" } },
        [
          _c("tr", [
            _c("th", [_vm._v("Description")]),
            _vm._v(" "),
            _c("th", [_vm._v("Type")]),
            _vm._v(" "),
            _c("th", [_vm._v("Filename")]),
            _vm._v(" "),
            _c("th", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Received")
            ]),
            _vm._v(" "),
            _c("th", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Verified")
            ]),
            _vm._v(" "),
            _vm.registration.status !== "Complete" && !_vm.mainAccount
              ? _c("th", { staticStyle: { "text-align": "right" } }, [
                  _vm._v("Options")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm._l(_vm.documents, function(document) {
            return _c("tr", { key: document.id }, [
              _c("td", [
                _c("span", [_vm._v("Description")]),
                _vm._v(_vm._s(document.description))
              ]),
              _vm._v(" "),
              _c("td", [
                _c("span", [_vm._v("Type")]),
                _vm._v(_vm._s(document.type))
              ]),
              _vm._v(" "),
              _c("td", [
                _c("span", [_vm._v("Filename")]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/download/RegDocument/" + document.id,
                      target: "_blank"
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(document.file) + "\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("td", { staticStyle: { "text-align": "center" } }, [
                _c("span", [_vm._v("Received")]),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "checkbox", disabled: "" },
                  domProps: { checked: document.file }
                })
              ]),
              _vm._v(" "),
              _c("td", { staticStyle: { "text-align": "center" } }, [
                _c("span", [_vm._v("Verified")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form[document.id],
                      expression: "form[document.id]"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    disabled: _vm.documentsChecklist || !document.file
                  },
                  domProps: {
                    checked: Array.isArray(_vm.form[document.id])
                      ? _vm._i(_vm.form[document.id], null) > -1
                      : _vm.form[document.id]
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.form[document.id],
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.form, document.id, $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.form,
                                document.id,
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.form, document.id, $$c)
                        }
                      },
                      function($event) {
                        return _vm.checkChange()
                      }
                    ]
                  }
                })
              ]),
              _vm._v(" "),
              _vm._m(0, true)
            ])
          }),
          _vm._v(" "),
          _c("tr", [
            _c(
              "td",
              { staticClass: "date-time", attrs: { colspan: "3" } },
              [
                _c("p", { staticClass: "date" }, [_vm._v("Date / Time:")]),
                _vm._v(" "),
                !_vm.documentsChecklist
                  ? [_vm._v("N/A")]
                  : [
                      _vm._v(
                        _vm._s(
                          _vm._f("date")(_vm.documentsChecklist.createdAt, "LL")
                        )
                      ),
                      _c("br"),
                      _vm._v(
                        _vm._s(
                          _vm._f("date")(_vm.documentsChecklist.createdAt, "LT")
                        )
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _vm.registration.status !== "Complete"
              ? _c(
                  "td",
                  {
                    staticStyle: { "text-align": "right" },
                    attrs: { colspan: "3" }
                  },
                  [
                    _c("span", [_vm._v("Options")]),
                    _vm._v(" "),
                    !_vm.documentsChecklist
                      ? _c(
                          "button",
                          {
                            class: { main: true, disabled: !_vm.checked },
                            attrs: { disabled: !_vm.checked },
                            on: {
                              click: function($event) {
                                return _vm.create()
                              }
                            }
                          },
                          [_vm._v("Confirm")]
                        )
                      : _vm.registration.status != "Active"
                      ? _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                return _vm.remove()
                              }
                            }
                          },
                          [_vm._v("Reset")]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Options")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
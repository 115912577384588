var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cp-box" },
    [
      _c(
        "ul",
        { staticClass: "sub-tabs", staticStyle: { "margin-bottom": "12px" } },
        [
          _c(
            "li",
            {
              class: { active: _vm.activeTab === "accounts" },
              on: {
                click: function($event) {
                  _vm.activeTab = "accounts"
                }
              }
            },
            [_c("a", [_vm._v("Accounts")])]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { active: _vm.activeTab === "statements" },
              on: {
                click: function($event) {
                  _vm.activeTab = "statements"
                }
              }
            },
            [_c("a", [_vm._v("Statements")])]
          ),
          _vm._v(" "),
          _vm.featureStatus
            ? _c(
                "li",
                {
                  class: { active: _vm.activeTab === "DisputeManager" },
                  on: {
                    click: function($event) {
                      _vm.activeTab = "DisputeManager"
                    }
                  }
                },
                [_c("a", [_vm._v("DisputeManager")])]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.activeTab === "accounts"
        ? _c("accounts-setup", { attrs: { registration: _vm.registration } })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab === "statements"
        ? _c("account-statements", {
            attrs: { registration: _vm.registration }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab === "DisputeManager"
        ? _c("dispute-manager", { attrs: { registration: _vm.registration } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
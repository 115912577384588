var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "amount-currency" },
    [
      _c("input", {
        attrs: { type: "text" },
        domProps: { value: _vm.amount },
        on: {
          input: function($event) {
            return _vm.$emit("update:amount", $event)
          }
        }
      }),
      _vm._v(" "),
      _c("dyn-select", {
        attrs: { value: _vm.currency, options: _vm.currencies },
        on: {
          input: function($event) {
            return _vm.$emit("update:currency", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "form-box reg-form" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ]
        },
        [_vm._v(_vm._s(_vm.stepDummy))]
      ),
      _vm._v(" "),
      _c("h1", { staticClass: "logo" }, [
        _c("img", {
          attrs: {
            src: _vm.base + _vm.system.logo,
            alt: _vm.system.logo + " Logo"
          }
        })
      ]),
      _vm._v(" "),
      _c("h2", [_c("span", [_vm._v(_vm._s(_vm.system.name))])]),
      _vm._v(" "),
      _c("h3", { staticStyle: { "margin-bottom": "10px" } }, [
        _vm._v(_vm._s(_vm.$t("register.title")))
      ]),
      _vm._v(" "),
      _vm.system.registration_form
        ? [
            _c("h4", { staticClass: "app-page-text" }, [
              _vm._v(_vm._s(_vm.system.app_page_text))
            ]),
            _vm._v(" "),
            !_vm.submitted
              ? [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.step == 0 && !_vm.hasAccount,
                          expression: "step==0 && !hasAccount"
                        }
                      ],
                      staticClass: "content-form"
                    },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$t("register.subtitle_q")))]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "form-row",
                          class: {
                            "form-split": _vm.hasAccount === null,
                            "form-fullw": _vm.hasAccount !== null
                          }
                        },
                        [
                          _vm.hasAccount === null
                            ? _c("form-field-select", {
                                attrs: {
                                  label: "Language",
                                  required: true,
                                  options: { en: "English", es: "Español" },
                                  "no-null": ""
                                },
                                model: {
                                  value: _vm.$i18n.locale,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$i18n, "locale", $$v)
                                  },
                                  expression: "$i18n.locale"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("form-field-select", {
                            attrs: {
                              label: _vm.$t("register.have_acc_label"),
                              required: true,
                              options: {
                                Yes: _vm.$t("register.have_acc_yes"),
                                No: _vm.$t("register.have_acc_no")
                              }
                            },
                            model: {
                              value: _vm.hasAccount,
                              callback: function($$v) {
                                _vm.hasAccount = $$v
                              },
                              expression: "hasAccount"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasAccount == "Yes" && _vm.step == 0,
                          expression: "hasAccount=='Yes' && step==0"
                        }
                      ],
                      staticClass: "content-form"
                    },
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("register.yes_description")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-center" }, [
                        _c("div", {}, [
                          _c("button", [
                            _c(
                              "a",
                              {
                                staticClass: "login-link",
                                attrs: { href: "/login" }
                              },
                              [_vm._v(_vm._s(_vm.$t("register.login_button")))]
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasAccount == "No" && _vm.step == 0,
                          expression: "hasAccount=='No' && step==0"
                        }
                      ],
                      staticClass: "content-form"
                    },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("register.acc_info")) + " "),
                        _c("span", [_vm._v("*")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-split" },
                        [
                          _c("form-field-select", {
                            attrs: {
                              label: _vm.$t("register.acc_type_selection"),
                              required: true,
                              options: _vm.businessPersonalOptions
                            },
                            model: {
                              value: _vm.accountType,
                              callback: function($$v) {
                                _vm.accountType = $$v
                              },
                              expression: "accountType"
                            }
                          }),
                          _vm._v(" "),
                          _c("form-field-select", {
                            attrs: {
                              label: _vm.$t("register.acc_curr_selection"),
                              required: true,
                              options: { USD: "USD" }
                            },
                            model: {
                              value: _vm.accountCurrency,
                              callback: function($$v) {
                                _vm.accountCurrency = $$v
                              },
                              expression: "accountCurrency"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.step == 0 && _vm.hasAccount != "Yes",
                          expression: "step==0 && hasAccount!='Yes'"
                        }
                      ],
                      staticClass: "content-stepnav"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "next",
                          attrs: {
                            disabled: !_vm.accountType || !_vm.accountCurrency
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.nextStep()
                            }
                          }
                        },
                        [_vm._v("Next")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.accountCurrency &&
                  _vm.accountType &&
                  _vm.form &&
                  _vm.step >= 1
                    ? [
                        _c(
                          "div",
                          { staticClass: "content-form" },
                          [
                            _vm.isPersonal
                              ? _c("personal-form", {
                                  attrs: { v: _vm.$v.form, step: _vm.step },
                                  model: {
                                    value: _vm.form,
                                    callback: function($$v) {
                                      _vm.form = $$v
                                    },
                                    expression: "form"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isPersonal
                              ? _c("business-form", {
                                  attrs: { v: _vm.$v.form, step: _vm.step },
                                  on: {
                                    "add-contact": function($event) {
                                      return _vm.addContact()
                                    },
                                    "remove-contact": function($event) {
                                      return _vm.removeContact()
                                    }
                                  },
                                  model: {
                                    value: _vm.form,
                                    callback: function($$v) {
                                      _vm.form = $$v
                                    },
                                    expression: "form"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isLastStep
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "form-row form-fullw" },
                                    [
                                      _c("div", { staticClass: "txtc" }, [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("register.agree_terms")
                                            )
                                          )
                                        ])
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-row form-fullw" },
                                    [
                                      _c("div", { staticClass: "autoh" }, [
                                        _c("ul", { staticClass: "cmarks" }, [
                                          _c("li", [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "check",
                                                class: {
                                                  error: _vm.errors.terms
                                                }
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.terms,
                                                      expression: "terms"
                                                    }
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.terms
                                                    )
                                                      ? _vm._i(
                                                          _vm.terms,
                                                          null
                                                        ) > -1
                                                      : _vm.terms
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a = _vm.terms,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.terms = $$a.concat(
                                                              [$$v]
                                                            ))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.terms = $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                        }
                                                      } else {
                                                        _vm.terms = $$c
                                                      }
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span"),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        _vm.termsModal = true
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("register.terms")
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("li", [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "check",
                                                class: {
                                                  error:
                                                    _vm.errors.privacy_policy
                                                }
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.privacy_policy,
                                                      expression:
                                                        "privacy_policy"
                                                    }
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.privacy_policy
                                                    )
                                                      ? _vm._i(
                                                          _vm.privacy_policy,
                                                          null
                                                        ) > -1
                                                      : _vm.privacy_policy
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a =
                                                          _vm.privacy_policy,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.privacy_policy = $$a.concat(
                                                              [$$v]
                                                            ))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.privacy_policy = $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                        }
                                                      } else {
                                                        _vm.privacy_policy = $$c
                                                      }
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span"),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        _vm.privacyModal = true
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "register.privacy"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ])
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-row form-center" },
                                    [
                                      _c("div", {}, [
                                        _c(
                                          "button",
                                          {
                                            attrs: { disabled: _vm.saving },
                                            on: {
                                              click: function($event) {
                                                return _vm.submit()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("register.submit_reg")
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "content-stepnav" }, [
                          _c(
                            "button",
                            {
                              staticClass: "prev",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.step--
                                }
                              }
                            },
                            [_vm._v("Back")]
                          ),
                          _vm._v(" "),
                          !_vm.isLastStep
                            ? _c(
                                "button",
                                {
                                  staticClass: "next",
                                  class: { disabled: _vm.checkInvalid() },
                                  on: {
                                    click: function($event) {
                                      return _vm.nextStep()
                                    }
                                  }
                                },
                                [_vm._v("Next")]
                              )
                            : _vm._e()
                        ])
                      ]
                    : _vm._e()
                ]
              : _c("div", { staticClass: "submitted-note" }, [
                  _vm.success
                    ? _c("div", { staticClass: "success" }, [
                        _vm._v(
                          "\n        Registration Successfully Submitted!\n      "
                        )
                      ])
                    : _c("div", { staticClass: "denied" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("register.unable_1")) + " "),
                          _c("b", [_vm._v(_vm._s(_vm.system.support_email))]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("register.unable_2")) + " "
                          ),
                          _c("b", [_vm._v(_vm._s(_vm.system.support_phone))]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("register.unable_3")) + "."
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          _vm._s(_vm.$t("register.unable_4")) + ", \n        "
                        ),
                        _c("br"),
                        _c("br"),
                        _c("b", [_vm._v(_vm._s(_vm.system.dba_name))])
                      ])
                ])
          ]
        : _c("h4", { staticClass: "app-page-text" }, [
            _vm._v(_vm._s(_vm.system.disabled_form_text))
          ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.termsModal },
          on: {
            "update:open": function($event) {
              _vm.termsModal = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "privacy-popup scrollable",
              on: {
                "&scroll": function($event) {
                  return _vm.handleScroll($event)
                }
              }
            },
            [_c("terms-popup")],
            1
          ),
          _vm._v(" "),
          _vm.submitClicked
            ? _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c(
                    "button",
                    {
                      class: _vm.reachTerms ? "main" : "second",
                      on: {
                        click: function($event) {
                          return _vm.acceptTerms()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("register.accept_button")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          _vm.termsModal = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("register.cancel_button")))]
                  ),
                  _vm._v(" "),
                  _vm.showTermsMessage
                    ? [_vm._v(_vm._s(_vm.$t("register.must_complete_terms")))]
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.privacyModal },
          on: {
            "update:open": function($event) {
              _vm.privacyModal = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "privacy-popup scrollable" },
            [_c("privacy-popup")],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("section", { staticClass: "content-tlist" }, [
      _c(
        "table",
        { staticClass: "content-table text-center" },
        [
          _vm._m(2),
          _vm._v(" "),
          _vm.registrations
            ? [
                _vm._l(5, function(index) {
                  return _vm.registrations[index - 1]
                    ? _c("tr", { key: index }, [
                        _c("td", [
                          _c("span", [_vm._v("Registration Date")]),
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.registrations[index - 1].createdAt,
                                  "MM/D/YYYY"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Registration")]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: {
                                href:
                                  "/system-customers/registrations/" +
                                  _vm.registrations[index - 1].id
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.registrations[index - 1].profileNumber ||
                                    _vm.registrations[index - 1].regId
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Name")]),
                            _vm._v(" "),
                            _vm.registrations[index - 1].type === "Personal"
                              ? [
                                  _vm._v(
                                    _vm._s(
                                      _vm.registrations[index - 1].data
                                        .firstName
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm.registrations[index - 1].data
                                          .lastName
                                      )
                                  )
                                ]
                              : [
                                  _vm._v(
                                    _vm._s(
                                      _vm.registrations[index - 1].data
                                        .legal_name
                                    )
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Last Update")]),
                            _vm._v(" "),
                            _vm.registrations[index - 1].accounts[
                              _vm.registrations[index - 1].accounts.length - 1
                            ] &&
                            _vm.registrations[index - 1].accounts[
                              _vm.registrations[index - 1].accounts.length - 1
                            ].complianceScan
                              ? [
                                  _vm.registrations[index - 1].accounts[
                                    _vm.registrations[index - 1].accounts
                                      .length - 1
                                  ].complianceScan
                                    ? [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("date")(
                                                _vm.registrations[index - 1]
                                                  .accounts[
                                                  _vm.registrations[index - 1]
                                                    .accounts.length - 1
                                                ].complianceScan.createdAt,
                                                "MM/D/YYYY hh:mm A"
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    : void 0
                                ]
                              : void 0
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Status")]),
                          _c(
                            "strong",
                            {
                              class:
                                _vm.statusColorClasses[
                                  _vm.registrations[index - 1].status
                                ]
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.registrations[index - 1].status)
                              )
                            ]
                          )
                        ])
                      ])
                    : _c("tr", [
                        _c("td", {
                          staticClass: "no-data",
                          attrs: { colspan: "5" }
                        })
                      ])
                }),
                _vm._v(" "),
                _vm._m(3)
              ]
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _c("section", { staticClass: "content-tlist" }, [
      _c(
        "table",
        { staticClass: "content-table text-center" },
        [
          _vm._m(5),
          _vm._v(" "),
          _vm.filteredAccounts
            ? [
                _vm._l(5, function(index) {
                  return _vm.filteredAccounts[index - 1]
                    ? _c("tr", { key: index }, [
                        _c("td", [
                          _c("span", [_vm._v("Account")]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: {
                                href:
                                  "/system-customers/registrations/" +
                                  _vm.filteredAccounts[index - 1].regId
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.filteredAccounts[index - 1].accountNumber
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Name")]),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.filteredAccounts[index - 1].regName) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Available")]),
                          _vm._v(
                            _vm._s(
                              _vm._f("balance")(
                                _vm.filteredAccounts[index - 1]
                                  .systemAvailableBalance
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Balance")]),
                          _vm._v(
                            _vm._s(
                              _vm._f("balance")(
                                _vm.filteredAccounts[index - 1]
                                  .systemTotalBalance
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Account Status")]),
                          _c(
                            "strong",
                            {
                              class:
                                _vm.statusAccountColorClasses[
                                  _vm.getStatus(
                                    _vm.filteredAccounts[index - 1]
                                      .accountStatus
                                  )
                                ]
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.getStatus(
                                      _vm.filteredAccounts[index - 1]
                                        .accountStatus
                                    )
                                  )
                              )
                            ]
                          )
                        ])
                      ])
                    : _c("tr", [
                        _c("td", {
                          staticClass: "no-data",
                          attrs: { colspan: "5" }
                        })
                      ])
                }),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _c("td", [_vm._v("Account Totals")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm._f("balance")(_vm.totalBalance)))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm._f("balance")(_vm.totalAvailable)))
                  ]),
                  _vm._v(" "),
                  _vm._m(6)
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _vm._m(7),
    _vm._v(" "),
    _c("section", { staticClass: "content-tlist" }, [
      _c(
        "table",
        { staticClass: "content-table text-center" },
        [
          _vm._m(8),
          _vm._v(" "),
          _vm.transactions
            ? [
                _vm._l(5, function(index) {
                  return _vm.transactions[index - 1]
                    ? _c("tr", { key: index }, [
                        _c("td", [
                          _c("span", [_vm._v("Transaction Date")]),
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.transactions[index - 1].createdAt,
                                  "MM/D/YYYY hh:mm A"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Transaction")]),
                          _vm._v(
                            _vm._s(_vm.transactions[index - 1].transactionId)
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Entry")]),
                          _vm._v(
                            _vm._s(
                              _vm.transactions[index - 1].entryType == "Deposit"
                                ? "DEP"
                                : "W/D"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("span", [_vm._v("Amount")]),
                            _vm._v(" "),
                            _vm.transactions[index - 1].currencyType == "Assets"
                              ? [
                                  _vm._v(
                                    _vm._s(
                                      parseFloat(
                                        _vm.transactions[index - 1].assetAmount
                                      )
                                    )
                                  )
                                ]
                              : [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transactions[index - 1].currencySymbol
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm._f("balance_without_symbol")(
                                          _vm.transactions[index - 1].amount
                                        )
                                      )
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v("Status")]),
                          _c(
                            "strong",
                            {
                              class:
                                _vm.statusTransactionColorClasses[
                                  _vm.transactions[index - 1].status
                                ]
                            },
                            [_vm._v(_vm._s(_vm.transactions[index - 1].status))]
                          )
                        ])
                      ])
                    : _c("tr", [
                        _c("td", {
                          staticClass: "no-data",
                          attrs: { colspan: "5" }
                        })
                      ])
                }),
                _vm._v(" "),
                _vm._m(9)
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [
        _c("h3", { staticClass: "inner-sub-headers" }, [_vm._v("Profiles")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Date")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Number")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "25%" } }, [
        _vm._v("Name")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Updated")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Status")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "view-href", attrs: { colspan: "5" } }, [
        _c("a", { attrs: { href: "/system-customers/profiles" } }, [
          _vm._v(">View Profiles")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [
        _c("h3", { staticClass: "inner-sub-headers" }, [_vm._v("Accounts")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Account")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Name")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Available")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Balance")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Status")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "view-href" }, [
      _c("a", { attrs: { href: "/system-customers/accounts" } }, [
        _vm._v(">View Accounts")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [
        _c("h3", { staticClass: "inner-sub-headers" }, [_vm._v("Transactions")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Date")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Number")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "25%" } }, [
        _vm._v("Entry")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Amount")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "text-center", staticStyle: { width: "15%" } }, [
        _vm._v("Status")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "view-href", attrs: { colspan: "5" } }, [
        _c("a", { attrs: { href: "/transactions/reporting" } }, [
          _vm._v(">View Transactions")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "form-box reg-form login-form" },
    [
      [
        _c("h2", [
          _vm._v(
            _vm._s(
              !_vm.resetsuccess
                ? "Reset Password"
                : "Reset Password Confirmation"
            )
          )
        ])
      ],
      _vm._v(" "),
      !_vm.resetsuccess
        ? [
            _c("p", { staticClass: "resetPasswordText" }, [
              _vm._v("Please enter your username below to reset your password")
            ]),
            _vm._v(" "),
            _c("form", [
              _c("div", { staticClass: "content-form" }, [
                _c(
                  "div",
                  { staticClass: "form-row form-fullw form-inputfield" },
                  [
                    _c("form-field-text", {
                      attrs: {
                        label: "User name",
                        validator: _vm.$v.form.username,
                        required: ""
                      },
                      model: {
                        value: _vm.form.username,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "username", $$v)
                        },
                        expression: "form.username"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.notFound
                  ? _c("p", { staticClass: "resetPasswordText" }, [
                      _vm._v(
                        "Username not found. Please check your information and try again. Contact support if you continue to experience issues"
                      )
                    ])
                  : _vm.notActive
                  ? _c("p", { staticClass: "resetPasswordText" }, [
                      _vm._v(
                        "Your username has not been activated. Please activate and try again. If your activation code has expired, contact support to receive a new activation code."
                      )
                    ])
                  : _c("p", { staticClass: "resetPasswordText" }, [
                      _vm._v(
                        "If your username is found in our system, you will receive an email with instructions on how to reset your password."
                      )
                    ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.resetPassword()
                        }
                      }
                    },
                    [_vm._v("Reset Password")]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(0)
            ])
          ]
        : [
            _c("p", { staticClass: "resetPasswordText confirmation" }, [
              _vm._v(
                "Please check your email for instructions on how to change your password"
              )
            ]),
            _vm._v(" "),
            _vm._m(1)
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-fullw" }, [
      _c("div", { staticClass: "txtc" }, [
        _c("a", { attrs: { href: "/login" } }, [_vm._v("Login")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("form", [
      _c("div", { staticClass: "form-row form-fullw" }, [
        _c("div", { staticClass: "txtc" }, [
          _c("a", { attrs: { href: "/login" } }, [_vm._v("Login")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
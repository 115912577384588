var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-filters" },
      [
        _c("basic-form", [
          _c(
            "div",
            { staticClass: "cof-row" },
            [
              _c("mnb-input", {
                attrs: {
                  maxlength: 4,
                  label: "Account Number Prefix",
                  disabled: !_vm.updateInformations
                },
                model: {
                  value: _vm.form.accountNumberPrefix,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "accountNumberPrefix", $$v)
                  },
                  expression: "form.accountNumberPrefix"
                }
              }),
              _vm._v(" "),
              _c("mnb-select", {
                attrs: {
                  label: "Provider prefixes",
                  options: _vm.providerPrefixes
                },
                model: {
                  value: _vm.dummy,
                  callback: function($$v) {
                    _vm.dummy = $$v
                  },
                  expression: "dummy"
                }
              }),
              _vm._v(" "),
              _c("mnb-input", {
                attrs: {
                  maxlength: 4,
                  label: "Master Business Account Prefix",
                  disabled: !_vm.updateInformations
                },
                model: {
                  value: _vm.form.masterBusinessAccountPrefix,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "masterBusinessAccountPrefix", $$v)
                  },
                  expression: "form.masterBusinessAccountPrefix"
                }
              }),
              _vm._v(" "),
              _c("mnb-input", {
                attrs: {
                  maxlength: 2,
                  label: "Personal Account Prefix",
                  disabled: !_vm.updateInformations
                },
                model: {
                  value: _vm.form.personalAccountPrefix,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "personalAccountPrefix", $$v)
                  },
                  expression: "form.personalAccountPrefix"
                }
              }),
              _vm._v(" "),
              _c("mnb-input", {
                attrs: {
                  maxlength: 2,
                  label: "Business Account Prefix",
                  disabled: !_vm.updateInformations
                },
                model: {
                  value: _vm.form.businessAccountPrefix,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "businessAccountPrefix", $$v)
                  },
                  expression: "form.businessAccountPrefix"
                }
              }),
              _vm._v(" "),
              _c("mnb-select", {
                attrs: {
                  options: _vm.accountNumberLengthOptions,
                  label: "Account Number Length",
                  disabled: !_vm.updateInformations
                },
                model: {
                  value: _vm.form.accountNumberLength,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "accountNumberLength", $$v)
                  },
                  expression: "form.accountNumberLength"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _vm.updateInformations
              ? _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ])
              : _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          _vm.updateInformations = true
                        }
                      }
                    },
                    [_vm._v("Update")]
                  )
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "cof-field mobinl" }, [
              _c("label", [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "second",
                  on: {
                    click: function($event) {
                      return _vm.clear()
                    }
                  }
                },
                [_vm._v("Clear")]
              )
            ])
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-filters" },
      [
        _c("basic-form", [
          _c("h3", [_vm._v("Session Management")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cof-row" },
            [
              _c("mnb-select", {
                attrs: {
                  options: _vm.sessionLengthOptions,
                  label: "Session Timeout (Minutes)",
                  disabled: !_vm.updateInformationsSession
                },
                model: {
                  value: _vm.formSession.sessionTimeout,
                  callback: function($$v) {
                    _vm.$set(_vm.formSession, "sessionTimeout", $$v)
                  },
                  expression: "formSession.sessionTimeout"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _vm.updateInformationsSession
              ? _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.saveSession()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ])
              : _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          _vm.updateInformationsSession = true
                        }
                      }
                    },
                    [_vm._v("Update")]
                  )
                ])
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-filters" },
      [
        _c("basic-form", [
          _c("h3", [_vm._v("Activation Code Timeout")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cof-row" },
            [
              _c("mnb-select", {
                attrs: {
                  options: _vm.activationLengthOptions,
                  label: "Activation Code Timeout (Hours)",
                  disabled: !_vm.updateInformationsActivation
                },
                model: {
                  value: _vm.formSession.activationCodeTimeout,
                  callback: function($$v) {
                    _vm.$set(_vm.formSession, "activationCodeTimeout", $$v)
                  },
                  expression: "formSession.activationCodeTimeout"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _vm.updateInformationsActivation
              ? _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.saveActivationCodeTimeout()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ])
              : _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          _vm.updateInformationsActivation = true
                        }
                      }
                    },
                    [_vm._v("Update")]
                  )
                ])
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-filters" },
      [
        _c("basic-form", [
          _c("h3", [_vm._v("Monthly Limit (USD)")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cof-row" },
            [
              _c("mnb-input", {
                attrs: {
                  label: "Transaction Monthly Limit (USD)",
                  disabled: !_vm.updateLimits
                },
                model: {
                  value: _vm.formSession.montlyLimitUsd,
                  callback: function($$v) {
                    _vm.$set(_vm.formSession, "montlyLimitUsd", $$v)
                  },
                  expression: "formSession.montlyLimitUsd"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", [_vm._v("Daily Limit (USD)")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cof-row" },
            [
              _c("mnb-input", {
                attrs: {
                  label: "Transaction Daily Limit (USD)",
                  disabled: !_vm.updateLimits
                },
                model: {
                  value: _vm.formSession.dailyLimitUsd,
                  callback: function($$v) {
                    _vm.$set(_vm.formSession, "dailyLimitUsd", $$v)
                  },
                  expression: "formSession.dailyLimitUsd"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _vm.updateLimits
              ? _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.saveLimits()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ])
              : _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          _vm.updateLimits = true
                        }
                      }
                    },
                    [_vm._v("Update")]
                  )
                ])
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-filters" },
      [
        _c("basic-form", [
          _c("h3", [_vm._v("Registration Countries")]),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _c(
              "div",
              [
                _c("label", [_vm._v("Formation Country")]),
                _vm._v(" "),
                _c("multi-select-dropdown", {
                  attrs: {
                    options: _vm.countries,
                    isMulti: true,
                    disabled: !_vm.updateCountry
                  },
                  model: {
                    value: _vm.formSession.formationCountry,
                    callback: function($$v) {
                      _vm.$set(_vm.formSession, "formationCountry", $$v)
                    },
                    expression: "formSession.formationCountry"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-left": "10px" } },
              [
                _c("label", [_vm._v("Contacts Country")]),
                _vm._v(" "),
                _c("multi-select-dropdown", {
                  attrs: {
                    options: _vm.countries,
                    isMulti: true,
                    disabled: !_vm.updateCountry
                  },
                  model: {
                    value: _vm.formSession.contactsCountry,
                    callback: function($$v) {
                      _vm.$set(_vm.formSession, "contactsCountry", $$v)
                    },
                    expression: "formSession.contactsCountry"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _vm.updateCountry
              ? _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          return _vm.saveCountry()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ])
              : _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main",
                      on: {
                        click: function($event) {
                          _vm.updateCountry = true
                        }
                      }
                    },
                    [_vm._v("Update")]
                  )
                ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
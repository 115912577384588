var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "cp-box" }, [
      _c("div", { staticClass: "mail-buttons-container" }, [
        _c("div", { staticClass: "cof-field mobinl" }, [
          _c("label", [_vm._v(" ")]),
          _vm._v(" "),
          _c(
            "button",
            {
              class: { main: true, disabled: _vm.employee.status === "Active" },
              attrs: { disabled: _vm.employee.status === "Active" },
              on: {
                click: function($event) {
                  return _vm.sendMail("employees", "EmployeeWelcomeEmail")
                }
              }
            },
            [_vm._v("Activation")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
        [
          _c(
            "table",
            { staticClass: "content-table" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.emails, function(email) {
                return _c("tr", { key: email.id }, [
                  _c("td", [
                    _c("span", [_vm._v("Type")]),
                    _vm._v(_vm._s(email.email_type))
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [_vm._v("Date/Time")]),
                    _vm._v(_vm._s(_vm._f("date")(email.createdAt)))
                  ])
                ])
              })
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date/Time")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", [
      _vm._m(0),
      _vm._v(" "),
      _vm.$hasPerm("add-employees")
        ? _c(
            "button",
            {
              staticClass: "btn btn-success",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.openEmployee($event)
                }
              }
            },
            [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" New Employee")]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.perms
      ? _c("div", { staticClass: "accordion" }, [
          _c("div", { class: { "cof-box-active": _vm.activeInfo } }, [
            _c("section", { staticClass: "content-tlist" }, [
              _c(
                "table",
                { staticClass: "content-table" },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._l(_vm.filteredData, function(employee) {
                    return _c("tr", { key: employee.id }, [
                      _c("td", [
                        _c("span", [_vm._v("Date")]),
                        _vm._v(
                          _vm._s(
                            _vm._f("date")(employee.createdAt, "MM/D/YYYY")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Emp ID")]),
                        _vm._v(" "),
                        _vm.$hasPerm("manage-employees")
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: "/settings/employees/" + employee.id
                                }
                              },
                              [_vm._v(_vm._s(employee.employeeId))]
                            )
                          : _c("p", { staticStyle: { color: "#00c2ac" } }, [
                              _vm._v(_vm._s(employee.employeeId))
                            ])
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Role")]),
                        _vm._v(_vm._s(employee.role.name))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("First Name")]),
                        _vm._v(_vm._s(employee.firstName))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Last Name")]),
                        _vm._v(_vm._s(employee.lastName))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Email")]),
                        _vm._v(_vm._s(employee.email))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Status")]),
                        _vm._v(" "),
                        _c("em", { class: _vm.statusColors[employee.status] }, [
                          _vm._v(_vm._s(employee.status))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [_vm._v("Options")]),
                        _vm._v(" "),
                        _vm.$hasPerm("access") && employee.user
                          ? _c(
                              "button",
                              {
                                staticClass: "action-button",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleBlock(employee)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    employee.user.active !== "Blocked"
                                      ? "Block"
                                      : "Unblock"
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$hasPerm("manage-employees") && employee.user
                          ? _c(
                              "button",
                              {
                                staticClass: "action-button remove",
                                on: {
                                  click: function($event) {
                                    return _vm.remove(employee)
                                  }
                                }
                              },
                              [_vm._v("Remove")]
                            )
                          : _vm._e()
                      ])
                    ])
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            !_vm.loading
              ? _c("section", { staticClass: "pagination-section" }, [
                  _c("div", [
                    _vm._v("\n            Per page\n            "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.perPage,
                            expression: "perPage"
                          }
                        ],
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.perPage = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function($event) {
                              return _vm.setPages()
                            }
                          ]
                        }
                      },
                      [
                        _c("option", { attrs: { value: "25" } }, [
                          _vm._v("25")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "50" } }, [
                          _vm._v("50")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "75" } }, [
                          _vm._v("75")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "100" } }, [
                          _vm._v("100")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "150" } }, [
                          _vm._v("150")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "200" } }, [
                          _vm._v("200")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "nav",
                    { attrs: { "aria-label": "Page navigation example" } },
                    [
                      _c("ul", { staticClass: "pagination" }, [
                        _c("li", { staticClass: "page-item" }, [
                          _vm.page != 1
                            ? _c(
                                "button",
                                {
                                  staticClass: "page-link",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      _vm.page--
                                    }
                                  }
                                },
                                [_vm._v(" Previous ")]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "page-item" },
                          _vm._l(
                            _vm.pages.slice(_vm.page - 1, _vm.page + 5),
                            function(pageNumber) {
                              return _c(
                                "button",
                                {
                                  key: pageNumber,
                                  staticClass: "page-link",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      _vm.page = pageNumber
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(pageNumber) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c("li", { staticClass: "page-item" }, [
                          _vm.page < _vm.pages.length
                            ? _c(
                                "button",
                                {
                                  staticClass: "page-link",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      _vm.page++
                                    }
                                  }
                                },
                                [_vm._v(" Next ")]
                              )
                            : _vm._e()
                        ])
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h2", { staticClass: "inner-headers" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Setup Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Emp ID")]),
      _vm._v(" "),
      _c("th", [_vm._v("Role")]),
      _vm._v(" "),
      _c("th", [_vm._v("First Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Last Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Email")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
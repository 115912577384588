var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", [
        _c("div", { staticClass: "flex-between" }, [
          _c("h2", { staticClass: "inner-headers" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
            _c(
              "button",
              {
                staticClass: "second",
                on: {
                  click: function($event) {
                    _vm.openedFilters = true
                  }
                }
              },
              [
                _c("i", { staticClass: "el-icon-s-operation" }),
                _vm._v(" Filters")
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("applied-filters", {
        attrs: { filters: _vm.appliedFilters },
        on: {
          clear: function($event) {
            return _vm.clearFilters()
          },
          remove: function($event) {
            return _vm.removeFilter($event)
          }
        }
      }),
      _vm._v(" "),
      _c("section", { staticClass: "content-tlist" }, [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _c(
              "tr",
              [
                _vm._l(_vm.header, function(item, i) {
                  return _c("th", [_vm._v(_vm._s(item))])
                }),
                _vm._v(" "),
                _c("th", [_vm._v("Options")])
              ],
              2
            ),
            _vm._v(" "),
            _vm._l(_vm.transactions, function(transaction) {
              return _c(
                "tr",
                { key: transaction.id },
                [
                  _vm._l(transaction, function(item, i) {
                    return _c(
                      "td",
                      { key: i },
                      [
                        i == "status"
                          ? [
                              _vm.isEdit(transaction.id)
                                ? _c("form-field-select", {
                                    attrs: {
                                      label: "",
                                      options: _vm.statusOptions,
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.itemstatus,
                                      callback: function($$v) {
                                        _vm.itemstatus = $$v
                                      },
                                      expression: "itemstatus"
                                    }
                                  })
                                : [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item) +
                                        "\n              "
                                    )
                                  ]
                            ]
                          : i == "currencyName"
                          ? [
                              _vm.isEdit(transaction.id)
                                ? [
                                    transaction.currencyType == "Funds"
                                      ? _c("form-field-select", {
                                          attrs: {
                                            label: "",
                                            options: _vm.fundsCurrencyOptions,
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.itemcurrencyName,
                                            callback: function($$v) {
                                              _vm.itemcurrencyName = $$v
                                            },
                                            expression: "itemcurrencyName"
                                          }
                                        })
                                      : _c("form-field-select", {
                                          attrs: {
                                            label: "",
                                            options: _vm.assetsCurrencyOptions,
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.itemcurrencyName,
                                            callback: function($$v) {
                                              _vm.itemcurrencyName = $$v
                                            },
                                            expression: "itemcurrencyName"
                                          }
                                        })
                                  ]
                                : [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item) +
                                        "\n              "
                                    )
                                  ]
                            ]
                          : [_vm._v(_vm._s(item))]
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Options")]),
                      _vm._v(" "),
                      _vm.isEdit(transaction.id)
                        ? [
                            _c(
                              "button",
                              {
                                staticClass: "action-button save",
                                on: {
                                  click: function($event) {
                                    return _vm.saveUpdate(transaction)
                                  }
                                }
                              },
                              [_vm._v("Save")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "action-button cancel",
                                on: {
                                  click: function($event) {
                                    return _vm.cancelEdit(transaction.id)
                                  }
                                }
                              },
                              [_vm._v("Cancel")]
                            )
                          ]
                        : !_vm.editStatus
                        ? [
                            _c(
                              "button",
                              {
                                staticClass: "action-button update",
                                on: {
                                  click: function($event) {
                                    return _vm.edit(transaction)
                                  }
                                }
                              },
                              [_vm._v("Edit")]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                2
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "pagination-section" }, [
        _c("div", [
          _vm._v("\n      Per page\n      "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.perPage,
                  expression: "perPage"
                }
              ],
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.perPage = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function($event) {
                    return _vm.setPages()
                  }
                ]
              }
            },
            [
              _c("option", { attrs: { value: "25" } }, [_vm._v("25")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "50" } }, [_vm._v("50")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "75" } }, [_vm._v("75")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "100" } }, [_vm._v("100")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "150" } }, [_vm._v("150")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "200" } }, [_vm._v("200")])
            ]
          )
        ]),
        _vm._v(" "),
        _c("nav", { attrs: { "aria-label": "Page navigation example" } }, [
          _c("ul", { staticClass: "pagination" }, [
            _c("li", { staticClass: "page-item" }, [
              _vm.page != 1
                ? _c(
                    "button",
                    {
                      staticClass: "page-link",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.page--
                        }
                      }
                    },
                    [_vm._v(" Previous ")]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "page-item" },
              _vm._l(_vm.pages.slice(_vm.page - 1, _vm.page + 5), function(
                pageNumber
              ) {
                return _c(
                  "button",
                  {
                    key: pageNumber,
                    staticClass: "page-link",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.page = pageNumber
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(pageNumber) + " ")]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("li", { staticClass: "page-item" }, [
              _vm.page < _vm.pages.length
                ? _c(
                    "button",
                    {
                      staticClass: "page-link",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.page++
                        }
                      }
                    },
                    [_vm._v(" Next ")]
                  )
                : _vm._e()
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("transactions-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="content-form content-form-modern">
    <div class="form-row form-split">
      <form-field-select :disabled="form.disabled" v-model="form.country" label="Country" :validator="v.country" :options="countryOptions" required />
      <form-field-select :disabled="form.disabled" v-if="stateOptions" v-model="form.state" label="Province/State" :validator="v.state" :options="stateOptions" required />
      <form-field-text :disabled="form.disabled" v-else v-model="form.state" :validator="v.state" label="Province/State" required />
    </div>
    <div class="form-row form-three">
      <form-field-text :disabled="form.disabled" v-model="form.address" label="Address" :validator="v.address" required />
      <form-field-text :disabled="form.disabled" v-model="form.city" label="City" :validator="v.city" required />
      <form-field-text :disabled="form.disabled" v-model="form.postal" label="Postal" :validator="v.postal" required />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    usOnly: {
      type: Boolean,
      required: false
    },

    transactionCountries: {
      type: Array,
      required: false
    },
  },

  data () {
    return {
      form: this.value,

      accountOptions: [
        {
          id: 'personal',
          text: 'Personal'
        },
        {
          id: 'business',
          text: 'Business'
        }
      ],

      bankTypeOptions: [
        {
          id: 'checking',
          text: 'Checking'
        },
        {
          id: 'savings',
          text: 'Savings'
        }
      ]
    }
  },

  computed: {
    countryOptions () {
      if(this.usOnly){
        return [
          {
            id: "US",
            text: "United States"
          }
        ]
      }else{
        if(this.transactionCountries) {
          return window.fullCountries.filter(a => {
            return this.transactionCountries.includes(a.id)
          })
        }else{
          return window.countriesOptions
        }
      }
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.country)) return null

      return window.states[this.form.country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    }
  },

  watch: {
    'form.country' (value) {
//      this.form.state = null
    },

    value: {
      handler () {
        this.form = this.value
      },
      deep: true,
      immediate: true
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", [
        _c("div", { staticClass: "flex-between" }, [
          _c("h2", { staticClass: "inner-headers" }),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
            _c(
              "button",
              {
                staticClass: "second",
                on: {
                  click: function($event) {
                    _vm.openedFilters = true
                  }
                }
              },
              [
                _c("i", { staticClass: "el-icon-s-operation" }),
                _vm._v(" Filters")
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-success",
          staticStyle: { "margin-top": "-65px" },
          on: {
            click: function($event) {
              _vm.open = "Open"
            }
          }
        },
        [
          _c("i", { staticClass: "el-icon-files" }),
          _vm._v(" New File Transfer")
        ]
      ),
      _vm._v(" "),
      _c("applied-filters", {
        attrs: { filters: _vm.appliedFilters },
        on: {
          clear: function($event) {
            return _vm.clearFilters()
          },
          remove: function($event) {
            return _vm.removeFilter($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "accordion" }, [
        _c("div", { class: { "cof-box-active": _vm.activeInfo } }, [
          _c("section", { staticClass: "content-tlist" }, [
            _c(
              "table",
              { staticClass: "content-table" },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm._l(_vm.files, function(file) {
                  return _c("tr", { key: file.id }, [
                    _c("td", [
                      _c("span", [_vm._v("File")]),
                      _vm._v(_vm._s(file.fileNumber))
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Date/Time")]),
                      _vm._v(
                        _vm._s(_vm._f("date")(file.createdAt, "MM/D/YYYY"))
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Profile")]),
                        _vm._v(" "),
                        !_vm.isCustomer
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "/system-customers/registrations/" +
                                    file.profile
                                }
                              },
                              [_vm._v(_vm._s(file.profileNumber))]
                            )
                          : [_vm._v(_vm._s(file.profileNumber))]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Description")]),
                      _vm._v(
                        "\n              " +
                          _vm._s(file.description) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("File")]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: {
                            target: "_blank",
                            href:
                              "files/fileTransfer/" + file.id + "/" + file.file
                          }
                        },
                        [_vm._v(_vm._s(file.file))]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Options")]),
                      _vm._v(" "),
                      !_vm.isCustomer
                        ? _c(
                            "button",
                            {
                              staticClass: "action-button remove",
                              on: {
                                click: function($event) {
                                  return _vm.remove(file.id)
                                }
                              }
                            },
                            [_vm._v("Remove")]
                          )
                        : _vm._e()
                    ])
                  ])
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          !_vm.loading
            ? _c("section", { staticClass: "pagination-section" }, [
                _c("div", [
                  _vm._v("\n          Per page\n          "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.perPage,
                          expression: "perPage"
                        }
                      ],
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.perPage = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function($event) {
                            return _vm.setPages()
                          }
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "25" } }, [_vm._v("25")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "50" } }, [_vm._v("50")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "75" } }, [_vm._v("75")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "100" } }, [
                        _vm._v("100")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "150" } }, [
                        _vm._v("150")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "200" } }, [_vm._v("200")])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "nav",
                  { attrs: { "aria-label": "Page navigation example" } },
                  [
                    _c("ul", { staticClass: "pagination" }, [
                      _c("li", { staticClass: "page-item" }, [
                        _vm.page != 1
                          ? _c(
                              "button",
                              {
                                staticClass: "page-link",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    _vm.page--
                                  }
                                }
                              },
                              [_vm._v(" Previous ")]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "page-item" },
                        _vm._l(
                          _vm.pages.slice(_vm.page - 1, _vm.page + 5),
                          function(pageNumber) {
                            return _c(
                              "button",
                              {
                                key: pageNumber,
                                staticClass: "page-link",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    _vm.page = pageNumber
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(pageNumber) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _c("li", { staticClass: "page-item" }, [
                        _vm.page < _vm.pages.length
                          ? _c(
                              "button",
                              {
                                staticClass: "page-link",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    _vm.page++
                                  }
                                }
                              },
                              [_vm._v(" Next ")]
                            )
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _vm.open
        ? _c("file-transfer-modal", {
            attrs: { "modal-type": _vm.open },
            on: {
              close: function($event) {
                _vm.open = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openedFilters
        ? _c("file-transfer-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openedFilters = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("File")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date/Time")]),
      _vm._v(" "),
      _c("th", [_vm._v("Profile")]),
      _vm._v(" "),
      _c("th", [_vm._v("Description")]),
      _vm._v(" "),
      _c("th", [_vm._v("File")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("h2", [_vm._v("Profile Account Confirmation")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "status" },
          [
            _vm._v("\n      Status\n      "),
            _c("status-icon", {
              attrs: { color: _vm.accountConfirmation ? "green" : null }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "content-table", staticStyle: { "min-width": "unset" } },
        [
          _c("tr", [
            _c("th", [_vm._v("Date/Time")]),
            _vm._v(" "),
            _vm.registration.type == "Business" && _vm.featureMasterAccounts
              ? _c("th")
              : _vm._e(),
            _vm._v(" "),
            _vm.registration.type == "Master Business" &&
            _vm.featureMasterAccounts
              ? _c("th")
              : _vm._e(),
            _vm._v(" "),
            _c("th", [_vm._v("Profile Type")]),
            _vm._v(" "),
            _c("th", [_vm._v("Profile Number")]),
            _vm._v(" "),
            _c("th", [_vm._v("Account Type")]),
            _vm._v(" "),
            _c("th", [_vm._v("Account Number")]),
            _vm._v(" "),
            _c("th", [_vm._v("Currency")]),
            _vm._v(" "),
            _vm.registration.status !== "Complete" && !_vm.mainAccount
              ? _c("th", [_vm._v("Options")])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("tr", [
            _c(
              "td",
              [
                _c("span", [_vm._v("Date/Time")]),
                _vm._v(" "),
                !_vm.accountConfirmation
                  ? [_vm._v("N/A")]
                  : [
                      _vm._v(
                        _vm._s(
                          _vm._f("date")(
                            _vm.accountConfirmation.createdAt,
                            "LL"
                          )
                        ) +
                          " " +
                          _vm._s(
                            _vm._f("date")(
                              _vm.accountConfirmation.createdAt,
                              "LT"
                            )
                          )
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _vm.registration.type == "Business" && _vm.featureMasterAccounts
              ? _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setAsMaster($event)
                        }
                      }
                    },
                    [_vm._v("Master Account")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.registration.type == "Master Business" &&
            _vm.featureMasterAccounts
              ? _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setAsMaster($event)
                        }
                      }
                    },
                    [_vm._v("Remove Master Account")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("td", [
              _c("span", [_vm._v("Profile Type")]),
              _vm._v("\n        " + _vm._s(_vm.registration.type) + "\n      ")
            ]),
            _vm._v(" "),
            _c("td", [
              _c("span", [_vm._v("Profile Number")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.mainAccount ? _vm.mainAccount.profileNumber : "") +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("td", [
              _c("span", [_vm._v("Account Type")]),
              _vm._v("\n        " + _vm._s(_vm.registration.type) + "\n      ")
            ]),
            _vm._v(" "),
            _c("td", [
              _c("span", [_vm._v("Account Number")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.mainAccount ? _vm.mainAccount.accountNumber : "") +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Currency")]),
                _vm._v(" "),
                _c("mnb-select", {
                  attrs: {
                    options: _vm.currencyOptions,
                    disabled: !!_vm.accountConfirmation
                  },
                  model: {
                    value: _vm.form.currency,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "currency", $$v)
                    },
                    expression: "form.currency"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.registration.status !== "Complete" && !_vm.mainAccount
              ? _c("td", [
                  _c("span", [_vm._v("Options")]),
                  _vm._v(" "),
                  !_vm.accountConfirmation
                    ? _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.save()
                            }
                          }
                        },
                        [_vm._v("Confirm")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              return _vm.reset()
                            }
                          }
                        },
                        [_vm._v("Reset")]
                      )
                ])
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("section", { staticClass: "content-tabs" }, [
      _c("div", { staticClass: "content-tabs-container" }, [
        _c("div", { staticClass: "cs-tri" }, [
          _c("div", [
            _vm._v("Profile: "),
            _vm.employee
              ? _c("strong", [_vm._v(_vm._s(_vm.employee.employeeId))])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("Name: "),
            _vm.employee
              ? _c("strong", [
                  _vm._v(
                    _vm._s(_vm.employee.firstName) +
                      " " +
                      _vm._s(_vm.employee.lastName)
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("Profile Status: "),
            _vm.employee
              ? _c("strong", [_vm._v(_vm._s(_vm.employee.status))])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "ctabs first-row" },
          _vm._l(_vm.tabs[1], function(tab) {
            return _c(
              "li",
              { key: tab, class: { active: _vm.activeTab === tab } },
              [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.activeTab = tab
                      }
                    }
                  },
                  [_vm._v(_vm._s(tab))]
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "ctabs second-row" },
          _vm._l(_vm.tabs[2], function(tab) {
            return _c(
              "li",
              { key: tab, class: { active: _vm.activeTab === tab } },
              [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.activeTab = tab
                      }
                    }
                  },
                  [_vm._v(_vm._s(tab))]
                )
              ]
            )
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "client-properties active" },
      [
        _vm.activeTab === "Employee Info"
          ? _c("info", { attrs: { employee: _vm.employee } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Notes"
          ? _c("notes", { attrs: { employee: _vm.employee } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Emails"
          ? _c("emails", { attrs: { employee: _vm.employee } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Access"
          ? _c("access", { attrs: { employee: _vm.employee } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Documents"
          ? _c("documents", { attrs: { employee: _vm.employee } })
          : _vm._e(),
        _vm._v(" "),
        _vm.activeTab === "Permissions"
          ? _c("permissions", { attrs: { employee: _vm.employee } })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [
        _c("h2", { staticClass: "inner-headers" }, [
          _vm._v("Profile Detail View - Employee")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
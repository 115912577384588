var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.open
        ? [
            _vm._m(0),
            _vm._v(" "),
            _c("section", [
              _vm.$hasPerm("corporate-new-account") && _vm.permsNew
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      on: {
                        click: function($event) {
                          return _vm.openModal()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(" New Account")
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.modalType
              ? _c("add-deposit-withdrawal-modal", {
                  attrs: { "modal-type": _vm.modalType },
                  on: {
                    close: function($event) {
                      _vm.modalType = null
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "popup",
              {
                attrs: { open: _vm.newAccount },
                on: {
                  "update:open": function($event) {
                    _vm.newAccount = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "popup-header" }, [
                  _c("h3", [_vm._v("New Corporate Account")])
                ]),
                _vm._v(" "),
                !_vm.successCorpAccount
                  ? [
                      _c("div", { staticClass: "scrollable" }, [
                        _c("div", { staticClass: "content-form" }, [
                          _c(
                            "div",
                            { staticClass: "form-row form-split" },
                            [
                              _c("form-field-select", {
                                attrs: {
                                  validator: _vm.$v.form.provider,
                                  label: "Provider",
                                  options: _vm.providersOptions,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.provider,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "provider", $$v)
                                  },
                                  expression: "form.provider"
                                }
                              }),
                              _vm._v(" "),
                              _c("form-field-text", {
                                attrs: {
                                  validator: _vm.$v.form.accountName,
                                  label: "Account Name",
                                  required: "",
                                  disabled: !_vm.form.provider
                                },
                                model: {
                                  value: _vm.form.accountName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "accountName", $$v)
                                  },
                                  expression: "form.accountName"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-row form-split" },
                            [
                              _c("form-field-select", {
                                attrs: {
                                  validator: _vm.$v.form.accountType,
                                  label: "Account Type",
                                  options: _vm.accountOptions,
                                  required: "",
                                  disabled: !_vm.form.provider
                                },
                                model: {
                                  value: _vm.form.accountType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "accountType", $$v)
                                  },
                                  expression: "form.accountType"
                                }
                              }),
                              _vm._v(" "),
                              _c("form-field-select", {
                                attrs: {
                                  validator: _vm.$v.form.currency,
                                  label: "Currency",
                                  options: _vm.currencyOptions,
                                  required: "",
                                  disabled: !_vm.form.provider
                                },
                                model: {
                                  value: _vm.form.currency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "currency", $$v)
                                  },
                                  expression: "form.currency"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _vm.accountNumber
                          ? _c("div", { staticClass: "acc-number" }, [
                              _vm._v(
                                "\n            Account Number - " +
                                  _vm._s(_vm.accountNumber) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _vm.accountNumber
                          ? _c(
                              "button",
                              {
                                staticClass: "main",
                                on: {
                                  click: function($event) {
                                    return _vm.confirmAccount()
                                  }
                                }
                              },
                              [_vm._v("Confirm")]
                            )
                          : _c(
                              "button",
                              {
                                staticClass: "main",
                                on: {
                                  click: function($event) {
                                    return _vm.addNewAccount()
                                  }
                                }
                              },
                              [_vm._v("Submit")]
                            ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                return _vm.closeModal()
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ])
                    ]
                  : [
                      _c("div", { staticClass: "scrollable" }, [
                        _c(
                          "div",
                          { staticClass: "acc-number" },
                          [
                            _vm.errorDate
                              ? [
                                  _vm._v(
                                    "\n             Error - " +
                                      _vm._s(_vm.errorDate) +
                                      "\n             "
                                  ),
                                  _c("p", [_vm._v(_vm._s(_vm.errorMessage))]),
                                  _vm._v(" "),
                                  _vm._l(_vm.messages, function(message) {
                                    return _c(
                                      "p",
                                      {
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(message) + " is missing."
                                          )
                                        ])
                                      ]
                                    )
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.providerMessages, function(
                                    providerMessage
                                  ) {
                                    return _c(
                                      "p",
                                      {
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _c("b", [
                                          _vm._v(_vm._s(providerMessage))
                                        ])
                                      ]
                                    )
                                  })
                                ]
                              : [
                                  _vm._v(
                                    "\n              Success.\n            "
                                  )
                                ]
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "second",
                            on: {
                              click: function($event) {
                                return _vm.closeModal()
                              }
                            }
                          },
                          [_vm._v("Close")]
                        )
                      ])
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content-filters accordion" }, [
              _vm.perms
                ? _c(
                    "div",
                    {
                      class: {
                        "cof-box": true,
                        "cof-box-active": _vm.activeInfo
                      }
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "accordion-headline",
                          on: {
                            click: function($event) {
                              _vm.activeInfo = !_vm.activeInfo
                            }
                          }
                        },
                        [_vm._v("Corporate Information")]
                      ),
                      _vm._v(" "),
                      _c(
                        "h3",
                        {
                          staticClass: "total-number",
                          on: {
                            click: function($event) {
                              _vm.activeInfo = !_vm.activeInfo
                            }
                          }
                        },
                        [_vm._v("Click to expand")]
                      ),
                      _vm._v(" "),
                      _c("h4", { staticStyle: { "margin-top": "16px" } }, [
                        _vm._v("Corporate Business Information")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content-form" }, [
                        _c(
                          "div",
                          { staticClass: "form-row form-corporate" },
                          [
                            _c("form-field-text", {
                              attrs: {
                                validator: _vm.$v.form.legal_name,
                                label: "Business Legal Name",
                                maxlength: 50,
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.legal_name,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "legal_name", $$v)
                                },
                                expression: "form.legal_name"
                              }
                            }),
                            _vm._v(" "),
                            _c("form-field-text", {
                              attrs: {
                                validator: _vm.$v.form.dba_name,
                                label: "Business DBA Name",
                                maxlength: 50,
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.dba_name,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "dba_name", $$v)
                                },
                                expression: "form.dba_name"
                              }
                            }),
                            _vm._v(" "),
                            _c("form-field-date", {
                              attrs: {
                                dirty: _vm.$v.form.$dirty,
                                label: "Business Incorporation Date",
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.incorporation_date,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "incorporation_date", $$v)
                                },
                                expression: "form.incorporation_date"
                              }
                            }),
                            _vm._v(" "),
                            _c("form-field-text", {
                              attrs: {
                                validator: _vm.$v.form.tax_id,
                                label: "Business Tax ID",
                                maxlength: 20,
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.tax_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "tax_id", $$v)
                                },
                                expression: "form.tax_id"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-row form-corporate" },
                          [
                            _c("form-field-select", {
                              attrs: {
                                validator: _vm.$v.form.formation_country,
                                label: "Country of Formation",
                                options: _vm.countryOptions,
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.formation_country,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "formation_country", $$v)
                                },
                                expression: "form.formation_country"
                              }
                            }),
                            _vm._v(" "),
                            _vm.stateOptions
                              ? _c("form-field-select", {
                                  attrs: {
                                    validator: _vm.$v.form.formation_state,
                                    label: "State/Province of Formation",
                                    options: _vm.stateOptions,
                                    disabled: _vm.updateIndentification,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.formation_state,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "formation_state", $$v)
                                    },
                                    expression: "form.formation_state"
                                  }
                                })
                              : _c("form-field-text", {
                                  attrs: {
                                    validator: _vm.$v.form.formation_state,
                                    label: "State/Province of Formation",
                                    disabled: _vm.updateIndentification,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.formation_state,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "formation_state", $$v)
                                    },
                                    expression: "form.formation_state"
                                  }
                                }),
                            _vm._v(" "),
                            _c("form-field-text", {
                              attrs: {
                                validator: _vm.$v.form.address,
                                label: "Business Address",
                                maxlength: 50,
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.address,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "address", $$v)
                                },
                                expression: "form.address"
                              }
                            }),
                            _vm._v(" "),
                            _c("form-field-text", {
                              attrs: {
                                label: "Business Address 2",
                                maxlength: 50,
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.address2,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "address2", $$v)
                                },
                                expression: "form.address2"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-row form-corporate" },
                          [
                            _c("form-field-text", {
                              attrs: {
                                validator: _vm.$v.form.city,
                                label: "Business City",
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.city,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "city", $$v)
                                },
                                expression: "form.city"
                              }
                            }),
                            _vm._v(" "),
                            _vm.stateOptions
                              ? _c("form-field-select", {
                                  attrs: {
                                    validator: _vm.$v.form.state,
                                    label: "Business State/Province",
                                    options: _vm.stateOptions,
                                    disabled: _vm.updateIndentification,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.state,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "state", $$v)
                                    },
                                    expression: "form.state"
                                  }
                                })
                              : _c("form-field-text", {
                                  attrs: {
                                    validator: _vm.$v.form.state,
                                    label: "Business State/Province",
                                    disabled: _vm.updateIndentification,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.state,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "state", $$v)
                                    },
                                    expression: "form.state"
                                  }
                                }),
                            _vm._v(" "),
                            _c("form-field-text", {
                              attrs: {
                                validator: _vm.$v.form.zip,
                                label: "Business ZIP / Postal Code",
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.zip,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "zip", $$v)
                                },
                                expression: "form.zip"
                              }
                            }),
                            _vm._v(" "),
                            _c("form-field-text", {
                              attrs: {
                                validator: _vm.$v.form.phone,
                                label: "Business Phone",
                                maxlength: 15,
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.phone,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "phone", $$v)
                                },
                                expression: "form.phone"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "form-row form-corporate",
                            staticStyle: { "justify-content": "flex-start" }
                          },
                          [
                            _c("form-field-text", {
                              staticStyle: { "margin-right": "7px" },
                              attrs: {
                                validator: _vm.$v.form.email,
                                label: "Business Email",
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.email,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "email", $$v)
                                },
                                expression: "form.email"
                              }
                            }),
                            _vm._v(" "),
                            _c("form-field-text", {
                              staticStyle: { "margin-right": "7px" },
                              attrs: {
                                validator: _vm.$v.form.website,
                                label: "Business Website",
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.website,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "website", $$v)
                                },
                                expression: "form.website"
                              }
                            }),
                            _vm._v(" "),
                            _c("form-field-select", {
                              key: _vm.businessTypequestions[0].key,
                              attrs: {
                                validator: _vm.$v.form.business_type,
                                label: "Business Type",
                                options: _vm.businessTypequestions[0].options,
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.business_type,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "business_type", $$v)
                                },
                                expression: "form.business_type"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.form.contacts, function(contact, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "h4",
                              { staticStyle: { "margin-top": "16px" } },
                              [
                                _vm._v("Corporate Contact "),
                                index
                                  ? [_vm._v(" " + _vm._s(index + 1))]
                                  : _vm._e(),
                                _vm._v(" Information")
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("corporate-contact", {
                              attrs: {
                                v: _vm.$v.form.contacts.$each[index],
                                initialLoad: _vm.initialLoad,
                                disabled: _vm.updateIndentification,
                                showSin: _vm.showSin
                              },
                              model: {
                                value: _vm.form.contacts[index],
                                callback: function($$v) {
                                  _vm.$set(_vm.form.contacts, index, $$v)
                                },
                                expression: "form.contacts[index]"
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "content-form" }, [
                        _c("div", { staticClass: "form-row form-split" }, [
                          _c("div", [
                            _vm.form.contacts && _vm.form.contacts.length < 4
                              ? _c(
                                  "a",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.addContact()
                                      }
                                    }
                                  },
                                  [_vm._v("+ Add Another Contact")]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.form.contacts && _vm.form.contacts.length > 1
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.removeContact()
                                    }
                                  }
                                },
                                [_vm._v("- Remove Contact")]
                              )
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticStyle: { "margin-top": "16px" } }, [
                        _vm._v("Corporate Business Description")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content-form" }, [
                        _c(
                          "div",
                          { staticClass: "form-row form-split" },
                          _vm._l(_vm.questions, function(question) {
                            return _c("form-field-select", {
                              key: question.key,
                              attrs: {
                                label: question.question,
                                validator: _vm.$v.form.questions[question.key],
                                options: question.options,
                                required: "",
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.questions[question.key],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.questions,
                                    question.key,
                                    $$v
                                  )
                                },
                                expression: "form.questions[question.key]"
                              }
                            })
                          }),
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content-form" }, [
                        _c(
                          "div",
                          { staticClass: "form-row form-fullw" },
                          [
                            _c("form-field-textarea", {
                              attrs: {
                                label: "Business Description",
                                rows: 3,
                                maxlength: 250,
                                disabled: _vm.updateIndentification
                              },
                              model: {
                                value: _vm.form.description,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "description", $$v)
                                },
                                expression: "form.description"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticStyle: { "margin-top": "16px" } }, [
                        _vm._v("Corporate Documents")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content-form" },
                        [
                          _c("div", { staticClass: "form-row form-split" }, [
                            _c(
                              "div",
                              [
                                _c("form-field-upload", {
                                  attrs: {
                                    validator: _vm.$v.form.doc_incorporation,
                                    label: "Business Incorporation Documents",
                                    disabled: _vm.updateIndentification,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.doc_incorporation,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "doc_incorporation",
                                        $$v
                                      )
                                    },
                                    expression: "form.doc_incorporation"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.parsedDocuments["business_incorporation"]
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "uploaded-document",
                                        attrs: {
                                          href:
                                            "/download/RegDocument/" +
                                            _vm.parsedDocuments[
                                              "business_incorporation"
                                            ].id,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.parsedDocuments[
                                                "business_incorporation"
                                              ].file
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("form-field-upload", {
                                  attrs: {
                                    validator:
                                      _vm.$v.form.doc_address_confirmation,
                                    label: "Business Address Confirmation",
                                    disabled: _vm.updateIndentification,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.doc_address_confirmation,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "doc_address_confirmation",
                                        $$v
                                      )
                                    },
                                    expression: "form.doc_address_confirmation"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.parsedDocuments["business_address"]
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "uploaded-document",
                                        attrs: {
                                          href:
                                            "/download/RegDocument/" +
                                            _vm.parsedDocuments[
                                              "business_address"
                                            ].id,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.parsedDocuments[
                                                "business_address"
                                              ].file
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.form.contacts, function(contact, index) {
                            return _c("div", { key: "docs_" + index }, [
                              _c(
                                "div",
                                { staticClass: "form-row form-split" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("form-field-upload", {
                                        attrs: {
                                          validator:
                                            _vm.$v.form.contacts.$each[index]
                                              .doc_passport,
                                          label:
                                            "Contact " +
                                            (index + 1) +
                                            " Passport",
                                          disabled: _vm.updateIndentification,
                                          required: ""
                                        },
                                        model: {
                                          value:
                                            _vm.form.contacts[index]
                                              .doc_passport,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.contacts[index],
                                              "doc_passport",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.contacts[index].doc_passport"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.parsedDocuments[
                                        "contact_passport-" + (index + 1)
                                      ]
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "uploaded-document",
                                              attrs: {
                                                href:
                                                  "/download/RegDocument/" +
                                                  _vm.parsedDocuments[
                                                    "contact_passport-" +
                                                      (index + 1)
                                                  ].id,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.parsedDocuments[
                                                      "contact_passport-" +
                                                        (index + 1)
                                                    ].file
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c("form-field-upload", {
                                        attrs: {
                                          validator:
                                            _vm.$v.form.contacts.$each[index]
                                              .doc_id_type,
                                          label:
                                            "Contact " +
                                            (index + 1) +
                                            " " +
                                            (_vm.form.contacts[index].id_type ||
                                              "Government Issued ID Type"),
                                          disabled: _vm.updateIndentification,
                                          required: ""
                                        },
                                        model: {
                                          value:
                                            _vm.form.contacts[index]
                                              .doc_id_type,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.contacts[index],
                                              "doc_id_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.contacts[index].doc_id_type"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.parsedDocuments[
                                        "contact_government_issued_id-" +
                                          (index + 1)
                                      ]
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "uploaded-document",
                                              attrs: {
                                                href:
                                                  "/download/RegDocument/" +
                                                  _vm.parsedDocuments[
                                                    "contact_government_issued_id-" +
                                                      (index + 1)
                                                  ].id,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.parsedDocuments[
                                                      "contact_government_issued_id-" +
                                                        (index + 1)
                                                    ].file
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-row form-split" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("form-field-upload", {
                                        attrs: {
                                          validator:
                                            _vm.$v.form.contacts.$each[index]
                                              .doc_address_confirmation,
                                          label:
                                            "Contact " +
                                            (index + 1) +
                                            " Address Confirmation",
                                          disabled: _vm.updateIndentification,
                                          required: ""
                                        },
                                        model: {
                                          value:
                                            _vm.form.contacts[index]
                                              .doc_address_confirmation,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.contacts[index],
                                              "doc_address_confirmation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.contacts[index].doc_address_confirmation"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.parsedDocuments[
                                        "contact_address-" + (index + 1)
                                      ]
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "uploaded-document",
                                              attrs: {
                                                href:
                                                  "/download/RegDocument/" +
                                                  _vm.parsedDocuments[
                                                    "contact_address-" +
                                                      (index + 1)
                                                  ].id,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.parsedDocuments[
                                                      "contact_address-" +
                                                        (index + 1)
                                                    ].file
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "cof-row",
                          staticStyle: { "margin-bottom": "16px" }
                        },
                        [
                          _vm.registrationId
                            ? [
                                _vm.updateIndentification
                                  ? _c("div", { staticClass: "cof-field" }, [
                                      _c("label", [_vm._v(" ")]),
                                      _vm._v(" "),
                                      _vm.$hasPerm(
                                        "corporate-update-information"
                                      )
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "action-button update",
                                              on: {
                                                click: function($event) {
                                                  return _vm.update()
                                                }
                                              }
                                            },
                                            [_vm._v("Update")]
                                          )
                                        : _vm._e()
                                    ])
                                  : _c("div", { staticClass: "cof-field" }, [
                                      _c("label", [_vm._v(" ")]),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "action-button save",
                                          on: {
                                            click: function($event) {
                                              return _vm.submit()
                                            }
                                          }
                                        },
                                        [_vm._v("Submit")]
                                      )
                                    ])
                              ]
                            : [
                                _vm.updateIndentification
                                  ? _c("div", { staticClass: "cof-field" }, [
                                      _c("label", [_vm._v(" ")]),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "action-button save",
                                          on: {
                                            click: function($event) {
                                              return _vm.create()
                                            }
                                          }
                                        },
                                        [_vm._v("Create")]
                                      )
                                    ])
                                  : _c("div", { staticClass: "cof-field" }, [
                                      _c("label", [_vm._v(" ")]),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "action-button save",
                                          on: {
                                            click: function($event) {
                                              return _vm.save()
                                            }
                                          }
                                        },
                                        [_vm._v("Save")]
                                      )
                                    ])
                              ]
                        ],
                        2
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.permsNew
                ? _c(
                    "div",
                    {
                      class: {
                        "cof-box": true,
                        "cof-box-active": _vm.activeAccounts
                      }
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "accordion-headline",
                          on: {
                            click: function($event) {
                              _vm.activeAccounts = !_vm.activeAccounts
                            }
                          }
                        },
                        [_vm._v("Corporate Accounts")]
                      ),
                      _vm._v(" "),
                      _c(
                        "h3",
                        {
                          staticClass: "total-number",
                          on: {
                            click: function($event) {
                              _vm.activeAccounts = !_vm.activeAccounts
                            }
                          }
                        },
                        [_vm._v("Click to expand")]
                      ),
                      _vm._v(" "),
                      _c("section", { staticClass: "content-tlist" }, [
                        _c(
                          "table",
                          { staticClass: "content-table" },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _vm._l(_vm.sortAccounts, function(item) {
                              return [
                                _c(
                                  "tbody",
                                  { key: item.id },
                                  [
                                    _c("tr", [
                                      _c("td", [
                                        _c("span", [_vm._v("Date Added")]),
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("date")(
                                              item.createdAt,
                                              "MM/D/YYYY "
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [_vm._v("Account Name")]),
                                        _vm._v(_vm._s(item.accountName))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [_vm._v("Account Type")]),
                                        _vm._v(
                                          _vm._s(
                                            _vm.accountTypeText[
                                              item.accountType
                                            ]
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [_vm._v("Account Number")]),
                                        _vm._v(_vm._s(item.accountNumber))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [_vm._v("Account Status")]),
                                        _vm._v(_vm._s(item.accountStatus))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [_vm._v("Currency")]),
                                        _vm._v(_vm._s(item.currency))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [_vm._v("Provider")]),
                                        _vm._v(
                                          _vm._s(
                                            item.serviceProviders.provider_name
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [
                                          _vm._v("Provider Account")
                                        ]),
                                        _vm._v(
                                          _vm._s(item.providerAccountNumber)
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [_vm._v("Status")]),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(item.result) +
                                            "\n                  "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [_vm._v("Available")]),
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("balance")(
                                              item.systemAvailableBalance
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [_vm._v("Balance")]),
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("balance")(
                                              item.systemTotalBalance
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {},
                                        [
                                          _c("span", [_vm._v("Options")]),
                                          _vm._v(" "),
                                          item.accountStatus == "Active"
                                            ? [
                                                _vm.systemMaintenanceStatus
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "action-button update",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.updateCorpAccount(
                                                              item.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        Update\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            : item.serviceProviders
                                                .provider_name == "Solid" ||
                                              item.serviceProviders
                                                .provider_name == "Solid Test"
                                            ? [
                                                item.solidAccount[0]
                                                  ? void 0
                                                  : [
                                                      item &&
                                                      item.kycStatus ==
                                                        "approved" &&
                                                      item.kybStatus ==
                                                        "approved"
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "action-button save",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.setAccount(
                                                                    item.id,
                                                                    item.provider
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Set Account\n                        "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                              ]
                                            : [
                                                item.result == "Opened" &&
                                                item.cipStatus == "APP" &&
                                                item.amlStatus == "APP" &&
                                                item.kycStatus == "APP"
                                                  ? void 0
                                                  : [
                                                      _vm.systemMaintenanceStatus
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "action-button update",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.updateCorpAccount(
                                                                    item.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Update \n                        "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "action-button save",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.resubmit(
                                                                item.id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Resubmit\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.systemMaintenanceStatus
                                                        ? [
                                                            !_vm.noforce
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "action-button",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.forceCorpAccount(
                                                                          item.id
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Force"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        : _vm._e()
                                                    ]
                                              ]
                                        ],
                                        2
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(2, true),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", { attrs: { colspan: "6" } }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "/download/Account/" + item.id,
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(item.file) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { attrs: { colspan: "6" } },
                                        [
                                          _vm.registration
                                            ? [
                                                _vm._l(
                                                  _vm.registration.documents.filter(
                                                    function(document) {
                                                      return (
                                                        document.type ==
                                                          "Account Agreement" &&
                                                        document.file.indexOf(
                                                          item.accountNumber
                                                        ) >= 0
                                                      )
                                                    }
                                                  ),
                                                  function(document) {
                                                    return [
                                                      _c(
                                                        "a",
                                                        {
                                                          key: document.id,
                                                          attrs: {
                                                            href:
                                                              "/download/RegDocument/" +
                                                              document.id,
                                                            target: "_blank"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                document.file
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(3, true),
                                    _vm._v(" "),
                                    _vm._m(4, true),
                                    _vm._v(" "),
                                    item.serviceProviders.connectionType ==
                                    "Manual"
                                      ? [
                                          item
                                            ? [
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    { attrs: { colspan: "2" } },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.registration
                                                              .data.legal_name
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c(
                                                      "em",
                                                      {
                                                        class:
                                                          _vm
                                                            .statusColorClasses[
                                                            item.amlStatus
                                                          ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.statusColorText[
                                                              item.amlStatus
                                                            ]
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c(
                                                      "em",
                                                      {
                                                        class:
                                                          _vm
                                                            .statusColorClasses[
                                                            item.cipStatus
                                                          ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.statusColorText[
                                                              item.cipStatus
                                                            ]
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c(
                                                      "em",
                                                      {
                                                        class:
                                                          _vm
                                                            .statusColorClasses[
                                                            item.kycStatus
                                                          ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.statusColorText[
                                                              item.kycStatus
                                                            ]
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", {
                                                    attrs: { colspan: "7" }
                                                  })
                                                ])
                                              ]
                                            : _vm._e()
                                        ]
                                      : item.serviceProviders.provider_name ==
                                          "Solid" ||
                                        item.serviceProviders.provider_name ==
                                          "Solid Test"
                                      ? [
                                          item
                                            ? [
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    { attrs: { colspan: "2" } },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.registration
                                                              .data.legal_name
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _c("td"),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c(
                                                      "em",
                                                      {
                                                        class:
                                                          _vm
                                                            .statusColorClasses[
                                                            item.kybStatus
                                                          ]
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.statusColorText[
                                                              item.kybStatus
                                                            ]
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    { attrs: { colspan: "7" } },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "action-button cancel",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.viewSolidBusinessMessage(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("View")]
                                                      )
                                                    ]
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.registration.data
                                                    .contacts,
                                                  function(contact) {
                                                    return _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            colspan: "2"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                contact.firstName
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                contact.lastName
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("td", {
                                                        attrs: { colspan: "1" }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("td", {
                                                        attrs: { colspan: "1" }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            colspan: "1"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "em",
                                                            {
                                                              class:
                                                                _vm
                                                                  .statusColorClasses[
                                                                  item.kycStatus
                                                                ]
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .statusColorText[
                                                                    item
                                                                      .kycStatus
                                                                  ]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            colspan: "7"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "action-button cancel",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.viewSolidContactMessage(
                                                                    contact.firstName +
                                                                      " " +
                                                                      contact.lastName,
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("View")]
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  }
                                                )
                                              ]
                                            : _vm._e()
                                        ]
                                      : item.response
                                      ? _vm._l(item.response.included, function(
                                          include
                                        ) {
                                          return include.type == "contacts"
                                            ? _c("tr", [
                                                _c(
                                                  "td",
                                                  { attrs: { colspan: "2" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        include.attributes.name
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { attrs: { colspan: "1" } },
                                                  [
                                                    include.attributes[
                                                      "aml-cleared"
                                                    ]
                                                      ? [
                                                          _c(
                                                            "em",
                                                            {
                                                              staticClass: "c1"
                                                            },
                                                            [_vm._v("Passed")]
                                                          )
                                                        ]
                                                      : [
                                                          _c(
                                                            "em",
                                                            {
                                                              staticClass: "c2"
                                                            },
                                                            [_vm._v("Pending")]
                                                          )
                                                        ]
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { attrs: { colspan: "1" } },
                                                  [
                                                    include.attributes[
                                                      "cip-cleared"
                                                    ]
                                                      ? [
                                                          _c(
                                                            "em",
                                                            {
                                                              staticClass: "c1"
                                                            },
                                                            [_vm._v("Passed")]
                                                          )
                                                        ]
                                                      : [
                                                          _c(
                                                            "em",
                                                            {
                                                              staticClass: "c2"
                                                            },
                                                            [_vm._v("Pending")]
                                                          )
                                                        ]
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { attrs: { colspan: "1" } },
                                                  [
                                                    include.attributes[
                                                      "identity-confirmed"
                                                    ]
                                                      ? [
                                                          _c(
                                                            "em",
                                                            {
                                                              staticClass: "c1"
                                                            },
                                                            [_vm._v("Passed")]
                                                          )
                                                        ]
                                                      : [
                                                          _c(
                                                            "em",
                                                            {
                                                              staticClass: "c2"
                                                            },
                                                            [_vm._v("Pending")]
                                                          )
                                                        ]
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  { attrs: { colspan: "7" } },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "action-button cancel",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.viewMessage(
                                                              include.id,
                                                              include.attributes
                                                                .name,
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("View")]
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _vm._e()
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._m(5, true)
                                  ],
                                  2
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.errorAccount, width: 400 },
          on: {
            "update:open": function($event) {
              _vm.errorAccount = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Error")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "scrollable" },
            [
              _vm._v("\n      Error - " + _vm._s(_vm.errorDate) + "\n      "),
              _c("p", [_vm._v(_vm._s(_vm.errorMessage))]),
              _vm._v(" "),
              _vm._l(_vm.messages, function(message) {
                return _c("p", { staticStyle: { "text-align": "center" } }, [
                  _c("b", [_vm._v(_vm._s(message) + " is missing.")])
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.providerMessages, function(providerMessage) {
                return _c("p", { staticStyle: { "text-align": "center" } }, [
                  _c("b", [_vm._v(_vm._s(providerMessage))])
                ])
              })
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "popup", class: { open: _vm.open } }, [
        _c("div", { staticClass: "pop-inner", style: { maxWidth: "400px" } }, [
          _c("div", { staticClass: "pop-box" }, [
            _c("h2", [_vm._v("Enter Admin PIN to Continue")]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pin,
                      expression: "pin"
                    }
                  ],
                  staticClass: "pin-code",
                  attrs: {
                    oninput:
                      "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                    type: "number",
                    maxlength: "4"
                  },
                  domProps: { value: _vm.pin },
                  on: {
                    keyup: _vm.pinInput,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.pin = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c(
                "div",
                { staticClass: "pf-field" },
                [_vm.pinFailed ? [_vm._v("Invalid PIN")] : _vm._e()],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row buttons" }, [
              _c(
                "a",
                {
                  staticClass: "pop-btn",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancelPin()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "pop-btn",
                  on: {
                    click: function($event) {
                      return _vm.checkPin()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "popup", class: { open: _vm.openForce } }, [
        _c("div", { staticClass: "pop-inner", style: { maxWidth: "400px" } }, [
          _c("div", { staticClass: "pop-box" }, [
            _c("h2", [_vm._v("Enter Admin PIN to Continue")]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pinForce,
                      expression: "pinForce"
                    }
                  ],
                  staticClass: "pin-code",
                  attrs: {
                    oninput:
                      "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                    type: "number",
                    maxlength: "4"
                  },
                  domProps: { value: _vm.pinForce },
                  on: {
                    keyup: _vm.pinInput,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.pinForce = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c(
                "div",
                { staticClass: "pf-field" },
                [_vm.pinFailedForce ? [_vm._v("Invalid PIN")] : _vm._e()],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row buttons" }, [
              _c(
                "a",
                {
                  staticClass: "pop-btn",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.cancelPinForce()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "pop-btn",
                  on: {
                    click: function($event) {
                      return _vm.checkPinForce()
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.contactItem
        ? _c(
            "popup",
            {
              attrs: { open: _vm.messageOpen },
              on: {
                "update:open": function($event) {
                  _vm.messageOpen = $event
                }
              }
            },
            [
              _c("h2", [_vm._v("Messages")]),
              _vm._v(" "),
              _vm.contactItem.serviceProviders.provider_name == "Solid" ||
              _vm.contactItem.serviceProviders.provider_name == "Solid Test"
                ? _c(
                    "div",
                    { staticClass: "pf-row split comliance-header" },
                    [
                      _vm.business
                        ? [
                            _vm.contactItem
                              ? _c("p", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.statusColorText[
                                        _vm.contactItem.kybStatus
                                      ]
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.contactItem
                              ? _c("p", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    _vm._s(_vm.registration.data.legal_name)
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.contactItem
                              ? _c("p", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.contactItem.solidAccount
                                        ? _vm.contactItem.solidAccount
                                            .accountNumber
                                        : ""
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.contactItem
                              ? _c("p", { staticClass: "subtitle" }, [
                                  _vm._v(_vm._s(_vm.contactItem.createdAt))
                                ])
                              : _vm._e()
                          ]
                        : [
                            _vm.contactItem
                              ? _c("p", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.statusColorText[
                                        _vm.contactItem.kycStatus
                                      ]
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.contactItem
                              ? _c("p", { staticClass: "subtitle" }, [
                                  _vm._v(_vm._s(_vm.contactName))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.contactItem
                              ? _c("p", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.contactItem.solidAccount
                                        ? _vm.contactItem.solidAccount
                                            .accountNumber
                                        : ""
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.contactItem
                              ? _c("p", { staticClass: "subtitle" }, [
                                  _vm._v(_vm._s(_vm.contactItem.createdAt))
                                ])
                              : _vm._e()
                          ]
                    ],
                    2
                  )
                : _c("div", { staticClass: "pf-row split comliance-header" }, [
                    _vm.contactItem
                      ? _c("p", { staticClass: "subtitle" }, [
                          _vm._v(_vm._s(_vm.contactItem.result))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.contactItem
                      ? _c("p", { staticClass: "subtitle" }, [
                          _vm._v(_vm._s(_vm.contactName))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.contactItem
                      ? _c("p", { staticClass: "subtitle" }, [
                          _vm._v(_vm._s(_vm.contactItem.accountNumber))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.contactItem
                      ? _c("p", { staticClass: "subtitle" }, [
                          _vm._v(_vm._s(_vm.contactItem.createdAt))
                        ])
                      : _vm._e()
                  ]),
              _vm._v(" "),
              _c("h3", { staticClass: "subtitle" }, [_vm._v("Review Details")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pf-row split" },
                [
                  _vm.contactItem
                    ? [
                        _vm.contactId && _vm.contactItem.reviewDetail
                          ? _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.contactItem.reviewDetail[_vm.contactId]
                                )
                              )
                            ])
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm.contactItem.serviceProviders.provider_name == "Solid" ||
              _vm.contactItem.serviceProviders.provider_name == "Solid Test"
                ? _c("div", { staticClass: "pf-row split" }, [
                    _c(
                      "div",
                      { staticClass: "pf-field" },
                      [
                        _c("h3", { staticClass: "subtitle" }, [
                          _vm._v("Review Code")
                        ]),
                        _vm._v(" "),
                        _vm.contactItem
                          ? [
                              _c("p", [
                                _vm._v(_vm._s(_vm.contactItem.reviewCode))
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pf-field" },
                      [
                        _c("h3", { staticClass: "subtitle" }, [
                          _vm._v("Review Message")
                        ]),
                        _vm._v(" "),
                        _vm.contactItem
                          ? [
                              _c("p", [
                                _vm._v(_vm._s(_vm.contactItem.reviewMessage))
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row split" }, [
                _c(
                  "div",
                  { staticClass: "pf-field" },
                  [
                    _c("h3", { staticClass: "subtitle" }, [
                      _vm._v("AML Message")
                    ]),
                    _vm._v(" "),
                    _vm.contactItem
                      ? [
                          _vm.contactId && _vm.contactItem.amlMessage
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.contactItem.amlMessage[_vm.contactId]
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pf-field" },
                  [
                    _c("h3", { staticClass: "subtitle" }, [
                      _vm._v("AML Message Details")
                    ]),
                    _vm._v(" "),
                    _vm.contactItem
                      ? [
                          _vm.contactId && _vm.contactItem.amlMessageDetail
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.contactItem.amlMessageDetail[
                                      _vm.contactId
                                    ]
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row split" }, [
                _c(
                  "div",
                  { staticClass: "pf-field" },
                  [
                    _c("h3", { staticClass: "subtitle" }, [
                      _vm._v("CIP Message")
                    ]),
                    _vm._v(" "),
                    _vm.contactItem
                      ? [
                          _vm.contactId && _vm.contactItem.cipMessage
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.contactItem.cipMessage[_vm.contactId]
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pf-field" },
                  [
                    _c("h3", { staticClass: "subtitle" }, [
                      _vm._v("CIP Message Details")
                    ]),
                    _vm._v(" "),
                    _vm.contactItem
                      ? [
                          _vm.contactId && _vm.contactItem.cipMessageDetail
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.contactItem.cipMessageDetail[
                                      _vm.contactId
                                    ]
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row split" }, [
                _c(
                  "div",
                  { staticClass: "pf-field" },
                  [
                    _c("h3", { staticClass: "subtitle" }, [
                      _vm._v("KYC Message")
                    ]),
                    _vm._v(" "),
                    _vm.contactItem
                      ? [
                          _vm.contactId && _vm.contactItem.kycMessage
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.contactItem.kycMessage[_vm.contactId]
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pf-field" },
                  [
                    _c("h3", { staticClass: "subtitle" }, [
                      _vm._v("KYC Message Details")
                    ]),
                    _vm._v(" "),
                    _vm.contactItem
                      ? [
                          _vm.contactId && _vm.contactItem.kycMessageDetail
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.contactItem.kycMessageDetail[
                                      _vm.contactId
                                    ]
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _vm.business
                ? _c("div", { staticClass: "pf-row split" }, [
                    _c(
                      "div",
                      { staticClass: "pf-field" },
                      [
                        _c("h3", { staticClass: "subtitle" }, [
                          _vm._v("KYB Message")
                        ]),
                        _vm._v(" "),
                        _vm.contactItem ? void 0 : _vm._e()
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pf-field" },
                      [
                        _c("h3", { staticClass: "subtitle" }, [
                          _vm._v("KYB Message Details")
                        ]),
                        _vm._v(" "),
                        _vm.contactItem
                          ? [
                              _c("p", [
                                _vm._v(_vm._s(_vm.contactItem.kycResults))
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row buttons" }, [
                _c(
                  "a",
                  {
                    staticClass: "pop-btn",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.messageOpen = false
                      }
                    }
                  },
                  [_vm._v("Close")]
                )
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Date Added")]),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Number")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Currency")]),
      _vm._v(" "),
      _c("th", [_vm._v("Provider")]),
      _vm._v(" "),
      _c("th", [_vm._v("Provider Account")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Available")]),
      _vm._v(" "),
      _c("th", [_vm._v("Balance")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("Account Agreement")]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("Final Account Agreement")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "13" } }, [_vm._v("Compliance")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "2" } }, [_vm._v("Name")]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [_vm._v("AML")]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [_vm._v("CIP")]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "1" } }, [_vm._v("KYC")]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "7" } }, [_vm._v("Messages")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "12" } })])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ProfileReviews.vue?vue&type=template&id=5942032a&scoped=true&"
import script from "./ProfileReviews.vue?vue&type=script&lang=js&"
export * from "./ProfileReviews.vue?vue&type=script&lang=js&"
import style0 from "./ProfileReviews.vue?vue&type=style&index=0&id=5942032a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5942032a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6monbi/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5942032a')) {
      api.createRecord('5942032a', component.options)
    } else {
      api.reload('5942032a', component.options)
    }
    module.hot.accept("./ProfileReviews.vue?vue&type=template&id=5942032a&scoped=true&", function () {
      api.rerender('5942032a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/monbi/tools/ProfileReviews.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c("section", { staticClass: "client-properties active" }, [
          _c(
            "div",
            { staticClass: "cp-box" },
            [
              _c(
                "ul",
                {
                  staticClass: "sub-tabs",
                  staticStyle: { "margin-bottom": "50px" }
                },
                [
                  _c(
                    "li",
                    {
                      class: { active: _vm.active === 1 },
                      on: {
                        click: function($event) {
                          _vm.active = 1
                        }
                      }
                    },
                    [_c("a", [_vm._v("My Info")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: { active: _vm.active === 2 },
                      on: {
                        click: function($event) {
                          _vm.active = 2
                        }
                      }
                    },
                    [_c("a", [_vm._v("User Log")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: { active: _vm.active === 3 },
                      on: {
                        click: function($event) {
                          _vm.active = 3
                        }
                      }
                    },
                    [
                      _c("a", { staticStyle: { background: "limegreen" } }, [
                        _vm._v("Change Password")
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.active === 1
                ? _c("div", [
                    _c(
                      "ul",
                      { staticClass: "cp-fields justify-content-none" },
                      [
                        _c("li", [
                          _c("span", [_vm._v("Active Since")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("date")(_vm.user.activationDate)
                                ) +
                                "\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Last Password Change Date")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "val" },
                            [
                              _vm.user.lastPasswordChangeDate
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(
                                          _vm.user.lastPasswordChangeDate
                                        )
                                      )
                                    )
                                  ]
                                : [_vm._v("N/A")]
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Employee ID")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.user.employee
                                    ? _vm.user.employee.employeeId
                                    : "N/A"
                                ) +
                                "\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Role")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.user.employee
                                    ? _vm.user.employee.roles.name
                                    : "N/A"
                                ) +
                                "\n            "
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("h4", { staticClass: "title" }, [
                      _vm._v("Employee Information")
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "cp-fields justify-content-none space-bottom"
                      },
                      [
                        _c("li", [
                          _c("span", [_vm._v("First Name")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.user.firstName) +
                                "\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Last Name")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.user.lastName) +
                                "\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Email Address")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.user.email) +
                                "\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("span", [_vm._v("Phone Number")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.user.employee
                                    ? _vm.user.employee.phone
                                    : "N/A"
                                ) +
                                "\n            "
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.active === 2
                ? _c("div", [
                    _vm.lastLogin
                      ? _c(
                          "ul",
                          { staticClass: "cp-fields justify-content-none" },
                          [
                            _c("li", [
                              _c("span", [_vm._v("Last Login")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("date")(_vm.lastLogin.recorded)
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("span", [_vm._v("Data Transfer")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("fileSize")(
                                        _vm.lastLogin.transferred
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c("span", [_vm._v("IP Address")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "val" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.lastLogin.ipAddress) +
                                    "\n            "
                                )
                              ])
                            ])
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.active === 3 ? _c("change-password") : _vm._e()
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [
        _c("h2", { staticClass: "inner-headers" }, [_vm._v("My Profile")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
      [
        _c("table", { staticClass: "content-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("Registration Tracking Link")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.trackingLink))]),
            _vm._v(" "),
            _c(
              "td",
              [
                _vm.$hasPerm("partners-manage")
                  ? [
                      !_vm.trackingLink
                        ? _c(
                            "button",
                            {
                              staticClass: "main",
                              attrs: {
                                disabled: !_vm.partner.settings
                                  .registrationTracking
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.createCode($event)
                                }
                              }
                            },
                            [_vm._v("New Link")]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "main",
                              attrs: {
                                disabled: !_vm.partner.settings
                                  .registrationTracking
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.createCode($event)
                                }
                              }
                            },
                            [_vm._v("Update Link")]
                          )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Link")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
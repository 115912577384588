import { render, staticRenderFns } from "./RegistrationData.vue?vue&type=template&id=e70e0f7e&scoped=true&"
import script from "./RegistrationData.vue?vue&type=script&lang=js&"
export * from "./RegistrationData.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationData.vue?vue&type=style&index=0&id=e70e0f7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e70e0f7e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6monbi/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e70e0f7e')) {
      api.createRecord('e70e0f7e', component.options)
    } else {
      api.reload('e70e0f7e', component.options)
    }
    module.hot.accept("./RegistrationData.vue?vue&type=template&id=e70e0f7e&scoped=true&", function () {
      api.rerender('e70e0f7e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/monbi/customers/RegistrationData.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      staticClass: "confirm-popup",
      attrs: { open: _vm.codeOpen },
      on: {
        "update:open": function($event) {
          _vm.codeOpen = $event
        }
      }
    },
    [
      _c("h2", [_vm._v("New Employee Created")]),
      _vm._v(" "),
      _c("form", { staticClass: "pop-form" }, [
        _c("div", { staticClass: "pf-row split" }, [
          _c("h5", [_vm._v("New Employee successfully created.")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pf-row split" }, [
          _c("h5", [
            _vm._v("Activation Code is: " + _vm._s(_vm.activationCode))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pf-row buttons" }, [
          _c(
            "button",
            {
              staticClass: "pop-btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.codeOpen = false
                }
              }
            },
            [_vm._v("OK")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    { attrs: { open: "" } },
    [
      _c("div", { staticClass: "popup-header" }, [
        _c("h3", [_vm._v("Trade")]),
        _vm._v(" "),
        _vm.trade
          ? _c("h3", [_vm._v(_vm._s(_vm.trade.tradeNumber))])
          : _vm._e(),
        _vm._v(" "),
        _vm.trade
          ? _c("h3", [
              _vm._v(
                "Status: " +
                  _vm._s(_vm.letterUpper(_vm.getStatus(_vm.trade.quoteStatus)))
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      [
        _c(
          "div",
          { staticClass: "scrollable" },
          [
            _c(
              "div",
              { staticClass: "information" },
              [
                _vm.trade
                  ? [
                      _c("div", [
                        _c("p", [
                          _c("b", [_vm._v("Account")]),
                          _vm._v(
                            ": ****" + _vm._s(_vm.trade.accountId.substr(4, 3))
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("b", [_vm._v("Created")]),
                          _vm._v(
                            ": " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.trade.createdAt,
                                  "MM/D/YYYY hh:mm A"
                                )
                              )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("p", [
                          _c("b", [_vm._v("Profile")]),
                          _vm._v(": " + _vm._s(_vm.trade.profileId))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("b", [_vm._v("Completed By")]),
                          _vm._v(": " + _vm._s(_vm.trade.completedBy))
                        ])
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "popup-header details" }, [
              _c("h3", [_vm._v("Details")])
            ]),
            _vm._v(" "),
            _vm.trade
              ? [
                  _c("div", { staticClass: "information" }, [
                    _c("div", [
                      _c("p", [
                        _c("b", [_vm._v("Quote")]),
                        _vm._v(": " + _vm._s(_vm.trade.quoteNumber))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("Trade Type")]),
                        _vm._v(": " + _vm._s(_vm.trade.quoteType))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("Base Amount")]),
                        _vm._v(
                          ": " + _vm._s(_vm._f("balance")(_vm.trade.baseAmount))
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("Total Amount")]),
                        _vm._v(
                          ": " +
                            _vm._s(_vm._f("balance")(_vm.trade.totalAmount))
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("Unit Count")]),
                        _vm._v(": " + _vm._s(parseFloat(_vm.trade.unitCount)))
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(" ")]),
                      _vm._v(" "),
                      _c("p", [_vm._v(" ")]),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _c("b", [_vm._v("Completed On")]),
                          _vm._v(": "),
                          _vm.trade.trade.providerSettledAt
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.trade.trade.providerSettledAt,
                                      "MM/D/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("p", [
                        _c("b", [_vm._v("Trade")]),
                        _vm._v(": " + _vm._s(_vm.trade.tradeNumber))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("Asset")]),
                        _vm._v(": " + _vm._s(_vm.trade.assetName))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("Broker Fee")]),
                        _vm._v(
                          ": " +
                            _vm._s(
                              _vm._f("balance")(Math.abs(_vm.trade.feeAmount))
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("Price Per Unit")]),
                        _vm._v(
                          ": " + _vm._s(parseFloat(_vm.trade.pricePerUnit))
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("b", [_vm._v("Trade Fee")]),
                        _vm._v(
                          ": " +
                            _vm._s(
                              _vm._f("balance")(Math.abs(_vm.trade.tradeFee))
                            )
                        )
                      ])
                    ])
                  ])
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "popup-header notes" }, [
          _c("h3", [_vm._v("Options")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _c("div"),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "main",
              on: {
                click: function($event) {
                  return _vm.print(_vm.trade.id)
                }
              }
            },
            [_vm._v("Print")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "second",
              on: {
                click: function($event) {
                  return _vm.closeTradeDetailModal()
                }
              }
            },
            [_vm._v("Close")]
          )
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("section", [
        _c("div", { staticClass: "pin-entry" }, [
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("h3", [_vm._v("Access Denied")])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
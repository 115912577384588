var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("h2", { staticClass: "inner-headers" }, [_vm._v("System Messages")]),
    _vm._v(" "),
    _c("div", { staticClass: "messages" }, [
      _c(
        "div",
        { staticClass: "panel" },
        [
          _c(
            "div",
            { staticClass: "flex-right" },
            [
              !_vm.onlyDiscarded
                ? [
                    _vm.messages.some(function(message) {
                      return !message.read
                    })
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Mark all as read",
                              effect: "dark",
                              placement: "top"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                icon: "el-icon-check",
                                size: "small",
                                plain: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.readAll()
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.messages.some(function(message) {
                      return !message.discarded
                    })
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Discard all",
                              effect: "dark",
                              placement: "top"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "danger",
                                icon: "el-icon-close",
                                size: "small",
                                plain: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.askDiscardAll()
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button-group",
                { staticClass: "ml-20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.onlyDiscarded ? "default" : "primary",
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          _vm.onlyDiscarded = false
                        }
                      }
                    },
                    [_vm._v("\n            Inbox\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.onlyDiscarded ? "primary" : "default",
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          _vm.onlyDiscarded = true
                        }
                      }
                    },
                    [_vm._v("\n            Discarded\n          ")]
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _vm._l(_vm.messages, function(message) {
            return _c("system-message", {
              key: message.id,
              attrs: { message: message }
            })
          }),
          _vm._v(" "),
          !_vm.messages.length
            ? _c("el-empty", {
                attrs: { description: "There are no system messages found." }
              })
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subnav" }, [_c("ul")])
  }
]
render._withStripped = true

export { render, staticRenderFns }
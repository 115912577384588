import { render, staticRenderFns } from "./GeneralInfo.vue?vue&type=template&id=a3360330&"
import script from "./GeneralInfo.vue?vue&type=script&lang=js&"
export * from "./GeneralInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6monbi/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a3360330')) {
      api.createRecord('a3360330', component.options)
    } else {
      api.reload('a3360330', component.options)
    }
    module.hot.accept("./GeneralInfo.vue?vue&type=template&id=a3360330&", function () {
      api.rerender('a3360330', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/monbi/customers/GeneralInfo.vue"
export default component.exports
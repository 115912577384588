var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-form vgv-form" },
    [
      !_vm.vgvScriptLoaded
        ? _c("div", [_vm._v("\n    Loading, please wait...\n  ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.vgvScriptLoaded ? _vm._t("default") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
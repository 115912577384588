import { render, staticRenderFns } from "./DisputeManager.vue?vue&type=template&id=4658b34e&"
import script from "./DisputeManager.vue?vue&type=script&lang=js&"
export * from "./DisputeManager.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6monbi/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4658b34e')) {
      api.createRecord('4658b34e', component.options)
    } else {
      api.reload('4658b34e', component.options)
    }
    module.hot.accept("./DisputeManager.vue?vue&type=template&id=4658b34e&", function () {
      api.rerender('4658b34e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/monbi/partners/DisputeManager.vue"
export default component.exports
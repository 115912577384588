var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { width: 600, open: "" },
      on: {
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("div", { staticClass: "popup-header" }, [
        _c("h3", [_vm._v("New Trade")])
      ]),
      _vm._v(" "),
      !_vm.error
        ? [
            !_vm.addConfirmModal
              ? [
                  _vm.system.transaction_trade
                    ? [
                        _c("div", { staticClass: "scrollable" }, [
                          _c(
                            "div",
                            { staticClass: "content-form content-form-modern" },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "margin-center" }, [
                                  _vm._v(
                                    "\n                The Trade Desk currently supports: "
                                  ),
                                  _c("b", [
                                    _vm._v("USD, BTC, ETH, LTC, USDC, USDT")
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-row form-split" },
                                [
                                  _c("form-field-select-search", {
                                    attrs: {
                                      label: "Account",
                                      validator: _vm.$v.form.account,
                                      options: _vm.accountOptions,
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.form.account,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "account", $$v)
                                      },
                                      expression: "form.account"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("form-field-select", {
                                    attrs: {
                                      label: "I want to...",
                                      validator: _vm.$v.form.tradeType,
                                      options: _vm.tradeTypeOptions,
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.form.tradeType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "tradeType", $$v)
                                      },
                                      expression: "form.tradeType"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-row form-split" },
                                [
                                  _c("form-field-select", {
                                    attrs: {
                                      label: "Assets Type",
                                      validator: _vm.$v.form.assetsCurrency,
                                      options: _vm.assetsCurrencyOptions,
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.form.assetsCurrency,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "assetsCurrency",
                                          $$v
                                        )
                                      },
                                      expression: "form.assetsCurrency"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("form-field-select", {
                                    attrs: {
                                      label: "Amount is in...",
                                      validator: _vm.$v.form.depositType,
                                      options: _vm.depositTypeOptions,
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.form.depositType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "depositType", $$v)
                                      },
                                      expression: "form.depositType"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-row form-split" },
                                [
                                  _vm.form.depositType == "Funds"
                                    ? _c("form-field-text", {
                                        attrs: {
                                          label: "Amount",
                                          validator: _vm.$v.form.fundsAmount,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.form.fundsAmount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "fundsAmount",
                                              $$v
                                            )
                                          },
                                          expression: "form.fundsAmount"
                                        }
                                      })
                                    : _c("form-field-text", {
                                        attrs: {
                                          label: "Amount",
                                          validator: _vm.$v.form.unitsAmount,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.form.unitsAmount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "unitsAmount",
                                              $$v
                                            )
                                          },
                                          expression: "form.unitsAmount"
                                        }
                                      })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "invalid-balance" }, [
                                _vm.invalidBalance
                                  ? _c(
                                      "div",
                                      { staticClass: "error-message" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "Exceeds available balance. Please lower amount and try again."
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "sum" }, [
                                _c("div", [
                                  _vm._v("Trade Fee: "),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm._f("money")(_vm.fee)))
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.form.depositType == "Funds"
                                  ? _c("div", [
                                      _vm._v("Total Amount: "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("money")(_vm.totalFunds)
                                          )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.form.depositType == "Units"
                                  ? _c("div", [
                                      _vm._v("Total Units: "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("unit_asset_balance")(
                                              _vm.totalUnits
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "buttons" }, [
                          _c(
                            "button",
                            {
                              staticClass: "main",
                              on: {
                                click: function($event) {
                                  return _vm.submit()
                                }
                              }
                            },
                            [_vm._v("Submit")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "second",
                              staticStyle: {
                                "margin-right": "auto",
                                "margin-left": "12px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            },
                            [_vm._v("Close")]
                          )
                        ])
                      ]
                    : [
                        _c("div", { staticClass: "scrollable" }, [
                          _c("div", { staticClass: "disabledText" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.system.disabled_trade_text))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "buttons" }, [
                          _c(
                            "button",
                            {
                              staticClass: "second",
                              staticStyle: {
                                "margin-right": "auto",
                                "margin-left": "12px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            },
                            [_vm._v("Close")]
                          )
                        ])
                      ]
                ]
              : !_vm.accepted
              ? [
                  _c("div", { staticClass: "scrollable" }, [
                    _c("h3", { staticClass: "subtitle quote-title" }, [
                      _vm._v("Quote Details")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "content-form content-form-modern text-center"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "quote-panel" },
                          [
                            _c("p", [
                              _c("b", [_vm._v("Date: ")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("date")(_vm.quote.createdAt))
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("b", [_vm._v("Quote: ")]),
                              _vm._v(" " + _vm._s(_vm.quote.quoteNumber))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("b", [_vm._v("Type: ")]),
                              _vm._v(" " + _vm._s(_vm.quote.quoteType))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("b", [_vm._v("Asset: ")]),
                              _vm._v(" " + _vm._s(_vm.quote.assetName))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("b", [_vm._v("Base Amount: ")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("balance")(_vm.quote.baseAmount)
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("b", [_vm._v("Broker Fee: ")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("balance")(_vm.quote.feeAmount))
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("b", [_vm._v("Total Amount: ")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("balance")(_vm.quote.totalAmount)
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("b", [_vm._v("Price Per Unit: ")]),
                              _vm._v(" " + _vm._s(_vm.quote.pricePerUnit))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("b", [_vm._v("Unit Count: ")]),
                              _vm._v(" " + _vm._s(_vm.quote.unitCount))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("b", [_vm._v("Trade Fee: ")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("balance")(_vm.quote.tradeFee))
                              )
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            !_vm.cancelled
                              ? [
                                  _vm.countDown != 0
                                    ? _c("p", [
                                        _vm._v(
                                          "This quote expires in " +
                                            _vm._s(_vm.countDown) +
                                            " seconds..."
                                        )
                                      ])
                                    : _c("p", [
                                        _vm._v("This quote has expired.")
                                      ])
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cancelled
                              ? _c("p", [
                                  _vm._v("This Quote has been cancelled")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.errorSell
                              ? _c("p", [
                                  _vm._v(
                                    "Cannot Complete - Quote amount, exceeds available balance."
                                  )
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex buttons quote-buttons" },
                    [
                      _vm.errorSell
                        ? [
                            _c(
                              "button",
                              {
                                staticClass: "second",
                                staticStyle: { "margin-left": "12px" },
                                on: {
                                  click: function($event) {
                                    return _vm.close()
                                  }
                                }
                              },
                              [_vm._v("Close")]
                            )
                          ]
                        : [
                            _vm.countDown != 0
                              ? [
                                  !_vm.cancelled
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "main",
                                          on: {
                                            click: function($event) {
                                              return _vm.accept()
                                            }
                                          }
                                        },
                                        [_vm._v("Accept")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.cancelled
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "second",
                                          staticStyle: {
                                            "margin-left": "12px"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.close()
                                            }
                                          }
                                        },
                                        [_vm._v("Close")]
                                      )
                                    : _vm._e()
                                ]
                              : [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "second",
                                      staticStyle: { "margin-left": "12px" },
                                      on: {
                                        click: function($event) {
                                          return _vm.close()
                                        }
                                      }
                                    },
                                    [_vm._v("Close")]
                                  )
                                ]
                          ]
                    ],
                    2
                  )
                ]
              : [
                  _c("div", { staticClass: "scrollable" }, [
                    _c("h3", { staticClass: "subtitle quote-title" }, [
                      _vm._v("Trade Confirmation")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "content-form content-form-modern text-center"
                      },
                      [
                        _vm.error || _vm.failed
                          ? _c("div", { staticClass: "quote-panel" }, [
                              _vm.error
                                ? _c("span", [_vm._v("Error")])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.failed
                                ? _c("span", [_vm._v("Failed")])
                                : _vm._e()
                            ])
                          : _c("div", { staticClass: "quote-panel" }, [
                              _c("p", [
                                _c("b", [_vm._v("Date: ")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("date")(_vm.trade.createdAt))
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Quote: ")]),
                                _vm._v(" " + _vm._s(_vm.trade.quoteNumber))
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Trade: ")]),
                                _vm._v(" " + _vm._s(_vm.trade.tradeNumber))
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Type: ")]),
                                _vm._v(" " + _vm._s(_vm.quote.quoteType))
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Asset: ")]),
                                _vm._v(" " + _vm._s(_vm.quote.assetName))
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Base Amount: ")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("balance")(_vm.quote.baseAmount)
                                    )
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Broker Fee: ")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("balance")(_vm.quote.feeAmount)
                                    )
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Total Amount: ")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("balance")(_vm.quote.totalAmount)
                                    )
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Price Per Unit: ")]),
                                _vm._v(" " + _vm._s(_vm.quote.pricePerUnit))
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Unit Count: ")]),
                                _vm._v(" " + _vm._s(_vm.quote.unitCount))
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Trade Fee: ")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("balance")(_vm.quote.tradeFee)
                                    )
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Completed On: ")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("date")(_vm.quote.quoteExecutedAt)
                                    )
                                )
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("p", [_vm._v("This trade is completed")])
                            ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex buttons quote-buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "second",
                        on: {
                          click: function($event) {
                            return _vm.close()
                          }
                        }
                      },
                      [_vm._v("Close")]
                    )
                  ])
                ]
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? [
            _c("div", { staticClass: "scrollable" }, [
              _c(
                "div",
                { staticClass: "content-form content-form-modern text-center" },
                [
                  _c("div", { staticClass: "quote-panel" }, [
                    _c("p", [
                      _vm._v(
                        "The quote cannot be completed at this time. Please try again later."
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.errorMessage))])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buttons quote-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "second",
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "popup", class: { open: _vm.show } }, [
      _c("div", { staticClass: "pop-inner" }, [
        _c("div", { staticClass: "pop-box" }, [
          _c("a", {
            staticClass: "close",
            on: {
              click: function($event) {
                _vm.$parent.currentUser = null
              }
            }
          }),
          _vm._v(" "),
          _c("h2", [_vm._v("Edit User")]),
          _vm._v(" "),
          _c("form", [
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("Username")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentUser.username,
                    expression: "currentUser.username"
                  }
                ],
                attrs: { type: "text", disabled: "" },
                domProps: { value: _vm.currentUser.username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.currentUser, "username", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("Role")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentUser.newRole,
                      expression: "currentUser.newRole"
                    }
                  ],
                  attrs: { disabled: "" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.currentUser,
                        "newRole",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.roles, function(role) {
                  return _c("option", { domProps: { value: role.id } }, [
                    _vm._v(_vm._s(role.name))
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("Email")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentUser.email,
                    expression: "currentUser.email"
                  }
                ],
                attrs: { type: "text", disabled: "" },
                domProps: { value: _vm.currentUser.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.currentUser, "email", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("Title")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentUser.title,
                    expression: "currentUser.title"
                  }
                ],
                attrs: { type: "text", disabled: "" },
                domProps: { value: _vm.currentUser.title },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.currentUser, "title", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("First Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentUser.firstName,
                    expression: "currentUser.firstName"
                  }
                ],
                attrs: { type: "text", disabled: "" },
                domProps: { value: _vm.currentUser.firstName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.currentUser, "firstName", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("Last Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentUser.lastName,
                    expression: "currentUser.lastName"
                  }
                ],
                attrs: { type: "text", disabled: "" },
                domProps: { value: _vm.currentUser.lastName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.currentUser, "lastName", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("Phone")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentUser.phone,
                    expression: "currentUser.phone"
                  }
                ],
                attrs: { type: "text", disabled: "" },
                domProps: { value: _vm.currentUser.phone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.currentUser, "phone", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("Password")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentUser.newPassword,
                    expression: "currentUser.newPassword"
                  }
                ],
                attrs: { type: "text", disabled: "" },
                domProps: { value: _vm.currentUser.newPassword },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.currentUser,
                      "newPassword",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pf-row" },
              [
                _c("permissions", {
                  attrs: { inherited: [], disabled: true, "no-buttons": true },
                  model: {
                    value: _vm.currentUser.perms,
                    callback: function($$v) {
                      _vm.$set(_vm.currentUser, "perms", $$v)
                    },
                    expression: "currentUser.perms"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row buttons" }, [
            _c(
              "a",
              {
                staticClass: "pop-btn",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.$parent.currentUser = null
                  }
                }
              },
              [_vm._v("Close")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pf-field" }, [
      _c("label", [_vm._v("Permissions")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("ul", { staticClass: "cp-fields" }, [
      _c("li", [
        _c("span", [_vm._v("Partner Review Days")]),
        _vm._v(" "),
        _c("div", { staticClass: "val" }, [
          _vm._v("\n        " + _vm._s(_vm.days) + "\n      ")
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("span", [_vm._v("Days Remaining")]),
        _vm._v(" "),
        _c("div", { staticClass: "val" }, [
          _vm._v("\n        " + _vm._s(_vm.daysRemaining) + "\n      ")
        ])
      ]),
      _vm._v(" "),
      _c("li")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
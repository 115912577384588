var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.title,
          expression: "title"
        }
      ],
      staticClass: "form-popup",
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.dismiss($event)
        }
      }
    },
    [
      _c("div", [
        _c("div", { staticClass: "error-popup" }, [
          _c("h2", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } }),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.dismiss($event)
                }
              }
            },
            [_vm._v("Dismiss")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
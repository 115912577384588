var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "applied-filters" },
    [
      _vm._l(_vm.filters, function(filter) {
        return _c(
          "el-tag",
          {
            key: filter.key,
            staticClass: "applied-filter",
            attrs: {
              type: "primary",
              effect: "dark",
              size: "small",
              closable: ""
            },
            on: {
              close: function($event) {
                return _vm.$emit("remove", filter)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(filter.text) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _vm.filters.length > 1
        ? _c(
            "button",
            {
              staticClass: "dark",
              on: {
                click: function($event) {
                  return _vm.$emit("clear")
                }
              }
            },
            [
              _c("i", { staticClass: "el-icon-close" }),
              _vm._v(" Clear Filters\n  ")
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
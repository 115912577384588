var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { open: _vm.open },
      on: {
        "update:open": function($event) {
          _vm.open = $event
        }
      }
    },
    [
      _c("div", { staticClass: "popup-header" }, [
        _c("h3", [_vm._v("Remote Deposit")])
      ]),
      _vm._v(" "),
      [
        _vm.addModal
          ? [
              _c(
                "div",
                { staticClass: "scrollable" },
                [
                  _vm.system.transaction_deposits
                    ? [
                        _c("div", { staticClass: "content-form" }, [
                          _c(
                            "div",
                            { staticClass: "form-row form-split" },
                            [
                              _c("form-field-select-search", {
                                attrs: {
                                  label: "Account",
                                  validator: _vm.$v.form.account,
                                  options: _vm.accountOptions,
                                  "option-group": !_vm.isCustomer,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.account,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "account", $$v)
                                  },
                                  expression: "form.account"
                                }
                              }),
                              _vm._v(" "),
                              _c("form-field-select", {
                                attrs: {
                                  label: "Currency",
                                  validator: _vm.$v.form.currency,
                                  options: _vm.currencyOptions,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.currency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "currency", $$v)
                                  },
                                  expression: "form.currency"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-row form-split" },
                            [
                              _c("form-field-text", {
                                attrs: {
                                  label: "Amount",
                                  validator: _vm.$v.form.amount,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.amount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "amount", $$v)
                                  },
                                  expression: "form.amount"
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "sum" }, [
                                _c("div", [
                                  _vm._v("Fee: "),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm._f("money")(_vm.fee)))
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v("Total Deposit"),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm._f("money")(_vm.total)))
                                  ])
                                ])
                              ])
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "invalid-balance" }, [
                          _vm.invalidDepositFee
                            ? _c("div", { staticClass: "error-message" }, [
                                _c("span", [
                                  _vm._v(
                                    "The Amount must be greater than the Fee."
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "content-form",
                            staticStyle: { "padding-bottom": "0" }
                          },
                          [
                            _c("h3", { staticClass: "subtitle" }, [
                              _vm._v("SENDER INFORMATION")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-row form-fullw" },
                              [
                                _c("form-field-text", {
                                  attrs: {
                                    label: "Name Of Sender",
                                    validator: _vm.$v.form.nameSender,
                                    required: "",
                                    maxlength: 75
                                  },
                                  model: {
                                    value: _vm.form.nameSender,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "nameSender", $$v)
                                    },
                                    expression: "form.nameSender"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("remote-address-form", {
                          staticStyle: { "padding-top": "0" },
                          attrs: { v: _vm.$v.form },
                          model: {
                            value: _vm.form,
                            callback: function($$v) {
                              _vm.form = $$v
                            },
                            expression: "form"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "content-form" }, [
                          _c("h3", { staticClass: "subtitle" }, [
                            _vm._v("CHECK IMAGES")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-row form-split" },
                            [
                              _c("form-field-upload", {
                                attrs: {
                                  label: "Front Image",
                                  validator: _vm.$v.form.frontImage,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.frontImage,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "frontImage", $$v)
                                  },
                                  expression: "form.frontImage"
                                }
                              }),
                              _vm._v(" "),
                              _c("form-field-upload", {
                                attrs: {
                                  label: "Back Image",
                                  validator: _vm.$v.form.backImage,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.backImage,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "backImage", $$v)
                                  },
                                  expression: "form.backImage"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: {
                                "error-message":
                                  _vm.submitFrontError || _vm.submitBackError,
                                "file-text": true
                              }
                            },
                            [
                              _c("p", [
                                _vm._v("Only file types JPG, JPEG, PNG")
                              ]),
                              _vm._v(" "),
                              _c("p", [_vm._v("Maximum file size = 10MB")])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content-form" }, [
                          _c("h3", { staticClass: "subtitle" }, [
                            _vm._v("EXTRA INFORMATION")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-row form-fullw" },
                            [
                              _c("form-field-text", {
                                attrs: {
                                  label: "Memo",
                                  maxlength: 150,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.memo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "memo", $$v)
                                  },
                                  expression: "form.memo"
                                }
                              }),
                              _vm._v(" "),
                              _c("form-field-text", {
                                attrs: {
                                  label: "Reference",
                                  maxlength: 150,
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.reference,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "reference", $$v)
                                  },
                                  expression: "form.reference"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "main",
                                  on: {
                                    click: function($event) {
                                      return _vm.add()
                                    }
                                  }
                                },
                                [_vm._v("Submit")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "second",
                                  on: {
                                    click: function($event) {
                                      _vm.open = false
                                    }
                                  }
                                },
                                [_vm._v("Cancel")]
                              )
                            ],
                            _vm._v(" "),
                            _vm.submitError
                              ? _c("label", { staticClass: "error-message" }, [
                                  _vm._v("Please check error(s) and try again.")
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    : [
                        _c("div", { staticClass: "disabledText" }, [
                          _c("b", [
                            _vm._v(_vm._s(_vm.system.disabled_deposits_text))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "buttons" }, [
                          _c(
                            "button",
                            {
                              staticClass: "second",
                              on: {
                                click: function($event) {
                                  _vm.open = false
                                }
                              }
                            },
                            [_vm._v("Close")]
                          )
                        ])
                      ]
                ],
                2
              )
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.addConfirmModal
          ? [
              _vm.isDeposit
                ? _c("div", { staticClass: "scrollable" }, [
                    _vm.form.depositType == "Funds"
                      ? _c("div", { staticClass: "content-form" }, [
                          _c("div", { staticClass: "form-row" }, [
                            _vm.transaction
                              ? _c("p", { staticClass: "transaction-status" }, [
                                  _vm._v(
                                    "Status: " + _vm._s(_vm.transaction.status)
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          this.form.deposit_type != "ach"
                            ? _c("div", { staticClass: "form-row" }, [
                                _vm.transaction
                                  ? _c(
                                      "p",
                                      { staticClass: "transaction-status" },
                                      [
                                        _vm._v(
                                          "Reference: " +
                                            _vm._s(
                                              _vm.transaction
                                                .transactionReferenceCode
                                            ) +
                                            ", " +
                                            _vm._s(_vm.selectedAccount.name)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "information" },
                            [
                              _vm.transaction
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "transaction-status margin-10"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.depositOptionsText) +
                                          " Information"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              this.form.deposit_type == "domestic_wire" ||
                              this.form.deposit_type == "international_wires"
                                ? _c("p", { staticClass: "margin-10" }, [
                                    _vm._v(
                                      "Please see below for the details you will need to complete your payment process."
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              this.form.deposit_type == "checks"
                                ? [_c("p", [_vm._v("No Address Provided")])]
                                : this.form.deposit_type == "ach"
                                ? [
                                    _c("p", [
                                      _vm._v("ACH Deposit Added Successfully")
                                    ])
                                  ]
                                : [
                                    _vm.transaction &&
                                    _vm.transaction.instructions
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v("Depository Bank Name")
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.instructions[
                                                  "depository-bank-name"
                                                ]
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction &&
                                    _vm.transaction.instructions
                                      ? _c("p", [
                                          _c("b", [_vm._v("Bank Address")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.instructions[
                                                  "bank-address"
                                                ]
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction &&
                                    _vm.transaction.instructions
                                      ? _c("p", [
                                          _c("b", [_vm._v("Bank Phone")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.instructions[
                                                  "bank-phone"
                                                ]
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction &&
                                    _vm.transaction.instructions
                                      ? _c("p", [
                                          _c("b", [_vm._v("Credit To")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.instructions[
                                                  "credit-to"
                                                ]
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction &&
                                    _vm.transaction.instructions
                                      ? _c("p", [
                                          _c("b", [_vm._v("Address")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.instructions[
                                                  "beneficiary-address"
                                                ]
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction &&
                                    _vm.transaction.instructions
                                      ? _c("p", [
                                          _c("b", [_vm._v("Routing Number")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.instructions[
                                                  "routing-number"
                                                ]
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction &&
                                    _vm.transaction.instructions
                                      ? _c("p", [
                                          _c("b", [_vm._v("Account Number")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.instructions[
                                                  "account-number"
                                                ]
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction &&
                                    _vm.transaction.instructions
                                      ? _c("p", [
                                          _c("b", [_vm._v("SWIFT Code")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.instructions[
                                                  "swift-code"
                                                ]
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction &&
                                    _vm.transaction.instructions
                                      ? _c("p", [
                                          _c("b", [_vm._v("Reference")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction
                                                  .transactionReferenceCode
                                              ) +
                                              ", " +
                                              _vm._s(_vm.selectedAccount.name)
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                              _vm._v(" "),
                              this.form.deposit_type == "domestic_wire"
                                ? _c("p", { staticClass: "margin-10" }, [
                                    _c("b", [_vm._v("Note")]),
                                    _vm._v(
                                      ": Your domestic wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly."
                                    )
                                  ])
                                : this.form.deposit_type ==
                                  "international_wires"
                                ? _c("p", { staticClass: "margin-10" }, [
                                    _c("b", [_vm._v("Note")]),
                                    _vm._v(
                                      ": Your international wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly."
                                    )
                                  ])
                                : _c("p", { staticClass: "margin-10" }, [
                                    _c("b", [_vm._v("Note")]),
                                    _vm._v(
                                      ": Please follow any specific funding instructions to ensure the funds are received quickly.  If you have any doubts or questions please verify your instructions with your account representative."
                                    )
                                  ])
                            ],
                            2
                          )
                        ])
                      : _c("div", { staticClass: "content-form" }, [
                          _c("div", { staticClass: "form-row" }, [
                            _vm.transaction
                              ? _c("p", { staticClass: "transaction-status" }, [
                                  _vm._v(
                                    "Status: " + _vm._s(_vm.transaction.status)
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("div", { staticClass: "information" }, [
                            _vm.transaction
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "transaction-status margin-10"
                                  },
                                  [_vm._v("Information")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.transaction
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getCurrencyName(
                                        _vm.form.assetsCurrency
                                      )
                                    ) + " Wallet Address"
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.transaction
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transaction
                                        .contributionIncomingWalletAddress
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", { staticClass: "margin-10" }, [
                              _c("b", [_vm._v("Note")]),
                              _vm._v(
                                ": Send only " +
                                  _vm._s(
                                    _vm.getCurrencyName(_vm.form.assetsCurrency)
                                  ) +
                                  " to this wallet address. Any other asset type sent may be lost and unrecoverable."
                              )
                            ])
                          ])
                        ])
                  ])
                : _c("div", { staticClass: "scrollable" }, [
                    _vm.form.depositType == "Funds"
                      ? _c("div", { staticClass: "content-form" }, [
                          _c("div", { staticClass: "form-row" }, [
                            _vm.transaction
                              ? _c("p", { staticClass: "transaction-status" }, [
                                  _vm._v(
                                    "Status: " + _vm._s(_vm.transaction.status)
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "information" },
                            [
                              _vm.transaction
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "transaction-status margin-10"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.withdrawOptionsText) +
                                          " Withdrawal Information"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              this.form.deposit_type == "domestic_wire" ||
                              this.form.deposit_type == "international_wires"
                                ? _c("p", { staticClass: "margin-10" }, [
                                    _vm._v(
                                      "Please see below for the details submitted for your withdrawal."
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              this.form.deposit_type == "international_wires"
                                ? [
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Bank Name")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.bank_name)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Account Name")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(this.form.account_name)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Account Number")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(this.form.account_number)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Account Type")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(this.form.account_type)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Bank Swift Code")]),
                                          _vm._v(": " + _vm._s(this.form.swift))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v("Intermediary Bank Name")
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form.intermediary_bank_name
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v(
                                              "Intermediary Bank Reference"
                                            )
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form
                                                  .intermediary_bank_reference
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v(
                                              "Intermediary Bank Routing Number"
                                            )
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form
                                                  .intermediary_bank_routing_number
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v(
                                              "Intermediary Bank SWIFT Code"
                                            )
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form
                                                  .intermediary_bank_swift_code
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v("INTERMEDIARY BANK ADDRESS")
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Country")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form.intermediary_address
                                                  .country
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Province/State")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form.intermediary_address
                                                  .state
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Address")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form.intermediary_address
                                                  .address
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("City")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form.intermediary_address
                                                  .city
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Postal")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form.intermediary_address
                                                  .postal
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v("BENEFICIARY ADDRESS")
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("IBAN")]),
                                          _vm._v(": " + _vm._s(this.form.iban))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Country")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.country)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Province/State")]),
                                          _vm._v(": " + _vm._s(this.form.state))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Address")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.address)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("City")]),
                                          _vm._v(": " + _vm._s(this.form.city))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Postal")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.postal)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v("ADDITIONAL INFORMATION")
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v(
                                              "Further Credit Account Name"
                                            )
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form.futher_credit_acc_name
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v(
                                              "Further Credit Account Number"
                                            )
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form
                                                  .futher_credit_acc_number
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Reference")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.reference)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Memo")]),
                                          _vm._v(": " + _vm._s(this.form.memo))
                                        ])
                                      : _vm._e()
                                  ]
                                : this.form.deposit_type == "checks"
                                ? [
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Amount")]),
                                          _vm._v(
                                            ": $" +
                                              _vm._s(
                                                _vm._f("formattedTwoDecimal")(
                                                  this.form.amount
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Payee")]),
                                          _vm._v(": " + _vm._s(this.form.payee))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("PAYEE ADDRESS")])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Country")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.country)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Province/State")]),
                                          _vm._v(": " + _vm._s(this.form.state))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Address")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.address)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("City")]),
                                          _vm._v(": " + _vm._s(this.form.city))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Postal")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.postal)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Reference")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.reference)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Memo")]),
                                          _vm._v(": " + _vm._s(this.form.memo))
                                        ])
                                      : _vm._e()
                                  ]
                                : this.form.deposit_type == "ach"
                                ? [
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Amount")]),
                                          _vm._v(
                                            ": $" +
                                              _vm._s(
                                                _vm._f("formattedTwoDecimal")(
                                                  this.form.amount
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v("Name of Bank Account")
                                          ]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.bank_name)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Account Type")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(this.form.account_type)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Routing Number")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(this.form.routing_number)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v("Bank Account Number")
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(this.form.account_number)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v("Bank Account Type")
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form.bank_account_type
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Reference")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.reference)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Memo")]),
                                          _vm._v(": " + _vm._s(this.form.memo))
                                        ])
                                      : _vm._e()
                                  ]
                                : this.form.deposit_type == "domestic_wire"
                                ? [
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Account Name")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.accountName
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Routing Number")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.routingNumber
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Account Number")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.accountNumber
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Account Type")]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                _vm.transaction.accountType
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v("BENEFICIARY ADDRESS")
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Country")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.country)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Province/State")]),
                                          _vm._v(": " + _vm._s(this.form.state))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Address")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.address)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("City")]),
                                          _vm._v(": " + _vm._s(this.form.city))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Postal")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.postal)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v("ADDITIONAL INFORMATION")
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v(
                                              "Further Credit Account Name"
                                            )
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form.futher_credit_acc_name
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [
                                            _vm._v(
                                              "Further Credit Account Number"
                                            )
                                          ]),
                                          _vm._v(
                                            ": " +
                                              _vm._s(
                                                this.form
                                                  .futher_credit_acc_number
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Reference")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.reference)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Memo")]),
                                          _vm._v(": " + _vm._s(this.form.memo))
                                        ])
                                      : _vm._e()
                                  ]
                                : [
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Amount")]),
                                          _vm._v(
                                            ": $" +
                                              _vm._s(
                                                _vm._f("formattedTwoDecimal")(
                                                  this.form.amount
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Account Number")]),
                                          _vm._v(
                                            ":  ****" +
                                              _vm._s(
                                                this.form.a2a_account_number.substr(
                                                  4,
                                                  3
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Reference")]),
                                          _vm._v(
                                            ": " + _vm._s(this.form.reference)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.transaction
                                      ? _c("p", [
                                          _c("b", [_vm._v("Memo")]),
                                          _vm._v(": " + _vm._s(this.form.memo))
                                        ])
                                      : _vm._e()
                                  ],
                              _vm._v(" "),
                              _c("p", { staticClass: "margin-10" }, [
                                _c("b", [_vm._v("Note")]),
                                _vm._v(
                                  ": If you have any doubts or questions please verify your instructions with your account representative. "
                                )
                              ])
                            ],
                            2
                          )
                        ])
                      : _c("div", { staticClass: "content-form" }, [
                          _c("div", { staticClass: "form-row" }, [
                            _vm.transaction
                              ? _c("p", { staticClass: "transaction-status" }, [
                                  _vm._v(
                                    "Status: " + _vm._s(_vm.transaction.status)
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "information" }, [
                            _vm.transaction
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "transaction-status margin-10"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.withdrawOptionsText) +
                                        " Withdrawal Information"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.transaction
                              ? _c("p", [
                                  _c("b", [_vm._v("Amount")]),
                                  _vm._v(
                                    ": " +
                                      _vm._s(
                                        _vm._f("assets_balance")(
                                          _vm.transaction.totalAssetAmount
                                        )
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.transaction
                              ? _c("p", [
                                  _c("b", [_vm._v("Account Number")]),
                                  _vm._v(
                                    ": ****" +
                                      _vm._s(
                                        _vm.transaction.toAccountId
                                          ? _vm.transaction.toAccountId.substr(
                                              4,
                                              3
                                            )
                                          : ""
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.transaction
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transaction.outgoingWalletAddress
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.transaction
                              ? _c("p", [
                                  _c("b", [_vm._v("Reference")]),
                                  _vm._v(
                                    ": " + _vm._s(_vm.transaction.reference)
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.transaction
                              ? _c("p", [
                                  _c("b", [_vm._v("Memo")]),
                                  _vm._v(": " + _vm._s(_vm.transaction.memo))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", { staticClass: "margin-10" }, [
                              _c("b", [_vm._v("Note")]),
                              _vm._v(
                                ": Send only " +
                                  _vm._s(
                                    _vm.getCurrencyName(_vm.form.assetsCurrency)
                                  ) +
                                  " to this wallet address. Any other asset type sent may be lost and unrecoverable."
                              )
                            ])
                          ])
                        ])
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        _vm.open = false
                      }
                    }
                  },
                  [_vm._v("Close")]
                )
              ])
            ]
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-issue" },
    [
      _c("div", { staticClass: "flex" }, [
        _c("p"),
        _vm._v(" "),
        _c("p", [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  _vm.open = true
                }
              }
            },
            [_vm._v("Report Issue")]
          )
        ]),
        _vm._v(" "),
        _c("p")
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.open },
          on: {
            "update:open": function($event) {
              _vm.open = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Report Issue")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "scrollable" },
            [
              _vm.success
                ? [
                    _c("div", { staticClass: "content-form" }, [
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw text-center" },
                        [
                          _c("div", { staticClass: "text-center success" }, [
                            _c("p", [_vm._v("Issue report has been sent.")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("We will contact you back shortly.")
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Thank you!")])
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "buttons text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              return _vm.cancel()
                            }
                          }
                        },
                        [_vm._v("Close")]
                      )
                    ])
                  ]
                : [
                    _c("div", { staticClass: "content-form" }, [
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw" },
                        [
                          _c("form-field-text", {
                            attrs: {
                              label: "Name",
                              validator: _vm.$v.form.name,
                              required: ""
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw" },
                        [
                          _c("form-field-text", {
                            attrs: {
                              label: "Email Address",
                              validator: _vm.$v.form.emailAddress,
                              required: ""
                            },
                            model: {
                              value: _vm.form.emailAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "emailAddress", $$v)
                              },
                              expression: "form.emailAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw" },
                        [
                          _c("form-field-text", {
                            attrs: {
                              label: "Account Number",
                              validator: _vm.$v.form.accountNumber
                            },
                            model: {
                              value: _vm.form.accountNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "accountNumber", $$v)
                              },
                              expression: "form.accountNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-row form-fullw" },
                        [
                          _c("form-field-upload", {
                            attrs: { label: "File Attachment" },
                            model: {
                              value: _vm.form.file,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "file", $$v)
                              },
                              expression: "form.file"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row form-fullw" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.issueDescription,
                              expression: "form.issueDescription"
                            }
                          ],
                          class: {
                            error: _vm.$v.form.issueDescription.$invalid
                          },
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "Please describe your issue *",
                            rows: "5"
                          },
                          domProps: { value: _vm.form.issueDescription },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "issueDescription",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "buttons" }, [
                      _c(
                        "button",
                        {
                          staticClass: "main",
                          on: {
                            click: function($event) {
                              return _vm.submit()
                            }
                          }
                        },
                        [_vm._v("Submit")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "second",
                          on: {
                            click: function($event) {
                              return _vm.cancel()
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ])
                  ]
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { open: _vm.open },
      on: {
        "update:open": function($event) {
          _vm.open = $event
        }
      }
    },
    [
      _c("div", { staticClass: "popup-header" }, [
        _c("h3", [_vm._v("Deposit Payment Details")]),
        _vm._v(" "),
        _vm.selectedAccount
          ? _c("span", [
              _vm._v("Account: " + _vm._s(_vm.selectedAccount.accountNumber))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.nextFlag
        ? [
            _c("div", { staticClass: "scrollable" }, [
              _c("h1", { staticClass: "text-center" }, [
                _vm._v("Choose the Deposit Type")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content-form text-center" }, [
                _c(
                  "div",
                  { staticClass: "form-row form-split depositType" },
                  [
                    _c("form-field-select", {
                      attrs: {
                        label: "Deposit Type",
                        validator: _vm.$v.form.depositType,
                        options: _vm.depositTypeOptions,
                        required: ""
                      },
                      model: {
                        value: _vm.form.depositType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "depositType", $$v)
                        },
                        expression: "form.depositType"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "main",
                    on: {
                      click: function($event) {
                        return _vm.moveNextPopup()
                      }
                    }
                  },
                  [_vm._v("Next")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        _vm.open = false
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ])
          ]
        : [
            _vm.form.depositType == "Funds"
              ? _c("div", { staticClass: "scrollable" }, [
                  _c("div", { staticClass: "content-form" }, [
                    _c(
                      "div",
                      { staticClass: "form-row form-split" },
                      [
                        _c("form-field-select-search", {
                          attrs: {
                            label: "Account",
                            validator: _vm.$v.form.account,
                            options: _vm.accountOptions,
                            "option-group": !_vm.isCustomer,
                            required: ""
                          },
                          model: {
                            value: _vm.form.account,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "account", $$v)
                            },
                            expression: "form.account"
                          }
                        }),
                        _vm._v(" "),
                        _c("form-field-select", {
                          attrs: {
                            label: "Currency",
                            validator: _vm.$v.form.currency,
                            options: _vm.currencyOptions,
                            required: ""
                          },
                          model: {
                            value: _vm.form.currency,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "currency", $$v)
                            },
                            expression: "form.currency"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-row form-split" },
                      [
                        _c("form-field-select", {
                          attrs: {
                            disabled: !_vm.selectedAccount,
                            label: "Deposit Type",
                            validator: _vm.$v.form.deposit_type,
                            options: _vm.depositOptions,
                            required: ""
                          },
                          model: {
                            value: _vm.form.deposit_type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "deposit_type", $$v)
                            },
                            expression: "form.deposit_type"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row payment-reference" }, [
                    _vm.selectedAccount && _vm.selectedAccount.primeAccount[0]
                      ? _c(
                          "label",
                          [
                            _vm._v("Deposit Payment Reference: "),
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedAccount.primeAccount[0]
                                    .QPaymentReference
                                )
                              )
                            ]
                          ],
                          2
                        )
                      : _vm.infos && _vm.infos["provider"] == "Think Green"
                      ? _c("label", [
                          _vm._v(
                            "\n          Deposit Payment Reference: " +
                              _vm._s(_vm.infos["reference"]) +
                              "\n        "
                          )
                        ])
                      : _c("label", [_vm._v("\n           \n        ")]),
                    _vm._v(" "),
                    !_vm.infos
                      ? _c(
                          "button",
                          {
                            staticClass: "main",
                            on: {
                              click: function($event) {
                                return _vm.paymentDetails()
                              }
                            }
                          },
                          [_vm._v("Submit")]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content-form bank-info" },
                    [
                      _c(
                        "h3",
                        { staticClass: "subtitle" },
                        [
                          _vm._v("Deposit Details"),
                          _vm.selectedAccount
                            ? [
                                _vm._v(
                                  " - " +
                                    _vm._s(_vm.selectedAccount.label) +
                                    " Account"
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.infos
                        ? [
                            _vm.infos["provider"] == "Solid"
                              ? [
                                  _vm.form.deposit_type == "domestic_wire"
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Name: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["accountName"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["accountNumber"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Routing Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["routingNumber"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Address: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.infos["address"][
                                                      "street-1"
                                                    ]
                                                  ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.infos["address"][
                                                        "street-2"
                                                      ]
                                                    )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.infos["address"]["city"]
                                                  ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.infos["address"][
                                                        "region"
                                                      ]
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.infos["address"][
                                                        "postal-code"
                                                      ]
                                                    )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.infos["address"][
                                                      "country"
                                                    ]
                                                  )
                                                )
                                              ])
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Bank Name: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bankName"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Bank Address: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bankAddress"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("b", [_vm._v("Note: ")]),
                                              _vm._v(
                                                "Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                "
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.form.deposit_type == "international_wires"
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Name: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["accountName"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["accountNumber"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Routing Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["routingNumber"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Type: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["accountType"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Address: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.infos["address"][
                                                      "street-1"
                                                    ]
                                                  ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.infos["address"][
                                                        "street-2"
                                                      ]
                                                    )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.infos["address"]["city"]
                                                  ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.infos["address"][
                                                        "region"
                                                      ]
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.infos["address"][
                                                        "postal-code"
                                                      ]
                                                    )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.infos["address"][
                                                      "country"
                                                    ]
                                                  )
                                                )
                                              ])
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Bank Name: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bankName"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Bank Address: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  6070 Poplar Ave, Suite 200, Memphis, TN 38119\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c("p", [
                                            _vm._v(
                                              "Correspondent/Intermediary Bank SWIFT: FRNAUS44"
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c("p", [
                                            _vm._v(
                                              "Correspondent/Intermediary Bank Name: First National Banker's Bank"
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c("p", [
                                            _vm._v(
                                              "Correspondent/Intermediary Bank Address: 7813 Office Park Blvd Baton Rouge, LA 70809"
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Special instructions: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  FBO Solid at Evolve, FFC " +
                                                  _vm._s(
                                                    _vm.infos["accountName"]
                                                  ) +
                                                  "," +
                                                  _vm._s(
                                                    _vm.infos["accountNumber"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("b", [_vm._v("Note: ")]),
                                              _vm._v(
                                                "Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                "
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.form.deposit_type == "ach"
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["accountNumber"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Routing Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["routingNumber"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Bank Name: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bankName"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Type: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["accountType"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Name: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["accountName"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("b", [_vm._v("Note: ")]),
                                              _vm._v(
                                                "Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                "
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ]
                              : _vm.infos["provider"] == "Think Green"
                              ? [
                                  _vm.form.deposit_type == "domestic_wire" ||
                                  _vm.form.deposit_type == "international_wires"
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Bank Name: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bankName"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Bank Routing Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["routingNumber"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Bank SWIFT Code: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["swiftCode"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Credit To: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["creditTo"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Address: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.infos["address"]) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["accountNumber"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Reference Code: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["reference"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Bank Address: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bankAddress"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Bank Phone: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bank-phone"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("b", [_vm._v("Note: ")]),
                                              _vm._v("Your account number "),
                                              _c("b", [_vm._v("MUST")]),
                                              _vm._v(
                                                " be included in the reference field on all deposit transactions. ACH deposits are not accepted. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                "
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.form.deposit_type == "ach"
                                    ? [
                                        _vm._v(
                                          "\n              ACH deposit details are unavailable at this time.\n            "
                                        )
                                      ]
                                    : _vm._e()
                                ]
                              : [
                                  _vm.form.deposit_type == "domestic_wire"
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Bank Name: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos[
                                                      "depository-bank-name"
                                                    ]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Bank Routing Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["routing-number"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Credit To: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["credit-to"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Address: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos[
                                                      "beneficiary-address"
                                                    ]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["account-number"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Reference Code: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["reference"]
                                                  ) +
                                                  ", " +
                                                  _vm._s(
                                                    _vm.selectedAccount.reg_name
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Bank Address: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bank-address"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Bank Phone: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bank-phone"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("b", [_vm._v("Note: ")]),
                                              _vm._v(
                                                "Your Q-Code and account name "
                                              ),
                                              _c("b", [_vm._v("MUST")]),
                                              _vm._v(
                                                " be included on all deposit transactions. ACH deposits are not accepted. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                "
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.form.deposit_type == "international_wires"
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Bank Name: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos[
                                                      "depository-bank-name"
                                                    ]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Bank Routing Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["routing-number"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Bank SWIFT Code: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["swift-code"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Credit To: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["credit-to"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Address: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos[
                                                      "beneficiary-address"
                                                    ]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Account Number: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["account-number"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Reference Code: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["reference"]
                                                  ) +
                                                  ", " +
                                                  _vm._s(
                                                    _vm.selectedAccount.reg_name
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Bank Address: ")
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bank-address"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("label", [
                                              _c("b", [_vm._v("Bank Phone: ")])
                                            ]),
                                            _vm._v(" "),
                                            _c("label", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.infos["bank-phone"]
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("b", [_vm._v("Note: ")]),
                                              _vm._v(
                                                "Your Q-Code and account name "
                                              ),
                                              _c("b", [_vm._v("MUST")]),
                                              _vm._v(
                                                " be included on all deposit transactions. ACH deposits are not accepted. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.\n                "
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.form.deposit_type == "ach"
                                    ? [
                                        _vm._v(
                                          "\n              ACH deposit details are unavailable at this time.\n            "
                                        )
                                      ]
                                    : _vm._e()
                                ]
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _vm.infos && _vm.form.deposit_type == "domestic_wire"
                      ? _c(
                          "a",
                          {
                            staticClass: "btn main",
                            attrs: {
                              href:
                                "/files/paymentDetails/" +
                                _vm.form.account +
                                "/" +
                                _vm.form.account +
                                "-payment_details_domestic.pdf",
                              target: "_blank"
                            }
                          },
                          [_vm._v("View")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.infos && _vm.form.deposit_type == "international_wires"
                      ? _c(
                          "a",
                          {
                            staticClass: "btn main",
                            attrs: {
                              href:
                                "/files/paymentDetails/" +
                                _vm.form.account +
                                "/" +
                                _vm.form.account +
                                "-payment_details_international.pdf",
                              target: "_blank"
                            }
                          },
                          [_vm._v("View")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.infos &&
                    _vm.form.deposit_type == "ach" &&
                    _vm.infos["provider"] == "Solid"
                      ? _c(
                          "a",
                          {
                            staticClass: "btn main",
                            attrs: {
                              href:
                                "/files/paymentDetails/" +
                                _vm.form.account +
                                "/" +
                                _vm.form.account +
                                "-payment_details_ach.pdf",
                              target: "_blank"
                            }
                          },
                          [_vm._v("View")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "second",
                        on: {
                          click: function($event) {
                            _vm.open = false
                          }
                        }
                      },
                      [_vm._v("Close")]
                    )
                  ])
                ])
              : _c("div", { staticClass: "scrollable" }, [
                  _c("div", { staticClass: "content-form" }, [
                    _c(
                      "div",
                      { staticClass: "form-row form-split" },
                      [
                        _c("form-field-select-search", {
                          attrs: {
                            label: "Account",
                            validator: _vm.$v.form.account,
                            options: _vm.accountOptions,
                            "option-group": !_vm.isCustomer,
                            required: ""
                          },
                          model: {
                            value: _vm.form.account,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "account", $$v)
                            },
                            expression: "form.account"
                          }
                        }),
                        _vm._v(" "),
                        _c("form-field-select", {
                          attrs: {
                            label: "Assets Type",
                            validator: _vm.$v.form.assetsCurrency,
                            options: _vm.assetsCurrencyOptions,
                            required: ""
                          },
                          model: {
                            value: _vm.form.assetsCurrency,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "assetsCurrency", $$v)
                            },
                            expression: "form.assetsCurrency"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row payment-reference" }, [
                    _c("label"),
                    _vm._v(" "),
                    !_vm.infos
                      ? _c(
                          "button",
                          {
                            staticClass: "main",
                            on: {
                              click: function($event) {
                                return _vm.assetPaymentDetails()
                              }
                            }
                          },
                          [_vm._v("Submit")]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content-form" },
                    [
                      _vm.infos
                        ? _c(
                            "h3",
                            { staticClass: "subtitle" },
                            [
                              _vm._v("Asset Deposit Details"),
                              _vm.selectedAccount
                                ? [
                                    _vm._v(
                                      " - " +
                                        _vm._s(_vm.selectedAccount.label) +
                                        " Account"
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.infos
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "form-row form-split depositType"
                              },
                              [
                                _c("label", [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.infos.currency) +
                                        " Wallet Address "
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-row form-split depositType"
                              },
                              [
                                _c("label", { staticClass: "wallet-address" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.infos.address) +
                                      "\n            "
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-row form-split depositType"
                              },
                              [
                                _c("label", [
                                  _c("b", [_vm._v(" Destination Tag ")])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-row form-split depositType"
                              },
                              [
                                _c("label", { staticClass: "wallet-address" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.infos.tag) +
                                      "\n            "
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-row form-split" }, [
                              _c("label", {}, [
                                _c("b", [_vm._v("Note:")]),
                                _vm._v(
                                  " Send only " +
                                    _vm._s(_vm.infos.currency) +
                                    " to this wallet address. Any other asset type sent may be lost and unrecoverable.\n            "
                                )
                              ])
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _vm.infos
                      ? _c(
                          "a",
                          {
                            staticClass: "btn main",
                            attrs: {
                              href:
                                "/files/paymentDetails/" +
                                _vm.form.account +
                                "/" +
                                _vm.form.account +
                                "-asset_payment_details.pdf",
                              target: "_blank"
                            }
                          },
                          [_vm._v("View")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "second",
                        on: {
                          click: function($event) {
                            _vm.open = false
                          }
                        }
                      },
                      [_vm._v("Close")]
                    )
                  ])
                ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
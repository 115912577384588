var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user && !_vm.user.isPartner
    ? _c(
        "section",
        [
          _vm.featureAdvanceDepositNoticeStatus == null
            ? void 0
            : [
                _vm.$hasPerm("new-transaction")
                  ? [
                      _vm.showDeposit && _vm.featureAdvanceDepositNoticeStatus
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              on: {
                                click: function($event) {
                                  return _vm.open("deposit")
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "el-icon-plus" }),
                              _vm._v(" Deposit")
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.featureRemoteDeposit
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              on: {
                                click: function($event) {
                                  return _vm.openRemoteDeposit()
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "el-icon-plus" }),
                              _vm._v(" Remote Deposit")
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showWithdrawal && _vm.featureWithdrawal
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              on: {
                                click: function($event) {
                                  return _vm.open("withdraw")
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "el-icon-minus" }),
                              _vm._v(" Withdrawal")
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          on: {
                            click: function($event) {
                              return _vm.openAccountLink()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-link" }),
                          _vm._v(" Account Link")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          on: {
                            click: function($event) {
                              return _vm.openPayment()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-document" }),
                          _vm._v(" Deposit Details")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          on: {
                            click: function($event) {
                              return _vm.openBank()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-office-building" }),
                          _vm._v(" Bank Look Up")
                        ]
                      )
                    ]
                  : _vm._e()
              ],
          _vm._v(" "),
          _vm.modalType
            ? _c("add-deposit-withdrawal-panel", {
                attrs: { "modal-type": _vm.modalType },
                on: {
                  close: function($event) {
                    _vm.modalType = null
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modalPaymentType
            ? _c("payment-details-panel", {
                attrs: { "modal-payment-type": _vm.modalPaymentType },
                on: {
                  close: function($event) {
                    _vm.modalPaymentType = null
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modalAccountLinkType
            ? _c("account-link-panel", {
                attrs: { "modal-account-link-type": _vm.modalAccountLinkType },
                on: {
                  close: function($event) {
                    _vm.modalAccountLinkType = null
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modalBankType
            ? _c("bank-lookup-panel", {
                attrs: { "modal-bank-type": _vm.modalBankType },
                on: {
                  close: function($event) {
                    _vm.modalBankType = null
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modalDepositType
            ? _c("add-remote-deposit-modal", {
                attrs: { "modal-type": _vm.modalDepositType },
                on: {
                  close: function($event) {
                    _vm.modalDepositType = null
                  }
                }
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("h2", [_vm._v("Account Settings")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "status" },
          [
            _vm._v("\n        Status\n        "),
            _c("status-icon", {
              attrs: { color: _vm.profileSettings ? "green" : null }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "content-table", staticStyle: { "min-width": "unset" } },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("tr", { staticClass: "content-filters" }, [
            _c(
              "td",
              [
                _c("span", [_vm._v("Services")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? _c("mnb-select", {
                      staticClass: "select",
                      attrs: {
                        options: _vm.accountOptions,
                        validator: _vm.$v.form.account
                      },
                      model: {
                        value: _vm.form.account,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "account", $$v)
                        },
                        expression: "form.account"
                      }
                    })
                  : [_vm._v(_vm._s(_vm.mainAccount.accountNumber))]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("ACH")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? [
                      _vm.provider.ach == 1
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.ach,
                              disabled: _vm.customEnv.noach || !_vm.form.account
                            },
                            model: {
                              value: _vm.form.ach,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "ach", $$v)
                              },
                              expression: "form.ach"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: { value: false, disabled: "" }
                          })
                    ]
                  : _c("mnb-switch", {
                      attrs: { value: _vm.form.ach == 1, disabled: "" }
                    })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Account to Account")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? [
                      _vm.provider.acct == 1
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.acc_to_acc,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.acc_to_acc,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "acc_to_acc", $$v)
                              },
                              expression: "form.acc_to_acc"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: { value: false, disabled: "" }
                          })
                    ]
                  : _c("mnb-switch", {
                      attrs: { value: _vm.form.acc_to_acc == 1, disabled: "" }
                    })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Checks")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? [
                      _vm.provider.checks == 1
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.checks,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.checks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "checks", $$v)
                              },
                              expression: "form.checks"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: { value: false, disabled: "" }
                          })
                    ]
                  : _c("mnb-switch", {
                      attrs: { value: _vm.form.checks == 1, disabled: "" }
                    })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Remote Deposit")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? [
                      _vm.provider.remoteDeposit == 1
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.remoteDeposit,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.remoteDeposit,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remoteDeposit", $$v)
                              },
                              expression: "form.remoteDeposit"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: { value: false, disabled: "" }
                          })
                    ]
                  : _c("mnb-switch", {
                      attrs: {
                        value: _vm.form.remoteDeposit == 1,
                        disabled: ""
                      }
                    })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Wire Transfer")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? [
                      _vm.provider.domWires == 1
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.domWires,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.domWires,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "domWires", $$v)
                              },
                              expression: "form.domWires"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: { value: false, disabled: "" }
                          })
                    ]
                  : _c("mnb-switch", {
                      attrs: { value: _vm.form.domWires == 1, disabled: "" }
                    })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Wire Transfer")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? [
                      _vm.provider.intlWires == 1
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.intlWires,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.intlWires,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "intlWires", $$v)
                              },
                              expression: "form.intlWires"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: { value: false, disabled: "" }
                          })
                    ]
                  : _c("mnb-switch", {
                      attrs: { value: _vm.form.intlWires == 1, disabled: "" }
                    })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Assets")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? [
                      _vm.featureAssetsStatus && _vm.provider.assets == 1
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.assets,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.assets,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "assets", $$v)
                              },
                              expression: "form.assets"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: { value: false, disabled: "" }
                          })
                    ]
                  : _c("mnb-switch", {
                      attrs: { value: _vm.form.assets == 1, disabled: "" }
                    })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "content-filters" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("ACH")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? _c(
                      "div",
                      { staticClass: "money-input" },
                      [
                        _c("span", [_vm._v("$")]),
                        _vm._v(" "),
                        !_vm.profileSettings
                          ? _c("mnb-input", {
                              staticClass: "input",
                              attrs: {
                                label: "Limit",
                                "no-style": "",
                                disabled:
                                  _vm.customEnv.noach ||
                                  _vm.provider.ach != 1 ||
                                  !_vm.form.account
                              },
                              model: {
                                value: _vm.form.ach_limit,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "ach_limit", $$v)
                                },
                                expression: "form.ach_limit"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : [_vm._v("$ " + _vm._s(_vm.form.ach_limit))]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Account to Account")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? _c(
                      "div",
                      { staticClass: "money-input" },
                      [
                        _c("span", [_vm._v("$")]),
                        _vm._v(" "),
                        !_vm.profileSettings
                          ? _c("mnb-input", {
                              staticClass: "input",
                              attrs: {
                                label: "Limit",
                                "no-style": "",
                                disabled:
                                  _vm.provider.acct != 1 || !_vm.form.account
                              },
                              model: {
                                value: _vm.form.acc_to_acc_limit,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "acc_to_acc_limit", $$v)
                                },
                                expression: "form.acc_to_acc_limit"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : [_vm._v("$ " + _vm._s(_vm.form.acc_to_acc_limit))]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Checks")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? _c(
                      "div",
                      { staticClass: "money-input" },
                      [
                        _c("span", [_vm._v("$")]),
                        _vm._v(" "),
                        !_vm.profileSettings
                          ? _c("mnb-input", {
                              staticClass: "input",
                              attrs: {
                                label: "Limit",
                                "no-style": "",
                                disabled:
                                  _vm.provider.checks != 1 || !_vm.form.account
                              },
                              model: {
                                value: _vm.form.checks_limit,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "checks_limit", $$v)
                                },
                                expression: "form.checks_limit"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : [_vm._v("$ " + _vm._s(_vm.form.checks_limit))]
              ],
              2
            ),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Wire Transfer")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? _c(
                      "div",
                      { staticClass: "money-input" },
                      [
                        _c("span", [_vm._v("$")]),
                        _vm._v(" "),
                        !_vm.profileSettings
                          ? _c("mnb-input", {
                              staticClass: "input",
                              attrs: {
                                label: "Limit",
                                "no-style": "",
                                disabled:
                                  _vm.provider.domWires != 1 ||
                                  !_vm.form.account
                              },
                              model: {
                                value: _vm.form.domWires_limit,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "domWires_limit", $$v)
                                },
                                expression: "form.domWires_limit"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : [_vm._v("$ " + _vm._s(_vm.form.domWires_limit))]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Wire Transfer")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? _c(
                      "div",
                      { staticClass: "money-input" },
                      [
                        _c("span", [_vm._v("$")]),
                        _vm._v(" "),
                        !_vm.profileSettings
                          ? _c("mnb-input", {
                              staticClass: "input",
                              attrs: {
                                label: "Limit",
                                "no-style": "",
                                disabled:
                                  _vm.provider.intlWires != 1 ||
                                  !_vm.form.account
                              },
                              model: {
                                value: _vm.form.intlWires_limit,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "intlWires_limit", $$v)
                                },
                                expression: "form.intlWires_limit"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : [_vm._v("$ " + _vm._s(_vm.form.intlWires_limit))]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Assets")]),
                _vm._v(" "),
                !_vm.featureAssetsStatus
                  ? [
                      !_vm.profileSettings
                        ? _c(
                            "div",
                            { staticClass: "money-input" },
                            [
                              _c("span"),
                              _vm._v(" "),
                              !_vm.profileSettings
                                ? _c("mnb-input", {
                                    staticClass: "input",
                                    attrs: {
                                      label: "Limit",
                                      "no-style": "",
                                      disabled: true
                                    },
                                    model: {
                                      value: _vm.form.assets_limit,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "assets_limit", $$v)
                                      },
                                      expression: "form.assets_limit"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : [_vm._v(_vm._s(_vm.form.assets_limit))]
                    ]
                  : [
                      !_vm.profileSettings
                        ? _c(
                            "div",
                            { staticClass: "money-input" },
                            [
                              _c("span"),
                              _vm._v(" "),
                              !_vm.profileSettings
                                ? _c("mnb-input", {
                                    staticClass: "input",
                                    attrs: {
                                      label: "Limit",
                                      "no-style": "",
                                      disabled:
                                        _vm.provider.assets != 1 ||
                                        !_vm.form.account
                                    },
                                    model: {
                                      value: _vm.form.assets_limit,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "assets_limit", $$v)
                                      },
                                      expression: "form.assets_limit"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : [_vm._v(_vm._s(_vm.form.assets_limit))]
                    ]
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("table", { staticClass: "content-table" }, [
        _vm._m(3),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("Enable")]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("span", [_vm._v("Multi-currency")]),
              _vm._v(" "),
              !_vm.profileSettings
                ? [
                    _vm.featureFundsStatus && _vm.provider.multiCurrency == 1
                      ? _c("mnb-switch", {
                          attrs: {
                            validator: _vm.$v.form.multiCurrency,
                            disabled: !_vm.form.account
                          },
                          model: {
                            value: _vm.form.multiCurrency,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "multiCurrency", $$v)
                            },
                            expression: "form.multiCurrency"
                          }
                        })
                      : _c("mnb-switch", {
                          attrs: { value: false, disabled: "" }
                        })
                  ]
                : _c("mnb-switch", {
                    attrs: { value: _vm.form.multiCurrency == 1, disabled: "" }
                  })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("span", [_vm._v("Debit Cards")]),
              _vm._v(" "),
              !_vm.profileSettings
                ? [
                    _vm.provider.debitCards == 1
                      ? _c("mnb-switch", {
                          attrs: {
                            validator: _vm.$v.form.debitCards,
                            disabled: !_vm.form.account
                          },
                          model: {
                            value: _vm.form.debitCards,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "debitCards", $$v)
                            },
                            expression: "form.debitCards"
                          }
                        })
                      : _c("mnb-switch", {
                          attrs: { value: false, disabled: "" }
                        })
                  ]
                : _c("mnb-switch", {
                    attrs: { value: _vm.form.debitCards == 1, disabled: "" }
                  })
            ],
            2
          ),
          _vm._v(" "),
          _c("td", { attrs: { colspan: "5" } })
        ]),
        _vm._v(" "),
        _c("tr", { staticClass: "content-filters" }, [
          _c("td", [_vm._v("Transaction Limits")]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("span", [_vm._v("Multi Currency")]),
              _vm._v(" "),
              !_vm.featureFundsStatus
                ? [
                    !_vm.profileSettings
                      ? _c(
                          "div",
                          { staticClass: "money-input" },
                          [
                            _c("span", [_vm._v("$")]),
                            _vm._v(" "),
                            !_vm.profileSettings
                              ? _c("mnb-input", {
                                  staticClass: "input",
                                  attrs: {
                                    label: "Limit",
                                    "no-style": "",
                                    disabled: true
                                  },
                                  model: {
                                    value: _vm.form.multi_currency_limit,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "multi_currency_limit",
                                        $$v
                                      )
                                    },
                                    expression: "form.multi_currency_limit"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : [_vm._v("$ " + _vm._s(_vm.form.multi_currency_limit))]
                  ]
                : [
                    !_vm.profileSettings
                      ? _c(
                          "div",
                          { staticClass: "money-input" },
                          [
                            _c("span", [_vm._v("$")]),
                            _vm._v(" "),
                            !_vm.profileSettings
                              ? _c("mnb-input", {
                                  staticClass: "input",
                                  attrs: {
                                    label: "Limit",
                                    "no-style": "",
                                    disabled:
                                      _vm.provider.multiCurrency != 1 ||
                                      !_vm.form.account
                                  },
                                  model: {
                                    value: _vm.form.multi_currency_limit,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "multi_currency_limit",
                                        $$v
                                      )
                                    },
                                    expression: "form.multi_currency_limit"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : [_vm._v("$ " + _vm._s(_vm.form.multi_currency_limit))]
                  ]
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("span", [_vm._v("Debit Cards")]),
              _vm._v(" "),
              !_vm.profileSettings
                ? _c(
                    "div",
                    { staticClass: "money-input" },
                    [
                      _c("span", [_vm._v("$")]),
                      _vm._v(" "),
                      !_vm.profileSettings
                        ? _c("mnb-input", {
                            staticClass: "input",
                            attrs: {
                              label: "Limit",
                              "no-style": "",
                              disabled:
                                _vm.provider.debitCards != 1 ||
                                !_vm.form.account
                            },
                            model: {
                              value: _vm.form.debit_cards_limit,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "debit_cards_limit", $$v)
                              },
                              expression: "form.debit_cards_limit"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : [_vm._v("$ " + _vm._s(_vm.form.debit_cards_limit))]
            ],
            2
          ),
          _vm._v(" "),
          _c("td", { attrs: { colspan: "5" } })
        ])
      ]),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "content-table" },
        [
          _vm._m(4),
          _vm._v(" "),
          _c("tr", { staticClass: "content-filters" }, [
            _vm._m(5),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Exchange")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? _c("mnb-switch", { attrs: { value: false, disabled: "" } })
                  : _c("mnb-switch", {
                      attrs: { value: _vm.form.exchange == 1, disabled: "" }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Trade Desk")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? [
                      _vm.form.assets
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.trade_desk,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.trade_desk,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "trade_desk", $$v)
                              },
                              expression: "form.trade_desk"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: { value: false, disabled: "" }
                          })
                    ]
                  : _c("mnb-switch", {
                      attrs: { value: _vm.form.trade_desk == 1, disabled: "" }
                    })
              ],
              2
            ),
            _vm._v(" "),
            _c("td", { attrs: { colspan: "7" } })
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "content-filters" }, [
            _vm._m(6),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Exchange")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? _c(
                      "div",
                      { staticClass: "money-input" },
                      [
                        _c("span", [_vm._v("$")]),
                        _vm._v(" "),
                        !_vm.profileSettings
                          ? _c("mnb-input", {
                              staticClass: "input",
                              attrs: {
                                label: "Limit",
                                "no-style": "",
                                disabled:
                                  !_vm.form.multiCurrency || !_vm.form.account
                              },
                              model: {
                                value: _vm.form.exchange_limit,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "exchange_limit", $$v)
                                },
                                expression: "form.exchange_limit"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : [_vm._v("$ " + _vm._s(_vm.form.exchange_limit))]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("span", [_vm._v("Trade Desk")]),
                _vm._v(" "),
                !_vm.profileSettings
                  ? _c(
                      "div",
                      { staticClass: "money-input" },
                      [
                        _c("span"),
                        _vm._v(" "),
                        !_vm.profileSettings
                          ? _c("mnb-input", {
                              staticClass: "input",
                              attrs: {
                                label: "Limit",
                                "no-style": "",
                                disabled: !_vm.form.assets || !_vm.form.account
                              },
                              model: {
                                value: _vm.form.trade_desk_limit,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "trade_desk_limit", $$v)
                                },
                                expression: "form.trade_desk_limit"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : [_vm._v(_vm._s(_vm.form.trade_desk_limit))]
              ],
              2
            ),
            _vm._v(" "),
            _c("td", { attrs: { colspan: "7" } })
          ]),
          _vm._v(" "),
          this.registration.accounts.length == 1
            ? [
                _vm._m(7),
                _vm._v(" "),
                _c("tr", [
                  _c("th", { staticStyle: { width: "15%" } }, [
                    _vm._v("Verifications")
                  ]),
                  _vm._v(" "),
                  _c("th", { staticStyle: { width: "12%" } }, [
                    _vm._v("Administrative")
                  ]),
                  _vm._v(" "),
                  _c("th", { staticStyle: { width: "12%" } }, [
                    _vm._v("Remote Deposit Capture")
                  ]),
                  _vm._v(" "),
                  !_vm.isPersonal
                    ? _c("th", { staticStyle: { width: "12%" } }, [
                        _vm._v("Double Verification")
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("tr", { staticClass: "content-filters" }, [
                  _vm._m(8),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Administrative")]),
                      _vm._v(" "),
                      !_vm.profileSettings
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.administrative,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.administrative,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "administrative", $$v)
                              },
                              expression: "form.administrative"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: {
                              value: _vm.form.administrative == 1,
                              disabled: ""
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      !_vm.profileSettings
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.remoteDepositCapture,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.remoteDepositCapture,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remoteDepositCapture", $$v)
                              },
                              expression: "form.remoteDepositCapture"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: {
                              value: _vm.form.remoteDepositCapture == 1,
                              disabled: ""
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isPersonal
                    ? _c(
                        "td",
                        [
                          _c("span", [_vm._v("Double Verification")]),
                          _vm._v(" "),
                          !_vm.profileSettings
                            ? _c("mnb-switch", {
                                attrs: {
                                  validator: _vm.$v.form.double_verification,
                                  disabled: !_vm.isPersonal || !_vm.form.account
                                },
                                model: {
                                  value: _vm.form.double_verification,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "double_verification",
                                      $$v
                                    )
                                  },
                                  expression: "form.double_verification"
                                }
                              })
                            : _c("mnb-switch", {
                                attrs: {
                                  value: _vm.form.double_verification == 1,
                                  disabled: ""
                                }
                              })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "8" } })
                ]),
                _vm._v(" "),
                _c("tr", { staticClass: "space" }, [
                  _c("th", { staticStyle: { width: "15%" } }, [
                    _vm._v("Permissions")
                  ]),
                  _vm._v(" "),
                  _c("th", { staticStyle: { width: "12%" } }, [
                    _vm._v("Create Sub-Users")
                  ]),
                  _vm._v(" "),
                  _vm.isMasterBusiness
                    ? _c("th", { staticStyle: { width: "12%" } }, [
                        _vm._v("Create Sub-Profiles")
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("tr", { staticClass: "content-filters" }, [
                  _vm._m(9),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Create Sub-Users")]),
                      _vm._v(" "),
                      !_vm.profileSettings
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.create_subusers,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.create_subusers,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "create_subusers", $$v)
                              },
                              expression: "form.create_subusers"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: {
                              value: _vm.form.create_subusers == 1,
                              disabled: ""
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isMasterBusiness
                    ? _c(
                        "td",
                        [
                          _c("span", [_vm._v("Create Sub-Profiles")]),
                          _vm._v(" "),
                          !_vm.profileSettings
                            ? _c("mnb-switch", {
                                attrs: {
                                  value: _vm.form.create_subprofiles,
                                  validator: _vm.$v.form.create_subprofiles,
                                  disabled: ""
                                }
                              })
                            : _c("mnb-switch", {
                                attrs: {
                                  value: _vm.form.create_subprofiles == 1,
                                  disabled: ""
                                }
                              })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "7" } })
                ]),
                _vm._v(" "),
                _vm._m(10),
                _vm._v(" "),
                _c("tr", { staticClass: "content-filters" }, [
                  _c("td"),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Send Welcome/Activation Email")]),
                      _vm._v(" "),
                      !_vm.profileSettings
                        ? _c("mnb-switch", {
                            attrs: {
                              validator: _vm.$v.form.welcome_email,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.welcome_email,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "welcome_email", $$v)
                              },
                              expression: "form.welcome_email"
                            }
                          })
                        : _c("mnb-switch", {
                            attrs: {
                              value: _vm.form.welcome_email == 1,
                              disabled: ""
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Profile Review Days")]),
                      _vm._v(" "),
                      !_vm.profileSettings
                        ? _c("mnb-select", {
                            staticClass: "select",
                            attrs: {
                              validator: _vm.$v.form.review_days,
                              options: _vm.reviewOptions,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.review_days,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "review_days", $$v)
                              },
                              expression: "form.review_days"
                            }
                          })
                        : [_vm._v(_vm._s(_vm.form.review_days))]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("span", [_vm._v("Profile Language")]),
                      _vm._v(" "),
                      !_vm.profileSettings
                        ? _c("mnb-select", {
                            staticClass: "select",
                            attrs: {
                              validator: _vm.$v.form.language,
                              options: _vm.languageOptions,
                              disabled: !_vm.form.account
                            },
                            model: {
                              value: _vm.form.language,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "language", $$v)
                              },
                              expression: "form.language"
                            }
                          })
                        : [_vm._v(_vm._s(_vm.form.language))]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "7" } })
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer space" }, [
        _c(
          "span",
          [
            _c("b", [_vm._v("Date / Time:")]),
            _c("br"),
            _vm._v(" "),
            !_vm.profileSettings
              ? [_vm._v("N/A")]
              : [
                  _vm._v(
                    _vm._s(_vm._f("date")(_vm.profileSettings.createdAt, "LL"))
                  ),
                  _c("br"),
                  _vm._v(
                    _vm._s(_vm._f("date")(_vm.profileSettings.createdAt, "LT"))
                  )
                ]
          ],
          2
        ),
        _vm._v(" "),
        _vm.registration.status !== "Complete"
          ? _c("div", [
              !_vm.profileSettings
                ? _c(
                    "button",
                    {
                      staticClass: "main",
                      attrs: { disabled: !_vm.form.account },
                      on: {
                        click: function($event) {
                          return _vm.create()
                        }
                      }
                    },
                    [_vm._v("Create")]
                  )
                : _vm.registration.status != "Active"
                ? _c(
                    "button",
                    {
                      staticClass: "second",
                      attrs: { disabled: !_vm.form.account },
                      on: {
                        click: function($event) {
                          return _vm.remove()
                        }
                      }
                    },
                    [_vm._v("Remove")]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Select Account")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("ACH")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [
        _vm._v("Account Transfers")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("Checks")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("Remote Deposit")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("Domestic Wires")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [
        _vm._v("International Wires")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("Assets")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Services")]),
      _vm._v(" Transaction Limits")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Remote deposit")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("Multi-Currency")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("Debit Cards")]),
      _vm._v(" "),
      _c("th", { attrs: { colspan: "5" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("System Services")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("Exchange Desk")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("Trade Desk")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Services")]), _vm._v(" Enable")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Services")]),
      _vm._v(" Transaction Limits")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "space" }, [
      _c("td", { attrs: { colspan: "11" } }, [
        _c("h2", { staticClass: "profile-settings" }, [
          _vm._v("Profile Settings")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Verifications")]), _vm._v(" Enable")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("span", [_vm._v("Permissions")]), _vm._v(" Enable")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "space" }, [
      _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Options")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [
        _vm._v("Send Welcome Email")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [
        _vm._v("Profile Review Days")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "12%" } }, [_vm._v("Profile Language")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
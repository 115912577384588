var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_c("checklist-steps", { attrs: { partner: _vm.partner } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
      [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.logs, function(log) {
              return _c("tr", { key: log.id }, [
                _c("td", [
                  _c("span", [_vm._v("Last Login")]),
                  _vm._v(
                    _vm._s(_vm._f("date")(log.recorded, "MM/D/YYYY hh:mm A"))
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Username")]),
                  _vm._v(_vm._s(log.user.username))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Data Transfer")]),
                  _vm._v(_vm._s(_vm._f("fileSize")(log.transferred)))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("IP Address")]),
                  _vm._v(_vm._s(log.ipAddress))
                ])
              ])
            })
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Last Login")]),
      _vm._v(" "),
      _c("th", [_vm._v("Username")]),
      _vm._v(" "),
      _c("th", [_vm._v("Data Transfer")]),
      _vm._v(" "),
      _c("th", [_vm._v("IP Address")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "accordion" }, [
      _vm.registration.status == "Active"
        ? _c(
            "section",
            {
              class: {
                "cof-box": true,
                "cof-box-active": _vm.activeInfo,
                "content-tlist": true
              },
              staticStyle: { margin: "30px 0" }
            },
            [
              _c(
                "table",
                {
                  staticClass: "content-table accordion-headlin",
                  staticStyle: { "font-size": "14px" },
                  on: {
                    click: function($event) {
                      _vm.activeInfo = !_vm.activeInfo
                    }
                  }
                },
                [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "15%" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("date")(_vm.registration.createdAt)) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticStyle: { width: "15%" } },
                      [
                        _vm.registration.type == "Personal"
                          ? [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.registration.data.firstName) +
                                  " " +
                                  _vm._s(_vm.registration.data.lastName) +
                                  "\n          "
                              )
                            ]
                          : [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.registration.data.legal_name) +
                                  "\n          "
                              )
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("th", { staticStyle: { width: "15%" } }, [
                      _vm._v(_vm._s(_vm.registration.accounts[0].profileNumber))
                    ]),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticStyle: { width: "15%" } },
                      [
                        _vm.serviceProviderName == "Solid" ||
                        _vm.serviceProviderName == "Solid Test"
                          ? [
                              _vm.solidAccount
                                ? [
                                    _vm._v(
                                      _vm._s(_vm.solidAccount.accountNumber)
                                    )
                                  ]
                                : _vm._e()
                            ]
                          : [
                              !_vm.complianceScan
                                ? [_vm._v("N/A")]
                                : [
                                    _vm._v(
                                      _vm._s(_vm.complianceScan.accountNumber)
                                    )
                                  ]
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("th", { staticStyle: { width: "15%" } }, [
                      _vm._v(_vm._s(_vm.serviceProviderName))
                    ]),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticStyle: { width: "15%" } },
                      [
                        _vm.serviceProviderName == "Solid" ||
                        _vm.serviceProviderName == "Solid Test"
                          ? [
                              _vm.registration.type != "Business"
                                ? [
                                    _vm.complianceScan &&
                                    _vm.complianceScan.kycStatus == "approved"
                                      ? [_vm._v("Pass")]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.complianceScan &&
                                    _vm.complianceScan.kycStatus == "declined"
                                      ? [_vm._v("Declined")]
                                      : _vm._e()
                                  ]
                                : [
                                    _vm.complianceScan &&
                                    _vm.complianceScan.kycStatus ==
                                      "approved" &&
                                    _vm.complianceScan.kybStatus == "approved"
                                      ? [_vm._v("Pass")]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.complianceScan &&
                                    (_vm.complianceScan.kycStatus ==
                                      "declined" ||
                                      _vm.complianceScan.kybStatus ==
                                        "declined")
                                      ? [_vm._v("Declined")]
                                      : _vm._e()
                                  ]
                            ]
                          : [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.complianceScan
                                      ? _vm.complianceScan.confirm
                                      : ""
                                  ) +
                                  "\n          "
                              )
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("th")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("checklist-steps", {
                attrs: { registration: _vm.registration }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "content-tlist", staticStyle: { margin: "30px 0" } },
      [
        _c("table", { staticClass: "content-table" }, [
          _c("tr", [
            _c("th", { staticStyle: { width: "15%" } }, [
              _vm._v("Registration Date")
            ]),
            _vm._v(" "),
            _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Name")]),
            _vm._v(" "),
            _c("th", { staticStyle: { width: "15%" } }, [
              _vm._v("Profile Number")
            ]),
            _vm._v(" "),
            _c("th", { staticStyle: { width: "15%" } }, [
              _vm._v("Account Number")
            ]),
            _vm._v(" "),
            _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Provider")]),
            _vm._v(" "),
            _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Result")]),
            _vm._v(" "),
            _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Options")])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
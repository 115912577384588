var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading && !_vm.hasNumber
      ? _c("div", [
          _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.providerId,
                  expression: "providerId"
                }
              ],
              attrs: { placeholder: "Provider ID" },
              domProps: { value: _vm.providerId },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.providerId = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.loadAccounts($event)
                  }
                }
              },
              [_vm._v("\n          Select provider\n        ")]
            )
          ])
        ])
      : _vm.loading
      ? _c("div", [_vm._v("Loading...")])
      : _vm._e(),
    _vm._v(" "),
    !_vm.loading
      ? _c("section", [
          _vm.closed
            ? _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
                _vm._v(
                  "\n        All templates listed below are deleted\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "24px 30px 10px 10px" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.closeAll($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n          Delete all shown templates\n          " +
                    _vm._s(
                      _vm.progress
                        ? "(" + _vm.progress + "/" + _vm.accounts.length + ")"
                        : ""
                    ) +
                    "\n        "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "content-tlist" }, [
            _c(
              "table",
              { staticClass: "content-table" },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm._l(_vm.accounts, function(item, i) {
                  return _c(
                    "tr",
                    { key: i },
                    [
                      _c("td", { staticStyle: { "text-align": "center" } }, [
                        _c("span", [_vm._v("Date Created")]),
                        _vm._v(
                          _vm._s(_vm._f("date")(item.createdAt, "MM/D/YYYY"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("span", [_vm._v("Last Updated")]),
                          _vm._v(" "),
                          item.updatedAt
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(item.updatedAt, "MM/D/YYYY")
                                  )
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("td", { staticStyle: { "text-align": "center" } }, [
                        _c("span", [_vm._v("By")]),
                        _vm._v(
                          "\n              " +
                            _vm._s(item.savedBy) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      [
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _c("span", [_vm._v("Account")]),
                          _vm._v(_vm._s(item.account))
                        ])
                      ],
                      _vm._v(" "),
                      _c("td", { staticStyle: { "text-align": "center" } }, [
                        _c("span", [_vm._v("Template Name")]),
                        _vm._v(_vm._s(item.templateName))
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("span", [_vm._v("Beneficiary")]),
                          _vm._v(" "),
                          item.method == "Asset"
                            ? [_vm._v(_vm._s(item.payeeToName))]
                            : item.method == "checks"
                            ? [_vm._v(_vm._s(item.payee))]
                            : [_vm._v(_vm._s(item.accountName))]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("td", { staticStyle: { "text-align": "center" } }, [
                        _c("span", [_vm._v("Method")]),
                        _vm._v(_vm._s(item.method))
                      ])
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                !_vm.accounts.length
                  ? _c("tr", [
                      _c(
                        "td",
                        { staticClass: "no-data", attrs: { colspan: "11" } },
                        [_vm._v("No data")]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "11%", "text-align": "center" } }, [
        _vm._v("Created")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "11%", "text-align": "center" } }, [
        _vm._v("Updated")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "5%", "text-align": "center" } }, [
        _vm._v("By")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "6%", "text-align": "center" } }, [
        _vm._v("Account")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "13%", "text-align": "center" } }, [
        _vm._v("Template")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "13%", "text-align": "center" } }, [
        _vm._v("Beneficiary")
      ]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "7%", "text-align": "center" } }, [
        _vm._v("Method")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "content-filters" },
        [
          _c("basic-form", [
            _c("h4", [_vm._v("Connex Connection Manager")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "cof-row",
                staticStyle: { "margin-bottom": "70px" }
              },
              [
                _c("mnb-select", {
                  attrs: {
                    options: _vm.yesNoOptions,
                    disabled: !_vm.updateConnex,
                    label: "Connex Connect"
                  },
                  model: {
                    value: _vm.connexForm.connex_connect,
                    callback: function($$v) {
                      _vm.$set(_vm.connexForm, "connex_connect", $$v)
                    },
                    expression: "connexForm.connex_connect"
                  }
                }),
                _vm._v(" "),
                _c("mnb-input", {
                  attrs: { disabled: !_vm.updateConnex, label: "Version" },
                  model: {
                    value: _vm.connexForm.version,
                    callback: function($$v) {
                      _vm.$set(_vm.connexForm, "version", $$v)
                    },
                    expression: "connexForm.version"
                  }
                }),
                _vm._v(" "),
                _c("mnb-input", {
                  attrs: { disabled: !_vm.updateConnex, label: "Username" },
                  model: {
                    value: _vm.connexForm.username,
                    callback: function($$v) {
                      _vm.$set(_vm.connexForm, "username", $$v)
                    },
                    expression: "connexForm.username"
                  }
                }),
                _vm._v(" "),
                _c("mnb-input", {
                  attrs: { disabled: !_vm.updateConnex, label: "Key" },
                  model: {
                    value: _vm.connexForm.code,
                    callback: function($$v) {
                      _vm.$set(_vm.connexForm, "code", $$v)
                    },
                    expression: "connexForm.code"
                  }
                }),
                _vm._v(" "),
                _c("mnb-select", {
                  attrs: {
                    options: _vm.statusOptions,
                    disabled: !_vm.updateConnex,
                    label: "Status"
                  },
                  model: {
                    value: _vm.connexForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.connexForm, "status", $$v)
                    },
                    expression: "connexForm.status"
                  }
                }),
                _vm._v(" "),
                _c("mnb-textarea", {
                  ref: "notesTextarea",
                  staticStyle: { "flex-grow": "1" },
                  attrs: {
                    label: "Notes",
                    disabled: !_vm.updateConnex,
                    classes: { connexNote: true }
                  },
                  on: { input: _vm.mixin_autoResize_resize },
                  model: {
                    value: _vm.connexForm.notes,
                    callback: function($$v) {
                      _vm.$set(_vm.connexForm, "notes", $$v)
                    },
                    expression: "connexForm.notes"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "cof-field mobinl",
                    staticStyle: { "max-width": "100px" }
                  },
                  [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    !_vm.updateConnex
                      ? _c(
                          "button",
                          {
                            staticClass: "main",
                            staticStyle: {
                              "min-width": "100px",
                              "margin-left": "20px"
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateConnexConnection()
                              }
                            }
                          },
                          [_vm._v("Update")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "main save-connex",
                            staticStyle: {
                              "min-width": "100px",
                              "margin-left": "20px",
                              "background-color": "#67c23a"
                            },
                            on: {
                              click: function($event) {
                                return _vm.saveConnex()
                              }
                            }
                          },
                          [_vm._v("Save")]
                        )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "cof-field mobinl" }, [
                  _c("label", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "second",
                      on: {
                        click: function($event) {
                          return _vm.resetFieldsConnex()
                        }
                      }
                    },
                    [_vm._v("Reset Fields")]
                  )
                ])
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.open },
          on: {
            "update:open": function($event) {
              _vm.open = $event
            }
          }
        },
        [
          _c("h2", [_vm._v("Update Status")]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-field status-options" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.status,
                    expression: "status"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.status = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { value: "Review" } }, [
                  _vm._v("Review")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Testing" } }, [
                  _vm._v("Testing")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Active" } }, [
                  _vm._v("Active")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "Disabled" } }, [
                  _vm._v("Disabled")
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row buttons" }, [
            _c(
              "a",
              {
                staticClass: "pop-btn",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.open = false
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "pop-btn",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.updateStatus()
                  }
                }
              },
              [_vm._v("Confirm")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "content-filters" },
        [
          _c("basic-form", [
            _c("div", { staticClass: "cof-row" }, [
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("label", [_vm._v("Currency Type")]),
                  _vm._v(" "),
                  _c("mnb-select", {
                    attrs: {
                      validator: _vm.$v.form.currencyType,
                      options: _vm.typeOptions
                    },
                    model: {
                      value: _vm.form.currencyType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "currencyType", $$v)
                      },
                      expression: "form.currencyType"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cof-field sysint-page" },
                [
                  _c("mnb-input", {
                    attrs: {
                      validator: _vm.$v.form.currencyNickname,
                      label: "Currency Nickname"
                    },
                    model: {
                      value: _vm.form.currencyNickname,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "currencyNickname", $$v)
                      },
                      expression: "form.currencyNickname"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cof-row" }, [
              _c("div", { staticClass: "cof-field mobinl" }, [
                _c("label", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "main",
                    on: {
                      click: function($event) {
                        return _vm.save()
                      }
                    }
                  },
                  [_vm._v("Add Currency")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cof-field mobinl" }, [
                _c("label", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        return _vm.resetFields()
                      }
                    }
                  },
                  [_vm._v("Reset")]
                )
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("section", { staticClass: "content-tlist" }, [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm._l(_vm.currencies, function(currency, i) {
              return _c("tr", { key: i }, [
                _c("td", [
                  _c("span"),
                  _vm._v(
                    "\n          " +
                      _vm._s(currency.systemCurrencyType) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Currency Nickname")]),
                  _vm._v(_vm._s(currency.currencyNickname))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Currency Name")]),
                  _vm._v(_vm._s(currency.name))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Currency Description")]),
                  _vm._v(_vm._s(currency.description))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Date Added")]),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("date")(currency.dateAdded)) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Trade Desk")]),
                  _vm._v(" "),
                  currency.systemCurrencyType == "Assets"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: currency.tradeDesk,
                            expression: "currency.tradeDesk"
                          }
                        ],
                        staticClass: "fee-checkbox",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(currency.tradeDesk)
                            ? _vm._i(currency.tradeDesk, null) > -1
                            : currency.tradeDesk
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = currency.tradeDesk,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      currency,
                                      "tradeDesk",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      currency,
                                      "tradeDesk",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(currency, "tradeDesk", $$c)
                              }
                            },
                            function($event) {
                              return _vm.checkTradeDesk(currency.id, currency)
                            }
                          ]
                        }
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Options")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "action-button update",
                      on: {
                        click: function($event) {
                          return _vm.update(currency.id)
                        }
                      }
                    },
                    [_vm._v("Update")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "action-button remove",
                      on: { click: function($event) {} }
                    },
                    [_vm._v("Remove")]
                  )
                ])
              ])
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.open, width: 400 },
          on: {
            "update:open": function($event) {
              _vm.open = $event
            }
          }
        },
        [
          _c("div", { staticClass: "scrollable" }, [
            _c("p", [_vm._v(_vm._s(_vm.message))])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.openConfirm, width: 400 },
          on: {
            "update:open": function($event) {
              _vm.openConfirm = $event
            }
          }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [_vm._v("Confirm New Currency")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "scrollable" }, [
            _c("table", { staticClass: "content-table" }, [
              _c("tr", [
                _c("th", [_vm._v("Type")]),
                _vm._v(" "),
                _c("th", [_vm._v("Currency")]),
                _vm._v(" "),
                _c("th", [_vm._v("Name")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.form.currencyType) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.form.currencyNickname) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.currency ? _vm.currency.name : "") +
                      "\n          "
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                staticClass: "main",
                on: {
                  click: function($event) {
                    return _vm.confirm()
                  }
                }
              },
              [_vm._v("Confirm")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "second",
                on: {
                  click: function($event) {
                    _vm.openConfirm = false
                  }
                }
              },
              [_vm._v("Close")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("div", [_c("h2", { staticClass: "inner-headers" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Currency Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Currency Nickname")]),
      _vm._v(" "),
      _c("th", [_vm._v("Currency Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Currency Description")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date Added")]),
      _vm._v(" "),
      _c("th", [_vm._v("Trade Desk")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.registration
    ? _c(
        "div",
        { staticClass: "cp-box" },
        [
          _c(
            "ul",
            { staticClass: "sub-tabs" },
            [
              _vm.$hasPerm("status-update") &&
              _vm.registration.status === "Denied"
                ? [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "set-profile",
                          on: {
                            click: function($event) {
                              return _vm.setNew()
                            }
                          }
                        },
                        [_vm._v("New")]
                      )
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.$hasPerm("setup-checklist") && _vm.showActive
                ? [
                    _vm.showSetupChecklist
                      ? _c(
                          "li",
                          {
                            class: {
                              active: _vm.activeTab === "setup_checklist"
                            },
                            on: {
                              click: function($event) {
                                _vm.activeTab = "setup_checklist"
                              }
                            }
                          },
                          [
                            _vm.registration.accounts.length > 1
                              ? _c("a", [_vm._v("Boarding")])
                              : _c("a", [_vm._v("Profile")])
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.$hasPerm("set-profile")
                ? [
                    _vm.showSetupButton
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "set-profile",
                              on: {
                                click: function($event) {
                                  return _vm.setProfile()
                                }
                              }
                            },
                            [_vm._v("Set Profile")]
                          )
                        ])
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.$hasPerm("setup-checklist") ? _c("hr") : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === "info"
            ? _c("registration-data-info", {
                attrs: {
                  registration: _vm.registration,
                  "show-edit":
                    ["New", "In Process", "Denied", "Archived"].includes(
                      this.registration.status
                    ) && _vm.showSetupChecklist
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === "setup_checklist"
            ? _c("setup-checklist", {
                attrs: { registration: _vm.registration }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === "setup_history"
            ? _c("setup-history", { attrs: { registration: _vm.registration } })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
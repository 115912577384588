var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cp-box" },
    [
      _c(
        "ul",
        { staticClass: "sub-tabs", staticStyle: { "margin-bottom": "12px" } },
        [
          _c(
            "li",
            {
              class: { active: _vm.activeTab === "settings" },
              on: {
                click: function($event) {
                  _vm.activeTab = "settings"
                }
              }
            },
            [_c("a", [_vm._v("Settings")])]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              class: { active: _vm.activeTab === "feesSchedule" },
              on: {
                click: function($event) {
                  _vm.activeTab = "feesSchedule"
                }
              }
            },
            [_c("a", [_vm._v("Schedule")])]
          )
        ]
      ),
      _vm._v(" "),
      _vm.$hasPerm("update-profile") ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.activeTab === "settings"
        ? _c("settings-setup", {
            attrs: {
              registration: _vm.registration,
              noDateUpdate: true,
              hideVerificationPermissionsReview: true
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
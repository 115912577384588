var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "space-around" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open"
            }
          ],
          staticClass: "content-tlist",
          staticStyle: { width: "70%", border: "none", padding: "30px" }
        },
        [
          _c("div", { staticClass: "popup-header" }, [
            _c("h3", [
              _vm._v(_vm._s(_vm.isDeposit ? "Deposit" : "Create New Template"))
            ]),
            _vm._v(" "),
            _vm.transaction
              ? _c("span", [
                  _vm._v(
                    "Transaction: " + _vm._s(_vm.transaction.transactionId)
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.form.depositType
            ? [
                _c("div", { staticClass: "scrollable" }, [
                  _c(
                    "div",
                    { staticClass: "content-form content-form-modern" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-row form-split justify-content-center"
                        },
                        [
                          _c("form-field-select", {
                            attrs: {
                              label: "Template Type",
                              validator: _vm.$v.form.depositType,
                              options: _vm.depositTypeOptions,
                              required: ""
                            },
                            model: {
                              value: _vm.form.depositType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "depositType", $$v)
                              },
                              expression: "form.depositType"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              ]
            : [
                _vm.addModal
                  ? [
                      _vm.form.depositType == "Funds"
                        ? _c(
                            "div",
                            { staticClass: "scrollable" },
                            [
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "content-form content-form-modern"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-row form-split" },
                                      [
                                        _c("form-field-select-search", {
                                          attrs: {
                                            label: "Account",
                                            validator: _vm.$v.form.account,
                                            options: _vm.accountOptions,
                                            "option-group": !_vm.isCustomer,
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.form.account,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "account", $$v)
                                            },
                                            expression: "form.account"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-row form-split" },
                                      [
                                        _c("form-field-select", {
                                          attrs: {
                                            disabled: !_vm.selectedAccount,
                                            label: "Withdrawal Method",
                                            validator: _vm.$v.form.deposit_type,
                                            options: _vm.depositOptions,
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.form.deposit_type,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "deposit_type",
                                                $$v
                                              )
                                            },
                                            expression: "form.deposit_type"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("form-field-select", {
                                          attrs: {
                                            label: "Currency",
                                            validator: _vm.$v.form.currency,
                                            options: _vm.currencyOptions,
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.form.currency,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "currency",
                                                $$v
                                              )
                                            },
                                            expression: "form.currency"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-balance" }, [
                                  _vm.invalidBalance
                                    ? _c(
                                        "div",
                                        { staticClass: "error-message" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Exceeds available balance. Please lower amount and try again."
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-balance" }, [
                                  _vm.invalidToProvider
                                    ? _c(
                                        "div",
                                        { staticClass: "error-message" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Receiver cannot accept Account to Account Transfers. Please check information and try again."
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "sum" }, [
                                  _c("div", [
                                    _vm._v("Fee: "),
                                    _c("b", [
                                      _vm._v(_vm._s(_vm._f("money")(_vm.fee)))
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.showInternationalWireForm
                                  ? _c("international-wire-form", {
                                      attrs: {
                                        v: _vm.$v.form,
                                        transactionCountries:
                                          _vm.transactionCountries
                                      },
                                      model: {
                                        value: _vm.form,
                                        callback: function($$v) {
                                          _vm.form = $$v
                                        },
                                        expression: "form"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showACHForm
                                  ? _c("ach-form", {
                                      attrs: { v: _vm.$v.form },
                                      model: {
                                        value: _vm.form,
                                        callback: function($$v) {
                                          _vm.form = $$v
                                        },
                                        expression: "form"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showPayee
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "content-form content-form-modern"
                                      },
                                      [
                                        _c("h3", { staticClass: "subtitle" }, [
                                          _vm._v("PAYEE INFORMATION")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("form-field-text", {
                                              attrs: {
                                                validator: _vm.$v.form.payee,
                                                label: "Payee",
                                                required: ""
                                              },
                                              model: {
                                                value: _vm.form.payee,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "payee",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.payee"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showAddressForm
                                  ? [
                                      _c("h3", { staticClass: "subtitle" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.deposit_type ===
                                              "domestic_wire"
                                              ? "BENEFICIARY ADDRESS"
                                              : "PAYEE ADDRESS"
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _vm.form.deposit_type !== "domestic_wire"
                                        ? _c("address-form", {
                                            attrs: {
                                              usOnly:
                                                _vm.form.deposit_type ===
                                                "domestic_wire"
                                                  ? false
                                                  : true,
                                              v: _vm.$v.form,
                                              transactionCountries:
                                                _vm.transactionCountries
                                            },
                                            model: {
                                              value: _vm.form,
                                              callback: function($$v) {
                                                _vm.form = $$v
                                              },
                                              expression: "form"
                                            }
                                          })
                                        : _c("address-dom-form", {
                                            attrs: {
                                              usOnly:
                                                _vm.form.deposit_type ===
                                                "domestic_wire"
                                                  ? false
                                                  : true,
                                              v: _vm.$v.form,
                                              transactionCountries:
                                                _vm.transactionCountries
                                            },
                                            model: {
                                              value: _vm.form,
                                              callback: function($$v) {
                                                _vm.form = $$v
                                              },
                                              expression: "form"
                                            }
                                          })
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "content-form content-form-modern"
                                  },
                                  [
                                    _vm.showA2AAccountNumber
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-row form-split acc-to-acc"
                                          },
                                          [
                                            _c("form-field-select", {
                                              attrs: {
                                                label: "Select Linked Account",
                                                validator:
                                                  _vm.$v.form
                                                    .a2a_account_number,
                                                options:
                                                  _vm.linkedAccountOptions,
                                                required: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.form.a2a_account_number,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "a2a_account_number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.a2a_account_number"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.invalidAccountNumber
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "error-message"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Invalid Account Number."
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._m(0),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-row form-split template-name"
                                      },
                                      [
                                        _c("form-field-text", {
                                          attrs: {
                                            label: "Memo",
                                            maxlength: 50,
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.form.memo,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "memo", $$v)
                                            },
                                            expression: "form.memo"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("form-field-text", {
                                          attrs: {
                                            validator:
                                              _vm.$v.form.template_name,
                                            label: "Template Name",
                                            maxlength: 50,
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.form.template_name,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "template_name",
                                                $$v
                                              )
                                            },
                                            expression: "form.template_name"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-row form-max" },
                                      [
                                        _c("form-field-text", {
                                          attrs: {
                                            label: "Reference",
                                            maxlength: 60,
                                            required: "",
                                            validator: _vm.$v.form.reference
                                          },
                                          model: {
                                            value: _vm.form.reference,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "reference",
                                                $$v
                                              )
                                            },
                                            expression: "form.reference"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              _vm._v(" "),
                              _c("div", { staticClass: "buttons" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "main",
                                    on: {
                                      click: function($event) {
                                        return _vm.withdraw()
                                      }
                                    }
                                  },
                                  [_vm._v("Save")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "second",
                                    on: {
                                      click: function($event) {
                                        _vm.open = false
                                      }
                                    }
                                  },
                                  [_vm._v("Cancel")]
                                )
                              ])
                            ],
                            2
                          )
                        : _c(
                            "div",
                            { staticClass: "scrollable" },
                            [
                              [
                                _c("div", { staticClass: "content-form" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-row form-split" },
                                    [
                                      _c("form-field-select-search", {
                                        attrs: {
                                          label: "Account",
                                          validator: _vm.$v.form.account,
                                          options: _vm.accountOptions,
                                          "option-group": !_vm.isCustomer,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.form.account,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "account", $$v)
                                          },
                                          expression: "form.account"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("form-field-select", {
                                        attrs: {
                                          label: "Assets Type",
                                          validator: _vm.$v.form.assetsCurrency,
                                          options: _vm.assetsCurrencyOptions,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.form.assetsCurrency,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "assetsCurrency",
                                              $$v
                                            )
                                          },
                                          expression: "form.assetsCurrency"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-row form-split" },
                                    [
                                      _c("form-field-select", {
                                        attrs: {
                                          label: "Sending to",
                                          validator: _vm.$v.form.sendingTo,
                                          options: _vm.sendingToOptions,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.form.sendingTo,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "sendingTo", $$v)
                                          },
                                          expression: "form.sendingTo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "invalid-balance" }, [
                                  _vm.invalidToProvider
                                    ? _c(
                                        "div",
                                        { staticClass: "error-message" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Receiver cannot accept Account to Account Transfers. Please check information and try again."
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "sum" }, [
                                  _c("div", [
                                    _vm._v("Fee: "),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(_vm._f("money")(_vm.assetsFee))
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.form.sendingTo == "WalletAddress"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "content-form paddingless"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row form-split"
                                          },
                                          [
                                            _c("form-field-text", {
                                              attrs: {
                                                validator:
                                                  _vm.$v.form.payeeToName,
                                                label: "Pay to Name"
                                              },
                                              model: {
                                                value: _vm.form.payeeToName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "payeeToName",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.payeeToName"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "form-row form-max" },
                                          [
                                            _c("form-field-text", {
                                              attrs: {
                                                validator:
                                                  _vm.$v.form
                                                    .payeeToWalletAddress,
                                                label: "Pay to Wallet Address",
                                                required: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.form.payeeToWalletAddress,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "payeeToWalletAddress",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.payeeToWalletAddress"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "form-row form-max" },
                                          [
                                            _c("form-field-text", {
                                              attrs: {
                                                validator: _vm.$v.form.tag,
                                                label: "Destination Tag",
                                                required: ""
                                              },
                                              model: {
                                                value: _vm.form.tag,
                                                callback: function($$v) {
                                                  _vm.$set(_vm.form, "tag", $$v)
                                                },
                                                expression: "form.tag"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "content-form paddingless" },
                                  [
                                    _vm.form.sendingTo == "AccountTransfer"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-row form-split acc-to-acc"
                                          },
                                          [
                                            _c("form-field-select", {
                                              attrs: {
                                                label: "Select Linked Account",
                                                validator:
                                                  _vm.$v.form
                                                    .a2a_account_number,
                                                options:
                                                  _vm.linkedAccountOptions,
                                                required: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.form.a2a_account_number,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "a2a_account_number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.a2a_account_number"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.invalidAccountNumber
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "error-message"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Invalid Account Number."
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._m(1),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-row form-split template-name"
                                      },
                                      [
                                        _c("form-field-text", {
                                          attrs: {
                                            label: "Memo",
                                            maxlength: 150,
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.form.memo,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "memo", $$v)
                                            },
                                            expression: "form.memo"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("form-field-text", {
                                          attrs: {
                                            validator:
                                              _vm.$v.form.template_name,
                                            label: "Template Name",
                                            maxlength: 50,
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.form.template_name,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "template_name",
                                                $$v
                                              )
                                            },
                                            expression: "form.template_name"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              _vm._v(" "),
                              _c("div", { staticClass: "buttons" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "main",
                                    on: {
                                      click: function($event) {
                                        return _vm.withdrawAssets()
                                      }
                                    }
                                  },
                                  [_vm._v("Save")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "second",
                                    on: {
                                      click: function($event) {
                                        _vm.open = false
                                      }
                                    }
                                  },
                                  [_vm._v("Cancel")]
                                )
                              ])
                            ],
                            2
                          )
                    ]
                  : _vm._e()
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("h3", { staticClass: "subtitle" }, [_vm._v("EXTRA INFORMATION")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row form-split" }, [
      _c("h3", { staticClass: "subtitle" }, [_vm._v("EXTRA INFORMATION")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
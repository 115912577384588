var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-wrapper",
    {
      class: {
        focused: _vm.focused,
        filled: _vm.focused || _vm.data,
        disabled: _vm.disabled
      },
      attrs: { label: _vm.label, required: _vm.isRequired, errors: _vm.errors }
    },
    [
      _c("copyinput", {
        ref: "input",
        class: { error: _vm.hasError },
        attrs: {
          type: _vm.type,
          required: _vm.isRequired,
          disabled: _vm.disabled,
          maxlength: _vm.maxlength || null
        },
        on: {
          focus: function($event) {
            _vm.focused = true
          },
          blur: function($event) {
            _vm.focused = false
          },
          input: function($event) {
            return _vm.$emit("input", $event.target.value)
          },
          change: function($event) {
            return _vm.$emit("input", $event.target.value)
          }
        },
        model: {
          value: _vm.data,
          callback: function($$v) {
            _vm.data = $$v
          },
          expression: "data"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup",
    {
      attrs: { open: _vm.open },
      on: {
        "update:open": function($event) {
          _vm.open = $event
        }
      }
    },
    [
      _c("div", { staticClass: "popup-header" }, [
        _c("h3", [_vm._v("Settings")])
      ]),
      _vm._v(" "),
      _vm.feeSchedule
        ? _c("div", {}, [
            _c("table", { staticClass: "content-table" }, [
              _c("tr", [
                _c("th", { staticStyle: { width: "15%" } }),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "15%" } }, [
                  _vm._v("Fee Name")
                ]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Fee")]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "10%" } }, [
                  _vm._v("Deposit")
                ]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "10%" } }, [
                  _vm._v("Withdrawal")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td"),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Fee Name")]),
                  _vm._v("\n            Volume Fee\n          ")
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Fee")]),
                    _vm._v(" "),
                    !_vm.updateFlag
                      ? [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("formattedTwoDecimal")(
                                  _vm.feeSchedule.volumeFee.fee
                                )
                              ) +
                              " %\n            "
                          )
                        ]
                      : [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.feeSchedule.volumeFee.fee,
                                expression: "feeSchedule.volumeFee.fee"
                              }
                            ],
                            staticClass: "fee-input",
                            attrs: {
                              type: "number",
                              validator: _vm.$v.feeSchedule.volumeFee.fee
                            },
                            domProps: { value: _vm.feeSchedule.volumeFee.fee },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.feeSchedule.volumeFee,
                                  "fee",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v("%\n            ")
                        ]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("td"),
                _vm._v(" "),
                _c("td")
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td"),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Fee Name")]),
                  _vm._v("\n            Monthly Access Fee\n          ")
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Fee")]),
                    _vm._v(" "),
                    !_vm.updateFlag
                      ? [
                          _vm._v(
                            "\n              $ " +
                              _vm._s(
                                _vm._f("formattedTwoDecimal")(
                                  _vm.feeSchedule.monthlyAccessFee.fee
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      : [
                          _vm._v("\n              $ "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.feeSchedule.monthlyAccessFee.fee,
                                expression: "feeSchedule.monthlyAccessFee.fee"
                              }
                            ],
                            staticClass: "fee-input",
                            attrs: {
                              type: "number",
                              validator: _vm.$v.feeSchedule.monthlyAccessFee.fee
                            },
                            domProps: {
                              value: _vm.feeSchedule.monthlyAccessFee.fee
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.feeSchedule.monthlyAccessFee,
                                  "fee",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("td"),
                _vm._v(" "),
                _c("td")
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td"),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Fee Name")]),
                  _vm._v("\n            Dormant Accounts\n          ")
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Fee")]),
                    _vm._v(" "),
                    !_vm.updateFlag
                      ? [
                          _vm._v(
                            "\n              $ " +
                              _vm._s(
                                _vm._f("formattedTwoDecimal")(
                                  _vm.feeSchedule.dormantAccounts.fee
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      : [
                          _vm._v("\n              $ "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.feeSchedule.dormantAccounts.fee,
                                expression: "feeSchedule.dormantAccounts.fee"
                              }
                            ],
                            staticClass: "fee-input",
                            attrs: {
                              type: "number",
                              validator: _vm.$v.feeSchedule.dormantAccounts.fee
                            },
                            domProps: {
                              value: _vm.feeSchedule.dormantAccounts.fee
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.feeSchedule.dormantAccounts,
                                  "fee",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("td"),
                _vm._v(" "),
                _c("td")
              ]),
              _vm._v(" "),
              _vm.provider.ach == 1
                ? _c("tr", [
                    _c(
                      "td",
                      [
                        _vm.updateFlag
                          ? _c("mnb-switch", {
                              model: {
                                value: _vm.profileSettings.ach,
                                callback: function($$v) {
                                  _vm.$set(_vm.profileSettings, "ach", $$v)
                                },
                                expression: "profileSettings.ach"
                              }
                            })
                          : _c("mnb-switch", {
                              attrs: {
                                value: _vm.profileSettings.ach,
                                disabled: ""
                              }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Fee Name")]),
                      _vm._v("\n            ACH\n          ")
                    ]),
                    _vm._v(" "),
                    _c("td", [_c("span", [_vm._v("Fee")])]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.ach.per_deposit
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.ach.per_deposit,
                                    expression: "feeSchedule.ach.per_deposit"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator: _vm.$v.feeSchedule.ach.per_deposit
                                },
                                domProps: {
                                  value: _vm.feeSchedule.ach.per_deposit
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.ach,
                                      "per_deposit",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.ach.per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.ach.per_withdrawal,
                                    expression: "feeSchedule.ach.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.ach.per_withdrawal
                                },
                                domProps: {
                                  value: _vm.feeSchedule.ach.per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.ach,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.provider.acct == 1
                ? _c("tr", [
                    _c(
                      "td",
                      [
                        _vm.updateFlag
                          ? _c("mnb-switch", {
                              model: {
                                value: _vm.profileSettings.acc_to_acc,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.profileSettings,
                                    "acc_to_acc",
                                    $$v
                                  )
                                },
                                expression: "profileSettings.acc_to_acc"
                              }
                            })
                          : _c("mnb-switch", {
                              attrs: {
                                value: _vm.profileSettings.acc_to_acc,
                                disabled: ""
                              }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Fee Name")]),
                      _vm._v("\n            Account Transfer\n          ")
                    ]),
                    _vm._v(" "),
                    _c("td", [_c("span", [_vm._v("Fee")])]),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.accountTransfer
                                        .per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.accountTransfer
                                        .per_withdrawal,
                                    expression:
                                      "feeSchedule.accountTransfer.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.accountTransfer
                                      .per_withdrawal
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.accountTransfer
                                      .per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.accountTransfer,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.provider.checks == 1
                ? _c("tr", [
                    _c(
                      "td",
                      [
                        _vm.updateFlag
                          ? _c("mnb-switch", {
                              model: {
                                value: _vm.profileSettings.checks,
                                callback: function($$v) {
                                  _vm.$set(_vm.profileSettings, "checks", $$v)
                                },
                                expression: "profileSettings.checks"
                              }
                            })
                          : _c("mnb-switch", {
                              attrs: {
                                value: _vm.profileSettings.checks,
                                disabled: ""
                              }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Fee Name")]),
                      _vm._v("\n            Checks\n          ")
                    ]),
                    _vm._v(" "),
                    _c("td", [_c("span", [_vm._v("Fee")])]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.checks.per_deposit
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.checks.per_deposit,
                                    expression: "feeSchedule.checks.per_deposit"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.checks.per_deposit
                                },
                                domProps: {
                                  value: _vm.feeSchedule.checks.per_deposit
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.checks,
                                      "per_deposit",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.checks.per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.checks.per_withdrawal,
                                    expression:
                                      "feeSchedule.checks.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.checks.per_withdrawal
                                },
                                domProps: {
                                  value: _vm.feeSchedule.checks.per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.checks,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.provider.domWires == 1
                ? _c("tr", [
                    _c(
                      "td",
                      [
                        _vm.updateFlag
                          ? _c("mnb-switch", {
                              model: {
                                value: _vm.profileSettings.domWires,
                                callback: function($$v) {
                                  _vm.$set(_vm.profileSettings, "domWires", $$v)
                                },
                                expression: "profileSettings.domWires"
                              }
                            })
                          : _c("mnb-switch", {
                              attrs: {
                                value: _vm.profileSettings.domWires,
                                disabled: ""
                              }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Fee Name")]),
                      _vm._v("\n            Domestic Wire\n          ")
                    ]),
                    _vm._v(" "),
                    _c("td", [_c("span", [_vm._v("Fee")])]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.domesticWire.per_deposit
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.domesticWire.per_deposit,
                                    expression:
                                      "feeSchedule.domesticWire.per_deposit"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.domesticWire.per_deposit
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.domesticWire.per_deposit
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.domesticWire,
                                      "per_deposit",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.domesticWire
                                        .per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.domesticWire
                                        .per_withdrawal,
                                    expression:
                                      "feeSchedule.domesticWire.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.domesticWire
                                      .per_withdrawal
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.domesticWire.per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.domesticWire,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.provider.intlWires == 1
                ? _c("tr", [
                    _c(
                      "td",
                      [
                        _vm.updateFlag
                          ? _c("mnb-switch", {
                              model: {
                                value: _vm.profileSettings.intlWires,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.profileSettings,
                                    "intlWires",
                                    $$v
                                  )
                                },
                                expression: "profileSettings.intlWires"
                              }
                            })
                          : _c("mnb-switch", {
                              attrs: {
                                value: _vm.profileSettings.intlWires,
                                disabled: ""
                              }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Fee Name")]),
                      _vm._v("\n            International Wire\n          ")
                    ]),
                    _vm._v(" "),
                    _c("td", [_c("span", [_vm._v("Fee")])]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.internationalWire
                                        .per_deposit
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.internationalWire
                                        .per_deposit,
                                    expression:
                                      "feeSchedule.internationalWire.per_deposit"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.internationalWire
                                      .per_deposit
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.internationalWire
                                      .per_deposit
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.internationalWire,
                                      "per_deposit",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.internationalWire
                                        .per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.internationalWire
                                        .per_withdrawal,
                                    expression:
                                      "feeSchedule.internationalWire.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.internationalWire
                                      .per_withdrawal
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.internationalWire
                                      .per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.internationalWire,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.provider.exchange == 1
                ? _c("tr", [
                    _c(
                      "td",
                      [
                        _vm.updateFlag
                          ? _c("mnb-switch", {
                              model: {
                                value: _vm.profileSettings.exchange,
                                callback: function($$v) {
                                  _vm.$set(_vm.profileSettings, "exchange", $$v)
                                },
                                expression: "profileSettings.exchange"
                              }
                            })
                          : _c("mnb-switch", {
                              attrs: {
                                value: _vm.profileSettings.exchange,
                                disabled: ""
                              }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Fee Name")]),
                      _vm._v("\n            Exchange\n          ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Fee")]),
                        _vm._v(" "),
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.exchange.fee
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.exchange.fee,
                                    expression: "feeSchedule.exchange.fee"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator: _vm.$v.feeSchedule.exchange.fee
                                },
                                domProps: {
                                  value: _vm.feeSchedule.exchange.fee
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.exchange,
                                      "fee",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.provider.assets == 1
                ? _c("tr", [
                    _c(
                      "td",
                      [
                        _vm.updateFlag
                          ? _c("mnb-switch", {
                              model: {
                                value: _vm.profileSettings.assets,
                                callback: function($$v) {
                                  _vm.$set(_vm.profileSettings, "assets", $$v)
                                },
                                expression: "profileSettings.assets"
                              }
                            })
                          : _c("mnb-switch", {
                              attrs: {
                                value: _vm.profileSettings.assets,
                                disabled: ""
                              }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Fee Name")]),
                      _vm._v("\n            Assets\n          ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Fee")]),
                        _vm._v(" "),
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.assets.fee
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.assets.fee,
                                    expression: "feeSchedule.assets.fee"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator: _vm.$v.feeSchedule.assets.fee
                                },
                                domProps: { value: _vm.feeSchedule.assets.fee },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.assets,
                                      "fee",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.assets.per_deposit
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.assets.per_deposit,
                                    expression: "feeSchedule.assets.per_deposit"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.assets.per_deposit
                                },
                                domProps: {
                                  value: _vm.feeSchedule.assets.per_deposit
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.assets,
                                      "per_deposit",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.assets.per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.assets.per_withdrawal,
                                    expression:
                                      "feeSchedule.assets.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.assets.per_withdrawal
                                },
                                domProps: {
                                  value: _vm.feeSchedule.assets.per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.assets,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.provider.debitCards == 1
                ? _c("tr", [
                    _c(
                      "td",
                      [
                        _vm.updateFlag
                          ? _c("mnb-switch", {
                              model: {
                                value: _vm.profileSettings.debitCards,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.profileSettings,
                                    "debitCards",
                                    $$v
                                  )
                                },
                                expression: "profileSettings.debitCards"
                              }
                            })
                          : _c("mnb-switch", {
                              attrs: {
                                value: _vm.profileSettings.debitCards,
                                disabled: ""
                              }
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v("Fee Name")]),
                      _vm._v("\n            Debit Card\n          ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("span", [_vm._v("Fee")]),
                        _vm._v(" "),
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.debitCard.fee
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.feeSchedule.debitCard.fee,
                                    expression: "feeSchedule.debitCard.fee"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator: _vm.$v.feeSchedule.debitCard.fee
                                },
                                domProps: {
                                  value: _vm.feeSchedule.debitCard.fee
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.debitCard,
                                      "fee",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        !_vm.updateFlag
                          ? [
                              _vm._v(
                                "\n              $ " +
                                  _vm._s(
                                    _vm._f("formattedTwoDecimal")(
                                      _vm.feeSchedule.debitCard.per_withdrawal
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : [
                              _vm._v("\n              $ "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.feeSchedule.debitCard.per_withdrawal,
                                    expression:
                                      "feeSchedule.debitCard.per_withdrawal"
                                  }
                                ],
                                staticClass: "fee-input",
                                attrs: {
                                  type: "number",
                                  validator:
                                    _vm.$v.feeSchedule.debitCard.per_withdrawal
                                },
                                domProps: {
                                  value:
                                    _vm.feeSchedule.debitCard.per_withdrawal
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.feeSchedule.debitCard,
                                      "per_withdrawal",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm.$hasPerm("update-fee-Schedule")
                  ? [
                      !_vm.updateFlag
                        ? _c(
                            "button",
                            {
                              staticClass: "main",
                              on: {
                                click: function($event) {
                                  _vm.updateFlag = true
                                }
                              }
                            },
                            [_vm._v("Update")]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "main",
                              on: {
                                click: function($event) {
                                  return _vm.save()
                                }
                              }
                            },
                            [_vm._v("Save")]
                          )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        _vm.open = false
                      }
                    }
                  },
                  [_vm._v("Close")]
                ),
                _vm._v(" "),
                _vm.submitError
                  ? _c("label", { staticClass: "error-message" }, [
                      _vm._v("Please check error(s) and try again.")
                    ])
                  : _vm._e()
              ],
              2
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }